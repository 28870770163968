<template>
  <div class="h-full min-h-0">
    <div class="h-full">
      <!-- vertical splitter -->
      <div class="flex h-full flex-col overflow-y-auto">
        <!-- title -->
        <div class="px-20 pt-10">
          <!-- back and title -->
          <!-- <div class="flex flex-row justify-between">
            <div class="flex flex-row items-center">
              <basePageTitle :title="useContentGuard.organisation.value" />
            </div>
          </div> -->
        </div>

        <!-- content columns -->
        <div class="flex flex-row min-h-0">
          <div class="basis-full">
            <!-- table -->
            <baseTable
              :fields="fields"
              :records="records"
              :records-per-page="25"
              :add-button="true"
              :edit-button="true"
              :delete-button="true"
              :duplicate-button="true"
              :export-record-button="true"
              :clickAble="true"
              :clickCallback="tableAction"
              :loading="loadHandler.somethingLoading.value"
              @add="openAdd"
              @edit="openEdit"
              @delete="deleteRecord"
              @duplicate="duplicateRecord"
              @export_record="exportRecord"
            />

            <!-- add modal -->
            <baseModal v-if="showAdd" @close="closeAdd()" :title="$translate('Add')" @enter="onEnter()">
              <baseForm :handler="addForm" :loading="loadHandler.states['add_record']" />
              <template v-slot:buttons>
                <!-- add -->
                <baseButton @action="addRecord()" :loading="loadHandler.states[`add_record`]">
                  {{ $translate('Add') }}
                </baseButton>

                <!-- import woningtool -->
                <baseButton
                  class="mr-2"
                  @action="openFilePicker('woningtool')"
                  :loading="loadHandler.states[`import_record`]"
                >
                  <div class="flex felx-row">
                    <featherIcon class="w-5 h-5 mr-2" icon="UploadIcon" />
                    <span>Woningtool</span>
                  </div>
                </baseButton>

                <!-- Import monitor -->
                <baseButton
                  v-if="useContentGuard.canSee('monitor:import')"
                  class="mr-2"
                  @action="openFilePicker('monitor')"
                  :loading="loadHandler.states[`import_record`]"
                >
                  <div class="flex felx-row">
                    <featherIcon class="w-5 h-5 mr-2" icon="UploadIcon" />
                    <span>Monitor</span>
                  </div>
                </baseButton>
              </template>
            </baseModal>

            <!-- edit modal -->
            <baseModal v-if="showEdit" @close="closeEdit()" :title="$translate('Edit')">
              <baseForm :handler="editForm" :loading="loadHandler.states['edit_record']" />
              <template v-slot:buttons>
                <baseButton @action="editRecord()" :loading="loadHandler.states[`edit_record`]">
                  {{ $translate('Edit') }}
                </baseButton>
              </template>
            </baseModal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import surveyHandler from '@/use/surveyHandler'
import contentGuardHandler from '@/use/contentGuardHandler'
import router from '@/router'
import vabiHandler from '@/use/vabiHandler.js'
import toastHandler from '@/use/toastHandler'

export default {
  setup() {
    const store = useStore()
    const useContentGuard = contentGuardHandler()
    const useVabi = vabiHandler()
    const useToast = toastHandler()

    // handlers
    const {
      records,
      fields,
      getRecords,
      loadHandler,
      showAdd,
      showEdit,
      addForm,
      addRecord,
      deleteRecord,
      openEdit,
      editForm,
      closeEdit,
      openAdd,
      closeAdd,
      editRecord,
      duplicateRecord,
      exportRecord,
    } = surveyHandler()

    const backgroundColor = computed(() => store.getters['general/getStyle']('background'))

    function tableAction(payload) {
      router.push({
        name: 'survey',
        params: { objectId: payload['object_id'] },
      })
    }

    function onEnter() {
      console.log('')
    }

    const openFilePicker = (type) => {
      const fileTypeSelector = {
        woningtool: 'application/json',
        monitor: 'text/xml',
      }

      const fileInput = document.createElement('input')
      fileInput.type = 'file'
      fileInput.accept = fileTypeSelector[type]

      const handleFileSelection = (event) => {
        const file = event.target.files[0]
        processFile(file, type) // pass the type to processFile
      }

      fileInput.addEventListener('change', handleFileSelection)
      fileInput.click()
    }

    const processFile = (file, type) => {
      const reader = new FileReader()

      reader.onload = (event) => {
        if (type === 'woningtool') {
          addForm.sync(JSON.parse(event.target.result))
        } else if (type === 'monitor') {
          try {
            const parsedFile = useVabi.parse(event.target.result)
            Object.assign(addForm.data.value, parsedFile)
            addForm.update_errors()
          } catch (error) {
            useToast.addToast('Kan monitoringsbestand niet inlezen', 'danger')
            console.log(error)
          }
        }
      }

      reader.readAsText(file)
    }

    onMounted(() => {
      getRecords()
    })

    return {
      backgroundColor,
      fields,
      records,
      showAdd,
      showEdit,
      addForm,
      loadHandler,
      tableAction,
      addRecord,
      deleteRecord,
      openEdit,
      editForm,
      closeEdit,
      openAdd,
      editRecord,
      duplicateRecord,
      closeAdd,
      useContentGuard,
      exportRecord,
      openFilePicker,
      onEnter,
    }
  },
}
</script>
