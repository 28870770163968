import { computed, ref } from 'vue'
import useValidate from '@/use/validationHandler'
import dataChangeHandler from '@/use/dataChangeHandler'

export default (useMaatregelen) => {
  const data = ref({
    rentepercentage: '0',
    looptijd: 20,
    anders_financieren: '0',
  })

  const errors = ref([])

  const fields = {
    rentepercentageField: {
      label: 'Rentepercentage',
      type: 'percentage',
      key: 'rentepercentage',
      required: true,
      unit: '%',
    },
    looptijdField: { label: 'Looptijd jaren', type: 'number', key: 'looptijd', required: true },
    andersFinancierenField: {
      label: 'Anders financieren',
      type: 'number',
      key: 'anders_financieren',
      required: true,
      unit: '€/maand',
    },
  }
  const loading = false
  const validator = useValidate()
  const dataMonitor = dataChangeHandler()

  function PMT(ir, np, pv, fv, type) {
    /*
     * ir   - interest rate per month
     * np   - number of periods (months)
     * pv   - present value
     * fv   - future value
     * type - when the payments are due:
     *        0: end of the period, e.g. end of month (default)
     *        1: beginning of period
     */
    var pmt, pvif

    fv || (fv = 0)
    type || (type = 0)

    // if (ir === 0) return -(pv + fv) / np
    if (ir === 0) return (pv + fv) / np

    pvif = Math.pow(1 + ir, np)
    pmt = (ir * (pv * pvif + fv)) / (pvif - 1)

    if (type === 1) pmt /= 1 + ir

    return pmt
  }

  const bedrag = computed(() => {
    return useMaatregelen.costs.value.toaal_investering
  })

  const maandlasten = computed(() => {
    const l_rentepercentage = Number(data.value.rentepercentage)
    const l_looptijd = Number(data.value.looptijd)
    const l_bedrag = Number(bedrag.value)

    if (l_looptijd && l_bedrag) {
      return PMT(l_rentepercentage / 100, l_looptijd, l_bedrag) / 12
    }
    return 0
  })

  const maanlasten_totaal = computed(() => {
    return Number(data.value.anders_financieren) + maandlasten.value
  })

  function load(payload) {
    Object.keys(payload).forEach((element) => {
      if (element in data.value) {
        data.value[element] = payload[element]
      }
    })
    dataMonitor.sync(data.value)
    update_errors()
  }

  function validate(event, type, rules, key) {
    const input = event.target.value

    // validatorguard
    if (validator.validate(input, type)) {
      data.value[key] = input

      // update errors
      update_errors()
    } else {
      if (data.value[key] != undefined) {
        event.target.value = data.value[key]
      } else {
        event.target.value = null
      }
    }
  }

  function update_errors() {
    let error_list = []
    Object.keys(fields).forEach((fieldKey) => {
      const field = fields[fieldKey]
      if (field.required) {
        const fieldValue = data.value[field.key]

        const dependent_show_field_key = field.dependent_show_field_key
        const dependent_show_values = field.dependent_show_values

        // if field is dependent
        if (dependent_show_field_key) {
          const dependent_field_value = data.value[dependent_show_field_key]

          // check if dependancy is active
          if (dependent_show_values.includes(dependent_field_value)) {
            if (fieldValue) {
              // pass
            } else {
              error_list.push({
                message: `${field.label} is niet ingevuld`,
              })
            }
          } else {
            // pass if dependancy is not acive
          }
        } else {
          if (fieldValue) {
            // pass
          } else {
            error_list.push({
              message: `${field.label} is niet ingevuld`,
            })
          }
        }
      }
    })
    errors.value = error_list
  }

  return {
    maandlasten,
    validate,
    loading,
    data,
    bedrag,
    maanlasten_totaal,
    load,
    fields,
    errors,
    dataMonitor,
  }
}
