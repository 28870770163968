<template>
  <span>
    <p class="text-lg text-slate-500 mb-2">Los de volgende problemen op om deze pagina weer te geven</p>
    <div class="mb-2" v-for="(value, key) in handler.allErrors.value" :key="key">
      <p v-if="handler.allErrors.value[key].errors.length > 0" class="text-lg text-slate-500 mb-2">
        {{ handler.allErrors.value[key].label }}
      </p>
      <div class="ml-4 text-red-500" v-for="(error, index) in handler.allErrors.value[key].errors" :key="index">
        - {{ error.message }}
      </div>
    </div>
  </span>
</template>

<script>
export default {
  props: {
    handler: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
