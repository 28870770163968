import { ref, watchEffect } from 'vue'
import pdokHandler from '@/use/pdokHandler'

export default () => {
  // data
  let searchInput = ref('')
  let searchResult = ref([])
  let chosen = ref(false)
  const { suggest, lookup } = pdokHandler()

  // watcher
  watchEffect(() => {
    onInput()
  })

  function onInput() {
    if (searchInput.value.length > 0) {
      suggest(searchInput.value).then((response) => {
        searchResult.value = response.response.docs
      })
    }
  }

  function setSearchInput(payload) {
    searchInput.value = payload
  }

  function setSearchResult(payload) {
    searchResult.value = payload
  }

  function reset() {
    searchInput.value = ''
    searchResult.value = []
  }

  function setInput(event) {
    const payload = event.target.value
    searchInput.value = payload
  }

  return {
    searchInput,
    searchResult,
    lookup,
    setSearchInput,
    setSearchResult,
    chosen,
    reset,
    setInput,
  }
}
