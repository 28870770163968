<template>
  <div class="flex flex-col h-full">
    <!-- buttons -->
    <div>
      <ul class="flex flex-wrap -mb-px justify-between">
        <span class="flex flex-wrap">
          <li
            v-for="tab in activeTabs"
            :key="tab.id"
            class="mr-2 my-2 text-sm text-center shadow-md"
            :class="[
              tab.disabled ? 'text-slate-400 cursor-not-allowed' : 'text-slate-500 hover:bg-white',
              handler.selected.value === tab.id ? 'bg-white border-solid border-b-2 border-sky-700' : '',
            ]"
          >
            <a
              @click="tab.disabled ? $event.preventDefault() : handler.onSelect(tab.id)"
              class="inline-block p-3"
              :class="tab.disabled ? 'cursor-not-allowed' : 'cursor-pointer'"
            >
              {{ tab.label }}
              <featherIcon
                v-if="tab.required"
                class="w-2 h-2 ml-2 inline-flex fill-sky-300 text-sky-300"
                icon="CircleIcon"
              />
            </a>
          </li>
        </span>
        <slot name="button"></slot>
      </ul>
    </div>
    <div class="h-full min-h-0">
      <template v-for="tab in handler.tabs.value" :key="tab.id">
        <div class="h-full" v-if="tab.id === handler.selected.value">
          <slot v-if="!loading" :name="`panel-${tab.id}`" />
          <p v-else class="p-2"><baseLoader :loading="loading" class="mx-auto my-auto" /></p>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { watch, computed } from 'vue'

export default {
  props: {
    handler: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['changed'],
  setup(props, { emit }) {
    watch(props.handler.selected, (target, source) => {
      emit('changed', { target, source })
    })

    const activeTabs = computed(() => {
      return props.handler.tabs.value.filter((tab) => tab.active)
    })

    return {
      activeTabs,
    }
  },
}
</script>
