export default () => {
  function dateFromEpochDay(value) {
    if (value) {
      const date = new Date(value)
      const dateFormatted = `${`0${date.getDate()}`.slice(-2)}-${`0${date.getMonth() + 1}`.slice(
        -2
      )}-${date.getFullYear()}`
      return dateFormatted
    }
    return 'No date'
  }

  function toCurrencyRound(value) {
    if (typeof value !== 'number') {
      return value
    }
    const formatter = new Intl.NumberFormat('nl-NL', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    })
    return formatter.format(value)
  }

  function round(value) {
    if (typeof value !== 'number') {
      return value
    }
    const formatter = new Intl.NumberFormat('nl-NL', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    return formatter.format(value)
  }

  function round2(value) {
    if (typeof value !== 'number') {
      return value
    }
    const formatter = new Intl.NumberFormat('nl-NL', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    })
    return formatter.format(value)
  }

  function round2en(value) {
    if (typeof value !== 'number') {
      return value
    }
    const formatter = new Intl.NumberFormat('en-IN', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
      useGrouping: false,
    })
    return formatter.format(value)
  }

  function roundDynamic(value) {
    if (typeof value !== 'number') {
      return value
    }

    let maximumFractionDigitsStore = 0

    if (Math.abs(value) < 1) {
      maximumFractionDigitsStore = 3
    } else if (Math.abs(value) < 10) {
      maximumFractionDigitsStore = 2
    }

    const formatter = new Intl.NumberFormat('nl-NL', {
      minimumFractionDigits: 0,
      maximumFractionDigits: maximumFractionDigitsStore,
    })
    return formatter.format(value)
  }

  function dateFromTimestamp(value) {
    if (value) {
      let dateStr = value
      let date = new Date(dateStr)
      let day = date.getUTCDate().toString().padStart(2, '0') // Ensure the day has two digits
      let month = (date.getUTCMonth() + 1).toString().padStart(2, '0') // Ensure the month has two digits
      let year = date.getUTCFullYear()
      let formattedDate = `${day}-${month}-${year}`

      return formattedDate
    }
    return '-'
  }

  function filter(value, type) {
    const selector = {
      roundDynamic: roundDynamic(value),
      currencyRound: toCurrencyRound(value),
      round: round(value),
      round2: round2(value),
      round2en: round2en(value),
      dateFromTimestamp: dateFromTimestamp(value),
      dateFromEpochDay: dateFromEpochDay(value),
    }

    return type ? selector[type] : value
  }

  return {
    dateFromEpochDay,
    toCurrencyRound,
    round,
    round2,
    filter,
  }
}
