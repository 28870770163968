<template>
  <baseModal
    v-if="handler.show.value"
    @close="handler.close()"
    @enter="handler.use()"
    :title="`${$translate('Calculate')}`"
    size="s"
  >
    <span class="w-full">
      <span class="flex flex-row my-1">
        <div class="basis-2/5">
          <label class="block text-gray-500 md:text-right mb-1 md:mb-0 pr-4 py-2 px-4 text-sm">Lengte</label>
        </div>
        <div class="basis-3/5 flex items-center relative">
          <input
            class="bg-white appearance-none border border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-400"
            type="text"
            :value="handler.data.value.lengte"
            @input="handler.validate($event, 'number', [], 'lengte')"
          />
          <div class="units absolute right-3">
            <span class="units-value text-gray-400 text-sm rounded-xl p-1" id="unitsValue">m</span>
          </div>
        </div>
      </span>

      <span class="flex flex-row my-1">
        <div class="basis-2/5">
          <label class="block text-gray-500 md:text-right mb-1 md:mb-0 pr-4 py-2 px-4 text-sm">Breedte</label>
        </div>
        <div class="basis-3/5 flex items-center relative">
          <input
            class="bg-white appearance-none border border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-400"
            type="text"
            :value="handler.data.value.breedte"
            @input="handler.validate($event, 'number', [], 'breedte')"
          />
          <div class="units absolute right-3">
            <span class="units-value text-gray-400 text-sm rounded-xl p-1" id="unitsValue">m</span>
          </div>
        </div>
      </span>

      <span class="flex flex-row my-1">
        <div class="basis-2/5">
          <label class="block text-gray-500 md:text-right mb-1 md:mb-0 pr-4 py-2 px-4 text-sm">Oppervlakte (m2)</label>
        </div>
        <div class="basis-3/5 flex items-center relative">
          <input
            disabled
            class="bg-gray-200 appearance-none border border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-400"
            type="text"
            :value="handler.oppervlakte.value"
          />
          <div class="units absolute right-3">
            <span class="units-value text-gray-400 text-sm rounded-xl p-1" id="unitsValue">m²</span>
          </div>
        </div>
      </span>
    </span>

    <template v-slot:buttons>
      <baseButton @click="handler.use()" class="mr-2">{{ $translate('Use') }}</baseButton>
    </template>
  </baseModal>
</template>

<script>
export default {
  props: {
    handler: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
