<template>
  <baseButton
    :variant="variant"
    :size="size"
    :loading="loading"
    :disabled="disabled"
    @action="toggle()"
    class="relative"
  >
    <!-- loading spinner -->
    <span v-if="loading">
      <svg
        :class="spinnerSizing"
        class="animate-spin"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </span>

    <span v-else class="flex flex-row">
      <p>{{ label }}</p>
      <ChevronDownIcon class="h-3 w-3 ml-2 my-auto" />
    </span>

    <div
      id="dropdown"
      v-if="show"
      class="absolute z-10 right-0 top-8 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700"
    >
      <slot></slot>
    </div>
  </baseButton>
</template>

<script>
import { ref } from 'vue'
import { ChevronDownIcon } from '@heroicons/vue/outline'

export default {
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
    size: {
      type: String,
      default: 'normal',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
  },
  emits: ['action'],
  setup() {
    let show = ref(false)

    function toggle() {
      show.value = !show.value
    }

    return {
      show,
      toggle,
    }
  },
  components: {
    ChevronDownIcon,
  },
}
</script>
