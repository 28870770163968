<template>
  <div class="flex flex-row justify-between">
    <p class="text-lg text-slate-500">{{ title }}</p>
    <baseButton v-if="!disabled && syncHandler" size="small" @action="onSync()" class="ml-2">Overnemen</baseButton>
  </div>
  <baseForm :disabled="disabled" :handler="handler" />
  <confimationModal :handler="confirm" />
</template>

<script>
import confirmationHandler from '@/use/confirmationHandler'
import confimationModal from '@/components/extended/confirmationModal.vue'

export default {
  props: {
    handler: {
      type: Object,
      default: () => {},
    },
    syncHandler: {
      type: Object,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    // confirmationhandler
    const confirm = confirmationHandler()

    function sync() {
      const test = JSON.parse(JSON.stringify(props.syncHandler.data.value))
      props.handler.sync(test)
    }

    async function onSync() {
      const ok = await confirm.open({
        title: 'Huidige installaties overnemen?',
        message: 'Let op, reeds ingevoerde gegevens worden overschreven',
      })
      if (ok) {
        sync()
      }
    }

    return {
      sync,
      onSync,
      confirm,
    }
  },
  components: {
    confimationModal,
  },
}
</script>
