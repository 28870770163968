<template>
  <span>
    <!-- rows -->
    <div class="flex flex-row">
      <!-- sync button -->
      <baseButton v-if="!disabled && syncHandler" size="small" @action="onSync()" class="my-2"> Overnemen </baseButton>
    </div>

    <!-- klimaatzones -->
    <div
      class="flex flex-row w-full text-slate-500"
      v-for="(klimaatzone, klimaatzoneIndex) in handler.data.value"
      :key="klimaatzoneIndex"
      draggable="true"
      @dragstart="useKlimaatzoneDrag.dragStartKlimaatzone(klimaatzoneIndex)"
      @dragenter="useKlimaatzoneDrag.dragEnterKlimaatzone(klimaatzoneIndex)"
      @dragend="useKlimaatzoneDrag.dragEndKlimaatzone"
      @dragover.prevent
    >
      <base-collapse class="mb-2 w-full">
        <!-- header -->
        <template v-slot:header>
          <div class="flex flex-row">
            <span class="mr-2 font-semibold text-lg">{{ klimaatzone.naam }}</span>
            <baseButton
              v-if="!disabled"
              @action="handler.openEditKlimaatZone(klimaatzone, klimaatzoneIndex, basic)"
              size="small"
              class="my-0 mr-2"
              variant="transparent"
            >
              <PencilIcon class="h-4 w-4 stroke-slate-500" />
            </baseButton>
            <baseButton
              v-if="!disabled && !basic"
              @action="handler.duplicateKlimaatzone(klimaatzoneIndex)"
              size="small"
              class="my-0 mr-2"
              variant="transparent"
            >
              <DuplicateIcon class="h-4 w-4" />
            </baseButton>
            <baseButton
              v-if="!disabled && !basic"
              @action="onRemoveKlimaatzone(klimaatzoneIndex)"
              size="small"
              class="my-0"
              variant="transparent"
            >
              <TrashIcon class="h-4 w-4" />
            </baseButton>
          </div>
        </template>
        <template v-slot:header-right>
          <div class="mr-2"></div>
        </template>

        <!-- body -->
        <template v-slot:body>
          <div>
            <div
              v-for="(bouwdeel, bouwdeelIndex) in handler.data.value[klimaatzoneIndex].bouwdelen"
              :key="bouwdeelIndex"
              class="my-2"
              draggable="true"
              @dragstart="useKlimaatzoneDrag.dragStartBouwdeel($event, klimaatzoneIndex, bouwdeelIndex)"
              @dragenter.prevent="useKlimaatzoneDrag.dragEnterBouwdeel(klimaatzoneIndex, bouwdeelIndex)"
              @dragend.prevent="useKlimaatzoneDrag.dragEndBouwdeel"
              @dragover.prevent
              @dragleave="useKlimaatzoneDrag.dragLeaveBouwdeel"
              :data-bouwdeel-index="bouwdeelIndex"
            >
              <!-- bouwdeel -->
              <div
                :class="disabledStyleTitle"
                class="flex flex-row border border-gray-300 w-full p-2 rounded text-gray-700 text-sm hover:bg-blue-200"
              >
                <span class="basis-4/12 my-auto">
                  <div>
                    {{
                      `${bouwdeel.type} ${bouwdeelOmshrijving(bouwdeel)} - ${bouwdeel.isolatieniveau} Rc (m2K/W) - ${
                        bouwdeel.orientatie
                      }`
                    }}
                  </div>
                </span>
                <span class="basis-3/12 my-auto">
                  <!-- <div class="flex justify-end">
              <div>{{ `${bouwdeel.orientatie}` }}</div>
            </div> -->
                </span>
                <span class="basis-3/12 my-auto">
                  <div class="flex justify-center">
                    <div>
                      <!-- <div>Oppervlakte:</div> -->
                      <div>
                        {{
                          `${useFilter.filter(bouwdeel.oppervlakte, 'round2')} (${useFilter.filter(
                            getNettoOppervlak(bouwdeel),
                            'round2'
                          )}) m2`
                        }}
                      </div>
                      <!-- <div>{{ `${useFilter.filter(getNettoOppervlak(bouwdeel), 'round2')} m2` }}</div> -->
                    </div>
                  </div>
                </span>
                <span class="basis-2/12 my-auto">
                  <div class="flex justify-end">
                    <baseButton
                      v-if="!disabled"
                      @action="handler.openEdit(bouwdeel, klimaatzoneIndex, bouwdeelIndex)"
                      size="small"
                      class="my-0 mr-2"
                    >
                      <PencilIcon class="h-4 w-4" />
                    </baseButton>
                    <baseButton
                      v-if="!disabled"
                      @action="handler.duplicateBouwdeel(klimaatzoneIndex, bouwdeelIndex)"
                      size="small"
                      class="my-0 mr-2"
                    >
                      <DuplicateIcon class="h-4 w-4" />
                    </baseButton>
                    <baseButton
                      v-if="!disabled"
                      @action="handler.removeBouwdeel(klimaatzoneIndex, bouwdeelIndex)"
                      size="small"
                      class="my-0"
                      variant="danger"
                    >
                      <TrashIcon class="h-4 w-4" />
                    </baseButton>
                  </div>
                </span>
              </div>

              <!-- deelvlakken -->
              <div v-for="(deelvlak, index) in bouwdeel.deelvlak" :key="index" class="flex flex-row">
                <div class="ml-6"></div>
                <div
                  :class="disabledStyle"
                  class="flex flex-row border-x border-b border-gray-300 w-full p-1 rounded bg-white text-gray-700 text-xs hover:bg-gray-50"
                >
                  <span class="basis-4/12 my-auto">
                    <div>{{ `${deelvlak.soort}` }}</div>
                  </span>
                  <span class="basis-3/12 my-auto">
                    <div></div>
                  </span>
                  <span class="basis-3/12 my-auto">
                    <!-- <div>Belemmering: {{ deelvlak.belemmering }}</div> -->
                    <div class="flex justify-center">
                      <div>
                        <!-- <div>Oppervlakte:</div> -->
                        <div class="mr-3">{{ `${deelvlak.oppervlakte} m2` }}</div>
                      </div>
                    </div>
                  </span>
                  <span class="basis-2/12 my-auto">
                    <div class="flex justify-end"></div>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <!-- add button -->
          <baseButton v-if="!disabled" size="small" @action="handler.openAdd(klimaatzoneIndex)" class="my-2">
            + bouwdeel
          </baseButton>

          <!-- add bouwdeel -->
          <baseModal
            v-if="handler.showAddModal.value"
            @close="handler.closeAdd()"
            :title="`${$translate('Add')}`"
            size="l"
          >
            <baseForm :handler="handler.addBouwdeelForm" />
            <template v-slot:buttons>
              <baseButton
                :disabled="handler.addBouwdeelForm.errors.value.length > 0"
                @action="handler.addBouwdeel()"
                class="mr-2"
                >{{ $translate('Add') }}</baseButton
              >
            </template>
          </baseModal>

          <!-- edit bouwdeel -->
          <baseModal
            v-if="handler.showEditModal.value"
            @close="handler.closeEdit()"
            :title="`${$translate('Edit')}`"
            size="l"
          >
            <baseForm :handler="handler.editBouwdeelForm" />
            <template v-slot:buttons>
              <baseButton
                :disabled="handler.editBouwdeelForm.errors.value.length > 0"
                @action="handler.editBouwdeel(index)"
                class="mr-2"
                >{{ $translate('Edit') }}</baseButton
              >
            </template>
          </baseModal>

          <!-- confirmer -->
          <confimationModal :handler="confirm" />
        </template>
      </base-collapse>
    </div>

    <!-- add button -->
    <baseButton v-if="!disabled && !basic" size="small" @action="handler.openAddKlimaatZone()" class="my-2"
      >+ klimaatzone</baseButton
    >

    <!-- add klimaatzone modal -->
    <baseModal
      v-if="handler.klimaatZoneShowAddModal.value"
      @close="handler.closeAddKlimaatzone()"
      :title="`${$translate('Add')}`"
      size="l"
    >
      <baseForm :handler="handler.addKlimaatZoneForm" />
      <template v-slot:buttons>
        <baseButton
          :disabled="handler.addKlimaatZoneForm.errors.value.length > 0"
          @action="handler.addKlimaatZone()"
          class="mr-2"
          >{{ $translate('Add') }}</baseButton
        >
      </template>
    </baseModal>

    <!-- add klimaatzone modal -->
    <baseModal
      v-if="handler.klimaatZoneShowEditModal.value"
      @close="handler.closeEditKlimaatzone()"
      :title="`${$translate('Edit')}`"
      size="l"
    >
      <baseForm :handler="handler.editKlimaatZoneForm" />
      <template v-slot:buttons>
        <baseButton
          :disabled="handler.editKlimaatZoneForm.errors.value.length > 0"
          @action="handler.editKlimaatZone()"
          class="mr-2"
          >{{ $translate('Edit') }}</baseButton
        >
      </template>
    </baseModal>

    <!-- confirmer -->
    <confimationModal :handler="confirm" />
  </span>
</template>

<script>
import { TrashIcon, DuplicateIcon, PencilIcon } from '@heroicons/vue/outline'
import { computed, onMounted, onUnmounted } from 'vue'
import confirmationHandler from '@/use/confirmationHandler'
import confimationModal from '@/components/extended/confirmationModal.vue'
import filterHandler from '@/use/filterHandler'
import useKlimaatzonesDraggable from '@/use/useKlimaatzonesDraggable copy'
import contentGuardHandler from '@/use/contentGuardHandler'

export default {
  props: {
    handler: {
      type: Object,
      default: () => {},
    },
    syncHandler: {
      type: Object,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    basic: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const confirm = confirmationHandler()
    const useFilter = filterHandler()
    const useKlimaatzoneDrag = useKlimaatzonesDraggable()
    const useContentGuard = contentGuardHandler()

    const disabledStyle = computed(() => {
      if (props.disabled) {
        return 'bg-gray-100'
      }
      return ''
    })

    const disabledStyleTitle = computed(() => {
      if (props.disabled) {
        return 'bg-gray-200 hover:bg-gray-300'
      }
      return 'bg-blue-100'
    })

    function sync() {
      const test = JSON.parse(JSON.stringify(props.syncHandler.data.value))
      props.handler.syncBouwdeel(test)
    }

    function bouwdeelOmshrijving(bouwdeel) {
      if (bouwdeel.omschrijving) {
        return `(${bouwdeel.omschrijving})`
      } else {
        return ''
      }
    }

    function getNettoOppervlak(bouwdeel) {
      const bouwdeel_opp_bruto = bouwdeel.oppervlakte
      const deelvlakken_opp = props.handler.sumDeelvlak(bouwdeel)
      const bouwdeel_opp_netto = Math.max(bouwdeel_opp_bruto - deelvlakken_opp, 0)
      return bouwdeel_opp_netto
    }

    async function onSync() {
      const ok = await confirm.open({
        title: 'Huidige thermische schil overnemen?',
        message: 'Let op, reeds ingevoerde gegevens worden overschreven',
      })
      if (ok) {
        sync()
      }
    }

    async function onRemoveKlimaatzone(klimaatzoneIndex) {
      const ok = await confirm.open({
        title: 'Klimaatzone verwijderen?',
        message: 'Let op, reeds ingevoerde gegevens worden permanent verwijderd',
      })
      if (ok) {
        props.handler.removeKlimaatzone(klimaatzoneIndex)
      }
    }

    onMounted(() => {
      useKlimaatzoneDrag.load(props.handler.data.value)
      window.addEventListener('dragend', useKlimaatzoneDrag.dragEnd)
    })

    onUnmounted(() => {
      window.removeEventListener('dragend', useKlimaatzoneDrag.dragEnd)
    })

    return {
      disabledStyle,
      disabledStyleTitle,
      sync,
      onSync,
      onRemoveKlimaatzone,
      confirm,
      bouwdeelOmshrijving,
      getNettoOppervlak,
      useFilter,
      useKlimaatzoneDrag,
      useContentGuard,
    }
  },
  components: {
    TrashIcon,
    DuplicateIcon,
    PencilIcon,
    confimationModal,
  },
}
</script>
