import { ref } from 'vue'

let toasts = ref([])

function addToast(message, type) {
  const id = Math.floor(Math.random() * 10000)
  toasts.value.push({ id, message, type })
  setTimeout(() => removeToast(id), 3000)
}

function removeToast(id) {
  toasts.value = toasts.value.filter((t) => t.id !== id)
}

export default function useToast() {
  return { toasts, addToast, removeToast }
}
