export default () => {
  const state = {
    settings: {
      colourMode: 'light',
      pageTitle: 'text-xl my-8 text-slate-500',
      colours: {
        light: {
          background: {
            background: 'bg-slate-50',
            primary: 'bg-sky-700',
            primary_pastel: 'bg-sky-100',
            warning: 'bg-yellow-500',
            success: 'bg-green-700',
            success_pastel: 'bg-green-100',
            danger: 'bg-red-600',
            danger_pastel: 'bg-red-200',
            transparent: 'bg-transparent',
            surface: 'bg-white',
            disabled: 'bg-gray-300',
            neutral: 'bg-slate-50',
            toned: 'bg-gray-100',
            bert: 'bg-none',
            'A++++': 'bg-[#33a357]',
            'A+++': 'bg-[#33a357]',
            'A++': 'bg-[#33a357]',
            'A+': 'bg-[#33a357]',
            A: 'bg-A',
            B: 'bg-B',
            C: 'bg-C',
            D: 'bg-D',
            E: 'bg-E',
            F: 'bg-F',
            G: 'bg-G',
          },
          text: {
            primary: 'text-white',
            surface: 'text-black',
            danger: 'text-white',
            warning: 'text-white',
            success: 'text-white',
            disabled: 'text-gray-600',
            neutral: 'text-gray-600',
            toned: 'text-gray-600',
            'A++++': 'text-white',
            'A+++': 'text-white',
            'A++': 'text-white',
            'A+': 'text-white',
            A: 'text-white',
            B: 'text-white',
            C: 'text-white',
            D: 'text-white',
            E: 'text-white',
            F: 'text-white',
            G: 'text-white',
          },
          hover: {
            neutral: 'hover:bg-white',
            disabled: '',
          },
        },
        dark: {
          background: {
            background: 'bg-slate-50',
            primary: 'bg-sky-700',
            danger: 'bg-red-600',
            transparent: 'bg-transparent',
            surface: 'bg-white',
            disabled: 'bg-gray-300',
            neutral: 'bg-slate-50',
            toned: 'bg-gray-100',
            'A++++': 'bg-[#33a357]',
            'A+++': 'bg-[#33a357]',
            'A++': 'bg-[#33a357]',
            'A+': 'bg-[#33a357]',
            A: 'bg-[#33a357]',
            B: 'bg-[#79b752]',
            C: 'bg-[#c3d545]',
            D: 'bg-[#fff12c]',
            E: 'bg-[#edb731]',
            F: 'bg-[#d66f2c]',
            G: 'bg-[#cc232a]',
          },
          text: {
            primary: 'text-white',
            surface: 'text-black',
            danger: 'text-white',
            disabled: 'text-gray-600',
            neutral: 'text-gray-600',
            toned: 'text-gray-600',
          },
          hover: {
            neutral: 'hover:bg-white',
            disabled: '',
          },
        },
      },
    },
    navBar: {
      title: 'Woningtool',
      items: [
        // {
        //   title: '',
        //   action: {
        //     type: 'storeMutation',
        //     target: 'panoramaMap/toggleModal',
        //   },
        // },
        {
          title: 'Uitloggen',
          action: {
            type: 'logOut',
          },
        },
      ],
    },
  }

  function getStyle(name) {
    return state.settings.colours[state.settings.colourMode][name]
  }

  function getBackgroundColor(name) {
    return state.settings.colours[state.settings.colourMode].background[name]
  }

  function getTextColor(name) {
    return state.settings.colours[state.settings.colourMode].text[name]
  }

  function getHover(name) {
    return state.settings.colours[state.settings.colourMode].hover[name]
  }

  return {
    getStyle,
    getBackgroundColor,
    getTextColor,
    getHover,
  }
}
