import { ref } from 'vue'
import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'
import formHandler from '@/use/formHandler'
import { useAuth0 } from '@auth0/auth0-vue'

const attachments = ref([])
const loadHandler = loadingHandler()
const inputDisabled = ref(false)

export default () => {
  const auth = useAuth0()
  const useApi = apiHandler()

  const organisationDetailFields = [
    {
      label: 'Bijlage',
      key: 'attachment',
      type: 'file',
      accept: '.docx',
    },
  ]

  const attachmentForm = formHandler()
  attachmentForm.create({ type: 'add', fields: organisationDetailFields, data: {} })

  async function list(payload) {
    loadHandler.setLoadingState('list', true)
    try {
      const response = await useApi.request('post', 'attachments', 'get_by', payload)
      attachments.value = response.data.data
    } finally {
      loadHandler.setLoadingState('list', false)
    }
  }

  function uploadAttachment(file, attachable_id, attachable_type) {
    loadHandler.setLoadingState('upload_attachment', true)
    let payload = new FormData()
    payload.append('file', file) // Assuming the file input is named 'attachment'
    payload.append('auth0_user_id', auth.user.value.sub)
    payload.append('username', auth.user.value.name)
    payload.append('attachable_id', attachable_id)
    payload.append('attachable_type', attachable_type)
    const headers = {
      'Content-Type': 'multipart/form-data',
    }
    useApi
      .request('post', 'attachments', 'upload', payload, headers)
      .then(() => {
        loadHandler.setLoadingState('upload_attachment', false)

        const refreshPayload = {
          attachable_id: attachable_id,
          attachable_type: attachable_type,
        }

        list(refreshPayload)
        // Optionally, refresh the list of attachments or provide user feedback
      })
      .catch((error) => {
        console.error('Failed to upload attachment', error)
        loadHandler.setLoadingState('upload_attachment', false)
      })
  }

  function downloadAttachment(attachmentId) {
    loadHandler.setLoadingState('download_attachment', true)

    useApi
      .download_attachment(attachmentId)
      .then((result) => {
        const filename = result.headers['x-filename']
        if (filename) {
          // Create a temporary anchor element
          const tempLink = document.createElement('a')

          // Set the download attribute with the desired filename
          tempLink.download = filename

          // Set the href attribute with the Object URL
          tempLink.href = URL.createObjectURL(result.data)

          // Append the temporary anchor element to the document
          document.body.appendChild(tempLink)

          // Trigger the click event to start the download
          tempLink.click()

          // Remove the temporary anchor element from the document
          document.body.removeChild(tempLink)
        } else {
          // Handle the case where a filename is not provided
        }
      })
      .finally(() => {
        loadHandler.setLoadingState('download_attachment', false)
      })
  }

  async function deleteAttachment(attachment, attachableId, attachableType) {
    loadHandler.setLoadingState('delete', true)
    const payload = {
      attachable_id: attachableId,
      attachment_id: attachment.object_id,
    }
    try {
      await useApi.request('post', 'attachments', 'delete', payload) // Adjust HTTP method and endpoint as necessary
      const refreshPayload = {
        attachable_id: attachableId,
        attachable_type: attachableType,
      }

      list(refreshPayload)
    } catch (error) {
      console.error('Failed to delete attachment:', error)
    } finally {
      loadHandler.setLoadingState('delete', false)
    }
  }

  return {
    attachments,
    list,
    uploadAttachment,
    downloadAttachment,
    inputDisabled,
    loadHandler,
    organisationDetailFields,
    attachmentForm,
    deleteAttachment,
  }
}
