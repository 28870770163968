<template>
  <div class="flex flex-row cursor-pointer" @click.stop="action()">
    <div class="my-auto">Openen</div>
    <div class="my-auto"><featherIcon class="w-4 h-4 m-2" icon="ExternalLinkIcon" /></div>
  </div>
</template>

<script>
export default {
  name: 'listLink',
  props: {
    lat: {
      type: String,
      default: '0',
    },
    lng: {
      type: String,
      default: '0',
    },
  },
  setup(props) {
    function action() {
      const url = `https://omgevingswet.overheid.nl/regels-op-de-kaart/viewer/documenten/gemeente?regelsandere=regels&locatie-x=${props.lat}&locatie-y=${props.lng}&locatie-stelsel=RD&no-zoom=false`
      window.open(url, '_blank')
    }
    return {
      action,
    }
  },
}
</script>
