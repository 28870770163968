/* eslint-disable no-unused-vars */
import { computed, ref, reactive, watch, toRefs } from 'vue'
import useValidate from '@/use/validationHandler'
import lookupHandler from '@/use/lookupHandler'
import filterHandler from '@/use/filterHandler'
import dataChangeHandler from '@/use/dataChangeHandler'
import selectValues from '@/use/selects/values'

export default (forms, formulas, formulasNieuw, installatiesHuidig, installatiesNieuw) => {
  const data = ref([])
  const useLookup = lookupHandler()
  const useFilter = filterHandler()
  const dataMonitor = dataChangeHandler()

  // handlers
  const validator = useValidate()

  function getUniqueValues(arrays) {
    const flattenedArray = [].concat(...arrays)
    const uniqueArray = Array.from(new Set(flattenedArray))
    return uniqueArray
  }

  const uniekeKlimaatzones = computed(() => {
    const zonenamen_huidig = forms.form_klimaatzones_huidig.data.value.map((object) => object.naam)
    const zonenamen_nieuw = forms.form_klimaatzones_nieuw.data.value.map((object) => object.naam)
    return getUniqueValues([zonenamen_huidig, zonenamen_nieuw])
  })

  function summarizeThermischeSchil(bouwdelen) {
    let summary_bouwdelen = {}
    let summary_deelvlakken = {}

    bouwdelen.forEach((bouwdeel) => {
      const gevel_opp = Number(bouwdeel.oppervlakte)
      const gevel_type = bouwdeel.type
      const gevel_isolatieniveau = bouwdeel.isolatieniveau
      const deelvlakken = bouwdeel.deelvlak
      let totaal_opp_deelvlakken = 0

      // write deelvlakken
      if (deelvlakken) {
        deelvlakken.forEach((deelvlak) => {
          const deelvlak_type = deelvlak.soort
          const deelvlak_opp = Number(deelvlak.oppervlakte)
          totaal_opp_deelvlakken += deelvlak_opp

          // write deelvlakken
          if (!(deelvlak_type in summary_deelvlakken)) {
            summary_deelvlakken[deelvlak_type] = deelvlak_opp
          } else {
            summary_deelvlakken[deelvlak_type] += deelvlak_opp
          }
        })
      }

      const gevel_opp_netto = Math.max(gevel_opp - totaal_opp_deelvlakken, 0)

      // write bouwdelen
      if (!(gevel_type in summary_bouwdelen)) {
        summary_bouwdelen[gevel_type] = {}
        summary_bouwdelen[gevel_type][gevel_isolatieniveau] = gevel_opp_netto
      } else {
        if (!(gevel_isolatieniveau in summary_bouwdelen[gevel_type])) {
          summary_bouwdelen[gevel_type][gevel_isolatieniveau] = gevel_opp_netto
        } else {
          summary_bouwdelen[gevel_type][gevel_isolatieniveau] += gevel_opp_netto
        }
      }
    })

    return {
      summary_bouwdelen,
      summary_deelvlakken,
    }
  }

  function summarizeAfgiftesystemen(afgiftesystemen) {
    const afgifteSummary = {}

    afgiftesystemen.forEach((afgiftesysteem) => {
      const afgifte_type = afgiftesysteem.afgifte
      const sizeField = useLookup.afgifteUnit[afgifte_type].field
      const afgifte_size = Number(afgiftesysteem[sizeField])

      if (!Object.keys(afgifteSummary).includes(afgifte_type)) {
        afgifteSummary[afgifte_type] = afgifte_size
      } else {
        afgifteSummary[afgifte_type] += afgifte_size
      }
    })

    return afgifteSummary
  }

  function compareSummariesBouwdeel(a, b) {
    let comparison = {}

    // check all new keys
    Object.keys(b).forEach((type) => {
      Object.keys(b[type]).forEach((isolatieniveau) => {
        const b_opp = b[type][isolatieniveau]

        let change = 0

        // compare
        if (type in a) {
          if (isolatieniveau in a[type]) {
            const a_opp = a[type][isolatieniveau]
            const difference = Math.abs(a_opp - b_opp)
            if (difference !== 0) {
              change += b_opp > a_opp ? difference : -difference
            }
          } else {
            change += b_opp
          }
        } else {
          change += b_opp
        }

        // write change
        if (change !== 0) {
          if (!(type in comparison)) {
            comparison[type] = {}
            comparison[type][isolatieniveau] = change
          } else {
            if (!(isolatieniveau in comparison[type])) {
              comparison[type][isolatieniveau] = change
            } else {
              comparison[type][isolatieniveau] += change
            }
          }
        }
      })
    })

    // check if there are old keys not in new
    Object.keys(a).forEach((type) => {
      Object.keys(a[type]).forEach((isolatieniveau) => {
        let change = 0
        const a_opp = Number(a[type][isolatieniveau])

        // compare
        if (!(type in b)) {
          change = -a_opp
        } else {
          if (!(isolatieniveau in b[type])) {
            change = -a_opp
          }
        }

        // write change
        if (change !== 0) {
          if (!(type in comparison)) {
            comparison[type] = {}
            comparison[type][isolatieniveau] = change
          } else {
            if (!(isolatieniveau in comparison[type])) {
              comparison[type][isolatieniveau] = change
            } else {
              comparison[type][isolatieniveau] += change
            }
          }
        }
      })
    })

    return comparison
  }

  function compareSummariesDeelvlak(a, b) {
    let comparison = {}

    // check all new keys
    Object.keys(b).forEach((type) => {
      const b_opp = b[type]

      let change = 0

      // compare
      if (type in a) {
        const a_opp = a[type]
        const difference = Math.abs(a_opp - b_opp)
        if (difference !== 0) {
          change += b_opp > a_opp ? difference : -difference
        }
      } else {
        change += b_opp
      }

      // write change
      if (change !== 0) {
        if (!(type in comparison)) {
          comparison[type] = change
        } else {
          comparison[type] += change
        }
      }
    })

    // check if there are old keys not in new
    Object.keys(a).forEach((type) => {
      // compare
      if (!(type in b)) {
        const a_opp = Number(a[type])
        comparison[type] = -a_opp
      }
    })

    return comparison
  }

  function compareSummariesAfgiftesystemen(a, b) {
    let comparison = {}

    // ceck all new keys
    Object.keys(b).forEach((type) => {
      const b_opp = Number(b[type])
      let change = 0

      // compare
      if (type in a) {
        const a_opp = Number(a[type])
        const difference = Math.abs(a_opp - b_opp)
        if (difference !== 0) {
          change += b_opp > a_opp ? difference : -difference
        }
      } else {
        change += b_opp
      }

      // write change
      if (change !== 0) {
        if (!(type in comparison)) {
          comparison[type] = change
        } else {
          comparison[type] += change
        }
      }
    })

    // check if there are old keys not in new
    Object.keys(a).forEach((type) => {
      // compare
      if (!(type in b)) {
        const a_opp = Number(a[type])
        comparison[type] = -a_opp
      }
    })

    return comparison
  }

  function syncMaatregelen() {
    // verschil bouwdelen
    uniekeKlimaatzones.value.forEach((klimaatzoneNaam) => {
      Object.keys(verschilThermischeSchil.value.result[klimaatzoneNaam].comparison_bouwdelen).forEach((element) => {
        const isolatiewaarden = verschilThermischeSchil.value.result[klimaatzoneNaam].comparison_bouwdelen[element]
        Object.keys(isolatiewaarden).forEach((isolatiewaarde) => {
          const oppervlakte = isolatiewaarden[isolatiewaarde]
          const oppervlakte_abs = Math.abs(oppervlakte)

          const richting = oppervlakte > 0 ? 'toevoegen' : 'verwijderen'

          const oppervlakteFormatted = useFilter.filter(oppervlakte_abs, 'round2')
          const isolatiewaardeFormatted = useFilter.filter(isolatiewaarde, 'round2')
          const omschrijving = `${element}: ${oppervlakteFormatted} m2 isolatieniveau ${isolatiewaardeFormatted} m2K/W ${richting}`
          const categorie = selectValues.categorie_maatregelen[0].value

          addMaatregel(omschrijving, categorie, oppervlakte_abs.toFixed(2), 'm2')
        })
      })
    })

    // verschil deelvlakken
    uniekeKlimaatzones.value.forEach((klimaatzoneNaam) => {
      Object.keys(verschilThermischeSchil.value.result[klimaatzoneNaam].comparison_deelvlakken).forEach((element) => {
        const oppervlakte = verschilThermischeSchil.value.result[klimaatzoneNaam].comparison_deelvlakken[element]
        const oppervlakte_abs = Math.abs(oppervlakte)
        const richting = oppervlakte > 0 ? 'toevoegen' : 'verwijderen'

        addMaatregel(
          `${element}: ${useFilter.filter(oppervlakte_abs, 'round2')} m2 ${richting}`,
          selectValues.categorie_maatregelen[0].value,
          oppervlakte_abs.toFixed(2),
          'm2'
        )
      })
    })

    // verschil installaties
    const verschillen_installaties = verschilInstallaties.value
    Object.keys(verschillen_installaties).forEach((key) => {
      const { label, value } = verschillen_installaties[key]
      let categorie = selectValues.categorie_maatregelen[2].value

      if (key === 'zonnepanelen') {
        categorie = selectValues.categorie_maatregelen[3].value
        addMaatregel(`Verandering: ${label}: ${value}`, categorie, verschillen_installaties[key].aantal, 'stuk')
      } else if (key === 'ventilatie_natuurlijk') {
        categorie = selectValues.categorie_maatregelen[1].value
        addMaatregel(`Verandering: ${label}: ${value}`, categorie, 1, 'stuk')
      } else if (key.startsWith('ventilatie')) {
        categorie = selectValues.categorie_maatregelen[1].value
        addMaatregel(`Verandering: ${label}: ${value}`, categorie, value, 'm3')
      } else {
        addMaatregel(`Verandering: ${label}: ${value}`, categorie, 1, 'stuk')
      }
    })

    // verschil afgiftesystemen
    uniekeKlimaatzones.value.forEach((klimaatzoneNaam) => {
      const verschillen_afgiftesystemen = verschilAfgiftesystemen.value[klimaatzoneNaam]
      if (Object.keys(verschillen_afgiftesystemen)) {
        Object.keys(verschillen_afgiftesystemen).forEach((element) => {
          const oppervlakte = verschillen_afgiftesystemen[element]
          const oppervlakte_abs = Math.abs(oppervlakte)
          const richting = oppervlakte > 0 ? 'toevoegen' : 'verwijderen'

          addMaatregel(
            `${element}: ${useFilter.filter(oppervlakte_abs, 'round2')} ${
              useLookup.afgifteUnit[element].unit
            } ${richting}`,
            selectValues.categorie_maatregelen[2].value,
            oppervlakte_abs.toFixed(2),
            useLookup.afgifteUnit[element].unit
          )
        })
      }
    })
  }

  function addMaatregel(
    omschrijving = '',
    categorie = '',
    hoeveelheid = 0,
    eenheid = '',
    prijs = 0,
    kosten = 0,
    subsidie = 0,
    subsidie_soort = ''
  ) {
    const maatregel = reactive({
      omschrijving,
      categorie,
      hoeveelheid,
      eenheid,
      prijs,
      kosten,
      subsidie,
      subsidie_soort,
    })
    // const maatregel = reactive({
    //   omschrijving: '',
    //   categorie: '',
    //   hoeveelheid: 0,
    //   prijs: 0,
    //   kosten: 0,
    //   subsidie: 0,
    //   subsidie_soort: '',
    // })

    watch([() => maatregel.prijs, () => maatregel.hoeveelheid], ([newPrijs, newHoeveelheid]) => {
      maatregel.kosten = newPrijs * newHoeveelheid
    })
    data.value.push(maatregel)
  }

  function removeMaatregel(index) {
    data.value.splice(index, 1)
  }

  function transformArray(arr) {
    return arr.reduce((acc, item) => {
      acc[item.naam] = item
      return acc
    }, {})
  }

  const verschilThermischeSchil = computed(() => {
    const result = {}
    const klimaatzonesHuidig = forms.form_klimaatzones_huidig.data.value
    const klimaatzonesNieuw = forms.form_klimaatzones_nieuw.data.value
    const klimaatzonesHuidigSearchable = transformArray(klimaatzonesHuidig)
    const klimaatzonesNieuwSearchable = transformArray(klimaatzonesNieuw)

    uniekeKlimaatzones.value.forEach((klimaatzoneNaam) => {
      const klimaatZoneHuidig = klimaatzonesHuidigSearchable[klimaatzoneNaam]
      const klimaatZoneNieuw = klimaatzonesNieuwSearchable[klimaatzoneNaam]

      // checken of klimaatzone uberhaupt bestaat, anders add of delete

      let bouwdelenHuidig = []
      let bouwdelenNieuw = []

      if (klimaatZoneHuidig) {
        if ('bouwdelen' in klimaatZoneHuidig) {
          bouwdelenHuidig = klimaatZoneHuidig.bouwdelen
        }
      }

      if (klimaatZoneNieuw) {
        if ('bouwdelen' in klimaatZoneNieuw) {
          bouwdelenNieuw = klimaatZoneNieuw.bouwdelen
        }
      }

      const summary = summarizeThermischeSchil(bouwdelenHuidig)
      const summary_nieuw = summarizeThermischeSchil(bouwdelenNieuw)

      const comparison_bouwdelen = compareSummariesBouwdeel(summary.summary_bouwdelen, summary_nieuw.summary_bouwdelen)

      const comparison_deelvlakken = compareSummariesDeelvlak(
        summary.summary_deelvlakken,
        summary_nieuw.summary_deelvlakken
      )

      result[klimaatzoneNaam] = {
        comparison_bouwdelen,
        comparison_deelvlakken,
      }
    })

    return {
      result,
    }
  })

  const verschilInstallaties = computed(() => {
    const installaties_huidig = installatiesHuidig.data.value
    const installaties_nieuw = installatiesNieuw.data.value
    const installaties_huidig_fields = installatiesHuidig.fields.value
    const exceptions = ['warmteafgifte_beganegrond', 'warmteafgifte_verdieping']

    const deltas = {}

    // Helper function to compute the sum of productie for zonnepanelen
    const getSumProductie = (zonnepanelen) => {
      return zonnepanelen.reduce((sum, panel) => sum + Number(panel.productie), 0)
    }

    const getSumAantal = (zonnepanelen) => {
      return zonnepanelen.reduce((sum, panel) => sum + Number(panel.aantal), 0)
    }

    // Loop through the fields
    installaties_huidig_fields.forEach((field) => {
      const key = field.key
      const label = field.label

      if (!exceptions.includes(key)) {
        if (key === 'zonnepanelen') {
          const sum_huidig = getSumProductie(installaties_huidig[key])
          const sum_nieuw = getSumProductie(installaties_nieuw[key])
          const aantal_huidig = getSumAantal(installaties_huidig[key])
          const aantal_nieuw = getSumAantal(installaties_nieuw[key])

          if (sum_huidig !== sum_nieuw) {
            deltas[key] = {
              label: 'Opbrengst zonnepanelen',
              value: `${sum_nieuw} kWh`,
              aantal: aantal_nieuw - aantal_huidig,
            }
          }
        } else {
          const element_huidig = installaties_huidig[key]
          const element_nieuw = installaties_nieuw[key]

          if (element_nieuw != element_huidig) {
            deltas[key] = {
              label: label,
              value: element_nieuw,
            }
          }
        }
      }
    })

    return deltas
  })

  const verschilAfgiftesystemen = computed(() => {
    const result = {}
    const klimaatzonesHuidig = forms.form_klimaatzones_huidig.data.value
    const klimaatzonesNieuw = forms.form_klimaatzones_nieuw.data.value
    const klimaatzonesHuidigSearchable = transformArray(klimaatzonesHuidig)
    const klimaatzonesNieuwSearchable = transformArray(klimaatzonesNieuw)

    uniekeKlimaatzones.value.forEach((klimaatzoneNaam) => {
      const klimaatZoneHuidig = klimaatzonesHuidigSearchable[klimaatzoneNaam]
      const klimaatZoneNieuw = klimaatzonesNieuwSearchable[klimaatzoneNaam]

      // let bouwdelenHuidig = []
      // let bouwdelenNieuw = []

      // if ('bouwdelen' in klimaatZoneHuidig) {
      //   bouwdelenHuidig = klimaatZoneHuidig.bouwdelen
      // }

      // if ('bouwdelen' in klimaatZoneNieuw) {
      //   bouwdelenNieuw = klimaatZoneNieuw.bouwdelen
      // }

      const afgiftesystemen_huidig = klimaatZoneHuidig.warmteafgifte
      const afgiftesystemen_nieuw = klimaatZoneNieuw.warmteafgifte

      const summary_huidig = summarizeAfgiftesystemen(afgiftesystemen_huidig)
      const summary_nieuw = summarizeAfgiftesystemen(afgiftesystemen_nieuw)
      const deltas = compareSummariesAfgiftesystemen(summary_huidig, summary_nieuw)
      result[klimaatzoneNaam] = deltas
    })

    return result
  })

  function validate(event, type, rules, key, index) {
    const input = event.target.value
    // validatorguard
    if (validator.validate(input, type)) {
      data.value[index][key] = input
    } else {
      if (data.value[index][key] != undefined) {
        event.target.value = data.value[index][key]
      } else {
        event.target.value = null
      }
    }
  }

  function load(payload) {
    data.value = payload
    dataMonitor.sync(data.value)
    data.value.forEach((maatregel) => {
      const { prijs, hoeveelheid } = toRefs(maatregel)

      watch([prijs, hoeveelheid], ([newPrijs, newHoeveelheid]) => {
        maatregel.kosten = newPrijs * newHoeveelheid
      })
    })
  }

  const totaalMaatregelen = computed(() => {
    let totaal_kosten = 0
    let totaal_subsidie = 0

    data.value.forEach((maatregel) => {
      totaal_kosten += Number(maatregel.kosten)
      totaal_subsidie += Number(maatregel.subsidie)
    })

    return {
      totaal_kosten,
      totaal_subsidie,
    }
  })

  function calculateFinancials(variables) {
    let return_data = {
      years: [],
      totaal_co2: 0,
    }
    const soort = forms.soortForm.data.value
    const years = Array.from({ length: 20 }, (x, i) => i + soort.jaar_verbouwing)

    const {
      gasverbruik,
      prijs_gas,
      prijsstijging_gas,
      van_het_net_kwh,
      prijs_elektra,
      prijsstijging_elektra,
      teruglevering_totaal_kwh,
      eigen_behoefte_totaal,
      terugleververgoeding,
      warmteverbruik,
      prijs_warmte,
      prijsstijging_warmte,
    } = variables

    const co2_gas = useLookup.co2_uitstoot.gas
    const co2_elektra = useLookup.co2_uitstoot.elektriciteit

    // CO2 warmte forfaitair of ingevoerd
    const uitstoot_warmtenet = soort.uitstoot_warmtenet
    let co2_warmte = useLookup.co2_uitstoot.warmte

    if (uitstoot_warmtenet) {
      co2_warmte = uitstoot_warmtenet
    }

    const gasverbruik_co2 = gasverbruik * co2_gas
    const warmte_co2 = warmteverbruik * co2_warmte
    const van_het_net_co2 = van_het_net_kwh * co2_elektra
    const teruglevering_totaal_co2 = teruglevering_totaal_kwh * co2_elektra
    const totaal_co2 = gasverbruik_co2 + (van_het_net_co2 - teruglevering_totaal_co2) + warmte_co2

    return_data.totaal_co2 = totaal_co2

    let energiekosten_cumulatief = 0
    let co2_cumulatief = totaal_co2

    years.forEach((year, index) => {
      const aandeel_saldering = useLookup.salderingAandeel[year] ?? 1
      const gas = gasverbruik * prijs_gas * (prijsstijging_gas / 100 + 1) ** index
      const elektra = van_het_net_kwh * prijs_elektra * (prijsstijging_elektra / 100 + 1) ** index
      const warmte = warmteverbruik * prijs_warmte * (prijsstijging_warmte / 100 + 1) ** index

      const salderen = Math.min(teruglevering_totaal_kwh, van_het_net_kwh) * aandeel_saldering
      const besparing_door_salderen = salderen * prijs_elektra * (prijsstijging_elektra / 100 + 1) ** index

      const boven_salderen = teruglevering_totaal_kwh - salderen
      const besparing_door_boven_salderen =
        boven_salderen * terugleververgoeding * (prijsstijging_elektra / 100 + 1) ** index

      const energiekosten = gas + warmte + elektra - besparing_door_salderen - besparing_door_boven_salderen

      energiekosten_cumulatief += energiekosten

      return_data.years[year] = {
        aandeel_saldering,
        gas,
        elektra,
        salderen,
        besparing_door_salderen,
        boven_salderen,
        besparing_door_boven_salderen,
        energiekosten,
        energiekosten_cumulatief,
        co2_cumulatief,
        warmte,
      }

      co2_cumulatief += totaal_co2
    })

    return return_data
  }

  function compareFinancials(financialsHuidig, financialsNieuw, investering) {
    let return_years = {}

    const totaal_co2_huidig = financialsHuidig.totaal_co2
    const totaal_co2_nieuw = financialsNieuw.totaal_co2
    const besparing_co2 = totaal_co2_huidig - totaal_co2_nieuw

    let terugverdienjaar = undefined

    Object.keys(financialsHuidig.years).forEach((year) => {
      const energiekosten_huidig = financialsHuidig.years[year].energiekosten
      const energiekosten_huidig_cumulatief = financialsHuidig.years[year].energiekosten_cumulatief
      const energiekosten_nieuw = financialsNieuw.years[year].energiekosten
      const energiekosten_nieuw_cumulatief = financialsNieuw.years[year].energiekosten_cumulatief

      const energiekosten_nieuw_inclusief_investering = energiekosten_nieuw_cumulatief + investering

      const co2_huidig = financialsHuidig.years[year].co2_cumulatief
      const co2_nieuw = financialsNieuw.years[year].co2_cumulatief

      const delta_energiekosten = energiekosten_huidig - energiekosten_nieuw
      const besparing_co2_cumulatief = co2_huidig - co2_nieuw

      // delta culumatief oud, cumulatief nieuw inclusie finvestering
      const delta_energiekosten_inclusief_investering =
        energiekosten_huidig_cumulatief - energiekosten_nieuw_inclusief_investering

      if (delta_energiekosten_inclusief_investering > 0 && terugverdienjaar === undefined) {
        terugverdienjaar = year
      }

      return_years[year] = {
        delta_energiekosten,
        besparing_co2_cumulatief,
        energiekosten_nieuw_inclusief_investering,
        delta_energiekosten_inclusief_investering,
        energiekosten_huidig_cumulatief,
      }
    })

    return {
      return_years,
      terugverdienjaar,
    }
  }

  const costs = computed(() => {
    // uniform
    const totaal_kosten = totaalMaatregelen.value.totaal_kosten
    const totaal_subsidie = totaalMaatregelen.value.totaal_subsidie
    const investering = totaal_kosten - totaal_subsidie

    const soort = forms.soortForm.data.value
    const prijs_gas = soort.prijs_gas
    const prijs_elektra = soort.prijs_elektra
    const prijs_warmte = Number(soort.prijs_warmte)
    const prijsstijging_gas = soort.prijsstijging_gas
    const prijsstijging_elektra = soort.prijsstijging_elektra
    const prijsstijging_warmte = Number(soort.prijsstijging_warmte)
    const terugleververgoeding = soort.teruglevertarief_elektra

    // vars huidig
    const gasverbruik_huidig = formulas.value.result.data_total.verbruik_totaal_m3
    const elektraverbruik_huidig = formulas.value.result.data_total.verbruik_totaal_kwh
    const warmteverbruik_huidig = formulas.value.result.data_total.verbruik_totaal_gj
    const van_het_net_huidig_kwh = formulas.value.result.data_total.van_het_net_kwh
    const direct_verbruik_totaal_huidig_kwh = formulas.value.result.data_total.direct_verbruik_totaal_kwh
    const eigen_behoefte_totaal_huidig_kwh = formulas.value.result.data_total.eigen_behoefte_totaal
    const teruglevering_totaal_huidig_kwh = formulas.value.result.data_total.teruglevering_totaal_kwh

    // vars nieuw
    const gasverbruik_nieuw = formulasNieuw.value.result.data_total.verbruik_totaal_m3
    const elektraverbruik_nieuw = formulasNieuw.value.result.data_total.verbruik_totaal_kwh
    const warmteverbruik_nieuw = formulasNieuw.value.result.data_total.verbruik_totaal_gj
    const van_het_net_nieuw_kwh = formulasNieuw.value.result.data_total.van_het_net_kwh
    const direct_verbruik_totaal_nieuw_kwh = formulasNieuw.value.result.data_total.direct_verbruik_totaal_kwh
    const eigen_behoefte_totaal_nieuw_kwh = formulasNieuw.value.result.data_total.eigen_behoefte_totaal
    const teruglevering_totaal_nieuw_kwh = formulasNieuw.value.result.data_total.teruglevering_totaal_kwh

    const financialsHuidig = calculateFinancials({
      gasverbruik: gasverbruik_huidig,
      prijs_gas: prijs_gas,
      prijsstijging_gas: prijsstijging_gas,
      van_het_net_kwh: van_het_net_huidig_kwh,
      prijs_elektra: prijs_elektra,
      prijsstijging_elektra: prijsstijging_elektra,
      teruglevering_totaal_kwh: teruglevering_totaal_huidig_kwh,
      eigen_behoefte_totaal: eigen_behoefte_totaal_huidig_kwh,
      terugleververgoeding: terugleververgoeding,
      warmteverbruik: warmteverbruik_huidig,
      prijs_warmte: prijs_warmte,
      prijsstijging_warmte: prijsstijging_warmte,
    })

    const financialsNieuw = calculateFinancials({
      gasverbruik: gasverbruik_nieuw,
      prijs_gas: prijs_gas,
      prijsstijging_gas: prijsstijging_gas,
      van_het_net_kwh: van_het_net_nieuw_kwh,
      prijs_elektra: prijs_elektra,
      prijsstijging_elektra: prijsstijging_elektra,
      teruglevering_totaal_kwh: teruglevering_totaal_nieuw_kwh,
      eigen_behoefte_totaal: eigen_behoefte_totaal_nieuw_kwh,
      terugleververgoeding: terugleververgoeding,
      warmteverbruik: warmteverbruik_nieuw,
      prijs_warmte: prijs_warmte,
      prijsstijging_warmte: prijsstijging_warmte,
    })

    const { return_years, terugverdienjaar } = compareFinancials(financialsHuidig, financialsNieuw, investering)

    let terugverdientijd = 0

    if (terugverdienjaar) {
      terugverdientijd += terugverdienjaar - soort.jaar_verbouwing
    } else {
      terugverdientijd = '>20'
    }

    const energiekosten_per_maand_eerste_jaar_huidig =
      financialsHuidig.years[Object.keys(financialsHuidig.years)[0]].energiekosten / 12

    const energiekosten_per_jaar_eerste_jaar_huidig =
      financialsHuidig.years[Object.keys(financialsHuidig.years)[0]].energiekosten

    const energiekosten_per_maand_eerste_jaar_nieuw =
      financialsNieuw.years[Object.keys(financialsNieuw.years)[0]].energiekosten / 12

    const energiekosten_per_jaar_eerste_jaar_nieuw =
      financialsNieuw.years[Object.keys(financialsNieuw.years)[0]].energiekosten

    const energiekosten_na_20_jaar_huidig =
      financialsHuidig.years[Object.keys(financialsHuidig.years)[19]].energiekosten_cumulatief
    const energiekosten_na_20_jaar_nieuw =
      financialsNieuw.years[Object.keys(financialsNieuw.years)[19]].energiekosten_cumulatief
    const co2_uitstoot_per_jaar_huidig = financialsHuidig.years[Object.keys(financialsHuidig.years)[0]].co2_cumulatief
    const co2_uitstoot_per_jaar_nieuw = financialsNieuw.years[Object.keys(financialsNieuw.years)[0]].co2_cumulatief
    const toaal_investering = investering

    const co2_reductie_na_20_jaar = return_years[Object.keys(financialsNieuw.years)[19]].besparing_co2_cumulatief

    const geldbesparing_na_20_jaar =
      return_years[Object.keys(financialsNieuw.years)[19]].delta_energiekosten_inclusief_investering

    const huidig_eerste_jaar_aandeel_saldering =
      financialsHuidig.years[Object.keys(financialsHuidig.years)[0]].aandeel_saldering
    const huidig_eerste_jaar_besparing_door_boven_salderen =
      financialsHuidig.years[Object.keys(financialsHuidig.years)[0]].besparing_door_boven_salderen
    const huidig_eerste_jaar_besparing_door_salderen =
      financialsHuidig.years[Object.keys(financialsHuidig.years)[0]].besparing_door_salderen
    const huidig_eerste_jaar_boven_salderen =
      financialsHuidig.years[Object.keys(financialsHuidig.years)[0]].boven_salderen
    const huidig_eerste_jaar_co2_cumulatief =
      financialsHuidig.years[Object.keys(financialsHuidig.years)[0]].co2_cumulatief
    const huidig_eerste_jaar_elektra = financialsHuidig.years[Object.keys(financialsHuidig.years)[0]].elektra
    const huidig_eerste_jaar_energiekosten =
      financialsHuidig.years[Object.keys(financialsHuidig.years)[0]].energiekosten
    const huidig_eerste_jaar_energiekosten_cumulatief =
      financialsHuidig.years[Object.keys(financialsHuidig.years)[0]].energiekosten_cumulatief
    const huidig_eerste_jaar_gas = financialsHuidig.years[Object.keys(financialsHuidig.years)[0]].gas
    const huidig_eerste_jaar_warmte = financialsHuidig.years[Object.keys(financialsHuidig.years)[0]].warmte
    const huidig_eerste_jaar_salderen = financialsHuidig.years[Object.keys(financialsHuidig.years)[0]].salderen

    const nieuw_eerste_jaar_aandeel_saldering =
      financialsNieuw.years[Object.keys(financialsNieuw.years)[0]].aandeel_saldering
    const nieuw_eerste_jaar_besparing_door_boven_salderen =
      financialsNieuw.years[Object.keys(financialsNieuw.years)[0]].besparing_door_boven_salderen
    const nieuw_eerste_jaar_besparing_door_salderen =
      financialsNieuw.years[Object.keys(financialsNieuw.years)[0]].besparing_door_salderen
    const nieuw_eerste_jaar_boven_salderen = financialsNieuw.years[Object.keys(financialsNieuw.years)[0]].boven_salderen
    const nieuw_eerste_jaar_co2_cumulatief = financialsNieuw.years[Object.keys(financialsNieuw.years)[0]].co2_cumulatief
    const nieuw_eerste_jaar_elektra = financialsNieuw.years[Object.keys(financialsNieuw.years)[0]].elektra
    const nieuw_eerste_jaar_energiekosten = financialsNieuw.years[Object.keys(financialsNieuw.years)[0]].energiekosten
    const nieuw_eerste_jaar_energiekosten_cumulatief =
      financialsNieuw.years[Object.keys(financialsNieuw.years)[0]].energiekosten_cumulatief
    const nieuw_eerste_jaar_gas = financialsNieuw.years[Object.keys(financialsNieuw.years)[0]].gas
    const nieuw_eerste_jaar_warmte = financialsNieuw.years[Object.keys(financialsNieuw.years)[0]].warmte
    const nieuw_eerste_jaar_salderen = financialsNieuw.years[Object.keys(financialsNieuw.years)[0]].salderen

    return {
      terugverdientijd,
      totaal_kosten,
      totaal_subsidie,
      toaal_investering,
      energiekosten_per_maand_eerste_jaar_huidig,
      energiekosten_per_jaar_eerste_jaar_huidig,
      energiekosten_per_maand_eerste_jaar_nieuw,
      energiekosten_per_jaar_eerste_jaar_nieuw,
      energiekosten_na_20_jaar_huidig,
      energiekosten_na_20_jaar_nieuw,
      co2_uitstoot_per_jaar_huidig,
      co2_uitstoot_per_jaar_nieuw,
      co2_reductie_na_20_jaar,
      geldbesparing_na_20_jaar,
      return_years,

      huidig_eerste_jaar_aandeel_saldering,
      huidig_eerste_jaar_besparing_door_boven_salderen,
      huidig_eerste_jaar_besparing_door_salderen,
      huidig_eerste_jaar_boven_salderen,
      huidig_eerste_jaar_co2_cumulatief,
      huidig_eerste_jaar_elektra,
      huidig_eerste_jaar_energiekosten,
      huidig_eerste_jaar_energiekosten_cumulatief,
      huidig_eerste_jaar_gas,
      huidig_eerste_jaar_warmte,
      huidig_eerste_jaar_salderen,

      nieuw_eerste_jaar_aandeel_saldering,
      nieuw_eerste_jaar_besparing_door_boven_salderen,
      nieuw_eerste_jaar_besparing_door_salderen,
      nieuw_eerste_jaar_boven_salderen,
      nieuw_eerste_jaar_co2_cumulatief,
      nieuw_eerste_jaar_elektra,
      nieuw_eerste_jaar_energiekosten,
      nieuw_eerste_jaar_energiekosten_cumulatief,
      nieuw_eerste_jaar_gas,
      nieuw_eerste_jaar_warmte,
      nieuw_eerste_jaar_salderen,
    }
  })

  return {
    syncMaatregelen,
    data,
    validate,
    totaalMaatregelen,
    addMaatregel,
    removeMaatregel,
    load,
    costs,
    formulas,
    formulasNieuw,
    dataMonitor,
  }
}
