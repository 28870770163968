<template>
  <div class="h-full min-h-0">
    <div class="h-full">
      <!-- vertical splitter -->
      <div class="flex h-full flex-col overflow-y-auto">
        <!-- title -->
        <div class="px-20 pt-10">
          <!-- back and title -->
          <div class="flex flex-row justify-between">
            <div class="flex flex-row items-center">
              <span class="mr-6">
                <backButton />
              </span>

              <basePageTitle title="Admin" />
            </div>
          </div>
        </div>

        <!-- content columns -->
        <div class="flex flex-row min-h-0">
          <div class="px-20 py-10 basis-full">
            <!-- tabs -->
            <baseTabs :handler="tabs" :loading="useSurveyAdmin.loadHandler.somethingLoading.value">
              <template v-slot:panel-opnameoverzicht>
                <!-- survey overview -->
                <baseTable
                  :fields="useSurveyAdmin.fields.value"
                  :records="useSurveyAdmin.records.value"
                  :records-per-page="25"
                  :add-button="false"
                  :edit-button="false"
                  :delete-button="false"
                  :duplicate-button="false"
                  :export-button="true"
                  :clickAble="false"
                  :sortable="true"
                  :loading="useSurveyAdmin.loadHandler.somethingLoading.value"
                  :exporting="useExportExcel.loadHandler.states.export_excel"
                  @export="onExport"
                />
              </template>

              <!-- organisations -->
              <template v-slot:panel-organisaties>
                <baseTable
                  :fields="useSurveyAdmin.organisationFields.value"
                  :records="useSurveyAdmin.organisations.value"
                  :records-per-page="25"
                  :add-button="false"
                  :edit-button="false"
                  :delete-button="false"
                  :duplicate-button="false"
                  :export-button="false"
                  :clickAble="true"
                  :clickCallback="organisationClick"
                  :sortable="true"
                  :loading="useSurveyAdmin.loadHandler.somethingLoading.value"
                />
              </template>

              <!-- manual -->
              <template v-slot:panel-handleiding>
                <!-- algemeen -->
                <baseCard
                  class="max-h-full overflow-y-auto"
                  v-if="!useManualDetails.loadHandler.states.get_manual_details"
                >
                  <baseButton
                    @action="useManualDetails.uploadTemplate()"
                    :loading="useManualDetails.loadHandler.states.upload_template"
                    :disabled="!useManualDetails.canSave.value"
                    >Opslaan</baseButton
                  >
                  <baseForm :handler="useManualDetails.manualForm" />
                </baseCard>
              </template>
            </baseTabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted } from 'vue'
import surveyAdminHandler from '@/use/surveyAdminHandler'
import { useRouter } from 'vue-router'
import exportExcelHandler from '@/use/exportExcelHandler'
import tabHandler from '@/use/tabHandler'
import manualDetailHandler from '@/use/manualDetailHandler'

export default {
  setup() {
    // handlers
    const useSurveyAdmin = surveyAdminHandler()
    const router = useRouter()
    const useExportExcel = exportExcelHandler()
    const useManualDetails = manualDetailHandler()

    function onExport(data) {
      useExportExcel.exportExcel('export', 'gebruiksoverzicht', data.records, data.fields)
    }

    // tabs
    const tabOptions = {
      tabs: [
        { id: 'opnameoverzicht', label: 'Opnameoverzicht', active: true },
        { id: 'organisaties', label: 'Organisaties', active: true },
        { id: 'handleiding', label: 'Handleiding', active: true },
      ],
    }

    const tabs = tabHandler()

    function organisationClick(event) {
      const organisationID = event.id

      router.push({
        name: 'organisation',
        params: { objectId: organisationID },
      })
    }

    onMounted(() => {
      useSurveyAdmin.getRecords()
      useSurveyAdmin.listOrganisations()
      tabs.create(tabOptions)
      useManualDetails.getDetails()
    })

    return {
      useSurveyAdmin,
      useExportExcel,
      onExport,
      tabs,
      organisationClick,
      useManualDetails,
    }
  },
}
</script>
