import { readonly, ref } from 'vue'
import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'

export default () => {
  // data
  let records = ref([])
  let organisations = ref([])
  let showAdd = ref(false)
  let showEdit = ref(false)

  // handlers
  const useApi = apiHandler()
  const loadHandler = loadingHandler()

  const fields = ref([
    {
      label: 'Survey ID',
      key: 'object_id',
      type: 'id',
      table: false,
      add: false,
      edit: false,
    },
    {
      label: 'Organisation',
      key: 'auth0_org_name',
      type: 'string',
    },
    {
      label: 'Description',
      key: 'description',
      type: 'string',
    },
    {
      label: 'name',
      key: 'name',
      type: 'string',
    },
    {
      label: 'address',
      key: 'address',
      type: 'string',
    },
    {
      label: 'zip',
      key: 'zip',
      type: 'string',
    },
    {
      label: 'place',
      key: 'place',
      type: 'string',
    },
    {
      label: 'Export count',
      key: 'export_count',
      type: 'number',
    },
    {
      label: 'Edit count',
      key: 'edit_count',
      type: 'number',
    },
    {
      label: 'Date created',
      key: 'date_created',
      type: 'date',
      add: false,
      edit: false,
    },
    {
      label: 'Date edited',
      key: 'date_edited',
      type: 'date',
      add: false,
      edit: false,
    },
    {
      label: 'First export date',
      key: 'export_date_first',
      type: 'date',
      add: false,
      edit: false,
    },
    {
      label: 'Last export date',
      key: 'export_date_last',
      type: 'date',
      add: false,
      edit: false,
    },
    {
      label: 'config',
      key: 'config',
      type: 'json',
      table: false,
      add: false,
      edit: false,
    },
  ])

  const organisationFields = ref([
    {
      label: 'Name',
      key: 'display_name',
      type: 'string',
    },
  ])

  // list
  function getRecords() {
    loadHandler.setLoadingState('get_records', true)
    useApi.request('get', 'surveys', 'list_admin').then((response) => {
      records.value = response.data.data
      loadHandler.setLoadingState('get_records', false)
    })
  }

  // list orgs
  function listOrganisations() {
    loadHandler.setLoadingState('list_organisations', true)
    useApi.request('get', 'surveys', 'list_organisations').then((response) => {
      organisations.value = response.data.data
      loadHandler.setLoadingState('list_organisations', false)
    })
  }

  // exposed
  return {
    fields: readonly(fields),
    records: readonly(records),
    getRecords,
    loadHandler,
    showAdd,
    showEdit,
    listOrganisations,
    organisations,
    organisationFields,
  }
}
