/* eslint-disable no-unused-vars */
import { computed, ref } from 'vue'
import lookupHandler from './lookupHandler'

export default (forms, formulasHuidig, setpointOverrule, basic, streefwaardenEraOverrule) => {
  const useLookup = lookupHandler()
  let locked = ref(true)

  const overgangsweerstanden = useLookup.overgangsweerstanden.value
  const transmissiewaarden_onderbrekingen = useLookup.glas.value

  function summarize_deelvlakken(deelvlakken) {
    let deelvlakken_oppervlakte = 0
    let deelvlakken_transmissie = 0
    let deelvlakken_zta = 0

    deelvlakken.forEach((deelvlak) => {
      const { soort } = deelvlak
      const oppervlakte = Number(deelvlak['oppervlakte'])
      const belemmering = Number(deelvlak['belemmering'])

      // transmissie
      const u_waarde = transmissiewaarden_onderbrekingen[soort].u_waarde
      const transmissie_glas = u_waarde * oppervlakte

      // toetreding
      const zta_factor = transmissiewaarden_onderbrekingen[soort].zta_waarde
      const zta_waarde = oppervlakte * (1 - belemmering / 100) * zta_factor

      deelvlakken_oppervlakte += oppervlakte
      deelvlakken_transmissie += transmissie_glas
      deelvlakken_zta += zta_waarde
    })

    return { deelvlakken_oppervlakte, deelvlakken_transmissie, deelvlakken_zta }
  }

  function get_dts(setpoint, buitentemperatuur, kruipruimtetemperatuur, factor, factorAOR) {
    const dt_buiten = Math.max(setpoint - buitentemperatuur, 0) * factor
    const dt_kruipruimte = Math.max(setpoint - kruipruimtetemperatuur, 0) * factor
    const dt_aor = Math.max(setpoint - buitentemperatuur, 0) * factorAOR
    const dt_grond = setpoint - 10

    return {
      dt_buiten,
      dt_kruipruimte,
      dt_aor,
      dt_grond,
    }
  }

  function get_infiltratie(luchtdoorlatendheidcoefficient_infiltratie, dt_buiten, winddruk_gevel) {
    const stromingsexponent = useLookup.stromingsexponent
    const dichtheid_lucht = useLookup.dichtheid_lucht
    const infiltratie_m3_h = luchtdoorlatendheidcoefficient_infiltratie * winddruk_gevel ** (1 / stromingsexponent)
    const infiltratie_kwh = (((infiltratie_m3_h * dichtheid_lucht) / 3600) * dt_buiten * 24) / 1000
    return { infiltratie_kwh, infiltratie_m3_h }
  }

  function get_ventilatie(
    luchtdoorlatendheidcoefficient_ventilatie,
    dt_buiten,
    winddruk_gevel,
    ventilatie_mechanisch,
    ventilatie_wtw,
    natuurlijke_ventilatie
  ) {
    const stromingsexponent = useLookup.stromingsexponent
    const dichtheid_lucht = useLookup.dichtheid_lucht

    const ventilatie_m3_h =
      natuurlijke_ventilatie === 'Ja'
        ? luchtdoorlatendheidcoefficient_ventilatie * winddruk_gevel ** (1 / stromingsexponent)
        : ventilatie_mechanisch + ventilatie_wtw

    const ventilatie_kwh = (((ventilatie_m3_h * dichtheid_lucht) / 3600) * dt_buiten * 24) / 1000

    const data = {
      ventilatie_m3_h,
      dichtheid_lucht,
      dt_buiten,
      ventilatie_kwh,
    }

    return { ventilatie_kwh, ventilatie_m3_h, data }
  }

  function sum_afgiftesystemen(afgiftesystemen) {
    let subtotaal_elektrisch_pompverbruik = {}

    afgiftesystemen.forEach((systeem) => {
      const type = systeem.afgifte
      const elektrisch_pompverbruik = Number(useLookup.afgifte[type]['elektrisch pompverbruik'])

      if (type.includes('Vloerverwarming')) {
        subtotaal_elektrisch_pompverbruik['vloerverwarming'] = elektrisch_pompverbruik
      } else if (type.includes('Lucht')) {
        subtotaal_elektrisch_pompverbruik['lucht'] = elektrisch_pompverbruik
      } else {
        subtotaal_elektrisch_pompverbruik[type] = elektrisch_pompverbruik
      }
    })

    let totaal_elektrisch_pompverbruik = 0

    Object.keys(subtotaal_elektrisch_pompverbruik).forEach((type) => {
      totaal_elektrisch_pompverbruik += subtotaal_elektrisch_pompverbruik[type]
    })

    return {
      totaal_elektrisch_pompverbruik,
    }
  }

  function sumObjectsByKey(keys, objs) {
    return objs.reduce((a, b) => {
      for (let k in b) {
        // eslint-disable-next-line no-prototype-builtins
        if (b.hasOwnProperty(k) && keys.includes(k)) a[k] = (a[k] || 0) + b[k]
      }
      return a
    }, {})
  }

  function loop_klimaatgegevens(
    setpoint,
    luchtdoorlatendheidcoefficient_infiltratie,
    luchtdoorlatendheidcoefficient_ventilatie,
    ventilatie_mechanisch,
    ventilatie_wtw,
    transmissie_bouwdeel,
    zta,
    transmissie_per_globale_orientatie,
    natuurlijke_ventilatie,
    winddruk_coefficient
  ) {
    let second_loop = []
    let totaal_transmissie_kwh = 0

    // infiltratie
    let totaal_infiltratie_kwh = 0
    let max_infiltratie_m3_h = 0

    // ventilatie
    let totaal_ventilatie_kwh = 0
    let max_ventilatie_m3_h = 0

    // zoninstraling
    let totaal_zoninstraling_kwh = 0
    let totaal_zonkracht_wh_m2 = 0

    // totaal
    let totaal_kwh = 0
    let export_data = {}

    const algemene_correctiefactor_transmissie = useLookup.algemene_correctiefactor_transmissie

    useLookup.klimaatgegevens.forEach((element) => {
      // temperaturen
      const { buitentemperatuur, factor, kruipruimtetemperatuur, factorAOR, windsnelheid, zonkracht, maand } = element

      // som zonkracht
      totaal_zonkracht_wh_m2 += zonkracht

      // dt's
      const dts = get_dts(setpoint, buitentemperatuur, kruipruimtetemperatuur, factor, factorAOR)

      const dt_buiten = dts.dt_buiten
      const dt_kruipruimte = dts.dt_kruipruimte
      const dt_aor = dts.dt_aor
      const dt_grond = dts.dt_grond

      // transmissie buiten
      const transmissie_buiten_kwh = convert_transmissie(
        transmissie_per_globale_orientatie['buiten'],
        dt_buiten,
        algemene_correctiefactor_transmissie
      )

      // transmissie grond
      const transmissie_grond_kwh = convert_transmissie(
        transmissie_per_globale_orientatie['grond'],
        dt_grond,
        algemene_correctiefactor_transmissie
      )

      // transmissie kruipruimte
      const transmissie_kruipruimte_kwh = convert_transmissie(
        transmissie_per_globale_orientatie['kruipruimte'],
        dt_kruipruimte,
        algemene_correctiefactor_transmissie
      )

      // transmissie aor
      const transmissie_aor_kwh = convert_transmissie(
        transmissie_per_globale_orientatie['aor'],
        dt_aor,
        algemene_correctiefactor_transmissie
      )

      // winddruk gevel
      const soortelijke_massa_lucht = useLookup.soortelijke_massa_lucht
      const correctie_winddruk_op_de_gevel = useLookup.correctie_winddruk_op_de_gevel

      const winddruk_gevel =
        0.5 *
        winddruk_coefficient *
        (soortelijke_massa_lucht * (windsnelheid / 3.6) ** 2) *
        correctie_winddruk_op_de_gevel

      // infiltratie
      const totaal_infiltratie = get_infiltratie(luchtdoorlatendheidcoefficient_infiltratie, dt_buiten, winddruk_gevel)

      const subtotaal_infiltratie_kwh = totaal_infiltratie.infiltratie_kwh
      const totaal_infiltratie_m3_h = totaal_infiltratie.infiltratie_m3_h

      if (totaal_infiltratie_m3_h > max_infiltratie_m3_h) {
        max_infiltratie_m3_h = totaal_infiltratie_m3_h
      }

      const totaal_ventilatie = get_ventilatie(
        luchtdoorlatendheidcoefficient_ventilatie,
        dt_buiten,
        winddruk_gevel,
        ventilatie_mechanisch,
        ventilatie_wtw,
        natuurlijke_ventilatie
      )

      const subtotaal_ventilatie_kwh = totaal_ventilatie.ventilatie_kwh
      const totaal_ventilatie_m3_h = totaal_ventilatie.ventilatie_m3_h

      if (totaal_ventilatie_m3_h > max_ventilatie_m3_h) {
        max_ventilatie_m3_h = totaal_ventilatie_m3_h
      }

      // zoninstraling begane grond
      let subtotaal_zoninstraling = 0

      Object.keys(zta).forEach((orientatie) => {
        const deelvlakken_zta = zta[orientatie]
        const gecorrigeerde_zonkracht = (zonkracht / 1000) * useLookup.correctie_zoninstraling
        const zoninstraling_orientatie_correctie = useLookup.zoninstraling[orientatie][maand]
        const zoninstraling_gecorrigeerd = gecorrigeerde_zonkracht * zoninstraling_orientatie_correctie
        const zoninstraling_deelvlakken = zoninstraling_gecorrigeerd * deelvlakken_zta
        subtotaal_zoninstraling += zoninstraling_deelvlakken
      })

      totaal_zoninstraling_kwh += subtotaal_zoninstraling

      const subtotaal_kwh =
        transmissie_buiten_kwh +
        transmissie_grond_kwh +
        transmissie_kruipruimte_kwh +
        transmissie_aor_kwh +
        subtotaal_ventilatie_kwh +
        subtotaal_infiltratie_kwh -
        subtotaal_zoninstraling

      const subtotaal_check = subtotaal_kwh > 0 ? subtotaal_kwh : 0

      totaal_kwh += subtotaal_check

      // fill secondloop array
      second_loop.push({
        zoninstraling: subtotaal_zoninstraling,
        totaal_kwh: subtotaal_check,
        zonkracht: zonkracht,
      })

      const subtotaal_transmissie_kwh =
        transmissie_buiten_kwh + transmissie_grond_kwh + transmissie_kruipruimte_kwh + transmissie_aor_kwh

      totaal_transmissie_kwh += subtotaal_transmissie_kwh
      totaal_infiltratie_kwh += subtotaal_infiltratie_kwh
      totaal_ventilatie_kwh += subtotaal_ventilatie_kwh

      // export_data.push({ subtotaal_transmissie_kwh, subtotaal_infiltratie_kwh, subtotaal_ventilatie_kwh, maand })

      export_data[maand] = export_data[maand] || []
      export_data[maand].push({
        subtotaal_transmissie_kwh,
        subtotaal_infiltratie_kwh,
        subtotaal_ventilatie_kwh,
        subtotaal_zoninstraling,
      })
    })

    return {
      totaal_kwh,
      totaal_zoninstraling_kwh,
      totaal_transmissie_kwh,
      totaal_infiltratie_kwh,
      totaal_ventilatie_kwh,
      max_infiltratie_m3_h,
      max_ventilatie_m3_h,
      second_loop,
      totaal_zonkracht_wh_m2,
      export_data,
    }
  }

  function convert_transmissie(transmissie, dt, algemene_correctiefactor_transmissie) {
    return ((transmissie * dt * 24) / 1000) * algemene_correctiefactor_transmissie
  }

  function parseBouwdeel(bouwdelen) {
    const totaal_deelvlakken_zta = {}
    let totaal_transmissie = 0
    let transmissie_per_globale_orientatie = {
      buiten: 0,
      grond: 0,
      kruipruimte: 0,
      aor: 0,
    }
    let totaal_oppervlakte_bouwdelen_bruto = 0
    let totaal_oppervlakte_deelvlakken = 0
    let totaal_oppervlakte_bouwdelen_bruto_streefwaarden_woningisolatie = 0

    if (bouwdelen) {
      bouwdelen.forEach((bouwdeel) => {
        const { orientatie, deelvlak, isolatieniveau } = bouwdeel
        const oppervlakte = Number(bouwdeel['oppervlakte'])

        // deelvlakken
        const { deelvlakken_oppervlakte, deelvlakken_transmissie, deelvlakken_zta } = summarize_deelvlakken(deelvlak)

        // oppervlakten totaal
        totaal_oppervlakte_bouwdelen_bruto += oppervlakte
        totaal_oppervlakte_deelvlakken += deelvlakken_oppervlakte

        // voor streefwaarden woningisolatie
        if (bouwdeel.type === 'Vloer' && ['grond', 'kruipruimte'].includes(orientatie)) {
          totaal_oppervlakte_bouwdelen_bruto_streefwaarden_woningisolatie += oppervlakte * 0.7
        } else {
          totaal_oppervlakte_bouwdelen_bruto_streefwaarden_woningisolatie += oppervlakte
        }

        // transmissie w/k
        const rsi = overgangsweerstanden[orientatie]['rsi']
        const rse = overgangsweerstanden[orientatie]['rse']
        const rc = Number(isolatieniveau)

        const u_waarde_bouwdeel = 1 / (rsi + rse + rc)
        const oppervlakte_bouwdeel = Math.max(oppervlakte - deelvlakken_oppervlakte, 0)
        const transmissie_bouwdeel = oppervlakte_bouwdeel * u_waarde_bouwdeel

        const subtotaal_transmissie = transmissie_bouwdeel + deelvlakken_transmissie
        const globale_orientatie = useLookup.orientatie_mapper[orientatie]

        totaal_transmissie += subtotaal_transmissie

        // deelvlakken zta
        if (!(orientatie in totaal_deelvlakken_zta)) {
          totaal_deelvlakken_zta[orientatie] = deelvlakken_zta
        } else {
          totaal_deelvlakken_zta[orientatie] += deelvlakken_zta
        }

        // transmissie per globale orientatie
        if (!(globale_orientatie in transmissie_per_globale_orientatie)) {
          transmissie_per_globale_orientatie[globale_orientatie] = subtotaal_transmissie
        } else {
          transmissie_per_globale_orientatie[globale_orientatie] += subtotaal_transmissie
        }
      })
    }

    let totaal_oppervlakte_bouwdelen_netto = totaal_oppervlakte_bouwdelen_bruto - totaal_oppervlakte_deelvlakken

    return {
      totaal_deelvlakken_zta,
      totaal_transmissie,
      totaal_oppervlakte_bouwdelen_bruto,
      totaal_oppervlakte_deelvlakken,
      totaal_oppervlakte_bouwdelen_netto,
      transmissie_per_globale_orientatie,
      totaal_oppervlakte_bouwdelen_bruto_streefwaarden_woningisolatie,
    }
  }

  function getMaxWarmtevraag(woningtype, era, categorie_compactheid, compactheid) {
    const selector = {
      Eengezinswoningen: {
        't/m 1945': {
          '< 1': 60,
          '>= 1': 60 + 105 * (compactheid - 1),
        },
        'na 1945': {
          '< 1': 43,
          '>= 1': 43 + 40 * (compactheid - 1),
        },
      },
      Meergezinswoningen: {
        't/m 1945': {
          '< 1': 95,
          '>= 1': 95 + 70 * (compactheid - 1),
        },
        'na 1945': {
          '< 1': 45,
          '>= 1': 45 + 55 * (compactheid - 1),
        },
      },
    }
    return selector[woningtype][era][categorie_compactheid]
  }

  function sumKwhAndExtractZonkracht(arrOfObjs) {
    // Extract the arrays from the 'second_loop' property of each object.
    let arr = arrOfObjs.map((obj) => obj.second_loop)

    let result = []

    // Assuming all inner arrays have the same length.
    arr[0].forEach((item, index) => {
      let total_kwh_sum = 0

      arr.forEach((innerArray) => {
        total_kwh_sum += innerArray[index].totaal_kwh
      })

      result.push({
        totaal_kwh: total_kwh_sum,
        zonkracht: arr[0][index].zonkracht,
      })
    })

    return result
  }

  function summarizeKlimaatzoneOpp(klimaatzone_data) {
    let summary = {
      total: 0,
      without_afwijkende_ventilatie: 0,
    }

    // Loop over each item in the list
    klimaatzone_data.forEach((item) => {
      // Assuming each item has properties 'naam' and 'verbruiksoppervlak'
      const verbruiksoppervlak = Number(item.verbruiksoppervlak)

      summary[item.naam] = verbruiksoppervlak
      summary.total += verbruiksoppervlak

      // If the item does not have afwijkende_ventilatie set to true
      if (!item.afwijkende_ventilatie) {
        summary.without_afwijkende_ventilatie += verbruiksoppervlak
      }
    })

    return summary
  }

  const result = computed(() => {
    if (!locked.value) {
      // unpacking forms
      const { soort, installaties, klimaatzones } = forms

      // Loop klimaatzones
      const klimaatzone_data = klimaatzones.data.value
      const klimaatzone_oppervlaktes = summarizeKlimaatzoneOpp(klimaatzone_data)

      const klimaatzone_summary = {}

      const rendementen_distributiesysteem = useLookup.rendementen_distributiesysteem
      const opwekking_binnen_thermische_schil = installaties.data.value.opwekking_binnen_thermische_schil
      const leidingen_buiten_schil = installaties.data.value.leidingen_buiten_schil
      const rendement_distributiesysteem =
        rendementen_distributiesysteem[leidingen_buiten_schil][opwekking_binnen_thermische_schil]

      klimaatzone_data.forEach((klimaatzone) => {
        const {
          naam,
          bouwdelen,
          stookgedrag_setpoint,
          verbruiksoppervlak,
          warmteafgifte,
          afwijkende_opwekker,
          afwijkende_ventilatie,
          verbruik_vermindering,
          verbruik_vermindering_reden,
        } = klimaatzone

        let {
          luchtdichtheid,
          ventilatie_mechanisch,
          ventilatie_natuurlijk,
          ventilatie_wtw,
          warmteopwekker,
          setpoint_vermogensberekening,
        } = klimaatzone

        if (!afwijkende_ventilatie) {
          ventilatie_mechanisch = Number(installaties.data.value.ventilatie_mechanisch)
          ventilatie_natuurlijk = installaties.data.value.ventilatie_natuurlijk
          ventilatie_wtw = Number(installaties.data.value.ventilatie_wtw)
          luchtdichtheid = Number(installaties.data.value.luchtdichtheid)
        }

        if (!afwijkende_opwekker) {
          warmteopwekker = installaties.data.value.warmteopwekker
        }

        const opwekker_details = useLookup.installaties[warmteopwekker]

        // constanten / globale waarden invoer
        const natuurlijke_ventilatie = computed(() => {
          // check if project is in basic mode
          if (basic === true) {
            const lookup = {
              'Natuurlijke ventilatie': 'Ja',
              'Mechanische afzuiging': 'Nee',
              WTW: 'Nee',
            }
            const selection = installaties.data.value.ventilatie_basic_select
            return lookup[selection]
          } else {
            // if project is not in basic mode
            const p_ventilatie_mechanisch = Number(ventilatie_mechanisch)
            const p_ventilatie_wtw = Number(ventilatie_wtw)
            if (p_ventilatie_mechanisch + p_ventilatie_wtw === 0) {
              return 'Ja'
            }
            return 'Nee'
          }
        })

        const qv_10_kar = computed(() => {
          const woningtype = soort.data.value.soort_woning
          const bouwjaar = soort.data.value.bouwjaar_woning
          const p_luchtdichtheid = luchtdichtheid

          if (p_luchtdichtheid) {
            return Number(p_luchtdichtheid)
          }
          return useLookup.woningtype_ventilatie[woningtype][bouwjaar][natuurlijke_ventilatie.value]
        })

        const qv_10_kar_type = computed(() => {
          const p_luchtdichtheid = luchtdichtheid
          if (p_luchtdichtheid) {
            return 'Handmatig'
          }
          return 'Forfaitair'
        })

        const qv_10_inf = computed(() => {
          const p_gebruiksoppervlakte = Number(verbruiksoppervlak)
          return p_gebruiksoppervlakte * qv_10_kar.value
        })

        const luchtdoorlatendheidcoëfficiënt_infiltratie = computed(() => {
          const stromingsexponent = useLookup.stromingsexponent
          return qv_10_inf.value / 10 ** (1 / stromingsexponent)
        })

        const qv_10_max = computed(() => {
          const p_gebruiksoppervlakte = Number(verbruiksoppervlak)
          return useLookup.maximale_ventilatie * p_gebruiksoppervlakte - qv_10_inf.value
        })

        const qv_10_vent = computed(() => {
          const p_gebruiksoppervlakte = Number(verbruiksoppervlak)
          const p_ventilatie_natuurlijk_soort = ventilatie_natuurlijk
          const p_ventilatie_natuurlijk = useLookup.ventilatie[p_ventilatie_natuurlijk_soort]

          const resultaat = p_ventilatie_natuurlijk * p_gebruiksoppervlakte
          if (resultaat < qv_10_max.value) {
            return resultaat
          }
          return qv_10_max.value
        })

        const luchtdoorlatendheidcoëfficiënt_ventilatie = computed(() => {
          const stromingsexponent = useLookup.stromingsexponent
          return qv_10_vent.value / 10 ** (1 / stromingsexponent)
        })

        const mechanische_ventilatie = computed(() => {
          const p_ventilatie_mechanisch = Number(ventilatie_mechanisch)
          const p_gebruiksoppervlakte = Number(verbruiksoppervlak)

          let return_waarde = 0

          if (basic === true) {
            const eis_bestaande_bouw = useLookup.eis_bestaande_bouw
            const gelijktijdigheid = useLookup.gelijktijdigheid
            const selection = installaties.data.value.ventilatie_basic_select

            if (selection === 'Mechanische afzuiging') {
              return p_gebruiksoppervlakte * eis_bestaande_bouw * gelijktijdigheid * 3.6
            } else {
              return 0
            }
          } else {
            if (!afwijkende_ventilatie) {
              const aandeel = p_gebruiksoppervlakte / klimaatzone_oppervlaktes['without_afwijkende_ventilatie']
              return_waarde = p_ventilatie_mechanisch * aandeel
            } else {
              const aandeel = 1
              return_waarde = p_ventilatie_mechanisch * aandeel
            }
            return return_waarde
          }
        })

        const wtw_ventilatie = computed(() => {
          const p_ventilatie_wtw = Number(ventilatie_wtw)
          const p_gebruiksoppervlakte = Number(verbruiksoppervlak)

          let return_waarde = 0
          if (basic === true) {
            const eis_bestaande_bouw = useLookup.eis_bestaande_bouw
            const gelijktijdigheid = useLookup.gelijktijdigheid
            const selection = installaties.data.value.ventilatie_basic_select

            if (selection === 'WTW') {
              return (
                p_gebruiksoppervlakte *
                eis_bestaande_bouw *
                gelijktijdigheid *
                3.6 *
                (1 - useLookup.rendement_ventilatie_wtw)
              )
            } else {
              return 0
            }
          } else {
            if (!afwijkende_ventilatie) {
              const aandeel = p_gebruiksoppervlakte / klimaatzone_oppervlaktes['without_afwijkende_ventilatie']
              return_waarde = p_ventilatie_wtw * aandeel * (1 - useLookup.rendement_ventilatie_wtw)
            } else {
              const aandeel = 1
              return_waarde = p_ventilatie_wtw * aandeel * (1 - useLookup.rendement_ventilatie_wtw)
            }
            return return_waarde
          }
        })

        // if setpoint ovverrule set p_setpoint to whatever the overrulevalue is
        let p_setpoint = Number(stookgedrag_setpoint)
        if (setpointOverrule) {
          p_setpoint = setpointOverrule
          setpoint_vermogensberekening = setpointOverrule
        }

        const p_gebruiksoppervlakte = Number(verbruiksoppervlak)
        const parameters_bouwdelen = parseBouwdeel(bouwdelen)
        const transmissie = parameters_bouwdelen.totaal_transmissie
        const zta = parameters_bouwdelen.totaal_deelvlakken_zta

        // oppervlakte
        const totaal_oppervlakte_bouwdelen_bruto = parameters_bouwdelen.totaal_oppervlakte_bouwdelen_bruto
        const totaal_oppervlakte_bouwdelen_bruto_streefwaarden_woningisolatie =
          parameters_bouwdelen.totaal_oppervlakte_bouwdelen_bruto_streefwaarden_woningisolatie
        const totaal_oppervlakte_deelvlakken = parameters_bouwdelen.totaal_oppervlakte_deelvlakken
        const totaal_oppervlakte_bouwdelen_netto = parameters_bouwdelen.totaal_oppervlakte_bouwdelen_netto

        const transmissie_buitenlucht = parameters_bouwdelen.transmissie_per_globale_orientatie.buiten
        const transmissie_grond = parameters_bouwdelen.transmissie_per_globale_orientatie.grond
        const transmissie_kruipruimte = parameters_bouwdelen.transmissie_per_globale_orientatie.kruipruimte
        const transmissie_aor = parameters_bouwdelen.transmissie_per_globale_orientatie.aor

        // vermogen transmissie
        function vermogenTransmissie(transmissie, dt) {
          return transmissie * (dt / 1000)
        }

        const vermogensTempLookup = computed(() => {
          return {
            'verschil met buitenlucht': Math.abs(setpoint_vermogensberekening - -10),
            'verschil met grond': Math.abs(setpoint_vermogensberekening - 5),
            'verschil met kruipruimte': Math.abs(setpoint_vermogensberekening - 2),
            'verschil met AOR': Math.abs(setpoint_vermogensberekening - 2),
          }
        })

        const vermogen_transmissie_buitenlucht = vermogenTransmissie(
          transmissie_buitenlucht,
          vermogensTempLookup.value['verschil met buitenlucht']
        )
        const vermogen_transmissie_grond = vermogenTransmissie(
          transmissie_grond,
          vermogensTempLookup.value['verschil met grond']
        )
        const vermogen_transmissie_kruipruimte = vermogenTransmissie(
          transmissie_kruipruimte,
          vermogensTempLookup.value['verschil met kruipruimte']
        )
        const vermogen_transmissie_aor = vermogenTransmissie(
          transmissie_aor,
          vermogensTempLookup.value['verschil met AOR']
        )

        // winddruk coefficient
        const soort_woning = soort.data.value.soort_woning
        const omgeving = soort.data.value.omgeving
        const winddruk_coefficient = useLookup.winddruk_coefficient[soort_woning][omgeving]

        const result_klimaatgegevens = loop_klimaatgegevens(
          p_setpoint,
          luchtdoorlatendheidcoëfficiënt_infiltratie.value,
          luchtdoorlatendheidcoëfficiënt_ventilatie.value,
          mechanische_ventilatie.value,
          wtw_ventilatie.value,
          transmissie,
          zta,
          parameters_bouwdelen.transmissie_per_globale_orientatie,
          natuurlijke_ventilatie.value,
          winddruk_coefficient
        )

        // ventilatie
        const totaal_ventilatie = mechanische_ventilatie.value + wtw_ventilatie.value
        result_klimaatgegevens['totaal_ventilatie'] = totaal_ventilatie

        // hulpenergie verwarming
        const afgiftesystemen = warmteafgifte
        const afgistesystemen_sum = sum_afgiftesystemen(warmteafgifte)
        const verbruik_hulpenergie_verwarming_kwh = afgistesystemen_sum.totaal_elektrisch_pompverbruik
        result_klimaatgegevens['verbruik_hulpenergie_verwarming_kwh'] = verbruik_hulpenergie_verwarming_kwh

        // vermogen ventilatie/infiltratie
        const max_infiltratie_m3_h = result_klimaatgegevens.max_infiltratie_m3_h
        const max_ventilatie_m3_h = result_klimaatgegevens.max_ventilatie_m3_h

        const dichtheid_lucht = useLookup.dichtheid_lucht

        const infiltratie_kw =
          max_infiltratie_m3_h *
          (dichtheid_lucht / 3600) *
          (vermogensTempLookup.value['verschil met buitenlucht'] / 1000)

        const ventilatie_kw =
          max_ventilatie_m3_h *
          (dichtheid_lucht / 3600) *
          (vermogensTempLookup.value['verschil met buitenlucht'] / 1000)

        // vermogen opwarming
        const gebruiksoppervlakte = Number(verbruiksoppervlak)
        const nachtverlaging = installaties.data.value.nachtverlaging
        const constante_opwarmen = useLookup.constante_opwarmen

        const nachtervlaging_factor = {
          Ja: 1,
          Nee: 0.1,
          undefined: 0.1,
        }

        const opwarming_kw = gebruiksoppervlakte * (constante_opwarmen / 1000) * nachtervlaging_factor[nachtverlaging]

        // vermogenstotalen
        const vermogen_subtotaal =
          vermogen_transmissie_buitenlucht +
          vermogen_transmissie_grond +
          vermogen_transmissie_kruipruimte +
          vermogen_transmissie_aor +
          infiltratie_kw +
          ventilatie_kw +
          opwarming_kw

        const veiligheidsmarge_kw = vermogen_subtotaal * 0.05
        const totaal_kw = vermogen_subtotaal + veiligheidsmarge_kw
        result_klimaatgegevens['totaal_kw'] = totaal_kw
        result_klimaatgegevens['benodigd_afgiftevermogen'] = totaal_kw

        // beschikbaar afgiftevermogen
        const aanvoertemperatuur = opwekker_details.aanvoertemperatuur

        let beschikbaar_afgiftevermogen = 0

        const afgiftevermogen_summary = {
          systemen: [],
        }

        function get_afgifte_vermogen(afgiftesysteem) {
          const afgifteField = useLookup.afgifteUnit[afgiftesysteem.afgifte].field

          if (['Lokaal / handmatig', 'Laagtemperatuur radiatoren'].includes(afgiftesysteem.afgifte)) {
            return Number(afgiftesysteem.vermogen)
          } else {
            return (
              (useLookup.afgifte[afgiftesysteem.afgifte][aanvoertemperatuur] * Number(afgiftesysteem[afgifteField])) /
              1000
            )
          }
        }

        function get_formaat(afgiftesysteem) {
          let formaat = 0
          let unit = ''

          const afgifteField = useLookup.afgifteUnit[afgiftesysteem.afgifte].field
          const afgifteUnit = useLookup.afgifteUnit[afgiftesysteem.afgifte].unit

          formaat = Number(afgiftesysteem[afgifteField])
          unit = afgifteUnit

          return {
            formaat,
            unit,
          }
        }

        afgiftesystemen.forEach((afgiftesysteem) => {
          if (aanvoertemperatuur) {
            const vermogen = get_afgifte_vermogen(afgiftesysteem)
            const formaat = get_formaat(afgiftesysteem)

            beschikbaar_afgiftevermogen += vermogen
            afgiftevermogen_summary.systemen.push({
              type: afgiftesysteem.afgifte,
              vermogen: vermogen,
              aanvoertemperatuur: aanvoertemperatuur,
              formaat: formaat.formaat,
              unit: formaat.unit,
            })
          }
        })

        // verbruik per zone
        // verwarming
        let totaal_kwh = result_klimaatgegevens.totaal_kwh
        let supertotaal_verwarming_kwh = result_klimaatgegevens.totaal_kwh
        let supertotaal_verwarming_verlaging_kwh = Number(verbruik_vermindering)
        let supertotaal_verwarming_verlaagd_kwh = result_klimaatgegevens.totaal_kwh
        let totaal_kwh_zonder_verlaging = result_klimaatgegevens.totaal_kwh
        let totaal_kwh_verlaging = verbruik_vermindering

        // check if verlaging gasverbruik
        if (supertotaal_verwarming_verlaging_kwh) {
          totaal_kwh -= supertotaal_verwarming_verlaging_kwh
          supertotaal_verwarming_verlaagd_kwh -= verbruik_vermindering
        }

        let verwarming_kwh = 0
        let verwarming_m3 = 0
        let verwarming_gj = 0

        const aandeel_gas = Number(opwekker_details.aandeel_gas)
        const aandeel_elektrisch = Number(opwekker_details.aandeel_elektrisch)
        const aandeel_warmte = Number(opwekker_details.aandeel_warmte)

        if (aandeel_gas) {
          const gas_verwarmingsrendement = Number(opwekker_details.gas_verwarmingsrendement)
          const energieinhoud_gas_kwh = useLookup.energieinhoud_gas_kwh

          verwarming_m3 +=
            (totaal_kwh / gas_verwarmingsrendement / energieinhoud_gas_kwh / rendement_distributiesysteem) * aandeel_gas
        }

        if (aandeel_elektrisch) {
          const elektrisch_verwarmingsrendement = Number(opwekker_details.elek_verwarmingsrendement)

          verwarming_kwh +=
            (totaal_kwh / elektrisch_verwarmingsrendement / rendement_distributiesysteem) * aandeel_elektrisch
        }

        if (aandeel_warmte) {
          const warmte_verwarmingsrendement = Number(opwekker_details.warm_verwarmingsrendement)
          const energieinhoud_warmte_kwh = useLookup.energieinhoud_warmte_kwh

          verwarming_gj +=
            (((totaal_kwh / warmte_verwarmingsrendement) * energieinhoud_warmte_kwh) / rendement_distributiesysteem) *
            aandeel_warmte
        }

        result_klimaatgegevens['transmissie_buitenlucht'] = transmissie_buitenlucht
        result_klimaatgegevens['transmissie_grond'] = transmissie_grond
        result_klimaatgegevens['transmissie_kruipruimte'] = transmissie_kruipruimte
        result_klimaatgegevens['transmissie_aor'] = transmissie_aor

        result_klimaatgegevens['vermogen_transmissie_buitenlucht'] = vermogen_transmissie_buitenlucht
        result_klimaatgegevens['vermogen_transmissie_grond'] = vermogen_transmissie_grond
        result_klimaatgegevens['vermogen_transmissie_kruipruimte'] = vermogen_transmissie_kruipruimte
        result_klimaatgegevens['vermogen_transmissie_aor'] = vermogen_transmissie_aor

        result_klimaatgegevens['natuurlijke_ventilatie'] = natuurlijke_ventilatie.value
        result_klimaatgegevens['winddruk_coefficient'] = winddruk_coefficient
        result_klimaatgegevens['qv_10_kar'] = qv_10_kar.value
        result_klimaatgegevens['qv_10_kar_type'] = qv_10_kar_type.value
        result_klimaatgegevens['qv_10_inf'] = qv_10_inf.value

        result_klimaatgegevens['luchtdoorlatendheidcoëfficiënt_infiltratie'] =
          luchtdoorlatendheidcoëfficiënt_infiltratie.value

        result_klimaatgegevens['qv_10_max'] = qv_10_max.value
        result_klimaatgegevens['qv_10_vent'] = qv_10_vent.value
        result_klimaatgegevens['luchtdoorlatendheidcoëfficiënt_ventilatie'] =
          luchtdoorlatendheidcoëfficiënt_ventilatie.value

        result_klimaatgegevens['mechanische_ventilatie'] = mechanische_ventilatie.value
        result_klimaatgegevens['rendement_ventilatie_wtw'] = useLookup.rendement_ventilatie_wtw * 100
        result_klimaatgegevens['wtw_ventilatie'] = wtw_ventilatie.value

        result_klimaatgegevens['infiltratie_kw'] = infiltratie_kw
        result_klimaatgegevens['ventilatie_kw'] = ventilatie_kw

        result_klimaatgegevens['opwarming_kw'] = opwarming_kw
        result_klimaatgegevens['veiligheidsmarge_kw'] = veiligheidsmarge_kw
        result_klimaatgegevens['totaal_kw'] = totaal_kw

        result_klimaatgegevens['beschikbaar_afgiftevermogen'] = beschikbaar_afgiftevermogen
        result_klimaatgegevens['afgiftevermogen_summary'] = afgiftevermogen_summary
        result_klimaatgegevens['gebruiksoppervlakte'] = p_gebruiksoppervlakte
        result_klimaatgegevens['totaal_oppervlakte_bouwdelen_bruto'] = totaal_oppervlakte_bouwdelen_bruto
        result_klimaatgegevens['totaal_oppervlakte_deelvlakken'] = totaal_oppervlakte_deelvlakken
        result_klimaatgegevens['totaal_oppervlakte_bouwdelen_netto'] = totaal_oppervlakte_bouwdelen_netto
        result_klimaatgegevens['totaal_oppervlakte_bouwdelen_bruto_streefwaarden_woningisolatie'] =
          totaal_oppervlakte_bouwdelen_bruto_streefwaarden_woningisolatie

        result_klimaatgegevens['naam'] = naam

        result_klimaatgegevens['totaal_kwh'] = totaal_kwh
        result_klimaatgegevens['supertotaal_verwarming_kwh'] = supertotaal_verwarming_kwh
        result_klimaatgegevens['supertotaal_verwarming_verlaging_kwh'] = supertotaal_verwarming_verlaging_kwh
        result_klimaatgegevens['verbruik_vermindering_reden'] = verbruik_vermindering_reden

        result_klimaatgegevens['supertotaal_verwarming_verlaagd_kwh'] = supertotaal_verwarming_verlaagd_kwh
        result_klimaatgegevens['totaal_kwh_zonder_verlaging'] = totaal_kwh_zonder_verlaging
        result_klimaatgegevens['totaal_kwh_verlaging'] = totaal_kwh_verlaging

        result_klimaatgegevens['verwarming_kwh'] = verwarming_kwh
        result_klimaatgegevens['verwarming_m3'] = verwarming_m3
        result_klimaatgegevens['verwarming_gj'] = verwarming_gj

        result_klimaatgegevens['setpoint_ruimtetemperatuur'] = stookgedrag_setpoint
        result_klimaatgegevens['setpoint_vermogensberekening'] = setpoint_vermogensberekening

        result_klimaatgegevens['afwijkende_opwekker'] = afwijkende_opwekker
        result_klimaatgegevens['afwijkende_ventilatie'] = afwijkende_ventilatie
        result_klimaatgegevens['warmteopwekker'] = warmteopwekker

        klimaatzone_summary[naam] = result_klimaatgegevens
      })

      const klimaatzone_summary_array = []
      Object.keys(klimaatzone_summary).forEach((klimaatzoneNaam) => {
        const klimaatzoneData = klimaatzone_summary[klimaatzoneNaam]
        klimaatzone_summary_array.push(klimaatzoneData)
      })

      const summary_klimaatgegevens = sumObjectsByKey(
        [
          'totaal_kwh',
          'totaal_ventilatie',
          'verbruik_hulpenergie_verwarming_kwh',
          'totaal_kw',
          'beschikbaar_afgiftevermogen',
          'benodigd_afgiftevermogen',
          'totaal_zonkracht_wh_m2',
          'totaal_oppervlakte_bouwdelen_bruto',
          'totaal_oppervlakte_deelvlakken',
          'totaal_oppervlakte_bouwdelen_netto',
          'totaal_oppervlakte_bouwdelen_bruto_streefwaarden_woningisolatie',
          'gebruiksoppervlakte',
          'totaal_kwh',
          'supertotaal_verwarming_kwh',
          'supertotaal_verwarming_verlaging_kwh',
          'supertotaal_verwarming_verlaagd_kwh',
          'totaal_kwh_zonder_verlaging',
          'totaal_kwh_verlaging',
          'verwarming_kwh',
          'verwarming_m3',
          'verwarming_gj',
        ],
        klimaatzone_summary_array
      )

      const second_loop = sumKwhAndExtractZonkracht(klimaatzone_summary_array)

      const sum_totaal_kwh = summary_klimaatgegevens.totaal_kwh
      const sum_supertotaal_verwarming_kwh = summary_klimaatgegevens.supertotaal_verwarming_kwh
      const sum_supertotaal_verwarming_verlaging_kwh = summary_klimaatgegevens.supertotaal_verwarming_verlaging_kwh
      const sum_supertotaal_verwarming_verlaagd_kwh = summary_klimaatgegevens.supertotaal_verwarming_verlaagd_kwh
      const sum_totaal_kwh_zonder_verlaging = summary_klimaatgegevens.totaal_kwh_zonder_verlaging
      const sum_totaal_kwh_verlaging = summary_klimaatgegevens.totaal_kwh_verlaging

      const sum_verwarming_kwh = summary_klimaatgegevens.verwarming_kwh
      const sum_verwarming_m3 = summary_klimaatgegevens.verwarming_m3
      const sum_verwarming_gj = summary_klimaatgegevens.verwarming_gj

      // tapwater
      let aantal_bewoners = Number(soort.data.value.gezinssamenstelling)
      let verbruiksprofiel_tapwater = installaties.data.value.verbruiksprofiel_tapwater

      // check if we are in overrulemode
      if (setpointOverrule) {
        aantal_bewoners = 2
        verbruiksprofiel_tapwater = 'Gemiddeld'
      }

      const warm_tapwater_behoefte = useLookup.warm_tapwater_behoefte[aantal_bewoners]
      const verbruiksprofiel_correctiefactor = useLookup.correctiefactor_verbruikprofiel[verbruiksprofiel_tapwater]
      const zonneboiler = installaties.data.value.zonneboiler_voor_bron_warm_tapwater
      const zonneboiler_tapwater_correctie = useLookup.zonneboiler_tapwater[zonneboiler]

      const bron_tapwater = installaties.data.value.bron_warm_tapwater
      const bron_tapwater_details = useLookup.installaties[bron_tapwater]

      const gas_tapwaterrendement = Number(bron_tapwater_details.gas_tapwaterrendement)
      const elektrisch_tapwaterrendement = Number(bron_tapwater_details.elek_tapwaterrendement)
      const warmte_tapwaterrendement = Number(bron_tapwater_details.warm_tapwaterrendement)

      const verbruik_tapwater_kwh =
        warm_tapwater_behoefte * verbruiksprofiel_correctiefactor * zonneboiler_tapwater_correctie

      const primaire_vraag_tapwater = warm_tapwater_behoefte * verbruiksprofiel_correctiefactor

      const supertotaal_tapwater_kwh = verbruik_tapwater_kwh

      let tapwater_kwh = 0
      let tapwater_m3 = 0
      let tapwater_gj = 0

      if (elektrisch_tapwaterrendement) {
        tapwater_kwh += verbruik_tapwater_kwh / elektrisch_tapwaterrendement
      }

      if (gas_tapwaterrendement) {
        const energieinhoud_gas_kwh = useLookup.energieinhoud_gas_kwh
        tapwater_m3 += verbruik_tapwater_kwh / gas_tapwaterrendement / energieinhoud_gas_kwh
      }

      if (warmte_tapwaterrendement) {
        const energieinhoud_warmte_kwh = useLookup.energieinhoud_warmte_kwh
        tapwater_gj += (verbruik_tapwater_kwh / warmte_tapwaterrendement) * energieinhoud_warmte_kwh
      }

      // verlies voorraadvat
      const bron_warm_tapwater = installaties.data.value.bron_warm_tapwater
      const verlies_voorraadvat_via_opwekker = useLookup.installaties[bron_warm_tapwater].verlies_voorraadvat
      const verlies_voorraadvat_kwh = Math.max(0, verlies_voorraadvat_via_opwekker)
      const ventilatie_kwh = summary_klimaatgegevens.totaal_ventilatie > 0 ? useLookup.ventilatie_verbruik['WTW'] : 0

      // hulpenergie verwarming
      const verbruik_hulpenergie_verwarming_kwh = summary_klimaatgegevens.verbruik_hulpenergie_verwarming_kwh

      // verbruik koken
      const type_koken = installaties.data.value.koken
      const verbruik_koken_kwh = useLookup.verbruik_koken
      const energieinhoud_gas_kwh = useLookup.energieinhoud_gas_kwh

      const supertotaal_koken_kwh = verbruik_koken_kwh

      const verbruik_koken_electrisch = type_koken === 'Elektrisch' ? verbruik_koken_kwh : 0
      const verbruik_koken_gas = type_koken === 'Gas' ? verbruik_koken_kwh / energieinhoud_gas_kwh : 0

      // opbrengst zonnepanelen
      // const zonnepanelen = installaties.data.value.zonnepanelen
      let opbrengst_zonnepanelen = 0

      if ('zonnepanelen' in installaties.data.value) {
        const zonnepanelen = installaties.data.value.zonnepanelen
        opbrengst_zonnepanelen += zonnepanelen.reduce((sum, item) => sum + (Number(item.productie) || 0), 0)
      }

      // const opbrengst_zonnepanelen = Number(installaties.data.value.opgewekt_zonnepanelen)

      const totaal_kw = summary_klimaatgegevens.totaal_kw

      const verbruik_totaal_m3 = sum_verwarming_m3 + tapwater_m3 + verbruik_koken_gas
      const verbruik_totaal_gj = sum_verwarming_gj + tapwater_gj

      const verbruik_subtotaal_kwh =
        sum_verwarming_kwh +
        tapwater_kwh +
        verlies_voorraadvat_kwh +
        ventilatie_kwh +
        verbruik_hulpenergie_verwarming_kwh +
        verbruik_koken_electrisch

      // totaal gas & elektrisch
      const opgegeven_elektrisch_verbruik = Number(soort.data.value.verbruik_elektra)
      const opgegeven_elektrisch_minus_opgegeven_pv = opgegeven_elektrisch_verbruik - opbrengst_zonnepanelen

      let eigen_elektrisch_verbruik = opgegeven_elektrisch_verbruik - verbruik_subtotaal_kwh

      if (formulasHuidig) {
        eigen_elektrisch_verbruik = formulasHuidig.value.result.data_total.eigen_elektrisch_verbruik
      }

      const verbruik_totaal_kwh = eigen_elektrisch_verbruik + verbruik_subtotaal_kwh - opbrengst_zonnepanelen

      // direct verbruik & accu
      let accu_historie = 0
      let direct_verbruik_totaal_kwh = 0
      let van_het_net_kwh = 0
      let eigen_behoefte_totaal = 0
      let teruglevering_totaal_kwh = 0
      let opwekking_totaal_kwh = 0

      second_loop.forEach((pair) => {
        const subtotaal_kwh = pair.totaal_kwh
        const zonkracht = pair.zonkracht
        const totaal_zonkracht_wh_m2 = useLookup.klimaatgegevens_sum_zonkracht
        const accu_capaciteit = Number(installaties.data.value.accu)
        const aandeel_direct_verbruik = Number(installaties.data.value.aandeel_direct_verbruik) / 100

        const elektrisch_verbruik =
          sum_verwarming_kwh * (subtotaal_kwh / sum_totaal_kwh_zonder_verlaging) +
          (tapwater_kwh +
            verlies_voorraadvat_kwh +
            ventilatie_kwh +
            verbruik_hulpenergie_verwarming_kwh +
            verbruik_koken_electrisch +
            eigen_elektrisch_verbruik) /
            365

        const opwekking = opbrengst_zonnepanelen * (zonkracht / totaal_zonkracht_wh_m2)

        elektrisch_verbruik + opwekking

        let accu = 0

        if (accu_historie - elektrisch_verbruik + opwekking < 0) {
          // pass
        } else {
          if (accu_historie - elektrisch_verbruik + opwekking > accu_capaciteit) {
            accu += accu_capaciteit
          } else {
            accu += accu_historie - elektrisch_verbruik + opwekking
          }
        }

        accu_historie = JSON.parse(JSON.stringify(accu))

        // const accu = accu_historie
        const accu_factor = accu == accu_capaciteit ? aandeel_direct_verbruik : 1
        const direct_verbruik =
          opwekking > elektrisch_verbruik ? elektrisch_verbruik * accu_factor : opwekking * accu_factor
        const van_het_net = elektrisch_verbruik - direct_verbruik

        const teruglevering = accu < accu_capaciteit ? 0 : opwekking - direct_verbruik

        van_het_net_kwh += van_het_net
        direct_verbruik_totaal_kwh += direct_verbruik
        eigen_behoefte_totaal += elektrisch_verbruik
        teruglevering_totaal_kwh += teruglevering
        opwekking_totaal_kwh += opwekking
      })

      // oppervlaktes
      const totaal_oppervlakte_bouwdelen_bruto = summary_klimaatgegevens['totaal_oppervlakte_bouwdelen_bruto']
      const totaal_oppervlakte_bouwdelen_bruto_streefwaarden_woningisolatie =
        summary_klimaatgegevens['totaal_oppervlakte_bouwdelen_bruto_streefwaarden_woningisolatie']
      const totaal_oppervlakte_deelvlakken = summary_klimaatgegevens['totaal_oppervlakte_deelvlakken']
      const totaal_oppervlakte_bouwdelen_netto = summary_klimaatgegevens['totaal_oppervlakte_bouwdelen_netto']
      const totaal_gebruiksoppervlakte = summary_klimaatgegevens['gebruiksoppervlakte']

      // streefwaarden woningisolatie
      const als = totaal_oppervlakte_bouwdelen_bruto_streefwaarden_woningisolatie
      const ag = totaal_gebruiksoppervlakte
      const compactheid = als / ag
      const categorie_compactheid = compactheid < 1 ? '< 1' : '>= 1'

      const streefwaarden_woningisolatie_woningtype =
        useLookup.streefwaarden_woningisolatie_type_mapper[soort.data.value.soort_woning]

      let streefwaarden_woningisolatie_era =
        useLookup.streefwaarden_woningisolatie_era_mapper[soort.data.value.bouwjaar_woning]

      if (streefwaardenEraOverrule) {
        streefwaarden_woningisolatie_era = 'na 1945'
      }

      let streefwaarden_woningisolatie_max_netto_warmtevraag = getMaxWarmtevraag(
        streefwaarden_woningisolatie_woningtype,
        streefwaarden_woningisolatie_era,
        categorie_compactheid,
        compactheid
      )

      const streefwaarden_woningisolatie_netto_warmtevraag = sum_totaal_kwh_zonder_verlaging / ag
      const streefwaarden_woningisolatie_voldoet =
        streefwaarden_woningisolatie_netto_warmtevraag <= streefwaarden_woningisolatie_max_netto_warmtevraag
          ? 'Ja'
          : 'Nee'

      const data_total = {}
      data_total['klimaatzones'] = klimaatzone_summary

      data_total['totaal_oppervlakte_bouwdelen_bruto'] = totaal_oppervlakte_bouwdelen_bruto
      data_total['totaal_oppervlakte_deelvlakken'] = totaal_oppervlakte_deelvlakken
      data_total['totaal_oppervlakte_bouwdelen_netto'] = totaal_oppervlakte_bouwdelen_netto
      data_total['totaal_kw'] = totaal_kw
      data_total['verbruik_hulpenergie_verwarming_kwh'] = verbruik_hulpenergie_verwarming_kwh
      data_total['opbrengst_zonnepanelen'] = opbrengst_zonnepanelen

      data_total['tapwater_kwh'] = tapwater_kwh
      data_total['tapwater_m3'] = tapwater_m3
      data_total['tapwater_gj'] = tapwater_gj

      data_total['sum_verwarming_kwh'] = sum_verwarming_kwh
      data_total['sum_verwarming_m3'] = sum_verwarming_m3
      data_total['sum_verwarming_gj'] = sum_verwarming_gj

      data_total['verbruik_totaal_m3'] = verbruik_totaal_m3
      data_total['verbruik_totaal_kwh'] = verbruik_totaal_kwh
      data_total['verbruik_totaal_gj'] = verbruik_totaal_gj

      data_total['eigen_elektrisch_verbruik'] = eigen_elektrisch_verbruik

      data_total['benodigd_afgiftevermogen'] = summary_klimaatgegevens['benodigd_afgiftevermogen']
      data_total['beschikbaar_afgiftevermogen'] = summary_klimaatgegevens['beschikbaar_afgiftevermogen']

      data_total['van_het_net_kwh'] = van_het_net_kwh
      data_total['direct_verbruik_totaal_kwh'] = direct_verbruik_totaal_kwh

      data_total['eigen_behoefte_totaal'] = eigen_behoefte_totaal
      data_total['teruglevering_totaal_kwh'] = teruglevering_totaal_kwh
      data_total['opwekking_totaal_kwh'] = opwekking_totaal_kwh

      data_total['werkelijk_gas_m3'] = Number(soort.data.value.verbruik_gas)
      data_total['werkelijk_warmte_gj'] = Number(soort.data.value.verbruik_warmte)
      data_total['werkelijk_elektriciteit_kwh'] = opgegeven_elektrisch_minus_opgegeven_pv

      data_total['streefwaarden_woningisolatie_woningtype'] = streefwaarden_woningisolatie_woningtype
      data_total['streefwaarden_woningisolatie_era'] = streefwaarden_woningisolatie_era
      data_total['streefwaarden_woningisolatie_compactheid'] = compactheid
      data_total['streefwaarden_woningisolatie_netto_warmtevraag'] = streefwaarden_woningisolatie_netto_warmtevraag
      data_total['streefwaarden_woningisolatie_max_netto_warmtevraag'] =
        streefwaarden_woningisolatie_max_netto_warmtevraag
      data_total['streefwaarden_woningisolatie_voldoet'] = streefwaarden_woningisolatie_voldoet
      data_total['streefwaarden_woningisolatie_als'] = als
      data_total['streefwaarden_woningisolatie_ag'] = ag
      data_total['totaal_gebruiksoppervlakte'] = totaal_gebruiksoppervlakte

      data_total['totaal_kwh'] = sum_totaal_kwh
      data_total['totaal_kwh_zonder_verlaging'] = sum_totaal_kwh_zonder_verlaging
      data_total['totaal_kwh_verlaging'] = sum_totaal_kwh_verlaging

      data_total['supertotaal_verwarming_kwh'] = sum_supertotaal_verwarming_kwh
      data_total['supertotaal_verwarming_verlaging_kwh'] = sum_supertotaal_verwarming_verlaging_kwh
      data_total['supertotaal_tapwater_kwh'] = supertotaal_tapwater_kwh
      data_total['supertotaal_koken_kwh'] = supertotaal_koken_kwh
      data_total['supertotaal_verwarming_verlaagd_kwh'] = sum_supertotaal_verwarming_verlaagd_kwh

      data_total['rendement_distributiesysteem'] = rendement_distributiesysteem * 100
      data_total['verlies_voorraadvat_kwh'] = verlies_voorraadvat_kwh
      data_total['ventilatie_kwh'] = ventilatie_kwh
      data_total['verbruik_koken_electrisch'] = verbruik_koken_electrisch
      data_total['verbruik_koken_gas'] = verbruik_koken_gas

      data_total['primaire_vraag_verwarming'] = sum_supertotaal_verwarming_kwh
      data_total['primaire_vraag_tapwater'] = primaire_vraag_tapwater

      return { data_total }
    } else {
      return {}
    }
  })

  return { locked, result }
}
