<template>
  <div class="flex flex-row text-xs" v-if="huidig">
    <div class="flex flex-col">
      <table class="text-gray-500 mr-4 divide-y">
        <!-- title -->
        <tr class="text-left">
          <th class="text-right px-2">Verbruik</th>
          <th class="text-left px-2">Huidig</th>
          <th class="text-left px-2">Nieuw</th>
        </tr>

        <!-- elektrisch -->
        <tr>
          <td class="text-right px-2">Elektriciteit berekend</td>
          <td v-if="showValues" class="text-left px-2">
            {{ useFilter.filter(huidig.result.data_total.verbruik_totaal_kwh, 'roundDynamic') }} kWh
          </td>
          <td v-else class="text-left px-2">- kWh</td>
          <td v-if="showValues" class="text-left px-2">
            {{ useFilter.filter(nieuw.result.data_total.verbruik_totaal_kwh, 'roundDynamic') }} kWh
          </td>
          <td v-else class="text-left px-2">- kWh</td>
        </tr>

        <tr>
          <td class="text-right px-2">Elektriciteit werkelijk</td>
          <td v-if="showValues" class="text-left px-2">
            {{ useFilter.filter(huidig.result.data_total.werkelijk_elektriciteit_kwh, 'roundDynamic') }} kWh
          </td>
          <td v-else class="text-left px-2">- kWh</td>
          <td class="text-left px-2"></td>
        </tr>

        <!-- aardgas -->
        <tr class="text-left">
          <td class="text-right px-2">Aardgas berekend</td>
          <td v-if="showValues" class="text-left px-2">
            {{ useFilter.filter(huidig.result.data_total.verbruik_totaal_m3, 'roundDynamic') }} m3
          </td>
          <td v-else class="text-left px-2">- m3</td>
          <td v-if="showValues" class="text-left px-2">
            {{ useFilter.filter(nieuw.result.data_total.verbruik_totaal_m3, 'roundDynamic') }} m3
          </td>
          <td v-else class="text-left px-2">- m3</td>
        </tr>
        <tr class="text-left">
          <td class="text-right px-2">Aardgas werkelijk</td>
          <td v-if="showValues" class="text-left px-2">
            {{ useFilter.filter(huidig.result.data_total.werkelijk_gas_m3, 'roundDynamic') }} m3
          </td>
          <td v-else class="text-left px-2">- m3</td>
          <td class="text-left px-2"></td>
        </tr>

        <!-- warmte -->
        <tr class="text-left" v-if="!basic">
          <td class="text-right px-2">Warmte berekend</td>
          <td v-if="showValues" class="text-left px-2">
            {{ useFilter.filter(huidig.result.data_total.verbruik_totaal_gj, 'roundDynamic') }} GJ
          </td>
          <td v-else class="text-left px-2">- GJ</td>
          <td v-if="showValues" class="text-left px-2">
            {{ useFilter.filter(nieuw.result.data_total.verbruik_totaal_gj, 'roundDynamic') }} GJ
          </td>
          <td v-else class="text-left px-2">- GJ</td>
        </tr>
        <tr class="text-left" v-if="!basic">
          <td class="text-right px-2">Warmte werkelijk</td>
          <td v-if="showValues" class="text-left px-2">
            {{ useFilter.filter(huidig.result.data_total.werkelijk_warmte_gj, 'roundDynamic') }} GJ
          </td>
          <td v-else class="text-left px-2">- GJ</td>
          <td class="text-left px-2"></td>
        </tr>
      </table>
    </div>
    <table class="text-gray-500" v-if="!basic">
      <tr class="text-left border-b">
        <th class="text-right px-2">Vermogen</th>
        <th class="text-left px-2">Huidig</th>
        <th class="text-left px-2">Nieuw</th>
      </tr>

      <tr class="border-b">
        <td class="text-right px-2">Benodigd vermogen</td>
        <td v-if="showValues" class="text-left px-2">
          {{ useFilter.filter(huidig.result.data_total.benodigd_afgiftevermogen, 'round2') }} kW
        </td>
        <td v-else class="text-left px-2">- kW</td>
        <td v-if="showValues" class="text-left px-2">
          {{ useFilter.filter(nieuw.result.data_total.benodigd_afgiftevermogen, 'round2') }} kW
        </td>
        <td v-else class="text-left px-2">- kW</td>
      </tr>
      <tr>
        <td class="text-right px-2">Beschikbaar vermogen</td>
        <td v-if="showValues" class="text-left px-2">
          {{ useFilter.filter(huidig.result.data_total.beschikbaar_afgiftevermogen, 'round2') }} kW
        </td>
        <td v-else class="text-left px-2">- kW</td>
        <td v-if="showValues" class="text-left px-2">
          {{ useFilter.filter(nieuw.result.data_total.beschikbaar_afgiftevermogen, 'round2') }} kW
        </td>
        <td v-else class="text-left px-2">- kW</td>
      </tr>

      <tr class="text-left divide-none">
        <td class="text-right px-2">&nbsp;</td>
        <td v-if="showValues" class="text-left px-2"></td>
        <td v-else class="text-left px-2"></td>
        <td v-if="showValues" class="text-left px-2"></td>
        <td v-else class="text-left px-2"></td>
      </tr>

      <tr class="text-left">
        <td class="text-right px-2">&nbsp;</td>
        <td v-if="showValues" class="text-left px-2"></td>
        <td v-else class="text-left px-2"></td>
        <td v-if="showValues" class="text-left px-2"></td>
        <td v-else class="text-left px-2"></td>
      </tr>

      <tr class="text-left">
        <td class="text-right px-2">&nbsp;</td>
        <td v-if="showValues" class="text-left px-2"></td>
        <td v-else class="text-left px-2"></td>
        <td v-if="showValues" class="text-left px-2"></td>
        <td v-else class="text-left px-2"></td>
      </tr>

      <tr class="text-left">
        <td class="text-right px-2">&nbsp;</td>
        <td v-if="showValues" class="text-left px-2"></td>
        <td v-else class="text-left px-2"></td>
        <td v-if="showValues" class="text-left px-2"></td>
        <td v-else class="text-left px-2"></td>
      </tr>
    </table>
  </div>
</template>

<script>
import filterHandler from '@/use/filterHandler'

export default {
  props: {
    huidig: {
      type: Object,
      default: () => {},
    },
    nieuw: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showValues: {
      type: Boolean,
      default: false,
    },
    basic: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const useFilter = filterHandler()

    function showData(value) {
      if (props.showValues) {
        return value
      } else {
        return ' - '
      }
    }

    return {
      useFilter,
      showData,
    }
  },
}
</script>
