import backButton from './components/baseBackButton.vue'
import baseAttachments from './components/baseAttachments.vue'
import baseButton from './components/baseButton.vue'
import baseButtonDropDown from './components/baseButtonDropDown.vue'
import baseCalculator from './components/calculator/baseCalculator.vue'
import baseCard from './components/baseCard.vue'
import baseCollapse from './components/baseCollapse.vue'
import baseComments from './components/baseComments.vue'
import baseDateInput from './components/baseDateInput.vue'
import baseField from './components/baseField.vue'
import baseForm from './components/baseForm.vue'
import baseFormCustom from './components/baseFormCustom.vue'
import baseGeolocator from './components/baseGeolocator.vue'
import baseKeyValue from './components/baseKeyValue.vue'
import baseList from './components/baseList.vue'
import baseListInput from './components/baseListInput.vue'
import baseLoader from './components/baseLoader.vue'
import baseModal from './components/baseModal.vue'
import basePageTitle from './components/basePageTitle.vue'
import basePanels from './components/basePanels.vue'
import baseTable from './components/baseTable.vue'
import baseTabs from './components/baseTabs.vue'
import baseToast from './components/baseToast.vue'
import baseTree from './components/baseTree.vue'
import baseTreeNode from './components/baseTreeNode.vue'
import baseSteps from './components/baseSteps.vue'
import featherIcon from './components/baseFeatherIcon.vue'
import stringField from './components/form/stringField.vue'

export default {
  backButton,
  baseAttachments,
  baseButton,
  baseButtonDropDown,
  baseCalculator,
  baseCard,
  baseCollapse,
  baseComments,
  baseDateInput,
  baseField,
  baseForm,
  baseFormCustom,
  baseGeolocator,
  baseKeyValue,
  baseList,
  baseListInput,
  baseLoader,
  baseModal,
  basePageTitle,
  basePanels,
  baseTable,
  baseTabs,
  baseToast,
  baseTree,
  baseTreeNode,
  baseSteps,
  featherIcon,
  stringField,
}
