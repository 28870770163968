<template>
  <baseButton @action="back()" variant="neutral">
    <featherIcon class="w-4 h-4 my-1" icon="ChevronLeftIcon" />
  </baseButton>
</template>

<script>
import { useRouter } from 'vue-router'

export default {
  setup() {
    const router = useRouter()

    function back() {
      router.back()
    }

    return {
      back,
    }
  },
}
</script>
