import ExcelJS from 'exceljs'
import loadingHandler from './loadingHandler'

export default () => {
  const loadHandler = loadingHandler()

  function exportExcel(filename, tablename, records, fields) {
    loadHandler.setLoadingState('export_excel', true)
    const options = {
      filename: filename,
      useStyles: true,
      useSharedStrings: true,
    }

    const workbook = new ExcelJS.Workbook(options)
    const worksheetMain = workbook.addWorksheet(tablename)

    // loop fields
    let cellNumber = 1

    fields.forEach((field) => {
      const label = field.label
      const key = field.key
      const type = field.type

      const titleRow = worksheetMain.getRow(1)

      // write title
      const titleCell = titleRow.getCell(cellNumber)
      titleCell.value = label

      let rowNumber = 2

      // add the rest
      records.forEach((record) => {
        const row = worksheetMain.getRow(rowNumber)
        const cell = row.getCell(cellNumber)
        const value = record[key]

        if (type === 'date' && value) {
          cell.value = new Date(value)
        } else {
          cell.value = value
        }

        rowNumber += 1
      })

      cellNumber += 1
    })

    // set autofilter
    worksheetMain.autoFilter = {
      from: {
        row: 1,
        column: 1,
      },
      to: {
        row: 1,
        column: cellNumber - 1,
      },
    }

    // global formatting
    worksheetMain.columns.forEach((column) => {
      let dataMax = 0
      column.eachCell((cell) => {
        let isDate = false
        let width = 0
        if (cell.value) {
          isDate = cell.type === ExcelJS.ValueType.Date
          width = isDate ? 12 : cell.value.toString().length
        }

        if (width > dataMax) {
          dataMax = width
        }
      })
      if (dataMax) {
        column.width = dataMax + 4
      } else {
        column.width = 10
      }
    })

    // Write workbook
    workbook.xlsx
      .writeBuffer({
        base64: true,
      })
      .then((xls64) => {
        // build anchor tag and attach file (works in chrome)
        const a = document.createElement('a')
        const data = new Blob([xls64], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

        const url = URL.createObjectURL(data)
        a.href = url
        a.download = options.filename
        document.body.appendChild(a)
        a.click()
        setTimeout(() => {
          document.body.removeChild(a)
          window.URL.revokeObjectURL(url)
          loadHandler.setLoadingState('export_excel', false)
        }, 0)
      })
      .catch(() => {})
  }

  return {
    exportExcel,
    loadHandler,
  }
}
