<template>
  <div class="h-full min-h-0" v-if="useContentGuard.canSee('organisation:proces')">
    <div class="h-full">
      <!-- vertical splitter -->
      <div class="flex h-full flex-col">
        <!-- title -->
        <div class="px-20 pt-10">
          <!-- back and title -->
          <div class="flex flex-row justify-between">
            <div class="flex flex-row items-center">
              <span class="mr-6">
                <backButton />
              </span>
              <basePageTitle
                v-if="!useProjectDetails.loadHandler.states.get_details"
                :title="useProjectDetails.project.value.name"
              >
                <p class="text-slate-500 text-xs">
                  {{ useProjectDetails.project.value.description }}
                </p>
              </basePageTitle>

              <base-loader
                class="text-xl text-slate-500 my-auto"
                :loading="useProjectDetails.loadHandler.states.get_details"
              />
            </div>
          </div>

          <baseTabs :handler="tabs" v-show="!useProjectDetails.loadHandler.states.get_details" @changed="onTabChange">
            <template v-slot:panel-overzicht>
              <!-- first row, details and map-->
              <div class="flex flex-row mt-4">
                <!-- detail card -->
                <div class="basis-1/2 mr-4">
                  <baseCard v-if="!useProjectDetails.loadHandler.states.get_details" class="overflow-y-auto">
                    <!-- details -->
                    <base-key-value
                      :data="useProjectDetails.project.value"
                      :fields="useProjectDetails.detailFields.value"
                    />
                  </baseCard>
                </div>

                <div class="basis-1/2">
                  <!-- <baseCard v-if="!useProjectDetails.loadHandler.states.get_details" class="overflow-y-auto"> -->
                  <!-- details -->
                  <div v-show="!useProjectDetails.loadHandler.states.get_details">
                    <div
                      class="w-full h-96 z-0 w-full h-96 z-0 rounded shadow-lg bg-white ml-2"
                      id="map_overview"
                    ></div>
                  </div>

                  <!-- </baseCard> -->
                </div>
              </div>

              <!-- second row -->
              <div class="flex flex-row mt-4">
                <div v-if="!useProjectDetails.loadHandler.states.get_details" class="w-full">
                  <div class="my-8">
                    <basePageTitle title="Adressen" class="my-8"></basePageTitle>
                  </div>
                  <baseTable
                    :fields="useProjectDetails.addressFields.value"
                    :records="useProjectDetails.project.value.addresses"
                    :records-per-page="25"
                    :add-button="true"
                    :edit-button="false"
                    :delete-button="true"
                    :duplicate-button="false"
                    :export-record-button="false"
                    :clickAble="true"
                    :clickCallback="tableActionProject"
                    :loading="useProjectDetails.loadHandler.somethingLoading.value"
                    @add="onOpenAdd"
                    @edit="useProjectDetails.openEdit"
                    @delete="useProjectDetails.unassignAddress"
                  />

                  <!-- add addresses modal -->
                  <baseModal
                    v-if="useProjectDetails.showAddAddresses.value"
                    @close="useProjectDetails.closeAddAddresses()"
                    :title="$translate('Add')"
                    @enter="useProjectDetails.onEnter()"
                    size="xl"
                  >
                    <!-- unassigned addresses table -->
                    <baseTable
                      :fields="useProjectDetails.addressFields.value"
                      :records="useProjectDetails.unassignedAddresses.value"
                      :records-per-page="25"
                      :add-button="false"
                      :edit-button="false"
                      :delete-button="false"
                      :duplicate-button="false"
                      :export-record-button="false"
                      :clickAble="true"
                      :clickCallback="tableActionProject"
                      :selectable="true"
                      :hoverEffect="true"
                      :loading="useProjectDetails.loadHandler.states.get_unassigned"
                      @selection="useProjectDetails.setSelectedUnassignedAddresses"
                    />
                    <template v-slot:buttons>
                      <baseButton
                        @action="useProjectDetails.assignAddresses()"
                        :loading="useProjectDetails.loadHandler.states[`assign_addresses`]"
                      >
                        {{ $translate('Add') }}
                      </baseButton>
                    </template>
                  </baseModal>
                </div>
              </div>
            </template>
            <template v-slot:panel-maatregelen>
              <div class="my-8">
                <basePageTitle title="Categorieën" class="my-8"></basePageTitle>
              </div>
              <!-- categorie table -->
              <baseTable
                :fields="useProjectDetails.maatregelencategorieenFields.value"
                :records="useProjectDetails.maatregelenCategorieen.value"
                :records-per-page="25"
                :add-button="true"
                :edit-button="true"
                :delete-button="true"
                :duplicate-button="false"
                :export-record-button="false"
                :clickAble="true"
                :loading="useProjectDetails.loadHandler.somethingLoading.value"
                @add="useProjectDetails.openAddMaatregelCategorie()"
                @edit="useProjectDetails.openEditMaatregelCategorie"
                @delete="useProjectDetails.deleteMaatregelCategorie"
              />

              <!-- add categorie modal -->
              <baseModal
                v-if="useProjectDetails.showAddmaatregelCategorie.value"
                @close="useProjectDetails.closeAddMaatregelCategorie()"
                :title="$translate('Add')"
                @enter="useProjectDetails.AddMaatregelCategorieOnEnter()"
              >
                <baseForm
                  :handler="useProjectDetails.maatregelCategorieForm"
                  :loading="useProjectDetails.loadHandler.states['add_maatregel_categorie']"
                />
                <template v-slot:buttons>
                  <baseButton
                    @action="useProjectDetails.addMaatregelCategorie()"
                    :loading="useProjectDetails.loadHandler.states[`add_maatregel_categorie`]"
                  >
                    {{ $translate('Add') }}
                  </baseButton>
                </template>
              </baseModal>

              <!-- edit categorie modal -->
              <baseModal
                v-if="useProjectDetails.showEditmaatregelCategorie.value"
                @close="useProjectDetails.closeEditMaatregelCategorie()"
                :title="$translate('Edit')"
                @enter="useProjectDetails.EditMaatregelCategorieOnEnter()"
              >
                <baseForm
                  :handler="useProjectDetails.maatregelCategorieEditForm"
                  :loading="useProjectDetails.loadHandler.states['edit_maatregel_categorie']"
                />
                <template v-slot:buttons>
                  <baseButton
                    @action="useProjectDetails.editMaatregelCategorie()"
                    :loading="useProjectDetails.loadHandler.states[`edit_maatregel_categorie`]"
                  >
                    {{ $translate('Edit') }}
                  </baseButton>
                </template>
              </baseModal>
            </template>
            <template v-slot:panel-stappen>
              <div class="my-8">
                <basePageTitle title="Stappen" class="my-8"></basePageTitle>
              </div>
              <!-- container -->
              <div class="flex flex-row">
                <div class="basis-1/5">
                  <baseList :items="useProjectDetails.steps.value" class="mr-2" has-gutters @changed="onListChange">
                    <template v-slot:default="{ item }">
                      <!-- Custom rendering for each item -->
                      <div>
                        <!-- Access properties of 'item' as needed -->
                        <p class="text-gray-500 text-sm">{{ item.name }}</p>
                        <!-- Example property -->
                        <!-- You can include other components or elements here -->
                      </div>
                    </template>
                  </baseList>

                  <baseButton @action="useProjectDetails.openAddStep()" class="mt-2" size="small"> + </baseButton>

                  <!-- add modal -->
                  <baseModal
                    v-if="useProjectDetails.showAddStep.value"
                    @close="useProjectDetails.closeAddStep()"
                    :title="$translate('Add')"
                  >
                    <baseForm
                      :handler="useProjectDetails.addStepForm"
                      :loading="useProjectDetails.loadHandler.states['add_step']"
                    />
                    <template v-slot:buttons>
                      <!-- add -->
                      <baseButton
                        @action="useProjectDetails.addStep()"
                        :loading="useProjectDetails.loadHandler.states[`add_step`]"
                      >
                        {{ $translate('Add') }}
                      </baseButton>
                    </template>
                  </baseModal>
                </div>
                <div class="basis-4/5">
                  <basePanels :panels="panels" :currentStepId="currentStepId" class="text-gray-500">
                    <template
                      v-for="(step, stepIndex) in useProjectDetails.steps.value"
                      :key="step.step_id"
                      v-slot:[step.step_id]
                    >
                      <div>{{ step.name }}</div>
                      <div>{{ step.description }}</div>
                      <!-- You can customize the content further based on the panel or fetch content dynamically -->
                      <baseButton @action="onDeleteStep(stepIndex)" class="mt-2" size="small" variant="danger">
                        verwijderen
                      </baseButton>
                    </template>
                  </basePanels>
                </div>
              </div>
            </template>

            <template v-slot:panel-data>
              <div class="my-8">
                <basePageTitle title="Data" class="my-8"></basePageTitle>
              </div>
              <baseLoader class="text-slate-500" :loading="useExporData.loadHandler.states.get_records" />

              <!-- algemeen -->
              <baseTree :handler="treeAlgemeen"></baseTree>

              <!-- soort woning -->
              <baseTree :handler="treeSoortWoning"></baseTree>

              <!-- intallaties huidig -->
              <baseTree :handler="treeInstallatiesHuidig"></baseTree>

              <!-- intallaties nieuw -->
              <baseTree :handler="treeInstallatiesNieuw"></baseTree>

              <!-- results huidig -->
              <baseTree :handler="treeResultsHuidig"></baseTree>

              <!-- results nieuw -->
              <baseTree :handler="treeResultsNieuw"></baseTree>

              <!-- results huidig 20 -->
              <baseTree :handler="treeResultsHuidig20"></baseTree>

              <!-- results nieuw 20 -->
              <baseTree :handler="treeResultsNieuw20"></baseTree>

              <!-- maatregel categorieen -->
              <baseTree :handler="treeMaatregelCategorieen"></baseTree>

              <baseButton class="mt-2" size="small" @action="exportData()">Exporteer</baseButton>
            </template>
          </baseTabs>
        </div>
      </div>
    </div>

    <!-- confirmer -->
    <confimationModal :handler="confirm" />
  </div>
</template>

<script>
import { useRouter, useRoute } from 'vue-router'
import { onMounted, ref, watch, nextTick, computed, reactive } from 'vue'
import projectDetailHandler from '@/use/projectDetailHandler'
import nodeMapHandler from '@/use/nodeMapHandler'
import contentGuardHandler from '@/use/contentGuardHandler'
import nodeMapHandlerMultiple from '@/use/nodeMapHandlerMultiple'
import tabHandler from '@/use/tabHandler'
import confirmationHandler from '@/use/confirmationHandler'
import confimationModal from '@/components/extended/confirmationModal.vue'
import treeHandler from '@/use/treeHandler'
import exportProjectDataHandler from '@/use/exportProjectDataHandler'

export default {
  setup() {
    const router = useRouter()
    const route = useRoute()
    const useProjectDetails = projectDetailHandler()
    const useNodeMap = nodeMapHandler()
    const useContentGuard = contentGuardHandler()
    const useNodeMapMultiple = nodeMapHandlerMultiple()
    const tabs = tabHandler()
    const currentStepId = ref(null)
    const overviewMap = ref(null)
    const overviewMapMarkers = ref(null)
    const confirm = confirmationHandler()
    const useExporData = exportProjectDataHandler()

    const algemeenCheckData = reactive([
      {
        id: 'algemeen',
        label: 'Algemeen',
        container: true,
        checked: false,
        collapsed: true,
        children: [
          {
            id: 'description',
            label: 'Omschrijving',
            dataPath: ['description'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'name',
            label: 'Naam',
            dataPath: ['name'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'address',
            label: 'Adres',
            dataPath: ['address'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'zip',
            label: 'Postcode',
            dataPath: ['zip'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'place',
            label: 'Plaats',
            dataPath: ['place'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'date_created',
            label: 'Aanmaakdatum',
            dataPath: ['date_created'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'soort_woning',
            label: 'Soort woning',
            dataPath: ['config', 'soort_woning', 'soort_woning'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'warmteopwekker',
            label: 'Warmteopwekker',
            dataPath: ['config', 'installaties', 'warmteopwekker'],
            checked: false,
            collapsed: true,
            children: [],
          },
        ],
      },
    ])

    const soortWoningCheckData = reactive([
      {
        id: 'soort_woning',
        label: 'Soort woning',
        container: true,
        checked: false,
        collapsed: true,
        children: [
          {
            id: 'ambitie',
            label: 'Ambitie',
            dataPath: ['config', 'soort_woning', 'ambitie'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'bouwjaar_woning',
            label: 'Bouwjaar woning',
            dataPath: ['config', 'soort_woning', 'bouwjaar_woning'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'bouwlagen',
            label: 'Bouwlagen',
            dataPath: ['config', 'soort_woning', 'bouwlagen'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'gezinssamenstelling',
            label: 'Gezinssamenstelling',
            dataPath: ['config', 'soort_woning', 'gezinssamenstelling'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'jaar_verbouwing',
            label: 'Jaar verbouwing',
            dataPath: ['config', 'soort_woning', 'jaar_verbouwing'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'kruipruimte',
            label: 'Kruipruimte',
            dataPath: ['config', 'soort_woning', 'kruipruimte'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'omgeving',
            label: 'Omgeving',
            dataPath: ['config', 'installaties', 'omgeving'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'prijs_elektra',
            label: 'Prijs elektra',
            dataPath: ['config', 'soort_woning', 'prijs_elektra'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'prijs_gas',
            label: 'Prijs gas',
            dataPath: ['config', 'soort_woning', 'prijs_gas'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'prijs_warmte',
            label: 'Prijs warmte',
            dataPath: ['config', 'soort_woning', 'prijs_warmte'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'prijsstijging_elektra',
            label: 'Prijsstijging elektra',
            dataPath: ['config', 'soort_woning', 'prijsstijging_elektra'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'prijsstijging_gas',
            label: 'Prijsstijging gas',
            dataPath: ['config', 'soort_woning', 'prijsstijging_gas'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'prijsstijging_warmte',
            label: 'Prijsstijging warmte',
            dataPath: ['config', 'soort_woning', 'prijsstijging_warmte'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'soort_dak',
            label: 'Soort dak',
            dataPath: ['config', 'soort_woning', 'soort_dak'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'soort_dakbedekking',
            label: 'Soort dakbedekking',
            dataPath: ['config', 'soort_woning', 'soort_dakbedekking'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'soort_muren',
            label: 'Soort muren',
            dataPath: ['config', 'soort_woning', 'soort_muren'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'soort_vloer_begane_grond',
            label: 'Soort vloer begane grond',
            dataPath: ['config', 'soort_woning', 'soort_vloer_begane_grond'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'soort_woning',
            label: 'Soort woning',
            dataPath: ['config', 'soort_woning', 'soort_woning'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'teruglevertarief_elektra',
            label: 'Teruglevertarief elektra',
            dataPath: ['config', 'soort_woning', 'teruglevertarief_elektra'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'uitstoot_warmtenet',
            label: 'Uitstoot warmtenet',
            dataPath: ['config', 'soort_woning', 'uitstoot_warmtenet'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'verbruik_elektra',
            label: 'Verbruik elektra',
            dataPath: ['config', 'soort_woning', 'verbruik_elektra'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'verbruik_gas',
            label: 'Verbruik gas',
            dataPath: ['config', 'soort_woning', 'verbruik_gas'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'verbruik_warmte',
            label: 'Verbruik warmte',
            dataPath: ['config', 'soort_woning', 'verbruik_warmte'],
            checked: false,
            collapsed: true,
            children: [],
          },
        ],
      },
    ])

    const installatiesHuidigCheckData = reactive([
      // Sample initial data
      {
        id: 'installaties_huidig',
        label: 'Installaties huidig',
        container: true,
        checked: false,
        collapsed: true,
        children: [
          {
            id: 'huidig_aandeel_direct_verbruik',
            label: 'Aandeel direct verbruik (huidig)',
            dataPath: ['config', 'installaties', 'aandeel_direct_verbruik'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'huidig_aansluitwaarde_elektra',
            label: 'Aansluitwaarde elektra (huidig)',
            dataPath: ['config', 'installaties', 'aansluitwaarde_elektra'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'huidig_accu',
            label: 'Accu (huidig)',
            dataPath: ['config', 'installaties', 'accu'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'huidig_bron_warm_tapwater',
            label: 'Bron warm tapwater (huidig)',
            dataPath: ['config', 'installaties', 'bron_warm_tapwater'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'huidig_koken',
            label: 'Koken (huidig)',
            dataPath: ['config', 'installaties', 'koken'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'huidig_leidingen_buiten_schil',
            label: 'Leidingen buiten schil (huidig)',
            dataPath: ['config', 'installaties', 'leidingen_buiten_schil'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'huidig_luchtdichtheid',
            label: 'Luchtdichtheid (huidig)',
            dataPath: ['config', 'installaties', 'luchtdichtheid'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'huidig_nachtverlaging',
            label: 'Nachtverlaging (huidig)',
            dataPath: ['config', 'installaties', 'nachtverlaging'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'huidig_opwekking_binnen_thermische_schil',
            label: 'Opwekking binnen thermische schil (huidig)',
            dataPath: ['config', 'installaties', 'opwekking_binnen_thermische_schil'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'huidig_ventilatie_mechanisch',
            label: 'Ventilatie mechanisch (huidig)',
            dataPath: ['config', 'installaties', 'ventilatie_mechanisch'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'huidig_ventilatie_natuurlijk',
            label: 'Ventilatie natuurlijk (huidig)',
            dataPath: ['config', 'installaties', 'ventilatie_natuurlijk'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'huidig_ventilatie_wtw',
            label: 'Ventilatie wtw (huidig)',
            dataPath: ['config', 'installaties', 'ventilatie_wtw'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'huidig_verbruiksprofiel_tapwater',
            label: 'Verbruiksprofiel tapwater (huidig)',
            dataPath: ['config', 'installaties', 'verbruiksprofiel_tapwater'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'huidig_warmteopwekker',
            label: 'Warmteopwekker (huidig)',
            dataPath: ['config', 'installaties', 'warmteopwekker'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'huidig_zonneboiler_voor_bron_warm_tapwater',
            label: 'Zonneboiler voor bron warm tapwater (huidig)',
            dataPath: ['config', 'installaties', 'zonneboiler_voor_bron_warm_tapwater'],
            checked: false,
            collapsed: true,
            children: [],
          },
        ],
      },
    ])

    const installatiesNieuwCheckData = reactive([
      // Sample initial data
      {
        id: 'installaties_nieuw',
        label: 'Installaties nieuw',
        container: true,
        checked: false,
        collapsed: true,
        children: [
          {
            id: 'nieuw_aandeel_direct_verbruik',
            label: 'Aandeel direct verbruik (nieuw)',
            dataPath: ['config', 'installaties_nieuw', 'aandeel_direct_verbruik'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'nieuw_aansluitwaarde_elektra',
            label: 'Aansluitwaarde elektra (nieuw)',
            dataPath: ['config', 'installaties_nieuw', 'aansluitwaarde_elektra'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'nieuw_accu',
            label: 'Accu (nieuw)',
            dataPath: ['config', 'installaties_nieuw', 'accu'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'nieuw_bron_warm_tapwater',
            label: 'Bron warm tapwater (nieuw)',
            dataPath: ['config', 'installaties_nieuw', 'bron_warm_tapwater'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'nieuw_koken',
            label: 'Koken (nieuw)',
            dataPath: ['config', 'installaties_nieuw', 'koken'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'nieuw_leidingen_buiten_schil',
            label: 'Leidingen buiten schil (nieuw)',
            dataPath: ['config', 'installaties_nieuw', 'leidingen_buiten_schil'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'nieuw_luchtdichtheid',
            label: 'Luchtdichtheid (nieuw)',
            dataPath: ['config', 'installaties_nieuw', 'luchtdichtheid'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'nieuw_nachtverlaging',
            label: 'Nachtverlaging (nieuw)',
            dataPath: ['config', 'installaties_nieuw', 'nachtverlaging'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'nieuw_opwekking_binnen_thermische_schil',
            label: 'Opwekking binnen thermische schil (nieuw)',
            dataPath: ['config', 'installaties_nieuw', 'opwekking_binnen_thermische_schil'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'nieuw_ventilatie_mechanisch',
            label: 'Ventilatie mechanisch (nieuw)',
            dataPath: ['config', 'installaties_nieuw', 'ventilatie_mechanisch'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'nieuw_ventilatie_natuurlijk',
            label: 'Ventilatie natuurlijk (nieuw)',
            dataPath: ['config', 'installaties_nieuw', 'ventilatie_natuurlijk'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'nieuw_ventilatie_wtw',
            label: 'Ventilatie wtw (nieuw)',
            dataPath: ['config', 'installaties_nieuw', 'ventilatie_wtw'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'nieuw_verbruiksprofiel_tapwater',
            label: 'Verbruiksprofiel tapwater (nieuw)',
            dataPath: ['config', 'installaties_nieuw', 'verbruiksprofiel_tapwater'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'nieuw_warmteopwekker',
            label: 'Warmteopwekker (nieuw)',
            dataPath: ['config', 'installaties_nieuw', 'warmteopwekker'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'nieuw_zonneboiler_voor_bron_warm_tapwater',
            label: 'Zonneboiler voor bron warm tapwater (nieuw)',
            dataPath: ['config', 'installaties_nieuw', 'zonneboiler_voor_bron_warm_tapwater'],
            checked: false,
            collapsed: true,
            children: [],
          },
        ],
      },
    ])

    const resultsHuidigCheckData = reactive([
      {
        id: 'results_huidig',
        label: 'Resultaten huidig',
        container: true,
        checked: false,
        collapsed: true,
        children: [
          {
            id: 'huidig_benodigd_afgiftevermogen (Huidig)',
            label: 'Benodigd afgiftevermogen',
            dataPath: ['results', 'huidig', 'benodigd_afgiftevermogen'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'huidig_beschikbaar_afgiftevermogen (Huidig)',
            label: 'Beschikbaar afgiftevermogen',
            dataPath: ['results', 'huidig', 'beschikbaar_afgiftevermogen'],
            checked: false,
            collapsed: true,
            children: [],
          },
        ],
      },
    ])

    const resultsNieuwCheckData = reactive([
      {
        id: 'results_nieuw',
        label: 'Resultaten nieuw',
        container: true,
        checked: false,
        collapsed: true,
        children: [
          {
            id: 'nieuw_benodigd_afgiftevermogen',
            label: 'Benodigd afgiftevermogen (nieuw)',
            dataPath: ['results', 'nieuw', 'benodigd_afgiftevermogen'],
            checked: false,
            collapsed: true,
            children: [],
          },
          {
            id: 'nieuw_beschikbaar_afgiftevermogen',
            label: 'Beschikbaar afgiftevermogen (nieuw)',
            dataPath: ['results', 'nieuw', 'beschikbaar_afgiftevermogen'],
            checked: false,
            collapsed: true,
            children: [],
          },
        ],
      },
    ])

    const resultsHuidig20CheckData = reactive([
      {
        id: 'results_huidig_20',
        label: 'Resultaten huidig (setpoint 20)',
        container: true,
        checked: false,
        collapsed: true,
        children: [
          {
            id: 'huidig_streefwaarden_woningisolatie',
            label: 'Streefwaarden woningisolatie',
            checked: false,
            container: true,
            collapsed: true,
            children: [
              {
                id: 'huidig_ag',
                label: 'Ag (huidig)',
                dataPath: ['results', 'huidig_20', 'streefwaarden_woningisolatie_ag'],
                checked: false,
                collapsed: true,
                children: [],
              },
              {
                id: 'huidig_als',
                label: 'Als (huidig)',
                dataPath: ['results', 'huidig_20', 'streefwaarden_woningisolatie_als'],
                checked: false,
                collapsed: true,
                children: [],
              },
              {
                id: 'huidig_compactheid',
                label: 'Compactheid (huidig)',
                dataPath: ['results', 'huidig_20', 'streefwaarden_woningisolatie_compactheid'],
                checked: false,
                collapsed: true,
                children: [],
              },
              {
                id: 'huidig_era',
                label: 'Era (huidig)',
                dataPath: ['results', 'huidig_20', 'streefwaarden_woningisolatie_era'],
                checked: false,
                collapsed: true,
                children: [],
              },
              {
                id: 'huidig_max_netto_warmtevraag',
                label: 'Max netto warmtevraag (huidig)',
                dataPath: ['results', 'huidig_20', 'streefwaarden_woningisolatie_max_netto_warmtevraag'],
                checked: false,
                collapsed: true,
                children: [],
              },
              {
                id: 'huidig_netto_warmtevraag',
                label: 'Netto warmtevraag (huidig)',
                dataPath: ['results', 'huidig_20', 'streefwaarden_woningisolatie_netto_warmtevraag'],
                checked: false,
                collapsed: true,
                children: [],
              },
              {
                id: 'huidig_voldoet',
                label: 'Voldoet (huidig)',
                dataPath: ['results', 'huidig_20', 'streefwaarden_woningisolatie_voldoet'],
                checked: false,
                collapsed: true,
                children: [],
              },
              {
                id: 'huidig_woningtype',
                label: 'Woningtype (huidig)',
                dataPath: ['results', 'huidig_20', 'streefwaarden_woningisolatie_woningtype'],
                checked: false,
                collapsed: true,
                children: [],
              },
            ],
          },
        ],
      },
    ])

    const resultsNieuw20CheckData = reactive([
      {
        id: 'results_nieuw_20',
        label: 'Resultaten nieuw (setpoint 20)',
        container: true,
        checked: false,
        collapsed: true,
        children: [
          {
            id: 'nieuw_streefwaarden_woningisolatie',
            label: 'Streefwaarden woningisolatie',
            checked: false,
            container: true,
            collapsed: true,
            children: [
              {
                id: 'nieuw_ag',
                label: 'Ag (nieuw)',
                dataPath: ['results', 'nieuw_20', 'streefwaarden_woningisolatie_ag'],
                checked: false,
                collapsed: true,
                children: [],
              },
              {
                id: 'nieuw_als',
                label: 'Als (nieuw)',
                dataPath: ['results', 'nieuw_20', 'streefwaarden_woningisolatie_als'],
                checked: false,
                collapsed: true,
                children: [],
              },
              {
                id: 'nieuw_compactheid',
                label: 'Compactheid (nieuw)',
                dataPath: ['results', 'nieuw_20', 'streefwaarden_woningisolatie_compactheid'],
                checked: false,
                collapsed: true,
                children: [],
              },
              {
                id: 'nieuw_era',
                label: 'Era (nieuw)',
                dataPath: ['results', 'nieuw_20', 'streefwaarden_woningisolatie_era'],
                checked: false,
                collapsed: true,
                children: [],
              },
              {
                id: 'nieuw_max_netto_warmtevraag',
                label: 'Max netto warmtevraag (nieuw)',
                dataPath: ['results', 'nieuw_20', 'streefwaarden_woningisolatie_max_netto_warmtevraag'],
                checked: false,
                collapsed: true,
                children: [],
              },
              {
                id: 'nieuw_netto_warmtevraag',
                label: 'Netto warmtevraag (nieuw)',
                dataPath: ['results', 'nieuw_20', 'streefwaarden_woningisolatie_netto_warmtevraag'],
                checked: false,
                collapsed: true,
                children: [],
              },
              {
                id: 'nieuw_voldoet',
                label: 'Voldoet (nieuw)',
                dataPath: ['results', 'nieuw_20', 'streefwaarden_woningisolatie_voldoet'],
                checked: false,
                collapsed: true,
                children: [],
              },
              {
                id: 'nieuw_woningtype',
                label: 'Woningtype (nieuw)',
                dataPath: ['results', 'nieuw_20', 'streefwaarden_woningisolatie_woningtype'],
                checked: false,
                collapsed: true,
                children: [],
              },
            ],
          },
        ],
      },
    ])

    const maatregelCategorieenCheckData = reactive([
      {
        id: 'maatregel_categorieen',
        label: 'Maatregelcategorieen',
        container: true,
        checked: false,
        collapsed: true,
        children: [],
      },
    ])

    const treeAlgemeen = treeHandler(algemeenCheckData)
    const treeSoortWoning = treeHandler(soortWoningCheckData)
    const treeInstallatiesHuidig = treeHandler(installatiesHuidigCheckData)
    const treeInstallatiesNieuw = treeHandler(installatiesNieuwCheckData)
    const treeResultsHuidig20 = treeHandler(resultsHuidig20CheckData)
    const treeResultsNieuw20 = treeHandler(resultsNieuw20CheckData)
    const treeResultsHuidig = treeHandler(resultsHuidigCheckData)
    const treeResultsNieuw = treeHandler(resultsNieuwCheckData)
    const treeMaatregelCategorieen = treeHandler(maatregelCategorieenCheckData)

    const tabOptions = {
      tabs: [
        { id: 'overzicht', label: 'Overzicht', active: true },
        { id: 'maatregelen', label: 'Maatregelen', active: true },
        { id: 'stappen', label: 'Stappen', active: true },
        { id: 'data', label: 'Data', active: true },
      ],
    }

    onMounted(() => {
      const payload = {
        object_id: route.params.objectId,
      }

      // get details
      useProjectDetails.getDetails(payload).then(() => {
        recordsLoaded.value = true
        const children = loadMaatregelCategorieCheckData()
        maatregelCategorieenCheckData[0].children = children
        // reattach paths
        treeMaatregelCategorieen.attachPaths(maatregelCategorieenCheckData)
        tabs.create(tabOptions)
      })
    })

    function onOpenAdd() {
      useProjectDetails.openAddAddresses()
    }

    function tableActionProject(payload) {
      router.push({
        name: 'address',
        params: { objectId: payload['object_id'] },
      })
    }

    function loadMaatregelCategorieCheckData() {
      const maatregelCategorieen = useProjectDetails.maatregelenCategorieen.value
      const maatregelCategorieenCheckData = maatregelCategorieen.map((item) => {
        return {
          id: item.omschrijving,
          label: item.omschrijving,
          dataPath: ['maatregelen', item.omschrijving],
          checked: false,
          collapsed: true,
          children: [],
        }
      })
      return maatregelCategorieenCheckData
    }

    const recordsLoaded = ref(false)
    const mapInitialized = ref(false)
    const currentTab = ref(null)

    // Reactively watch for the right conditions to initialize the map
    watch(
      [recordsLoaded, currentTab],
      ([newRecordsLoaded, newCurrentTab]) => {
        nextTick(() => {
          if (newRecordsLoaded && newCurrentTab === 'overzicht' && !mapInitialized.value) {
            const settings = {
              markerType: 'pinMarker',
              nodes: useProjectDetails.project.value.addresses,
            }
            const { map, marker } = useNodeMapMultiple.renderMap('map_overview', 'minimap', false, settings)
            overviewMap.value = map
            overviewMapMarkers.value = marker
            mapInitialized.value = true
          }
        })
      },
      {
        immediate: true,
      }
    )

    async function onTabChange(changeInfo) {
      const { source, target } = changeInfo
      currentTab.value = target

      if (source === 'overzicht') {
        mapInitialized.value = false
      }
    }

    function onListChange(payload) {
      currentStepId.value = payload.target.step_id
    }

    function transformPanelData(inputData) {
      return inputData.map((item, index) => {
        // Use the existing step_id if present, otherwise generate a new one
        const stepId = item.step_id ? item.step_id : `panel${index + 1}`
        return {
          step_id: stepId,
        }
      })
    }

    const panels = computed(() => {
      const steps = useProjectDetails.steps.value
      return transformPanelData(steps)
    })

    async function onDeleteStep(stepindex) {
      const ok = await confirm.open({
        title: 'Opmerking verwijderen?',
        message: 'Let op, de gegevens worden permanent verwijderd',
      })
      if (ok) {
        useProjectDetails.deleteStep(stepindex)
      }
    }

    // function collectLabels(node) {
    //   let labels = []
    //   if (node.checked) {
    //     labels.push(node.label)
    //   }
    //   for (let child of node.children) {
    //     labels = labels.concat(collectLabels(child))
    //   }
    //   return labels
    // }

    // const collectedData = computed(() => {
    //   let data = []
    //   for (let tree of [treeAlgemeen]) {
    //     for (let node of tree) {
    //       data = data.concat(collectLabels(node))
    //     }
    //   }
    //   return data
    // })

    function exportData() {
      // Initialize empty objects to hold the combined paths and labels
      const combinedPaths = {}
      const combinedLabels = {}

      // Function to merge paths and labels from a tree's checkedPaths
      const mergeCheckedPaths = (checkedPathsObject) => {
        Object.assign(combinedPaths, checkedPathsObject.paths)
        Object.assign(combinedLabels, checkedPathsObject.labels)
      }

      // Merge checked paths and labels from all trees
      const trees = [
        treeAlgemeen.checkedPaths.value,
        treeSoortWoning.checkedPaths.value,
        treeInstallatiesHuidig.checkedPaths.value,
        treeInstallatiesNieuw.checkedPaths.value,
        treeResultsHuidig20.checkedPaths.value,
        treeResultsNieuw20.checkedPaths.value,
        treeResultsHuidig.checkedPaths.value,
        treeResultsNieuw.checkedPaths.value,
        treeMaatregelCategorieen.checkedPaths.value,
      ]

      trees.forEach((treeCheckedPaths) => mergeCheckedPaths(treeCheckedPaths))

      const treeData = { paths: combinedPaths, labels: combinedLabels }

      useExporData.getProjectSurveys(useProjectDetails.project.value.object_id, treeData, useProjectDetails.project)
    }

    return {
      route,
      useNodeMap,
      useContentGuard,
      useProjectDetails,
      onOpenAdd,
      tableActionProject,
      tabs,
      onTabChange,
      recordsLoaded,
      mapInitialized,
      currentTab,
      onListChange,
      panels,
      currentStepId,
      onDeleteStep,
      confirm,
      algemeenCheckData,
      soortWoningCheckData,
      installatiesHuidigCheckData,
      treeAlgemeen,
      treeSoortWoning,
      treeInstallatiesHuidig,
      treeInstallatiesNieuw,
      treeResultsHuidig20,
      treeResultsNieuw20,
      treeResultsHuidig,
      treeResultsNieuw,
      exportData,
      maatregelCategorieenCheckData,
      treeMaatregelCategorieen,
      useExporData,
    }
  },
  components: {
    confimationModal,
  },
}
</script>
