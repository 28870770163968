export const draggable = {
  mounted(el) {
    el.style.cursor = 'grab'
    el.style.position = 'absolute'
    el.style.transition = 'none'

    const onMove = (event) => {
      const deltaX = event.clientX - el.startX
      const deltaY = event.clientY - el.startY

      const viewportWidth = document.documentElement.clientWidth
      const viewportHeight = document.documentElement.clientHeight

      const rect = el.getBoundingClientRect()
      const elementWidth = rect.width
      const elementHeight = rect.height

      const buffer = 20 // Define the buffer size in pixels

      const leftBound = buffer
      const rightBound = viewportWidth - elementWidth - buffer
      const topBound = buffer
      const bottomBound = viewportHeight - elementHeight - buffer

      const restrictedX = Math.min(Math.max(deltaX, leftBound), rightBound)
      const restrictedY = Math.min(Math.max(deltaY, topBound), bottomBound)

      el.style.left = `${restrictedX}px`
      el.style.top = `${restrictedY}px`
    }

    el.onmousedown = (event) => {
      if (event.button !== 0) return // Only left button
      el.style.cursor = 'grabbing'
      el.startX = event.clientX - el.getBoundingClientRect().left
      el.startY = event.clientY - el.getBoundingClientRect().top
      document.addEventListener('mousemove', onMove)
      document.addEventListener(
        'mouseup',
        () => {
          el.style.cursor = 'grab'
          document.removeEventListener('mousemove', onMove)
        },
        { once: true }
      )
    }
  },
}
