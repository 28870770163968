<template>
  <div class="h-full min-h-0">
    <div class="h-full">
      <!-- vertical splitter -->
      <div class="flex h-full flex-col">
        <!-- title -->
        <div class="px-20 pt-10">
          <!-- back and title -->
          <div class="flex flex-row justify-between">
            <div class="flex flex-row items-center">
              <span class="mr-6">
                <backButton />
              </span>
              <basePageTitle
                v-if="!useOrganisationDetails.loadHandler.states.get_organisation_details"
                :title="useOrganisationDetails.org_name.value"
                :subtitle="route.params.objectId"
              />
              <base-loader
                class="text-xl text-slate-500 my-auto"
                :loading="useOrganisationDetails.loadHandler.states.get_organisation_details"
              />
            </div>
          </div>

          <!-- algemeen -->
          <baseCard
            class="max-h-full overflow-y-auto"
            v-if="!useOrganisationDetails.loadHandler.states.get_organisation_details"
          >
            <baseButton
              @action="useOrganisationDetails.updateOrganisation()"
              :loading="useOrganisationDetails.loadHandler.states.update_organisation"
              >Opslaan</baseButton
            >
            <baseForm :handler="useOrganisationDetails.organisationForm" />
          </baseCard>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { onMounted } from 'vue'
import organisationDetailHandler from '@/use/organisationDetailHandler'

export default {
  setup() {
    const route = useRoute()
    const useOrganisationDetails = organisationDetailHandler()

    onMounted(() => {
      const payload = {
        object_id: route.params.objectId,
      }
      useOrganisationDetails.getDetails(payload)
    })

    return {
      route,
      useOrganisationDetails,
    }
  },
}
</script>
