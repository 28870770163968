const baseUrl = 'https://api.pdok.nl/bzk/locatieserver/search/v3_1'

export default () => {
  function suggest(payload) {
    const url = new URL(`${baseUrl}/suggest`)
    url.search = new URLSearchParams({ q: payload, fq: ['type:adres'] })

    return fetch(url).then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json()
    })
  }

  function lookup(payload) {
    const url = new URL(`${baseUrl}/lookup`)
    url.search = new URLSearchParams({ id: payload })

    return fetch(url).then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json()
    })
  }

  return {
    suggest,
    lookup,
  }
}
