<template>
  <div>
    <baseCard>
      <div class="flex flex-row gap-4 text-sm">
        <div class="flex flex-col flex-none">
          <div v-for="(step, index) in stappen" :key="step.name">
            <div
              class="flex flex-row align-middle items-center hover:bg-gray-50 p-2 mr-4 cursor-pointer rounded-lg"
              @click="selectedStep = index"
            >
              <div
                v-if="finishedSteps.includes(step.step_id)"
                :class="`${backGroundColor} ${textColor}`"
                class="flex justify-center items-center w-12 h-12 rounded-full opacity-50"
              >
                <featherIcon class="w-5 h-5" icon="CheckIcon" />
              </div>
              <div
                v-else-if="step.step_id === currentStep.step_id"
                class="border-2 border-sky-700 flex justify-center items-center w-12 h-12 rounded-full opacity-100"
              >
                <featherIcon class="w-5 h-5 text-sky-700" icon="BriefcaseIcon" />
              </div>
              <div
                v-else
                class="border-2 border-gray-300 flex justify-center items-center w-12 h-12 rounded-full opacity-100"
              >
                <featherIcon class="w-5 h-5 text-gray-300" icon="BriefcaseIcon" />
              </div>
              <div v-if="selectedStep === index" class="pl-4 text-gray-700 font-bold">
                {{ step.name }}
              </div>
              <div v-else class="pl-4 text-gray-500">{{ step.name }}</div>
            </div>
            <div v-if="index + 1 !== stappen.length" class="w-16">
              <div
                v-if="finishedSteps.includes(step.step_id)"
                class="w-0.5 bg-sky-700 h-full mx-auto my-0 min-h-[24px] opacity-50"
              ></div>
              <div v-else class="w-0.5 bg-gray-300 h-full mx-auto my-0 min-h-[24px]"></div>
            </div>
          </div>
        </div>
        <div class="flex flex-col flex-initial text-gray-500">
          <basePageTitle :title="stappen[selectedStep].name"> </basePageTitle>
          <div class="mt-4">{{ stappen[selectedStep].description }}</div>
          <div class="mt-4">
            <baseButton
              v-if="stappen[selectedStep].step_id === currentStep.step_id && selectedStep !== stappen.length"
              size="small"
              @action="finishStep()"
            >
              Voltooien
            </baseButton>
            <baseButton
              v-else-if="finishedSteps[finishedSteps.length - 1] === stappen[selectedStep].step_id"
              size="small"
              @action="handler.unfinishStep()"
            >
              Onvoltooien
            </baseButton>
          </div>
        </div>
      </div>
    </baseCard>
  </div>
</template>

<script>
import themeHandler from '@/use/themeHandler'

export default {
  props: {
    handler: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    const useTheme = themeHandler()
    return {
      backGroundColor: useTheme.getBackgroundColor('primary'),
      textColor: useTheme.getTextColor('primary'),
      selectedStep: 0,
    }
  },

  methods: {
    finishStep() {
      this.handler.finishStep()
      if (this.selectedStep < this.stappen.length - 1) {
        this.selectedStep = this.selectedStep + 1
      }
    },
  },

  computed: {
    stappen() {
      return this.handler.address.value.projects[0]?.config.stappen
    },
    currentStep() {
      return this.handler.currentStep.value
    },
    finishedSteps() {
      return this.handler.finishedSteps.value
    },
  },
}
</script>
