<template>
  <span class="my-auto w-full">
    <!-- value list -->
    <ul class="w-full">
      <li
        @click="clicker(index, value)"
        :class="`${disabledStyle} ${clickStyle}`"
        class="w-full border border-gray-200 rounded py-1 px-4 my-1 text-sm text-gray-500"
        v-for="(value, index) in values"
        :key="index"
      >
        <div class="flex flex-col md:flex-row justify-between">
          <!-- content -->
          <span class="my-auto">
            <component :is="{ ...displayTemplate }" :data="value" />
          </span>

          <!-- actions -->
          <span class="flex flex-row-reverse flex-nowrap" v-if="!disabled">
            <span>
              <baseButton size="small" @action="duplicate(index)" class="my-0">
                <DuplicateIcon class="h-4 w-4" />
              </baseButton>
            </span>
            <span>
              <baseButton size="small" @click="onEdit(value, index)" class="my-0 mx-2">
                <PencilIcon class="h-4 w-4" />
              </baseButton>
            </span>
            <span>
              <baseButton size="small" @click.stop="remove(index)" class="my-0" variant="danger">
                <TrashIcon class="h-4 w-4" />
              </baseButton>
            </span>
          </span>
        </div>
      </li>
    </ul>

    <!-- add button -->
    <baseButton :disabled="disabled" @action="handler.openAdd(fieldConfig)" size="small">+</baseButton>

    <!-- add modal -->
    <baseModal
      v-if="handler.showAddModal.value"
      @close="handler.closeAdd()"
      :title="`${$translate('Add')}`"
      :size="modalSize"
    >
      <baseForm :handler="handler.addForm" />
      <template v-slot:buttons>
        <baseButton :disabled="handler.addForm.errors.value.length > 0" @action="add()" class="mr-2">{{
          $translate('Add')
        }}</baseButton>
      </template>
    </baseModal>

    <!-- edit modal -->
    <baseModal
      v-if="handler.showEditModal.value"
      @close="handler.closeEdit()"
      :title="`${$translate('Edit')}`"
      :size="modalSize"
    >
      <baseForm :handler="handler.editForm" />
      <template v-slot:buttons>
        <baseButton
          :disabled="handler.editForm.errors.value.length > 0"
          @action="edit(handler.editForm.data.value)"
          class="mr-2"
          >{{ $translate('Edit') }}</baseButton
        >
      </template>
    </baseModal>
  </span>
</template>

<script>
import { computed } from 'vue'
import useBaseListInput from '@/use/baseListInputHandler'
import { XIcon, TrashIcon, PencilIcon, DuplicateIcon } from '@heroicons/vue/outline'

export default {
  props: {
    keyField: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    fieldConfig: {
      type: Object,
      default: () => {},
    },
    values: {
      type: Array,
      default: () => [],
    },
    displayTemplate: {
      type: Object,
      default: () => {},
    },
    modalSize: {
      type: String,
      default: 'm',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clickToEdit: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['add', 'remove', 'itemClick', 'edit', 'duplicate'],
  setup(props, { emit }) {
    // handler
    const handler = useBaseListInput()

    function add() {
      const payload = JSON.parse(JSON.stringify(handler.addForm.data.value))
      emit('add', payload)
      handler.closeAdd()
    }

    function remove(index) {
      emit('remove', index)
    }

    function duplicate(index) {
      emit('duplicate', index)
    }

    function edit(payload) {
      const emit_payload = JSON.parse(JSON.stringify(payload))
      emit('edit', handler.editIndex.value, emit_payload)
      handler.closeEdit()
    }

    function clicker(index, value) {
      if (props.clickToEdit) {
        onEdit(value, index)
      }

      emit('itemClick', index, value)
    }

    const disabledStyle = computed(() => {
      if (props.disabled) {
        return 'bg-gray-100'
      }
      return ''
    })

    const clickStyle = computed(() => {
      if (props.clickToEdit) {
        return 'cursor-pointer'
      }
      return ''
    })

    function onEdit(value, index) {
      handler.openEdit(value, index, props.fieldConfig)
    }

    return {
      open,
      close,
      add,
      handler,
      remove,
      clicker,
      disabledStyle,
      edit,
      duplicate,
      onEdit,
      clickStyle,
    }
  },
  components: {
    XIcon,
    TrashIcon,
    PencilIcon,
    DuplicateIcon,
  },
}
</script>
