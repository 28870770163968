import { ref } from 'vue'

let tooltipVisible = ref(false)
let tooltipKey = ref('jaar_verbouwing')

export default () => {
  const tooltipContent = {
    jaar_verbouwing: {
      title: 'Jaar verbouwing',
      content:
        '"Jaar verbouwing" verwijst naar een periode van een jaar waarin er significante renovatie- of verbouwingswerkzaamheden plaatsvinden aan een gebouw, huis of eigendom. Tijdens deze periode worden doorgaans diverse bouwkundige, esthetische of functionele verbeteringen aangebracht aan de bestaande structuur. De term impliceert dat gedurende het hele jaar verschillende aspecten van de constructie worden aangepakt, zoals het vernieuwen van de keuken, badkamer, het dak, vloeren, elektrische bedrading of het uitvoeren van andere renovatieprojecten. Een "jaar verbouwing" geeft aan dat de werkzaamheden uitgebreid zijn en mogelijk veel tijd in beslag nemen. De exacte aard van de verbouwing kan variëren afhankelijk van de behoeften en doelstellingen van de eigenaar. Het kan bijvoorbeeld gaan om het moderniseren van verouderde voorzieningen, het vergroten van de leefruimte, het verbeteren van energie-efficiëntie of simpelweg het upgraden van de algehele uitstraling van het pand. Het doel is over het algemeen om de levenskwaliteit te verbeteren en de waarde van het onroerend goed te verhogen.',
    },
    verbruik_warmte: {
      title: 'Verbruik warmte',
      content:
        '"Verbruik warmte (GJ)" verwijst naar de hoeveelheid warmte-energie die gedurende een bepaalde periode wordt verbruikt, gemeten in gigajoules (GJ). Deze term wordt vaak gebruikt bij het evalueren en rapporteren van het energieverbruik, met name in relatie tot verwarmingsprocessen. Het verbruik van warmte wordt gemeten in gigajoules omdat het een eenheid is die de hoeveelheid energie aangeeft die vrijkomt bij het verbranden van een gigajoule aan brandstof. Dit kan relevant zijn voor zowel huishoudelijk als industrieel gebruik. Bijvoorbeeld, in huishoudens wordt het verbruik van warmte (GJ) gebruikt om de hoeveelheid energie te meten die wordt gebruikt voor verwarmingssystemen, zoals boilers, radiatoren of vloerverwarming. In industriële contexten kan het verbruik van warmte (GJ) gerelateerd zijn aan productieprocessen waar warmte een cruciale rol speelt. Het monitoren van het verbruik is belangrijk voor efficiëntieverbeteringen en het beheersen van energiekosten. Het kan ook deel uitmaken van inspanningen om duurzamer te opereren door het optimaliseren van energiebronnen en het verminderen van verspilling.',
    },
  }

  function getTitle() {
    return tooltipContent[tooltipKey.value].title
  }

  function getContent() {
    return tooltipContent[tooltipKey.value].content
  }

  function showTooltip(key) {
    tooltipKey.value = key
    tooltipVisible.value = true
  }

  function hideTooltip() {
    tooltipVisible.value = false
  }

  return {
    showTooltip,
    hideTooltip,
    tooltipVisible,
    tooltipContent,
    tooltipKey,
    getTitle,
    getContent,
  }
}
