<template>
  <base-button class="my-4 mr-2" size="small" @action="handler.syncMaatregelen()">Overnemen</base-button>
  <base-button class="my-4" size="small" @action="handler.addMaatregel()">+</base-button>
  <!-- tabel header -->

  <div class="flex flex-row gap-2">
    <div class="w-5"></div>
    <div class="m-px flex flex-1 cursor-pointer flex-row items-start justify-between px-3" @click="toggle()">
      <!-- Header Content -->
      <div class="flex flex-auto items-center justify-between">
        <div class="flex w-full gap-2 text-sm text-slate-500">
          <div class="flex-1 basis-5/12 font-bold"></div>
          <div v-if="useContentGuard.canSee('organisation:proces')" class="flex-1 basis-3/12 font-bold">Categorie</div>
          <div v-if="!useContentGuard.canSee('organisation:proces')" class="flex-1 basis-3/12 font-bold"></div>
          <div class="flex-1 basis-2/12 font-bold">Kosten</div>
          <div class="flex-1 basis-2/12 font-bold">Subsidie</div>
        </div>
      </div>
      <span class="mr-2 w-4"></span>
    </div>
    <p class="w-8"></p>
  </div>

  <!-- maatregels -->
  <div
    :class="`${useDrag.dropIndex.value === index ? 'bg-gray-50' : ''} ${dragHand}`"
    class="mb-2 flex flex-row gap-2 text-sm text-slate-500"
    v-for="(maatregel, index) in handler.data.value"
    :key="index"
    draggable="true"
    @dragstart="useDrag.dragStart(index)"
    @dragenter="useDrag.dragEnter(index)"
    @dragover.prevent
    @drop="useDrag.dragEnd(index)"
  >
    <div class=""><featherIcon class="m-auto h-11 w-5" icon="MoreVerticalIcon" /></div>
    <base-collapse class="mb-2 w-full">
      <template v-slot:header>
        <div class="flex w-full flex-row gap-2">
          <div class="w-full basis-5/12 font-bold">{{ handler.data.value[index]['omschrijving'] }}</div>
          <div v-if="useContentGuard.canSee('organisation:proces')" class="w-full basis-3/12">
            {{ handler.data.value[index]['categorie'] }}
          </div>
          <div v-if="!useContentGuard.canSee('organisation:proces')" class="w-full basis-3/12"></div>
          <div class="w-full basis-2/12">€ {{ handler.data.value[index]['kosten'] }}</div>
          <div class="w-full basis-2/12">€ {{ handler.data.value[index]['subsidie'] }}</div>
        </div>
      </template>
      <template v-slot:body>
        <div class="flex w-full flex-row flex-wrap">
          <div class="flex w-full basis-full flex-row gap-2">
            <div class="w-full basis-7/12">
              <div class="text-l text-slate-500">Omschrijving</div>
              <numberField class="w-full" :handler="handler" :index="index" dataKey="omschrijving" type="mixed" />
            </div>
            <div class="w-full basis-5/12">
              <div v-if="useContentGuard.canSee('organisation:proces')">
                <div class="text-l text-slate-500">Categorie</div>
                <selectField
                  class="w-full"
                  :handler="handler"
                  :index="index"
                  dataKey="categorie"
                  :options="lookupMaatregelCategorie"
                  :value="maatregel.categorie"
                />
              </div>
            </div>
          </div>
          <div class="flex w-full basis-full flex-row gap-2">
            <div class="flex w-full basis-7/12 flex-row gap-2">
              <div class="w-full basis-1/5 pt-1">
                <div class="text-l text-slate-500">Prijs</div>
                <numberField class="w-full" :handler="handler" :index="index" dataKey="prijs" type="number" />
              </div>
              <div class="w-full basis-1/5 pt-1">
                <div class="text-l text-slate-500">Hoeveelheid</div>
                <numberField class="w-full" :handler="handler" :index="index" dataKey="hoeveelheid" type="number" />
              </div>
              <div class="mt-auto w-full basis-1/5 pt-1">
                <selectField
                  class=""
                  :handler="handler"
                  :index="index"
                  dataKey="eenheid"
                  :options="selectValues.eenheid_maatregelen"
                  :value="maatregel.eenheid"
                />
              </div>
              <div class="w-full basis-2/5 pt-1">
                <div class="text-l text-slate-500">Kosten</div>
                <numberField class="w-full" :handler="handler" :index="index" dataKey="kosten" type="number" />
              </div>
            </div>
            <div class="flex w-full basis-5/12 flex-row gap-2">
              <div class="w-full pt-1">
                <div class="text-l text-slate-500">Subsidie</div>
                <numberField :handler="handler" :index="index" dataKey="subsidie" type="number" />
              </div>
              <div class="mt-auto w-full pt-1">
                <p class="w-5"></p>
                <numberField :handler="handler" :index="index" dataKey="subsidie_soort" type="mixed" />
              </div>
            </div>
          </div>
        </div>
      </template>
    </base-collapse>
    <p class="mt-2 h-11">
      <baseButton size="small" @click="handler.removeMaatregel(index)" class="my-0" variant="danger">
        <TrashIcon class="h-4 w-4" />
      </baseButton>
    </p>
  </div>

  <div class="flex flex-row gap-2">
    <div class="w-5"></div>
    <div class="m-px flex flex-auto cursor-pointer flex-row items-start justify-between px-3" @click="toggle()">
      <!-- Header Content -->
      <div class="flex flex-auto items-center justify-between">
        <div class="flex w-full gap-2 text-sm text-slate-500">
          <div class="basis-5/12 font-bold">Totaal</div>
          <div class="basis-3/12"></div>
          <div class="basis-2/12 font-bold">€ {{ handler.totaalMaatregelen.value.totaal_kosten }}</div>
          <div class="basis-2/12 font-bold">€ {{ handler.totaalMaatregelen.value.totaal_subsidie }}</div>
        </div>
      </div>
      <span class="mr-2 w-4"></span>
    </div>
    <p class="w-8"></p>
  </div>
</template>

<script>
import { TrashIcon } from '@heroicons/vue/outline'
import numberField from './numberField.vue'
import selectField from './selectField.vue'
import dragHandler from '@/use/dragHandler'
import { onMounted, onUnmounted, computed } from 'vue'
import selectValues from '@/use/selects/values'
import contentGuardHandler from '@/use/contentGuardHandler'

export default {
  components: {
    TrashIcon,
    numberField,
    selectField,
  },
  props: {
    handler: {
      type: Object,
      default: () => {},
    },
    categorieen: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const useDrag = dragHandler(props.handler.data.value)
    const useContentGuard = contentGuardHandler()

    const dragHand = computed(() => {
      if (useDrag.dragging.value) {
        return 'cursor-grabbing'
      }
      return ''
    })

    const lookupMaatregelCategorie = computed(() => {
      if (props.categorieen) {
        const parsedArray = props.categorieen.map((item) => ({
          label: item.omschrijving,
          value: item.omschrijving,
        }))

        return parsedArray
      }
      return []
    })

    onMounted(() => {
      useDrag.load(props.handler.data.value)
      window.addEventListener('dragend', useDrag.dragEnd)
    })

    onUnmounted(() => {
      window.removeEventListener('dragend', useDrag.dragEnd)
    })

    return {
      useDrag,
      dragHand,
      selectValues,
      lookupMaatregelCategorie,
      useContentGuard,
    }
  },
}
</script>
