/* eslint-disable no-unused-vars */
import { ref, computed } from 'vue'
import formHandler from '@/use/formHandler'
import { evaluate } from 'mathjs'

let show = ref(false)
let storeHandler = ref(null)
let targetKey = ref('')
let result = ref('')
const inputForm = formHandler()

export default () => {
  // handlers
  const calculatorFields = [
    {
      label: 'Input',
      key: 'input',
      type: 'calculation',
    },
  ]

  function close() {
    inputForm.close()
    show.value = false
    storeHandler.value = null
    targetKey.value = ''
    result.value = ''
  }

  function open(handler, key) {
    show.value = true

    // for using calculator in form
    if (handler) {
      storeHandler.value = handler
    }

    if (key) {
      targetKey.value = key
    }

    inputForm.create({
      type: 'edit',
      fields: calculatorFields.value,
      data: {
        input: '',
      },
    })
    show.value = true
  }

  function input(payload) {
    let currentInput = ''
    if ('input' in inputForm.data.value) {
      currentInput = inputForm.data.value.input
    }

    const targetPayload = currentInput.concat(payload)
    const event = {
      target: {
        value: targetPayload,
      },
    }
    inputForm.validate(event, 'calculation', [], 'input')
  }

  function reset() {
    if ('input' in inputForm.data.value) {
      inputForm.data.value.input = ''
    }
  }

  function calculate() {
    const result = evaluate(inputForm.data.value.input)
    if (result) {
      inputForm.data.value.input = String(result)
    }
  }

  function use() {
    // storeHandler.value.data.value[targetKey.value] = result.value
    storeHandler.value.data[targetKey.value] = inputForm.data.value.input
    storeHandler.value.update_errors()
    close()
  }

  const useable = computed(() => {
    if (inputForm.data.value.input) {
      const input = inputForm.data.value.input

      if (input.match(/[/*\-+]/)) {
        return false
      } else {
        return true
      }
    }
    return false
  })

  return {
    show,
    open,
    close,
    inputForm,
    calculatorFields,
    input,
    reset,
    calculate,
    use,
    storeHandler,
    useable,
  }
}
