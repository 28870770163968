/* eslint-disable no-unused-vars */
import { ref, computed } from 'vue'
import useValidate from '@/use/validationHandler'
import filterHandler from '@/use/filterHandler'

let show = ref(false)
let data = ref({
  lengte: null,
  breedte: null,
})
let storeHandler = null
let targetKey = ref('oppervlakte')

export default () => {
  // handler
  const validator = useValidate()
  const useFilter = filterHandler()

  function validate(event, type, rules, key) {
    const input = event.target.value

    // validatorguard
    if (validator.validate(input, type)) {
      data.value[key] = input
    } else {
      if (data.value[key] != undefined) {
        event.target.value = data.value[key]
      } else {
        event.target.value = null
      }
    }
  }

  let oppervlakte = computed(() => {
    if (data.value.lengte && data.value.breedte) {
      const sum = data.value.lengte * data.value.breedte

      return useFilter.filter(sum, 'round2en')
    } else {
      return null
    }
  })

  function open(handler, key) {
    storeHandler = handler
    show.value = true

    if (key) {
      targetKey.value = key
    }
  }

  function close() {
    show.value = false
    data.value.lengte = null
    data.value.breedte = null
    storeHandler = null
  }

  function use() {
    storeHandler.data.value[targetKey.value] = oppervlakte.value
    storeHandler.update_errors()
    close()
  }

  return {
    oppervlakte,
    show,
    open,
    close,
    use,
    validate,
    data,
  }
}
