<template>
  <span>{{ `${data.afgifte} (${vermogenString})` }}</span>
</template>

<script>
// import helpers from '@/use/utils/helpers'
import lookupHandler from '@/use/lookupHandler'

import { computed } from 'vue'
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const useLookup = lookupHandler()

    const vermogenString = computed(() => {
      const info = useLookup.afgifteUnit[props.data.afgifte]
      // const info = helpers.afgifteSelector(props.data.afgifte)
      const field = info.field
      const unit = info.unit
      return `${props.data[field]} ${unit}`
    })

    return {
      // helpers,
      vermogenString,
    }
  },
}
</script>
