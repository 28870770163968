<template>
  <div>
    <baseTreeNode
      v-for="node in handler.tree"
      :key="node.id"
      :node="node"
      @updateNode="handleUpdateNode"
      @toggleVisibility="handleToggleVisibility"
    />
  </div>
</template>

<script>
export default {
  props: {
    handler: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    // Methods within setup...
    const handleUpdateNode = (node) => {
      // Assuming node has a path and the updated value for checked
      const payload = {
        checked: !node.checked,
      }

      props.handler.updateNodeByPath(node.path, payload)
    }

    function handleToggleVisibility(node) {
      // Directly modifying the property here for simplicity
      const payload = {
        collapsed: !node.collapsed,
      }

      props.handler.updateNodeByPath(node.path, payload)
    }

    return {
      handleUpdateNode,
      handleToggleVisibility,
    }
  },
}
</script>
