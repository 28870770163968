import { ref } from 'vue'

export default () => {
  let show = ref(false)
  let message = ref('')
  let title = ref('')
  let resolvePromise = ref(undefined)

  function open(options) {
    // set data
    message.value = options.message
    title.value = options.title

    // open modal
    show.value = true

    // set promise
    return new Promise((resolve) => {
      resolvePromise = resolve
    })
  }

  // confirm
  function confirm() {
    show.value = false
    resolvePromise(true)
    reset()
  }

  // cancel
  function cancel() {
    show.value = false
    resolvePromise(false)
    reset()
  }

  // reset
  function reset() {
    message.value = ''
  }

  return {
    show,
    open,
    message,
    confirm,
    cancel,
    title,
  }
}
