<template>
  <form class="w-full">
    <div v-for="(field, index) in handler.filteredFields.value" :key="index" class="flex flex-row my-1">
      <div class="basis-2/5 flex flex-row items-center place-content-end">
        <label
          v-show="dependentCheck(field)"
          class="block text-gray-500 md:text-right mb-1 md:mb-0 pr-2 py-2 text-sm"
          :for="field.key"
          v-if="field.type !== 'subtitle'"
        >
          {{ $translate(field.label) }}
        </label>
        <!-- tooltip  field.tooltip === true -->
        <span class="pr-2 -ml-1 cursor-pointer" v-if="false" @click="tooltipHandler.showTooltip(field.key)">
          <featherIcon class="w-4 h-4 text-gray-400 hover:text-gray-500" icon="InfoIcon" />
        </span>
      </div>
      <div
        class="basis-3/5 flex items-center relative max-w-xl"
        :style="'z-index: ' + (handler.filteredFields.value.length - index)"
      >
        <baseField :disabled="disabled" :handler="handler" :field="field" />
        <div
          class="units absolute right-3"
          :class="{ '!right-12': field.type === 'select' || field.type === 'area' || field.type === 'insulation' }"
          v-show="dependentCheck(field)"
        >
          <span class="units-value text-gray-400 text-sm rounded-xl p-1" id="unitsValue">{{ field.unit }}</span>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import contentGuardHandler from '@/use/contentGuardHandler'
import tooltipHandler from '@/use/tooltipHandler'

export default {
  props: {
    handler: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errorLevel: {
      type: String,
      default: 'info',
    },
  },
  setup(props) {
    const useContentGuard = contentGuardHandler()
    function dependentCheck(field) {
      // Check if user has necessary permissions based on canSeeKey
      if (field.can_see && !useContentGuard.canSee(field.can_see)) {
        return false // Hide the field if user doesn't have the necessary permissions
      }

      const checkValues = field.dependent_show_values
      if (checkValues) {
        const dependentShowFieldKey = field.dependent_show_field_key
        const dependentShowFieldType = field.dependent_show_field_type
        const dependentValue = props.handler.data.value[dependentShowFieldKey]

        // Ensure dependentValue is not undefined or null
        if (dependentValue !== undefined && dependentValue !== null) {
          // Handle 'select' type differently
          if (dependentShowFieldType === 'select' && checkValues.includes(dependentValue.value)) {
            return true
          } else if (checkValues.includes(dependentValue)) {
            return true
          }
        }
        return false
      }
      return true
    }

    return {
      dependentCheck,
      tooltipHandler: tooltipHandler(),
    }
  },
}
</script>
