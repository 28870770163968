import { createRouter, createWebHistory } from 'vue-router'
// import viewSurvey from '@/views/viewSurvey.vue'
import viewAddress from '@/views/viewAddress.vue'
import viewSurveyAdmin from '@/views/viewSurveyAdmin.vue'
import viewDetailSurvey from '@/views/viewDetailSurvey.vue'
import viewDetailAddress from '@/views/viewDetailAddress.vue'
import viewDetailProjects from '@/views/viewDetailProjects.vue'
import viewNotFound from '@/views/viewNotFound.vue'
import viewDetailOrganisation from '@/views/viewDetailOrganisation.vue'
import { authGuard } from '@auth0/auth0-vue'

const routes = [
  {
    path: '/',
    name: 'addresses',
    component: viewAddress,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal',
    },
  },
  {
    path: '/addresses/:objectId',
    name: 'address',
    component: viewDetailAddress,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal',
    },
  },
  {
    path: '/projects/:objectId',
    name: 'project',
    component: viewDetailProjects,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal',
    },
  },
  {
    path: '/surveys',
    name: 'surveys_admin',
    component: viewSurveyAdmin,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal',
    },
  },
  {
    path: '/surveys/:objectId',
    name: 'survey',
    component: viewDetailSurvey,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal',
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: viewNotFound,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal',
    },
  },
  {
    path: '/organisations/:objectId',
    name: 'organisation',
    component: viewDetailOrganisation,
    beforeEnter: authGuard,
    meta: {
      layout: 'horizontal',
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
