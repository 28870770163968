<template>
  <span>{{ `${data.soort} (${data.oppervlakte} m2 / ${data.belemmering}%)` }}</span>
  <span>{{ ` ZTA ${useLookup.glas.value[data.soort].zta_waarde}` }}</span>
</template>

<script>
import lookupHandler from '@/use/lookupHandler'

export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const useLookup = lookupHandler()
    return {
      useLookup,
    }
  },
}
</script>
