/* eslint-disable no-unused-vars */
import { ref, computed } from 'vue'
import useValidate from '@/use/validationHandler'
import filterHandler from '@/use/filterHandler'
import lookupHandler from '@/use/lookupHandler'
import formHandler from '@/use/formHandler'
import selectValues from '@/use/selects/values'

let show = ref(false)
let data = ref({})
let formData = ref({})
let storeHandler = null
let targetKey = ref('isolatieniveau')

export default () => {
  // handler
  const validator = useValidate()
  const useFilter = filterHandler()
  const useLookup = lookupHandler()

  function validate(event, type, rules, key) {
    const input = event.target.value

    // validatorguard
    if (validator.validate(input, type)) {
      data.value[key] = input
    } else {
      if (data.value[key] != undefined) {
        event.target.value = data.value[key]
      } else {
        event.target.value = null
      }
    }
  }

  let rcWaarde = computed(() => {
    if (data.value.bouwjaar && data.value.bouwdeel) {
      const waarde = useLookup.standaardIsolatie[data.value.bouwdeel][data.value.bouwjaar]
      return String(waarde)
    } else {
      return null
    }
  })

  function open(handler, key) {
    storeHandler = handler
    show.value = true

    if (key) {
      targetKey.value = key
    }
  }

  function close() {
    show.value = false
    data.value.lengte = null
    data.value.breedte = null
    storeHandler = null
  }

  function use() {
    storeHandler.data.value[targetKey.value] = rcWaarde.value
    storeHandler.update_errors()
    close()
  }

  return {
    rcWaarde,
    show,
    open,
    close,
    use,
    validate,
    data,
  }
}
