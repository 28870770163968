export const config = {
  soort_woning: {
    ambitie: 'De klant wil een duurzamere woning',
    bouwjaar_woning: '',
    bouwlagen: 2,
    gezinssamenstelling: '',
    jaar_verbouwing: 2024,
    kruipruimte: 'niet aanwezig',
    omgeving: '',
    prijs_elektra: '0.40',
    prijs_gas: '1.45',
    prijs_warmte: '6',
    prijsstijging_elektra: '2',
    prijsstijging_gas: '5',
    prijsstijging_warmte: '6',
    soort_dak: 'Schuin dak',
    soort_dakbedekking: 'pannen',
    soort_muren: 'spouw',
    soort_vloer_begane_grond: 'hout',
    soort_woning: '',
    teruglevertarief_elektra: '0.07',
    uitstoot_warmtenet: '',
    verbruik_elektra: '',
    verbruik_gas: '',
    verbruik_warmte: '0',
  },
  installaties: {
    Zonnepanelen: [],
    aandeel_direct_verbruik: '30',
    aansluitwaarde_elektra: '3x25A',
    accu: '0',
    koken: 'Gas',
    leidingen_buiten_schil: 'Geen leidingen buiten de thermische schil',
    luchtdichtheid: '',
    nachtverlaging: 'Ja',
    opwekking_binnen_thermische_schil: 'Ja',
    verbruiksprofiel_tapwater: 'Gemiddeld',
    zonneboiler_voor_bron_warm_tapwater: 'Geen',
    warmteopwekker: 'HR 107 gasketel HTV (recent)',
    bron_warm_tapwater: 'HR 107 gasketel (>15j)',
    ventilatie_mechanisch: '0',
    ventilatie_wtw: '0',
  },
  installaties_nieuw: {
    Zonnepanelen: [],
    aandeel_direct_verbruik: '30',
    aansluitwaarde_elektra: '3x25A',
    accu: '0',
    koken: 'Gas',
    leidingen_buiten_schil: 'Geen leidingen buiten de thermische schil',
    luchtdichtheid: '',
    nachtverlaging: 'Ja',
    opwekking_binnen_thermische_schil: 'Ja',
    verbruiksprofiel_tapwater: 'Gemiddeld',
    zonneboiler_voor_bron_warm_tapwater: 'Geen',
    warmteopwekker: 'HR 107 gasketel HTV (recent)',
    bron_warm_tapwater: 'HR 107 gasketel (>15j)',
    ventilatie_mechanisch: '0',
    ventilatie_wtw: '0',
  },
  klimaatzones_huidig: [
    {
      afwijkende_opwekker: '',
      afwijkende_ventilatie: '',
      luchtdichtheid: '',
      naam: 'Huis',
      setpoint_vermogensberekening: 20,
      stookgedrag_setpoint: 20,
      undefined: '',
      ventilatie_mechanisch: '',
      ventilatie_natuurlijk: '',
      ventilatie_wtw: '',
      verbruik_vermindering: '0',
      verbruik_vermindering_reden: '',
      verbruiksoppervlak: '50',
      warmteafgifte: [],
      warmteopwekker: '',
    },
  ],
  klimaatzones_nieuw: [
    {
      afwijkende_opwekker: '',
      afwijkende_ventilatie: '',
      luchtdichtheid: '',
      naam: 'Huis',
      setpoint_vermogensberekening: 20,
      stookgedrag_setpoint: 20,
      undefined: '',
      ventilatie_mechanisch: '',
      ventilatie_natuurlijk: '',
      ventilatie_wtw: '',
      verbruik_vermindering: '0',
      verbruik_vermindering_reden: '',
      verbruiksoppervlak: '50',
      warmteafgifte: [],
      warmteopwekker: '',
    },
  ],
  maatregelen: [],
  hypotheek: {},
}
