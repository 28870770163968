<template>
  <table class="w-full h-full text-sm text-left text-gray-500">
    <thead>
      <tr>
        <!-- normal header cells -->
        <th>{{ labelColumn }}</th>
        <th>{{ valueColumn }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(field, index) in filteredFieldsDependent" :key="index" :class="`${rowStyle(index)}`">
        <!-- label -->
        <td class="p-2">{{ $translate(field.label) }}</td>

        <!-- value with filter -->
        <td v-if="field.filter" class="p-2">
          {{ useFilter.filter(data[field.key], field.filter) }}
        </td>

        <td v-else-if="field.component" class="p-2">
          <!-- Dynamic component rendering -->
          <component :is="field.component" v-bind="field.componentProps"></component>
        </td>

        <!-- raw value -->
        <td v-else class="p-2">{{ data[field.key] }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { computed } from 'vue'
import filterHandler from '@/use/filterHandler'
import listLink from '@/components/extended/listLink.vue'

export default {
  props: {
    labelColumn: {
      type: String,
      default: '',
    },
    valueColumn: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      default: () => {},
    },
    fields: {
      type: Object,
      default: () => {},
    },
    demo: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const useFilter = filterHandler()

    function isEven(number) {
      return number % 2 == 0 ? true : false
    }

    function rowStyle(index) {
      if (isEven(index)) {
        return 'bg-gray-100'
      }
      return ''
    }

    const filteredFields = computed(() => {
      return props.fields.filter((field) => {
        if ('details_list' in field) {
          if (!field.details_list) {
            return false
          }
        }
        return true
      })
    })

    const filteredFieldsDependent = computed(() => {
      return filteredFields.value.filter((field) => {
        if (dependentCheck(field)) {
          return true
        }
        return false
      })
    })

    function dependentSearcher(field) {
      if (field.dependent_show_values) {
        const dependentShowFieldKey = field.dependent_show_field_key
        const dependentShowFieldValue = props.data[dependentShowFieldKey]
        return dependentShowFieldValue
      }
    }

    function dependentCheck(field) {
      const checkValues = field.dependent_show_values
      const dependentShowFieldKey = field.dependent_show_field_key
      const dependentShowFieldType = field.dependent_show_field_type

      if (checkValues) {
        if (dependentShowFieldType === 'select') {
          if (props.data[dependentShowFieldKey] && checkValues.includes(props.data[dependentShowFieldKey])) {
            return true
          }
        }
        if (props.data[dependentShowFieldKey] && checkValues.includes(props.data[dependentShowFieldKey])) {
          return true
        }
        return false
      }

      if (dependentShowFieldKey) {
        if (dependentShowFieldType === 'labels') {
          const dependentData = props.data[dependentShowFieldKey]
          if (dependentData.length > 0) {
            return true
          }
        }
        return false
      }
      return true
    }

    return {
      rowStyle,
      filteredFields,
      filteredFieldsDependent,
      useFilter,
      dependentCheck,
      dependentSearcher,
    }
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    listLink,
  },
}
</script>
