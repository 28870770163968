import { reactive, computed } from 'vue'

// Defined outside to make it reusable and independent
const attachPaths = (nodes, path = []) => {
  nodes.forEach((node, index) => {
    const currentPath = [...path, index] // The path for this node
    node.path = currentPath

    if (node.children && node.children.length > 0) {
      attachPaths(node.children, currentPath)
    }
  })
}

export default (initialTreeData) => {
  const tree = reactive(initialTreeData)

  // Initially attach paths to each node in the tree
  attachPaths(tree)

  // Updates a node's properties by its path
  const updateNodeByPath = (path, updates) => {
    let currentNode = tree
    for (let i = 0; i < path.length; i++) {
      const index = path[i]
      if (i === path.length - 1) {
        // Apply the updates using Vue's reactivity system
        const updatedNode = { ...currentNode[index], ...updates }
        currentNode[index] = updatedNode
      } else {
        currentNode = currentNode[index].children
      }
    }
  }

  // Method to find and return a node by its path
  const findNodeByPath = (path) => {
    let currentNode = tree
    for (let i = 0; i < path.length; i++) {
      const index = path[i]
      if (i < path.length - 1) {
        currentNode = currentNode[index].children
      } else {
        return currentNode[index]
      }
    }
    return null
  }

  const checkedPaths = computed(() => {
    const paths = {}
    const labels = {}

    const collectCheckedPaths = (nodes) => {
      nodes.forEach((node) => {
        if (node.checked && node.path) {
          paths[node.id] = node.dataPath
          labels[node.id] = node.label
        }
        if (node.children) {
          collectCheckedPaths(node.children)
        }
      })
    }

    collectCheckedPaths(tree)
    return { paths, labels }
  })

  return {
    tree,
    updateNodeByPath,
    findNodeByPath,
    checkedPaths,
    attachPaths,
  }
}
