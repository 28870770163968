const state = {
  settings: {
    colourMode: 'light',
    colours: {
      light: {
        background: 'bg-slate-50',
        surface: 'text-black bg-white drop-shadow-md',
        onsurface: 'text-black',
        primary: 'bg-sky-700',
        loadingprimary: 'bg-gray-300',
        onprimary: 'text-white',
        danger: 'bg-red-600',
        ondanger: 'text-white',
        secondary: '',
        pageTitle: 'text-xl my-8 text-slate-500',
        transparent: 'bg-transparent',
      },
      dark: {
        background: '',
        surface: 'text-white k10default',
        onsurface: 'text-white',
        primary: 'bg-sky-700',
        loadingprimary: 'bg-gray-300',
        onprimary: 'text-white',
        danger: 'bg-red-600',
        ondanger: 'text-white',
        secondary: '',
        pageTitle: 'text-xl my-8 text-slate-500',
        transparent: 'bg-transparent',
      },
    },
  },
  navBar: {
    title: 'Woningtool',
    items: [
      // {
      //   title: '',
      //   action: {
      //     type: 'storeMutation',
      //     target: 'panoramaMap/toggleModal',
      //   },
      // },
      {
        title: 'Uitloggen',
        action: {
          type: 'logOut',
        },
      },
    ],
  },
}

const getters = {
  getStyle: (state) => (name) => {
    return state.settings.colours[state.settings.colourMode][name]
  },
}

export default {
  namespaced: true,
  state,
  getters,
}
