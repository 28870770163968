<template>
  <div
    class="z-[60] select-none"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
    v-if="usecalculator.show.value"
  >
    <!-- grey background -->
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-[60]"></div>

    <!-- card -->
    <div
      class="fixed z-[70] inset-0 overflow-y-auto"
      @keydown.esc="close()"
      @keydown="onKeyPress"
      tabindex="0"
      ref="focusRef"
    >
      <!-- modal -->
      <div class="flex items-end items-center justify-center min-h-full p-4 text-center sm:p-0">
        <div
          :class="`relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all xl:w-1/5`"
          v-draggable
        >
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <span class="flex justify-between">
              <div class="text-lg text-gray-500 mb-4">Rekenmachine</div>
              <div class="text-gray-400 cursor-pointer" @click="close()">
                <!-- closer -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </div>
            </span>

            <!-- calculator -->
            <div class="grid grid-cols-4 gap-1 font-montserrat">
              <!-- inputbox -->
              <span class="col-span-4 my-2">
                <baseField :handler="usecalculator.inputForm" :field="usecalculator.calculatorFields[0]" />
              </span>

              <!-- row -->
              <base-calculator-button text="C" @pressed="buttonClick" variant="danger_pastel" />
              <base-calculator-button text="(" @pressed="buttonClick" variant="primary_pastel" />
              <base-calculator-button text=")" @pressed="buttonClick" variant="primary_pastel" />
              <base-calculator-button text="/" @pressed="buttonClick" variant="primary_pastel" />

              <!-- row -->
              <base-calculator-button text="7" @pressed="buttonClick" variant="neutral" class="rounded-none" />
              <base-calculator-button text="8" @pressed="buttonClick" variant="neutral" class="rounded-none" />
              <base-calculator-button text="9" @pressed="buttonClick" variant="neutral" class="rounded-none" />
              <base-calculator-button text="*" @pressed="buttonClick" variant="primary_pastel" class="rounded-none" />

              <!-- row -->
              <base-calculator-button text="4" @pressed="buttonClick" variant="neutral" />
              <base-calculator-button text="5" @pressed="buttonClick" variant="neutral" />
              <base-calculator-button text="6" @pressed="buttonClick" variant="neutral" />
              <base-calculator-button text="-" @pressed="buttonClick" variant="primary_pastel" />

              <!-- row -->
              <base-calculator-button text="1" @pressed="buttonClick" variant="neutral" />
              <base-calculator-button text="2" @pressed="buttonClick" variant="neutral" />
              <base-calculator-button text="3" @pressed="buttonClick" variant="neutral" />
              <base-calculator-button text="+" @pressed="buttonClick" variant="primary_pastel" />

              <!-- row -->
              <base-calculator-button text="0" @pressed="buttonClick" variant="neutral" class="col-span-2" />
              <base-calculator-button text="." @pressed="buttonClick" variant="neutral" />
              <base-calculator-button text="=" @pressed="buttonClick" variant="primary_pastel" />

              <!-- row -->
              <base-calculator-button
                :disabled="!usecalculator.useable.value"
                v-if="hasHandler"
                text="Overnemen"
                @pressed="buttonClick"
                class="col-span-4 my-4"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <base-modal v-if="usecalculator.show.value" @close="usecalculator.close()" size="xs"> -->
</template>

<script>
import calcualtorHandler2 from '@/use/calcualtorHandler2'
import baseCalculatorButton from './baseCalculatorbutton.vue'
import { ref, watch, computed } from 'vue'

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['use'],
  setup() {
    // handlers
    const usecalculator = calcualtorHandler2()
    const focusRef = ref(null)

    function buttonClick(value) {
      if (value === 'C') {
        handleInput('Delete')
      } else if (value === 'Overnemen') {
        usecalculator.use()
      } else {
        handleInput(value)
      }
    }

    function onKeyPress(event) {
      const value = event.key
      handleInput(value)
    }

    function handleInput(value) {
      const allowedInput = ['+', '-', '*', '/', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', '0', '(', ')']

      if (allowedInput.includes(value)) {
        usecalculator.input(value)
      } else if (value === 'Delete') {
        usecalculator.reset(value)
      } else if (value === 'Backspace') {
        usecalculator.reset(value)
      } else if (value === '=' || value === 'Enter') {
        usecalculator.calculate()
      }
    }

    function close() {
      usecalculator.close()
    }

    watch(usecalculator.show, (newValue) => {
      if (newValue) {
        setTimeout(() => {
          focusRef.value.focus()
        }, 500)
      }
    })

    const hasHandler = computed(() => {
      if (usecalculator.storeHandler.value !== null) {
        return true
      }
      return false
    })

    return {
      usecalculator,
      buttonClick,
      focusRef,
      onKeyPress,
      close,
      hasHandler,
    }
  },
  components: {
    baseCalculatorButton,
  },
}
</script>
