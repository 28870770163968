import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'

export default () => {
  const { download } = apiHandler()
  const loadHandler = loadingHandler()

  function downloadManual() {
    loadHandler.setLoadingState('download_manual', true)
    download('manual').then((result) => {
      const filename = result.headers['x-filename']

      if (filename) {
        // Create a temporary anchor element
        const tempLink = document.createElement('a')

        // Set the download attribute with the desired filename
        tempLink.download = filename

        // Set the href attribute with the Object URL
        tempLink.href = URL.createObjectURL(result.data)

        // Append the temporary anchor element to the document
        document.body.appendChild(tempLink)

        // Trigger the click event to start the download
        tempLink.click()

        // Remove the temporary anchor element from the document
        document.body.removeChild(tempLink)

        loadHandler.setLoadingState('download_manual', false)
      } else {
        loadHandler.setLoadingState('download_manual', false)
      }
    })
  }

  return {
    downloadManual,
    loadHandler,
  }
}
