/* eslint-disable prettier/prettier */
import { ref } from 'vue'

export default () => {
  const glas = ref({
    'Drievoudige beglazing': { u_waarde: 1.1, zta_waarde: 0.6 },
    'Drievoudige beglazing zonwerend': { u_waarde: 1.1, zta_waarde: 0.4 },
    'HR++': { u_waarde: 1.3, zta_waarde: 0.6 },
    'HR++ zonwerend': { u_waarde: 1.3, zta_waarde: 0.4 },
    'HR+ glas': { u_waarde: 1.8, zta_waarde: 0.6 },
    'HR glas': { u_waarde: 2.1, zta_waarde: 0.6 },
    'Dubbel glas': { u_waarde: 2.9, zta_waarde: 0.8 },
    'Enkel glas': { u_waarde: 5.2, zta_waarde: 0.8 },
    'Ongeisoleerde deur': { u_waarde: 3.4, zta_waarde: 0 },
    'Geisoleerde deur': { u_waarde: 2.0, zta_waarde: 0 },
    'Paneel geen isolatie': { u_waarde: 3.4, zta_waarde: 0 },
    'Paneel isolatie': { u_waarde: 1.0, zta_waarde: 0 },
  })

  const overgangsweerstanden = ref({
    noord: { rsi: 0.13, rse: 0.04 },
    noordoost: { rsi: 0.13, rse: 0.04 },
    oost: { rsi: 0.13, rse: 0.04 },
    zuidoost: { rsi: 0.13, rse: 0.04 },
    zuid: { rsi: 0.13, rse: 0.04 },
    zuidwest: { rsi: 0.13, rse: 0.04 },
    west: { rsi: 0.13, rse: 0.04 },
    noordwest: { rsi: 0.13, rse: 0.04 },
    boven: { rsi: 0.1, rse: 0.04 },
    onder: { rsi: 0.17, rse: 0.04 },
    grond: { rsi: 0.17, rse: 0.0 },
    kruipruimte: { rsi: 0.17, rse: 0.17 },
    'aangrenzende onverwarmde ruimte': { rsi: 0.13, rse: 0.13 },
  })

  const orientatie_mapper = {
    noord: 'buiten',
    noordoost: 'buiten',
    oost: 'buiten',
    zuidoost: 'buiten',
    zuid: 'buiten',
    zuidwest: 'buiten',
    west: 'buiten',
    noordwest: 'buiten',
    boven: 'buiten',
    onder: 'buiten',
    grond: 'grond',
    kruipruimte: 'kruipruimte',
    'aangrenzende onverwarmde ruimte': 'aor',
  }

  const algemene_correctiefactor_transmissie = 0.8
  const stromingsexponent = 0.84

  const klimaatgegevens = [
    {
      factor: 0.9,
      buitentemperatuur: 4.45740740740741,
      kruipruimtetemperatuur: 8.61435185185185,
      windsnelheid: 18.8166666666667,
      zonkracht: 473.765432098765,
      factorAOR: 0.7,
      maand: 'januari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 5.0212962962963,
      kruipruimtetemperatuur: 8.75532407407407,
      windsnelheid: 18.55,
      zonkracht: 461.728395061728,
      factorAOR: 0.7,
      maand: 'januari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 5.85740740740741,
      kruipruimtetemperatuur: 8.96435185185185,
      windsnelheid: 22.7166666666667,
      zonkracht: 312.345679012346,
      factorAOR: 0.7,
      maand: 'januari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 4.66157407407407,
      kruipruimtetemperatuur: 8.66539351851852,
      windsnelheid: 21.3166666666667,
      zonkracht: 440.432098765432,
      factorAOR: 0.7,
      maand: 'januari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 3.17777777777778,
      kruipruimtetemperatuur: 8.29444444444444,
      windsnelheid: 16.25,
      zonkracht: 473.148148148148,
      factorAOR: 0.7,
      maand: 'januari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 2.92592592592593,
      kruipruimtetemperatuur: 8.23148148148148,
      windsnelheid: 14.7166666666667,
      zonkracht: 451.234567901235,
      factorAOR: 0.7,
      maand: 'januari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 3.78842592592592,
      kruipruimtetemperatuur: 8.44710648148148,
      windsnelheid: 18.1,
      zonkracht: 482.716049382716,
      factorAOR: 0.7,
      maand: 'januari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 5.2787037037037,
      kruipruimtetemperatuur: 8.81967592592593,
      windsnelheid: 18.9666666666667,
      zonkracht: 502.469135802469,
      factorAOR: 0.7,
      maand: 'januari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 5.4875,
      kruipruimtetemperatuur: 8.871875,
      windsnelheid: 18.4166666666667,
      zonkracht: 352.777777777778,
      factorAOR: 0.7,
      maand: 'januari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 5.52175925925926,
      kruipruimtetemperatuur: 8.88043981481481,
      windsnelheid: 19.1666666666667,
      zonkracht: 463.58024691358,
      factorAOR: 0.7,
      maand: 'januari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 4.76018518518518,
      kruipruimtetemperatuur: 8.6900462962963,
      windsnelheid: 22.0333333333333,
      zonkracht: 498.765432098765,
      factorAOR: 0.7,
      maand: 'januari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 4.02962962962963,
      kruipruimtetemperatuur: 8.50740740740741,
      windsnelheid: 19.1166666666667,
      zonkracht: 679.012345679012,
      factorAOR: 0.7,
      maand: 'januari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 3.60138888888889,
      kruipruimtetemperatuur: 8.40034722222222,
      windsnelheid: 16.8,
      zonkracht: 536.728395061728,
      factorAOR: 0.7,
      maand: 'januari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 2.66666666666667,
      kruipruimtetemperatuur: 8.16666666666667,
      windsnelheid: 16.2333333333333,
      zonkracht: 575.308641975309,
      factorAOR: 0.7,
      maand: 'januari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 3.26666666666667,
      kruipruimtetemperatuur: 8.31666666666667,
      windsnelheid: 17.8333333333333,
      zonkracht: 381.172839506173,
      factorAOR: 0.7,
      maand: 'januari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 2.3537037037037,
      kruipruimtetemperatuur: 8.08842592592593,
      windsnelheid: 16.2666666666667,
      zonkracht: 715.123456790123,
      factorAOR: 0.7,
      maand: 'januari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 1.53842592592593,
      kruipruimtetemperatuur: 7.88460648148148,
      windsnelheid: 13.7333333333333,
      zonkracht: 786.41975308642,
      factorAOR: 0.7,
      maand: 'januari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 1.79305555555556,
      kruipruimtetemperatuur: 7.94826388888889,
      windsnelheid: 15.25,
      zonkracht: 606.172839506173,
      factorAOR: 0.7,
      maand: 'januari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 1.675,
      kruipruimtetemperatuur: 7.91875,
      windsnelheid: 16.2166666666667,
      zonkracht: 499.074074074074,
      factorAOR: 0.7,
      maand: 'januari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 1.3875,
      kruipruimtetemperatuur: 7.846875,
      windsnelheid: 14.3,
      zonkracht: 505.246913580247,
      factorAOR: 0.7,
      maand: 'januari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 0.785648148148148,
      kruipruimtetemperatuur: 7.69641203703704,
      windsnelheid: 13.6333333333333,
      zonkracht: 713.271604938272,
      factorAOR: 0.7,
      maand: 'januari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 0.175,
      kruipruimtetemperatuur: 7.54375,
      windsnelheid: 12.15,
      zonkracht: 959.259259259259,
      factorAOR: 0.7,
      maand: 'januari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 0.309722222222222,
      kruipruimtetemperatuur: 7.57743055555556,
      windsnelheid: 11.3,
      zonkracht: 664.814814814815,
      factorAOR: 0.7,
      maand: 'januari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 1.58287037037037,
      kruipruimtetemperatuur: 7.89571759259259,
      windsnelheid: 13.9833333333333,
      zonkracht: 575.925925925926,
      factorAOR: 0.7,
      maand: 'januari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 1.47453703703704,
      kruipruimtetemperatuur: 7.86863425925926,
      windsnelheid: 14.1833333333333,
      zonkracht: 798.765432098765,
      factorAOR: 0.7,
      maand: 'januari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 1.58888888888889,
      kruipruimtetemperatuur: 7.89722222222222,
      windsnelheid: 15.0333333333333,
      zonkracht: 750,
      factorAOR: 0.7,
      maand: 'januari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 3.35740740740741,
      kruipruimtetemperatuur: 8.33935185185185,
      windsnelheid: 18.4666666666667,
      zonkracht: 590.740740740741,
      factorAOR: 0.7,
      maand: 'januari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 3.90046296296296,
      kruipruimtetemperatuur: 8.47511574074074,
      windsnelheid: 19.8166666666667,
      zonkracht: 829.320987654321,
      factorAOR: 0.7,
      maand: 'januari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 4.78101851851852,
      kruipruimtetemperatuur: 8.69525462962963,
      windsnelheid: 25.0333333333333,
      zonkracht: 452.777777777778,
      factorAOR: 0.7,
      maand: 'januari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 3.56944444444444,
      kruipruimtetemperatuur: 8.39236111111111,
      windsnelheid: 20.05,
      zonkracht: 796.296296296296,
      factorAOR: 0.7,
      maand: 'januari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 3.23425925925926,
      kruipruimtetemperatuur: 8.30856481481482,
      windsnelheid: 18.3833333333333,
      zonkracht: 854.012345679012,
      factorAOR: 0.7,
      maand: 'januari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 3.86157407407407,
      kruipruimtetemperatuur: 8.46539351851852,
      windsnelheid: 19.8166666666667,
      zonkracht: 683.333333333333,
      factorAOR: 0.7,
      maand: 'februari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 4.0125,
      kruipruimtetemperatuur: 8.503125,
      windsnelheid: 16.7333333333333,
      zonkracht: 992.592592592593,
      factorAOR: 0.7,
      maand: 'februari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 3.75231481481481,
      kruipruimtetemperatuur: 8.4380787037037,
      windsnelheid: 14.2166666666667,
      zonkracht: 977.469135802469,
      factorAOR: 0.7,
      maand: 'februari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 3.27731481481481,
      kruipruimtetemperatuur: 8.3193287037037,
      windsnelheid: 14.4333333333333,
      zonkracht: 1014.1975308642,
      factorAOR: 0.7,
      maand: 'februari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 2.7337962962963,
      kruipruimtetemperatuur: 8.18344907407407,
      windsnelheid: 14.4833333333333,
      zonkracht: 844.444444444444,
      factorAOR: 0.7,
      maand: 'februari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 2.51157407407407,
      kruipruimtetemperatuur: 8.12789351851852,
      windsnelheid: 15.5333333333333,
      zonkracht: 1185.8024691358,
      factorAOR: 0.7,
      maand: 'februari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 1.92546296296296,
      kruipruimtetemperatuur: 7.98136574074074,
      windsnelheid: 20.2166666666667,
      zonkracht: 883.641975308642,
      factorAOR: 0.7,
      maand: 'februari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 2.49351851851852,
      kruipruimtetemperatuur: 8.12337962962963,
      windsnelheid: 21.25,
      zonkracht: 833.024691358025,
      factorAOR: 0.7,
      maand: 'februari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 2.63287037037037,
      kruipruimtetemperatuur: 8.15821759259259,
      windsnelheid: 22.2666666666667,
      zonkracht: 812.345679012346,
      factorAOR: 0.7,
      maand: 'februari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 2.36712962962963,
      kruipruimtetemperatuur: 8.09178240740741,
      windsnelheid: 18.1,
      zonkracht: 1047.22222222222,
      factorAOR: 0.7,
      maand: 'februari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 2.20462962962963,
      kruipruimtetemperatuur: 8.05115740740741,
      windsnelheid: 20.5166666666667,
      zonkracht: 1170.67901234568,
      factorAOR: 0.7,
      maand: 'februari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 1.79351851851852,
      kruipruimtetemperatuur: 7.94837962962963,
      windsnelheid: 20.2833333333333,
      zonkracht: 1297.53086419753,
      factorAOR: 0.7,
      maand: 'februari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 1.64027777777778,
      kruipruimtetemperatuur: 7.91006944444444,
      windsnelheid: 15.4833333333333,
      zonkracht: 1362.65432098765,
      factorAOR: 0.7,
      maand: 'februari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 2.42916666666667,
      kruipruimtetemperatuur: 8.10729166666667,
      windsnelheid: 17.2166666666667,
      zonkracht: 1594.13580246914,
      factorAOR: 0.7,
      maand: 'februari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 4.21990740740741,
      kruipruimtetemperatuur: 8.55497685185185,
      windsnelheid: 19.6833333333333,
      zonkracht: 1143.51851851852,
      factorAOR: 0.7,
      maand: 'februari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 4.45185185185185,
      kruipruimtetemperatuur: 8.61296296296296,
      windsnelheid: 17.0666666666667,
      zonkracht: 1377.77777777778,
      factorAOR: 0.7,
      maand: 'februari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 3.42222222222222,
      kruipruimtetemperatuur: 8.35555555555556,
      windsnelheid: 13.3,
      zonkracht: 1378.39506172839,
      factorAOR: 0.7,
      maand: 'februari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 4.07592592592593,
      kruipruimtetemperatuur: 8.51898148148148,
      windsnelheid: 13.7333333333333,
      zonkracht: 1546.6049382716,
      factorAOR: 0.7,
      maand: 'februari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 4.65833333333333,
      kruipruimtetemperatuur: 8.66458333333333,
      windsnelheid: 16.7166666666667,
      zonkracht: 1477.16049382716,
      factorAOR: 0.7,
      maand: 'februari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 5.9787037037037,
      kruipruimtetemperatuur: 8.99467592592593,
      windsnelheid: 19.1833333333333,
      zonkracht: 1188.58024691358,
      factorAOR: 0.7,
      maand: 'februari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 5.43425925925926,
      kruipruimtetemperatuur: 8.85856481481481,
      windsnelheid: 18.2166666666667,
      zonkracht: 1631.17283950617,
      factorAOR: 0.7,
      maand: 'februari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 5.0212962962963,
      kruipruimtetemperatuur: 8.75532407407407,
      windsnelheid: 20.7666666666667,
      zonkracht: 1262.96296296296,
      factorAOR: 0.7,
      maand: 'februari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 5.11805555555556,
      kruipruimtetemperatuur: 8.77951388888889,
      windsnelheid: 20.7833333333333,
      zonkracht: 1500.61728395062,
      factorAOR: 0.7,
      maand: 'februari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 4.75509259259259,
      kruipruimtetemperatuur: 8.68877314814815,
      windsnelheid: 19.3666666666667,
      zonkracht: 1498.45679012346,
      factorAOR: 0.7,
      maand: 'februari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 4.35833333333333,
      kruipruimtetemperatuur: 8.58958333333333,
      windsnelheid: 17,
      zonkracht: 1607.71604938272,
      factorAOR: 0.7,
      maand: 'februari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 3.91064814814815,
      kruipruimtetemperatuur: 8.47766203703704,
      windsnelheid: 15.1166666666667,
      zonkracht: 1888.88888888889,
      factorAOR: 0.7,
      maand: 'februari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 2.98888888888889,
      kruipruimtetemperatuur: 8.24722222222222,
      windsnelheid: 13.2333333333333,
      zonkracht: 1944.13580246914,
      factorAOR: 0.7,
      maand: 'februari',
    },
    {
      factor: 0.9,
      buitentemperatuur: 2.22407407407407,
      kruipruimtetemperatuur: 8.05601851851852,
      windsnelheid: 14.45,
      zonkracht: 2018.20987654321,
      factorAOR: 0.7,
      maand: 'februari',
    },
    {
      factor: 0.8,
      buitentemperatuur: 3.61481481481481,
      kruipruimtetemperatuur: 8.4037037037037,
      windsnelheid: 17.75,
      zonkracht: 2093.20987654321,
      factorAOR: 0.6,
      maand: 'maart',
    },
    {
      factor: 0.8,
      buitentemperatuur: 3.21759259259259,
      kruipruimtetemperatuur: 8.30439814814815,
      windsnelheid: 18.9333333333333,
      zonkracht: 2104.32098765432,
      factorAOR: 0.6,
      maand: 'maart',
    },
    {
      factor: 0.8,
      buitentemperatuur: 3.41990740740741,
      kruipruimtetemperatuur: 8.35497685185185,
      windsnelheid: 13.6166666666667,
      zonkracht: 1623.45679012346,
      factorAOR: 0.6,
      maand: 'maart',
    },
    {
      factor: 0.8,
      buitentemperatuur: 4.18888888888889,
      kruipruimtetemperatuur: 8.54722222222222,
      windsnelheid: 12.6833333333333,
      zonkracht: 2044.13580246914,
      factorAOR: 0.6,
      maand: 'maart',
    },
    {
      factor: 0.8,
      buitentemperatuur: 4.86481481481481,
      kruipruimtetemperatuur: 8.7162037037037,
      windsnelheid: 12.4,
      zonkracht: 2247.83950617284,
      factorAOR: 0.6,
      maand: 'maart',
    },
    {
      factor: 0.8,
      buitentemperatuur: 5.65925925925926,
      kruipruimtetemperatuur: 8.91481481481481,
      windsnelheid: 12.5,
      zonkracht: 1800.92592592593,
      factorAOR: 0.6,
      maand: 'maart',
    },
    {
      factor: 0.8,
      buitentemperatuur: 5.18425925925926,
      kruipruimtetemperatuur: 8.79606481481481,
      windsnelheid: 16.45,
      zonkracht: 1483.02469135802,
      factorAOR: 0.6,
      maand: 'maart',
    },
    {
      factor: 0.8,
      buitentemperatuur: 5.4,
      kruipruimtetemperatuur: 8.85,
      windsnelheid: 17,
      zonkracht: 2300.92592592593,
      factorAOR: 0.6,
      maand: 'maart',
    },
    {
      factor: 0.8,
      buitentemperatuur: 6.13240740740741,
      kruipruimtetemperatuur: 9.03310185185185,
      windsnelheid: 16.3666666666667,
      zonkracht: 1645.06172839506,
      factorAOR: 0.6,
      maand: 'maart',
    },
    {
      factor: 0.8,
      buitentemperatuur: 6.07407407407407,
      kruipruimtetemperatuur: 9.01851851851852,
      windsnelheid: 18.0666666666667,
      zonkracht: 2361.11111111111,
      factorAOR: 0.6,
      maand: 'maart',
    },
    {
      factor: 0.8,
      buitentemperatuur: 5.76759259259259,
      kruipruimtetemperatuur: 8.94189814814815,
      windsnelheid: 19.55,
      zonkracht: 2320.98765432099,
      factorAOR: 0.6,
      maand: 'maart',
    },
    {
      factor: 0.8,
      buitentemperatuur: 5.36296296296296,
      kruipruimtetemperatuur: 8.84074074074074,
      windsnelheid: 16.45,
      zonkracht: 2825.30864197531,
      factorAOR: 0.6,
      maand: 'maart',
    },
    {
      factor: 0.8,
      buitentemperatuur: 4.4337962962963,
      kruipruimtetemperatuur: 8.60844907407407,
      windsnelheid: 17.05,
      zonkracht: 2572.83950617284,
      factorAOR: 0.6,
      maand: 'maart',
    },
    {
      factor: 0.8,
      buitentemperatuur: 4.43472222222222,
      kruipruimtetemperatuur: 8.60868055555556,
      windsnelheid: 16.3833333333333,
      zonkracht: 2433.02469135802,
      factorAOR: 0.6,
      maand: 'maart',
    },
    {
      factor: 0.8,
      buitentemperatuur: 5.14490740740741,
      kruipruimtetemperatuur: 8.78622685185185,
      windsnelheid: 19.2333333333333,
      zonkracht: 2508.33333333333,
      factorAOR: 0.6,
      maand: 'maart',
    },
    {
      factor: 0.8,
      buitentemperatuur: 6.56805555555556,
      kruipruimtetemperatuur: 9.14201388888889,
      windsnelheid: 21.3166666666667,
      zonkracht: 2059.87654320988,
      factorAOR: 0.6,
      maand: 'maart',
    },
    {
      factor: 0.8,
      buitentemperatuur: 5.2412037037037,
      kruipruimtetemperatuur: 8.81030092592593,
      windsnelheid: 19.4166666666667,
      zonkracht: 2556.48148148148,
      factorAOR: 0.6,
      maand: 'maart',
    },
    {
      factor: 0.8,
      buitentemperatuur: 5.38009259259259,
      kruipruimtetemperatuur: 8.84502314814815,
      windsnelheid: 18.55,
      zonkracht: 2662.96296296296,
      factorAOR: 0.6,
      maand: 'maart',
    },
    {
      factor: 0.8,
      buitentemperatuur: 5.91712962962963,
      kruipruimtetemperatuur: 8.97928240740741,
      windsnelheid: 20.1833333333333,
      zonkracht: 2271.2962962963,
      factorAOR: 0.6,
      maand: 'maart',
    },
    {
      factor: 0.8,
      buitentemperatuur: 5.85231481481481,
      kruipruimtetemperatuur: 8.9630787037037,
      windsnelheid: 18.5833333333333,
      zonkracht: 2277.16049382716,
      factorAOR: 0.6,
      maand: 'maart',
    },
    {
      factor: 0.8,
      buitentemperatuur: 4.93240740740741,
      kruipruimtetemperatuur: 8.73310185185185,
      windsnelheid: 16.9833333333333,
      zonkracht: 2124.69135802469,
      factorAOR: 0.6,
      maand: 'maart',
    },
    {
      factor: 0.8,
      buitentemperatuur: 4.69537037037037,
      kruipruimtetemperatuur: 8.67384259259259,
      windsnelheid: 16.2666666666667,
      zonkracht: 2755.55555555556,
      factorAOR: 0.6,
      maand: 'maart',
    },
    {
      factor: 0.8,
      buitentemperatuur: 4.7087962962963,
      kruipruimtetemperatuur: 8.67719907407407,
      windsnelheid: 17.1833333333333,
      zonkracht: 2669.75308641975,
      factorAOR: 0.6,
      maand: 'maart',
    },
    {
      factor: 0.8,
      buitentemperatuur: 5.35601851851852,
      kruipruimtetemperatuur: 8.83900462962963,
      windsnelheid: 14.7,
      zonkracht: 3340.43209876543,
      factorAOR: 0.6,
      maand: 'maart',
    },
    {
      factor: 0.8,
      buitentemperatuur: 5.33703703703704,
      kruipruimtetemperatuur: 8.83425925925926,
      windsnelheid: 12.45,
      zonkracht: 3005.55555555556,
      factorAOR: 0.6,
      maand: 'maart',
    },
    {
      factor: 0.8,
      buitentemperatuur: 5.41574074074074,
      kruipruimtetemperatuur: 8.85393518518519,
      windsnelheid: 13.8833333333333,
      zonkracht: 2907.71604938272,
      factorAOR: 0.6,
      maand: 'maart',
    },
    {
      factor: 0.8,
      buitentemperatuur: 5.90092592592593,
      kruipruimtetemperatuur: 8.97523148148148,
      windsnelheid: 17.1,
      zonkracht: 3729.62962962963,
      factorAOR: 0.6,
      maand: 'maart',
    },
    {
      factor: 0.8,
      buitentemperatuur: 6.6662037037037,
      kruipruimtetemperatuur: 9.16655092592593,
      windsnelheid: 19.3333333333333,
      zonkracht: 2767.9012345679,
      factorAOR: 0.6,
      maand: 'maart',
    },
    {
      factor: 0.8,
      buitentemperatuur: 7.49583333333333,
      kruipruimtetemperatuur: 9.37395833333333,
      windsnelheid: 19.1,
      zonkracht: 2665.12345679012,
      factorAOR: 0.6,
      maand: 'maart',
    },
    {
      factor: 0.8,
      buitentemperatuur: 7.59166666666667,
      kruipruimtetemperatuur: 9.39791666666667,
      windsnelheid: 16.2,
      zonkracht: 3416.97530864198,
      factorAOR: 0.6,
      maand: 'maart',
    },
    {
      factor: 0.8,
      buitentemperatuur: 7.97962962962963,
      kruipruimtetemperatuur: 9.49490740740741,
      windsnelheid: 14.4833333333333,
      zonkracht: 3041.35802469136,
      factorAOR: 0.6,
      maand: 'maart',
    },
    {
      factor: 0.7,
      buitentemperatuur: 6.23333333333333,
      kruipruimtetemperatuur: 9.05833333333333,
      windsnelheid: 12.2,
      zonkracht: 3695.06172839506,
      factorAOR: 0.5,
      maand: 'april',
    },
    {
      factor: 0.7,
      buitentemperatuur: 6.53194444444444,
      kruipruimtetemperatuur: 9.13298611111111,
      windsnelheid: 13.0333333333333,
      zonkracht: 3729.93827160494,
      factorAOR: 0.5,
      maand: 'april',
    },
    {
      factor: 0.7,
      buitentemperatuur: 7.74768518518518,
      kruipruimtetemperatuur: 9.4369212962963,
      windsnelheid: 14.7833333333333,
      zonkracht: 3729.01234567901,
      factorAOR: 0.5,
      maand: 'april',
    },
    {
      factor: 0.7,
      buitentemperatuur: 7.50416666666667,
      kruipruimtetemperatuur: 9.37604166666667,
      windsnelheid: 13.1166666666667,
      zonkracht: 3046.2962962963,
      factorAOR: 0.5,
      maand: 'april',
    },
    {
      factor: 0.7,
      buitentemperatuur: 7.31388888888889,
      kruipruimtetemperatuur: 9.32847222222222,
      windsnelheid: 16.3666666666667,
      zonkracht: 3694.75308641975,
      factorAOR: 0.5,
      maand: 'april',
    },
    {
      factor: 0.7,
      buitentemperatuur: 7.82546296296296,
      kruipruimtetemperatuur: 9.45636574074074,
      windsnelheid: 14.25,
      zonkracht: 3536.41975308642,
      factorAOR: 0.5,
      maand: 'april',
    },
    {
      factor: 0.7,
      buitentemperatuur: 8.5412037037037,
      kruipruimtetemperatuur: 9.63530092592593,
      windsnelheid: 14.4833333333333,
      zonkracht: 3372.83950617284,
      factorAOR: 0.5,
      maand: 'april',
    },
    {
      factor: 0.7,
      buitentemperatuur: 8.20277777777778,
      kruipruimtetemperatuur: 9.55069444444444,
      windsnelheid: 14.0166666666667,
      zonkracht: 3165.43209876543,
      factorAOR: 0.5,
      maand: 'april',
    },
    {
      factor: 0.7,
      buitentemperatuur: 8.96388888888889,
      kruipruimtetemperatuur: 9.74097222222222,
      windsnelheid: 11.8166666666667,
      zonkracht: 3480.86419753086,
      factorAOR: 0.5,
      maand: 'april',
    },
    {
      factor: 0.7,
      buitentemperatuur: 9.56342592592593,
      kruipruimtetemperatuur: 9.89085648148148,
      windsnelheid: 12.75,
      zonkracht: 3854.62962962963,
      factorAOR: 0.5,
      maand: 'april',
    },
    {
      factor: 0.7,
      buitentemperatuur: 8.01851851851852,
      kruipruimtetemperatuur: 9.50462962962963,
      windsnelheid: 14.1833333333333,
      zonkracht: 3427.16049382716,
      factorAOR: 0.5,
      maand: 'april',
    },
    {
      factor: 0.7,
      buitentemperatuur: 8.40185185185185,
      kruipruimtetemperatuur: 9.60046296296296,
      windsnelheid: 17.4333333333333,
      zonkracht: 3985.49382716049,
      factorAOR: 0.5,
      maand: 'april',
    },
    {
      factor: 0.7,
      buitentemperatuur: 8.51388888888889,
      kruipruimtetemperatuur: 9.62847222222222,
      windsnelheid: 15.1833333333333,
      zonkracht: 3976.85185185185,
      factorAOR: 0.5,
      maand: 'april',
    },
    {
      factor: 0.7,
      buitentemperatuur: 8.38657407407407,
      kruipruimtetemperatuur: 9.59664351851852,
      windsnelheid: 16.6833333333333,
      zonkracht: 3728.7037037037,
      factorAOR: 0.5,
      maand: 'april',
    },
    {
      factor: 0.7,
      buitentemperatuur: 8.22777777777778,
      kruipruimtetemperatuur: 9.55694444444444,
      windsnelheid: 14.3333333333333,
      zonkracht: 3990.43209876543,
      factorAOR: 0.5,
      maand: 'april',
    },
    {
      factor: 0.7,
      buitentemperatuur: 7.91805555555556,
      kruipruimtetemperatuur: 9.47951388888889,
      windsnelheid: 12.7,
      zonkracht: 4681.48148148148,
      factorAOR: 0.5,
      maand: 'april',
    },
    {
      factor: 0.7,
      buitentemperatuur: 8.31666666666667,
      kruipruimtetemperatuur: 9.57916666666667,
      windsnelheid: 15.3666666666667,
      zonkracht: 4691.66666666667,
      factorAOR: 0.5,
      maand: 'april',
    },
    {
      factor: 0.7,
      buitentemperatuur: 8.81111111111111,
      kruipruimtetemperatuur: 9.70277777777778,
      windsnelheid: 16.3,
      zonkracht: 5339.81481481482,
      factorAOR: 0.5,
      maand: 'april',
    },
    {
      factor: 0.7,
      buitentemperatuur: 9.62731481481482,
      kruipruimtetemperatuur: 9.9068287037037,
      windsnelheid: 15.05,
      zonkracht: 4727.46913580247,
      factorAOR: 0.5,
      maand: 'april',
    },
    {
      factor: 0.7,
      buitentemperatuur: 10.3884259259259,
      kruipruimtetemperatuur: 10.0971064814815,
      windsnelheid: 14.7833333333333,
      zonkracht: 5798.76543209877,
      factorAOR: 0.5,
      maand: 'april',
    },
    {
      factor: 0.7,
      buitentemperatuur: 9.92037037037037,
      kruipruimtetemperatuur: 9.98009259259259,
      windsnelheid: 14.3833333333333,
      zonkracht: 4909.56790123457,
      factorAOR: 0.5,
      maand: 'april',
    },
    {
      factor: 0.7,
      buitentemperatuur: 10.4046296296296,
      kruipruimtetemperatuur: 10.1011574074074,
      windsnelheid: 15.7666666666667,
      zonkracht: 5081.48148148148,
      factorAOR: 0.5,
      maand: 'april',
    },
    {
      factor: 0.7,
      buitentemperatuur: 10.0069444444444,
      kruipruimtetemperatuur: 10.0017361111111,
      windsnelheid: 14.4166666666667,
      zonkracht: 5299.69135802469,
      factorAOR: 0.5,
      maand: 'april',
    },
    {
      factor: 0.7,
      buitentemperatuur: 10.8148148148148,
      kruipruimtetemperatuur: 10.2037037037037,
      windsnelheid: 14.7333333333333,
      zonkracht: 4950,
      factorAOR: 0.5,
      maand: 'april',
    },
    {
      factor: 0.7,
      buitentemperatuur: 10.275,
      kruipruimtetemperatuur: 10.06875,
      windsnelheid: 16.15,
      zonkracht: 4340.74074074074,
      factorAOR: 0.5,
      maand: 'april',
    },
    {
      factor: 0.7,
      buitentemperatuur: 8.3337962962963,
      kruipruimtetemperatuur: 9.58344907407407,
      windsnelheid: 13.7333333333333,
      zonkracht: 3729.01234567901,
      factorAOR: 0.5,
      maand: 'april',
    },
    {
      factor: 0.7,
      buitentemperatuur: 8.52407407407407,
      kruipruimtetemperatuur: 9.63101851851852,
      windsnelheid: 12.6,
      zonkracht: 4673.76543209877,
      factorAOR: 0.5,
      maand: 'april',
    },
    {
      factor: 0.7,
      buitentemperatuur: 9.13148148148148,
      kruipruimtetemperatuur: 9.78287037037037,
      windsnelheid: 12.7666666666667,
      zonkracht: 4691.66666666667,
      factorAOR: 0.5,
      maand: 'april',
    },
    {
      factor: 0.7,
      buitentemperatuur: 8.7875,
      kruipruimtetemperatuur: 9.696875,
      windsnelheid: 13.0833333333333,
      zonkracht: 3328.3950617284,
      factorAOR: 0.5,
      maand: 'april',
    },
    {
      factor: 0.7,
      buitentemperatuur: 9.30925925925926,
      kruipruimtetemperatuur: 9.82731481481481,
      windsnelheid: 14.7666666666667,
      zonkracht: 3470.98765432099,
      factorAOR: 0.5,
      maand: 'april',
    },
    {
      factor: 0.5,
      buitentemperatuur: 8.66203703703704,
      kruipruimtetemperatuur: 9.66550925925926,
      windsnelheid: 14.05,
      zonkracht: 3901.54320987654,
      factorAOR: 0.3,
      maand: 'mei',
    },
    {
      factor: 0.5,
      buitentemperatuur: 8.6875,
      kruipruimtetemperatuur: 9.671875,
      windsnelheid: 15.2666666666667,
      zonkracht: 4462.34567901235,
      factorAOR: 0.3,
      maand: 'mei',
    },
    {
      factor: 0.5,
      buitentemperatuur: 10.0185185185185,
      kruipruimtetemperatuur: 10.0046296296296,
      windsnelheid: 13.9833333333333,
      zonkracht: 4542.28395061728,
      factorAOR: 0.3,
      maand: 'mei',
    },
    {
      factor: 0.5,
      buitentemperatuur: 9.92546296296296,
      kruipruimtetemperatuur: 9.98136574074074,
      windsnelheid: 16.45,
      zonkracht: 4182.0987654321,
      factorAOR: 0.3,
      maand: 'mei',
    },
    {
      factor: 0.5,
      buitentemperatuur: 10.5236111111111,
      kruipruimtetemperatuur: 10.1309027777778,
      windsnelheid: 14.95,
      zonkracht: 4428.7037037037,
      factorAOR: 0.3,
      maand: 'mei',
    },
    {
      factor: 0.5,
      buitentemperatuur: 10.9717592592593,
      kruipruimtetemperatuur: 10.2429398148148,
      windsnelheid: 13.95,
      zonkracht: 5300.61728395062,
      factorAOR: 0.3,
      maand: 'mei',
    },
    {
      factor: 0.5,
      buitentemperatuur: 11.6134259259259,
      kruipruimtetemperatuur: 10.4033564814815,
      windsnelheid: 14.2166666666667,
      zonkracht: 5945.37037037037,
      factorAOR: 0.3,
      maand: 'mei',
    },
    {
      factor: 0.5,
      buitentemperatuur: 12.3717592592593,
      kruipruimtetemperatuur: 10.5929398148148,
      windsnelheid: 12.4333333333333,
      zonkracht: 4739.81481481481,
      factorAOR: 0.3,
      maand: 'mei',
    },
    {
      factor: 0.5,
      buitentemperatuur: 13.4324074074074,
      kruipruimtetemperatuur: 10.8581018518519,
      windsnelheid: 14.9166666666667,
      zonkracht: 4868.20987654321,
      factorAOR: 0.3,
      maand: 'mei',
    },
    {
      factor: 0.5,
      buitentemperatuur: 12.7287037037037,
      kruipruimtetemperatuur: 10.6821759259259,
      windsnelheid: 14.6833333333333,
      zonkracht: 4464.5061728395,
      factorAOR: 0.3,
      maand: 'mei',
    },
    {
      factor: 0.5,
      buitentemperatuur: 12.9842592592593,
      kruipruimtetemperatuur: 10.7460648148148,
      windsnelheid: 15.6333333333333,
      zonkracht: 4530.24691358025,
      factorAOR: 0.3,
      maand: 'mei',
    },
    {
      factor: 0.5,
      buitentemperatuur: 13.0101851851852,
      kruipruimtetemperatuur: 10.7525462962963,
      windsnelheid: 14.8166666666667,
      zonkracht: 5139.50617283951,
      factorAOR: 0.3,
      maand: 'mei',
    },
    {
      factor: 0.5,
      buitentemperatuur: 11.9324074074074,
      kruipruimtetemperatuur: 10.4831018518519,
      windsnelheid: 14.4333333333333,
      zonkracht: 5137.96296296296,
      factorAOR: 0.3,
      maand: 'mei',
    },
    {
      factor: 0.5,
      buitentemperatuur: 10.850462962963,
      kruipruimtetemperatuur: 10.2126157407407,
      windsnelheid: 14.1166666666667,
      zonkracht: 5207.71604938272,
      factorAOR: 0.3,
      maand: 'mei',
    },
    {
      factor: 0.5,
      buitentemperatuur: 10.3657407407407,
      kruipruimtetemperatuur: 10.0914351851852,
      windsnelheid: 11.8666666666667,
      zonkracht: 5402.77777777778,
      factorAOR: 0.3,
      maand: 'mei',
    },
    {
      factor: 0.5,
      buitentemperatuur: 10.8203703703704,
      kruipruimtetemperatuur: 10.2050925925926,
      windsnelheid: 13.6,
      zonkracht: 4613.88888888889,
      factorAOR: 0.3,
      maand: 'mei',
    },
    {
      factor: 0.5,
      buitentemperatuur: 11.6305555555556,
      kruipruimtetemperatuur: 10.4076388888889,
      windsnelheid: 12.6166666666667,
      zonkracht: 4609.56790123457,
      factorAOR: 0.3,
      maand: 'mei',
    },
    {
      factor: 0.5,
      buitentemperatuur: 11.5134259259259,
      kruipruimtetemperatuur: 10.3783564814815,
      windsnelheid: 12.5,
      zonkracht: 4427.16049382716,
      factorAOR: 0.3,
      maand: 'mei',
    },
    {
      factor: 0.5,
      buitentemperatuur: 12.112962962963,
      kruipruimtetemperatuur: 10.5282407407407,
      windsnelheid: 12.65,
      zonkracht: 4180.86419753086,
      factorAOR: 0.3,
      maand: 'mei',
    },
    {
      factor: 0.5,
      buitentemperatuur: 13.6972222222222,
      kruipruimtetemperatuur: 10.9243055555556,
      windsnelheid: 10.75,
      zonkracht: 4479.62962962963,
      factorAOR: 0.3,
      maand: 'mei',
    },
    {
      factor: 0.5,
      buitentemperatuur: 14.3898148148148,
      kruipruimtetemperatuur: 11.0974537037037,
      windsnelheid: 13.5,
      zonkracht: 4729.32098765432,
      factorAOR: 0.3,
      maand: 'mei',
    },
    {
      factor: 0.5,
      buitentemperatuur: 15.3657407407407,
      kruipruimtetemperatuur: 11.3414351851852,
      windsnelheid: 15.35,
      zonkracht: 5417.9012345679,
      factorAOR: 0.3,
      maand: 'mei',
    },
    {
      factor: 0.5,
      buitentemperatuur: 13.7175925925926,
      kruipruimtetemperatuur: 10.9293981481481,
      windsnelheid: 12.2833333333333,
      zonkracht: 3858.02469135802,
      factorAOR: 0.3,
      maand: 'mei',
    },
    {
      factor: 0.5,
      buitentemperatuur: 13.2824074074074,
      kruipruimtetemperatuur: 10.8206018518519,
      windsnelheid: 14.2166666666667,
      zonkracht: 4795.98765432099,
      factorAOR: 0.3,
      maand: 'mei',
    },
    {
      factor: 0.5,
      buitentemperatuur: 12.7902777777778,
      kruipruimtetemperatuur: 10.6975694444444,
      windsnelheid: 13.8333333333333,
      zonkracht: 4276.54320987654,
      factorAOR: 0.3,
      maand: 'mei',
    },
    {
      factor: 0.5,
      buitentemperatuur: 13.6402777777778,
      kruipruimtetemperatuur: 10.9100694444444,
      windsnelheid: 14.8166666666667,
      zonkracht: 4969.44444444444,
      factorAOR: 0.3,
      maand: 'mei',
    },
    {
      factor: 0.5,
      buitentemperatuur: 14.9449074074074,
      kruipruimtetemperatuur: 11.2362268518519,
      windsnelheid: 12.9833333333333,
      zonkracht: 5871.2962962963,
      factorAOR: 0.3,
      maand: 'mei',
    },
    {
      factor: 0.5,
      buitentemperatuur: 14.087037037037,
      kruipruimtetemperatuur: 11.0217592592593,
      windsnelheid: 12.7666666666667,
      zonkracht: 5948.45679012346,
      factorAOR: 0.3,
      maand: 'mei',
    },
    {
      factor: 0.5,
      buitentemperatuur: 14.4712962962963,
      kruipruimtetemperatuur: 11.1178240740741,
      windsnelheid: 13.5,
      zonkracht: 5153.08641975309,
      factorAOR: 0.3,
      maand: 'mei',
    },
    {
      factor: 0.5,
      buitentemperatuur: 14.4018518518519,
      kruipruimtetemperatuur: 11.100462962963,
      windsnelheid: 11.6833333333333,
      zonkracht: 5634.87654320988,
      factorAOR: 0.3,
      maand: 'mei',
    },
    {
      factor: 0.5,
      buitentemperatuur: 14.6953703703704,
      kruipruimtetemperatuur: 11.1738425925926,
      windsnelheid: 14.0833333333333,
      zonkracht: 6300.61728395062,
      factorAOR: 0.3,
      maand: 'mei',
    },
    {
      factor: 0.4,
      buitentemperatuur: 14.6240740740741,
      kruipruimtetemperatuur: 11.1560185185185,
      windsnelheid: 12.3666666666667,
      zonkracht: 5836.11111111111,
      factorAOR: 0.2,
      maand: 'juni',
    },
    {
      factor: 0.4,
      buitentemperatuur: 16.0648148148148,
      kruipruimtetemperatuur: 11.5162037037037,
      windsnelheid: 16.1833333333333,
      zonkracht: 5371.91358024691,
      factorAOR: 0.2,
      maand: 'juni',
    },
    {
      factor: 0.4,
      buitentemperatuur: 16.7828703703704,
      kruipruimtetemperatuur: 11.6957175925926,
      windsnelheid: 12.8833333333333,
      zonkracht: 5232.40740740741,
      factorAOR: 0.2,
      maand: 'juni',
    },
    {
      factor: 0.4,
      buitentemperatuur: 16.4935185185185,
      kruipruimtetemperatuur: 11.6233796296296,
      windsnelheid: 10.9,
      zonkracht: 6056.79012345679,
      factorAOR: 0.2,
      maand: 'juni',
    },
    {
      factor: 0.4,
      buitentemperatuur: 16.8138888888889,
      kruipruimtetemperatuur: 11.7034722222222,
      windsnelheid: 13.35,
      zonkracht: 5445.98765432099,
      factorAOR: 0.2,
      maand: 'juni',
    },
    {
      factor: 0.4,
      buitentemperatuur: 15.2300925925926,
      kruipruimtetemperatuur: 11.3075231481481,
      windsnelheid: 14.55,
      zonkracht: 5961.41975308642,
      factorAOR: 0.2,
      maand: 'juni',
    },
    {
      factor: 0.4,
      buitentemperatuur: 15.925,
      kruipruimtetemperatuur: 11.48125,
      windsnelheid: 14.2,
      zonkracht: 6427.16049382716,
      factorAOR: 0.2,
      maand: 'juni',
    },
    {
      factor: 0.4,
      buitentemperatuur: 15.8203703703704,
      kruipruimtetemperatuur: 11.4550925925926,
      windsnelheid: 13.0666666666667,
      zonkracht: 5575.61728395062,
      factorAOR: 0.2,
      maand: 'juni',
    },
    {
      factor: 0.4,
      buitentemperatuur: 15.162037037037,
      kruipruimtetemperatuur: 11.2905092592593,
      windsnelheid: 12.5166666666667,
      zonkracht: 4628.08641975309,
      factorAOR: 0.2,
      maand: 'juni',
    },
    {
      factor: 0.4,
      buitentemperatuur: 15.6324074074074,
      kruipruimtetemperatuur: 11.4081018518519,
      windsnelheid: 12,
      zonkracht: 5570.98765432099,
      factorAOR: 0.2,
      maand: 'juni',
    },
    {
      factor: 0.4,
      buitentemperatuur: 15.7231481481481,
      kruipruimtetemperatuur: 11.430787037037,
      windsnelheid: 13.8333333333333,
      zonkracht: 5792.9012345679,
      factorAOR: 0.2,
      maand: 'juni',
    },
    {
      factor: 0.4,
      buitentemperatuur: 15.7967592592593,
      kruipruimtetemperatuur: 11.4491898148148,
      windsnelheid: 12.95,
      zonkracht: 4466.97530864198,
      factorAOR: 0.2,
      maand: 'juni',
    },
    {
      factor: 0.4,
      buitentemperatuur: 16.2787037037037,
      kruipruimtetemperatuur: 11.5696759259259,
      windsnelheid: 15.3833333333333,
      zonkracht: 5170.06172839506,
      factorAOR: 0.2,
      maand: 'juni',
    },
    {
      factor: 0.4,
      buitentemperatuur: 16.2050925925926,
      kruipruimtetemperatuur: 11.5512731481481,
      windsnelheid: 12.0333333333333,
      zonkracht: 4980.55555555556,
      factorAOR: 0.2,
      maand: 'juni',
    },
    {
      factor: 0.4,
      buitentemperatuur: 15.7902777777778,
      kruipruimtetemperatuur: 11.4475694444444,
      windsnelheid: 11.8666666666667,
      zonkracht: 5165.43209876543,
      factorAOR: 0.2,
      maand: 'juni',
    },
    {
      factor: 0.4,
      buitentemperatuur: 15.5050925925926,
      kruipruimtetemperatuur: 11.3762731481481,
      windsnelheid: 12.85,
      zonkracht: 4785.8024691358,
      factorAOR: 0.2,
      maand: 'juni',
    },
    {
      factor: 0.4,
      buitentemperatuur: 17.0625,
      kruipruimtetemperatuur: 11.765625,
      windsnelheid: 12.3333333333333,
      zonkracht: 5237.96296296296,
      factorAOR: 0.2,
      maand: 'juni',
    },
    {
      factor: 0.4,
      buitentemperatuur: 17.1833333333333,
      kruipruimtetemperatuur: 11.7958333333333,
      windsnelheid: 11.4,
      zonkracht: 4696.91358024691,
      factorAOR: 0.2,
      maand: 'juni',
    },
    {
      factor: 0.4,
      buitentemperatuur: 17.2125,
      kruipruimtetemperatuur: 11.803125,
      windsnelheid: 12.4,
      zonkracht: 3995.37037037037,
      factorAOR: 0.2,
      maand: 'juni',
    },
    {
      factor: 0.4,
      buitentemperatuur: 16.5990740740741,
      kruipruimtetemperatuur: 11.6497685185185,
      windsnelheid: 12.6666666666667,
      zonkracht: 5228.7037037037,
      factorAOR: 0.2,
      maand: 'juni',
    },
    {
      factor: 0.4,
      buitentemperatuur: 15.3259259259259,
      kruipruimtetemperatuur: 11.3314814814815,
      windsnelheid: 14.7833333333333,
      zonkracht: 4197.83950617284,
      factorAOR: 0.2,
      maand: 'juni',
    },
    {
      factor: 0.4,
      buitentemperatuur: 16.2069444444444,
      kruipruimtetemperatuur: 11.5517361111111,
      windsnelheid: 14.95,
      zonkracht: 4873.76543209876,
      factorAOR: 0.2,
      maand: 'juni',
    },
    {
      factor: 0.4,
      buitentemperatuur: 15.5458333333333,
      kruipruimtetemperatuur: 11.3864583333333,
      windsnelheid: 14.3833333333333,
      zonkracht: 4985.49382716049,
      factorAOR: 0.2,
      maand: 'juni',
    },
    {
      factor: 0.4,
      buitentemperatuur: 15.7597222222222,
      kruipruimtetemperatuur: 11.4399305555556,
      windsnelheid: 12,
      zonkracht: 4278.7037037037,
      factorAOR: 0.2,
      maand: 'juni',
    },
    {
      factor: 0.4,
      buitentemperatuur: 16.2481481481482,
      kruipruimtetemperatuur: 11.562037037037,
      windsnelheid: 12.2666666666667,
      zonkracht: 5602.77777777778,
      factorAOR: 0.2,
      maand: 'juni',
    },
    {
      factor: 0.4,
      buitentemperatuur: 17.1027777777778,
      kruipruimtetemperatuur: 11.7756944444444,
      windsnelheid: 10.6166666666667,
      zonkracht: 5709.25925925926,
      factorAOR: 0.2,
      maand: 'juni',
    },
    {
      factor: 0.4,
      buitentemperatuur: 17.4486111111111,
      kruipruimtetemperatuur: 11.8621527777778,
      windsnelheid: 12.6333333333333,
      zonkracht: 5666.97530864197,
      factorAOR: 0.2,
      maand: 'juni',
    },
    {
      factor: 0.4,
      buitentemperatuur: 17.9592592592593,
      kruipruimtetemperatuur: 11.9898148148148,
      windsnelheid: 12.3666666666667,
      zonkracht: 4598.76543209877,
      factorAOR: 0.2,
      maand: 'juni',
    },
    {
      factor: 0.4,
      buitentemperatuur: 16.2587962962963,
      kruipruimtetemperatuur: 11.5646990740741,
      windsnelheid: 12.5333333333333,
      zonkracht: 4958.64197530864,
      factorAOR: 0.2,
      maand: 'juni',
    },
    {
      factor: 0.4,
      buitentemperatuur: 15.8435185185185,
      kruipruimtetemperatuur: 11.4608796296296,
      windsnelheid: 14.8166666666667,
      zonkracht: 4642.9012345679,
      factorAOR: 0.2,
      maand: 'juni',
    },
    {
      factor: 0.2,
      buitentemperatuur: 16.6648148148148,
      kruipruimtetemperatuur: 11.6662037037037,
      windsnelheid: 14.8333333333333,
      zonkracht: 5079.93827160494,
      factorAOR: 0,
      maand: 'juli',
    },
    {
      factor: 0.2,
      buitentemperatuur: 17.7171296296296,
      kruipruimtetemperatuur: 11.9292824074074,
      windsnelheid: 13.1333333333333,
      zonkracht: 5809.25925925926,
      factorAOR: 0,
      maand: 'juli',
    },
    {
      factor: 0.2,
      buitentemperatuur: 17.6032407407407,
      kruipruimtetemperatuur: 11.9008101851852,
      windsnelheid: 12.5833333333333,
      zonkracht: 5892.59259259259,
      factorAOR: 0,
      maand: 'juli',
    },
    {
      factor: 0.2,
      buitentemperatuur: 18.1333333333333,
      kruipruimtetemperatuur: 12.0333333333333,
      windsnelheid: 12.9,
      zonkracht: 5707.40740740741,
      factorAOR: 0,
      maand: 'juli',
    },
    {
      factor: 0.2,
      buitentemperatuur: 17.1259259259259,
      kruipruimtetemperatuur: 11.7814814814815,
      windsnelheid: 13.4166666666667,
      zonkracht: 4509.56790123457,
      factorAOR: 0,
      maand: 'juli',
    },
    {
      factor: 0.2,
      buitentemperatuur: 17.1287037037037,
      kruipruimtetemperatuur: 11.7821759259259,
      windsnelheid: 15.7166666666667,
      zonkracht: 5681.79012345679,
      factorAOR: 0,
      maand: 'juli',
    },
    {
      factor: 0.2,
      buitentemperatuur: 16.8041666666667,
      kruipruimtetemperatuur: 11.7010416666667,
      windsnelheid: 13.05,
      zonkracht: 6065.12345679012,
      factorAOR: 0,
      maand: 'juli',
    },
    {
      factor: 0.2,
      buitentemperatuur: 15.9365740740741,
      kruipruimtetemperatuur: 11.4841435185185,
      windsnelheid: 13.15,
      zonkracht: 5120.37037037037,
      factorAOR: 0,
      maand: 'juli',
    },
    {
      factor: 0.2,
      buitentemperatuur: 15.6564814814815,
      kruipruimtetemperatuur: 11.4141203703704,
      windsnelheid: 12.25,
      zonkracht: 4342.28395061728,
      factorAOR: 0,
      maand: 'juli',
    },
    {
      factor: 0.2,
      buitentemperatuur: 16.4819444444444,
      kruipruimtetemperatuur: 11.6204861111111,
      windsnelheid: 12.6333333333333,
      zonkracht: 4707.71604938272,
      factorAOR: 0,
      maand: 'juli',
    },
    {
      factor: 0.2,
      buitentemperatuur: 17.4976851851852,
      kruipruimtetemperatuur: 11.8744212962963,
      windsnelheid: 13.3,
      zonkracht: 5357.71604938272,
      factorAOR: 0,
      maand: 'juli',
    },
    {
      factor: 0.2,
      buitentemperatuur: 16.525,
      kruipruimtetemperatuur: 11.63125,
      windsnelheid: 12.6333333333333,
      zonkracht: 4537.65432098766,
      factorAOR: 0,
      maand: 'juli',
    },
    {
      factor: 0.2,
      buitentemperatuur: 16.2412037037037,
      kruipruimtetemperatuur: 11.5603009259259,
      windsnelheid: 10.1,
      zonkracht: 4466.66666666667,
      factorAOR: 0,
      maand: 'juli',
    },
    {
      factor: 0.2,
      buitentemperatuur: 16.6305555555556,
      kruipruimtetemperatuur: 11.6576388888889,
      windsnelheid: 10.45,
      zonkracht: 4450.61728395062,
      factorAOR: 0,
      maand: 'juli',
    },
    {
      factor: 0.2,
      buitentemperatuur: 16.7634259259259,
      kruipruimtetemperatuur: 11.6908564814815,
      windsnelheid: 11.3333333333333,
      zonkracht: 4346.2962962963,
      factorAOR: 0,
      maand: 'juli',
    },
    {
      factor: 0.2,
      buitentemperatuur: 17.2143518518519,
      kruipruimtetemperatuur: 11.803587962963,
      windsnelheid: 11.0166666666667,
      zonkracht: 5058.64197530864,
      factorAOR: 0,
      maand: 'juli',
    },
    {
      factor: 0.2,
      buitentemperatuur: 18.0708333333333,
      kruipruimtetemperatuur: 12.0177083333333,
      windsnelheid: 11.7833333333333,
      zonkracht: 5430.86419753086,
      factorAOR: 0,
      maand: 'juli',
    },
    {
      factor: 0.2,
      buitentemperatuur: 18.2652777777778,
      kruipruimtetemperatuur: 12.0663194444444,
      windsnelheid: 11,
      zonkracht: 6227.77777777778,
      factorAOR: 0,
      maand: 'juli',
    },
    {
      factor: 0.2,
      buitentemperatuur: 18.987037037037,
      kruipruimtetemperatuur: 12.2467592592593,
      windsnelheid: 11.45,
      zonkracht: 5450.61728395062,
      factorAOR: 0,
      maand: 'juli',
    },
    {
      factor: 0.2,
      buitentemperatuur: 19.2388888888889,
      kruipruimtetemperatuur: 12.3097222222222,
      windsnelheid: 10.15,
      zonkracht: 5514.50617283951,
      factorAOR: 0,
      maand: 'juli',
    },
    {
      factor: 0.2,
      buitentemperatuur: 19.6078703703704,
      kruipruimtetemperatuur: 12.4019675925926,
      windsnelheid: 11.5833333333333,
      zonkracht: 5699.07407407407,
      factorAOR: 0,
      maand: 'juli',
    },
    {
      factor: 0.2,
      buitentemperatuur: 19.5893518518518,
      kruipruimtetemperatuur: 12.397337962963,
      windsnelheid: 10.9,
      zonkracht: 6238.58024691358,
      factorAOR: 0,
      maand: 'juli',
    },
    {
      factor: 0.2,
      buitentemperatuur: 19.0384259259259,
      kruipruimtetemperatuur: 12.2596064814815,
      windsnelheid: 10.6,
      zonkracht: 5112.96296296296,
      factorAOR: 0,
      maand: 'juli',
    },
    {
      factor: 0.2,
      buitentemperatuur: 19.2634259259259,
      kruipruimtetemperatuur: 12.3158564814815,
      windsnelheid: 11.2,
      zonkracht: 4882.71604938272,
      factorAOR: 0,
      maand: 'juli',
    },
    {
      factor: 0.2,
      buitentemperatuur: 19.3074074074074,
      kruipruimtetemperatuur: 12.3268518518519,
      windsnelheid: 11.9666666666667,
      zonkracht: 4396.6049382716,
      factorAOR: 0,
      maand: 'juli',
    },
    {
      factor: 0.2,
      buitentemperatuur: 19.9078703703704,
      kruipruimtetemperatuur: 12.4769675925926,
      windsnelheid: 10.3833333333333,
      zonkracht: 5008.64197530864,
      factorAOR: 0,
      maand: 'juli',
    },
    {
      factor: 0.2,
      buitentemperatuur: 20.3231481481481,
      kruipruimtetemperatuur: 12.580787037037,
      windsnelheid: 12.6333333333333,
      zonkracht: 4817.59259259259,
      factorAOR: 0,
      maand: 'juli',
    },
    {
      factor: 0.2,
      buitentemperatuur: 19.3518518518519,
      kruipruimtetemperatuur: 12.337962962963,
      windsnelheid: 15.6333333333333,
      zonkracht: 4358.33333333333,
      factorAOR: 0,
      maand: 'juli',
    },
    {
      factor: 0.2,
      buitentemperatuur: 18.9569444444444,
      kruipruimtetemperatuur: 12.2392361111111,
      windsnelheid: 17.15,
      zonkracht: 5173.45679012346,
      factorAOR: 0,
      maand: 'juli',
    },
    {
      factor: 0.2,
      buitentemperatuur: 18.3939814814815,
      kruipruimtetemperatuur: 12.0984953703704,
      windsnelheid: 15.7166666666667,
      zonkracht: 4851.54320987654,
      factorAOR: 0,
      maand: 'juli',
    },
    {
      factor: 0.2,
      buitentemperatuur: 17.9662037037037,
      kruipruimtetemperatuur: 11.9915509259259,
      windsnelheid: 12.35,
      zonkracht: 5330.24691358025,
      factorAOR: 0,
      maand: 'juli',
    },
    {
      factor: 0.3,
      buitentemperatuur: 18.6666666666667,
      kruipruimtetemperatuur: 12.1666666666667,
      windsnelheid: 8.41666666666667,
      zonkracht: 5282.40740740741,
      factorAOR: 0,
      maand: 'augustus',
    },
    {
      factor: 0.3,
      buitentemperatuur: 19.5189814814815,
      kruipruimtetemperatuur: 12.3797453703704,
      windsnelheid: 10.05,
      zonkracht: 5567.28395061728,
      factorAOR: 0,
      maand: 'augustus',
    },
    {
      factor: 0.3,
      buitentemperatuur: 18.6263888888889,
      kruipruimtetemperatuur: 12.1565972222222,
      windsnelheid: 12.85,
      zonkracht: 5562.03703703704,
      factorAOR: 0,
      maand: 'augustus',
    },
    {
      factor: 0.3,
      buitentemperatuur: 17.8361111111111,
      kruipruimtetemperatuur: 11.9590277777778,
      windsnelheid: 12.3666666666667,
      zonkracht: 4214.81481481481,
      factorAOR: 0,
      maand: 'augustus',
    },
    {
      factor: 0.3,
      buitentemperatuur: 17.8810185185185,
      kruipruimtetemperatuur: 11.9702546296296,
      windsnelheid: 10.5166666666667,
      zonkracht: 5455.55555555556,
      factorAOR: 0,
      maand: 'augustus',
    },
    {
      factor: 0.3,
      buitentemperatuur: 18.3212962962963,
      kruipruimtetemperatuur: 12.0803240740741,
      windsnelheid: 12.05,
      zonkracht: 5235.18518518519,
      factorAOR: 0,
      maand: 'augustus',
    },
    {
      factor: 0.3,
      buitentemperatuur: 18.4861111111111,
      kruipruimtetemperatuur: 12.1215277777778,
      windsnelheid: 10.2166666666667,
      zonkracht: 5082.40740740741,
      factorAOR: 0,
      maand: 'augustus',
    },
    {
      factor: 0.3,
      buitentemperatuur: 18.6064814814815,
      kruipruimtetemperatuur: 12.1516203703704,
      windsnelheid: 13.6833333333333,
      zonkracht: 4663.88888888889,
      factorAOR: 0,
      maand: 'augustus',
    },
    {
      factor: 0.3,
      buitentemperatuur: 18.1361111111111,
      kruipruimtetemperatuur: 12.0340277777778,
      windsnelheid: 13.25,
      zonkracht: 4961.72839506173,
      factorAOR: 0,
      maand: 'augustus',
    },
    {
      factor: 0.3,
      buitentemperatuur: 17.2430555555556,
      kruipruimtetemperatuur: 11.8107638888889,
      windsnelheid: 12.8333333333333,
      zonkracht: 4019.13580246914,
      factorAOR: 0,
      maand: 'augustus',
    },
    {
      factor: 0.3,
      buitentemperatuur: 16.8972222222222,
      kruipruimtetemperatuur: 11.7243055555556,
      windsnelheid: 13.1833333333333,
      zonkracht: 4803.08641975309,
      factorAOR: 0,
      maand: 'augustus',
    },
    {
      factor: 0.3,
      buitentemperatuur: 17.4300925925926,
      kruipruimtetemperatuur: 11.8575231481481,
      windsnelheid: 13.5666666666667,
      zonkracht: 3717.28395061728,
      factorAOR: 0,
      maand: 'augustus',
    },
    {
      factor: 0.3,
      buitentemperatuur: 18.1407407407407,
      kruipruimtetemperatuur: 12.0351851851852,
      windsnelheid: 15.6,
      zonkracht: 4404.62962962963,
      factorAOR: 0,
      maand: 'augustus',
    },
    {
      factor: 0.3,
      buitentemperatuur: 18.0597222222222,
      kruipruimtetemperatuur: 12.0149305555556,
      windsnelheid: 13.9,
      zonkracht: 4435.49382716049,
      factorAOR: 0,
      maand: 'augustus',
    },
    {
      factor: 0.3,
      buitentemperatuur: 17.7847222222222,
      kruipruimtetemperatuur: 11.9461805555556,
      windsnelheid: 11.0166666666667,
      zonkracht: 3865.12345679012,
      factorAOR: 0,
      maand: 'augustus',
    },
    {
      factor: 0.3,
      buitentemperatuur: 16.7513888888889,
      kruipruimtetemperatuur: 11.6878472222222,
      windsnelheid: 12.45,
      zonkracht: 3475.30864197531,
      factorAOR: 0,
      maand: 'augustus',
    },
    {
      factor: 0.3,
      buitentemperatuur: 17.0069444444444,
      kruipruimtetemperatuur: 11.7517361111111,
      windsnelheid: 12.6,
      zonkracht: 3321.60493827161,
      factorAOR: 0,
      maand: 'augustus',
    },
    {
      factor: 0.3,
      buitentemperatuur: 16.4310185185185,
      kruipruimtetemperatuur: 11.6077546296296,
      windsnelheid: 14.4833333333333,
      zonkracht: 3229.62962962963,
      factorAOR: 0,
      maand: 'augustus',
    },
    {
      factor: 0.3,
      buitentemperatuur: 15.9680555555556,
      kruipruimtetemperatuur: 11.4920138888889,
      windsnelheid: 11.8333333333333,
      zonkracht: 3822.22222222222,
      factorAOR: 0,
      maand: 'augustus',
    },
    {
      factor: 0.3,
      buitentemperatuur: 16.6555555555556,
      kruipruimtetemperatuur: 11.6638888888889,
      windsnelheid: 11.2166666666667,
      zonkracht: 4232.0987654321,
      factorAOR: 0,
      maand: 'augustus',
    },
    {
      factor: 0.3,
      buitentemperatuur: 17.3601851851852,
      kruipruimtetemperatuur: 11.8400462962963,
      windsnelheid: 9.81666666666667,
      zonkracht: 4275.30864197531,
      factorAOR: 0,
      maand: 'augustus',
    },
    {
      factor: 0.3,
      buitentemperatuur: 17.6175925925926,
      kruipruimtetemperatuur: 11.9043981481482,
      windsnelheid: 12.8166666666667,
      zonkracht: 4233.95061728395,
      factorAOR: 0,
      maand: 'augustus',
    },
    {
      factor: 0.3,
      buitentemperatuur: 17.1203703703704,
      kruipruimtetemperatuur: 11.7800925925926,
      windsnelheid: 14.9833333333333,
      zonkracht: 4556.17283950617,
      factorAOR: 0,
      maand: 'augustus',
    },
    {
      factor: 0.3,
      buitentemperatuur: 16.5347222222222,
      kruipruimtetemperatuur: 11.6336805555556,
      windsnelheid: 12.4333333333333,
      zonkracht: 4306.79012345679,
      factorAOR: 0,
      maand: 'augustus',
    },
    {
      factor: 0.3,
      buitentemperatuur: 16.2092592592593,
      kruipruimtetemperatuur: 11.5523148148148,
      windsnelheid: 12.6333333333333,
      zonkracht: 4323.45679012346,
      factorAOR: 0,
      maand: 'augustus',
    },
    {
      factor: 0.3,
      buitentemperatuur: 17.0564814814815,
      kruipruimtetemperatuur: 11.7641203703704,
      windsnelheid: 13.1833333333333,
      zonkracht: 4636.72839506173,
      factorAOR: 0,
      maand: 'augustus',
    },
    {
      factor: 0.3,
      buitentemperatuur: 17.0300925925926,
      kruipruimtetemperatuur: 11.7575231481481,
      windsnelheid: 14.1,
      zonkracht: 3931.79012345679,
      factorAOR: 0,
      maand: 'augustus',
    },
    {
      factor: 0.3,
      buitentemperatuur: 17.4347222222222,
      kruipruimtetemperatuur: 11.8586805555556,
      windsnelheid: 10.2,
      zonkracht: 4293.51851851852,
      factorAOR: 0,
      maand: 'augustus',
    },
    {
      factor: 0.3,
      buitentemperatuur: 17.7092592592593,
      kruipruimtetemperatuur: 11.9273148148148,
      windsnelheid: 11.9166666666667,
      zonkracht: 3851.85185185185,
      factorAOR: 0,
      maand: 'augustus',
    },
    {
      factor: 0.3,
      buitentemperatuur: 17.5666666666667,
      kruipruimtetemperatuur: 11.8916666666667,
      windsnelheid: 13.3166666666667,
      zonkracht: 3815.43209876543,
      factorAOR: 0,
      maand: 'augustus',
    },
    {
      factor: 0.3,
      buitentemperatuur: 16.4814814814815,
      kruipruimtetemperatuur: 11.6203703703704,
      windsnelheid: 11.0166666666667,
      zonkracht: 3764.50617283951,
      factorAOR: 0,
      maand: 'augustus',
    },
    {
      factor: 0.6,
      buitentemperatuur: 15.124537037037,
      kruipruimtetemperatuur: 11.2811342592593,
      windsnelheid: 9.96666666666667,
      zonkracht: 3796.60493827161,
      factorAOR: 0.3,
      maand: 'september',
    },
    {
      factor: 0.6,
      buitentemperatuur: 14.8060185185185,
      kruipruimtetemperatuur: 11.2015046296296,
      windsnelheid: 9.23333333333333,
      zonkracht: 3670.06172839506,
      factorAOR: 0.3,
      maand: 'september',
    },
    {
      factor: 0.6,
      buitentemperatuur: 15.7805555555556,
      kruipruimtetemperatuur: 11.4451388888889,
      windsnelheid: 9.25,
      zonkracht: 4034.87654320988,
      factorAOR: 0.3,
      maand: 'september',
    },
    {
      factor: 0.6,
      buitentemperatuur: 16.3208333333333,
      kruipruimtetemperatuur: 11.5802083333333,
      windsnelheid: 11.85,
      zonkracht: 3691.97530864198,
      factorAOR: 0.3,
      maand: 'september',
    },
    {
      factor: 0.6,
      buitentemperatuur: 16.8064814814815,
      kruipruimtetemperatuur: 11.7016203703704,
      windsnelheid: 12.1666666666667,
      zonkracht: 3926.85185185185,
      factorAOR: 0.3,
      maand: 'september',
    },
    {
      factor: 0.6,
      buitentemperatuur: 16.0712962962963,
      kruipruimtetemperatuur: 11.5178240740741,
      windsnelheid: 10.6666666666667,
      zonkracht: 3016.97530864198,
      factorAOR: 0.3,
      maand: 'september',
    },
    {
      factor: 0.6,
      buitentemperatuur: 15.4087962962963,
      kruipruimtetemperatuur: 11.3521990740741,
      windsnelheid: 10.6166666666667,
      zonkracht: 3099.07407407407,
      factorAOR: 0.3,
      maand: 'september',
    },
    {
      factor: 0.6,
      buitentemperatuur: 14.9847222222222,
      kruipruimtetemperatuur: 11.2461805555556,
      windsnelheid: 12.5166666666667,
      zonkracht: 3487.34567901235,
      factorAOR: 0.3,
      maand: 'september',
    },
    {
      factor: 0.6,
      buitentemperatuur: 15.4921296296296,
      kruipruimtetemperatuur: 11.3730324074074,
      windsnelheid: 12.0833333333333,
      zonkracht: 3176.23456790123,
      factorAOR: 0.3,
      maand: 'september',
    },
    {
      factor: 0.6,
      buitentemperatuur: 15.162962962963,
      kruipruimtetemperatuur: 11.2907407407407,
      windsnelheid: 11.9833333333333,
      zonkracht: 2808.95061728395,
      factorAOR: 0.3,
      maand: 'september',
    },
    {
      factor: 0.6,
      buitentemperatuur: 15.1361111111111,
      kruipruimtetemperatuur: 11.2840277777778,
      windsnelheid: 13.3166666666667,
      zonkracht: 3147.83950617284,
      factorAOR: 0.3,
      maand: 'september',
    },
    {
      factor: 0.6,
      buitentemperatuur: 14.4314814814815,
      kruipruimtetemperatuur: 11.1078703703704,
      windsnelheid: 10.25,
      zonkracht: 3151.85185185185,
      factorAOR: 0.3,
      maand: 'september',
    },
    {
      factor: 0.6,
      buitentemperatuur: 14.5759259259259,
      kruipruimtetemperatuur: 11.1439814814815,
      windsnelheid: 11.6,
      zonkracht: 3386.72839506173,
      factorAOR: 0.3,
      maand: 'september',
    },
    {
      factor: 0.6,
      buitentemperatuur: 15.4115740740741,
      kruipruimtetemperatuur: 11.3528935185185,
      windsnelheid: 12.9333333333333,
      zonkracht: 2656.48148148148,
      factorAOR: 0.3,
      maand: 'september',
    },
    {
      factor: 0.6,
      buitentemperatuur: 15.7717592592593,
      kruipruimtetemperatuur: 11.4429398148148,
      windsnelheid: 13.8833333333333,
      zonkracht: 3333.95061728395,
      factorAOR: 0.3,
      maand: 'september',
    },
    {
      factor: 0.6,
      buitentemperatuur: 15.8814814814815,
      kruipruimtetemperatuur: 11.4703703703704,
      windsnelheid: 12.5666666666667,
      zonkracht: 3293.20987654321,
      factorAOR: 0.3,
      maand: 'september',
    },
    {
      factor: 0.6,
      buitentemperatuur: 14.6805555555556,
      kruipruimtetemperatuur: 11.1701388888889,
      windsnelheid: 11.85,
      zonkracht: 3078.08641975309,
      factorAOR: 0.3,
      maand: 'september',
    },
    {
      factor: 0.6,
      buitentemperatuur: 14.5615740740741,
      kruipruimtetemperatuur: 11.1403935185185,
      windsnelheid: 11.0666666666667,
      zonkracht: 3362.03703703704,
      factorAOR: 0.3,
      maand: 'september',
    },
    {
      factor: 0.6,
      buitentemperatuur: 14.0402777777778,
      kruipruimtetemperatuur: 11.0100694444444,
      windsnelheid: 12.4166666666667,
      zonkracht: 3303.39506172839,
      factorAOR: 0.3,
      maand: 'september',
    },
    {
      factor: 0.6,
      buitentemperatuur: 14.0175925925926,
      kruipruimtetemperatuur: 11.0043981481481,
      windsnelheid: 11.7,
      zonkracht: 2722.53086419753,
      factorAOR: 0.3,
      maand: 'september',
    },
    {
      factor: 0.6,
      buitentemperatuur: 13.5768518518519,
      kruipruimtetemperatuur: 10.894212962963,
      windsnelheid: 12.9833333333333,
      zonkracht: 2783.64197530864,
      factorAOR: 0.3,
      maand: 'september',
    },
    {
      factor: 0.6,
      buitentemperatuur: 13.0731481481481,
      kruipruimtetemperatuur: 10.768287037037,
      windsnelheid: 10.5166666666667,
      zonkracht: 3060.49382716049,
      factorAOR: 0.3,
      maand: 'september',
    },
    {
      factor: 0.6,
      buitentemperatuur: 12.499537037037,
      kruipruimtetemperatuur: 10.6248842592593,
      windsnelheid: 8.25,
      zonkracht: 2611.11111111111,
      factorAOR: 0.3,
      maand: 'september',
    },
    {
      factor: 0.6,
      buitentemperatuur: 13.487037037037,
      kruipruimtetemperatuur: 10.8717592592593,
      windsnelheid: 12.4,
      zonkracht: 2673.45679012346,
      factorAOR: 0.3,
      maand: 'september',
    },
    {
      factor: 0.6,
      buitentemperatuur: 14.2268518518519,
      kruipruimtetemperatuur: 11.056712962963,
      windsnelheid: 10.8666666666667,
      zonkracht: 2563.58024691358,
      factorAOR: 0.3,
      maand: 'september',
    },
    {
      factor: 0.6,
      buitentemperatuur: 13.750462962963,
      kruipruimtetemperatuur: 10.9376157407407,
      windsnelheid: 12.1,
      zonkracht: 2543.20987654321,
      factorAOR: 0.3,
      maand: 'september',
    },
    {
      factor: 0.6,
      buitentemperatuur: 13.0324074074074,
      kruipruimtetemperatuur: 10.7581018518519,
      windsnelheid: 9.85,
      zonkracht: 2760.49382716049,
      factorAOR: 0.3,
      maand: 'september',
    },
    {
      factor: 0.6,
      buitentemperatuur: 13.0712962962963,
      kruipruimtetemperatuur: 10.7678240740741,
      windsnelheid: 9.83333333333333,
      zonkracht: 2492.59259259259,
      factorAOR: 0.3,
      maand: 'september',
    },
    {
      factor: 0.6,
      buitentemperatuur: 13.3013888888889,
      kruipruimtetemperatuur: 10.8253472222222,
      windsnelheid: 13.9,
      zonkracht: 2266.35802469136,
      factorAOR: 0.3,
      maand: 'september',
    },
    {
      factor: 0.6,
      buitentemperatuur: 12.7425925925926,
      kruipruimtetemperatuur: 10.6856481481481,
      windsnelheid: 14.95,
      zonkracht: 2220.98765432099,
      factorAOR: 0.3,
      maand: 'september',
    },
    {
      factor: 0.8,
      buitentemperatuur: 12.4398148148148,
      kruipruimtetemperatuur: 10.6099537037037,
      windsnelheid: 14.7333333333333,
      zonkracht: 2600,
      factorAOR: 0.5,
      maand: 'oktober',
    },
    {
      factor: 0.8,
      buitentemperatuur: 12.7157407407407,
      kruipruimtetemperatuur: 10.6789351851852,
      windsnelheid: 14.65,
      zonkracht: 1894.13580246914,
      factorAOR: 0.5,
      maand: 'oktober',
    },
    {
      factor: 0.8,
      buitentemperatuur: 12.2569444444444,
      kruipruimtetemperatuur: 10.5642361111111,
      windsnelheid: 14.05,
      zonkracht: 2310.49382716049,
      factorAOR: 0.5,
      maand: 'oktober',
    },
    {
      factor: 0.8,
      buitentemperatuur: 13.3712962962963,
      kruipruimtetemperatuur: 10.8428240740741,
      windsnelheid: 15.0166666666667,
      zonkracht: 2052.16049382716,
      factorAOR: 0.5,
      maand: 'oktober',
    },
    {
      factor: 0.8,
      buitentemperatuur: 12.5560185185185,
      kruipruimtetemperatuur: 10.6390046296296,
      windsnelheid: 15.0166666666667,
      zonkracht: 2324.07407407407,
      factorAOR: 0.5,
      maand: 'oktober',
    },
    {
      factor: 0.8,
      buitentemperatuur: 11.7513888888889,
      kruipruimtetemperatuur: 10.4378472222222,
      windsnelheid: 12.3833333333333,
      zonkracht: 2024.38271604938,
      factorAOR: 0.5,
      maand: 'oktober',
    },
    {
      factor: 0.8,
      buitentemperatuur: 11.0532407407407,
      kruipruimtetemperatuur: 10.2633101851852,
      windsnelheid: 13.35,
      zonkracht: 1640.12345679012,
      factorAOR: 0.5,
      maand: 'oktober',
    },
    {
      factor: 0.8,
      buitentemperatuur: 11.474537037037,
      kruipruimtetemperatuur: 10.3686342592593,
      windsnelheid: 11.1833333333333,
      zonkracht: 1969.13580246914,
      factorAOR: 0.5,
      maand: 'oktober',
    },
    {
      factor: 0.8,
      buitentemperatuur: 11.2439814814815,
      kruipruimtetemperatuur: 10.3109953703704,
      windsnelheid: 13.3166666666667,
      zonkracht: 1723.14814814815,
      factorAOR: 0.5,
      maand: 'oktober',
    },
    {
      factor: 0.8,
      buitentemperatuur: 10.3564814814815,
      kruipruimtetemperatuur: 10.0891203703704,
      windsnelheid: 11.65,
      zonkracht: 2335.18518518519,
      factorAOR: 0.5,
      maand: 'oktober',
    },
    {
      factor: 0.8,
      buitentemperatuur: 10.8652777777778,
      kruipruimtetemperatuur: 10.2163194444444,
      windsnelheid: 15.0333333333333,
      zonkracht: 1819.13580246914,
      factorAOR: 0.5,
      maand: 'oktober',
    },
    {
      factor: 0.8,
      buitentemperatuur: 10.5375,
      kruipruimtetemperatuur: 10.134375,
      windsnelheid: 13.7333333333333,
      zonkracht: 1717.59259259259,
      factorAOR: 0.5,
      maand: 'oktober',
    },
    {
      factor: 0.8,
      buitentemperatuur: 11.6069444444444,
      kruipruimtetemperatuur: 10.4017361111111,
      windsnelheid: 14.8166666666667,
      zonkracht: 1881.79012345679,
      factorAOR: 0.5,
      maand: 'oktober',
    },
    {
      factor: 0.8,
      buitentemperatuur: 11.8666666666667,
      kruipruimtetemperatuur: 10.4666666666667,
      windsnelheid: 16.75,
      zonkracht: 1326.23456790123,
      factorAOR: 0.5,
      maand: 'oktober',
    },
    {
      factor: 0.8,
      buitentemperatuur: 11.3740740740741,
      kruipruimtetemperatuur: 10.3435185185185,
      windsnelheid: 12.8833333333333,
      zonkracht: 1633.33333333333,
      factorAOR: 0.5,
      maand: 'oktober',
    },
    {
      factor: 0.8,
      buitentemperatuur: 12.2319444444444,
      kruipruimtetemperatuur: 10.5579861111111,
      windsnelheid: 10.95,
      zonkracht: 1328.7037037037,
      factorAOR: 0.5,
      maand: 'oktober',
    },
    {
      factor: 0.8,
      buitentemperatuur: 11.4958333333333,
      kruipruimtetemperatuur: 10.3739583333333,
      windsnelheid: 12.0166666666667,
      zonkracht: 1483.64197530864,
      factorAOR: 0.5,
      maand: 'oktober',
    },
    {
      factor: 0.8,
      buitentemperatuur: 11.6259259259259,
      kruipruimtetemperatuur: 10.4064814814815,
      windsnelheid: 9.23333333333333,
      zonkracht: 1653.7037037037,
      factorAOR: 0.5,
      maand: 'oktober',
    },
    {
      factor: 0.8,
      buitentemperatuur: 11.8388888888889,
      kruipruimtetemperatuur: 10.4597222222222,
      windsnelheid: 12.75,
      zonkracht: 1406.48148148148,
      factorAOR: 0.5,
      maand: 'oktober',
    },
    {
      factor: 0.8,
      buitentemperatuur: 11.7986111111111,
      kruipruimtetemperatuur: 10.4496527777778,
      windsnelheid: 14.5833333333333,
      zonkracht: 1386.72839506173,
      factorAOR: 0.5,
      maand: 'oktober',
    },
    {
      factor: 0.8,
      buitentemperatuur: 11.1162037037037,
      kruipruimtetemperatuur: 10.2790509259259,
      windsnelheid: 17.1166666666667,
      zonkracht: 1019.44444444444,
      factorAOR: 0.5,
      maand: 'oktober',
    },
    {
      factor: 0.8,
      buitentemperatuur: 11.3222222222222,
      kruipruimtetemperatuur: 10.3305555555556,
      windsnelheid: 17.4,
      zonkracht: 1263.58024691358,
      factorAOR: 0.5,
      maand: 'oktober',
    },
    {
      factor: 0.8,
      buitentemperatuur: 11.149537037037,
      kruipruimtetemperatuur: 10.2873842592593,
      windsnelheid: 15.4,
      zonkracht: 1199.07407407407,
      factorAOR: 0.5,
      maand: 'oktober',
    },
    {
      factor: 0.8,
      buitentemperatuur: 10.7902777777778,
      kruipruimtetemperatuur: 10.1975694444444,
      windsnelheid: 14.0166666666667,
      zonkracht: 1265.74074074074,
      factorAOR: 0.5,
      maand: 'oktober',
    },
    {
      factor: 0.8,
      buitentemperatuur: 10.8953703703704,
      kruipruimtetemperatuur: 10.2238425925926,
      windsnelheid: 14.4166666666667,
      zonkracht: 1022.22222222222,
      factorAOR: 0.5,
      maand: 'oktober',
    },
    {
      factor: 0.8,
      buitentemperatuur: 10.7805555555556,
      kruipruimtetemperatuur: 10.1951388888889,
      windsnelheid: 15.2166666666667,
      zonkracht: 1285.49382716049,
      factorAOR: 0.5,
      maand: 'oktober',
    },
    {
      factor: 0.8,
      buitentemperatuur: 10.5638888888889,
      kruipruimtetemperatuur: 10.1409722222222,
      windsnelheid: 16.65,
      zonkracht: 1197.53086419753,
      factorAOR: 0.5,
      maand: 'oktober',
    },
    {
      factor: 0.8,
      buitentemperatuur: 10.2087962962963,
      kruipruimtetemperatuur: 10.0521990740741,
      windsnelheid: 19.95,
      zonkracht: 1305.24691358025,
      factorAOR: 0.5,
      maand: 'oktober',
    },
    {
      factor: 0.8,
      buitentemperatuur: 10.450462962963,
      kruipruimtetemperatuur: 10.1126157407407,
      windsnelheid: 20.3833333333333,
      zonkracht: 1089.50617283951,
      factorAOR: 0.5,
      maand: 'oktober',
    },
    {
      factor: 0.8,
      buitentemperatuur: 9.05092592592593,
      kruipruimtetemperatuur: 9.76273148148148,
      windsnelheid: 14.65,
      zonkracht: 1224.69135802469,
      factorAOR: 0.5,
      maand: 'oktober',
    },
    {
      factor: 0.8,
      buitentemperatuur: 10.2203703703704,
      kruipruimtetemperatuur: 10.0550925925926,
      windsnelheid: 14.7,
      zonkracht: 1213.88888888889,
      factorAOR: 0.5,
      maand: 'oktober',
    },
    {
      factor: 0.9,
      buitentemperatuur: 10.2300925925926,
      kruipruimtetemperatuur: 10.0575231481481,
      windsnelheid: 13.2833333333333,
      zonkracht: 1222.22222222222,
      factorAOR: 0.7,
      maand: 'november',
    },
    {
      factor: 0.9,
      buitentemperatuur: 8.91388888888889,
      kruipruimtetemperatuur: 9.72847222222222,
      windsnelheid: 12.8166666666667,
      zonkracht: 1159.25925925926,
      factorAOR: 0.7,
      maand: 'november',
    },
    {
      factor: 0.9,
      buitentemperatuur: 8.22037037037037,
      kruipruimtetemperatuur: 9.55509259259259,
      windsnelheid: 15.5,
      zonkracht: 1275.30864197531,
      factorAOR: 0.7,
      maand: 'november',
    },
    {
      factor: 0.9,
      buitentemperatuur: 7.63472222222222,
      kruipruimtetemperatuur: 9.40868055555556,
      windsnelheid: 11.7166666666667,
      zonkracht: 1234.87654320988,
      factorAOR: 0.7,
      maand: 'november',
    },
    {
      factor: 0.9,
      buitentemperatuur: 8.51064814814815,
      kruipruimtetemperatuur: 9.62766203703704,
      windsnelheid: 13.15,
      zonkracht: 963.888888888889,
      factorAOR: 0.7,
      maand: 'november',
    },
    {
      factor: 0.9,
      buitentemperatuur: 8.65925925925926,
      kruipruimtetemperatuur: 9.66481481481481,
      windsnelheid: 12.9333333333333,
      zonkracht: 1021.60493827161,
      factorAOR: 0.7,
      maand: 'november',
    },
    {
      factor: 0.9,
      buitentemperatuur: 9.09074074074074,
      kruipruimtetemperatuur: 9.77268518518519,
      windsnelheid: 14.4166666666667,
      zonkracht: 1066.97530864198,
      factorAOR: 0.7,
      maand: 'november',
    },
    {
      factor: 0.9,
      buitentemperatuur: 7.8337962962963,
      kruipruimtetemperatuur: 9.45844907407407,
      windsnelheid: 11.5333333333333,
      zonkracht: 1053.7037037037,
      factorAOR: 0.7,
      maand: 'november',
    },
    {
      factor: 0.9,
      buitentemperatuur: 7.55972222222222,
      kruipruimtetemperatuur: 9.38993055555556,
      windsnelheid: 13.6333333333333,
      zonkracht: 1084.87654320988,
      factorAOR: 0.7,
      maand: 'november',
    },
    {
      factor: 0.9,
      buitentemperatuur: 8.27222222222222,
      kruipruimtetemperatuur: 9.56805555555555,
      windsnelheid: 15.5666666666667,
      zonkracht: 706.172839506173,
      factorAOR: 0.7,
      maand: 'november',
    },
    {
      factor: 0.9,
      buitentemperatuur: 7.27453703703704,
      kruipruimtetemperatuur: 9.31863425925926,
      windsnelheid: 13.6166666666667,
      zonkracht: 854.320987654321,
      factorAOR: 0.7,
      maand: 'november',
    },
    {
      factor: 0.9,
      buitentemperatuur: 7.28935185185185,
      kruipruimtetemperatuur: 9.32233796296296,
      windsnelheid: 12.6166666666667,
      zonkracht: 750.308641975309,
      factorAOR: 0.7,
      maand: 'november',
    },
    {
      factor: 0.9,
      buitentemperatuur: 7.02962962962963,
      kruipruimtetemperatuur: 9.25740740740741,
      windsnelheid: 14.3833333333333,
      zonkracht: 1144.44444444444,
      factorAOR: 0.7,
      maand: 'november',
    },
    {
      factor: 0.9,
      buitentemperatuur: 6.89212962962963,
      kruipruimtetemperatuur: 9.22303240740741,
      windsnelheid: 14.8,
      zonkracht: 595.987654320988,
      factorAOR: 0.7,
      maand: 'november',
    },
    {
      factor: 0.9,
      buitentemperatuur: 7.41111111111111,
      kruipruimtetemperatuur: 9.35277777777778,
      windsnelheid: 14.5333333333333,
      zonkracht: 570.987654320988,
      factorAOR: 0.7,
      maand: 'november',
    },
    {
      factor: 0.9,
      buitentemperatuur: 7.78287037037037,
      kruipruimtetemperatuur: 9.44571759259259,
      windsnelheid: 15.1166666666667,
      zonkracht: 509.567901234568,
      factorAOR: 0.7,
      maand: 'november',
    },
    {
      factor: 0.9,
      buitentemperatuur: 7.50324074074074,
      kruipruimtetemperatuur: 9.37581018518519,
      windsnelheid: 15.3,
      zonkracht: 864.814814814815,
      factorAOR: 0.7,
      maand: 'november',
    },
    {
      factor: 0.9,
      buitentemperatuur: 7.39027777777778,
      kruipruimtetemperatuur: 9.34756944444444,
      windsnelheid: 20.0166666666667,
      zonkracht: 612.962962962963,
      factorAOR: 0.7,
      maand: 'november',
    },
    {
      factor: 0.9,
      buitentemperatuur: 7.47453703703704,
      kruipruimtetemperatuur: 9.36863425925926,
      windsnelheid: 19.05,
      zonkracht: 726.543209876543,
      factorAOR: 0.7,
      maand: 'november',
    },
    {
      factor: 0.9,
      buitentemperatuur: 5.37037037037037,
      kruipruimtetemperatuur: 8.84259259259259,
      windsnelheid: 15.8666666666667,
      zonkracht: 656.481481481482,
      factorAOR: 0.7,
      maand: 'november',
    },
    {
      factor: 0.9,
      buitentemperatuur: 5.36712962962963,
      kruipruimtetemperatuur: 8.84178240740741,
      windsnelheid: 16.0166666666667,
      zonkracht: 671.604938271605,
      factorAOR: 0.7,
      maand: 'november',
    },
    {
      factor: 0.9,
      buitentemperatuur: 5.98796296296296,
      kruipruimtetemperatuur: 8.99699074074074,
      windsnelheid: 15.6,
      zonkracht: 643.518518518519,
      factorAOR: 0.7,
      maand: 'november',
    },
    {
      factor: 0.9,
      buitentemperatuur: 6.05740740740741,
      kruipruimtetemperatuur: 9.01435185185185,
      windsnelheid: 12.75,
      zonkracht: 926.234567901235,
      factorAOR: 0.7,
      maand: 'november',
    },
    {
      factor: 0.9,
      buitentemperatuur: 4.88055555555556,
      kruipruimtetemperatuur: 8.72013888888889,
      windsnelheid: 13.5,
      zonkracht: 587.962962962963,
      factorAOR: 0.7,
      maand: 'november',
    },
    {
      factor: 0.9,
      buitentemperatuur: 4.59074074074074,
      kruipruimtetemperatuur: 8.64768518518519,
      windsnelheid: 11.95,
      zonkracht: 686.728395061728,
      factorAOR: 0.7,
      maand: 'november',
    },
    {
      factor: 0.9,
      buitentemperatuur: 4.36435185185185,
      kruipruimtetemperatuur: 8.59108796296296,
      windsnelheid: 14.6833333333333,
      zonkracht: 813.888888888889,
      factorAOR: 0.7,
      maand: 'november',
    },
    {
      factor: 0.9,
      buitentemperatuur: 4.79398148148148,
      kruipruimtetemperatuur: 8.69849537037037,
      windsnelheid: 13.2333333333333,
      zonkracht: 535.185185185185,
      factorAOR: 0.7,
      maand: 'november',
    },
    {
      factor: 0.9,
      buitentemperatuur: 5.06481481481481,
      kruipruimtetemperatuur: 8.7662037037037,
      windsnelheid: 15.15,
      zonkracht: 529.012345679012,
      factorAOR: 0.7,
      maand: 'november',
    },
    {
      factor: 0.9,
      buitentemperatuur: 4.59675925925926,
      kruipruimtetemperatuur: 8.64918981481481,
      windsnelheid: 16.25,
      zonkracht: 450.617283950617,
      factorAOR: 0.7,
      maand: 'november',
    },
    {
      factor: 0.9,
      buitentemperatuur: 4.18240740740741,
      kruipruimtetemperatuur: 8.54560185185185,
      windsnelheid: 14.0833333333333,
      zonkracht: 625.617283950617,
      factorAOR: 0.7,
      maand: 'november',
    },
    {
      factor: 0.9,
      buitentemperatuur: 4.77037037037037,
      kruipruimtetemperatuur: 8.69259259259259,
      windsnelheid: 17.8333333333333,
      zonkracht: 475,
      factorAOR: 0.7,
      maand: 'december',
    },
    {
      factor: 0.9,
      buitentemperatuur: 4.9837962962963,
      kruipruimtetemperatuur: 8.74594907407407,
      windsnelheid: 14.2666666666667,
      zonkracht: 493.518518518519,
      factorAOR: 0.7,
      maand: 'december',
    },
    {
      factor: 0.9,
      buitentemperatuur: 4.20046296296296,
      kruipruimtetemperatuur: 8.55011574074074,
      windsnelheid: 14.1833333333333,
      zonkracht: 549.691358024691,
      factorAOR: 0.7,
      maand: 'december',
    },
    {
      factor: 0.9,
      buitentemperatuur: 3.74074074074074,
      kruipruimtetemperatuur: 8.43518518518519,
      windsnelheid: 13.2,
      zonkracht: 545.061728395062,
      factorAOR: 0.7,
      maand: 'december',
    },
    {
      factor: 0.9,
      buitentemperatuur: 3.99861111111111,
      kruipruimtetemperatuur: 8.49965277777778,
      windsnelheid: 17.8333333333333,
      zonkracht: 529.938271604938,
      factorAOR: 0.7,
      maand: 'december',
    },
    {
      factor: 0.9,
      buitentemperatuur: 4.2912037037037,
      kruipruimtetemperatuur: 8.57280092592593,
      windsnelheid: 17.5333333333333,
      zonkracht: 612.037037037037,
      factorAOR: 0.7,
      maand: 'december',
    },
    {
      factor: 0.9,
      buitentemperatuur: 4.32083333333333,
      kruipruimtetemperatuur: 8.58020833333333,
      windsnelheid: 15.5666666666667,
      zonkracht: 456.172839506173,
      factorAOR: 0.7,
      maand: 'december',
    },
    {
      factor: 0.9,
      buitentemperatuur: 5.13842592592593,
      kruipruimtetemperatuur: 8.78460648148148,
      windsnelheid: 20.05,
      zonkracht: 455.246913580247,
      factorAOR: 0.7,
      maand: 'december',
    },
    {
      factor: 0.9,
      buitentemperatuur: 5.11574074074074,
      kruipruimtetemperatuur: 8.77893518518519,
      windsnelheid: 19.1833333333333,
      zonkracht: 437.345679012346,
      factorAOR: 0.7,
      maand: 'december',
    },
    {
      factor: 0.9,
      buitentemperatuur: 5.08888888888889,
      kruipruimtetemperatuur: 8.77222222222222,
      windsnelheid: 19.2,
      zonkracht: 510.802469135803,
      factorAOR: 0.7,
      maand: 'december',
    },
    {
      factor: 0.9,
      buitentemperatuur: 3.80740740740741,
      kruipruimtetemperatuur: 8.45185185185185,
      windsnelheid: 17.6833333333333,
      zonkracht: 450,
      factorAOR: 0.7,
      maand: 'december',
    },
    {
      factor: 0.9,
      buitentemperatuur: 4.7337962962963,
      kruipruimtetemperatuur: 8.68344907407407,
      windsnelheid: 17.5166666666667,
      zonkracht: 425.308641975309,
      factorAOR: 0.7,
      maand: 'december',
    },
    {
      factor: 0.9,
      buitentemperatuur: 4.35046296296296,
      kruipruimtetemperatuur: 8.58761574074074,
      windsnelheid: 14.9333333333333,
      zonkracht: 497.839506172839,
      factorAOR: 0.7,
      maand: 'december',
    },
    {
      factor: 0.9,
      buitentemperatuur: 4.83287037037037,
      kruipruimtetemperatuur: 8.70821759259259,
      windsnelheid: 15.1166666666667,
      zonkracht: 445.37037037037,
      factorAOR: 0.7,
      maand: 'december',
    },
    {
      factor: 0.9,
      buitentemperatuur: 5.38009259259259,
      kruipruimtetemperatuur: 8.84502314814815,
      windsnelheid: 16.0333333333333,
      zonkracht: 396.604938271605,
      factorAOR: 0.7,
      maand: 'december',
    },
    {
      factor: 0.9,
      buitentemperatuur: 5.56064814814815,
      kruipruimtetemperatuur: 8.89016203703704,
      windsnelheid: 14.3833333333333,
      zonkracht: 308.333333333333,
      factorAOR: 0.7,
      maand: 'december',
    },
    {
      factor: 0.9,
      buitentemperatuur: 6.1037037037037,
      kruipruimtetemperatuur: 9.02592592592593,
      windsnelheid: 14.1333333333333,
      zonkracht: 424.382716049383,
      factorAOR: 0.7,
      maand: 'december',
    },
    {
      factor: 0.9,
      buitentemperatuur: 7.10740740740741,
      kruipruimtetemperatuur: 9.27685185185185,
      windsnelheid: 17.7833333333333,
      zonkracht: 344.444444444445,
      factorAOR: 0.7,
      maand: 'december',
    },
    {
      factor: 0.9,
      buitentemperatuur: 7.34861111111111,
      kruipruimtetemperatuur: 9.33715277777778,
      windsnelheid: 17.2166666666667,
      zonkracht: 370.987654320988,
      factorAOR: 0.7,
      maand: 'december',
    },
    {
      factor: 0.9,
      buitentemperatuur: 6.75972222222222,
      kruipruimtetemperatuur: 9.18993055555556,
      windsnelheid: 16.3,
      zonkracht: 487.962962962963,
      factorAOR: 0.7,
      maand: 'december',
    },
    {
      factor: 0.9,
      buitentemperatuur: 5.56990740740741,
      kruipruimtetemperatuur: 8.89247685185185,
      windsnelheid: 19.3,
      zonkracht: 414.197530864198,
      factorAOR: 0.7,
      maand: 'december',
    },
    {
      factor: 0.9,
      buitentemperatuur: 6.49768518518519,
      kruipruimtetemperatuur: 9.1244212962963,
      windsnelheid: 20.9666666666667,
      zonkracht: 356.172839506173,
      factorAOR: 0.7,
      maand: 'december',
    },
    {
      factor: 0.9,
      buitentemperatuur: 7.38842592592593,
      kruipruimtetemperatuur: 9.34710648148148,
      windsnelheid: 23.65,
      zonkracht: 321.296296296296,
      factorAOR: 0.7,
      maand: 'december',
    },
    {
      factor: 0.9,
      buitentemperatuur: 7.02731481481482,
      kruipruimtetemperatuur: 9.2568287037037,
      windsnelheid: 22.1166666666667,
      zonkracht: 333.333333333333,
      factorAOR: 0.7,
      maand: 'december',
    },
    {
      factor: 0.9,
      buitentemperatuur: 5.15601851851852,
      kruipruimtetemperatuur: 8.78900462962963,
      windsnelheid: 19.0666666666667,
      zonkracht: 515.740740740741,
      factorAOR: 0.7,
      maand: 'december',
    },
    {
      factor: 0.9,
      buitentemperatuur: 4.80416666666667,
      kruipruimtetemperatuur: 8.70104166666667,
      windsnelheid: 19.5,
      zonkracht: 503.703703703704,
      factorAOR: 0.7,
      maand: 'december',
    },
    {
      factor: 0.9,
      buitentemperatuur: 5.1462962962963,
      kruipruimtetemperatuur: 8.78657407407407,
      windsnelheid: 20.9833333333333,
      zonkracht: 288.58024691358,
      factorAOR: 0.7,
      maand: 'december',
    },
    {
      factor: 0.9,
      buitentemperatuur: 5.3087962962963,
      kruipruimtetemperatuur: 8.82719907407407,
      windsnelheid: 15.55,
      zonkracht: 476.234567901235,
      factorAOR: 0.7,
      maand: 'december',
    },
    {
      factor: 0.9,
      buitentemperatuur: 5.09537037037037,
      kruipruimtetemperatuur: 8.77384259259259,
      windsnelheid: 14.1,
      zonkracht: 516.049382716049,
      factorAOR: 0.7,
      maand: 'december',
    },
    {
      factor: 0.9,
      buitentemperatuur: 5.30185185185185,
      kruipruimtetemperatuur: 8.82546296296296,
      windsnelheid: 17.0833333333333,
      zonkracht: 491.666666666667,
      factorAOR: 0.7,
      maand: 'december',
    },
    {
      factor: 0.9,
      buitentemperatuur: 5.6037037037037,
      kruipruimtetemperatuur: 8.90092592592593,
      windsnelheid: 18.1833333333333,
      zonkracht: 500,
      factorAOR: 0.7,
      maand: 'december',
    },
  ]

  const klimaatgegevens_sum_zonkracht = 1036917.5925925921

  const zoninstraling = {
    noord: {
      januari: 0.396428571,
      februari: 0.395537525,
      maart: 0.360248447,
      april: 0.30471028,
      mei: 0.304786802,
      juni: 0.338318204,
      juli: 0.33873822,
      augustus: 0.309153499,
      september: 0.334140436,
      oktober: 0.360655738,
      november: 0.396501458,
      december: 0.423809524,
    },
    noordoost: {
      januari: 0.396428571,
      februari: 0.418661258,
      maart: 0.443830228,
      april: 0.440579439,
      mei: 0.39135533,
      juni: 0.386813187,
      juli: 0.38686911,
      augustus: 0.353397291,
      september: 0.38283293,
      oktober: 0.381639344,
      november: 0.4,
      december: 0.423809524,
    },
    oost: {
      januari: 0.663714286,
      februari: 0.584888438,
      maart: 0.058550725,
      april: 0.636149533,
      mei: 0.552639594,
      juni: 0.493645485,
      juli: 0.510769634,
      augustus: 0.472121896,
      september: 0.517506053,
      oktober: 0.57147541,
      november: 0.641049563,
      december: 0.651142857,
    },
    zuidoost: {
      januari: 0.752571429,
      februari: 0.933225152,
      maart: 0.859192547,
      april: 0.803800623,
      mei: 0.65177665,
      juni: 0.563784042,
      juli: 0.586743455,
      augustus: 0.621625282,
      september: 0.769265537,
      oktober: 0.943415301,
      november: 0.96122449,
      december: 0.681904762,
    },
    zuid: {
      januari: 0.493678571,
      februari: 1.231176471,
      maart: 1.053830228,
      april: 0.841744548,
      mei: 0.634010152,
      juni: 0.538461538,
      juli: 0.57434555,
      augustus: 0.7251693,
      september: 0.98708636,
      oktober: 1.274781421,
      november: 1.058163265,
      december: 0.418,
    },
    zuidwest: {
      januari: 0.84175,
      februari: 0.878823529,
      maart: 0.790403727,
      april: 0.698741433,
      mei: 0.613598985,
      juni: 0.610606785,
      juli: 0.613089005,
      augustus: 0.765965011,
      september: 0.824067797,
      oktober: 0.91726776,
      november: 0.943906706,
      december: 0.963904762,
    },
    west: {
      januari: 0.710357143,
      februari: 0.565517241,
      maart: 0.527919255,
      april: 0.491489097,
      mei: 0.479309645,
      juni: 0.558533206,
      juli: 0.542848168,
      augustus: 0.602708804,
      september: 0.575585149,
      oktober: 0.529521858,
      november: 0.595626822,
      december: 0.629714286,
    },
    noordwest: {
      januari: 0.394928571,
      februari: 0.411217039,
      maart: 0.382608696,
      april: 0.347457944,
      mei: 0.340431472,
      juni: 0.425217391,
      juli: 0.417015707,
      augustus: 0.41268623,
      september: 0.415302663,
      oktober: 0.380314208,
      november: 0.398309038,
      december: 0.423809524,
    },
    boven: {
      januari: 1.0,
      februari: 1.0,
      maart: 1.0,
      april: 1.0,
      mei: 1.0,
      juni: 1.0,
      juli: 1.0,
      augustus: 1.0,
      september: 1.0,
      oktober: 1.0,
      november: 1.0,
      december: 1.0,
    },
    onder: {
      januari: 0.0,
      februari: 0.0,
      maart: 0.0,
      april: 0.0,
      mei: 0.0,
      juni: 0.0,
      juli: 0.0,
      augustus: 0.0,
      september: 0.0,
      oktober: 0.0,
      november: 0.0,
      december: 0.0,
    },
    grond: {
      januari: 0.0,
      februari: 0.0,
      maart: 0.0,
      april: 0.0,
      mei: 0.0,
      juni: 0.0,
      juli: 0.0,
      augustus: 0.0,
      september: 0.0,
      oktober: 0.0,
      november: 0.0,
      december: 0.0,
    },
    kruipruimte: {
      januari: 0.0,
      februari: 0.0,
      maart: 0.0,
      april: 0.0,
      mei: 0.0,
      juni: 0.0,
      juli: 0.0,
      augustus: 0.0,
      september: 0.0,
      oktober: 0.0,
      november: 0.0,
      december: 0.0,
    },
    'aangrenzende onverwarmde ruimte': {
      januari: 0.0,
      februari: 0.0,
      maart: 0.0,
      april: 0.0,
      mei: 0.0,
      juni: 0.0,
      juli: 0.0,
      augustus: 0.0,
      september: 0.0,
      oktober: 0.0,
      november: 0.0,
      december: 0.0,
    },
  }

  const woningtype_ventilatie = {
    Vrijstaand: {
      '< 1945': { Ja: 1.044, Nee: 1.16 },
      '1945 - 1970': { Ja: 1.044, Nee: 1.16 },
      '1970 - 1980': { Ja: 0.936, Nee: 1.04 },
      '1980 - 1990': { Ja: 0.729, Nee: 0.81 },
      '1990 - 2000': { Ja: 0.69, Nee: 0.69 },
      '2000 - 2010': { Ja: 0.58, Nee: 0.58 },
      '> 2010': { Ja: 0.46, Nee: 0.46 },
    },
    '2/1 kap/hoekwoning': {
      '< 1945': { Ja: 0.9, Nee: 1.0 },
      '1945 - 1970': { Ja: 0.9, Nee: 1.0 },
      '1970 - 1980': { Ja: 0.81, Nee: 0.9 },
      '1980 - 1990': { Ja: 0.63, Nee: 0.7 },
      '1990 - 2000': { Ja: 0.6, Nee: 0.6 },
      '2000 - 2010': { Ja: 0.5, Nee: 0.5 },
      '> 2010': { Ja: 0.4, Nee: 0.4 },
    },
    Tussenwoning: {
      '< 1945': { Ja: 0.756, Nee: 0.84 },
      '1945 - 1970': { Ja: 0.756, Nee: 0.84 },
      '1970 - 1980': { Ja: 0.684, Nee: 0.76 },
      '1980 - 1990': { Ja: 0.531, Nee: 0.59 },
      '1990 - 2000': { Ja: 0.51, Nee: 0.51 },
      '2000 - 2010': { Ja: 0.42, Nee: 0.42 },
      '> 2010': { Ja: 0.34, Nee: 0.34 },
    },
    Appartement: {
      '< 1945': { Ja: 0.639, Nee: 0.71 },
      '1945 - 1970': { Ja: 0.639, Nee: 0.71 },
      '1970 - 1980': { Ja: 0.576, Nee: 0.64 },
      '1980 - 1990': { Ja: 0.45, Nee: 0.5 },
      '1990 - 2000': { Ja: 0.43, Nee: 0.43 },
      '2000 - 2010': { Ja: 0.36, Nee: 0.36 },
      '> 2010': { Ja: 0.28, Nee: 0.28 },
    },
  }
  
  const winddruk_coefficient = {
    Vrijstaand: {
      'Vrije veld': 0.7,
      'Buiten de bebouwde kom, beschut': 0.6,
      'Dorp': 0.5,
      'Stad': 0.4,
    },
    '2/1 kap/hoekwoning': {
      'Vrije veld': 0.7,
      'Buiten de bebouwde kom, beschut': 0.6,
      'Dorp': 0.5,
      'Stad': 0.4,
    },
    Tussenwoning: {
      'Vrije veld': 0.7,
      'Buiten de bebouwde kom, beschut': 0.6,
      'Dorp': 0.5,
      'Stad': 0.4,
    },
    Appartement: {
      'Vrije veld': 0.7,
      'Buiten de bebouwde kom, beschut': 0.6,
      'Dorp': 0.5,
      'Stad': 0.4,
    }
  }

  const streefwaarden_woningisolatie_type_mapper = {
    Vrijstaand: 'Eengezinswoningen',
    '2/1 kap/hoekwoning': 'Eengezinswoningen',
    Tussenwoning: 'Eengezinswoningen',
    Appartement: 'Meergezinswoningen'
  }

  const streefwaarden_woningisolatie_era_mapper = {
    '< 1945': 't/m 1945',
    '1945 - 1970': 'na 1945',
    '1970 - 1980': 'na 1945',
    '1980 - 1990': 'na 1945',
    '1990 - 2000': 'na 1945',
    '2000 - 2010': 'na 1945',
    '> 2010': 'na 1945',
  }

  // const streefwaarden_woningisolatie_warmtevraag_mapper = {
  //   'Eengezinswoningen': {
  //     't/m 1945': 60,
  //     'na 1945': 43
  //   },
  //   'Meergezinswoningen': {
  //     't/m 1945': 95,
  //     'na 1945': 45
  //   }
  // }

  const ventilatie = {
    'Geen (alleen mechanisch)': 0.0,
    'Te openen ramen': 0.8,
    Ventilatieroosters: 0.65,
  }

  const ventilatie_verbruik = {
    mechanisch: 15,
    WTW: 30,
  }

  // ja/nee is hier opwekking binnen de thermische schil ja/nee
  const rendement_distributiesysteem = {
    'Ja, ongeïsoleerde leidingen': {
      Ja: 0.9,
      Nee: 0.85,
    },
    'Ja, geïsoleerd leidingen': {
      Ja: 0.97,
      Nee: 0.9,
    },
    'Geen leidingen buiten de thermische schil': {
      Ja: 1,
      Nee: 0.95,
    },
  }

  const vermogensberekening = {
    'Temp binnen': 20,
    'Temp buiten': -10,
    'verschil met buitenlucht': 30,
    'verschil met grond': 15,
    'verschil met kruipruimte': 18,
    'verschil met AOR': 18,
  }

  const warm_tapwater_behoefte = {
    1: 680,
    2: 1360,
    3: 2040,
    4: 2720,
    5: 3400,
    6: 4080,
  }

  const correctiefactor_verbruikprofiel = {
    Hoog: 1.25,
    Gemiddeld: 1,
    Laag: 0.8,
  }

  const zonneboiler_tapwater = {
    Geen: 1,
    'Zonneboiler 120l vat': 0.5,
    'Zonneboiler 200l vat': 0.5,
    'Zonneboiler 300l vat': 0.5,
    'Zonneboiler 400l vat': 0.5,
  }

  const verlies_voorraadvat = {
    Geen: 0,
    'Zonneboiler 120l vat': 120,
    'Zonneboiler 200l vat': 130,
    'Zonneboiler 300l vat': 150,
    'Zonneboiler 400l vat': 160,
  }

  const installaties = {
    "Elektrische boiler": {
        "aandeel_elektrisch": "",
        "aandeel_gas": "",
        "aandeel_hout": "",
        "aanvoertemperatuur": "",
        "elek_tapwaterrendement": 0.95,
        "elek_verwarmingsrendement": "",
        "gas_tapwaterrendement": "",
        "gas_verwarmingsrendement": "",
        "verlies_voorraadvat": 150.0,
        "vermogen": "",
        "aandeel_warmte": "",
        "warm_tapwaterrendement": "",
        "warm_verwarmingsrendement": ""
    },
    "Elektrische doorstroomverwarmer": {
        "aandeel_elektrisch": "",
        "aandeel_gas": "",
        "aandeel_hout": "",
        "aanvoertemperatuur": "",
        "elek_tapwaterrendement": 0.95,
        "elek_verwarmingsrendement": "",
        "gas_tapwaterrendement": "",
        "gas_verwarmingsrendement": "",
        "verlies_voorraadvat": 0.0,
        "vermogen": "",
        "aandeel_warmte": "",
        "warm_tapwaterrendement": "",
        "warm_verwarmingsrendement": ""
    },
    "Elektrische doorstroomverwarmer 11kW": {
        "aandeel_elektrisch": "",
        "aandeel_gas": "",
        "aandeel_hout": "",
        "aanvoertemperatuur": "",
        "elek_tapwaterrendement": 0.95,
        "elek_verwarmingsrendement": "",
        "gas_tapwaterrendement": "",
        "gas_verwarmingsrendement": "",
        "verlies_voorraadvat": 0.0,
        "vermogen": "",
        "aandeel_warmte": "",
        "warm_tapwaterrendement": "",
        "warm_verwarmingsrendement": ""
    },
    "Elektrische doorstroomverwarmer 22kW": {
        "aandeel_elektrisch": "",
        "aandeel_gas": "",
        "aandeel_hout": "",
        "aanvoertemperatuur": "",
        "elek_tapwaterrendement": 0.95,
        "elek_verwarmingsrendement": "",
        "gas_tapwaterrendement": "",
        "gas_verwarmingsrendement": "",
        "verlies_voorraadvat": 0.0,
        "vermogen": "",
        "aandeel_warmte": "",
        "warm_tapwaterrendement": "",
        "warm_verwarmingsrendement": ""
    },
    "Gasboiler": {
        "aandeel_elektrisch": "",
        "aandeel_gas": "",
        "aandeel_hout": "",
        "aanvoertemperatuur": "",
        "elek_tapwaterrendement": "",
        "elek_verwarmingsrendement": "",
        "gas_tapwaterrendement": 0.45,
        "gas_verwarmingsrendement": "",
        "verlies_voorraadvat": 0.0,
        "vermogen": "",
        "aandeel_warmte": "",
        "warm_tapwaterrendement": "",
        "warm_verwarmingsrendement": ""
    },
    "Gaskachels lokaal": {
        "aandeel_elektrisch": "",
        "aandeel_gas": 1.0,
        "aandeel_hout": "",
        "aanvoertemperatuur": "60-90",
        "elek_tapwaterrendement": "",
        "elek_verwarmingsrendement": "",
        "gas_tapwaterrendement": "",
        "gas_verwarmingsrendement": 0.85,
        "verlies_voorraadvat": 0.0,
        "vermogen": "",
        "aandeel_warmte": "",
        "warm_tapwaterrendement": "",
        "warm_verwarmingsrendement": ""
    },
    "Geiser": {
        "aandeel_elektrisch": "",
        "aandeel_gas": "",
        "aandeel_hout": "",
        "aanvoertemperatuur": "",
        "elek_tapwaterrendement": "",
        "elek_verwarmingsrendement": "",
        "gas_tapwaterrendement": 0.40,
        "gas_verwarmingsrendement": "",
        "verlies_voorraadvat": 0.0,
        "vermogen": "",
        "aandeel_warmte": "",
        "warm_tapwaterrendement": "",
        "warm_verwarmingsrendement": ""
    },
    "HR 100 gasketel": {
        "aandeel_elektrisch": "",
        "aandeel_gas": "",
        "aandeel_hout": "",
        "aanvoertemperatuur": "",
        "elek_tapwaterrendement": "",
        "elek_verwarmingsrendement": "",
        "gas_tapwaterrendement": 0.6,
        "gas_verwarmingsrendement": "",
        "verlies_voorraadvat": 0.0,
        "vermogen": "",
        "aandeel_warmte": "",
        "warm_tapwaterrendement": "",
        "warm_verwarmingsrendement": ""
    },
    "HR 100 gasketel HTV (oud)": {
        "aandeel_elektrisch": "",
        "aandeel_gas": 1.0,
        "aandeel_hout": "",
        "aanvoertemperatuur": "60-90",
        "elek_tapwaterrendement": "",
        "elek_verwarmingsrendement": "",
        "gas_tapwaterrendement": 0.6,
        "gas_verwarmingsrendement": 0.9,
        "verlies_voorraadvat": 0.0,
        "vermogen": "",
        "aandeel_warmte": "",
        "warm_tapwaterrendement": "",
        "warm_verwarmingsrendement": ""
    },
    "HR 107 gasketel (>15j)": {
        "aandeel_elektrisch": "",
        "aandeel_gas": "",
        "aandeel_hout": "",
        "aanvoertemperatuur": "",
        "elek_tapwaterrendement": "",
        "elek_verwarmingsrendement": "",
        "gas_tapwaterrendement": 0.675,
        "gas_verwarmingsrendement": "",
        "verlies_voorraadvat": 0.0,
        "vermogen": "",
        "aandeel_warmte": "",
        "warm_tapwaterrendement": "",
        "warm_verwarmingsrendement": ""
    },
    "HR 107 gasketel (recent)": {
        "aandeel_elektrisch": "",
        "aandeel_gas": "",
        "aandeel_hout": "",
        "aanvoertemperatuur": "",
        "elek_tapwaterrendement": "",
        "elek_verwarmingsrendement": "",
        "gas_tapwaterrendement": 0.75,
        "gas_verwarmingsrendement": "",
        "verlies_voorraadvat": 0.0,
        "vermogen": "",
        "aandeel_warmte": "",
        "warm_tapwaterrendement": "",
        "warm_verwarmingsrendement": ""
    },
    "HR 107 gasketel HTV (oud)": {
        "aandeel_elektrisch": "",
        "aandeel_gas": 1.0,
        "aandeel_hout": "",
        "aanvoertemperatuur": "60-90",
        "elek_tapwaterrendement": "",
        "elek_verwarmingsrendement": "",
        "gas_tapwaterrendement": 0.675,
        "gas_verwarmingsrendement": 0.95,
        "verlies_voorraadvat": 0.0,
        "vermogen": "",
        "aandeel_warmte": "",
        "warm_tapwaterrendement": "",
        "warm_verwarmingsrendement": ""
    },
    "HR 107 gasketel HTV (recent)": {
        "aandeel_elektrisch": "",
        "aandeel_gas": 1.0,
        "aandeel_hout": "",
        "aanvoertemperatuur": "60-90",
        "elek_tapwaterrendement": "",
        "elek_verwarmingsrendement": "",
        "gas_tapwaterrendement": 0.75,
        "gas_verwarmingsrendement": 0.95,
        "verlies_voorraadvat": 0.0,
        "vermogen": "",
        "aandeel_warmte": "",
        "warm_tapwaterrendement": "",
        "warm_verwarmingsrendement": ""
    },
    "HR 107 gasketel LTV (recent)": {
        "aandeel_elektrisch": "",
        "aandeel_gas": 1.0,
        "aandeel_hout": "",
        "aanvoertemperatuur": 55.0,
        "elek_tapwaterrendement": "",
        "elek_verwarmingsrendement": "",
        "gas_tapwaterrendement": 0.75,
        "gas_verwarmingsrendement": 0.975,
        "verlies_voorraadvat": 0.0,
        "vermogen": "",
        "aandeel_warmte": "",
        "warm_tapwaterrendement": "",
        "warm_verwarmingsrendement": ""
    },
    "Infraroodpanelen / elektrische radiatoren": {
        "aandeel_elektrisch": 1.0,
        "aandeel_gas": "",
        "aandeel_hout": "",
        "aanvoertemperatuur": "",
        "elek_tapwaterrendement": "",
        "elek_verwarmingsrendement": 0.95,
        "gas_tapwaterrendement": "",
        "gas_verwarmingsrendement": "",
        "verlies_voorraadvat": 0.0,
        "vermogen": "",
        "aandeel_warmte": "",
        "warm_tapwaterrendement": "",
        "warm_verwarmingsrendement": ""
    },
    "VR gasketel": {
        "aandeel_elektrisch": "",
        "aandeel_gas": "",
        "aandeel_hout": "",
        "aanvoertemperatuur": "",
        "elek_tapwaterrendement": "",
        "elek_verwarmingsrendement": "",
        "gas_tapwaterrendement": 0.6,
        "gas_verwarmingsrendement": "",
        "verlies_voorraadvat": 0.0,
        "vermogen": "",
        "aandeel_warmte": "",
        "warm_tapwaterrendement": "",
        "warm_verwarmingsrendement": ""
    },
    "VR gasketel HTV (oud)": {
        "aandeel_elektrisch": "",
        "aandeel_gas": 1.0,
        "aandeel_hout": "",
        "aanvoertemperatuur": "60-90",
        "elek_tapwaterrendement": "",
        "elek_verwarmingsrendement": "",
        "gas_tapwaterrendement": 0.6,
        "gas_verwarmingsrendement": 0.8,
        "verlies_voorraadvat": 0.0,
        "vermogen": "",
        "aandeel_warmte": "",
        "warm_tapwaterrendement": "",
        "warm_verwarmingsrendement": ""
    },
    "Warmtenet": {
        "aandeel_elektrisch": "",
        "aandeel_gas": "",
        "aandeel_hout": "",
        "aanvoertemperatuur": "",
        "elek_tapwaterrendement": "",
        "elek_verwarmingsrendement": "",
        "gas_tapwaterrendement": "",
        "gas_verwarmingsrendement": "",
        "verlies_voorraadvat": 0.0,
        "vermogen": "",
        "aandeel_warmte": 1.0,
        "warm_tapwaterrendement": 1.0,
        "warm_verwarmingsrendement": 1.0
    },
    "Warmtepomp + PVT systeem 35 graden": {
        "aandeel_elektrisch": 1.0,
        "aandeel_gas": "",
        "aandeel_hout": "",
        "aanvoertemperatuur": 35.0,
        "elek_tapwaterrendement": 2.2,
        "elek_verwarmingsrendement": 4.2,
        "gas_tapwaterrendement": "",
        "gas_verwarmingsrendement": "",
        "verlies_voorraadvat": 150.0,
        "vermogen": "",
        "aandeel_warmte": "",
        "warm_tapwaterrendement": "",
        "warm_verwarmingsrendement": ""
    },
    "Warmtepomp + PVT systeem 45 graden": {
        "aandeel_elektrisch": 1.0,
        "aandeel_gas": "",
        "aandeel_hout": "",
        "aanvoertemperatuur": 45.0,
        "elek_tapwaterrendement": 2.2,
        "elek_verwarmingsrendement": 3.7,
        "gas_tapwaterrendement": "",
        "gas_verwarmingsrendement": "",
        "verlies_voorraadvat": 150.0,
        "vermogen": "",
        "aandeel_warmte": "",
        "warm_tapwaterrendement": "",
        "warm_verwarmingsrendement": ""
    },
    "Warmtepomp + PVT systeem 55 graden": {
        "aandeel_elektrisch": 1.0,
        "aandeel_gas": "",
        "aandeel_hout": "",
        "aanvoertemperatuur": 55.0,
        "elek_tapwaterrendement": 2.2,
        "elek_verwarmingsrendement": 3.0,
        "gas_tapwaterrendement": "",
        "gas_verwarmingsrendement": "",
        "verlies_voorraadvat": 150.0,
        "vermogen": "",
        "aandeel_warmte": "",
        "warm_tapwaterrendement": "",
        "warm_verwarmingsrendement": ""
    },
    "Warmtepomp + PVT systeem; 150l boiler": {
        "aandeel_elektrisch": "",
        "aandeel_gas": "",
        "aandeel_hout": "",
        "aanvoertemperatuur": "",
        "elek_tapwaterrendement": 2.2,
        "elek_verwarmingsrendement": "",
        "gas_tapwaterrendement": "",
        "gas_verwarmingsrendement": "",
        "verlies_voorraadvat": 120.0,
        "vermogen": "",
        "aandeel_warmte": "",
        "warm_tapwaterrendement": "",
        "warm_verwarmingsrendement": ""
    },
    "Warmtepomp + PVT systeem; 200l boiler": {
        "aandeel_elektrisch": "",
        "aandeel_gas": "",
        "aandeel_hout": "",
        "aanvoertemperatuur": "",
        "elek_tapwaterrendement": 2.2,
        "elek_verwarmingsrendement": "",
        "gas_tapwaterrendement": "",
        "gas_verwarmingsrendement": "",
        "verlies_voorraadvat": 150.0,
        "vermogen": "",
        "aandeel_warmte": "",
        "warm_tapwaterrendement": "",
        "warm_verwarmingsrendement": ""
    },
    "Warmtepomp + PVT systeem; 300l boiler": {
        "aandeel_elektrisch": "",
        "aandeel_gas": "",
        "aandeel_hout": "",
        "aanvoertemperatuur": "",
        "elek_tapwaterrendement": 2.2,
        "elek_verwarmingsrendement": "",
        "gas_tapwaterrendement": "",
        "gas_verwarmingsrendement": "",
        "verlies_voorraadvat": 160.0,
        "vermogen": "",
        "aandeel_warmte": "",
        "warm_tapwaterrendement": "",
        "warm_verwarmingsrendement": ""
    },
    "Warmtepomp + PVT systeem; 400l boiler": {
        "aandeel_elektrisch": "",
        "aandeel_gas": "",
        "aandeel_hout": "",
        "aanvoertemperatuur": "",
        "elek_tapwaterrendement": 2.2,
        "elek_verwarmingsrendement": "",
        "gas_tapwaterrendement": "",
        "gas_verwarmingsrendement": "",
        "verlies_voorraadvat": 170.0,
        "vermogen": "",
        "aandeel_warmte": "",
        "warm_tapwaterrendement": "",
        "warm_verwarmingsrendement": ""
    },
    "Warmtepomp + buitenlucht  45 graden": {
        "aandeel_elektrisch": 1.0,
        "aandeel_gas": "",
        "aandeel_hout": "",
        "aanvoertemperatuur": 45.0,
        "elek_tapwaterrendement": 2.2,
        "elek_verwarmingsrendement": 3.2,
        "gas_tapwaterrendement": "",
        "gas_verwarmingsrendement": "",
        "verlies_voorraadvat": 150.0,
        "vermogen": "",
        "aandeel_warmte": "",
        "warm_tapwaterrendement": "",
        "warm_verwarmingsrendement": ""
    },
    "Warmtepomp + buitenlucht 35 graden": {
        "aandeel_elektrisch": 1.0,
        "aandeel_gas": "",
        "aandeel_hout": "",
        "aanvoertemperatuur": 35.0,
        "elek_tapwaterrendement": 2.2,
        "elek_verwarmingsrendement": 3.8,
        "gas_tapwaterrendement": "",
        "gas_verwarmingsrendement": "",
        "verlies_voorraadvat": 150.0,
        "vermogen": "",
        "aandeel_warmte": "",
        "warm_tapwaterrendement": "",
        "warm_verwarmingsrendement": ""
    },
    "Warmtepomp + buitenlucht 55 graden": {
        "aandeel_elektrisch": 1.0,
        "aandeel_gas": "",
        "aandeel_hout": "",
        "aanvoertemperatuur": 55.0,
        "elek_tapwaterrendement": 2.2,
        "elek_verwarmingsrendement": 2.6,
        "gas_tapwaterrendement": "",
        "gas_verwarmingsrendement": "",
        "verlies_voorraadvat": 150.0,
        "vermogen": "",
        "aandeel_warmte": "",
        "warm_tapwaterrendement": "",
        "warm_verwarmingsrendement": ""
    },
    "Warmtepomp + grondbuizen 35 graden": {
        "aandeel_elektrisch": 1.0,
        "aandeel_gas": "",
        "aandeel_hout": "",
        "aanvoertemperatuur": 35.0,
        "elek_tapwaterrendement": 2.5,
        "elek_verwarmingsrendement": 5.3,
        "gas_tapwaterrendement": "",
        "gas_verwarmingsrendement": "",
        "verlies_voorraadvat": 150.0,
        "vermogen": "",
        "aandeel_warmte": "",
        "warm_tapwaterrendement": "",
        "warm_verwarmingsrendement": ""
    },
    "Warmtepomp + grondbuizen 45 graden": {
        "aandeel_elektrisch": 1.0,
        "aandeel_gas": "",
        "aandeel_hout": "",
        "aanvoertemperatuur": 45.0,
        "elek_tapwaterrendement": 2.5,
        "elek_verwarmingsrendement": 4.9,
        "gas_tapwaterrendement": "",
        "gas_verwarmingsrendement": "",
        "verlies_voorraadvat": 150.0,
        "vermogen": "",
        "aandeel_warmte": "",
        "warm_tapwaterrendement": "",
        "warm_verwarmingsrendement": ""
    },
    "Warmtepomp + grondbuizen 55 graden": {
        "aandeel_elektrisch": 1.0,
        "aandeel_gas": "",
        "aandeel_hout": "",
        "aanvoertemperatuur": 55.0,
        "elek_tapwaterrendement": 2.5,
        "elek_verwarmingsrendement": 4.0,
        "gas_tapwaterrendement": "",
        "gas_verwarmingsrendement": "",
        "verlies_voorraadvat": 150.0,
        "vermogen": "",
        "aandeel_warmte": "",
        "warm_tapwaterrendement": "",
        "warm_verwarmingsrendement": ""
    },
    "Warmtepomp + oppervlaktewater 35 graden": {
        "aandeel_elektrisch": 1.0,
        "aandeel_gas": "",
        "aandeel_hout": "",
        "aanvoertemperatuur": 35.0,
        "elek_tapwaterrendement": "",
        "elek_verwarmingsrendement": 4.5,
        "gas_tapwaterrendement": "",
        "gas_verwarmingsrendement": "",
        "verlies_voorraadvat": 0.0,
        "vermogen": "",
        "aandeel_warmte": "",
        "warm_tapwaterrendement": "",
        "warm_verwarmingsrendement": ""
    },
    "Warmtepomp + oppervlaktewater 45 graden": {
        "aandeel_elektrisch": 1.0,
        "aandeel_gas": "",
        "aandeel_hout": "",
        "aanvoertemperatuur": 45.0,
        "elek_tapwaterrendement": "",
        "elek_verwarmingsrendement": 4.0,
        "gas_tapwaterrendement": "",
        "gas_verwarmingsrendement": "",
        "verlies_voorraadvat": 0.0,
        "vermogen": "",
        "aandeel_warmte": "",
        "warm_tapwaterrendement": "",
        "warm_verwarmingsrendement": ""
    },
    "Warmtepomp + oppervlaktewater 55 graden": {
        "aandeel_elektrisch": 1.0,
        "aandeel_gas": "",
        "aandeel_hout": "",
        "aanvoertemperatuur": 55.0,
        "elek_tapwaterrendement": "",
        "elek_verwarmingsrendement": 3.3,
        "gas_tapwaterrendement": "",
        "gas_verwarmingsrendement": "",
        "verlies_voorraadvat": 0.0,
        "vermogen": "",
        "aandeel_warmte": "",
        "warm_tapwaterrendement": "",
        "warm_verwarmingsrendement": ""
    },
    "Warmtepomp hybride": {
        "aandeel_elektrisch": "",
        "aandeel_gas": "",
        "aandeel_hout": "",
        "aanvoertemperatuur": "",
        "elek_tapwaterrendement": "",
        "elek_verwarmingsrendement": "",
        "gas_tapwaterrendement": 0.75,
        "gas_verwarmingsrendement": "",
        "verlies_voorraadvat": 0.0,
        "vermogen": "",
        "aandeel_warmte": "",
        "warm_tapwaterrendement": "",
        "warm_verwarmingsrendement": ""
    },
    "Warmtepomp hybride 35 graden": {
        "aandeel_elektrisch": 0.75,
        "aandeel_gas": 0.25,
        "aandeel_hout": "",
        "aanvoertemperatuur": 35.0,
        "elek_tapwaterrendement": "",
        "elek_verwarmingsrendement": 4.0,
        "gas_tapwaterrendement": 0.75,
        "gas_verwarmingsrendement": 0.975,
        "verlies_voorraadvat": 0.0,
        "vermogen": 7.0,
        "aandeel_warmte": "",
        "warm_tapwaterrendement": "",
        "warm_verwarmingsrendement": ""
    },
    "Warmtepomp hybride 45 graden": {
        "aandeel_elektrisch": 0.65,
        "aandeel_gas": 0.35,
        "aandeel_hout": "",
        "aanvoertemperatuur": 45.0,
        "elek_tapwaterrendement": "",
        "elek_verwarmingsrendement": 3.4,
        "gas_tapwaterrendement": 0.75,
        "gas_verwarmingsrendement": 0.975,
        "verlies_voorraadvat": 0.0,
        "vermogen": 7.0,
        "aandeel_warmte": "",
        "warm_tapwaterrendement": "",
        "warm_verwarmingsrendement": ""
    },
    "Warmtepomp hybride 55 graden": {
        "aandeel_elektrisch": 0.55,
        "aandeel_gas": 0.45,
        "aandeel_hout": "",
        "aanvoertemperatuur": 55.0,
        "elek_tapwaterrendement": "",
        "elek_verwarmingsrendement": 3.2,
        "gas_tapwaterrendement": 0.75,
        "gas_verwarmingsrendement": 0.975,
        "verlies_voorraadvat": 0.0,
        "vermogen": 7.0,
        "aandeel_warmte": "",
        "warm_tapwaterrendement": "",
        "warm_verwarmingsrendement": ""
    },
    "Warmtepomp op buitenlucht; 120l boiler": {
        "aandeel_elektrisch": "",
        "aandeel_gas": "",
        "aandeel_hout": "",
        "aanvoertemperatuur": "",
        "elek_tapwaterrendement": 2.2,
        "elek_verwarmingsrendement": "",
        "gas_tapwaterrendement": "",
        "gas_verwarmingsrendement": "",
        "verlies_voorraadvat": 120.0,
        "vermogen": "",
        "aandeel_warmte": "",
        "warm_tapwaterrendement": "",
        "warm_verwarmingsrendement": ""
    },
    "Warmtepomp op buitenlucht; 200l boiler": {
        "aandeel_elektrisch": "",
        "aandeel_gas": "",
        "aandeel_hout": "",
        "aanvoertemperatuur": "",
        "elek_tapwaterrendement": 2.2,
        "elek_verwarmingsrendement": "",
        "gas_tapwaterrendement": "",
        "gas_verwarmingsrendement": "",
        "verlies_voorraadvat": 150.0,
        "vermogen": "",
        "aandeel_warmte": "",
        "warm_tapwaterrendement": "",
        "warm_verwarmingsrendement": ""
    },
    "Warmtepomp op buitenlucht; 300l boiler": {
        "aandeel_elektrisch": "",
        "aandeel_gas": "",
        "aandeel_hout": "",
        "aanvoertemperatuur": "",
        "elek_tapwaterrendement": 2.2,
        "elek_verwarmingsrendement": "",
        "gas_tapwaterrendement": "",
        "gas_verwarmingsrendement": "",
        "verlies_voorraadvat": 160.0,
        "vermogen": "",
        "aandeel_warmte": "",
        "warm_tapwaterrendement": "",
        "warm_verwarmingsrendement": ""
    },
    "Warmtepomp op buitenlucht; 400l boiler": {
        "aandeel_elektrisch": "",
        "aandeel_gas": "",
        "aandeel_hout": "",
        "aanvoertemperatuur": "",
        "elek_tapwaterrendement": 2.2,
        "elek_verwarmingsrendement": "",
        "gas_tapwaterrendement": "",
        "gas_verwarmingsrendement": "",
        "verlies_voorraadvat": 170.0,
        "vermogen": "",
        "aandeel_warmte": "",
        "warm_tapwaterrendement": "",
        "warm_verwarmingsrendement": ""
    },
    "Warmtepomp op grondbuizen; 150l boiler": {
        "aandeel_elektrisch": "",
        "aandeel_gas": "",
        "aandeel_hout": "",
        "aanvoertemperatuur": "",
        "elek_tapwaterrendement": 2.5,
        "elek_verwarmingsrendement": "",
        "gas_tapwaterrendement": "",
        "gas_verwarmingsrendement": "",
        "verlies_voorraadvat": 120.0,
        "vermogen": "",
        "aandeel_warmte": "",
        "warm_tapwaterrendement": "",
        "warm_verwarmingsrendement": ""
    },
    "Warmtepomp op grondbuizen; 200l boiler": {
        "aandeel_elektrisch": "",
        "aandeel_gas": "",
        "aandeel_hout": "",
        "aanvoertemperatuur": "",
        "elek_tapwaterrendement": 2.5,
        "elek_verwarmingsrendement": "",
        "gas_tapwaterrendement": "",
        "gas_verwarmingsrendement": "",
        "verlies_voorraadvat": 150.0,
        "vermogen": "",
        "aandeel_warmte": "",
        "warm_tapwaterrendement": "",
        "warm_verwarmingsrendement": ""
    },
    "Warmtepomp op grondbuizen; 300l boiler": {
        "aandeel_elektrisch": "",
        "aandeel_gas": "",
        "aandeel_hout": "",
        "aanvoertemperatuur": "",
        "elek_tapwaterrendement": 2.5,
        "elek_verwarmingsrendement": "",
        "gas_tapwaterrendement": "",
        "gas_verwarmingsrendement": "",
        "verlies_voorraadvat": 160.0,
        "vermogen": "",
        "aandeel_warmte": "",
        "warm_tapwaterrendement": "",
        "warm_verwarmingsrendement": ""
    },
    "Warmtepomp op grondbuizen; 400l boiler": {
        "aandeel_elektrisch": "",
        "aandeel_gas": "",
        "aandeel_hout": "",
        "aanvoertemperatuur": "",
        "elek_tapwaterrendement": 2.5,
        "elek_verwarmingsrendement": "",
        "gas_tapwaterrendement": "",
        "gas_verwarmingsrendement": "",
        "verlies_voorraadvat": 170.0,
        "vermogen": "",
        "aandeel_warmte": "",
        "warm_tapwaterrendement": "",
        "warm_verwarmingsrendement": ""
    },
    "Warmtepompboiler op buitenlucht; 150l": {
        "aandeel_elektrisch": "",
        "aandeel_gas": "",
        "aandeel_hout": "",
        "aanvoertemperatuur": "",
        "elek_tapwaterrendement": 2.2,
        "elek_verwarmingsrendement": "",
        "gas_tapwaterrendement": "",
        "gas_verwarmingsrendement": "",
        "verlies_voorraadvat": 120.0,
        "vermogen": "",
        "aandeel_warmte": "",
        "warm_tapwaterrendement": "",
        "warm_verwarmingsrendement": ""
    },
    "Warmtepompboiler op buitenlucht; 200l": {
        "aandeel_elektrisch": "",
        "aandeel_gas": "",
        "aandeel_hout": "",
        "aanvoertemperatuur": "",
        "elek_tapwaterrendement": 2.2,
        "elek_verwarmingsrendement": "",
        "gas_tapwaterrendement": "",
        "gas_verwarmingsrendement": "",
        "verlies_voorraadvat": 150.0,
        "vermogen": "",
        "aandeel_warmte": "",
        "warm_tapwaterrendement": "",
        "warm_verwarmingsrendement": ""
    },
    "Warmtepompboiler op buitenlucht; 300l": {
        "aandeel_elektrisch": "",
        "aandeel_gas": "",
        "aandeel_hout": "",
        "aanvoertemperatuur": "",
        "elek_tapwaterrendement": 2.2,
        "elek_verwarmingsrendement": "",
        "gas_tapwaterrendement": "",
        "gas_verwarmingsrendement": "",
        "verlies_voorraadvat": 160.0,
        "vermogen": "",
        "aandeel_warmte": "",
        "warm_tapwaterrendement": "",
        "warm_verwarmingsrendement": ""
    },
    "Warmtepompboiler op buitenlucht; 400l": {
        "aandeel_elektrisch": "",
        "aandeel_gas": "",
        "aandeel_hout": "",
        "aanvoertemperatuur": "",
        "elek_tapwaterrendement": 2.2,
        "elek_verwarmingsrendement": "",
        "gas_tapwaterrendement": "",
        "gas_verwarmingsrendement": "",
        "verlies_voorraadvat": 170.0,
        "vermogen": "",
        "aandeel_warmte": "",
        "warm_tapwaterrendement": "",
        "warm_verwarmingsrendement": ""
    },
    "Warmtepompboiler op ventilatielucht; 150l": {
        "aandeel_elektrisch": "",
        "aandeel_gas": "",
        "aandeel_hout": "",
        "aanvoertemperatuur": "",
        "elek_tapwaterrendement": 2.7,
        "elek_verwarmingsrendement": "",
        "gas_tapwaterrendement": "",
        "gas_verwarmingsrendement": "",
        "verlies_voorraadvat": 120.0,
        "vermogen": "",
        "aandeel_warmte": "",
        "warm_tapwaterrendement": "",
        "warm_verwarmingsrendement": ""
    },
    "Warmtepompboiler op ventilatielucht; 200l": {
        "aandeel_elektrisch": "",
        "aandeel_gas": "",
        "aandeel_hout": "",
        "aanvoertemperatuur": "",
        "elek_tapwaterrendement": 2.7,
        "elek_verwarmingsrendement": "",
        "gas_tapwaterrendement": "",
        "gas_verwarmingsrendement": "",
        "verlies_voorraadvat": 150.0,
        "vermogen": "",
        "aandeel_warmte": "",
        "warm_tapwaterrendement": "",
        "warm_verwarmingsrendement": ""
    },
    "Warmtepompboiler op ventilatielucht; 300l": {
        "aandeel_elektrisch": "",
        "aandeel_gas": "",
        "aandeel_hout": "",
        "aanvoertemperatuur": "",
        "elek_tapwaterrendement": 2.7,
        "elek_verwarmingsrendement": "",
        "gas_tapwaterrendement": "",
        "gas_verwarmingsrendement": "",
        "verlies_voorraadvat": 160.0,
        "vermogen": "",
        "aandeel_warmte": "",
        "warm_tapwaterrendement": "",
        "warm_verwarmingsrendement": ""
    },
    "Warmtepompboiler op ventilatielucht; 400l": {
        "aandeel_elektrisch": "",
        "aandeel_gas": "",
        "aandeel_hout": "",
        "aanvoertemperatuur": "",
        "elek_tapwaterrendement": 2.7,
        "elek_verwarmingsrendement": "",
        "gas_tapwaterrendement": "",
        "gas_verwarmingsrendement": "",
        "verlies_voorraadvat": 170.0,
        "vermogen": "",
        "aandeel_warmte": "",
        "warm_tapwaterrendement": "",
        "warm_verwarmingsrendement": ""
    },
    "Boosterwarmtepomp": {
      "aandeel_elektrisch": "",
      "aandeel_gas": "",
      "aandeel_hout": "",
      "aanvoertemperatuur": "",
      "elek_tapwaterrendement": 3,
      "elek_verwarmingsrendement": "",
      "gas_tapwaterrendement": "",
      "gas_verwarmingsrendement": "",
      "verlies_voorraadvat": 0.0,
      "vermogen": "",
      "aandeel_warmte": "",
      "warm_tapwaterrendement": "",
      "warm_verwarmingsrendement": ""
  },
    "Warmtenet 45°C": {
        "aandeel_elektrisch": "",
        "aandeel_gas": "",
        "aandeel_hout": "",
        "aanvoertemperatuur": 45.0,
        "elek_tapwaterrendement": "",
        "elek_verwarmingsrendement": "",
        "gas_tapwaterrendement": "",
        "gas_verwarmingsrendement": "",
        "verlies_voorraadvat": 0.0,
        "vermogen": "",
        "aandeel_warmte": 1.0,
        "warm_tapwaterrendement": 1.0,
        "warm_verwarmingsrendement": 1.0
    },
    "Warmtenet 55°C": {
        "aandeel_elektrisch": "",
        "aandeel_gas": "",
        "aandeel_hout": "",
        "aanvoertemperatuur": 55.0,
        "elek_tapwaterrendement": "",
        "elek_verwarmingsrendement": "",
        "gas_tapwaterrendement": "",
        "gas_verwarmingsrendement": "",
        "verlies_voorraadvat": 0.0,
        "vermogen": "",
        "aandeel_warmte": 1.0,
        "warm_tapwaterrendement": 1.0,
        "warm_verwarmingsrendement": 1.0
    },
    "Warmtenet 65°C": {
        "aandeel_elektrisch": "",
        "aandeel_gas": "",
        "aandeel_hout": "",
        "aanvoertemperatuur": 65.0,
        "elek_tapwaterrendement": "",
        "elek_verwarmingsrendement": "",
        "gas_tapwaterrendement": "",
        "gas_verwarmingsrendement": "",
        "verlies_voorraadvat": 0.0,
        "vermogen": "",
        "aandeel_warmte": 1.0,
        "warm_tapwaterrendement": 1.0,
        "warm_verwarmingsrendement": 1.0
    }
  }

  const afgifte = {
    'Radiatoren type 10': {
      'elektrisch pompverbruik': 0,
      '35': 169.7068404,
      '45': 309.1988131,
      '55': 465.1785714,
      '65': 939,
      '60-90': 1042,
    },
    'Radiatoren type 11': {
      'elektrisch pompverbruik': 0,
      '35': 276.2214984,
      '45': 503.264095,
      '55': 757.1428571,
      '65': 1528,
      '60-90': 1696,
    },
    'Radiatoren type 20': {
      'elektrisch pompverbruik': 0,
      '35': 287.6221498,
      '45': 524.0356083,
      '55': 788.3928571,
      '65': 1591,
      '60-90': 1766,
    },
    'Radiatoren type 21': {
      'elektrisch pompverbruik': 0,
      '35': 394.1368078,
      '45': 718.1008902,
      '55': 1080.357143,
      '65': 2180,
      '60-90': 2420,
    },
    'Radiatoren type 22': {
      'elektrisch pompverbruik': 0,
      '35': 500,
      '45': 910.9792285,
      '55': 1370.535714,
      '65': 2766,
      '60-90': 3070,
    },
    'Radiatoren type 33': {
      'elektrisch pompverbruik': 0,
      '35': 706.514658,
      '45': 1287.240356,
      '55': 1936.607143,
      '65': 3908,
      '60-90': 4338,
    },
    'Laagtemperatuur radiatoren': {
      'elektrisch pompverbruik': 0,
      '35': 850,
      '45': 1000,
      '55': 1500,
      '65': 1700,
      '60-90': 2200,
    },
    'Vloerverwarming (200mm hoh)': { 'elektrisch pompverbruik': 60, '35': 73, '45': 97, '55': 121, '65': 121, '60-90': 121 },
    'Vloerverwarming (150mm hoh)': { 'elektrisch pompverbruik': 60, '35': 84, '45': 112, '55': 140, '65': 140, '60-90': 140 },
    'Vloerverwarming (100mm hoh)': { 'elektrisch pompverbruik': 60, '35': 97, '45': 129, '55': 162, '65': 162, '60-90': 162 },
    'Ledenradiator 160mm breed': {
      'elektrisch pompverbruik': 0,
      '35': 284,
      '45': 532,
      '55': 810,
      '65': 1598,
      '60-90': 1775,
    },
    'Ledenradiator 210mm breed': {
      'elektrisch pompverbruik': 0,
      '35': 378,
      '45': 710,
      '55': 1246,
      '65': 2131,
      '60-90': 2368,
    },
    'Ledenradiator 270mm breed': {
      'elektrisch pompverbruik': 0,
      '35': 490,
      '45': 919,
      '55': 1610,
      '65': 2758,
      '60-90': 3064,
    },
    'Badkamerradiator': {
      'elektrisch pompverbruik': 0,
      '35': 171,
      '45': 312,
      '55': 536,
      '65': 783,
      '60-90': 1048,
    },
    'Convectorput': {
      'elektrisch pompverbruik': 0,
      '35': 300,
      '45': 700,
      '55': 1100,
      '65': 1400,
      '60-90': 1800,
    },
    'Lucht': { 'elektrisch pompverbruik': 60, '35': 4.666666667, '45': 8, '55': 11.33333333, '65': 14.66666667, '60-90': 13 },
    'Lokaal / handmatig': { 'elektrisch pompverbruik': 0, '35': 1000, '45': 1000, '55': 1000, '65': 1000, '60-90': 1000 },
  }

  const afgifteUnit = {
    'Radiatoren type 10': {
      unit: 'm2',
      field: 'afmeting',
    },
    'Radiatoren type 11': {
      unit: 'm2',
      field: 'afmeting',
    },
    'Radiatoren type 20': {
      unit: 'm2',
      field: 'afmeting',
    },
    'Radiatoren type 21': {
      unit: 'm2',
      field: 'afmeting',
    },
    'Radiatoren type 22': {
      unit: 'm2',
      field: 'afmeting',
    },
    'Radiatoren type 33': {
      unit: 'm2',
      field: 'afmeting',
    },
    'Vloerverwarming (200mm hoh)': {
      unit: 'm2',
      field: 'afmeting',
    },
    'Vloerverwarming (150mm hoh)': {
      unit: 'm2',
      field: 'afmeting',
    },
    'Vloerverwarming (100mm hoh)': {
      unit: 'm2',
      field: 'afmeting',
    },
    'Ledenradiator 160mm breed': {
      unit: 'm2',
      field: 'afmeting',
    },
    'Ledenradiator 210mm breed': {
      unit: 'm2',
      field: 'afmeting',
    },
    'Ledenradiator 270mm breed': {
      unit: 'm2',
      field: 'afmeting',
    },
    'Badkamerradiator': {
      unit: 'm2',
      field: 'afmeting',
    },
    'Convectorput': {
      unit: 'm',
      field: 'lengte',
    },
    // eslint-disable-next-line prettier/prettier
    'Lucht': {
      unit: 'm3/h',
      field: 'volume',
    },
    'Lokaal / handmatig': {
      unit: 'kW',
      field: 'vermogen',
    },
    'Laagtemperatuur radiatoren': {
      unit: 'kW',
      field: 'vermogen',
    },
  }

  // Ja/Nee is hier opwekker binnen thermische schil?
  const rendementen_distributiesysteem = {
    'Ja, ongeïsoleerde leidingen': {
      Ja: 0.9,
      Nee: 0.85,
    },
    'Ja, geïsoleerd leidingen': {
      Ja: 0.97,
      Nee: 0.9,
    },
    'Geen leidingen buiten de thermische schil': {
      Ja: 1,
      Nee: 0.95,
    },
  }

  const salderingAandeel = {
    2020: 1,
    2021: 1,
    2022: 1,
    2023: 1,
    2024: 1,
    2025: 1,
    2026: 1,
    2027: 1,
    2028: 1,
    2029: 1,
    2030: 1,
    2031: 1,
    2032: 1,
    2033: 1,
    2034: 1,
    2035: 1,
    2036: 1,
    2037: 1,
    2038: 1,
    2039: 1,
    2040: 1,
    2041: 1,
    2042: 1,
    2043: 1,
    2044: 1,
    2045: 1,
    2046: 1,
    2047: 1,
    2048: 1,
    2049: 1,
    2050: 1,
  }

  const zonnepanelenOrientatieHellingshoek = {
    'Oost': {
        0: 87,
        10: 89,
        20: 87,
        30: 86,
        40: 82,
        50: 78,
        60: 74,
        70: 69,
        80: 63,
        90: 56
    },
    'Zuidoost': {
        0: 87,
        10: 92,
        20: 92.5,
        30: 93,
        40: 90.5,
        50: 86.5,
        60: 82,
        70: 78,
        80: 71.5,
        90: 63.5
    },
    'Zuid': {
        0: 87,
        10: 95,
        20: 98,
        30: 100,
        40: 99,
        50: 95,
        60: 90,
        70: 87,
        80: 80,
        90: 71
    },
    'Zuidwest': {
        0: 87,
        10: 92,
        20: 92.5,
        30: 93,
        40: 90.5,
        50: 86.5,
        60: 82,
        70: 78,
        80: 71.5,
        90: 63.5
    },
    'West': {
        0: 87,
        10: 89,
        20: 87,
        30: 86,
        40: 82,
        50: 78,
        60: 74,
        70: 69,
        80: 63,
        90: 56
    }
  }

  const standaardIsolatie = {
    'Vloer': {
        '<1920': 0.17,
        '1920-1965': 0.17,
        '1965-1975': 0.17,
        '1975-1988': 0.52,
        '1988-1992': 1.3,
        '1992-2012': 2.5,
        '2012-2015': 3.5,
        '2015-2021': 3.5,
        '>2021': 3.7
    },
    'Gevel': {
        '<1920': 0.29,
        '1920-1965': 0.43,
        '1965-1975': 0.43,
        '1975-1988': 1.3,
        '1988-1992': 2,
        '1992-2012': 2.5,
        '2012-2015': 3.5,
        '2015-2021': 4.5,
        '>2021': 4.7
    },
    'Dak': {
        '<1920': 0.3,
        '1920-1965': 0.3,
        '1965-1975': 0.86,
        '1975-1988': 1.3,
        '1988-1992': 2,
        '1992-2012': 2.5,
        '2012-2015': 3.5,
        '2015-2021': 6,
        '>2021': 6.3
    }
  }

  // voor gas per m3, voor elektriciteit per kWh, voor warmte per GJ
  const co2_uitstoot = {
    gas: 1.788,
    elektriciteit: 0.30,
    warmte: 22,
  }

  const maximale_ventilatie = 2.5
  const rendement_ventilatie_wtw = 0.85
  const constante_opwarmen = 25
  const dichtheid_lucht = 1200
  const soortelijke_massa_lucht = 1.29
  const correctie_winddruk_op_de_gevel = 1.1
  const correctie_zoninstraling = 0.3
  const energieinhoud_gas_kwh = 35.17 / 3.6
  const energieinhoud_warmte_kwh = 3.6 / 1000
  const verbruik_koken = 360

  const eis_bestaande_bouw = 0.7
  const gelijktijdigheid = 0.7

  return {
    glas,
    overgangsweerstanden,
    orientatie_mapper,
    klimaatgegevens,
    algemene_correctiefactor_transmissie,
    woningtype_ventilatie,
    stromingsexponent,
    ventilatie,
    maximale_ventilatie,
    rendement_ventilatie_wtw,
    constante_opwarmen,
    dichtheid_lucht,
    soortelijke_massa_lucht,
    winddruk_coefficient,
    correctie_winddruk_op_de_gevel,
    correctie_zoninstraling,
    zoninstraling,
    rendement_distributiesysteem,
    vermogensberekening,
    warm_tapwater_behoefte,
    correctiefactor_verbruikprofiel,
    zonneboiler_tapwater,
    verlies_voorraadvat,
    installaties,
    ventilatie_verbruik,
    afgifte,
    energieinhoud_gas_kwh,
    energieinhoud_warmte_kwh,
    rendementen_distributiesysteem,
    salderingAandeel,
    co2_uitstoot,
    verbruik_koken,
    streefwaarden_woningisolatie_type_mapper,
    streefwaarden_woningisolatie_era_mapper,
    afgifteUnit,
    klimaatgegevens_sum_zonkracht,
    zonnepanelenOrientatieHellingshoek,
    standaardIsolatie,
    eis_bestaande_bouw,
    gelijktijdigheid,
  }
}
