import { ref } from 'vue'
import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'
import formHandler from '@/use/formHandler'

export default () => {
  // data
  let records = ref([])
  let showAdd = ref(false)
  let showEdit = ref(false)

  // handlers
  const loadHandler = loadingHandler()
  const addForm = formHandler()
  const editForm = formHandler()
  const useApi = apiHandler()

  const fields = ref([
    {
      label: 'Address ID',
      key: 'object_id',
      type: 'id',
      table: false,
      add: false,
      edit: false,
    },
    {
      label: 'address',
      key: 'address',
      type: 'string',
    },
    {
      label: 'zip',
      key: 'zip',
      type: 'string',
    },
    {
      label: 'place',
      key: 'place',
      type: 'string',
    },
    {
      label: 'dateCreated',
      key: 'date_created',
      type: 'date',
      add: false,
      edit: false,
    },
  ])

  // list
  function getRecords() {
    // Start loading state
    loadHandler.setLoadingState('get_records', true)

    // Return a new promise
    return new Promise((resolve, reject) => {
      useApi
        .request('get', 'addresses', 'list')
        .then((result) => {
          const data = result.data.data
          records.value = data

          // Stop loading state
          loadHandler.setLoadingState('get_records', false)

          // Resolve the promise with data
          resolve(data)
        })
        .catch((error) => {
          // Stop loading state
          loadHandler.setLoadingState('get_records', false)

          // Reject the promise if there's an error
          reject(error)
        })
    })
  }

  // add
  function addRecord(data) {
    const payload = {
      address: `${data.data.straatnaam} ${data.data.huis_nlt}`,
      zip: data.data.postcode,
      place: data.data.woonplaatsnaam,
      config: data.data,
      x: data.geometry[0],
      y: data.geometry[1],
    }

    loadHandler.setLoadingState('add_record', true)
    useApi.request('post', 'addresses', 'add', payload).then(() => {
      loadHandler.setLoadingState('add_record', false)
      showAdd.value = false
      getRecords()
    })
  }

  // open edit
  function openEdit(data) {
    loadHandler.setLoadingState('get_details', true)
    const payload = {
      object_id: data.object_id,
    }

    useApi.request('post', 'addresses', 'get_details', payload).then((response) => {
      const response_data = response.data.data
      editForm.create({ type: 'edit', fields: fields.value, data: response_data })
      showEdit.value = true
      loadHandler.setLoadingState('get_details', false)
    })
  }

  function openAdd(prefill) {
    const formOptions = { type: 'add', fields: fields.value, data: {} }

    if (prefill) {
      formOptions.data = prefill
    }

    addForm.create(formOptions)
    showAdd.value = true
  }

  function closeEdit() {
    editForm.data.value = {}
    showEdit.value = false
  }

  function closeAdd() {
    showAdd.value = false
    addForm.close()
  }

  // edit
  function editRecord() {
    const payload = editForm.data.value

    loadHandler.setLoadingState('edit_record', true)
    useApi.request('post', 'addresses', 'update', payload).then(() => {
      loadHandler.setLoadingState('edit_record', false)
      closeEdit()
      getRecords()
    })
  }

  function deleteRecord(value) {
    loadHandler.setLoadingState('delete_record', true)
    const payload = {
      object_id: value,
    }
    useApi.request('post', 'addresses', 'delete', payload).then(() => {
      loadHandler.setLoadingState('delete_record', false)
      getRecords()
    })
  }

  // exposed
  return {
    fields: fields,
    records: records,
    getRecords,
    loadHandler,
    deleteRecord,
    showAdd,
    showEdit,
    addForm,
    addRecord,
    editRecord,
    openEdit,
    editForm,
    closeEdit,
    openAdd,
    closeAdd,
  }
}
