<template>
  <!-- number -->
  <input
    :disabled="disabled"
    class="bg-white appearance-none border border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-400 text-sm disabled:bg-gray-100"
    :id="`${dataKey}_${index}`"
    :value="handler.data.value[index][dataKey]"
    type="text"
    @input="handler.validate($event, type, [], dataKey, index)"
  />
</template>

<script>
export default {
  props: {
    handler: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dataKey: {
      type: String,
      default: '',
    },
    index: {
      type: Number,
      default: undefined,
    },
    type: {
      type: String,
      default: 'string',
    },
  },
  setup() {},
  components: {},
}
</script>
