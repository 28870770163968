<template>
  <span v-if="handler">
    <!-- rentepercentage -->
    <div class="flex flex-row text-sm text-slate-500 mb-1">
      <p class="basis-1/3">{{ handler.fields.rentepercentageField.label }}</p>
      <div class="basis-1/3 relative">
        <baseField :handler="handler" :field="handler.fields.rentepercentageField" />
        <div class="units absolute right-3 bottom-0 mb-2">
          <span class="units-value text-gray-400 text-sm rounded-xl p-1" id="unitsValue">{{
            handler.fields.rentepercentageField.unit
          }}</span>
        </div>
      </div>
      <p class="basis-1/3"></p>
    </div>

    <!-- Looptijd jaren -->
    <div class="flex flex-row text-sm text-slate-500 mb-1">
      <p class="basis-1/3">{{ handler.fields.looptijdField.label }}</p>
      <p class="basis-1/3"><baseField :handler="handler" :field="handler.fields.looptijdField" /></p>
      <p class="basis-1/3"></p>
    </div>

    <!-- anders financieren -->
    <div class="flex flex-row text-sm text-slate-500">
      <p class="basis-1/3">{{ handler.fields.andersFinancierenField.label }}</p>
      <div class="basis-1/3 relative">
        <baseField :handler="handler" :field="handler.fields.andersFinancierenField" />
        <div class="units absolute right-3 bottom-0 mb-2">
          <span class="units-value text-gray-400 text-sm rounded-xl p-1" id="unitsValue">{{
            handler.fields.andersFinancierenField.unit
          }}</span>
        </div>
      </div>
      <p class="basis-1/3"></p>
    </div>

    <!-- Bedrag -->
    <div class="flex flex-row text-sm text-slate-500 mt-2">
      <p class="basis-1/3">Bedrag</p>
      <p class="basis-1/3">{{ useFilter.filter(handler.bedrag.value, 'currencyRound') }}</p>
      <p class="basis-1/3"></p>
    </div>

    <!-- Maandlasten -->
    <div class="flex flex-row text-sm text-slate-500">
      <p class="basis-1/3">Maandlasten anniutair</p>
      <p class="basis-1/3">{{ useFilter.filter(handler.maandlasten.value, 'currencyRound') }}</p>
      <p class="basis-1/3"></p>
    </div>

    <!-- Maandlasten totaal-->
    <div class="flex flex-row text-sm text-slate-500">
      <p class="basis-1/3">Totale maandelijkse financieringskosten</p>
      <p class="basis-1/3">{{ useFilter.filter(handler.maanlasten_totaal.value, 'currencyRound') }}</p>
      <p class="basis-1/3"></p>
    </div>

    <!-- Maandlasten oud-->
    <div class="flex flex-row text-sm text-slate-500">
      <p class="basis-1/3">Maandlasten oud</p>
      <p class="basis-1/3">
        {{
          useFilter.filter(maatregelenhandler.costs.value.energiekosten_per_maand_eerste_jaar_huidig, 'currencyRound')
        }}
      </p>
      <p class="basis-1/3"></p>
    </div>

    <!-- Maandlasten nieuw-->
    <div class="flex flex-row text-sm text-slate-500">
      <p class="basis-1/3">Maandlasten nieuw (energie, excl indexering)</p>
      <p class="basis-1/3">
        {{
          useFilter.filter(maatregelenhandler.costs.value.energiekosten_per_maand_eerste_jaar_nieuw, 'currencyRound')
        }}
      </p>
      <p class="basis-1/3"></p>
    </div>

    <!-- Maandlasten nieuw-->
    <div class="flex flex-row text-sm text-slate-500">
      <p class="basis-1/3">Maandlasten nieuw (energie, excl indexering + financiering)</p>
      <p class="basis-1/3">
        {{
          useFilter.filter(
            maatregelenhandler.costs.value.energiekosten_per_maand_eerste_jaar_nieuw + handler.maanlasten_totaal.value,
            'currencyRound'
          )
        }}
      </p>
      <p class="basis-1/3"></p>
    </div>
  </span>
</template>

<script>
import filterHandler from '@/use/filterHandler'

export default {
  props: {
    handler: {
      type: Object,
      default: () => {},
    },
    maatregelenhandler: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const useFilter = filterHandler()

    return {
      useFilter,
    }
  },
}
</script>
