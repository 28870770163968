<template>
  <div class="h-full min-h-0" v-if="useContentGuard.canSee('organisation:proces')">
    <div class="h-full">
      <!-- vertical splitter -->
      <div class="flex h-full flex-col">
        <!-- title -->
        <div class="px-20 pt-10">
          <!-- back and title -->
          <div class="flex flex-row justify-between">
            <div class="flex flex-row items-center">
              <span class="mr-6">
                <backButton />
              </span>
              <basePageTitle
                v-if="!useAddressDetails.loadHandler.states.get_address_details"
                :title="useAddressDetails.address.value.address"
              >
                <p class="text-slate-500 text-xs">
                  {{ useAddressDetails.address.value.zip }}
                </p>
                <p class="text-slate-500 text-xs">
                  {{ useAddressDetails.address.value.place }}
                </p>
              </basePageTitle>

              <base-loader
                class="text-xl text-slate-500 my-auto"
                :loading="useAddressDetails.loadHandler.states.get_address_details"
              />
            </div>
          </div>

          <!-- first row, details and map-->
          <div class="flex flex-row mt-4">
            <!-- detail card -->
            <div class="basis-1/2 mr-4">
              <baseCard v-if="!useAddressDetails.loadHandler.states.get_address_details" class="overflow-y-auto">
                <!-- details -->
                <base-key-value
                  :data="useAddressDetails.address.value"
                  :fields="useAddressDetails.addressDetailFields.value"
                />
              </baseCard>

              <!-- buttons -->
              <div class="flex -flex-row gap-4 mt-4">
                <base-card
                  v-if="useAddressDetails.label.value.length > 0"
                  :loading="useAddressDetails.loadHandler.somethingLoading.value"
                  :title="`Energielabel: ${useAddressDetails.label.value[0].energieklasse}`"
                  titleIcon="TagIcon"
                  :class="labelStyle(useAddressDetails.label.value[0].energieklasse)"
                  @click="tagClick()"
                >
                  <div class="text-sm">{{ useAddressDetails.label.value[0].gebouwtype }}</div>
                  <div class="text-sm">{{ useAddressDetails.label.value[0].registratiedatum }}</div>
                </base-card>

                <base-card
                  v-if="
                    !useAddressDetails.loadHandler.states.get_address_details &&
                    !useAddressDetails.label.value.length > 0
                  "
                  :loading="useAddressDetails.loadHandler.somethingLoading.value"
                  title="Energielabel: onbekend"
                  titleIcon="TagIcon"
                  class="basis-1/2 hover:bg-gray-50 cursor-pointer text-gray-500"
                  @click="tagClick()"
                >
                </base-card>

                <!-- cluster card -->
                <base-card
                  v-if="!useAddressDetails.loadHandler.states.get_address_details"
                  :loading="false"
                  title="Project"
                  titleIcon="PackageIcon"
                  class="basis-1/2 hover:bg-gray-50 cursor-pointer text-gray-500"
                  @click="tagClick()"
                >
                </base-card>
              </div>
            </div>

            <!-- map card -->
            <div class="basis-1/2">
              <div v-show="!useAddressDetails.loadHandler.states.get_address_details" class="h-full">
                <div class="w-full h-full z-10 rounded shadow-lg bg-white ml-2" id="detail_map"></div>
              </div>
            </div>
          </div>

          <!-- second row, linked surveys-->
          <div class="flex flex-row mt-4">
            <div v-if="!useAddressDetails.loadHandler.states.get_address_details" class="w-1/2 mr-4">
              <div class="my-8">
                <basePageTitle title="Opnames" class="my-8"></basePageTitle>
              </div>
              <baseTable
                :fields="useSurvey.fields.value"
                :records="useSurvey.records.value"
                :records-per-page="25"
                :add-button="true"
                :edit-button="true"
                :delete-button="true"
                :duplicate-button="false"
                :export-record-button="true"
                :clickAble="true"
                :clickCallback="tableAction"
                :loading="useSurvey.loadHandler.somethingLoading.value"
                @add="onOpenAdd()"
                @edit="onOpenEdit"
                @delete="onDelete"
                @duplicate="useSurvey.duplicateRecord"
                @export_record="useSurvey.exportRecord"
              />
            </div>
            <div class="w-1/2 ml-4" v-if="!useAddressDetails.loadHandler.states.get_address_details">
              <baseTabs :handler="tabs">
                <template v-slot:panel-steps>
                  <div class="" v-if="useAddressDetails.address.value.projects?.[0]?.config?.stappen">
                    <baseSteps :handler="useAddressDetails"> </baseSteps>
                  </div>
                  <div class="" v-else-if="useAddressDetails.address.value.projects[0] === undefined">
                    <baseCard class="text-sm text-gray-500"> Dit adres is niet toegevoegd aan een project. </baseCard>
                  </div>
                  <div class="" v-else>
                    <baseCard class="text-sm text-gray-500"> Dit project heeft geen stappen. </baseCard>
                  </div>
                </template>
                <template v-slot:panel-attachments>
                  <div>
                    <baseAttachments :attachable_id="route.params.objectId" attachable_type="address" :title="false" />
                  </div>
                </template>
                <template v-slot:panel-comments>
                  <div class="">
                    <baseComments :commentable_id="route.params.objectId" commentable_type="address" :title="false" />
                  </div>
                </template>
              </baseTabs>
            </div>
          </div>

          <!-- add modal -->
          <baseModal
            v-if="useSurvey.showAdd.value"
            @close="useSurvey.closeAdd()"
            :title="$translate('Add')"
            @enter="onEnter()"
          >
            <baseForm :handler="useSurvey.addForm" :loading="useSurvey.loadHandler.states['add_record']" />
            <template v-slot:buttons>
              <!-- add -->
              <baseButton @action="useSurvey.addRecord()" :loading="useSurvey.loadHandler.states[`add_record`]">
                {{ $translate('Add') }}
              </baseButton>

              <!-- import woningtool -->
              <baseButton
                class="mr-2"
                @action="openFilePicker('woningtool')"
                :loading="useSurvey.loadHandler.states[`import_record`]"
              >
                <div class="flex felx-row">
                  <featherIcon class="w-5 h-5 mr-2" icon="UploadIcon" />
                  <span>Woningtool</span>
                </div>
              </baseButton>

              <!-- Import monitor -->
              <baseButton
                v-if="useContentGuard.canSee('monitor:import')"
                class="mr-2"
                @action="openFilePicker('monitor')"
                :loading="useSurvey.loadHandler.states[`import_record`]"
              >
                <div class="flex felx-row">
                  <featherIcon class="w-5 h-5 mr-2" icon="UploadIcon" />
                  <span>Monitor</span>
                </div>
              </baseButton>
            </template>
          </baseModal>

          <!-- edit modal -->
          <baseModal v-if="useSurvey.showEdit.value" @close="useSurvey.closeEdit()" :title="$translate('Edit')">
            <baseForm :handler="useSurvey.editForm" :loading="useSurvey.loadHandler.states['edit_record']" />
            <template v-slot:buttons>
              <baseButton @action="useSurvey.editRecord()" :loading="useSurvey.loadHandler.states[`edit_record`]">
                {{ $translate('Edit') }}
              </baseButton>
            </template>
          </baseModal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter, useRoute } from 'vue-router'
import { onMounted, computed } from 'vue'
import addressDetailHandler from '@/use/addressDetailHandler'
import nodeMapHandler from '@/use/nodeMapHandler'
import useTheme from '@/use/themeHandler'
import surveyHandler from '@/use/surveyHandler'
import contentGuardHandler from '@/use/contentGuardHandler'
import commentHandler from '@/use/commentHandler'
import attachmentHandler from '@/use/attachmentHandler'
import vabiHandler from '@/use/vabiHandler.js'
import toastHandler from '@/use/toastHandler'
import tabHandler from '@/use/tabHandler'

export default {
  setup() {
    const router = useRouter()
    const route = useRoute()
    const useAddressDetails = addressDetailHandler()
    const useNodeMap = nodeMapHandler()
    const themeHandler = useTheme()
    const useSurvey = surveyHandler()
    const useContentGuard = contentGuardHandler()
    const useComments = commentHandler()
    const useAttachments = attachmentHandler()
    const useVabi = vabiHandler()
    const useToast = toastHandler()
    const tabs = tabHandler()

    onMounted(() => {
      const payload = {
        object_id: route.params.objectId,
      }

      useAddressDetails.getDetails(payload).then(() => {
        // load map
        const geometry = useAddressDetails.address_geometry.value
        const settings = {
          lat: geometry.lat,
          lng: geometry.lng,
          zoom: 19,
          markerType: 'pinMarker',
          nodeId: null,
          geometry: geometry,
          energieKlasse: useAddressDetails.label.value?.[0]?.energieklasse ?? 'default',
        }

        useNodeMap.renderMap('detail_map', 'map_detail', false, settings)

        // load related surveys
        const object_id = useAddressDetails.address.value.object_id
        useSurvey.getRecords(object_id)

        // load related comments
        const commentPayload = {
          commentable_id: object_id,
          commentable_type: 'address',
        }
        useComments.list(commentPayload)

        // load related attachments
        const attachmentPayload = {
          attachable_id: object_id,
          attachable_type: 'address',
        }
        useAttachments.list(attachmentPayload)
      })
    })

    function tagClick() {
      // pass
    }

    function labelStyle(energieklasse) {
      const lookup = {
        'A++++': '!bg-[#33a357] basis-1/2 cursor-pointer hover:!bg-[#33a357]/75 text-white',
        'A+++': '!bg-[#33a357] basis-1/2 cursor-pointer hover:!bg-[#33a357]/75 text-white',
        'A++': '!bg-[#33a357] basis-1/2 cursor-pointer hover:!bg-[#33a357]/75 text-white',
        'A+': '!bg-[#33a357] basis-1/2 cursor-pointer hover:!bg-[#33a357]/75 text-white',
        A: '!bg-[#33a357] basis-1/2 cursor-pointer hover:!bg-[#33a357]/75 text-white',
        B: '!bg-[#79b752] basis-1/2 cursor-pointer hover:!bg-[#79b752]/75 text-white',
        C: '!bg-[#c3d545] basis-1/2 cursor-pointer hover:!bg-[#c3d545]/75 text-white',
        D: '!bg-[#fff12c] basis-1/2 cursor-pointer hover:!bg-[#fff12c]/75 text-white',
        E: '!bg-[#edb731] basis-1/2 cursor-pointer hover:!bg-[#edb731]/75 text-white',
        F: '!bg-[#d66f2c] basis-1/2 cursor-pointer hover:!bg-[#d66f2c]/75 text-white',
        G: '!bg-[#cc232a] basis-1/2 cursor-pointer hover:!bg-[#cc232a]/75 text-white',
      }
      return lookup[energieklasse]
    }

    const labelColor = computed(() => {
      if (useAddressDetails.label.value.length > 0) {
        const energieklasse = useAddressDetails.label.value[0].energieklasse
        return themeHandler.getBackgroundColor(energieklasse)
      }
      return ''
    })

    const labelTextColor = computed(() => {
      if (useAddressDetails.label.value.length > 0) {
        const energieklasse = useAddressDetails.label.value[0].energieklasse
        return themeHandler.getTextColor(energieklasse)
      }
      return ''
    })

    function tableAction(payload) {
      router.push({
        name: 'survey',
        params: { objectId: payload['object_id'] },
      })
    }

    function onOpenAdd() {
      const address_data = useAddressDetails.address.value
      const projectsLookup = parseProjects(address_data.projects)

      const payload = {
        address: address_data.address,
        address_id: address_data.object_id,
        zip: address_data.zip,
        place: address_data.place,
      }

      useSurvey.openAdd(payload, address_data.object_id, projectsLookup)
    }

    function onOpenEdit(data) {
      const address_data = useAddressDetails.address.value
      const projectsLookup = parseProjects(address_data.projects)
      useSurvey.openEdit(data, address_data.object_id, projectsLookup)
    }

    function parseProjects(payload) {
      return payload.map((item) => ({
        label: item.name,
        value: item.object_id,
      }))
    }

    function onDelete(object_id) {
      const address_data = useAddressDetails.address.value
      const address_id = address_data.object_id
      useSurvey.deleteRecord(object_id, address_id)
    }

    const openFilePicker = (type) => {
      const fileTypeSelector = {
        woningtool: 'application/json',
        monitor: 'text/xml',
      }

      const fileInput = document.createElement('input')
      fileInput.type = 'file'
      fileInput.accept = fileTypeSelector[type]

      const handleFileSelection = (event) => {
        const file = event.target.files[0]
        processFile(file, type) // pass the type to processFile
      }

      fileInput.addEventListener('change', handleFileSelection)
      fileInput.click()
    }

    const processFile = (file, type) => {
      const reader = new FileReader()

      reader.onload = (event) => {
        if (type === 'woningtool') {
          const importData = JSON.parse(event.target.result)
          importData.address_id = route.params.objectId
          useSurvey.addForm.sync(importData)
        } else if (type === 'monitor') {
          try {
            const parsedFile = useVabi.parse(event.target.result)
            Object.assign(useSurvey.addForm.data.value, parsedFile)
            useSurvey.addForm.update_errors()
          } catch (error) {
            useToast.addToast('Kan monitoringsbestand niet inlezen', 'danger')
            console.log(error)
          }
        }
      }

      reader.readAsText(file)
    }

    const tabOptions = computed(() => {
      return {
        tabs: [
          { id: 'steps', label: 'Stappen', active: true },
          { id: 'attachments', label: 'Bijlagen', active: true },
          { id: 'comments', label: 'Opmerkingen', active: true },
        ],
      }
    })
    onMounted(() => {
      tabs.create(tabOptions.value)
    })

    return {
      route,
      useAddressDetails,
      useNodeMap,
      tagClick,
      labelColor,
      labelTextColor,
      labelStyle,
      useSurvey,
      tableAction,
      useContentGuard,
      onOpenAdd,
      onDelete,
      useComments,
      openFilePicker,
      onOpenEdit,
      tabs,
    }
  },
}
</script>
