<template>
  <baseModal v-if="handler.show.value" @close="handler.close()" @enter="handler.use()" :title="`Opzoeken`" size="s">
    <span class="w-full">
      <span class="flex flex-row my-1">
        <div class="basis-2/5">
          <label class="block text-gray-500 md:text-right mb-1 md:mb-0 pr-4 py-2 px-4 text-sm">Bouwdeeltype</label>
        </div>
        <div class="basis-3/5 select-none z-10">
          <selectField
            class="w-full"
            :handler="handler"
            :options="selectValues.bouwdeel"
            dataKey="bouwdeel"
            :value="handler.data.value.bouwdeel"
          />
        </div>
      </span>

      <span class="flex flex-row my-1">
        <div class="basis-2/5">
          <label class="block text-gray-500 md:text-right mb-1 md:mb-0 pr-4 py-2 px-4 text-sm">Bouwjaar</label>
        </div>
        <div class="basis-3/5 select-none z-[9]">
          <selectField
            class="w-full"
            :handler="handler"
            :options="selectValues.bouwjaar_standaardisolatie"
            dataKey="bouwjaar"
            :value="handler.data.value.bouwjaar"
          />
        </div>
      </span>
      <span class="flex flex-row my-1">
        <div class="basis-2/5">
          <label class="block text-gray-500 md:text-right mb-1 md:mb-0 pr-4 py-2 px-4 text-sm">Isolatieniveau Rc</label>
        </div>
        <div class="basis-3/5 flex items-center relative">
          <input
            disabled
            class="bg-gray-200 appearance-none border border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-400 text-sm"
            type="text"
            :value="handler.rcWaarde.value"
          />
          <div class="units absolute right-3">
            <span class="units-value text-gray-400 text-sm rounded-xl p-1" id="unitsValue">m²K/W</span>
          </div>
        </div>
      </span>
    </span>

    <template v-slot:buttons>
      <baseButton @click="handler.use()" class="mr-2">{{ $translate('Use') }}</baseButton>
    </template>
  </baseModal>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue'
import selectValues from '@/use/selects/values'
import selectField from '@/components/extended/selectField.vue'

export default {
  props: {
    handler: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const selectOpen = ref(false)
    const selectRef = ref(null)
    const selectsState = ref({})

    const selectRefs = {
      bouwjaar: ref(null),
      bouwdeel: ref(null),
      // ... add more as needed
    }

    function toggleSelect(selectID) {
      selectsState.value[selectID] = !selectsState.value[selectID]
    }

    function clicker(event) {
      for (let selectID in selectsState.value) {
        const selectRefForID = selectRefs[selectID]

        // Check if selectRefForID and selectRefForID.value are not null before proceeding
        if (selectRefForID && selectRefForID.value && !selectRefForID.value.contains(event.target)) {
          if (selectsState.value[selectID]) {
            toggleSelect(selectID)
          }
        }
      }
    }

    function selectOption(value, selectID) {
      props.handler.validate({ target: { value: value } }, 'select', [], selectID)

      // Close the dropdown
      selectsState.value[selectID] = false
    }

    onMounted(() => {
      window.addEventListener('click', clicker)
    })

    onUnmounted(() => window.removeEventListener('click', clicker))

    return {
      toggleSelect,
      selectRef,
      clicker,
      selectValues,
      selectOpen,
      selectRefs,
      selectsState,
      selectOption,
    }
  },
  components: {
    selectField,
  },
}
</script>
