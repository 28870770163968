<template>
  <form class="w-full">
    <div v-for="(field, index) in handler.filteredFields.value" :key="index" class="flex flex-row my-2">
      <div class="basis-2/5">
        <label class="block text-gray-500 md:text-right mb-1 md:mb-0 pr-4 py-2 px-4" :for="field.key">
          {{ $translate(field.label) }}
        </label>
      </div>
      <div class="basis-3/5">
        <slot></slot>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  props: {
    handler: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
