<template>
  <div class="h-full min-h-0">
    <div class="h-full">
      <!-- vertical splitter -->
      <div class="flex h-full flex-col overflow-y-auto">
        <!-- title -->
        <div class="px-20 pt-10">
          <!-- back and title -->
          <div class="flex flex-row justify-between">
            <div class="flex flex-row items-center">
              <basePageTitle :title="useContentGuard.organisation.value" />
            </div>
          </div>
        </div>

        <!-- content container -->
        <div class="flex flex-row min-h-0">
          <div class="px-20 py-10 basis-full">
            <!-- tabs -->
            <baseTabs :handler="tabs" @changed="onTabChange">
              <!-- adrestab -->
              <template v-slot:panel-adressen>
                <div class="flex flex-row">
                  <div class="basis-1/2">
                    <!-- adrestable -->
                    <baseTable
                      :fields="useAddress.fields.value"
                      :records="useAddress.records.value"
                      :records-per-page="25"
                      :add-button="true"
                      :edit-button="false"
                      :delete-button="true"
                      :duplicate-button="false"
                      :export-record-button="false"
                      :clickAble="true"
                      :clickCallback="tableAction"
                      :loading="useAddress.loadHandler.somethingLoading.value"
                      @add="openAdd"
                      @edit="useAddress.openEdit"
                      @delete="useAddress.deleteRecord"
                    />

                    <!-- add adres modal -->
                    <baseModal
                      v-if="useAddress.showAdd.value"
                      @close="useAddress.closeAdd()"
                      :title="$translate('Add')"
                      @enter="useAddress.onEnter()"
                    >
                      <base-geolocator
                        :handler="geoLocatorHandler"
                        class="w-full mb-2"
                        @lookupresult="geolocatorAction"
                      />
                      <div class="w-full h-96 z-0" id="map_pop"></div>
                      <template v-slot:buttons>
                        <baseButton @action="addRecord()" :loading="useAddress.loadHandler.states[`add_record`]">
                          {{ $translate('Add') }}
                        </baseButton>
                      </template>
                    </baseModal>
                  </div>
                  <div class="basis-1/2 ml-2">
                    <!-- <baseCard class="overflow-y-auto mr-4 p-0"> -->
                    <div class="w-full h-96 z-0 rounded shadow-lg bg-white ml-2" id="map_overview"></div>
                    <!-- </baseCard> -->
                  </div>
                </div>

                <!-- edit modal -->
                <!-- <baseModal v-if="showEdit" @close="closeEdit()" :title="$translate('Edit')">
                  <baseForm :handler="editForm" :loading="loadHandler.states['edit_record']" />
                  <template v-slot:buttons>
                    <baseButton @action="editRecord()" :loading="loadHandler.states[`edit_record`]">
                      {{ $translate('Edit') }}
                    </baseButton>
                  </template>
                </baseModal> -->
              </template>

              <template v-slot:panel-projecten>
                <baseTable
                  :fields="useProject.fields.value"
                  :records="useProject.records.value"
                  :records-per-page="25"
                  :add-button="true"
                  :edit-button="false"
                  :delete-button="true"
                  :duplicate-button="false"
                  :export-record-button="false"
                  :clickAble="true"
                  :clickCallback="tableActionProject"
                  :loading="useProject.loadHandler.somethingLoading.value"
                  @add="openAddProject"
                  @edit="useProject.openEdit"
                  @delete="useProject.deleteRecord"
                />

                <!-- add project modal -->
                <baseModal
                  v-if="useProject.showAdd.value"
                  @close="useProject.closeAdd()"
                  :title="$translate('Add')"
                  @enter="useProject.onEnter()"
                >
                  <baseForm :handler="useProject.addForm" :loading="useProject.loadHandler.states['add_record']" />
                  <template v-slot:buttons>
                    <baseButton @action="useProject.addRecord()" :loading="useProject.loadHandler.states[`add_record`]">
                      {{ $translate('Add') }}
                    </baseButton>
                  </template>
                </baseModal>
              </template>

              <template v-slot:panel-opnames>
                <viewSurvey />
              </template>
            </baseTabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, nextTick, ref, computed, watch } from 'vue'
import addressHandler from '@/use/addressHandler'
import contentGuardHandler from '@/use/contentGuardHandler'
import nodeMapHandler from '@/use/nodeMapHandler'
import nodeMapHandlerMultiple from '@/use/nodeMapHandlerMultiple'
import useGeoLocator from '@/use/useGeoLocator'
import router from '@/router'
import tabHandler from '@/use/tabHandler'
import projectHandler from '@/use/projectHandler'
import viewSurvey from './viewSurvey.vue'

export default {
  setup() {
    const useContentGuard = contentGuardHandler()
    // const useToast = toastHandler()
    const tabs = tabHandler()
    const addressMap = ref(null)
    const adresMarker = ref(null)
    const addressData = ref({})

    const overviewMap = ref(null)
    const overviewMapMarkers = ref(null)

    // handlers
    const useAddress = addressHandler()
    const useProject = projectHandler()
    const useNodeMap = nodeMapHandler()
    const useNodeMapMultiple = nodeMapHandlerMultiple()
    const geoLocatorHandler = useGeoLocator()

    function tableAction(payload) {
      router.push({
        name: 'address',
        params: { objectId: payload['object_id'] },
      })
    }

    function tableActionProject(payload) {
      router.push({
        name: 'project',
        params: { objectId: payload['object_id'] },
      })
    }

    function openAdd() {
      useAddress.openAdd()

      nextTick(() => {
        const settings = {
          lat: 52,
          lng: 6,
          zoom: 6,
          markerType: null,
          nodeId: null,
          geometry: false,
        }
        const { map, marker } = useNodeMap.renderMap('map_pop', 'map', false, settings)
        addressMap.value = map
        adresMarker.value = marker
      })
    }

    function openAddProject() {
      useProject.openAdd()
    }

    function geolocatorAction(result) {
      addressData.value = result
      useNodeMap.panMap(addressMap.value, result.geometry[1], result.geometry[0], 19)
      useNodeMap.addSearchMarker(addressMap.value, result.geometry[1], result.geometry[0])
    }

    function addRecord() {
      useAddress.addRecord(addressData.value)
    }

    const tabOptions = computed(() => {
      if (useContentGuard.permissions.value.includes('organisation:proces') ? true : false) {
        return {
          tabs: [
            { id: 'adressen', label: 'Adressen', active: true },
            { id: 'projecten', label: 'Projecten', active: true },
          ],
        }
      }
      return {
        tabs: [{ id: 'opnames', label: 'Opnames', active: true }],
      }
    })

    const recordsLoaded = ref(false)
    const mapInitialized = ref(false)
    const currentTab = ref(null)

    onMounted(() => {
      useAddress.getRecords().then(() => {
        recordsLoaded.value = true // Indicate that records are loaded
        // Assuming `useAddress.records` is reactively updated with this data
      })
      useProject.getRecords()
      tabs.create(tabOptions.value)
    })

    // Reactively watch for the right conditions to initialize the map
    watch(
      [recordsLoaded, currentTab],
      ([newRecordsLoaded, newCurrentTab]) => {
        nextTick(() => {
          if (newRecordsLoaded && newCurrentTab === 'adressen' && !mapInitialized.value) {
            const settings = {
              markerType: 'pinMarker',
              nodes: useAddress.records.value,
            }
            const { map, marker } = useNodeMapMultiple.renderMap('map_overview', 'minimap', false, settings)
            overviewMap.value = map
            overviewMapMarkers.value = marker
            mapInitialized.value = true // Ensure map is only initialized once
          }
        })
      },
      {
        immediate: true, // Run on initial setup
      }
    )

    async function onTabChange(changeInfo) {
      const { source, target } = changeInfo
      currentTab.value = target // This should trigger the watcher if other conditions are met

      if (source === 'adressen') {
        mapInitialized.value = false
      }
    }

    return {
      tableAction,
      useAddress,
      useContentGuard,
      useNodeMap,
      geoLocatorHandler,
      geolocatorAction,
      openAdd,
      addRecord,
      tabs,
      onTabChange,
      useProject,
      openAddProject,
      tableActionProject,
    }
  },
  components: {
    viewSurvey,
  },
}
</script>
