/* eslint-disable no-unused-vars */
import { computed } from 'vue'
import lookupHandler from '@/use/lookupHandler'
import filterHandler from '@/use/filterHandler'

export default (maatregelen, soortForm) => {
  const useFilter = filterHandler()
  const useLookup = lookupHandler()

  const vars = computed(() => {
    if (maatregelen.costs.value) {
      return {
        huidig_eerste_jaar_gas: maatregelen.costs.value.huidig_eerste_jaar_gas,
        nieuw_eerste_jaar_gas: maatregelen.costs.value.nieuw_eerste_jaar_gas,
        huidig_eerste_jaar_warmte: maatregelen.costs.value.huidig_eerste_jaar_warmte,
        nieuw_eerste_jaar_warmte: maatregelen.costs.value.nieuw_eerste_jaar_warmte,
        huidig_eerste_jaar_elektra: maatregelen.costs.value.huidig_eerste_jaar_elektra,
        nieuw_eerste_jaar_elektra: maatregelen.costs.value.nieuw_eerste_jaar_elektra,
        huidig_eerste_jaar_besparing_door_salderen: maatregelen.costs.value.huidig_eerste_jaar_besparing_door_salderen,
        nieuw_eerste_jaar_besparing_door_salderen: maatregelen.costs.value.nieuw_eerste_jaar_besparing_door_salderen,
        huidig_eerste_jaar_besparing_door_boven_salderen:
          maatregelen.costs.value.huidig_eerste_jaar_besparing_door_boven_salderen,
        nieuw_eerste_jaar_besparing_door_boven_salderen:
          maatregelen.costs.value.nieuw_eerste_jaar_besparing_door_boven_salderen,
        return_years: maatregelen.costs.value.return_years,
      }
    }
    return 0
  })

  const huidig_eerste_jaar_elektra_totaal = computed(() => {
    if (maatregelen.costs.value) {
      return vars.value.huidig_eerste_jaar_elektra - vars.value.huidig_eerste_jaar_besparing_door_salderen
    }
    return 0
  })

  const nieuw_eerste_jaar_elektra_totaal = computed(() => {
    if (maatregelen.costs.value) {
      return vars.value.nieuw_eerste_jaar_elektra - vars.value.nieuw_eerste_jaar_besparing_door_salderen
    }
    return 0
  })

  const energiekosten_cumulatief_labels = computed(() => {
    if (maatregelen.costs.value) {
      return Object.keys(vars.value.return_years)
    }
    return []
  })

  const energiekosten_cumulatief_data = computed(() => {
    let huidig_cum = []
    let toekomstig_cum = []
    if (maatregelen.costs.value) {
      energiekosten_cumulatief_labels.value.forEach((label) => {
        huidig_cum.push(vars.value.return_years[label].energiekosten_huidig_cumulatief)
        toekomstig_cum.push(vars.value.return_years[label].energiekosten_nieuw_inclusief_investering)
      })
      return {
        huidig_cum,
        toekomstig_cum,
      }
    }
    return {}
  })

  const options_energiekosten = computed(() => {
    return {
      chart: {
        type: 'column',
      },
      navigation: {
        buttonOptions: {
          enabled: false,
        },
      },
      colors: ['#ffd749', '#949494', '#fc855a', '#904C77'],
      credits: {
        enabled: false,
      },
      title: {
        text: 'Energiekosten eerste jaar',
        align: 'center',
        style: { color: '#949494' },
      },
      xAxis: {
        categories: ['Huidige situatie', 'Nieuwe situatie'],
      },
      yAxis: {
        min: 0,
        title: {
          text: null,
        },
        labels: {
          format: '€{value:,.0f}',
          // format: `${useFilter.filter('value', 'currencyRound')}`,
        },
      },
      legend: {
        align: 'center',
        verticalAlign: 'bottom',
        x: 0,
        y: 0,
        floating: false,
        shadow: false,
      },
      tooltip: {
        // headerFormat: '<b>{point.x}</b><br/>',
        // pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}',
        formatter: function () {
          return `<b> ${this.point.category} </b><br> ${this.series.name}: ${useFilter.filter(
            this.point.y,
            'currencyRound'
          )} <br> Totaal: ${useFilter.filter(this.point.stackTotal, 'currencyRound')}`
        },
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: false,
          },
        },
      },
      series: [
        {
          name: 'Terugleveren boven salderen',
          data: [
            vars.value.huidig_eerste_jaar_besparing_door_boven_salderen,
            vars.value.nieuw_eerste_jaar_besparing_door_boven_salderen,
          ],
          legendIndex: 2,
        },
        {
          name: 'Elektriciteit',
          data: [huidig_eerste_jaar_elektra_totaal.value, nieuw_eerste_jaar_elektra_totaal.value],
          legendIndex: 1,
        },
        {
          name: 'Gas',
          data: [vars.value.huidig_eerste_jaar_gas, vars.value.nieuw_eerste_jaar_gas],
          legendIndex: 0,
        },
        {
          name: 'Warmte',
          data: [vars.value.huidig_eerste_jaar_warmte, vars.value.nieuw_eerste_jaar_warmte],
          legendIndex: 0,
        },
      ],
    }
  })

  const options_energiekosten_cumulatief = computed(() => {
    return {
      navigation: {
        buttonOptions: {
          enabled: false,
        },
      },
      colors: ['#949494', '#fc855a', '#ffd749'],
      credits: {
        enabled: false,
      },
      title: {
        text: 'Energiekosten cumulatief',
        align: 'center',
        style: { color: '#949494' },
      },
      xAxis: {
        categories: energiekosten_cumulatief_labels.value,
      },
      yAxis: {
        min: 0,
        title: {
          text: null,
        },
        labels: {
          format: '€{value:,.0f}',
          // format: `${useFilter.filter('value', 'currencyRound')}`,
        },
      },
      legend: {
        align: 'center',
        verticalAlign: 'bottom',
        x: 0,
        y: 0,
        floating: false,
        shadow: false,
      },
      tooltip: {
        // headerFormat: '<b>{point.x}</b><br/>',
        // pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}',
        formatter: function () {
          return `<b> ${this.point.category} </b><br> ${this.series.name}: ${useFilter.filter(
            this.point.y,
            'currencyRound'
          )}`
        },
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: false,
          },
        },
      },
      series: [
        {
          name: 'Huidig',
          data: energiekosten_cumulatief_data.value.huidig_cum,
          legendIndex: 1,
        },
        {
          name: 'Toekomstig',
          data: energiekosten_cumulatief_data.value.toekomstig_cum,
          legendIndex: 2,
        },
      ],
    }
  })

  function extractTotaal(gegevens, soort) {
    const co2_per_kwh = useLookup.co2_uitstoot.elektriciteit
    const co2_per_m3 = useLookup.co2_uitstoot.gas
    const utistoot_warmtenet = soort.data.value.uitstoot_warmtenet

    let co2_per_gj = useLookup.co2_uitstoot.warmte

    if (utistoot_warmtenet) {
      co2_per_gj = utistoot_warmtenet
    }

    const energiegebruik = {
      totaal_verwarming:
        gegevens.sum_verwarming_kwh +
        gegevens.sum_verwarming_m3 * useLookup.energieinhoud_gas_kwh +
        gegevens.sum_verwarming_gj / useLookup.energieinhoud_warmte_kwh,
      totaal_warm_tapwater:
        gegevens.tapwater_kwh +
        gegevens.tapwater_m3 * useLookup.energieinhoud_gas_kwh +
        gegevens.tapwater_gj / useLookup.energieinhoud_warmte_kwh,
      totaal_koken: gegevens.verbruik_koken_electrisch + gegevens.verbruik_koken_gas * useLookup.energieinhoud_gas_kwh,
      totaal_overig:
        gegevens.verlies_voorraadvat_kwh +
        gegevens.ventilatie_kwh +
        gegevens.verbruik_hulpenergie_verwarming_kwh +
        gegevens.eigen_elektrisch_verbruik,
      totaal_pv: -gegevens.opbrengst_zonnepanelen,
    }

    const co2_uitstoot = {
      totaal_verwarming:
        gegevens.sum_verwarming_kwh * co2_per_kwh +
        gegevens.sum_verwarming_m3 * co2_per_m3 +
        gegevens.sum_verwarming_gj * co2_per_gj,
      totaal_warm_tapwater:
        gegevens.tapwater_kwh * co2_per_kwh + gegevens.tapwater_m3 * co2_per_m3 + gegevens.tapwater_gj * co2_per_gj,
      totaal_koken: gegevens.verbruik_koken_electrisch * co2_per_kwh + gegevens.verbruik_koken_gas * co2_per_m3,
      totaal_overig:
        (gegevens.verlies_voorraadvat_kwh +
          gegevens.ventilatie_kwh +
          gegevens.verbruik_hulpenergie_verwarming_kwh +
          gegevens.eigen_elektrisch_verbruik) *
        co2_per_kwh,
      totaal_pv: -gegevens.opbrengst_zonnepanelen * co2_per_kwh,
    }

    return {
      energiegebruik,
      co2_uitstoot,
    }
  }

  const energiegebruik = computed(() => {
    const gegevens_huidig = maatregelen.formulas.value.result.data_total
    const gegevens_nieuw = maatregelen.formulasNieuw.value.result.data_total

    const totalen_huidig = extractTotaal(gegevens_huidig, soortForm)
    const totalen_nieuw = extractTotaal(gegevens_nieuw, soortForm)

    const totalen_huidig_kwh = totalen_huidig.energiegebruik
    const totalen_nieuw_kwh = totalen_nieuw.energiegebruik
    const totalen_huidig_co2 = totalen_huidig.co2_uitstoot
    const totalen_nieuw_co2 = totalen_nieuw.co2_uitstoot

    return {
      totalen_huidig_kwh,
      totalen_nieuw_kwh,
      totalen_huidig_co2,
      totalen_nieuw_co2,
    }
  })

  const options_energiegebruik = computed(() => {
    return {
      chart: {
        type: 'column',
      },
      navigation: {
        buttonOptions: {
          enabled: false,
        },
      },
      // colors: ['#406691', '#fc855a', '#949494', '#ffd749', '#2C5530'],
      colors: ['#2C5530', '#ffd749', '#949494', '#fc855a', '#406691'],
      credits: {
        enabled: false,
      },
      title: {
        text: 'Energiegebruik',
        align: 'center',
        style: { color: '#949494' },
      },
      xAxis: {
        categories: ['Huidige situatie', 'Nieuwe situatie'],
      },
      yAxis: {
        title: {
          text: null,
        },
        labels: {
          format: '{value} kWh',
          // format: `${useFilter.filter('value', 'currencyRound')}`,
        },
      },
      legend: {
        align: 'center',
        verticalAlign: 'bottom',
        x: 0,
        y: 0,
        floating: false,
        shadow: false,
      },
      tooltip: {
        // headerFormat: '<b>{point.x}</b><br/>',
        // pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}',
        formatter: function () {
          return `<b> ${this.point.category} </b><br> ${this.series.name}: ${useFilter.filter(
            this.point.y,
            'roundDynamic'
          )} kWh`
        },
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: false,
          },
        },
      },
      series: [
        {
          name: 'PV-panelen',
          data: [energiegebruik.value.totalen_huidig_kwh.totaal_pv, energiegebruik.value.totalen_nieuw_kwh.totaal_pv],
          legendIndex: 4,
        },
        {
          name: 'Overig',
          data: [
            energiegebruik.value.totalen_huidig_kwh.totaal_overig,
            energiegebruik.value.totalen_nieuw_kwh.totaal_overig,
          ],
          legendIndex: 3,
        },
        {
          name: 'Koken',
          data: [
            energiegebruik.value.totalen_huidig_kwh.totaal_koken,
            energiegebruik.value.totalen_nieuw_kwh.totaal_koken,
          ],
          legendIndex: 2,
        },
        {
          name: 'Warm tapwater',
          data: [
            energiegebruik.value.totalen_huidig_kwh.totaal_warm_tapwater,
            energiegebruik.value.totalen_nieuw_kwh.totaal_warm_tapwater,
          ],
          legendIndex: 1,
        },
        {
          name: 'Verwarming',
          data: [
            energiegebruik.value.totalen_huidig_kwh.totaal_verwarming,
            energiegebruik.value.totalen_nieuw_kwh.totaal_verwarming,
          ],
          legendIndex: 0,
        },
      ],
    }
  })

  const options_co2 = computed(() => {
    return {
      chart: {
        type: 'column',
      },
      navigation: {
        buttonOptions: {
          enabled: false,
        },
      },
      colors: ['#2C5530', '#ffd749', '#949494', '#fc855a', '#406691'],
      credits: {
        enabled: false,
      },
      title: {
        text: 'CO2 Uitstoot',
        align: 'center',
        style: { color: '#949494' },
      },
      xAxis: {
        categories: ['Huidige situatie', 'Nieuwe situatie'],
      },
      yAxis: {
        title: {
          text: null,
        },
        labels: {
          format: '{value} kg CO2',
          // format: `${useFilter.filter('value', 'currencyRound')}`,
        },
      },
      legend: {
        align: 'center',
        verticalAlign: 'bottom',
        x: 0,
        y: 0,
        floating: false,
        shadow: false,
      },
      tooltip: {
        formatter: function () {
          return `<b> ${this.point.category} </b><br> ${this.series.name}: ${useFilter.filter(
            this.point.y,
            'roundDynamic'
          )} kg CO2`
        },
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: false,
          },
        },
      },
      series: [
        {
          name: 'PV-panelen',
          data: [energiegebruik.value.totalen_huidig_co2.totaal_pv, energiegebruik.value.totalen_nieuw_co2.totaal_pv],
          legendIndex: 4,
        },
        {
          name: 'Overig',
          data: [
            energiegebruik.value.totalen_huidig_co2.totaal_overig,
            energiegebruik.value.totalen_nieuw_co2.totaal_overig,
          ],
          legendIndex: 3,
        },
        {
          name: 'Koken',
          data: [
            energiegebruik.value.totalen_huidig_co2.totaal_koken,
            energiegebruik.value.totalen_nieuw_co2.totaal_koken,
          ],
          legendIndex: 2,
        },
        {
          name: 'Warm tapwater',
          data: [
            energiegebruik.value.totalen_huidig_co2.totaal_warm_tapwater,
            energiegebruik.value.totalen_nieuw_co2.totaal_warm_tapwater,
          ],
          legendIndex: 1,
        },
        {
          name: 'Verwarming',
          data: [
            energiegebruik.value.totalen_huidig_co2.totaal_verwarming,
            energiegebruik.value.totalen_nieuw_co2.totaal_verwarming,
          ],
          legendIndex: 0,
        },
      ],
    }
  })

  return {
    options_energiekosten,
    options_energiekosten_cumulatief,
    options_energiegebruik,
    options_co2,
  }
}
