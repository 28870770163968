<template>
  <span>
    <!-- top bar -->
    <div class="flex flex-row justify-between">
      <!-- add button / export button-->
      <baseButton class="px-4 my-2" v-if="addButton" @action="onAdd()" :disabled="loading">+</baseButton>
      <baseButton class="px-4 my-2" v-if="exportButton" @action="onExport()" :loading="exporting"
        >Exporteren</baseButton
      >

      <p v-else></p>
      <!-- search -->
      <div class="relative px-4 py-3 bg-white shadow-md text-sm text-gray-500 my-2">
        <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
          </svg>
        </div>

        <input v-model="searchQuery" class="pl-8 focus:outline-none w-40" placeholder="zoeken" />
      </div>
    </div>

    <!-- table -->
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead class="text-xs text-gray-700 bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <!-- normal header cells -->
            <th
              scope="col"
              class="px-6 py-3 cursor-pointer"
              v-for="(field, index) in filteredFields"
              :key="index"
              @click="onSort(field, index)"
            >
              <div v-if="sortable" class="flex">
                <span class="my-auto">{{ $translate(field.label) }}</span>
                <chevronupdown v-if="index === sortFieldIndex" class="h-5 w-5 min-w-5 min-h-5 ml-2 my-auto" />
                <chevronupdown v-else class="h-5 w-5 ml-2 my-auto text-gray-400" />
              </div>
              <div v-else>
                {{ $translate(field.label) }}
              </div>
            </th>

            <!-- action header cell -->
            <th v-if="showActions" scope="col" class="px-6 py-3 w-60">Acties</th>
          </tr>
        </thead>
        <tbody>
          <!-- row looper -->
          <tr
            @click="onClick(record)"
            :class="rowStyle + ' ' + isSelected(record)"
            class="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
            v-for="(record, index) in filteredRecordsPaginated"
            :key="index"
          >
            <!-- normal cells -->
            <td class="px-6 py-4" v-for="(field, index) in filteredFields" :key="index">
              <span v-if="field.type === 'date'">{{ $filter.dateFromEpochDay(record[field.key]) }}</span>
              <span v-else>{{ record[field.key] }}</span>
            </td>

            <!-- action cells -->
            <td v-if="showActions" class="px-6 py-4 w-60">
              <span v-if="exportRecordButton">
                <baseButton
                  v-if="addButton"
                  size="small"
                  @action="onExportRecord(record)"
                  class="mr-2 my-0"
                  :disabled="loading"
                >
                  <featherIcon class="w-4 h-4" icon="DownloadIcon" />
                </baseButton>
              </span>
              <span v-if="duplicateButton">
                <baseButton
                  v-if="addButton"
                  size="small"
                  @action="onDuplicate(record[keyField])"
                  class="mr-2 my-0"
                  :disabled="loading"
                >
                  <DuplicateIcon class="h-4 w-4" />
                </baseButton>
              </span>
              <span v-if="editButton">
                <baseButton
                  v-if="addButton"
                  size="small"
                  @action="onEdit(record, index)"
                  class="mr-2 my-0"
                  :disabled="loading"
                >
                  <PencilIcon class="h-4 w-4" />
                </baseButton>
              </span>
              <span v-if="deleteButton">
                <baseButton
                  v-if="addButton"
                  size="small"
                  @action="onDelete(record[keyField], index)"
                  class="my-0"
                  variant="danger"
                  :disabled="loading"
                >
                  <TrashIcon class="h-4 w-4" />
                </baseButton>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- no data box -->
      <span v-if="showNoRecords && !loading" class="flex justify-center bg-white">
        <span class="py-6 text-center text-xs text-gray-700">Geen gegevens gevonden</span>
      </span>

      <!-- loader -->
      <span v-if="loading" class="flex justify-center bg-white">
        <span class="py-6 text-center text-xs text-gray-700">
          <baseLoader :loading="loading" />
        </span>
      </span>
    </div>

    <!-- pagination -->
    <div class="flex flex-row-reverse ...">
      <div
        :class="onLastPage ? 'bg-gray-100 cursor-not-allowed' : 'bg-white'"
        class="px-4 py-3 bg-white shadow-md text-sm text-gray-500 my-2 cursor-pointer"
        @click="movePpage(1)"
      >
        {{ '>' }}
      </div>
      <div class="px-4 py-3 bg-white shadow-md text-sm text-gray-500 my-2 mx-2">{{ pageIndex + 1 }}/{{ lastPage }}</div>
      <div
        v-if="!onFirstPage"
        class="px-4 py-3 bg-white shadow-md text-sm text-gray-500 cursor-pointer my-2"
        @click="movePpage(-1)"
      >
        {{ '<' }}
      </div>
    </div>

    <!-- confirmer -->
    <confimationModal :handler="confirm" />
  </span>
</template>

<script>
import { computed, ref } from 'vue'
import { TrashIcon, PencilIcon, DuplicateIcon } from '@heroicons/vue/outline'
import confirmationHandler from '@/use/confirmationHandler'
import confimationModal from '@/components/extended/confirmationModal.vue'
import chevronupdown from '@/components/extended/chevronUpDown.vue'

export default {
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
    records: {
      type: Array,
      default: () => [],
    },
    pagination: {
      type: Boolean,
      default: true,
    },
    search: {
      type: Boolean,
      default: true,
    },
    searchType: {
      type: String,
      default: 'all',
    },
    recordsPerPage: {
      type: Number,
      default: 10,
    },
    addButton: {
      type: Boolean,
      default: false,
    },
    editButton: {
      type: Boolean,
      default: false,
    },
    deleteButton: {
      type: Boolean,
      default: false,
    },
    duplicateButton: {
      type: Boolean,
      default: false,
    },
    exportButton: {
      type: Boolean,
      default: false,
    },
    exportRecordButton: {
      type: Boolean,
      default: false,
    },
    keyField: {
      type: String,
      default: 'object_id',
    },
    clickAble: {
      type: Boolean,
      default: false,
    },
    clickCallback: {
      type: Function,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    exporting: {
      type: Boolean,
      default: false,
    },
    hoverEffect: {
      type: Boolean,
      default: false,
    },
    sortable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['add', 'edit', 'delete', 'duplicate', 'export', 'export_record'],
  setup(props, { emit }) {
    const pageIndex = ref(0)
    const searchQuery = ref('')
    let sortFieldIndex = ref(null)
    let sortField = ref({})
    let sortAsc = ref(true)
    const selected = ref({})

    // handlers
    const confirm = confirmationHandler()

    // fields filter
    const filteredFields = computed(() => {
      return props.fields.filter((field) => {
        if (field.table === false) {
          return false
        }
        return true
      })
    })

    // filter
    const filteredRecords = computed(() => {
      // check if records need filtering
      if (searchQuery.value) {
        const searchQueryLower = searchQuery.value.toLowerCase()
        const queryResult = filterByValue(props.records, searchQueryLower)
        return queryResult
      } else {
        return props.records
      }
    })

    // sort
    const sortedRecords = computed(() => {
      // check if records need filtering
      if (sortFieldIndex.value || sortFieldIndex.value === 0) {
        const key = sortField.value.key
        const type = sortField.value.type
        const copy = JSON.parse(JSON.stringify(filteredRecords.value))
        return sort(copy, key, type)
      } else {
        return JSON.parse(JSON.stringify(filteredRecords.value))
      }
    })

    // pagination
    const filteredRecordsPaginated = computed(() => {
      if (props.pagination) {
        return paginate(sortedRecords.value)
      } else {
        return sortedRecords.value
      }
    })

    // checks if on first page
    const onFirstPage = computed(() => {
      return pageIndex.value === 0
    })

    // checks if on last page
    const onLastPage = computed(() => {
      return filteredRecords.value.length / props.recordsPerPage < pageIndex.value + 1
    })

    // checks what last page's index is
    const lastPage = computed(() => {
      const lastpageNumber = filteredRecords.value.length / props.recordsPerPage
      return lastpageNumber ? Math.ceil(lastpageNumber) : 0
    })

    // mutates pageindex, used by up and down buttons
    function movePpage(direction) {
      if (direction === -1 && !onFirstPage.value) {
        pageIndex.value += direction
      } else if (direction === 1 && !onLastPage.value) {
        pageIndex.value += direction
      }
    }

    // search filter
    function filterByValue(array, string) {
      try {
        return array.filter((record) =>
          props.fields.some((field) => {
            if (field.type === 'string') {
              return record[field.key]?.toLowerCase().includes(string)
            }
          })
        )
      } catch (error) {
        console.log(error)
      }
    }

    // paginaton filter
    function paginate(source) {
      const startIndex = pageIndex.value * props.recordsPerPage
      const endIndex = startIndex + props.recordsPerPage
      return source.slice(startIndex, endIndex)
    }

    // sort
    function sort(source, key, type) {
      let returnValue = []
      if (type === 'string') {
        returnValue = source.sort((a, b) => (a[key].toLowerCase() > b[key].toLowerCase() ? 1 : -1))
      } else {
        returnValue = source.sort((a, b) => (a[key] > b[key] ? 1 : -1))
      }

      if (!sortAsc.value) {
        return returnValue.reverse()
      } else {
        return returnValue
      }
    }

    // show actions column
    const showActions = computed(() => {
      return props.editButton || props.deleteButton
    })

    // show no records message
    const showNoRecords = computed(() => {
      if (props.records.length > 0) {
        return false
      }
      return true
    })

    // add button emitter
    function onAdd(prefill) {
      if (props.addButton) {
        emit('add', prefill)
      }
    }

    // edit button emitter
    function onEdit(record, index) {
      if (props.editButton) {
        emit('edit', record, index)
      }
    }

    // edit button emitter
    function onDuplicate(id) {
      if (props.duplicateButton) {
        emit('duplicate', id)
      }
    }

    // export row emitter
    function onExportRecord(payload) {
      if (props.exportRecordButton) {
        emit('export_record', payload)
      }
    }

    // delete button emitter
    async function onDelete(id, index) {
      if (props.deleteButton) {
        const ok = await confirm.open({
          title: 'Object verwijderen?',
          message: 'deleteObject',
        })
        if (ok) {
          emit('delete', id, index)
        }
      }
    }

    // export emitter
    function onExport() {
      if (props.exportButton) {
        emit('export', {
          records: sortedRecords.value,
          fields: filteredFields.value,
        })
      }
    }

    // selected
    function isSelected(record) {
      const identifier = record[props.keyField]
      if (identifier in selected.value) {
        return '!bg-blue-100'
      } else {
        return 'bg-white'
      }
    }

    // clickable
    // clickable
    const rowStyle = computed(() => {
      let style = ''
      if (props.clickAble) {
        style += 'cursor-pointer'
      }

      if (props.hoverEffect) {
        style += ' hover:bg-gray-100'
      }

      return style
    })

    // row click event
    // function onClick(payload) {
    //   if (props.clickAble) {
    //     props.clickCallback(payload)
    //   }
    // }

    function onClick(record) {
      const identifier = record[props.keyField]
      if (props.selectable) {
        if (identifier in selected.value) {
          delete selected.value[identifier]
        } else {
          selected.value[identifier] = record
        }
        // emit selected values so external components can hook into it
        emit('selection', selected)
      } else {
        if (props.clickAble) {
          props.clickCallback(record)
        }
      }
    }

    function onSort(field, index) {
      sortField.value = field
      if (sortFieldIndex.value === index) {
        sortAsc.value = !sortAsc.value
      } else {
        sortFieldIndex.value = index
        sortAsc.value = true
      }
    }

    return {
      filteredRecords,
      filteredRecordsPaginated,
      pageIndex,
      movePpage,
      onFirstPage,
      onLastPage,
      lastPage,
      searchQuery,
      onAdd,
      onEdit,
      onDelete,
      showActions,
      showNoRecords,
      filteredFields,
      confirm,
      onClick,
      rowStyle,
      onDuplicate,
      onExport,
      sortFieldIndex,
      onSort,
      sortedRecords,
      onExportRecord,
      isSelected,
    }
  },
  components: {
    confimationModal,
    TrashIcon,
    PencilIcon,
    DuplicateIcon,
    // ChevronUpIcon,
    chevronupdown,
  },
}
</script>
