import { createStore } from 'vuex'
import general from './general/index.js'

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    general,
  },
})
