<template>
  <span class="mb-4">
    <p class="text-xl mt-4 text-slate-500 font-medium">{{ title }}</p>
    <p v-if="subtitle" class="text-slate-500 text-s">{{ subtitle }}</p>
    <slot></slot>
  </span>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
  setup() {},
}
</script>
