import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'
import formHandler from '@/use/formHandler'
import { ref, computed } from 'vue'

export default () => {
  const useApi = apiHandler()
  const loadHandler = loadingHandler()
  const manualForm = formHandler()
  const organisation = ref({})

  const manualnDetailFields = ref([
    {
      label: 'Handleiding',
      key: 'manual',
      type: 'file',
      accept: '.pdf',
      required: true,
    },
  ])

  function getDetails(payload) {
    loadHandler.setLoadingState('get_manual_details', true)
    useApi.request('get', 'manual', 'get_details', payload).then((response) => {
      manualForm.create({
        type: 'edit',
        fields: manualnDetailFields.value,
        data: response.data.data,
      })

      loadHandler.setLoadingState('get_manual_details', false)
    })
  }

  function uploadTemplate() {
    loadHandler.setLoadingState('upload_template', true)
    const formData = manualForm.data.value
    let payload = new FormData()
    payload.append('file', formData.manual)
    const headers = {
      'Content-Type': 'multipart/form-data',
    }
    useApi.request('post', 'manual', 'upload', payload, headers).then(() => {
      loadHandler.setLoadingState('upload_template', false)
    })
  }

  const canSave = computed(() => {
    if (manualForm.errors.value.length > 0) {
      return false
    }
    return true
  })

  // const org_name = computed(() => {
  //   if ('display_name' in organisation.value) {
  //     return organisation.value.display_name
  //   } else {
  //     return ''
  //   }
  // })

  return {
    getDetails,
    loadHandler,
    manualForm,
    organisation,
    uploadTemplate,
    manualnDetailFields,
    canSave,
  }
}
