<template>
  <span>{{ `${data.description} (${data.productie} kWh)` }}</span>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
