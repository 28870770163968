import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'
import formHandler from '@/use/formHandler'
import { ref, computed } from 'vue'

export default () => {
  const useApi = apiHandler()
  const loadHandler = loadingHandler()
  const organisationForm = formHandler()
  const organisation = ref({})

  const organisationDetailFields = ref([
    {
      label: 'Template',
      key: 'template',
      type: 'file',
      accept: '.docx',
    },
    {
      label: 'Template basic',
      key: 'template_basic',
      type: 'file',
      accept: '.docx',
    },
  ])

  function getDetails(payload) {
    loadHandler.setLoadingState('get_organisation_details', true)
    useApi.request('post', 'surveys', 'get_organisation_details', payload).then((response) => {
      organisation.value = response.data.data
      organisationForm.create({
        type: 'edit',
        fields: organisationDetailFields.value,
        data: response.data.data,
      })

      loadHandler.setLoadingState('get_organisation_details', false)
    })
  }

  function updateOrganisation() {
    loadHandler.setLoadingState('update_organisation', true)
    const formData = organisationForm.data.value
    let payload = new FormData()

    if (typeof formData.template === 'object') {
      payload.append('file', formData.template)
    }

    if (typeof formData.template_basic === 'object') {
      payload.append('file_basic', formData.template_basic)
    }

    if (formData.template === '') {
      payload.append('delete_file', 'true')
    }

    if (formData.template_basic === '') {
      payload.append('delete_file_basic', 'true')
    }

    // payload.append('file_basic', formData.template_basic)
    payload.append('organisation', organisation.value.id)
    const headers = {
      'Content-Type': 'multipart/form-data',
    }
    useApi.request('post', 'surveys', 'update_organisation', payload, headers).then(() => {
      loadHandler.setLoadingState('update_organisation', false)
    })
  }

  const org_name = computed(() => {
    if ('display_name' in organisation.value) {
      return organisation.value.display_name
    } else {
      return ''
    }
  })

  return {
    loadHandler,
    organisationForm,
    getDetails,
    organisation,
    org_name,
    updateOrganisation,
  }
}
