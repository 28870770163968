import { ref } from 'vue'

export default () => {
  let tabs = ref([])
  let selected = ref(null)

  // create
  function create(options) {
    tabs.value = options.tabs
    if (tabs.value.length > 0) {
      selected.value = tabs.value[0].id
    }
  }

  function onSelect(tabId) {
    selected.value = tabId
  }

  return {
    create,
    tabs,
    selected,
    onSelect,
  }
}
