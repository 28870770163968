<template>
  <base-button class="shadow-none" @action="pressed()">{{ text }}</base-button>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
  },
  emits: ['pressed'],
  setup(props, { emit }) {
    function pressed() {
      emit('pressed', props.text)
    }

    return {
      pressed,
    }
  },
}
</script>
