<template>
  <div class="h-full min-h-0">
    <div class="h-full">
      <!-- vertical splitter -->
      <div class="flex h-full flex-col overflow-y-auto">
        <!-- title -->
        <div class="px-20 pt-10">
          <!-- back and title -->
          <div class="flex flex-row justify-between">
            <div class="flex flex-row items-center">
              <span class="mr-6">
                <backButton />
              </span>
              <basePageTitle
                :title="`Niet gevonden`"
                :subtitle="'Dit is waar we het vergeten portret van Erica Terpstra bewaren. Niet wat je zoekt? Ga dan terug naar de startpagina of probeer een andere URL'"
              />
            </div>
          </div>

          <!-- glitterplaatjes -->
          <div class="flex flex-row min-h-0">
            <img alt="404" :src="require('@/assets/et.jpg')" class="object-scale-down my-4" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
