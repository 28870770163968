import { ref } from 'vue'

export default () => {
  let dataShadow = ref('')

  function sync(currentData) {
    dataShadow.value = JSON.stringify(currentData)
  }

  function changed(currentData) {
    return dataShadow.value === JSON.stringify(currentData) ? false : true
  }

  return {
    sync,
    changed,
  }
}
