<template>
  <span class="my-auto w-full">
    <span class="flex flex-row">
      <!-- input box -->
      <input
        disabled
        :value="useFilter.dateFromEpochDay(value)"
        class="bg-white appearance-none border border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-400 text-sm disabled:bg-gray-100"
      />
      <!-- add button -->
      <baseButton :disabled="disabled" @action="handler.openEdit(value)" size="small">
        <featherIcon :class="`w-4 h-4`" icon="CalendarIcon" />
      </baseButton>
    </span>

    <!-- add modal -->
    <baseModal
      v-if="handler.showEditmodal.value"
      @close="handler.closeEdit()"
      :title="`${$translate('Edit')}`"
      :size="modalSize"
    >
      <baseForm :handler="handler.editForm" />
      <template v-slot:buttons>
        <baseButton @click="add()" class="mr-2">{{ $translate('Edit') }}</baseButton>
      </template>
    </baseModal>
  </span>
</template>

<script>
import { computed } from 'vue'
import inputDateHandler from '@/use/inputDateHandler'
import filterHandler from '@/use/filterHandler'

export default {
  props: {
    keyField: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    fieldConfig: {
      type: Object,
      default: () => {},
    },
    value: {
      type: Number,
      default: 0,
    },
    displayTemplate: {
      type: Object,
      default: () => {},
    },
    modalSize: {
      type: String,
      default: 'm',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['add', 'remove', 'itemClick', 'edit'],
  setup(props, { emit }) {
    // handler
    const handler = inputDateHandler()
    const useFilter = filterHandler()

    function add() {
      const payload = JSON.parse(JSON.stringify(handler.getResult()))
      emit('add', payload)
      handler.closeEdit()
    }

    function remove(index) {
      emit('remove', index)
    }

    function edit(payload) {
      const emit_payload = JSON.parse(JSON.stringify(payload))
      emit('edit', handler.editIndex.value, emit_payload)
      handler.closeEdit()
    }

    function clicker(index, value) {
      emit('itemClick', index, value)
    }

    const disabledStyle = computed(() => {
      if (props.disabled) {
        return 'bg-gray-100'
      }
      return ''
    })

    return {
      open,
      close,
      add,
      handler,
      remove,
      clicker,
      disabledStyle,
      edit,
      useFilter,
    }
  },
}
</script>
