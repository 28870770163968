import { computed, reactive } from 'vue'

export default () => {
  let states = reactive({})

  function setLoadingState(processName, processStatus) {
    states[processName] = processStatus
  }

  const somethingLoading = computed(() => {
    return Object.values(states).includes(true)
  })

  return {
    states,
    setLoadingState,
    somethingLoading,
  }
}
