<template>
  <input
    :id="dataKey"
    :disabled="handler.loading.value"
    class="bg-white appearance-none border border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-400"
    type="text"
    :value="handler.data.value[dataKey]"
    @input="handler.validate($event, 'string', [], dataKey)"
  />
</template>

<script>
export default {
  props: {
    handler: {
      type: Object,
      default: () => {},
    },
    dataKey: {
      type: String,
      default: '',
    },
  },
}
</script>
