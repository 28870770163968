<template>
  <div>
    <div class="flex items-center text-gray-500">
      <!-- Checkbox Styling -->
      <input
        v-if="!node.container"
        type="checkbox"
        :checked="node.checked"
        @change="handleCheck(node)"
        class="mr-2 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
      />
      <!-- Label Styling with Expand/Collapse Icon -->
      <span @click.stop="handleToggleVisibility(node)" class="flex items-center cursor-pointer select-none">
        <span v-if="node.container" class="text-lg font-bold">{{ node.label }}</span>
        <span v-if="!node.container">{{ node.label }}</span>
      </span>
      <svg
        v-if="node.children && node.children.length"
        :class="{ 'rotate-90': !node.collapsed }"
        class="fill-current text-gray-500 mr-2 transition-transform transform"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        width="20"
        height="20"
      >
        <path d="M6 6L14 10L6 14V6Z" />
      </svg>
    </div>
    <!-- Children Styling -->
    <ul v-if="!node.collapsed" class="pl-2">
      <baseTreeNode
        v-for="child in node.children"
        :key="child.id"
        :node="child"
        @updateNode="handleCheck"
        @toggleVisibility="handleToggleVisibility"
        class="mb-1"
      />
    </ul>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    node: {
      type: Object,
      required: true,
    },
  },
  emits: ['updateNode', 'toggleVisibility'],
  setup(props, { emit }) {
    const handleCheck = (node) => {
      emit('updateNode', node)
    }

    function handleToggleVisibility(node) {
      emit('toggleVisibility', node)
    }

    return {
      handleCheck,
      handleToggleVisibility,
    }
  },
})
</script>
