<template>
  <!-- calculation -->
  <input
    :class="requiredAndFilled(field) ? 'bg-white border-gray-200' : 'border-gray-200 border-l-sky-300 border-l-[6px]'"
    disabled
    v-if="field.type === 'calculation'"
    class="bg-white appearance-none border border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:border-gray-400 text-xl disabled:bg-gray-100 disabled:border-gray-200"
    :id="field.key"
    type="text"
    :value="handler.data.value[field.key]"
    @input="handler.validate($event, 'calculation', [], field.key)"
  />

  <!-- string -->
  <!-- number -->
  <!-- percentage -->
  <input
    v-show="dependentCheck(field)"
    :class="requiredAndFilled(field) ? 'bg-white border-gray-200' : 'border-gray-200 border-l-sky-300 border-l-[6px]'"
    v-if="['number', 'string', 'percentage'].includes(field.type)"
    :disabled="handler.loading.value || disabled || field.disabled"
    class="bg-white appearance-none border border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:border-gray-400 text-sm disabled:bg-gray-100 disabled:border-gray-200"
    :id="field.key"
    :value="handler.data.value[field.key]"
    type="text"
    :placeholder="field.placeholder ? field.placeholder : ''"
    @input="handler.validate($event, field.type, [], field.key)"
  />

  <!-- textarea -->
  <textarea
    v-if="field.type === 'textarea'"
    v-show="dependentCheck(field)"
    :disabled="handler.loading.value || disabled || field.disabled"
    :value="handler.data.value[field.key]"
    @input="handler.validate($event, field.type, [], field.key)"
    class="resize-y rounded-md bg-white appearance-none border border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:border-gray-400 text-sm disabled:bg-gray-100 disabled:border-gray-200"
  ></textarea>

  <!-- select -->
  <div class="w-full select-none" v-if="field.type === 'select'" v-show="dependentCheck(field)">
    <selectField
      class="w-full"
      :handler="handler"
      :options="field.options"
      :dataKey="field.key"
      :value="handler.data.value[field.key]"
      :disabled="handler.loading.value || field.disabled"
      :required="!requiredAndFilled(field)"
      :errorLevel="errorLevel"
    />
  </div>

  <!-- subtitle -->
  <span v-show="dependentCheck(field)" class="text-gray-500 text-sm" v-if="field.type === 'subtitle'">
    {{ field.label }}
  </span>

  <!-- list -->
  <baseListInput
    v-if="field.type === 'list'"
    :disabled="handler.loading.value || disabled"
    :values="handler.data.value[field.key]"
    :handler="handler"
    :keyField="field.key"
    :fieldConfig="field.fieldConfig"
    :displayTemplate="field.displayTemplate"
    :modalSize="field.modalSize"
    :clickToEdit="field.clickToEdit"
    @add="(...args) => handler.addListItem(field.key, ...args)"
    @remove="(...args) => handler.removeListItem(field.key, ...args)"
    @edit="(...args) => handler.editListItem(field.key, ...args)"
    @duplicate="(...args) => handler.duplicateListItem(field.key, ...args)"
  />

  <!-- area -->
  <span v-if="field.type === 'area'" v-show="dependentCheck(field)" class="flex w-full">
    <input
      :class="requiredAndFilled(field) ? 'bg-white border-gray-200' : 'border-gray-200 border-l-sky-300 border-l-[6px]'"
      :disabled="handler.loading.value || disabled"
      class="bg-white appearance-none border border-gray-200 rounded-l w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-400 text-sm"
      :id="field.key"
      type="text"
      :value="handler.data.value[field.key]"
      @input="handler.validate($event, 'number', [], field.key)"
    />

    <baseButton class="rounded-r ml-1" @action="calculatorHandler.open(handler, field.key)" size="small"
      ><CalculatorIcon class="h-5 w-5"
    /></baseButton>
  </span>

  <!-- insulatation -->
  <span v-if="field.type === 'insulation'" v-show="dependentCheck(field)" class="flex w-full">
    <input
      :class="requiredAndFilled(field) ? 'bg-white border-gray-200' : 'border-gray-200 border-l-sky-300 border-l-[6px]'"
      :disabled="handler.loading.value || disabled"
      class="bg-white appearance-none border border-gray-200 rounded-l w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-400 text-sm"
      :id="field.key"
      type="text"
      :value="handler.data.value[field.key]"
      @input="handler.validate($event, 'number', [], field.key)"
    />

    <baseButton class="rounded-r ml-1" @action="useStandaardIsolatie.open(handler, field.key)" size="small"
      ><CalculatorIcon class="h-5 w-5"
    /></baseButton>
  </span>

  <!-- file -->
  <span v-if="field.type === 'file'" class="flex">
    <input
      :class="requiredAndFilled(field) ? 'bg-white border-gray-200' : 'border-gray-200 border-l-sky-300 border-l-[6px]'"
      disabled
      class="bg-white appearance-none border border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-400 text-sm"
      type="string"
      :value="parsedFilename(handler.data.value[field.key])"
    />

    <!-- uploadbutton -->
    <label :for="`${field.key}`" class="cursor-pointer bg-sky-700 px-2 py-1.5 text-xs text-white rounded ml-1">
      <featherIcon class="w-5 h-5" icon="UploadIcon"
    /></label>

    <!-- delete button -->
    <base-button
      class="rounded-r ml-1"
      variant="danger"
      size="small"
      :disabled="parsedFilename(handler.data.value[field.key]) ? false : true"
      @action="handler.removeData(field.key)"
      ><featherIcon class="w-5 h-5" icon="XIcon" />
    </base-button>

    <input
      :class="requiredAndFilled(field) ? 'bg-white border-gray-200' : 'border-gray-200 border-l-sky-300 border-l-[6px]'"
      :disabled="handler.loading.value || disabled"
      class="hidden"
      :id="`${field.key}`"
      type="file"
      :accept="field.accept"
      @input="handler.validate($event, 'file', [], field.key)"
    />
  </span>

  <!-- toggle -->
  <span v-if="field.type === 'toggle' && dependentCheck(field)">
    <label class="relative inline-flex items-center cursor-pointer">
      <input
        :disabled="handler.loading.value || disabled"
        type="checkbox"
        class="sr-only peer"
        :checked="handler.data.value[field.key] === true ? true : false"
        @input="handler.validate($event, 'check', [], field.key)"
      />
      <div
        class="my-auto w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-sky-700"
      ></div>
    </label>
  </span>
</template>

<script>
import { CalculatorIcon } from '@heroicons/vue/outline'
import useCalculator from '@/use/calculatorHandler'
// import useCalculator from '@/use/calcualtorHandler2'
import { ref, onMounted, onUnmounted, computed } from 'vue'
import themeHandler from '@/use/themeHandler'
import standaardIsolatieHandler from '@/use/standaardIsolatieHandler'
import contentGuardHandler from '@/use/contentGuardHandler'
import selectField from '@/components/extended/selectField.vue'

export default {
  props: {
    handler: {
      type: Object,
      default: () => {},
    },
    field: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
    errorLevel: {
      type: String,
      default: 'info',
    },
  },
  setup(props) {
    const calculatorHandler = useCalculator()
    const selectOpen = ref(false)
    const selectRef = ref(null)
    const useTheme = themeHandler()
    const useStandaardIsolatie = standaardIsolatieHandler()
    const useContentGuard = contentGuardHandler()

    const rotatedStyle = computed(() => {
      return selectOpen.value ? 'rotate-180' : ''
    })

    function requiredAndFilled(field) {
      if (field.required) {
        const fieldValue = props.handler.data.value[field.key]
        if (fieldValue !== undefined && fieldValue !== null && fieldValue !== '') {
          return true
        }
        return false
      }
      return true
    }

    function dependentCheck(field) {
      // Check if user has necessary permissions based on canSeeKey
      if (field.can_see && !useContentGuard.canSee(field.can_see)) {
        return false // Hide the field if user doesn't have the necessary permissions
      }

      const checkValues = field.dependent_show_values
      if (checkValues) {
        const dependentShowFieldKey = field.dependent_show_field_key
        const dependentShowFieldType = field.dependent_show_field_type
        const dependentValue = props.handler.data.value[dependentShowFieldKey]

        // Ensure dependentValue is not undefined or null
        if (dependentValue !== undefined && dependentValue !== null) {
          // Handle 'select' type differently
          if (dependentShowFieldType === 'select' && checkValues.includes(dependentValue.value)) {
            return true
          } else if (checkValues.includes(dependentValue)) {
            return true
          }
        }
        return false
      }
      return true
    }

    function parsedFilename(value) {
      if (value) {
        if (typeof value.name === 'string') {
          return value.name
        } else {
          return value
        }
      } else return value
    }

    function toggleSelect() {
      selectOpen.value = !selectOpen.value
    }

    function clicker(event) {
      if (selectRef.value) {
        if (!selectRef.value.contains(event.target)) {
          if (selectOpen.value) {
            toggleSelect()
          }
        }
      }
    }

    function getBackgroundColour(variant) {
      if (!props.disabled) {
        return useTheme.getBackgroundColor(variant)
      }
      return useTheme.getBackgroundColor('disabled')
    }

    onMounted(() => {
      window.addEventListener('click', clicker)
    })

    onUnmounted(() => window.removeEventListener('click', clicker))

    return {
      calculatorHandler,
      requiredAndFilled,
      dependentCheck,
      parsedFilename,
      toggleSelect,
      selectOpen,
      selectRef,
      getBackgroundColour,
      useStandaardIsolatie,
      rotatedStyle,
    }
  },
  components: {
    CalculatorIcon,
    selectField,
  },
  // Animatie code
  methods: {
    beforeEnter(el) {
      el.style.maxHeight = '0'
      el.style.overflow = 'hidden'
    },
    enter(el, done) {
      el.style.maxHeight = el.scrollHeight + 'px'
      el.addEventListener('transitionend', () => {
        el.style.overflow = 'visible'
        done()
      })
    },
    beforeLeave(el) {
      el.style.maxHeight = el.scrollHeight + 'px'
      el.style.overflow = 'hidden'
    },
    leave(el, done) {
      el.style.maxHeight = '0'
      el.addEventListener('transitionend', () => {
        el.style.overflow = ''
        done()
      })
    },
  },
}
</script>
