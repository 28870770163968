import { ref } from 'vue'
import selectValues from '@/use/selects/values'
import formHandler from '@/use/formHandler'
import useValidate from '@/use/validationHandler'

export default () => {
  let data = ref({})
  let selectedDate = ref()
  let showEditmodal = ref(false)
  const today = new Date()
  const editForm = formHandler()
  const validator = useValidate()

  const fieldConfig = ref([
    {
      label: 'Day',
      key: 'day',
      type: 'number',
    },
    {
      label: 'Month',
      key: 'month',
      type: 'select',
      options: selectValues.months,
    },
    {
      label: 'Year',
      key: 'year',
      type: 'number',
    },
  ])

  function openEdit(data) {
    const date = new Date(data)
    const day = date.getDate()
    const month = date.getMonth()
    const year = date.getFullYear()

    const payload = {
      day,
      month,
      year,
    }

    editForm.create({
      type: 'edit',
      fields: fieldConfig.value,
      data: payload,
    })
    showEditmodal.value = true
  }

  function closeEdit() {
    showEditmodal.value = false
    reset()
  }

  function getDaysInMonth(year, month) {
    return new Date(year, month, 0).getDate()
  }

  function add(key, value) {
    data.value[key] = value
  }

  function reset() {
    data.value = {}
  }

  function getResult() {
    const day = editForm.data.value.day
    const month = editForm.data.value.month
    const year = editForm.data.value.year
    const date = new Date(year, month, day).getTime()
    return date
  }

  function validate(event, type, rules, key) {
    const input = event.target.value

    // validatorguard
    if (validator.validate(input, type)) {
      data.value[key] = input
    } else {
      if (data.value[key] != undefined) {
        event.target.value = data.value[key]
      } else {
        event.target.value = null
      }
    }
  }

  return {
    selectedDate,
    today,
    getDaysInMonth,
    openEdit,
    closeEdit,
    showEditmodal,
    editForm,
    validate,
    add,
    reset,
    getResult,
  }
}
