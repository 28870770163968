<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 150.84 135.27"
    style="enable-background: new 0 0 150.84 135.27"
    xml:space="preserve"
  >
    <g id="XMLID_15_">
      <g id="XMLID_2423_">
        <defs>
          <path
            id="XMLID_927_"
            d="M105.9,6.52c3.46,5.21,4.92,10.76,2.75,16.9c0.23,0.13,0.31-0.04,0.41-0.14
          c3.21-3.07,4.28-6.79,3.24-11.09c-0.86-3.56-2.93-6.39-5.59-8.82c-1.3-1.19-2.71-2.23-4.22-3.14c-0.58-0.35-0.63-0.32-0.79,0.35
          c-0.45,1.88-1.07,3.7-1.68,5.53c-0.67,2.01-1.09,4.06-0.86,6.2c0.44,4.11,2.22,7.52,5.32,10.25c0.39,0.34,0.82,0.63,1.22,0.97
          c0.4,0.34,0.63,0.22,0.82-0.23c0.88-1.99,1.32-4.08,1.43-6.24c0.19-3.6-0.53-7.03-2.14-10.26c-0.04-0.07-0.05-0.15-0.08-0.23
          C105.79,6.54,105.84,6.53,105.9,6.52z"
          />
        </defs>
        <clipPath id="XMLID_00000039854379185273840560000012889608774366482060_">
          <use xlink:href="#XMLID_927_" style="overflow: visible" />
        </clipPath>
        <g id="XMLID_1618_" style="clip-path: url(#XMLID_00000039854379185273840560000012889608774366482060_)">
          <g>
            <path
              style="fill: #181c13"
              d="M-19.21,364.2c-49.45,0-98.9-0.14-148.35,0.16c-7.09,0.04-10.02-0.81-9.69-9.13
            c0.78-20.02,0.25-40.08,0.25-60.13c9.16-4.05,11.93,0.4,11.73,8.8c-1.73,7.95,2.57,11.95,9.11,8.7
            c10.68-5.31,19.46-2.13,28.65,2.53c2.59,0.8,5.18,0.8,7.79,0c7.28-2,10.36,2.57,12.79,8.15c3.29,4.19,7.65,5.17,12.67,4.24
            c7.1,2.65,9.9-0.34,9.66-7.34c-0.24-7-0.15-13.91,5-19.53c0.83-1.46,1.64-2.94,2.89-4.11c5.41-3.01,7.36-7.31,4.87-13.24
            c-0.13-1.1-0.14-2.21-0.07-3.31c0.08-1.1,0.34-2.16,0.74-3.18c2.31-2.48,2.07-5.86,3.01-8.82c0.91-2.64,0.89-5.37,0.86-8.11
            c2.63-6.36-4.7-15.88,4.61-19.58c8.15-3.24,12.4,6,18.68,9.1c3.52,1.73,6.59,4.6,9.12,7.82c0.69,1.7,0.72,3.42,0.1,5.15
            c-5.76,3.64-3.48,10.33-5.76,15.28c-0.25,1.72-2.06,3.15-0.97,5.13c0.32-2.31,0.97-4.55,1.52-6.8c1.87-3.5-0.42-8.23,3.28-11.2
            c6.76-1.69,10.96,2.52,11.71,7.8c1.94,13.69,13.46,24.87,10.09,39.81c-0.37,1.64,0.36,3.98,2.01,3.96
            c7.95-0.08,15.66,5.36,23.8,0.84c10.62-5.89,10.52-14.11-0.51-27.13c0.52-8.82-5.93-18.53,3.28-26.26
            c3.52-1.58,6.6,0.06,9.73,1.33c2.29,1.39,3.71,3.5,4.81,5.86c0.12,5.23,1.03,10.27,3.72,14.88c5.13,6.56,3.45,13.9,2.46,21.17
            c2.04,5.79,1.79,11.03-3.15,15.5c-3.44,3.12-5.58,6.95-4.46,11.89c0.82,4.63,4.63,8.74,2.41,13.9
            c-2.65,4.24-6.32,5.94-11.23,4.48c-1.68-1.29-1.74-3.5-2.83-5.13c-0.44-0.66-0.79-1.38-1.14-2.1c0.21,0.85,0.88,1.53,1.05,2.35
            c1.88,9.19-4.7,7.08-9.62,7.2c-6.35-1.81-11.32-6.21-17.83-10.41C-13.99,352.16-12.22,360-19.21,364.2z"
            />
            <path
              style="fill: #1b2115"
              d="M229.8,364.2c-27.12,0-54.24,0-81.36,0c-2.23-5.36-9.16-4.15-12.05-8.59
            c-5.85-9.47-2.09-15.88,6.5-20.67c-0.49-1.31-1.62-1.4-2.78-1.51c-16.6-0.66-31.37-14.94-32.26-31.12
            c-0.03-7.49-8.3-11.12-8.64-18.47c-3.64-7.46-4.39-13.86,5.92-16.21c4.79,0.01,7.76,3.48,11.25,5.94
            c4.09,2.07,4.43,6.1,6.02,9.87c2.52,5.96,4.4,1.34,5.8-1.56c1.45-2.48,3.91-4.04,5.59-6.3c3.32-1.71,4.35-5.06,5.76-8.15
            c0.84-0.88,1.82-1.54,2.96-1.97c14.28,2.28,18.47,9.25,12.82,22.12c-0.98,2.24-1.73,4.32-1.85,6.56
            c3.69,0.95,6.42-3.97,10.23-1.63c5.4,2.51,5.22,8.88,8.79,12.58c0.03-0.11,0.67,0.82,0.67,0.82s0.9-0.11,0.84-0.27
            c-8.66-11.75-11.03-26.33-18.37-38.57c-1.45-2.41-0.15-5.63,2.06-7.88c3.24-2.46,6.72-2.31,10.3-0.94
            c6.69,1.93,13.65-0.59,16.15-5.46c2.5-4.86-3.28-10.22-6.53-14.86c-1.54-3.14-0.93-5.89,1.43-8.34
            c0.94-0.75,2.01-1.29,3.16-1.64c6.36-0.12,12.39,1.18,18,4.29c13.28,2.09,14.44,3.52,14.81,18.06
            c2.35,6.41,1.21,12.36-2.35,17.98c-1.4,2.87-2.14,5.81-0.99,8.97c0.66,3.1,2.23,5.56,5.09,7.09
            c11.57,4.24,15.31,12.64,12.94,24.32c-3.13,5.62-5.81,10.97-2.74,17.93c2.27,5.13,1.32,10.35-5.09,12.54
            c0.16,0.07-0.85,0.75-0.65,0.81c-0.24,2.9,2.06,4.85,2.6,7.37c1.57,3.56,4.65,5.93,6.91,8.94c0.86,1.58,1.19,3.25,0.91,5.03
            C231.24,362.39,230.63,363.37,229.8,364.2z"
            />
            <path
              style="fill: #202817"
              d="M-177,216.12c0-8.23,0-16.45,0-24.68c11.5-2.49,16.13-9.5,13.83-21.09
            c1.68-14.68,8.43-17.92,20.08-9.7c2.21,3.35,5.64,6.04,6.01,10.32c0.21,0.36,0.55,0.75,1.1,0.77c3.33-3.5,6.98-6.27,11.36-8.02
            c1.87-0.39,3.69-0.24,5.47,0.44c7.26,3.06,15.9,4.16,18.89,13.29c3.77,15.2,11.2,28.03,24.11,37.34
            c2.36,1.7,3.68,4.24,4.97,6.77c0.46,1.08,0.69,2.22,0.74,3.39c-0.83,7.88-5.81,12.99-12.75,14.87
            c-8.58,2.32-9.78,6.94-7.79,14.52c1.08,4.11,2.83,8.94-2.35,12.1c-4.62,0.86-8.59-1.29-9.96-5.06
            c-4.29-11.78-12.2-19.84-23.16-25.32c-0.95-7.98,2.81-12.37,10.47-13.75c8.92-3.82,5.88-8.77,1.27-13.79
            c-2.71-0.26-4.98,0.96-7.28,2.06c-1.95,1.15-3.92,2.24-6.29,2.13c-9.14-1.69-18.04-1.27-26.47,3.11
            C-162.16,217.15-169.51,221.96-177,216.12z"
            />
            <path
              style="fill: #313f1c"
              d="M20.24-188.63c9.86,0,19.72,0,29.59,0c2.17,4.91,6.3,5.42,10.88,4.78
            c5.8-0.81,8.81,2.25,10.46,7.29c1.37,1.92,3.29,2.09,5.41,1.68c6.75-0.67,10.14-6.3,14.83-10.01c1.05-0.5,2.15-0.82,3.29-1.01
            c8.31,6.49,16.55,2.22,24.8-0.18c4.1-0.07,8.39-0.2,9.84,4.78c1.34,4.6-1.45,8.09-4.81,10.14
            c-6.76,4.13-10.64,10.77-15.69,16.37c-0.96,0.74-2.04,1.27-3.21,1.58c-7.77,0.19-15.29-3.75-23.2-1.42
            c-2.26,0.53-4.38,0-6.45-0.88c-2-0.87-3.97-1.83-6.13-2.29c-6.05-2.52-11.92-5.52-18.44-6.75c-3.26-0.23-7.37-1.33-9.41,1.26
            c-8.29,10.5-21.29,16.78-26.64,29.88c-0.84,0.93-1.83,1.64-2.98,2.15c-4.96,0.84-9.91,1.13-14.84-0.19
            c-3.29-1.25-4.93-6.34-9.7-4.12c-6.14,2.91-9.96,8.97-16.14,11.82c-6.15,3.4-10.75,0.2-15.22-3.56
            c-1.51-2.03-2.59-4.29-3.59-6.61c-0.61-4.3-3.2-8.25-2.25-12.83c0.52-1.86,1.54-3.38,3.04-4.58c3.35-1.15,6.21-3.11,8.82-5.47
            c13.03-5.6,13.03-5.6,10.61-23.03c1.22-1.44,2.81-2.15,4.64-2.44c1.78-0.18,3.57-0.26,5.34-0.44
            c5.01-1.64,10.36-0.52,15.41-1.86C5.16-189.06,14.12-181.15,20.24-188.63z"
            />
            <path
              style="fill: #242c19"
              d="M229.8,364.2c-0.02-0.8-0.02-1.6-0.01-2.39c4.1-10.2,12.93-12.71,22.56-13.96
            c0.26-0.03,0.71-0.03,1.19-0.3c2.19-1.2-0.46-1.5-0.52-2.36c-5.83-11.49,5.35-16.61,9.01-24.55c0.84-1.53,1.99-2.77,3.45-3.72
            c1.01-0.49,2.07-0.86,3.16-1.14c5.24-1.48,10.25-6.03,16.53,0.3c-1.96-6.2-7.05-4.05-9.68-6.48c-3.68-3.48-1.8-6.42,0.88-9.28
            c12.79-11.58,13.05-11.53,25.99,4.56c5.95,6.51,9.6,15.12,17.91,19.47c5.22,2.98,8.56,7.74,11.89,12.51
            c4.56,5.38,1.47,9.4-2.19,13.27c-0.23,4.45,2.82,1.58,3.79,1.33c3.17-0.81,6.19-2.21,9.11,0.57c3.31,3.97,3.1,8.05,0.36,12.19
            c-13.97,0-27.94,0-41.91,0c-4.72-1.81-10.64-1.37-13.26-7.06c-5.78-5.78-2.44,3.48-4.95,3.06c-3.03,3.52-7.94,1.65-11.37,4
            C257.74,364.2,243.77,364.2,229.8,364.2z"
            />
            <path
              style="fill: #27331b"
              d="M-157.39,210.81c9.7-4.18,19.64-4.75,29.8-2.02c2.73,5.67-4.17,8.16-4.4,12.96
            c13.71-5.71,15.43-5.32,19.12,4.22c-7.04-0.37-9.99,4.4-12.37,9.88c-3.43,11.78-3.4,24.99-15.17,33.19
            c-3.56,2.48-5.7,7.11-5.93,11.76c-0.36,7.47-2.69,14.32-5.28,21.21c-1.68,4.48-1.57,10.37-7.45,11.61
            c-6.52,1.37-6.74-6.06-10.51-8.68c1.78-6.49-1.52-9.15-7.42-9.84c0-21.39,0-42.78,0-64.17c1.45-7.21,6.45-9.77,13.16-9.81
            C-156.14,221.07-155.32,216.89-157.39,210.81z"
            />
            <path
              style="fill: #2c3a1c"
              d="M-177,8.81c0-10.69,0-21.39,0-32.08c4.83-8.04,8.61-16.2,2.68-25.39
            c-0.26-4.21,1.33-7.29,5.37-8.87c4.37-0.74,8.53,0.84,12.8,1.15c1.12,0.18,2.2,0.55,3.21,1.07c2.4,1.65,4.62,4.59,7.82,1.18
            c4.32-4.98,6.59-11.23,10.49-16.46c12.25-16.41,17-16.94,31.43-3.33c4.24,1.75,7.65,5.07,12.12,6.41
            c6.35,6.19,1.21,12.18-0.28,18.23c-1.68,5.65,0.64,9.3,5.79,11.54c5.72,4.02,6.68,9.89,5.58,16.07
            c-1.14,6.39-6.24,9.81-11.35,6.23c-7.14-5-3.14,2.13-4.47,3.22c-3.38,6.44-8.49,6.17-14.34,3.93
            c-5.43-2.08-7.08-5.72-6.11-11.32c0.39-2.26-0.34-4.76-1.14-7.04c-2-4.36-1.18-9.15-1.72-13.1c1,3.23-0.76,7.45,1.22,11.19
            c2.38,12.87,1.73,13.49-11.4,10.82c-2.92-1.44-4.7-3.77-5.42-6.93c-1.72-2.93,2.89-8.34-3.73-9.22c-2.5,0.99-4.59,2.6-6.89,3.77
            c-2.61,0.97-4.01,3.5-6.27,4.94c-3.6,2.01-6.97,4.43-6.85,9.01c0.17,6.58-4.16,10.98-7,16.14C-167.2,5.69-169.27,10.97-177,8.81
            z"
            />
            <path
              style="fill: #2b3b1b"
              d="M375.26,127.28c0,16.45,0,32.91,0,49.36c-6.23,4.94-3.29,9.87,0,14.81c0,5.76,0,11.52,0,17.28
            c-8.04,1.27-7.18-10.01-14.26-10.1c-3.54-3.46-5.98-7.49-6.45-12.53c-4.96,6.72-9.7,4.03-13.41-0.97
            c-2.37-3.2-3.39-7.39-8.51-7.03c-2.7-0.43-5.23,0.48-7.81,1.04c-1.62,0.27-3.22,0.1-4.83-0.05c-5.55,0.36-4.81,3.91-4.28,7.79
            c0.91,6.67-1.23,11.87-8.1,14.26c-7.23-4.87-13.29,2.31-20.11,2c-8.63-6.98-10.09-15.29-4.62-24.89
            c2.1-6.19,8.54-9.14,11.13-14.97c4.05-5.12,9.27-6.69,15.49-5.18c13.38,1.77,4.71-8.34,6.75-12.68c0.91-3.03,2.82-5.17,5.64-6.5
            c7.42-0.32,10.89-9.96,19.22-8.22c3.59,2.72,7.46,5.03,11.25,7.47c2.89,4.02,0.94,9.94,5.05,12.98
            c-3.2-4.04-2.66-8.69-0.4-13.42c0.41-1.1,0.97-2.11,1.69-3.03C366.27,120.97,366.27,120.97,375.26,127.28z"
            />
            <path
              style="fill: #31451e"
              d="M375.26,107.53c0,3.29,0,6.58,0,9.87c-1.31,1.29-2.86,2.01-4.73,1.92
            c-8.09-6.8-17.2-2.14-25.89-2.27c-2.34,0.58-4.62,1.42-7.08,1.31c-2.69-0.05-4.98-0.98-6.78-3.02
            c-1.36-2.16-2.79-4.29-3.63-6.73c-1.46-2.92-2.1-5.89-0.42-8.95c3.07-4.37,9.24-3.11,12.72-7.22
            c-4.69-4.43-8.11-9.63-7.04-16.68c0.39-3.25,2.48-5.28,4.96-7.02c0.89-0.72,1.87-1.32,2.91-1.81c3.86-1.77,8.64-2.68,8.02-8.64
            c0.73-9.48,5.73-13.54,15.17-11.95c3.19,2.13,6.05,4.61,7.76,8.16c0.54,2.57,2.16,4.43,4.04,6.14c0,1.65,0,3.29,0,4.94
            c-2.73,2.47-3.08,4.94,0,7.4c0,9.87,0,19.74,0,29.62c-1.66,1.84-3.75,2.48-6.18,2.2C371.42,105.09,373.64,105.64,375.26,107.53z
            "
            />
            <path
              style="fill: #34461d"
              d="M-152.35-188.63c9.04,0,18.08,0,27.12,0c2.44,3.35,3.9,6.88,2.09,11.02
            c-2.8,3.38-3.94,7.25-3.41,11.63c-1.95,5.94,0.97,10.5,4.25,14.97c0.52,2.48,0.27,4.84-1.11,7.02
            c-7.23,5.24-15.49,6.22-24.09,5.79c-0.93-0.76-1.65-1.7-2.13-2.8c-0.95-3.45,0.82-7.2-1.16-10.65c-3.49,0.3-5.59,2.62-7.47,5.24
            c-2.4,4.23-5.57,7.36-10.8,5.35c-5.69-2.19-4.56-6.74-3.3-11.29c1.37-4.95-0.88-8.64-4.65-11.62c0-6.17,0.84-12.49-0.21-18.47
            c-1.37-7.83,3.53-6.09,7.6-6.21C-163.85-184.72-158.1-181.85-152.35-188.63z"
            />
            <path
              style="fill: #212517"
              d="M343.21-188.63c4.11,0,8.22,0,12.33,0c9.33,8.99,9.43,21.16,10.14,32.62
            c0.63,10.1,2.62,19.09,9.58,26.61c0,6.58,0,13.16,0,19.74c-10.03,6.86-18.43,2.7-26.47-3.91c-1.81-3.01-1.53-6.36-1.53-9.66
            c-4.04-12.05-0.77-25.13-5.2-37.14c-2.86-7.01,1.82-14.79-2.26-21.68C339.69-184.89,341.42-186.78,343.21-188.63z"
            />
            <path
              style="fill: #636b26"
              d="M-43.79-149.46c-1.04,0.91-2.08,1.82-3.12,2.73c-0.49,0.26-0.98,0.52-1.46,0.79
            c-1.09-0.21-2.04-0.71-2.86-1.44c-1.58-2.76-3.56-5.08-6.8-5.98c-5.08-3.41-10.33-6.69-12.05-13.18
            c-0.06-1.77,0.51-3.33,1.58-4.72c4.88-3.06,8.21-6.95,7.04-13.26c0.45-1.7,1.61-2.91,2.8-4.11c5.75,0,11.51,0,17.26,0
            c1.64,1.94,3.29,1.74,4.93,0c5.75,0,11.51,0,17.26,0c2.7,2.88,9.44,4.72,2.61,10.08c-1.86-0.13-3.73-0.27-5.59-0.4
            c-1.45-1.45-3.42-0.73-5.1-1.24c0.24,0.7,0.54,1.38,0.89,2.03c6.01,1.76,12.53,3.97,9.32,11.95
            c-3.26,8.1-8.83,14.43-19.19,12.29C-39.8-154.16-41.67-151.6-43.79-149.46z"
            />
            <path
              style="fill: #252a19"
              d="M375.26-107.19c0,27.15,0,54.3,0,81.44c-3.08,2.49-6.13,2.51-9.15-0.09
            c-6.36-20.36-10.86-41.15-14.09-62.26c-0.12-1.4-0.23-2.78-0.34-4.18c0.25-6.93-3.58-15.82,8.27-17.1
            C364.92-107.65,370.41-109.65,375.26-107.19z"
            />
            <path
              style="fill: #273019"
              d="M375.26,304.97c0,7.4,0,14.81,0,22.21c-0.44,6.39-13.59,8.22-6.21,17.41
            c0.97,1.59,1.39,3.3,1.32,5.15c-0.98,3.53-1.96,7.07-5.93,8.51c-8.62,1.08-10.08-7.72-15.43-11.2
            c-3.76-3.53-4.8-8.32-5.99-13.02c-2.73-6.39-12.68-3.86-14.32-11.58c0-10.89,5.28-16.49,16.33-16.25
            c4.01,0.39,7.4-2.17,11.29-2.39c2.34-0.12,4.67-0.29,7-0.58C367.63,301.61,371.8,300.88,375.26,304.97z"
            />
            <path
              style="fill: #64753a"
              d="M-177,8.81c4.85-2.46,8.21-5.92,7.83-11.84c1.97-2.49,4.09-1.74,6.25-0.32
            c3.72,12.64,14.56,9.84,23.65,10.87c2.68,2.06,3.56,4.7,2.63,7.95c-2.71,4.12-3.8,8.05-2.04,13.32
            c1.74,5.21,1.65,11.13-3.41,15.38c-8.11,2.18-8.87-7.89-14.98-8.95c-1.01-0.65-2.04-1.07-3.06,0.09
            c-1.28,7.19,6.5,12.2,4.44,19.44c-0.48,3.48-0.1,7.04-1.05,10.46c-8.83,6.91-9.42,6.7-17.06-5.92
            c0.72-6.82-0.48-13.5-1.49-20.19c-2.58-4.93,3.65-10.85-1.7-15.48C-177,18.68-177,13.75-177,8.81z"
            />
            <path
              style="fill: #262e18"
              d="M106.7-157.17c2.76-7.87,6.23-15.06,14.72-18.51c2.03-0.82,5.09-2.4,4.43-5.15
            c-0.88-3.62-4.63-2.41-7.3-2.87c-1.98-1.09-3.72-2.29-2.16-4.93c6.57,0,13.15,0,19.72,0c1.56,1.58,3.21,3.11,3.46,5.51
            c0.18,3.25,0.49,6.5,0.51,9.76c-0.64,4.2,3.48,6.78,3.65,10.7c0.05,4.65,0.91,9,4.53,12.35c0.55,1.09,0.85,2.25,0.97,3.46
            c0.69,6.6-4.15,11.14-6.16,16.74c-9.5,3.86-13.34,2.15-16-7.09c-1.16-4.02,0.86-9.49-5.15-11.29
            C117.09-151.78,108.31-148.24,106.7-157.17z"
            />
            <path
              style="fill: #424825"
              d="M375.26-129.4c-10.52-1.98-15.8-8.01-14.79-19.01c1.25-13.76-5.19-26.58-4.93-40.22
            c19.72,0,19.72,0,19.72,19.74C375.26-155.72,375.26-142.56,375.26-129.4z"
            />
            <path
              style="fill: #3e5723"
              d="M-177-60.29c0-7.4,0-14.81,0-22.21c0.78-0.79,1.62-1.49,2.52-2.12
            c8.05,1.11,12.82-4.56,18.24-8.88c7.89-3.03,3.5-10.92,6.28-15.98c0.67-1.05,1.53-1.9,2.55-2.62
            c12.73-2.69,16.42-0.85,22.04,10.96c0.79,5.83-5.94,8.24-6.17,13.63c-2.34,5.6-7.94,9.54-8.17,16.22
            c-2.18,6.97-6.53,10.79-14.17,9.79C-161.63-62.3-169.3-61.02-177-60.29z"
            />
            <path
              style="fill: #1f2416"
              d="M138.93,354.34c0.37,0.13,0.8,0.19,1.11,0.41c4.05,2.92,6.28-6.73,9.88-1.81
            c2.49,3.4,0.11,7.62-1.48,11.27c-18.08,0-36.16,0-54.24,0c-1.19-10.9,3.55-19.05,11.81-25.59c4.3-2.39,9.18-1.12,13.69-2.19
            C131.65,336.43,133.79,346.99,138.93,354.34z"
            />
            <path
              style="fill: #3c5322"
              d="M-177-94.85c0-12.34,0-24.68,0-37.02c4.31-5.18,9.6-6.07,15.65-3.59
            c6.78,1.68,7.32,9.21,12.07,12.82c3.07,3.87,4.59,8.01,1.96,12.76l-0.41,0.67c-2.99,5.5-1.57,14.13-10.77,15.23
            c-4.19,0.14-8.12,1.64-12.22,2.32C-173.4-91.56-175.23-93.14-177-94.85z"
            />
            <path
              style="fill: #3c4c2b"
              d="M-145.05,164.51c-2.85-3.04-5.69-6.09-10.39-3.28c-4.43,2.65-4.86,6.11-2.13,10.24
            c-4.64,14.74-8.29,17.57-19.43,15.04c0-3.29,0-6.58,0-9.87c2.47-2.06,3.59-4.89,4.4-7.88c0.96-3.07-0.46-6.24,0.3-9.33
            c0.36-1.71,1.18-3.17,2.38-4.42c4.05-3,8.4-5.64,11.7-9.57c7.54-5.25,9.24-17.22,20.64-18.06c1.2,0.09,2.34,0.44,3.42,0.98
            c1.02,0.69,2.35,1.5,2.5,2.6c1.49,10.96,0.71,22.03,1.38,33.07C-135.06,168.44-140.11,164.81-145.05,164.51z"
            />
            <path
              style="fill: #bccf43"
              d="M362.96,48.76c-3.1,3.28-12.94-0.26-9.59,9.52c-2.58,5.75-8.97,7.6-12.59,12.28l-0.03,0.1
            c-10.75,2.31-12.87-3.89-12.45-12.6c0.15-1.1,0.53-2.15,1.06-3.12c0.97-1.51,2.67-2.38,3.59-3.9c-0.18-0.83-0.28-1.6-0.57-2.41
            c-1.22-2.7-0.41-5.3,0.08-7.93c1.34-5.18,5.73-6.47,9.99-7.92c3-0.63,6.04-0.49,9.07-0.51c5.5,1.82,9.16-3.7,14.24-3.64
            c4.93,0.15,6.41,4.77,9.5,7.33c0,1.65,0,3.29,0,4.94C371.53,44.09,370.3,51.22,362.96,48.76z"
            />
            <path
              style="fill: #95a664"
              d="M180.49-188.63c3.29,0,6.57,0,9.86,0c2.3,3.99,6.72,5.57,9.84,8.66
            c1.58,1.96,2.53,4.16,2.71,6.69c-0.34,3.17-1.1,6.2-3.16,8.75c-4.79,4.29-10.3,6.79-16.84,6.64c-5.15-1.16-8.89,5.1-14.29,2.73
            c-3.98-2.39-4.85-6.02-4.09-10.29c0.24-2.56,1.23-4.85,2.55-7.01C169.93-179.19,171.4-187.08,180.49-188.63z"
            />
            <path
              style="fill: #4b5a2a"
              d="M-172.11,58.33c2.21,1.95,9.47-1.94,6.53,6.45c-1.02,2.92,3.35,1.49,5.46,0.9
            c3.26-0.35,5.67,1.14,7.67,3.53c0.99,1.53,1.22,3.28,1.41,5.04c0.81,5.08,3.93,10.09,0.61,15.36c-2.3,3.13-5.28,4.97-9.29,4.75
            c-5.5-3.47-13.92-1.43-17.28-9.03c0-11.52,0-23.03,0-34.55C-171.22,50.6-171.46,54.33-172.11,58.33z"
            />
            <path
              style="fill: #131510"
              d="M-19.21,364.2c-0.09-4.71,1.76-9.15-0.73-14.22c-2.54-5.16-0.02-10.59,5.18-13.38
            c5.11-2.74,6.78,2.28,9.47,5.14c3.32,3.53,7.08,6.65,10.65,9.95c0.21,0.95,0.48,1.88,0.81,2.8c0.14,0.47,0.27,0.94,0.42,1.4
            c0.58,2.75,3.63,5.11,1.32,8.31C-1.13,364.2-10.17,364.2-19.21,364.2z"
            />
            <path
              style="fill: #bec587"
              d="M284.04-188.63c1.64,0,3.29,0,4.93,0c3.15,3.17,6.26,6.37,9.72,9.23
            c2.85,2.81,6.69,4.91,6.95,9.6c-2.03,5.25-3.46,10.77-6.7,15.51c-5.41,2.52-6.15,9.88-12.17,11.79
            c-1.26,0.19-2.47,0.04-3.66-0.42c-2.17-1.43-3.96-3.21-5.17-5.54c-0.97-2.47-2.24-4.84-2.42-7.57
            C271.74-168.64,279.71-178.16,284.04-188.63z"
            />
            <path
              style="fill: #27331a"
              d="M-159.41-131.68c-5.86-0.06-11.73-0.12-17.59-0.18c0-10.69,0-21.39,0-32.08
            c7.27,0.65,16.32-0.32,9.79,11.92c-1.13,2.12-4.72,5.45-1.35,7.24c5.02,2.68,5.06-3.29,7.05-5.79
            c7.85-1.87,12.44,1.33,14.1,9.08l-0.09,1.06c1.04,6.92-5.88,6.32-8.88,9.43C-157.49-130.82-158.49-131.06-159.41-131.68z"
            />
            <path
              style="fill: #528553"
              d="M17.84,352.33c3.23-1.89,6.45-3.78,9.68-5.67c7.16-5.47,14.52-4.69,21.97-0.72
            c0.46,3.7-1.06,7.74,6,7.87c5.68,0.11,11.73,2.33,17.4,4.39c2.72,0.99,5.42,2.17,4.05,6.01c-13.97,0-27.94,0-41.91,0
            c-5.78-3.62-12.25-3.47-18.74-3.82c-4.76-0.26-10.86,1.63-12.96-5.41l-0.45-1.05C7.75,352.23,12.95,353.72,17.84,352.33z"
            />
            <path
              style="fill: #32391b"
              d="M-107.97-188.63c5.75,0,11.51,0,17.26,0c2.62,4.88,8.91,7.85,7.73,14.74
            c-1.68,3.89,0.3,7.82-0.07,11.74c-0.69,3.38-2.58,5.64-6,6.47c-4.36,0.06-8.72,2.53-13.1,0.04c-1.5-1.13-2.76-2.52-3.93-3.98
            C-109.87-169.07-118.74-178.21-107.97-188.63z"
            />
            <path
              style="fill: #39451c"
              d="M-107.97-188.63c-2.24,9.83,1.5,18.59,4.88,27.41c-2.79,3.15-5.92,3.07-9.24,1.05
            c-1.24-1.77-2.42-1.27-3.59,0.28c-6.57,3.02-10.92,1.21-12.9-5.81c-0.64-4.94,0.73-9.32,3.65-13.28
            c-2.03-3.21-1.88-6.43-0.06-9.66C-119.47-188.63-113.72-188.63-107.97-188.63z"
            />
            <path
              style="fill: #465723"
              d="M375.26,213.66c0,10.69,0,21.39,0,32.08c-5.8,2.33-9.61-0.45-12.75-4.95
            c-5.85-6.17-0.29-16.7-8.43-22.01c-2.32-1.7-4.52-3.49-5.42-6.39c-0.57-5.32-1.35-10.71,3.84-14.51
            c1.72-0.73,3.46-0.69,5.22-0.18C367.7,198.46,371.34,206.22,375.26,213.66z"
            />
            <path
              style="fill: #a2ab5d"
              d="M284.04-188.63c1.98,11.79-7.55,22.03-4.55,33.96c-0.26,1.73-0.51,3.47-1.32,5.06
            c-6.7,3.82-7,11.49-10.38,17.33c-2.34,1.6-4.73,2.2-7.26,0.43c-2.79-2.66-2.35-6.12-2.38-9.47c0.03-3.83-0.77-7.79,1.6-11.28
            c3.79-3.57,5.79-7.94,5.73-13.22c-0.61-9.74,6.14-15.86,11.17-22.82C279.11-188.63,281.58-188.63,284.04-188.63z"
            />
            <path
              style="fill: #3d5222"
              d="M-157.39,210.81c9.75,2.58,7.93,11.66,13.33,19.34c-13.22-4.17-23.33-6.01-32.94,0.78
            c0-4.94,0-9.87,0-14.81C-170.84,212.96-164.05,212.14-157.39,210.81z"
            />
            <path
              style="fill: #7e8860"
              d="M-177,137.15c0-10.69,0-21.39,0-32.08c1.88-1.99,1.28-8.07,6.76-4.1
            c2.26,3.13,3.06,7.19,6.27,9.72c9.74,18.06,9.49,20.42-3.52,33.09C-173.25,145.29-174.29,140.03-177,137.15z"
            />
            <path
              style="fill: #3f4827"
              d="M343.21-188.63c0.04,2.03,0.08,4.05,0.11,6.08c-1.94,2.42-1.17,6.02-3.58,8.24
            c-2.44,1.92-6.02,2.09-7.39,5.71c-3.47,9.13-8.7,6.01-13.96,1.7c-1.33-1.42-2.46-2.97-3.08-4.84
            c-0.2-5.66-4.97-11.52,0.78-16.89C325.13-188.63,334.17-188.63,343.21-188.63z"
            />
            <path
              style="fill: #455220"
              d="M276.65-188.63c2.96,9.85-8.9,15.28-7.35,24.71c-5.44,5.31-10.98,1.39-16.49,0.2
            c-7.71-5.48-2.01-15.08-6.58-21.54c-0.07-1.21,0.19-2.34,0.84-3.37c3.29,0,6.57,0,9.86,0c2.47,3.39,4.93,2.1,7.4,0
            C268.43-188.63,272.54-188.63,276.65-188.63z"
            />
            <path
              style="fill: #445526"
              d="M375.26,263.02c0,3.29,0,6.58,0,9.87c-5.62,6.74-7.37,17.02-17.71,19.5
            c-4.96-0.14-8.31-2.27-9.16-7.46c-0.31-4.27,3.96-6.79,4.16-10.88c2.41-3,0.42-6.38,0.76-9.56c0.64-3.12,2.7-5.1,5.29-6.67
            C365.92,253.83,370.67,258.18,375.26,263.02z"
            />
            <path
              style="fill: #414e24"
              d="M350.66,344.35c3.97,0.01,10.08-3.22,8.52,5.36c-0.65,3.57,2.26,4.99,3.71,7.31
            c1.17,6.1-5.85,3.82-7.35,7.19c-4.11,0-8.22,0-12.33,0c-2.42-3.72-2.64-7.93-2.72-12.18c-0.86-3.79,0.14-6.78,3.69-8.69
            C346.55,342.36,348.53,343.82,350.66,344.35z"
            />
            <path
              style="fill: #739966"
              d="M76.94,364.2c-13.07-6.03-27.56-7.18-42.76-11.98c5.61-4.64,10.98-2.11,15.67-2.83
            c11.57-5.74,27.88-1.77,36.03,8.77c0.52,2.27-0.06,4.27-1.53,6.04C81.87,364.2,79.41,364.2,76.94,364.2z"
            />
            <path
              style="fill: #596538"
              d="M-171.78,102.45c-3.03-1.71-3.41,1.89-5.22,2.62c0-6.58,0-13.16,0-19.74
            c2.65,1.21,5.35,2.22,8.19,0.73c7.62-3.99,9.36,0.05,9.25,6.7c0.96,0.69,1.79,1.51,2.51,2.46c1.62,3.51,2.64,7-0.3,10.39
            C-163.38,110.2-168.3,109.59-171.78,102.45z"
            />
            <path
              style="fill: #abb46d"
              d="M170.53-172.62c-1.06,2.25-2.12,4.5-3.18,6.75c-1.9,1.84-4.05,1.74-6.33,0.87
            c-4.5-5.58-12.62-4.49-17.48-9.46c-1.67-1.96-2.67-4.14-2.44-6.78c0.76-5.8,6.34-5.17,9.81-7.38c3.29,0,6.57,0,9.86,0
            C168.91-186.28,170.28-179.79,170.53-172.62z"
            />
            <path
              style="fill: #405f26"
              d="M370.88,117.29c1.46,0.04,2.92,0.08,4.39,0.12c0,3.29,0,6.58,0,9.87
            c-3.15,0.17-8.49-4.72-7.99,3.8c0.23,3.88-2.95,5.49-6.41,6.21c-4.64,1.07-8.33,0.17-10.1-4.82c-0.78-2.78-0.92-5.63-0.79-8.52
            C355.05,110.02,355.29,109.95,370.88,117.29z"
            />
            <path
              style="fill: #5b7b2c"
              d="M116.39-188.63c0.72,1.64,1.44,3.29,2.16,4.93c-2.75,6.28-11.76,5.85-13.91,13.21
            c-0.56,1.91-4.33,1.54-6.55,0.2c-2.19-1.31-2.17-3.17-0.74-5.09c3.18-4.26,2.51-6.69-3.17-6.78c-1.97-3.24,0.09-4.92,2.49-6.47
            C103.24-188.63,109.82-188.63,116.39-188.63z"
            />
            <path
              style="fill: #212715"
              d="M357.76,290.31c5.08-6.57,7.32-16,17.5-17.42c0,7.4,0,14.81,0,22.21
            c-6.53,5.79-12.72,5.92-18.48-1.12C356.58,292.62,356.91,291.39,357.76,290.31z"
            />
            <path
              style="fill: #688230"
              d="M-171.93-48.26c14.64,10.4,14.64,10.4-5.07,24.98c0-7.4,0-14.81,0-22.21
            C-175.64-47.01-174.85-49.57-171.93-48.26z"
            />
            <path
              style="fill: #5c6f37"
              d="M84.34,364.2c-0.1-1.5-0.2-3-0.3-4.51c3.2-6.72-2.76-16.76,7.56-20.92
            c5.35-0.18,11.27-2.95,15.12,3.41c-4.17,7.34-8.34,14.68-12.52,22.02C90.91,364.2,87.63,364.2,84.34,364.2z"
            />
            <path
              style="fill: #bfc69d"
              d="M199.6-173.37c-0.56-1.71-1.11-3.42-1.67-5.13c-3.78-7.79,3.34-7.8,7.22-10.13
            c3.29,0,6.57,0,9.86,0c1.64,5.97,4.25,11.86,1.74,18.24c-1.71,2.14-3.96,3.46-6.54,4.27
            C204.41-165.22,201.63-168.75,199.6-173.37z"
            />
            <path
              style="fill: #789736"
              d="M67.23-173.97c-0.55-3.62-0.26-7.89-6.35-5.79c-3.01,1.04-6.45,1.82-9.52,1.41
            c-3-0.4-7.08-1.42-7.57-5.14c-0.48-3.69,4.63-2.61,6.03-5.14c4.11,0,8.22,0,12.33,0c5.28,3.77,17.93,2.18,10.85,14.95
            C71.01-172.35,69.08-172.34,67.23-173.97z"
            />
            <path
              style="fill: #6d935f"
              d="M3.33,354.97c5.98,3.19,12.5,1.21,18.73,1.95c5.36,0.63,10,2.44,12.97,7.29
            c-9.04,0-18.08,0-27.12,0C-1.34,360.07-1.34,360.07,3.33,354.97z"
            />
            <path
              style="fill: #54782b"
              d="M365.39,31.05c-3.04,4.76-5.6,10.84-12.61,4.56c-1.08-2.86-1.07-5.79-0.53-8.75
            c1.18-3.69,3.65-6.17,7.13-7.71c4.54-1.41,4.52-6.59,7.57-9.25c1.08-0.61,2.24-0.95,3.48-1.04c1.95,0.13,3.46,1.15,4.84,2.42
            c0,2.47,0,4.94,0,7.4C372.96,23.59,373.16,30.51,365.39,31.05z"
            />
            <path
              style="fill: #aeb884"
              d="M316.09-188.63c1.04,5.63,2.09,11.27,3.13,16.9c0.68,1.31,0.52,2.5-0.48,3.59
            c-10.81-0.11-17-5.57-19.31-17.03c0.39-1.28,1.01-2.43,1.87-3.46C306.23-188.63,311.16-188.63,316.09-188.63z"
            />
            <path
              style="fill: #7ca133"
              d="M-177-60.29c5.73-7.93,14.12-7.33,22.43-6.99c0.05,4.82,6.32,9.71-0.18,14.45
            c-4.26,0.13-8.59,0.59-12.08-2.7c-3.14-1.86-7.58,2.03-10.17-2.29C-177-58.65-177-59.47-177-60.29z"
            />
            <path
              style="fill: #6b794f"
              d="M-177,186.51c8.39-2.54,14.03-8.63,19.43-15.04c3.01,14.92-5.71,23.89-19.43,19.98
            C-177,189.8-177,188.15-177,186.51z"
            />
            <path
              style="fill: #5a7729"
              d="M94.28-182.2c-5.34,3.89-7.19,13.35-16.75,10.48c-1.06-0.31-1.85-0.95-2.38-1.92
            c0.26-5.27-3.85-9.62-3.14-14.99c4.93,0,9.86,0,14.79,0C88.53-185.6,91.6-184.13,94.28-182.2z"
            />
            <path
              style="fill: #bdc39c"
              d="M170.53-172.62c-8.27-2.27-5.58-11.24-9.76-16.01c6.57,0,13.15,0,19.72,0
            C173.18-185.78,179.2-174.62,170.53-172.62z"
            />
            <path
              style="fill: #495b25"
              d="M210.4-168.93c0.8-2.04,2.67-2.31,4.47-2.71c2.66-5.37-2.32-13.03,5.07-16.99
            c1.64,0,3.29,0,4.93,0c1.63,1.72,3.43,3.34,3.72,5.91c-0.37,6.02,8.43,9.19,5.02,16.17c-3.81,3.5-8.46,4.08-13.36,3.79
            C215.82-162.97,211.55-163.46,210.4-168.93z"
            />
            <path
              style="fill: #5e5926"
              d="M-85.96-173.8c-0.61-5.26-10.76-7.45-4.75-14.83c4.93,0,9.86,0,14.79,0
            c3.58,3.11,5.46,6.58,2.4,11.13c-3.17,2.46-6.22,5.11-9.82,6.98C-85.23-170.79-86.6-171.49-85.96-173.8z"
            />
            <path
              style="fill: #667348"
              d="M375.26,263.02c-5.5,2.89-9.61-3.92-14.95-2.16c-7.29-5.16-0.98-8.76,1.45-12.83
            c4.54-3.11,9.03-2.61,13.49,0.18C375.26,253.14,375.26,258.08,375.26,263.02z"
            />
            <path
              style="fill: #455726"
              d="M365.67,346.74c-7.81-11.37-7.59-11.82,9.59-19.55c0,4.94,0,9.87,0,14.81
            C372.61,344.68,369.32,346.07,365.67,346.74z"
            />
            <path
              style="fill: #445526"
              d="M357.56,292.44c5.37,4.42,11.99,0.5,17.7,2.66c0,3.29,0,6.58,0,9.87
            c-4.27,0.69-8.55,1.38-12.82,2.06C358.5,302.95,348.7,300.82,357.56,292.44z"
            />
            <path
              style="fill: #757a3a"
              d="M20.24-188.63c0.72,6.27-2.72,8.1-8.02,6.79c-4.14-1.02-7.88-1.05-11.57,1.14
            c-2.61,0.7-5.97,1.97-7.31-0.94c-1.79-3.88,2.94-4.73,4.7-6.99C5.44-188.63,12.84-188.63,20.24-188.63z"
            />
            <path
              style="fill: #706136"
              d="M-75.9-178.99c1.53-3.22,0.4-6.43-0.02-9.64c5.75,0,11.51,0,17.26,0
            c0.03,1.44,0.06,2.89,0.1,4.33c-1.5,4.06-3.19,8.01-6.89,10.67C-70.64-172.09-76.17-169.88-75.9-178.99z"
            />
            <path
              style="fill: #545627"
              d="M-1.95-188.63c-0.2,2.99-5.43,7.64,2.6,7.92c-5.45,3.14-11.48,1.65-17.26,2.15
            c2.22-4.16-2.37-6.55-2.61-10.08C-13.46-188.63-7.71-188.63-1.95-188.63z"
            />
            <path
              style="fill: #89ad34"
              d="M-152.35-188.63c-0.77,2.77,4.2,5.37,1.14,7.52c-3.21,2.26-7.94,2.39-11.28,0.28
            c-2.83-1.79-6.31-3.81-7.12-7.81C-163.85-188.63-158.1-188.63-152.35-188.63z"
            />
            <path
              style="fill: #7b8847"
              d="M301.3-188.63c0.08,0.75,0.16,1.5,0.24,2.25c1.49,5.24,2.25,10.09-5.47,10.52
            c-8.22-1-6.26-7.67-7.09-12.78C293.08-188.63,297.19-188.63,301.3-188.63z"
            />
            <path
              style="fill: #56663f"
              d="M-177,137.15c3.33,1.52,6.67,3.04,10,4.56c2.59-1.05,4.99-0.66,5.4,2.3
            c0.81,5.74-3.05,8.49-7.7,10.45c-3.07,0.72-5.52-0.48-7.71-2.5C-177,147.02-177,142.08-177,137.15z"
            />
            <path
              style="fill: #9f9b80"
              d="M375.26-107.19c-4.87-0.22-9.73-0.44-14.6-0.67c-6.32-0.49-12.72,3.84-18.99-0.32
            c-4.38-1.19-7.05-3.73-6.73-8.61c0.91-1.89,2.47-2.97,4.44-3.54c4.52-0.71,8.94-1.08,11.82,3.62
            c6.46,7.66,15.74,5.71,24.05,7.05C375.26-108.83,375.26-108.01,375.26-107.19z"
            />
            <path
              style="fill: #819c2d"
              d="M365.67,346.74c1.83-4.36,3.45-9.13,9.59-4.74c-1.6,7.32,3.16,15.34-2.47,22.21
            c-4.91-3.89-7.33-8.61-5.04-14.94C367.06,348.42,366.37,347.58,365.67,346.74z"
            />
            <path
              style="fill: #434c29"
              d="M271.71,364.2c3-1.66,6-3.32,9-4.98c3.7-1.14,4.03-8.73,10.13-5.3
            c2.36,4.58,6.13,7.71,10.46,10.28C291.44,364.2,281.58,364.2,271.71,364.2z"
            />
            <path
              style="fill: #647e2b"
              d="M367.76,349.27c1.68,4.98,3.36,9.96,5.04,14.94c-5.75,0-11.51,0-17.26,0
            c0.34-4.56,5.06-4.63,7.35-7.19C364.51,354.43,366.13,351.85,367.76,349.27z"
            />
            <path
              style="fill: #7e9446"
              d="M375.26,248.21c-4.15,0.94-8.3,1.88-12.45,2.82c-4.76,0.2-7.07-2.5-6.33-6.81
            c0.78-4.54,3.66-7.26,8.84-5.66c3.32,2.39,6.63,4.79,9.95,7.18C375.26,246.56,375.26,247.39,375.26,248.21z"
            />
            <path
              style="fill: #1d2414"
              d="M375.26,213.66c-4.76-6.91-12.72-8.65-19.6-12.15c1.67-3.05,3.65-5.61,7.7-4.92
            c8.73-0.65,7.81,8.21,11.9,12.13C375.26,210.37,375.26,212.01,375.26,213.66z"
            />
            <path
              style="fill: #b7d05e"
              d="M368.36-29.83c0.96,3.64,3.37,4.81,6.91,4.09c0,3.29,0,6.58,0,9.87
            c-1.36,2.04-2.73,4.09-5.72,3.49c-3.4-1.74-7.11-3.09-8.88-6.94C359.42-25.61,360.07-30.53,368.36-29.83z"
            />
            <path
              style="fill: #6d9632"
              d="M365.39,31.05c3.29-4.12,6.59-8.25,9.88-12.37c0,5.76,0,11.52,0,17.28
            C370.63,37.02,367.65,34.77,365.39,31.05z"
            />
            <path
              style="fill: #7a9337"
              d="M264.32-188.63c-3.71,4.03,7.43,13.02-3.14,13.31c-6.59,0.18-3.36-8.51-4.26-13.31
            C259.39-188.63,261.85-188.63,264.32-188.63z"
            />
            <path
              style="fill: #7b893d"
              d="M205.15-188.63c-3.55,2.56-5.8,6.05-7.22,10.13c-9.17,1.61-9.09-3.73-7.57-10.13
            C195.29-188.63,200.22-188.63,205.15-188.63z"
            />
            <path
              style="fill: #68902b"
              d="M375.26,191.44c-6.76-2.08-7.93-7.52-6.88-13.25c0.7-3.81,4.2-2.55,6.88-1.56
            C375.26,181.57,375.26,186.51,375.26,191.44z"
            />
            <path
              style="fill: #416226"
              d="M375.26,107.53c-3.58-0.75-7.17-1.45-10.73-2.27c-1.88-0.43-4.55-0.35-4.47-3.03
            c0.04-1.35,1.44-2.89,2.6-3.94c2.98-2.71,3.79-0.31,4.95,2.11c1.61,3.35,4.95,1.95,7.65,2.19
            C375.26,104.24,375.26,105.89,375.26,107.53z"
            />
            <path
              style="fill: #313f1c"
              d="M72.01-188.63c1.57,5.03,6.35,8.9,5.37,14.85c-1.87,1.45-3.7,1.33-5.5-0.13
            c3.43-9.33-7.39-9.22-9.73-14.72C65.44-188.63,68.72-188.63,72.01-188.63z"
            />
            <path
              style="fill: #3e5723"
              d="M-177-57.83c2.26-0.78,4.61-1.35,6.73-2.4c1.41-0.69,2.71-2.5,4.17-0.87
            c1.63,1.82,0.16,3.77-0.73,5.55c-3.06,1.47-4.9,3.8-5.1,7.28c-1.69,0.92-3.38,1.85-5.07,2.77C-177-49.6-177-53.71-177-57.83z"
            />
            <path
              style="fill: #98a278"
              d="M-172.11,58.33c-1.63-2.52-3.26-5.04-4.89-7.56c0-4.11,0-8.23,0-12.34
            c0.51-1.69,1.27-1.81,2.28-0.37C-172.32,44.62-166.93,50.79-172.11,58.33z"
            />
            <path
              style="fill: #62747f"
              d="M-169.61,159.35c-3.44,4.17,17.09,8.25,0.05,12.48c-7.53,0.44-2.71-8.34-7.44-10
            c0-0.82,0-1.65,0-2.47C-174.54,157.41-172.08,157.15-169.61,159.35z"
            />
            <path
              style="fill: #a2a789"
              d="M225.48-181.45c-0.2-2.39-0.41-4.79-0.61-7.18c4.93,0,9.86,0,14.79,0
            c1.42,1.59,1.73,3.41,1.06,5.41c-0.69,1.15-1.62,2.04-2.79,2.69C233.61-178.5,229.31-176.73,225.48-181.45z"
            />
            <path
              style="fill: #557b2b"
              d="M375.26,11.28c-1.63,0-3.25,0-4.88,0c-4.75-5.97-7-12.07-0.99-18.61
            c2.2-0.63,4.15-0.14,5.87,1.34C375.26-0.24,375.26,5.52,375.26,11.28z"
            />
            <path
              style="fill: #30441d"
              d="M362.96,48.76c3.62-3.37,6.7-7.6,12.3-7.86c0,0.82,0,1.65,0,2.47
            c-2.99,3.73-2.28,8.02-2.13,12.34c0.14,4.19-2.21,4.41-5.27,2.44C366.23,55.01,364.6,51.88,362.96,48.76z"
            />
            <path
              style="fill: #424f4c"
              d="M-177,161.83c6.01,0.7,5.32,6.39,7.44,10c-1.71,2.8-4.13,4.49-7.44,4.81
            C-177,171.7-177,166.76-177,161.83z"
            />
            <path
              style="fill: #86ad25"
              d="M-177-94.85c2.44-0.04,4.87-0.08,7.31-0.11c2.36-1.44,4.51-1.36,5.87,1.25
            c1.26,2.42,0.2,4.62-1.45,6.45c-2.52,2.8-6.5,2.63-9.51,4.47c-0.74,0.14-1.48,0.23-2.22,0.29C-177-86.62-177-90.73-177-94.85z"
            />
            <path
              style="fill: #a1a781"
              d="M219.94-188.63c-3.13,5.23,2.65,13.13-5.07,16.99c-1.38-5.68-3.68-11.36,0.14-16.99
            C216.65-188.63,218.3-188.63,219.94-188.63z"
            />
            <path
              style="fill: #7d9f34"
              d="M367.87,58.14c3.72,0.22,3.74-1.81,3.06-4.66c-1.04-4.36-0.46-8.19,4.34-10.12
            c0,5.76,0,11.52,0,17.28C372.77,59.89,369.36,61.86,367.87,58.14z"
            />
            <path
              style="fill: #2f3f1d"
              d="M94.28-182.2c-4.53,0.23-11.55,3.37-7.47-6.43c3.29,0,6.57,0,9.86,0
            c-0.83,2.15-1.66,4.31-2.49,6.46C94.17-182.16,94.28-182.2,94.28-182.2z"
            />
            <path
              style="fill: #747f39"
              d="M150.91-188.63c-3.62,2.23-5.79,5.33-5.81,9.71c-3.71,2.03-6.63,1.14-8.82-2.41
            c-0.05-2.43-0.1-4.87-0.16-7.3C141.04-188.63,145.98-188.63,150.91-188.63z"
            />
            <path
              style="fill: #9aa2be"
              d="M-169.61,159.35c-2.46,0-4.93,0.01-7.39,0.01c0-2.47,0-4.94,0-7.4c2.43,0.24,4.86,0.48,7.29,0.72
            c2.3,0.57,3.94,1.56,2.67,4.33C-167.9,157.79-168.75,158.57-169.61,159.35z"
            />
            <path
              style="fill: #abc76e"
              d="M-36.47-188.63c0.27,8.07-5.58,5.51-9.88,6.36c-0.49-4.45,4.18-3.71,4.95-6.36
            C-39.76-188.63-38.11-188.63-36.47-188.63z"
            />
            <path
              style="fill: #36441e"
              d="M-174.72,38.06c-0.72,0.35-1.49,0.48-2.28,0.37c0-4.94,0-9.87,0-14.81
            C-164.69,26.6-177.96,33.64-174.72,38.06z"
            />
            <path
              style="fill: #598034"
              d="M375.26,72.98c-2.46,1.01-4.96,3.16-6.05-1.75c-1.29-5.82,2.21-5.74,6.05-5.66
            C375.26,68.04,375.26,70.51,375.26,72.98z"
            />
            <path
              style="fill: #8fb44a"
              d="M375.26-6c-1.63-0.05-3.25-0.1-4.88-0.15c-2.12-0.81-3.94-1.88-4.1-4.48
            c0.18-1.75,1.02-3.02,2.63-3.77c2.11-0.49,4.23-0.99,6.34-1.48C375.26-12.58,375.26-9.29,375.26-6z"
            />
            <path
              style="fill: #5b692d"
              d="M239.12-183.97c0.18-1.55,0.37-3.1,0.55-4.66c2.47,0,4.93,0,7.4,0c0,0.86,0.01,1.72,0.01,2.58
            c0.02,0.56,0.04,1.11,0.06,1.67C244.71-179.28,242.06-178.7,239.12-183.97z"
            />
            <path
              style="fill: #1f2417"
              d="M15.28,265.48c-3.86,7.98,3.14,16.94-2.25,24.78c-5.28,5.53-8.87,5.84-14.17,0.5
            c-6.04-6.09-8-13.92-8.59-22.26c-1.42-19.87-8.35-37.14-24.19-50.03c-3.67-2.99-5.44-7.03-6.83-11.32
            c-1.06-3.29-2.34-6.21-6.67-5.41c-1.3,0.21-2.6,0.16-3.69,0.22c0.99,2.04,3.42,2.7,3.95,4.86c1.63,6.22-4.42,8.55-6.5,12.92
            c-5.79,5.45-11.77,9.85-19.76,3.81l-0.06-0.07c-1.74-3.5-3.14-6.93-6.15-9.94c-4.98-4.99-6.17-13.13-1.54-17.51
            c7.06-6.67,1.32-9.17-1.83-13.09c-1.67-2.39-3.35-4.78-4.36-7.56c-4.83-13.82-4.83-13.82,11.49-26.04
            c6.7-4.54,8.22-13.82,15.88-17.5c2.22-1.2,4.47-1.53,6.78-0.27c2.15,1.47,2.76,6.08,7.22,1.75
            c7.71-7.48,13.86-15.95,18.59-25.41c4.3-8.59-1.77-21.85-10.98-25.35c-11.85-4.51-14.58-11.7-9.06-23.83
            c3.82-2.18,7.29-5.05,11.7-6.13c4.08-0.8,7.83-0.24,10.98,2.7c4.85,9.54,11.77,18,15.25,28.29c0.47,1.1,0.71,2.25,0.79,3.44
            c-0.01,3.44-1.43,6.64-1.72,10.05c-0.21,4.45-0.32,8.91,0.23,13.35c2.72,7.16,4.81,14.32-0.1,21.44
            c-5.24,4.82-7.23,11.53-9.91,17.74c-3.25,7.53-8.18,10.73-16.18,8.21c-1.08,0.06-2.04,0.35-2.33,1.69
            c-0.72,3.45,0.28,6.73,0.53,10.03c0.13,2.7-0.63,5.77,3.08,6.9c2.97,2.67,6.2,3.48,9.87,1.53c3.16-0.71,6.29-1.46,8.86-3.63
            c5.23-5.15,10.41-10.78,17.24-1.98c2.08,4.06,1.62,8.79,3.35,12.96c0.83,3.3,3.91,5.38,4.73,8.7
            c2.52,8.84,7.17,17.23,5.56,26.91c1.48,5.45-1.33,11.34,1.75,16.62c0.18,1.14,0.29,2.27,0.34,3.42
            c0.61,7.32,5.45,14.43,1.04,21.98C16.97,263.89,16.19,264.75,15.28,265.48z"
            />
            <path
              style="fill: #232b19"
              d="M106.47,270.47c-9.99,2.29-5.4,7.43-2.38,12.25c-2.27,5.58-6.52,10.35-6.29,16.95
            c0.19,5.52-2.42,9.61-8,10.76c-6.41,1.31-9.94-2.1-11.73-10.03c0.38,9.81,1.34,16.76,12.19,15.19c4.6-0.66,7.64,2.08,10.07,5.66
            c2.49,9.59-6.51,10.01-11.1,13.96c-2.74,0.63-5.52,0.56-8.31,0.48c-9.9-2.53-19.6-2.98-29.85-0.68
            c-7.05,1.58-15.28,3.33-21.87-2.8c-1.62-8.12,1.55-12.77,9.88-13.67c1.33,0.64,2.53,1.44,3.18,2.9
            c0.25,0.55,0.54,0.69,0.45,0.63c-1.27-1.04,1.4-1.42-0.25-1.15c-1.6-6.95-7.81-9.39-12.45-13.32
            c-5.04-6.3,6.24-14.33-2.14-20.28c5.54-7.16,11.89-1.6,17.92-1.17c9.44,3.61,9.97-1.37,7.53-8.24
            c-2.83-7.98-4.18-15.05,6.24-18.35c2.18,0.81,3.7,2.74,6.03,3.75c-0.85-2.62-3.78-2.51-4.94-4.41
            c-11.93-8.9-10.08-23.81-15.53-35.55c-0.27-1.24-0.24-2.47,0.03-3.7c0.37-1.17,0.99-2.2,1.82-3.1
            c9.73-5.75,11.93,5.58,17.82,7.5c5.51,1.8,12.04-7.1,19.19-1.56c2.37,1.84,3.9,4.2,4.07,7.28
            c4.58,10.12,11.75,17.28,22.78,20.08c1.91,0.71,3.76,1.54,5.77,1.86c5.6,0.88,9,4.08,8.6,9.79c-0.42,6.02-5.26,7.47-10.31,8.33
            C112.09,270.28,109.23,269.73,106.47,270.47z"
            />
            <path
              style="fill: #131510"
              d="M-48.76,198.78c6.04-7.63,9.24-2.18,10.94,3.07c2.42,7.48,7.99,11.62,13.15,16.87
            c11.92,12.14,18.92,27.34,20.1,44.54c0.51,7.46,1.34,14.7,4.97,21.18c2.61,4.65,6.04,9.1,12.63,5.81
            c10.84,2.34,14.23,8.19,11.8,20.32c-1.53,7.64-15.04,16.46-22.68,14.74c-1.18-0.26-2.24-1.01-3.41-1.34
            c-12.66-3.57-14.09-8.75-19.26-21.09c-5.21-12.43-4.9-27.4-15.68-37.43l-0.99-0.69c0,0,0.41-1.14,0.41-1.14
            c0.07-1.9,0.14-3.8,0.22-5.7c-7.29-12.68-11.25-26.41-12.97-40.85C-44.4,211.18-50.86,204.8-48.76,198.78z"
            />
            <path
              style="fill: #1c2215"
              d="M-169.58,304.94c4.23-0.41,6.51,2.6,9.85,6.31c1.31-12.07,4.75-23.16,8.56-33.01
            c5.85-15.1,15.22-29.5,25.93-42.38c12.3-1.82,20.52,1.81,23.06,15.37c1.02,5.44,3.72,10.95,9.01,14.25
            c1.83-1.81,2.8-4.33,4.82-6c5.77-3.58,10.87-3.82,14.61,2.88c0.98,4.56,0.2,9.08-0.5,13.62c-7.73,6.87-12.47,15.85-17.34,24.76
            c-3.56,4.26-7.84,6.28-13.39,4.43c-5.43-2.9-6.7-9.89-13.14-12.34c-6.28-2.39-10.54-4.15-9.16,5.26c0.6,4.1-1.05,8.23,3.42,12.1
            c5.21,4.51,0.74,7.7-3.93,9.6c-8.48-5.31-17.42-6.18-26.9-3.08C-165.39,320.19-170.18,315.92-169.58,304.94z"
            />
            <path
              style="fill: #2a3c22"
              d="M-88.05,263.49c-1.7,0.66-3.41,1.33-5.11,1.99c-0.2-1.6-0.22-3.24-0.62-4.79
            c-1.6-6.17-6.75-11.76-5.14-18.31c1.99-8.12,10.63-5.71,16.2-8.17c4.91-2.17,6.94-6.48,9.31-10.68
            c6.22-1.31,12.04-3.54,17.16-7.42c3.27-3.19,6.55-2.48,9.84-0.04c5.44,13.44,11.74,26.68,9.85,41.84
            c-10.17-1.42-16.92-8.73-24.35-14.81c-8.22,5.39,9.38,13.1-2.2,17.61C-71.68,259.27-80.93,251.75-88.05,263.49z"
            />
            <path
              style="fill: #1f2416"
              d="M89.08,341.93c0.74,3.52,1.75,7,2.17,10.55c0.61,5.2-0.7,8.93-7.21,7.21
            c-10.25-7.25-23.02-6.11-34.19-10.3c-7.01-4.48-14.82-2.32-22.33-2.74c-1.67-3.99-3.33-7.97-5-11.96
            c0.43-0.4,0.86-0.79,1.29-1.19c1.13-0.36,2.27-0.41,3.42-0.15c7.67,3.78,15.79,2.39,23.82,2.07c2.72-0.99,5.34-2.42,8.39-2.06
            c8.68-0.22,16.74,4.45,25.56,3.23c1.15,0.42,2.15,1.07,3.01,1.93C88.68,339.56,89.05,340.69,89.08,341.93z"
            />
            <path
              style="fill: #232e22"
              d="M-105.35,302.43c4.93,0.46,8.45-2.62,12.29-4.91c2.92-2.63,5.52-5.96,10.24-4.54
            c4.56,1.39,5.17,5.68,6.76,9.25c-3.92,5.59-5.22,11.75-4.75,18.56c0.97,13.84-1.1,15.29-15.02,11.23
            c-3.86-4.73-10.41-1.55-14.7-5.02c-2.43-3.59-2.02-11.06-9.88-7.27c-3.11-4.9-1.97-8.92,3.39-10.6
            C-112.51,307.71-109.49,304.2-105.35,302.43z"
            />
            <path
              style="fill: #324420"
              d="M-105.35,302.43c2.47,6.07,0.4,9.45-6.09,9.78c-5.02,0.26-8.75,1.7-8.95,7.52
            c-2.45,1.61-4.92,1.52-7.39,0.05c1.43-3.01,5.09-6-1.05-8.45c-3.4-1.36-4.95-3.8-4.48-8.63c2.04-20.96,5.1-22.72,23.76-12.2
            C-104.48,293.36-107.42,298.7-105.35,302.43z"
            />
            <path
              style="fill: #314339"
              d="M46.84,218.23c0.13,0.9,0.25,1.8,0.38,2.69c-1.22,5.41-2.12,10.65-0.38,16.33
            c1.55,5.06,0.51,10.29-3.01,14.66c-1.62,3.1-4.16,5.5-6.22,8.27c-4.31,3.83-8.43,8.04-14.89,7.74
            c-2.48-0.82-4.96-1.63-7.44-2.45c-0.15-0.63-0.29-1.26-0.44-1.89c5.95-4.94,3.92-11.68,4.02-17.95c1.75-3.35,8.15-4.16,5.37-10
            C26.09,216.89,32.94,211.64,46.84,218.23z"
            />
            <path
              style="fill: #4e604e"
              d="M44.91,290.12c-5.68-0.94-11.36-1.88-17.04-2.82c-0.52-4.95-7.65-8.97-2.07-14.78
            c4.48-11.83,8.57-13.59,22.88-9.86c3.43,4.8,2.35,10.77,4.05,16.1C54.6,284.66,50.86,288.61,44.91,290.12z"
            />
            <path
              style="fill: #1f2416"
              d="M30.02,307.58c10.5-0.82,16.08,3.39,14.85,14.56c-1.71,1.35-3.34,1.37-4.87-0.28
            c-7.29-2.15-6.05,6.53-10.35,8.17c-1.23,2.67-4.35,2.29-6.25,3.89c0,0-0.88,0.78-0.88,0.78c-6.79-5.67,0.03-8.48,2.9-11.2
            C30.32,318.87,32.9,314.18,30.02,307.58z"
            />
            <path
              style="fill: #324520"
              d="M-36.12,275.78c0.14,2-0.17,4.18,0.52,5.97c1.56,4.08,4.65,8.04-0.85,11.54
            c-3.84,2.43-6.89,0.99-9.59-1.97c-3.65-4-4.97-8.33-1.26-13c0.98-1.23,2.42-2.09,3.65-3.13
            C-41,273.62-38.43,272.98-36.12,275.78z"
            />
            <path
              style="fill: #36524a"
              d="M-88.05,263.49c-0.93-8.31,7.39-10.61,11.42-12.28c5.35-2.22,10.83,3.51,13.53,9.52
            c-0.02,3.12-0.05,6.23-0.08,9.35c-5.56,1.83-9.35-1.25-12.99-4.72C-79.08,258.02-83.65,261.28-88.05,263.49z"
            />
            <path
              style="fill: #1f2416"
              d="M17.84,352.33c-4.75,2.72-9.91,1.61-14.96,1.59c0.82-0.74,1.65-1.48,2.47-2.22
            c1.99,0,3.97,0,5.94,0c0-1.3,0.28-2.38-0.04-2.61c-4.31-3.06-9.67-5.56-7.18-12.4c1.09-2.99,3.86-4.77,6.86-3.74
            c6.23,2.14,5.88,8.14,6.7,13.25C17.96,348.2,17.79,350.28,17.84,352.33z"
            />
            <path
              style="fill: #3f596b"
              d="M-84.1,295.67c-2.96,0.76-4.93,6.3-8.96,1.85c2.42-9.64,5-19.17,16.23-22.41
            c4.32-2.62,7.12-0.98,8.98,3.24c-0.17,0.66-0.37,1.32-0.59,1.97c-2.45,1.96-4.12,4.36-4.27,7.63c-0.38,1.01-0.89,1.94-1.51,2.82
            C-76.38,294.71-80.13,295.42-84.1,295.67z"
            />
            <path
              style="fill: #365f74"
              d="M-67.85,278.35c-2.99-1.08-5.98-2.16-8.98-3.24c-2.94-3.46-3.09-6.74,0.66-9.74
            c5.24-0.95,9.84-0.12,12.99,4.72C-59.6,275.74-63.79,277.01-67.85,278.35z"
            />
            <path
              style="fill: #556c70"
              d="M46.68,263.14c-7.4,2.15-16.09,1.42-20.88,9.38c-1.03-1.53-2.05-3.06-3.08-4.59
            c2.55-5.03,8.15-6.51,11.87-10.18c3.4,0.66,6.25-2.72,9.76-1.34C46.64,258.13,49.55,259.64,46.68,263.14z"
            />
            <path
              style="fill: #3c4b32"
              d="M-24.88,348.78c0.32,3.03-1.13,4.94-4.06,4.85c-4.71-0.13-3.27-5-4.99-7.48
            c-1.63-2.35,0.6-3.55,2.9-3.68C-26.64,342.22-25.8,345.56-24.88,348.78z"
            />
            <path
              style="fill: #30414b"
              d="M-110.52,327.01c7.45-5.8,13.44-7.32,14.7,5.02C-101.17,331.65-107.69,334.75-110.52,327.01z"
            />
            <path
              style="fill: #5a7399"
              d="M-84.1,295.67c0.84-4.47,5.74-4.74,7.99-7.76c10.76,1.84,4.71,7.41,2.68,12.09
            c-0.87,0.75-1.75,1.49-2.63,2.24C-80.5,302.2-81.99,298.55-84.1,295.67z"
            />
            <path
              style="fill: #375876"
              d="M-73.44,300c0.3-4.3,2.2-8.95-2.68-12.09c0,0,0.43-0.87,0.43-0.87c1.49-2.99,4.15-4.14,7.24-4.62
            C-63.98,290.05-64.81,292.97-73.44,300z"
            />
            <path
              style="fill: #3b5155"
              d="M-36.12,275.78c-2.51-0.19-5.02-0.39-7.53-0.59c3.37-3.22-3.42-12.48,6.87-11.57
            c0.1,0.64,0.3,1.25,0.58,1.83C-36.17,268.9-36.15,272.34-36.12,275.78z"
            />
            <path
              style="fill: #30471f"
              d="M-70.76,348.58c2.42,0,3.69,1.59,4.01,3.61c0.21,1.36-0.27,3.97-0.91,4.14
            c-2.99,0.75-4.07-1.75-4.84-4C-73.04,350.76-72.67,349.22-70.76,348.58z"
            />
            <path
              style="fill: #264049"
              d="M-68.44,282.42c-1.41,3.12-3.66,4.91-7.24,4.62c-4.11-9.29,3.71-5.69,7.24-6.72
            C-68.36,281.02-68.36,281.72-68.44,282.42z"
            />
            <path
              style="fill: #1f2b18"
              d="M207.84,268.39c1.24-5.88,5.45-11.38,2.31-17.84c4.92-5.11,1.72-11.62,2.99-17.36
            c1.02-4.62-0.78-9.85,2.95-13.91c11.69-7.4,11.69,3.01,14.36,9.4c1.35,2.47,3.86,3.82,5.73,5.77c8.58,1.37,16.88,4.6,25.83,0.6
            c5.48-2.45,11.3-0.66,16.39,2.61c4.16,1.25,5.5,8.83,11.83,5.2c10.21-0.7,16.69,4.25,20.61,13.35c1.61,3.74,2.19,6.94-2.15,9.12
            c-9.05-0.48-18.32-1.62-24.46,7.49c-5.53,6.15-16.9,6.71-17.11,17.95c-3.58,5.28-5.33,11.94-12.2,14.68
            c-3.3,1.32-0.91,4.2-0.57,6.42c0.36,5.13,1.68,10.45-2.73,14.71c-7.53,3.03-12.19,0.11-14.8-7.11c-0.3-4.78-1.7-9.33-3.19-13.85
            c-5.67-11.48-6.89-23.37-2.16-36.14c-8.59,4.93-6.95,18.66-19.21,18.28c-2.59-2.83-4.13-6.15-4.63-9.95
            C210.24,274.72,210.9,271.6,207.84,268.39z"
            />
            <path
              style="fill: #405629"
              d="M242.09,317.27c-0.88,8.32,5.92,6.32,10.25,7.65c2.28-0.57,3.03,0.82,3.41,2.68
            c-1.64,3.68-3.61,7.19-5.85,10.54c-5.15,3.72-8.14,10.56-15.63,10.93c-4.95-0.13-9.79,3.35-14.84,0.57
            c-5.64-2.18-2.53-9.42-7.07-12.24c1.58-3.66,5.52-0.99,7.75-2.91c3.89-2.81,4.09-3.94,1.34-7.6c-6.09-8.1-3.79-16.07,5.6-19.39
            c-0.72-3.37,1.43-3.93,3.98-4.11C237.19,306.03,236.21,314.39,242.09,317.27z"
            />
            <path
              style="fill: #303f1c"
              d="M96.58,322.33c-1.33-0.9-2.64-2.54-3.99-2.57c-5.1-0.11-10.81,8.4-13.63,3.79
            c-4.23-6.91-9.08-15.66-6.46-24.33c1.21-4.01-3.03-11.94,4.27-11.84c4.05,0.05,5.74,7.13,7.15,11.76
            c0.92,3.02-1.83,8.55,4.62,7.57c5.08-0.78,6.85-5.59,5.07-9.44c-5.45-11.8,2.34-13.08,10.49-14.52
            c2.47,6.58,7.76,12.09,7.43,19.72C107.25,309.61,104.86,318.19,96.58,322.33z"
            />
            <path
              style="fill: #2b361c"
              d="M106.47,270.47c0.66-3.57,2.13-6.03,6.43-5.6c3.5,0.35,8.18,1.52,8.59-3.78
            c0.38-4.82-4.37-5.01-7.78-6.08c-1.54-0.48-3.02-1.18-4.53-1.78c-0.81-0.95-1.18-2.04-1.13-3.29c1.02-3.85,3.38-6.38,7.27-7.41
            c8.42,0.11,14.76-7.35,23.4-6.5c6.88,2.17,8.96,7.13,8.03,13.85c-0.29,2.55-1.42,4.93-1.59,7.53c-0.02,2.56,1.2,5.15-0.13,7.68
            c-0.92,1.42-2.65,1.76-3.79,2.89l-0.11,0c-1.38,1.5-2.42,3.32-4.15,4.5c-3.64,1.75-4.59,5.66-6.8,8.55
            c-3.71,3.78-6.83,3.8-9.34-1.3c-1.46-2.97-3.57-4.33-6.7-2.03C110.76,276.16,108.32,273.62,106.47,270.47z"
            />
            <path
              style="fill: #2d411e"
              d="M232.16,304.87c-1.7,0.88-3.4,1.75-5.11,2.63c-2.62-2.18-0.28-5.73-1.78-7.74
            c-2.39-3.2-0.36-13.42-10.09-6.77c-2.03,1.39-2.42-3.09-2.92-5.23c6.52-6.32,8.79-14.4,9.34-23.1c0.16-2.46-0.32-5.23,2.03-6.62
            c3.03-1.8,3.79,2.24,5.74,2.88c13.76,4.49,12.32,12.12,5.01,21.53c-2.49,3.21-1.8,6.79,0.42,10.24
            c2.39,3.73,5.09,7.54,2.85,12.41C235.78,306.02,233.92,306.65,232.16,304.87z"
            />
            <path
              style="fill: #3c5322"
              d="M96.58,322.33c-0.05-7.58,8.51-13.68,2.22-22.57c5.56-0.15,8.05,6.88,12.72,2.69
            c0.63,8.43,7.87,11.09,12.65,16.24c5.21,5.62,7.7,12.87,17.16,11.29c0.2,2.91-1.46,5.2-3.97,5.18
            c-14.87-0.11-29.75,4.41-44.6-0.06c-1.65-0.5-2.74-1.64-3.55-3.1C88.45,326.32,99.36,329.56,96.58,322.33z"
            />
            <path
              style="fill: #2e3d1e"
              d="M141.24,267.97c0.36-0.86,0.72-1.73,1.09-2.59c3.3-3.04,6.04-6.92,9.68-9.3
            c4.87-3.18,9.09-3.06,6.34,4.89c10.27,12.61,8.42,31.31,21.53,42.59c3.7,3.18,1.88,6.49-1.63,8.95c-3.6-0.22-7.17-0.48-7.7-5.24
            c-6.62-1.59-6.97-7.1-7.7-12.36c-4.01-3.41-8.11,3.79-12.11-0.11c-1.29-2.96-2.19-5.66-0.44-9.07
            C155.36,275.9,152.05,269.92,141.24,267.97z"
            />
            <path
              style="fill: #739e65"
              d="M89.2,332c17.37-0.67,34.75-1.35,52.12-2.02c3.94-0.05,7.88-0.1,11.82-0.15
            c3.78,6.44-2.64,6.51-5.47,7.88c-8.35,4.03-17.56,3.76-26.6,4.13c-4.78,0.12-9.57,0.24-14.35,0.35
            c-5.88-0.08-11.76-0.17-17.64-0.26c-0.8-0.69-1.59-1.38-2.38-2.06c0.05-3.64-7.24-2.62-4.91-7.72
            C84.26,332.1,86.73,332.05,89.2,332z"
            />
            <path
              style="fill: #24311a"
              d="M180.75,235.78c1.18,1.11,2.74,2.02,3.48,3.37c3.39,6.19,13.4,12.98,8.23,18.45
            c-4.87,5.15-15.26,8.47-24.53,5.18c-1.8-4.4-4.49-8.39-5.93-12.97c-4.24-11.56-2.82-14.45,9.15-18.71
            c1.08,0.23,2.07,0.72,2.96,1.38C175.91,234.35,176.41,238.89,180.75,235.78z"
            />
            <path
              style="fill: #2f411d"
              d="M164.09,355.94c-3.66-9.93-3.57-18.34,7.93-23.08c1.47-0.61,3.71-1.42,3.98-2.53
            c1.21-4.91,1.48-11.29,8.49-4.82c4.89,4.53,6.61,9.57,1.49,16.05C180.23,348.88,173.27,353.47,164.09,355.94z"
            />
            <path
              style="fill: #24311a"
              d="M197.88,240.65c-2.91-6.7-9.67-7.44-15.3-9.93c-1.69-3.75-5.51-6.66-4.47-11.49
            c2.18-6.74,8.1-9.26,13.7-12.14c5.7-3.5,11.47-6.57,18.2-2.46c4.33,4.02,3.4,9.27,3.34,14.32
            C212.17,229.02,200.48,231.59,197.88,240.65z"
            />
            <path
              style="fill: #384823"
              d="M232.15,344.5c7.11-0.14,10.02-6.68,15.09-9.93c4.35,1.86,4.64,6.6,7,9.87
            c4.1,2.45,13.3-0.4,12.23,6.27c-1.47,9.22-7.44,1.98-12.27,1.52c-8.89-0.86-15.67,7.63-24.41,9.59
            c-0.85-0.96-1.7-1.92-2.56-2.88C223.19,352.18,228.27,348.54,232.15,344.5z"
            />
            <path
              style="fill: #33461f"
              d="M197.88,240.65c-1.07-10.46,6.2-16.13,12.74-22.22c2.04-2.32,4.1-4.56,7.54-2.29
            c0.96,1.77,0.64,3.41-0.5,4.98c3.49,11.35,0.3,20.99-7.52,29.43C210.82,235.93,209.4,234.78,197.88,240.65z"
            />
            <path
              style="fill: #445724"
              d="M165.84,248.32c4.91,4.21,6.49,8.91,2.08,14.46c-3.19-0.61-6.39-1.21-9.58-1.81
            c-0.51-1.05-1.03-2.11-1.54-3.17c-1.83,8.26-8.44,7.36-14.47,7.57c-0.4-2.48-0.79-4.96-1.18-7.44c4.11-3.42,8.09-7,12.67-9.84
            C157.87,245.6,161.91,244.39,165.84,248.32z"
            />
            <path
              style="fill: #414d26"
              d="M114.14,277.68c2.59-4.13,5.34-4.89,8.17-0.28c1.35,2.2,2.6,4.32,5.7,2.75
            c1.99,0.02,3.04,1.1,3.36,3c-0.32,2.38-0.28,4.88-1.03,7.1c-1.12,3.28-3.88,5.46-7.2,5.72c-4.9,0.38-3.26-4.56-4.89-6.9
            C115.82,285.59,120.45,279.65,114.14,277.68z"
            />
            <path
              style="fill: #66872e"
              d="M180.76,221c4.23,2.56,5.39,5.7,1.83,9.72c-0.63,0.09-1.25,0.2-1.87,0.35
            c-2.67,0.91-4.43,4.47-8,2.72l0.03-0.03c-0.87-1.45-2.03-2.65-3.2-3.86c-2.33-3.58-8.15-1.73-9.84-6.32
            c-0.71-4.23,1.3-7.01,4.79-9c2.61-1.41,4.8-3.62,7.85-4.19C181.38,208.98,181.04,215.01,180.76,221z"
            />
            <path
              style="fill: #3c5322"
              d="M121.07,341.83c5.67-4.08,12.56-1.95,18.7-3.68c5.28-1.49,11.6-1.23,13.37-8.33
            c4.21,5.15,3.93,6.94-5.13,9.31c-8.88,2.33-12.62,6.17-9.09,15.2C131.02,352.97,130.52,340.99,121.07,341.83z"
            />
            <path
              style="fill: #4e6e2b"
              d="M150.73,294.8c4.05-1,8.11-3.24,12.12,0.11c-7.15,1.25-13.64,14.45-21.89,1.21
            C143.98,292.47,147.95,297.98,150.73,294.8z"
            />
            <path
              style="fill: #2e3d1e"
              d="M196.31,297.3c-0.21,2.97,1.78,7.16-3.24,6.36c-3.36-0.54-5.41-4.01-5.88-7.23
            c-0.57-3.9,3.13-4.47,5.83-4.72C198.26,291.23,195.68,295.44,196.31,297.3z"
            />
            <path
              style="fill: #4e712b"
              d="M172.72,233.79c2.67-0.91,5.33-1.81,8-2.72c-0.61,1.57-2.46,3.15,0.03,4.71
            c-0.53,3.67-2.23,6.62-4.83,9.53C172.16,241.55,171.28,237.96,172.72,233.79z"
            />
            <path
              style="fill: #425b36"
              d="M170.55,307.28c2.56,1.74,5.13,3.49,7.69,5.24c-2.25,0.82-4.46,2.03-6.78,2.35
            c-2.32,0.31-5.56,0.47-6.28-2.39C164.15,308.41,168.26,308.54,170.55,307.28z"
            />
            <path
              style="fill: #64854c"
              d="M232.15,344.5c0.37,5.5-5.74,8.79-4.93,14.44c-2.6-3.1-5.2-6.2-7.8-9.3
            C223.67,347.92,227.91,346.21,232.15,344.5z"
            />
            <path
              style="fill: #585c40"
              d="M134.28,267.57c2.28,0.13,4.57,0.26,6.85,0.39c3.23,5.56,1.9,9.05-4.63,10.16
            C131.38,275.54,130.84,271.98,134.28,267.57z"
            />
            <path
              style="fill: #848568"
              d="M134.28,267.57c-0.9,3.86,0,7.35,2.23,10.55c-1.71,1.68-3.43,3.35-5.14,5.03
            c-1.12-1-2.24-2-3.36-3C128.7,275.25,129.34,270.34,134.28,267.57z"
            />
            <path
              style="fill: #667156"
              d="M220.11,334.49c-2,2.54-4.84,2.81-7.75,2.91c-1.7-1.68-4.73-3.99-2.44-5.93
            C213.99,328.01,217.33,331.26,220.11,334.49z"
            />
            <path
              style="fill: #455b29"
              d="M207.84,268.39c3.7,0.58,6,2.74,5.56,6.48c-0.37,3.16-3.47,2.65-5.77,2.94
            C205.67,274.62,207.18,271.52,207.84,268.39z"
            />
            <path
              style="fill: #27341b"
              d="M-130.26,161.8c-0.52-4.05-4.34-4.06-6.8-5.87c-4.64-3.42-7.48-9.87-1.86-12.76
            c7.42-3.81,3.28-8.77,4.04-13.26c0.32-6.81,4.62-9.12,10.64-9.49c9.33,1.6,16.84,9.04,27.61,7.27
            c5.63-0.93,7.93,5.44,6.91,10.61c-1.44,7.29-0.36,14.57-1.12,21.82c-0.77,3.27-2.78,5.35-5.94,6.36
            c-7.82-1.15-15.65-0.79-23.47,0.27c-1.67,0-3.33,0.01-5,0.01C-129.26,167.47-130.97,165.87-130.26,161.8z"
            />
            <path
              style="fill: #303f1c"
              d="M-78.02,178.93c2.35,4.26,4.96,8.41,6.91,12.85c0.87,1.99,2.25,5.25-0.23,6.59
            c-1.26,0.67-4.02-1.46-6.42-2.46c-2,9.83,3.46,18.35,4.29,27.55c-1.68-1.76-3.03-4.3-5.09-5.17
            c-17.35-7.36-22.77-22.05-24.58-39.1c0.58-1.01,1.37-1.79,2.4-2.33c3.39-1.25,6.71-0.47,9.2,1.67
            C-86.94,182.49-82.59,181.19-78.02,178.93z"
            />
            <path
              style="fill: #3e5221"
              d="M-112.87,225.98c-4.72-3.53-8.99-7.51-13.14,1.22c-2.14,4.5-7.2,7.84-11.54,2.45
            c-3.07-3.82-5.93-9.24,0.24-13.68c3.27-2.35,6.48-4.78,9.72-7.17c1.68-0.77,3.35-1.54,5.02-2.3c3.39,1.59,5.62,0.33,7.04-2.89
            c5.42-3.22,9.59-3.14,9.47,4.53c-0.09,5.99,3.11,11.78,0.81,17.85C-107.83,232.81-110.33,230.48-112.87,225.98z"
            />
            <path
              style="fill: #3e5321"
              d="M-100.57,179.1c-0.85,0.03-1.71,0.06-2.56,0.09c-2.44-8.65-13.85-4.95-17.12-12.45
            c9.92-10.92,13.62-11.32,22.15-2.43C-93.19,170.21-96.33,174.75-100.57,179.1z"
            />
            <path
              style="fill: #384923"
              d="M-130.26,161.8c1.67,1.65,3.34,3.31,5,4.96c-3.17,4.96-7.37,8.93-11.99,12.49
            c-4.51-0.03-4.33-2.5-2.91-5.61c-0.23-3.8-3.13-6.17-4.9-9.14C-140.65,160.72-135.29,162.17-130.26,161.8z"
            />
            <path
              style="fill: #475228"
              d="M-140.15,173.65c0.97,1.87,1.93,3.74,2.9,5.61c-2.76,1.71-6.13,6-8.69,1.79
            C-148.87,176.21-144.34,174.43-140.15,173.65z"
            />
            <path
              style="fill: #5d7428"
              d="M-115.53,203.6c4.06,1.41,2.91,3.27,0.25,4.87c-2.96,1.78-6.14,3.35-7.29-1.98
            C-120.22,205.53-117.87,204.56-115.53,203.6z"
            />
            <path
              style="fill: #496b29"
              d="M12.9-136.61c-4.14-12.76,9.43-12.66,14.03-19.07c6.08-8.47,14.23-14.19,25.47-13.37
            c2.56,3.37,1.93,8.16,5.06,11.28c3.48,7.14-3.89,12.55-3.04,19.29c-0.55,2.48-1.89,4.37-4.15,5.57
            c-1.1,0.44-2.26,0.63-3.44,0.64c-2.67-0.07-5.56-1.49-7.14,2.47c-4.35,10.87-10.48,7.43-16.77,1.88
            c-2.06-1.37-3.99-2.94-6.08-4.3C15.22-133.4,14.49-135.39,12.9-136.61z"
            />
            <path
              style="fill: #3f5c24"
              d="M55.07-155.6c-2.65-5.44-7.37,0.24-10.64-1.68c-0.76-6.51,7.51-6.42,7.97-11.77
            c8.21-2.47,12.51,6.31,19.67,6.84c1.13,1.86,2.38,3.53,4.94,3.13c1.59,0.83,3.18,1.65,4.77,2.48c3.37,2.75,7.84,3.63,11.09,6.57
            c2.94,6.74-4.19,9.47-5.72,14.47c-2.88,2.81-6.45,2.44-10,2.17c-4.38,0.51-8.38-0.16-11.43-3.74
            c-1.96-3.34-2.25-7.47-4.87-10.52C58.9-150.28,57.03-152.97,55.07-155.6z"
            />
            <path
              style="fill: #56792c"
              d="M-29.1-126.95c3.17-6.47,8.48-10.4,15.11-12.77c2.5,2.23,9.38-3.31,8.55,4.81
            c-0.43,2.57,0.32,4.7,2.44,6.34c0.7,0.86,1.2,1.8,1.59,2.83c0.46,1.67,0.48,3.35,0.3,5.05c-2.4,7.69,2,16.73-4.66,23.58
            c-1.65,1-3.32,0.85-4.99,0.04c-2.81-2.63-5.56-5.33-8.51-7.83c-2.11-1.8-4.52-3.17-6.92-4.61
            C-32.48-114.44-32.89-120.35-29.1-126.95z"
            />
            <path
              style="fill: #191f14"
              d="M-11.55-154.65c-2.36-4.03-4.52-8.6-0.64-12.34c3.84-3.71,8.24-0.81,10.53,2.38
            c2.7,3.77,12.41,0.97,9.45,8.2c-2.68,6.54-9.36,9.93-17.27,7.17C-11.39-150.58-11.97-152.42-11.55-154.65z"
            />
            <path
              style="fill: #4e6a2a"
              d="M86.61-136.99c-1.84-3.1-6.67-3-7.75-7.3c3.87-2.86,11.1,3.27,12.89-4.71
            c-3.85-6.11-3.46-9.5,4.91-7.42c2.6,0.65,5.12,2.82,7.55-0.26c0.83-0.16,1.67-0.32,2.5-0.49c4.87,5.57,12.25-2.69,17.09,3.08
            c-3.95,6.88-10.31,11.42-16.06,16.58c-5.93,1.44-12.51-3.54-18.04,1.88C88.47-135.65,87.46-136.13,86.61-136.99z"
            />
            <path
              style="fill: #524f35"
              d="M-4.48-122.6c0.21-1.21,0.43-2.41,0.64-3.62c3.65-6.68,11.65-4.45,16.74-8.18
            c0.01-0.74,0.01-1.47,0.01-2.21c1.63,0.7,3.25,1.39,4.87,2.08c5.07,8.65,8.79,17.79,9.94,27.86c-0.76,4.03-3.48,6.39-6.92,8.15
            c-2.62,0.47-4.66-0.53-6.34-2.46c-2.84-4.02,3.39-11.33-4.86-13.39c-2.65-1.03-5.44-1.41-8.24-1.77
            C-1.84-117.17-3.7-119.4-4.48-122.6z"
            />
            <path
              style="fill: #3b5524"
              d="M-29.1-126.95c3.49,4.42,0.03,12.34,7.42,14.79c-2.32,10.32-9.73,8.72-17.07,7.34
            c-3.29-1.87-3.98-5.46-5.55-8.46c-0.92-3.01-0.75-6.11-0.66-9.2c0.24-2.99,0.93-5.74,4.07-7.06
            C-37.1-128.03-33.62-125.13-29.1-126.95z"
            />
            <path
              style="fill: #678c2f"
              d="M104.2-156.68c-1.54,3.23-0.48,10.38-7.65,3.52c-4.5-4.31-4.46,1.17-4.81,4.16
            c-5.29,0.06-9.93-0.75-9.96-7.6c1.27-2.86,2.53-5.73,3.08-6.95C91.49-161.2,97.84-158.94,104.2-156.68z"
            />
            <path
              style="fill: #8a8954"
              d="M-48.12-146.78c0,0,1.22,0.05,1.22,0.05c0.53,4.26,4.29,7.66,3.33,12.32
            c-0.29,1.83-1.05,3.4-2.53,4.58c-4.49,1.81-8.94,4.08-13.95,1.7c-3.34-2.94-6.11-6.13-4.61-11.08
            C-60.09-143.77-54.36-145.82-48.12-146.78z"
            />
            <path
              style="fill: #7e942e"
              d="M-43.79-149.46c-0.34-1.13-1.24-2.46-0.92-3.37c1.13-3.29-3.02-8.57,1.05-9.59
            c5.3-1.33,6.98,3.77,7.38,8.5C-37.46-150.21-37.7-144.91-43.79-149.46z"
            />
            <path
              style="fill: #afb475"
              d="M-26.4-178.17c-5.71,0.03-2.39,8.71-8.6,8.61c-3-4.3-1.49-8.42,1.97-11.26
            c3.73-3.07,7.42-0.07,10.84,1.87C-23.6-178.69-25-178.43-26.4-178.17z"
            />
            <path
              style="fill: #73854c"
              d="M-46.32-132.16c0.91-0.75,1.83-1.5,2.74-2.24c0.89,1.62,1.79,3.24,2.68,4.86
            c0.21,1.9,1.99,4.12-1.08,5.36c-1.38,2.66-2.19,5.94-6.23,5.67C-58.59-124.58-50.73-128.13-46.32-132.16z"
            />
            <path
              style="fill: #56792c"
              d="M12.89-134.4c-5.04,3.82-8.97,9.93-16.74,8.17c-0.27-0.33-0.54-0.66-0.82-0.98
            c-1.29-1.65-1.8-3.46-1.16-5.51c0.27-0.98,0.85-1.71,1.74-2.19C1.57-134.74,7.23-134.57,12.89-134.4z"
            />
            <path
              style="fill: #171c11"
              d="M71.88-173.91c1.83,0.04,3.66,0.09,5.5,0.13c-0.02,0.69,0.03,1.38,0.14,2.06
            c-3.08,2.57-6.17,6.07-10.57,3.83c-2.92-1.48-1.38-4.1,0.27-6.09C68.78-173.95,70.33-173.93,71.88-173.91z"
            />
            <path
              style="fill: #3e6027"
              d="M-6.79-134.64c-0.32-4.65-6.08-1.56-7.2-5.08c4.1-0.48,7.53-6.11,11.77-2.68
            c2.2,1.78-1.07,4.94-1.87,7.48c-0.29,0.37-0.52,0.78-0.69,1.22C-5.71-133.45-6.37-133.79-6.79-134.64z"
            />
            <path
              style="fill: #49722b"
              d="M-11.55-154.65c0.69,1.8,1.38,3.61,2.07,5.41c-2.82,0.55-5.54,6.17-7.88,2.14
            C-19.27-150.37-15.62-153.66-11.55-154.65z"
            />
            <path
              style="fill: #4d7a2c"
              d="M77.02-159.08c-0.32,3.5-0.51,7.07-3.74,9.78c-4.02-4.33-0.46-8.79-1.2-12.9
            C73.72-161.16,75.37-160.12,77.02-159.08z"
            />
            <path
              style="fill: #1b2015"
              d="M345.68,309.91c-8.64-0.73-11.71,5.53-14.78,11.79c-2.46,5.57-7.33,5.7-12.35,5.48
            c-12.72-0.41-13.97-12.29-19.69-19.71c-5.55-8.85-4.56-11.2,8.19-19.58c12.24-3.7,22.31,4.18,33.48,6.29
            c5.6,1.26,6.39,6.01,7.58,10.47C348.09,306.77,347.32,308.54,345.68,309.91z"
            />
            <path
              style="fill: #466628"
              d="M308.36,290.17c-2.7,6.02-14.85,6.85-9.51,17.3c-1.87,0.54-4.74,1.51-4.88-1.1
            c-0.96-17.64-8.66-6.87-14.6-3.63c-2.32-0.74-4.51-1.71-6.15-3.65c-0.7-1.13-1.05-2.35-1.09-3.67c0.41-3.59,1.76-6.57,5.25-8.17
            c5.39-1.37,10.83-2.46,16.26-3.64C300.25,282.17,305.41,283.58,308.36,290.17z"
            />
            <path
              style="fill: #637632"
              d="M318.54,327.18c2.35-5.83,6.84-6.81,12.35-5.47c4.8,5.09,14.7,1.67,17.32,10.39
            c-3.91,9.94-13.29,4.68-19.88,7.17C323.34,336.64,321.83,331.19,318.54,327.18z"
            />
            <path
              style="fill: #42502c"
              d="M328.34,339.26c5-6.91,11.52-9.59,19.88-7.17c2.88,3.67-0.79,8.65,2.44,12.25
            c-1.31,1.38-2.68,1.04-4.07,0.15c-6.7,3.65-12.78,14.34-20.92,1.86C326.36,343.92,335.54,344.67,328.34,339.26z"
            />
            <path
              style="fill: #556357"
              d="M325.68,346.36c7.28,2.87,13.96-0.91,20.91-1.86c-2.02,2.51-4.05,5.02-6.08,7.53
            c-3.16,2.44-6.19,5.07-9.51,7.26c-2.63,1.74-5.55,1.53-7.43-1.26c-2.42-3.59-5.58-7.4-3.3-12
            C321.35,343.84,323.82,346.25,325.68,346.36z"
            />
            <path
              style="fill: #405a25"
              d="M275.89,308.07c7.7-2.57,14.82-4.27,13.86,8.14c-0.16,2.07,4.42,3.3,1.8,6.03
            c-2.9,3.01-6.1-0.01-7.33-1.55c-4.93-6.2-10.09-2.22-15.24-1.22C265.37,312.07,269.02,309.09,275.89,308.07z"
            />
            <path
              style="fill: #51752b"
              d="M254.24,344.44c-6.68-0.2-7.32-4.7-7-9.87c2.42-2.45,4.84-4.89,7.26-7.34
            c1.23-5.73,3.78-8.87,9.87-5.02c1.46,0.73,6.91,0.23,3.31,2.73C260.36,329.99,255.94,336,254.24,344.44z"
            />
            <path
              style="fill: #405a25"
              d="M264.37,322.21c-3.47,1.32-7.49,1.56-9.87,5.02c-0.72-0.77-1.44-1.55-2.16-2.31
            c-1.91-4.16-4.67-8.25-1.25-12.93c1.04-6.22,5.68-6.88,10.71-6.98c1.16,0.22,2.23,0.69,3.21,1.36c3.37,3.85,1.57,8.57,2.14,12.9
            C266.22,320.24,265.29,321.23,264.37,322.21z"
            />
            <path
              style="fill: #6f962e"
              d="M267.14,319.26c-4.09-3.19-4.14-7.28-2.61-11.72c0.44-7.95,7.86-7.12,12.42-9.9
            c0.8,1.7,1.61,3.4,2.42,5.1c-1.18,1.77-5.87,1.23-3.48,5.33c-2.3,3.8-4.6,7.6-6.9,11.4
            C268.37,319.47,267.75,319.4,267.14,319.26z"
            />
            <path
              style="fill: #5f7a3d"
              d="M290.84,353.93c-3.69,1.17-5.75,5.44-10.12,5.3c0.14-0.8,0.12-1.68,0.47-2.37
            c2.33-4.57-10.9-6.72-2.41-11.15C285.15,342.37,287.98,349.33,290.84,353.93z"
            />
            <path
              style="fill: #1e2817"
              d="M-164.47-1.15c-1.57-0.63-3.13-1.26-4.7-1.88c4.18-5.89,3.65-13.12,5.72-19.53
            c1.22-3.79,4.6-9.22,11.01-5.59c4.04,0.78,2.99-3.59,4.96-4.91c-1.78-8.18,6.78-0.46,7.45-4.85c7.24,0.66,10.68,3.92,7.85,11.48
            c1.43,1.88,2.87,3.77,4.3,5.65c1.56,1.38,2.49,3.08,2.62,5.19c-0.5,4.52-3.11,7.69-7.2,9c-8.11,2.6-16.38,4.54-23.99,8.68
            C-159.54,3.77-162.82,2.49-164.47-1.15z"
            />
            <path
              style="fill: #35481e"
              d="M-154.75-52.84c0.92-4.81-5.08-9.7,0.18-14.45c8.83,4.44,9.47-1.36,9.64-7.74
            c2.87-6.52,9.82-8.3,14.61-12.6c5.5,0.94,10.93,0.38,16.34-0.76c8.53,1.29,9.81,7.63,10.17,14.6c-0.19,1.33-0.74,2.5-1.66,3.49
            c-5.46,3.24-6.22-0.42-6.66-4.55c-0.38-3.56-2.62-4.85-5.9-4.72c-2.59,0.1-4.35,1.29-5.91,3.57
            c-5.94,8.65-12.17,17.11-18.29,25.63c-4.37-0.08-8.88,0.6-12.65-2.5L-154.75-52.84z"
            />
            <path
              style="fill: #405b26"
              d="M-127.71-15.86c-0.05-1.64-0.1-3.29-0.15-4.93c2.89-1.92,14.55,5.5,7.48-7.04
            c1.3-1.66,2.88-1.92,4.7-0.95c3.23,17.12,4.87,18.55,17.45,15.32c3.37-1.02,4.48,1.33,5.58,3.72
            c2.62,10.24-8.91,12.14-11.4,19.38c-0.79,2.31-0.9,4.89-1.99,7.02c-3.31,6.54-8.7,7.25-11.59,0.73
            C-122.32,6.83-127.98-3.69-127.71-15.86z"
            />
            <path
              style="fill: #48682e"
              d="M-95.59-8.3c-0.88-1.72-1.76-3.44-2.64-5.16c-2.17-6.65-4.34-13.29-6.8-20.83
            c6.65,1.69,6.67,7.02,9.26,10.28c2.46,3.11,5.49,7.4,9.68,5.2c4.1-2.15,2.67-7.2,1.52-11.04c-0.67-2.27-2.31-4.24-3.52-6.35
            c-2.39-6.46,2.09-6.95,6.5-7.52c5.58,0.74,9.32,4.46,9.05,9.51c-0.41,7.77,2.63,14.89,2.69,22.46
            c-1.66,4.44-2.64,9.33-7.42,11.82C-85.64,2.28-91.11-1.92-95.59-8.3z"
            />
            <path
              style="fill: #37491e"
              d="M-95.67-48c-2.2-6.18,5.56-10.95,2.45-17.26c-6.17-5.08-5.54-11.81-4.54-18.64
            c0.62-1.2,1.54-2.11,2.72-2.76c8.22,1.2,19.19-7.03,23.78,6.88c-6.89,11.4-5.47,24.79-8.7,37.08
            C-86.32-41.15-92.73-39.43-95.67-48z"
            />
            <path
              style="fill: #506e28"
              d="M-95.51-85.04c1.52,6.5,2.87,13.03,2.29,19.78c-5.19,1.02-8.43-2.73-12.26-5.03
            c-0.02-0.82-0.06-1.64-0.13-2.46c-0.84-3.28-0.08-6.62,0.38-9.75C-104.13-90.07-100.77-91.94-95.51-85.04z"
            />
            <path
              style="fill: #6a8d75"
              d="M-115.68-28.77c-1.57,0.32-3.14,0.63-4.7,0.95c-3.57-4.63-0.35-11.77-5.86-15.83
            c-0.63-0.47-0.37-3.29,0.41-4.26c1.29-1.61,2.58-0.11,3.98,0.82C-114.97-42.54-114.46-35.98-115.68-28.77z"
            />
            <path
              style="fill: #5d8431"
              d="M-132.17-26.44c-1.03-4.92-2.72-9.37-7.85-11.48c-1.53-3.49-1.73-6.42,3.01-7.37
            c4.82-0.97,4.52,2.86,4.33,5.35C-133.04-35.38-122.61-31.21-132.17-26.44z"
            />
            <path
              style="fill: #4d6933"
              d="M-95.67-48c5.59-0.26,8.55,7.18,14.78,5.04c0.54,0.44,0.78,0.93,0.72,1.47
            c-0.07,0.57-0.17,0.85-0.32,0.85c-3.3,0.19-6.42,0.67-7.6,4.46C-95.11-37.24-97.94-40.98-95.67-48z"
            />
            <path
              style="fill: #63803a"
              d="M-154.89-52.86c4.67-1.47,9.87-5.62,12.65,2.5C-148.42-41.23-152.46-42.93-154.89-52.86z"
            />
            <path
              style="fill: #405b24"
              d="M-147.47-33.06c-0.32,4.46,5.37,7.04,3.88,12.07c-5.1,0.03-5.65-5.21-8.84-7.16
            C-151.98-31.01-150.45-32.76-147.47-33.06z"
            />
            <path
              style="fill: #32451d"
              d="M288.96,245.69c-1.62,2.24-3.25,4.48-5.75,7.94c-2.1-5.04-2.33-10.23-6.67-12.77
            c-1.49-3.06-4.28-4.48-7.09-6.08c-10.25-5.83-11.16-10.34-4.56-20.94c4.72-2.1,9.97-2.57,12.74,2.12
            c5.28,8.94,6.46,0.83,9.06-1.67c5.31-3.66,10.44-7.56,15.37-11.72c0.87-1.36,2-2.47,3.34-3.37c5.7-2.97,7.42-8.8,5.35-13.53
            c-5.94-13.54,2.88-11.55,10.64-11.24c4.13,7.06,6.57,15,11.25,21.79c0.57,3.71-1.09,6.67-3.16,9.49
            c-3.79,4.22-1.68,9.5-2.53,14.26c-0.69,3.98-2.98,6.62-6.71,8.05c-5.15-0.14-9.85-1.05-13.52,5.09
            C302.95,239.42,294.78,241.26,288.96,245.69z"
            />
            <path
              style="fill: #97b72c"
              d="M311.11,161.89c-5.02,1.3-10.87,0.07-14.72,4.9c-1.57,1.08-3.22,1.04-4.92,0.37
            c-5.92-2.61-10-6.56-9.96-13.57c0.68-4.77,4.44-6.92,7.92-9.3c2.55-1.96,5.81-3.38,7.34-6.2c6.59-12.21,15.46-10.39,25.19-4.55
            c2.18,2.54,3.22,5.23,0.98,8.29c-1.49,1.76-2.98,3.53-4.47,5.29C318.03,153.06,317.89,159.13,311.11,161.89z"
            />
            <path
              style="fill: #415a24"
              d="M363.37,196.59c-1.85,2.76-4.46,4.34-7.7,4.91c-0.87-0.87-1.75-1.75-2.62-2.62
            c-2.02,3-4.26,5.73-7.99,6.69c-1.22,0.07-2.39-0.13-3.52-0.58c-4.2-2.13-4.7-6.53-6.32-10.24c-1.26-3.06-1.74-6.41-3.24-9.41
            c-1.37-3.71-4.38-7.33-0.71-11.34c5.13,0.42,11.17-0.98,12.97,6.25c0.69,2.78,1.01,6.82,4.45,6.27c4.5-0.72,2.39-5.2,2.65-8.19
            c0.18-2.02,0.2-4.05,0.36-7.67C363.48,176.61,356.92,189.71,363.37,196.59z"
            />
            <path
              style="fill: #435325"
              d="M306.15,203.65c-4.01,6.49-10.33,9.8-17.14,12.41c-6.62-7.83-15.17-3.93-23.12-3.79
            c-2.22-1.37-3.56-3.33-4.02-5.9c-0.25-2.6,0.61-4.81,2.39-6.68c7.98-2.21,7.18-8.59,6.94-14.73c1.22-3.04,3.37-5.19,6.23-6.67
            c3.1-1.31,6.18-2.4,8.97,0.64c-4.11,8.2-3.05,15.71,2.97,22.58C295.06,201.46,301.66,194.28,306.15,203.65z"
            />
            <path
              style="fill: #5a792b"
              d="M335.86,184.08c0.59,3.26,8.37,4.78,2.32,9.64c-1.38,3.74-3.61,5.19-7.3,2.72
            c-9.91-3.04-13.52-11.41-9.49-22.02c1.47-0.12,2.94-0.25,4.4-0.37C331.33,175.2,331.15,182.09,335.86,184.08z"
            />
            <path
              style="fill: #3a5322"
              d="M322.93,141.84c-0.69-2.34-1.39-4.68-2.08-7.01c-2.23-2.68-2.52-5.55-0.89-8.63
            c1.58-1.88,2.95-3.99,5.24-5.14c1.18-0.36,2.38-0.44,3.6-0.24c2.95,2.02,6.37,2.91,9.76,3.9c1.59,1,2.64,2.4,3.16,4.2
            c0,0.59-0.02,1.19-0.02,1.79c-0.32,0.5-0.65,1-0.97,1.51C335.13,136.05,334.13,148.39,322.93,141.84z"
            />
            <path
              style="fill: #85ae28"
              d="M311.11,161.89c-0.2-6.24,2.89-10.84,7.35-14.75c2.14,4.67,5.87,9.16,3.21,14.66
            c-1.27,2.62-0.42,7.29-5.21,7.24C311.85,168.98,312.29,164.73,311.11,161.89z"
            />
            <path
              style="fill: #415a24"
              d="M346.38,157.18c-0.59,9.36-5.36,10.67-10.36,11.1c-2.28,0.19-6.03-2.96-5.07-3.45
            c5.34-2.67,2.96-11.21,8.98-11.96C343.7,152.4,346.43,155.67,346.38,157.18z"
            />
            <path
              style="fill: #405f26"
              d="M360.23,139.57c-2.17,7.44,1.53,14.87-0.19,24.83c-2.94-9.84-11.18-14.85-9.65-24.29
            C353.49,136.43,356.79,136.79,360.23,139.57z"
            />
            <path
              style="fill: #718e37"
              d="M306.15,203.65c-5.11-4.49-11.68,2.4-16.78-2.14c4.44-7.12,8.59-16.22,16.05-2.32
            C305.66,200.68,305.9,202.17,306.15,203.65z"
            />
            <path
              style="fill: #7ba92a"
              d="M360.23,139.57c-3.28,0.18-6.56,0.36-9.84,0.54c-5.43,0.08-10.2-0.65-9.67-7.9
            c0,0,0.44,0.5,0.44,0.5c3.71-2.47,6.79-7.38,12.22-3.15c-0.29,5.28,5.41,4.73,7.49,7.72
            C360.63,138.04,360.42,138.8,360.23,139.57z"
            />
            <path
              style="fill: #7ca92e"
              d="M286.4,178.93c-2.27,0.78-4.55,1.57-6.82,2.35c-2.69-1.75-2.26-4.07-1.22-6.51
            c4.41-4.61,7.01-11.86,15.44-10.6c0.86,0.87,1.73,1.74,2.59,2.62C295.9,173.17,288.95,174.24,286.4,178.93z"
            />
            <path
              style="fill: #85ac27"
              d="M335.86,184.08c-7.34,0.66-6.73-6.67-10.07-10.04c1.81-1.31,3.63-2.4,5.46-0.03
            C332.79,177.37,334.33,180.72,335.86,184.08z"
            />
            <path
              style="fill: #4a6a24"
              d="M330.95,107.71c0.75,1.68,1.51,3.35,2.26,5.03c-4.2,4.81-10.17,4.94-15.81,5.99
            c-3.76,0.83-7.5,1.75-11.14-0.44c-1.82-0.88-3.28-2.2-4.58-3.72c-5.58-9.01-1.52-16.33,4.1-23.38c4.9-2.67,8.82-0.95,12.28,2.76
            c1.43,1.18,2.71,2.5,3.59,4.14C322.96,103.03,327.06,105.27,330.95,107.71z"
            />
            <path
              style="fill: #7ba92a"
              d="M340.57,130.22c-0.61-0.9-1.21-1.8-1.82-2.7c1.96-4.87,0.13-10.91,4.6-15
            c5.51-0.77,11-2.02,16.53-2.17c4.8-0.13,9.8,0.72,11,6.94c-0.96,1.99-2.03,5.96-4.58,3.05c-8.13-9.28-10.36-1.41-13.65,4.06
            C349.94,129.07,346.46,132.14,340.57,130.22z"
            />
            <path
              style="fill: #597f2b"
              d="M335.29,75.19c3.02,14.55,4.17,15.3,15.35,10.02c0.32,9.2-2.94,11.5-21.65,15.25
            c-1.04-0.62-1.72-1.51-2.01-2.7c3.17-7.22-2.23-16.34,4.99-22.68C333.09,74.63,334.2,74.67,335.29,75.19z"
            />
            <path
              style="fill: #2b3b1b"
              d="M343.36,112.52c-2.69,4.65,6.73,13.02-4.6,15c-4.3,0.17-10.29,3.85-10.26-4.96
            c0.18-6.26,7.21-3.87,9.46-7.5C339.76,114.21,341.56,113.36,343.36,112.52z"
            />
            <path
              style="fill: #9ab12e"
              d="M337.96,115.06c0.84,7.56-6.93,4.21-9.46,7.5c-0.68,0.05-1.34,0.19-1.98,0.41
            c-4.44-0.99-9.16-1.69-7.87-8.19c4.02-6.65,9.66-1.73,14.57-2.04C334.79,113.52,336.37,114.29,337.96,115.06z"
            />
            <path
              style="fill: #9ab12e"
              d="M328.82,97.81c0.06,0.88,0.11,1.77,0.17,2.65c0.65,2.42,1.31,4.84,1.96,7.25
            c-4.6-2.07-17.31,8.18-12.48-8.23C321.33,95.29,324.66,93.97,328.82,97.81z"
            />
            <path
              style="fill: #81a12f"
              d="M335.29,75.19c-0.54,0.17-1.09,0.33-1.65,0.47c-5.46,1.73-8.26-0.49-8.85-5.99
            c-0.22-1.91-0.71-3.8-0.44-5.75c0.9-3.35,2.01-6.47,6.43-6.08c3.21,4.36,2.4,11.85,9.96,12.81
            C338.93,72.17,337.11,73.68,335.29,75.19z"
            />
            <path
              style="fill: #85ae26"
              d="M340.78,70.56c-0.31-8.72,6.83-9.8,12.59-12.28C354.96,68.32,346.37,67.9,340.78,70.56z"
            />
            <path
              style="fill: #789f2a"
              d="M362.38,71.14c0.07,2.36-2.22,3.38-4.65,3.94c-1.51,0.35-3.59,0.48-2.19-2.31
            c0.88-1.76-1.75-5,2.43-5.41C360.42,67.12,361.97,67.72,362.38,71.14z"
            />
            <path
              style="fill: #5a7332"
              d="M-157.77-132.39c-3.05-10.95,7.72-4.24,10.26-8.05c7.33-1.69,13.69-8.48,22.16-4.25
            c1.94-3.43,3.17-7.65,8.78-6.06c6.98,1.98,7.64,7.56,8.16,13.34c1.78,6.42,0.49,13.12,1.65,19.62
            c0.28,3.78,0.25,7.49-2.18,10.73c-7.14,5.4-11.95,2.59-15.57-4.41c-0.35-1.15-0.43-2.34-0.34-3.53
            c-0.03-3.36,4.89-6.88-0.34-9.98c-2.78,3.2-4.64,7.7-10.1,6.54c-5.52-4.76-10.39-10.69-18.69-10.52
            C-155.55-129.77-156.98-130.72-157.77-132.39z"
            />
            <path
              style="fill: #3f4c26"
              d="M-112.76-135.42c0.14-9.01-1-16.25-12.59-9.26c0.01-2.48,0.01-4.95,0.02-7.43
            c0.16-5.09,3.78-5.61,7.55-5.4c5.68,0.31,11.1-1.07,16.6-2.07c3.45,2.44,7.24,0.32,10.83,0.79c2.18,1.28,3.58,2.91,2.49,5.64
            c-5.56,4.78-8.58,11.7-13.91,16.71C-105.35-135.23-108.59-130.16-112.76-135.42z"
            />
            <path
              style="fill: #7c9133"
              d="M-101.19-159.58c-6.43,7.74-15.56,6.73-24.15,7.47c-10.62-1.94-5.78-8.7-4.48-14.36
            c0.68-1.22,1.32-1.2,1.92,0.06c3.61,4.54,7.67,6.68,12.91,2.39c0.86-0.7,1.66-0.63,2.4,0.2c2.72,2.51,6.05,2.74,9.5,2.6
            C-102.46-160.67-101.82-160.13-101.19-159.58z"
            />
            <path
              style="fill: #5d792c"
              d="M-147.42-141.49c-4.7-3.02-9.41-6.04-14.11-9.07c0.35-0.74,0.57-1.98,1.07-2.11
            c5.85-1.53,9.42-12.73,16.5-6.1C-138.13-153.33-145.98-147.32-147.42-141.49z"
            />
            <path
              style="fill: #7cb228"
              d="M-127.89-166.41l-0.96-0.07c0,0-0.96,0-0.96,0c-4.61-2.89-3.45-6,4.65-12.5
            C-126.08-174.79-126.98-170.6-127.89-166.41z"
            />
            <path
              style="fill: #637849"
              d="M345.56-161.5c6,11.75,1.95,25.38,7,37.31c-4.03,4.6-8.82,3.18-13.63,1.75
            c-10.68-11.11-3.9-21.96,0.51-32.86C340.22-158.61,341.83-161.11,345.56-161.5z"
            />
            <path
              style="fill: #899b8b"
              d="M345.56-161.5c-0.78,2.6-1.56,5.2-2.34,7.8c-4.93,5.63-8.85,4.07-9.93-2.48
            c-1.16-6.99-2.68-14.74,4.72-20c0.12-3.51,2.07-5.48,5.32-6.38C349.23-176.08,342.05-168.22,345.56-161.5z"
            />
            <path
              style="fill: #96a666"
              d="M340.28-124.24c4.11-3.8,8.18,0.75,12.27,0.05c0.45,2.65,1.44,5.41-1.34,7.49
            c-3.47-0.14-6.94-0.28-10.41-0.42C338.84-119.37,338.4-121.72,340.28-124.24z"
            />
            <path
              style="fill: #ccb8a1"
              d="M-48.12-146.78c-2.47,8.13-6.89,12.24-15.57,7.58c-3.18,0.68-4.2-1.51-5.12-3.84
            c1.82-7.51-4.19-14.63-1.19-22.21c0.43-0.4,0.86-0.79,1.3-1.19c4.15,2.49,6.56,8.1,12.67,7.07c6.58,1.86,3.08,9.47,7.24,12.7
            L-48.12-146.78z"
            />
            <path
              style="fill: #c0b37c"
              d="M-75.9-178.99c2.01,5.69,6.4,2.33,9.83,2.6c2.32,2.51,1.79,5-0.07,7.47
            c-0.86,0.83-1.71,1.66-2.57,2.49c0,0,0.29-0.03,0.29-0.03c-4.93,4.3-9.43,4.49-13.28-1.41c-0.5-1.23-0.82-2.5-0.94-3.83
            C-84.36-177.81-80.79-179.02-75.9-178.99z"
            />
            <path
              style="fill: #9e8c5b"
              d="M-66.14-168.93c0.02-2.49,0.05-4.98,0.07-7.47c-0.39-5.38,2.49-7.65,7.5-7.9
            C-50.67-174.03-59.99-172.26-66.14-168.93z"
            />
            <path
              style="fill: #cfd2c7"
              d="M-48.79-146.67c-5.85-2.27-4.25-8.79-7.24-12.7c0.18-5.02,2.16-6.88,4.61-1.45
            C-49.5-156.58-49.59-151.42-48.79-146.67z"
            />
            <path
              style="fill: #2e3a20"
              d="M335.69-117.11c1.48,3.28,4.71,3.97,7.6,5.17c7.97,4.9,5.67,15.44,11.72,21.39
            c0.22,0.92,0.43,1.83,0.65,2.75c0.12,4.96,2.96,10.21-1.57,14.66c-1.21,1.29-2.54,2.53-2.31,4.59c0.76,2.58,1.31,5.14,0.06,7.72
            c-2.76,3.25-5.24,6.8-9.32,8.63c-4.4,0.5-8.88,1.38-12.47-2.36c-2.37-6.74-1.78-14.54-7.18-20.23
            c-1.78-5.67,2.23-11.48-0.42-17.13c-0.18-1.72-0.14-3.45-0.05-5.17c1.15-6.14,1.36-12.34,1.07-18.59
            c1.69-3.82,4.55-5.72,8.78-5.35C334.19-120.44,335.33-119.12,335.69-117.11z"
            />
            <path
              style="fill: #4a5b2b"
              d="M350.42-63.4c-1.56-1.47-3.13-2.93-4.69-4.4c2.86-1.59,2.54-5.3,4.83-7.28
            c6.66-2.25,1.63-9.19,5.09-12.73c4.24,19.33,8.47,38.65,12.71,57.98c-6.76,2.57-6.47,6.37-1.88,10.91
            c0.56,5.45-3.49,6.94-7.37,8.57c-3.73,1-6.7-0.96-9.78-2.52c-4.54-2.97-9.46-5.67-9-12.3c1.02-4.33,3.39-7.79,6.95-10.43
            c2.13-1.26,4.73-1.92,5.89-4.48c1.84-3.61-0.67-6.75-1.06-10.1c0.23-3.48,1.03-7.01-1-10.27
            C350.74-61.4,350.51-62.38,350.42-63.4z"
            />
            <path
              style="fill: #566533"
              d="M355.01-90.55c-15.23-0.92-10.63-12.72-11.72-21.39c5.8,1.37,11.59,2.73,17.38,4.1
            C352.93-104,356.09-96.58,355.01-90.55z"
            />
            <path
              style="fill: #68833b"
              d="M357.56,292.44c-1.74,5.99,8.26,8.06,4.88,14.6c-2.2,0.06-4.4,0.12-6.6,0.18
            c-6.29-5.93-12.88-11.68-12.58-21.5c1.62-2.91,3.22-5.91,6.94-2.24c0.77,4.21,4.68,4.95,7.56,6.83
            C357.79,291.03,357.72,291.74,357.56,292.44z"
            />
            <path
              style="fill: #343e1d"
              d="M345.6,287.78c1.63,7.42,11.82,10.32,10.24,19.43c-3.15,1.82-5.95,4.92-10.17,2.7
            c-0.1-1.48-0.19-2.96-0.29-4.45c0.55-4.6,0.09-8.47-5.06-10.63c-4.21-1.76-4.93-4.83-1.23-8.18
            C341.52,285.53,343.49,287.05,345.6,287.78z"
            />
            <path
              style="fill: #36471e"
              d="M-127.71-15.86c8.68,9.53,13.65,20.79,13.9,37.31c3.3-6.75,4.79-9.8,6.28-12.84
            c2.85-2.8,6.15-2.92,9.7-1.64c4.95,2.29,8.68,6.3,10.87,10.89c2.87,6,4.64,12.8-5.45,14.44c-5.22,0.52-7.99-5.6-13.94-5.1
            c1.95,2.47,5.28,2.63,6.23,5.33c3.21,8.76-0.36,16.02-5.01,23.04c0.56,11.89,5.32,22.4,10.58,32.77
            c-0.03,1.94-0.59,3.69-1.68,5.28c-2.21,2.64-5.46,3.03-8.46,3.95c-1.19,0.08-2.38-0.06-3.53-0.42
            c-3.27-5.24-9.24-1.44-13.36-3.91c-1.37-1.12-2.29-2.55-2.84-4.22c0.32-9.08-10.14-7.78-13.16-13.65
            c-3.03-4.84-9.06-5.95-12.6-10.58c-2.68-3.51-4.32-6.41-3.24-10.64c0.23-1.18,0.69-2.27,1.33-3.27
            c1.47-5.14,8.06-4.05,10.2-8.39c0.81-9.66-9.02-20.22,2.45-28.97c1.76,0.81,3.1,0.09,3.17-1.64c0.1-2.47-2.02-2.8-3.93-3.07
            c-0.34,0.21-0.66,0.45-1.02,0.63c-17.84,8.69-19.06,8.13-23.25-10.57C-153.19-8.5-138.95-8.42-127.71-15.86z"
            />
            <path
              style="fill: #788152"
              d="M-141.88,42.47c2.28,2.7,5.97,5.99-1.02,6.8c-3.29,0.38-4.45,2.76-6.59,4.2
            c-7.66-4.71-13.66-10.39-11.96-20.57c1.62-2.06,3.61-3.14,6.29-2.36C-150.74,34.52-146.31,38.5-141.88,42.47z"
            />
            <path
              style="fill: #a0a27f"
              d="M-159.63,33.54c-0.33,8.54,10.8,11.23,10.14,19.94c0,0-0.05,0.08-0.05,0.08
            c-1.57,2.1-3.16,4.17-5.54,5.44c-3.92,0.09-2.87-3.73-4.36-5.55c-0.45-6.99-4.49-12.42-8.05-17.99
            c-2.2-3.44,0.33-3.95,2.42-4.97C-162.66,30.43-160.66,31.11-159.63,33.54z"
            />
            <path
              style="fill: #85a13b"
              d="M-140.21,8.79c3.11-2.77,6.87-8.24,10.5-3.41c2.98,3.96-1,10.26-4.44,12.95
            c-5.37,4.2-5.57-0.83-5.3-4.83C-139.69,11.93-139.95,10.36-140.21,8.79z"
            />
            <path
              style="fill: #b5ac99"
              d="M-159.44,53.44c1.4,1.63,2.81,3.25,4.21,4.88c2.38,4.13,9.36,8.18,0.23,12.53
            c-1.71-1.72-3.41-3.45-5.12-5.17C-159.89,61.6-159.67,57.52-159.44,53.44z"
            />
            <path
              style="fill: #acb1ba"
              d="M-159.63,33.54c-1.81-1.02-3.62-2.03-5.44-3.05c0.87-3.31,2.24-6.83,6.09-6.53
            c3.23,0.25,4.14,3.44,3.82,6.6C-156.65,31.54-158.14,32.54-159.63,33.54z"
            />
            <path
              style="fill: #181d12"
              d="M106.62-139.37c1.98-9.28,6.69-15.36,17.16-14.71c8.32-0.38,10.6,3.55,9.02,11.43
            c-1.15,5.73-3.51,13.5,7.75,10.87c2.1,0.56,3.4,2.03,4.39,3.86c2.54,3.84,2.6,7.61-0.35,11.28c-5.23,2.46-10.07,1.15-14.77-1.52
            c-2.7-2.72-4.89-6.55-9.53-2.13c-3.74,3.56-7.91,2.9-11.67-0.52c-2.8-4.18-3.73-9-4.72-13.8
            C104.13-136.6,105.14-138.12,106.62-139.37z"
            />
            <path
              style="fill: #879656"
              d="M146.1-146.7c-0.19-0.76-0.28-1.53-0.27-2.31c0.53-1.08,1.3-1.94,2.3-2.59
            c12.67-6.34,22.02-0.05,30.89,8.15c2.67,3.13,3.22,6.71,2.26,10.6c-4.71,7.52-12.59,8.66-20.37,10.07
            c-1-0.55-1.86-1.28-2.56-2.18c-2.26-4.53-0.25-11.16-6.72-13.63C148.38-140.34,147.4-143.63,146.1-146.7z"
            />
            <path
              style="fill: #6f7b3f"
              d="M146.1-146.7c2.51,1.69,5.03,3.38,7.54,5.08c1.68,1.39,2.15,3.23,2.03,5.31
            c-2.46,6.92-6.09,12.03-14.66,9.47c-0.15-1.65-0.3-3.29-0.45-4.94C140.49-137.46,143.14-142.14,146.1-146.7z"
            />
            <path
              style="fill: #6f7b3f"
              d="M148.76-149.2c-0.98,0.06-1.95,0.13-2.93,0.19c-6.43-1.75-5.06-8.05-7.17-12.31
            c4.72-2.25,6.63-8.34,12.43-9.15c1.28,0.01,2.43,0.39,3.47,1.12C160.09-160.49,157.96-153.82,148.76-149.2z"
            />
            <path
              style="fill: #80913e"
              d="M150.71-168.58c-2.03,5.72-3.89,11.73-12.05,7.26c-0.64-4.15-1.29-8.29-1.93-12.44
            c2.45-2.82,5.08-2.06,7.75-0.39C146.87-172.64,149.7-171.64,150.71-168.58z"
            />
            <path
              style="fill: #a1ac3c"
              d="M143.65-171.42c-2.31-0.78-4.61-1.57-6.92-2.35c-1.71-2.42-4.23-4.79-0.47-7.56
            c3.67-1.98,6.34-0.24,8.81,2.38c0.34,1.05,0.66,2.07,0.98,3.09C147-173.43,145.54-172.31,143.65-171.42z"
            />
            <path
              style="fill: #35461e"
              d="M-123.31-114.14c3.67,2.62,5.84,9.81,12.27,4.04c10.97-4.86,14.26-3.22,18.58,9.28
            c-0.07,1.42-0.45,2.77-1.1,4.04c-1.64,2.36-3.88,3.63-6.77,3.68c-6.63-2.12-12.86-6-20.47-3.82c-2.65,0.76-5.51-0.42-6.85-3.46
            c-4.97-6.46-11.74-9.15-19.66-9.48c-1.81-3.47-3.63-6.93-5.44-10.39c-2.43-4.06-4.78-8.11,0.57-11.79
            c10.98-3.39,17.92-1.02,17.28,12.16C-131.38-117.33-124.94-120.58-123.31-114.14z"
            />
            <path
              style="fill: #61852e"
              d="M-127.66-100.39c4.73-0.67,9.51-1.08,14.16-2.08c6.92-1.5,11.93-0.11,13.66,7.54
            c0.43,8.99-4.76,9.83-11.68,8c-3.44-0.91-5.99-4.62-10.15-3.23c-2.9,1.36-5.66,3.07-8.89,3.62c-2.51,0.34-3.34-2.31-5.28-2.99
            C-139.08-97.66-128.99-95.72-127.66-100.39z"
            />
            <path
              style="fill: #5a7a2b"
              d="M-174.78-82.79c0.82-3.29,2.58-5.67,5.81-7.16c4.77-2.2,2.74-3.8-0.73-5.01
            c2.2-5.18,6.59-1.59,9.87-2.47c1.92,2.28,6.46,2.72,5.1,7.3C-160.73-85.82-163.97-73.94-174.78-82.79z"
            />
            <path
              style="fill: #739d2e"
              d="M-154.73-90.13c-2.01-2.22-7.6-1.92-5.1-7.3c6.76-1.12,5.89-10.09,12.1-11.78
            c0.32,4.43-0.02,9.02,1.09,13.24C-144.08-86.19-148.68-87.39-154.73-90.13z"
            />
            <path
              style="fill: #567f2d"
              d="M-135.84-89.53c1.78,0.72,3.57,1.44,5.35,2.16c0.62,0.31,0.92,0.71,0.89,1.21
            c-0.03,0.55-0.13,0.82-0.29,0.82c-5.01,3.44-10.03,6.87-15.04,10.31C-147.57-83.43-135.38-82.51-135.84-89.53z"
            />
            <path
              style="fill: #6f9a2c"
              d="M-152.2-132.06c-0.19,3.93-0.37,7.87-0.56,11.8c-7.16-0.93-3.4-8.22-6.65-11.43
            c0.54-0.26,1.08-0.49,1.64-0.7C-155.91-132.28-154.06-132.17-152.2-132.06z"
            />
            <path
              style="fill: #465631"
              d="M-122.73,124.79c-4.83-0.16-8.61,2.2-12.15,5.12c-0.96-0.08-1.92-0.16-2.88-0.24
            c-9.18-0.76-11.47-8.84-15.77-14.8c-3.54-3.45-4.09-7.79-3.68-12.41c-1.48-2.21-5.51-3.53-2.55-7.3
            c11.02-2.74,22,0.63,33.03,0.2c8.01,6.3,8.93,15.33,9.42,24.53C-118.26,122.48-120.78,123.32-122.73,124.79z"
            />
            <path
              style="fill: #758763"
              d="M-149.93,110.29c2.75,0.64,6.94,2.39,6.1,4.42c-3.08,7.51,4.06,10.06,6.08,14.96
            c-5.98,6.52-11.95,13.04-17.93,19.57c-5.88-1.1-5.27-7.45-4.34-9.46c3.72-8.1-0.11-15.55-0.33-23.25
            C-159.7,109.72-153.69,111.9-149.93,110.29z"
            />
            <path
              style="fill: #82908b"
              d="M-157.33,117.57c3.31,10.41-0.18,21.18,1.66,31.67c-0.49,7.41-7.86,4.76-11.36,7.77
            c-0.89-1.45-1.78-2.89-2.67-4.34c0.61-3.73,9.08-5.51,2.71-10.97c-3.21-6.8,10.11-9.38,4.14-17.91
            c-2.01-2.88-3.04-7.87-4.92-11.75C-159.74,105.25-160.59,115.31-157.33,117.57z"
            />
            <path
              style="fill: #58732f"
              d="M342.44,36.11c-2.31,2.37-6.34,2.85-7.36,6.65c-2.95,1.88-5.59,4.46-9.47,4.31
            c-1.21-0.02-2.38-0.23-3.54-0.59c-3.04-1.33-4.71-3.66-5.1-6.95c0.08-3.82,2.5-6.37,4.87-8.96c3.1-5.33,8.62-6.88,13.86-8.92
            c6.94,0.71,8.1,5.51,7.9,11.25C343.24,33.98,342.85,35.05,342.44,36.11z"
            />
            <path
              style="fill: #c4d356"
              d="M323.95,43.75c0.58,0.05,1.14,0.15,1.7,0.32c5.66,1.72,7.37,5.44,5.54,10.96
            c-0.13,0.94-0.26,1.89-0.39,2.83c-2.67,1.86-2.55,4.69-2.58,7.46c-1.79,3.22-2.79,7.34-7.81,6.88
            c-1.97-0.47-3.41-1.63-4.41-3.37c-1.87-3.75-0.18-7.25,0.76-10.8C318.55,52.97,315.63,45.51,323.95,43.75z"
            />
            <path
              style="fill: #81a12f"
              d="M331.19,55.02c-1.85-3.65-3.7-7.3-5.54-10.96c3.14-0.52,5.98-3.15,9.44-1.3
            c-0.19,2.15-1.59,4.5,1.03,6.18c3.09,0.98,5.59,2.35,4.23,6.32C337.29,55.18,334.24,55.1,331.19,55.02z"
            />
            <path
              style="fill: #c8d2a3"
              d="M342.44,36.11c-0.4-0.83-0.81-1.66-1.21-2.48c2.66-7.28,6.67-10.81,14.05-5.13
            c1.32,3.14,0.48,5.51-2.52,7.11C349.33,35.78,345.89,35.95,342.44,36.11z"
            />
            <path
              style="fill: #a5c41d"
              d="M340.34,55.26c-1.41-2.11-2.82-4.21-4.23-6.32c2.69-1.34,5.85-4.42,8.2-0.74
            C346.5,51.64,343.42,53.85,340.34,55.26z"
            />
            <path
              style="fill: #536636"
              d="M182.45-162.21c5.05-1.43,10.09-2.86,15.14-4.29c9.86,1.03,8.34,8.73,8.91,15.18
            c-0.1,1.15-0.24,2.34-0.38,3.49c-1.12,3.28-2.61,6.62,0.66,9.67c1.26,2.12,1.74,4.4,1.53,6.85c-0.16,1.31-0.6,2.49-1.29,3.6
            c-1.72,2.08-3.87,3.48-6.51,4.08c-1.95,0.26-3.9,0.26-5.84,0c-6.74-1.87-11.3-5.89-12.96-12.93c-0.77-3.26-2.75-5.79-5.53-7.66
            c-1.91-2.77-2.05-5.67-0.6-8.67C177.52-156.24,181.33-158.22,182.45-162.21z"
            />
            <path
              style="fill: #c0c79e"
              d="M178.37-154.01c-0.08,3.17-0.15,6.34-0.23,9.51c0.01,1.54-0.94,2.58-1.87,3.64
            c-4.89-1.75-14.61,1.88-9.61-11.65c-6.47,5.45-12.05,6.18-17.9,3.3c2.45-6.13,5.46-12.11,5.41-18.96
            c3.46-4.06,4.93-0.37,6.8,1.76c2.13,0.17,4.26,0.35,6.39,0.53c1.1,3.13,2.19,6.26,3.29,9.39
            C173.01-155.01,176.42-156.8,178.37-154.01z"
            />
            <path
              style="fill: #a1a781"
              d="M202.84-149.6c-2.74-5.32-0.55-12.18-5.25-16.89c0.67-2.29,1.34-4.59,2-6.88
            c3.35,2.1,6.66,4.29,10.8,4.44c2.42,3.57,7.23-1.78,9.44,2.55c2.03,2.11,3.42,4.48,3.05,7.56c-1.2,7.28-5.73,11.2-12.72,12.63
            C207.04-145.85,204.71-147.23,202.84-149.6z"
            />
            <path
              style="fill: #596d3d"
              d="M178.37-154.01c-2.71-0.42-6.42,2.31-7.72-2.48c2.89-4.07,7.58-4.41,11.81-5.72
            c1.39,2.66,1.8,5.45,0.84,8.33c-0.45,1.34,0.24,3.87-2,3.53C180.16-150.52,179.34-152.73,178.37-154.01z"
            />
            <path
              style="fill: #3e5321"
              d="M-127.71,97.66c-11.09,4.44-21.99,6.42-32.05-2.49c0.05-0.81,0.11-1.62,0.2-2.43
            c2.24-2.29,4.4-4.68,7.77-5.3c2.24-2.54,1.53-5.84,2.25-8.78c2.92-6.21,7.28-8.89,14.02-5.74c7.11,2.89,16.12,3.82,15.22,14.91
            C-125.03,89.39-124.64,94.83-127.71,97.66z"
            />
            <path
              style="fill: #778a4a"
              d="M-135.52,72.92c-3.87,2.63-9.11,3.15-11.77,7.64c-3.08-1.17-5.9-2.71-7.85-5.5
            c0.05-1.4,0.09-2.81,0.14-4.21c5.12-4.27-1.31-8.33-0.23-12.53c1.18-2.45,3.11-4,5.69-4.77
            C-150.15,63.84-138.06,64.91-135.52,72.92z"
            />
            <path
              style="fill: #908e63"
              d="M-155.14,75.06c4.36-0.66,8.18-0.54,7.85,5.5c-0.31,3.07-0.58,6.17-4.49,6.88
            C-150.79,82.74-157.14,80.03-155.14,75.06z"
            />
            <path
              style="fill: #828e46"
              d="M261.99-131.96c1.66-0.72,3.33-1.43,4.99-2.15c3.7-0.99,3.92-4.02,3.33-6.81
            c-1.19-5.63,2.25-8.15,6.23-10.52c0.98-1.08,1.96-2.15,2.95-3.23c0.76,1.79,1.53,3.58,2.29,5.37
            c2.39,4.69,0.29,11.44,6.59,14.34c0.9,0.73,1.6,1.62,2.13,2.65c0.57,1.68,0.5,3.34-0.21,4.97c-2.74,3.37-5.56,6.68-9.39,8.92
            c-3.77,0.92-5.94,3.14-7.35,6.94c-1.51,4.07-3.94,8.01-9.54,7.12c-4.11-2.71-3.73-8.9-8.57-11.06c-2.34-2.73-2.42-5.89-1.84-9.2
            C254.63-129.09,257.34-131.64,261.99-131.96z"
            />
            <path
              style="fill: #bec9aa"
              d="M295.95-156.14c3.08-3.98,0.98-11.22,7.99-12.71c6.75-1.07,5.06,6.17,8.4,8.49
            c1.73,3.07,3.81,6.03,3.32,9.87c-0.26,1.28-0.85,2.39-1.73,3.35c-4.55,2.97-9.3,5-14.86,3.07c-1.22-0.64-2.21-1.53-2.98-2.67
            C294.47-149.85,293.45-152.97,295.95-156.14z"
            />
            <path
              style="fill: #919e57"
              d="M304.9-156.63c0.89-4.17,2.47-8.39-0.96-12.22c-2.62-2.33-5.25-4.67-7.87-7
            c4.26-2.24,5.23-6.19,5.47-10.52c2.7,8.74,9.54,13.52,16.97,17.74C317.55-160.57,313.54-155.97,304.9-156.63z"
            />
            <path
              style="fill: #9aa46c"
              d="M286.6-131.96c-4.73,1.13-8.18,0.51-7.16-5.6c0.65-3.94,1.55-7.83,2.34-11.75
            c0.95,1.75,1.91,3.5,2.86,5.26C290.7-140.9,289.6-136.58,286.6-131.96z"
            />
            <path
              style="fill: #9aa46c"
              d="M295.95-156.14c0.87,2.3,1.74,4.61,2.61,6.91c-1.36,3.62-2.87,7.1-7.52,7.34
            c-2-0.12-3.58-0.99-4.71-2.66C289.25-148.64,290-154.55,295.95-156.14z"
            />
            <path
              style="fill: #bcc2b6"
              d="M286.33-144.54c1.39,0.24,2.78,0.49,4.17,0.73c5.16,4.91,5.72,9.23-1.61,12.53
            c-0.76-0.22-1.52-0.45-2.28-0.68c-0.66-4.03-1.31-8.06-1.97-12.09C285.22-144.17,285.78-144.33,286.33-144.54z"
            />
            <path
              style="fill: #77723c"
              d="M-80.84-171.43c3.92,2.21,6.5,7.8,12.43,4.96c1.08,7.58,9.35,14.25,2.87,22.78
            c-2.77,0.58-5.54,1.2-8.4,0.57c-6.05-1.01-8.65-6.8-13.37-9.66c-0.28-0.48-0.55-0.97-0.82-1.46c-0.74-1.52-1.49-3.04-2.23-4.56
            c2.55-0.61,4.31-2.08,4.97-4.69C-84.14-166.29-83.5-169.44-80.84-171.43z"
            />
            <path
              style="fill: #3b3c1b"
              d="M-80.84-171.43c0.12,3.58-0.57,6.71-4.54,7.94c-1.8-2.15-6.34-0.17-6.76-3.86
            c-0.46-4.09,3.65-4.72,6.18-6.45c1.11,0.7,2.21,1.39,3.32,2.09C-82.03-171.68-81.43-171.59-80.84-171.43z"
            />
            <path
              style="fill: #7fa728"
              d="M-112.59-163.82c-0.8-0.09-1.6-0.16-2.4-0.2c-0.13-3.78-1.34-7.81,2.99-11.45
            C-109.8-171.08-109.1-167.45-112.59-163.82z"
            />
            <path
              style="fill: #29361b"
              d="M330.89,196.44c2.43-0.91,4.87-1.81,7.3-2.72c1.64,3.03,3.29,6.07,4.93,9.1
            c0.8,7.42,3.53,13.05,12.2,13.15c1.95,1.38,9.99-11.15,6.61,3.01c-0.9,3.76,2.55,1.16,4.09,1.08c3.62-0.2,4.78,3.5,3.07,4.36
            c-7.12,3.56-1,9.94-3.77,14.14c-4.35,3.45-10.33,6.56-2.51,12.47c0.75,3.68-4.2,5.91-2.49,9.83c-1.63,1.41-3.26,2.82-4.9,4.23
            c-0.77,3.93,0.41,8.34-2.85,11.67c-2.98,1.56-5.8,1.17-8.52-0.64c-13.35-10.18-15.22-26.98-4.03-35.4
            c0.62-0.49,0.82-0.83,0.38-0.84c-5.73,2.54-8.78,6.53-7.98,12.9c-0.06,2.81-0.37,5.62,0.33,8.42c0.72,4.16-0.69,7.39-4.22,9.69
            c-6.53,1.7-14.38,10.68-18.08-3.26c0.75-3.43,2.67-6.77-0.18-10.29c-6.74-8.32-0.07-16.89,0.01-25.33
            c0.04-3.89,4.65-5.12,8.29-6c1.66-2.56,3.33-5.12,4.99-7.68c5.79-3.92-0.7-10.11,2.67-14.48
            C327.78,201.38,329.33,198.91,330.89,196.44z"
            />
            <path
              style="fill: #65852d"
              d="M355.31,215.97c-6.14,8.56-11.96-3.78-18.44-0.47c2.08-4.23,4.16-8.45,6.24-12.68
            c0,0,0.12,1.08,0.12,1.08c3.32,1.82,7.29,3.01,7.61,7.78C352.33,213.1,353.82,214.54,355.31,215.97z"
            />
            <path
              style="fill: #97b749"
              d="M350.83,211.67c-3.58-1.57-7.4-2.9-7.61-7.78c3.27-1.67,6.55-3.34,9.82-5.01
            C350.04,202.76,357.68,208.47,350.83,211.67z"
            />
            <path
              style="fill: #5b7129"
              d="M261.99-131.96c-0.27,3.49-3.13,4.94-5.39,6.87c-2.08,3.41-4.71,5.94-9.09,5.67
            c-2.54-0.45-4.54-1.79-6.12-3.81c-1.78-3.72,0.31-7.93-1.47-11.68c0.18-9.5,9.31-7.5,14.15-11.05c3.52-0.47,5.58,1.78,7.61,4.09
            C261.78-138.56,261.88-135.26,261.99-131.96z"
            />
            <path
              style="fill: #4f6126"
              d="M276.55-151.44c-4.69,4.91-2.65,10.67-1.99,16.22c0.46,3.88,2.82,9.14-3.48,9.53
            c-5.58,0.34-3.5-5.16-4.1-8.41C266.16-142.1,265.1-150.23,276.55-151.44z"
            />
            <path
              style="fill: #627b2c"
              d="M252.22-166.66c5.69,0.91,11.38,1.83,17.07,2.74c2.37,5.85,0.57,9.93-5.22,12.31
            c-3.86-0.83-8.2,4.6-11.73-0.9c-2.48-4.12-3.52-8.41-1.43-13.04C251.35-165.92,251.79-166.29,252.22-166.66z"
            />
            <path
              style="fill: #7d9e2b"
              d="M254.65-153.91c2.33,4.1,7.64-4.04,9.42,2.3c-2.58,2.81-2.05,6.39-2.39,9.75
            c-2.55-0.46-5.1-0.92-7.65-1.38c-4.29,1.81-8.93,2.85-12.18-1.14c-2.88-3.54-0.13-6.73,2.59-9.38
            C247.81-155.9,251.21-156.62,254.65-153.91z"
            />
            <path
              style="fill: #6c7a51"
              d="M-157.33,117.57c-4.87,0.8-4.78-7.81-10.45-5.52c-5.04-1.66-3.95-5.87-4-9.61
            c4.85,3.11,9.71,2.86,14.58,0.02c5.49-0.26,2.87,7.06,7.28,7.82C-152.56,112.55-156.73,113.25-157.33,117.57z"
            />
            <path
              style="fill: #2a301b"
              d="M335.69-117.11c-0.96-0.95-1.92-1.89-2.89-2.84c-11.77-1.85-11.8-12.05-13.71-20.34
            c-0.97-4.22-0.63-8.88-1.58-13.28c-1.25-5.71-0.56-10.93,3.85-15.21c2.45,0.67,5.52,5.4,7.07-0.56
            c1.37-5.28,4.92-6.62,9.58-6.83c0.07,6.67,0.57,13.38,0.08,20.01c-0.47,6.41,3.47,1.63,5.13,2.46
            c-3.21,9.6-7.27,19.11-2.94,29.46c0.17,2.37,0.35,4.74,0.52,7.11C339.1-117.12,337.39-117.12,335.69-117.11z"
            />
            <path
              style="fill: #738a58"
              d="M321.36-168.78c-0.04,4.95-0.09,9.91-0.13,14.86c-2.21,1.64-4.21,3.97-7.42,2.17
            c-2.97-1.63-5.95-3.25-8.92-4.88c3.84-4.8,7.84-9.42,13.61-12.01c0.24-1.03,0.48-2.06,0.72-3.09
            C319.94-170.75,320.65-169.76,321.36-168.78z"
            />
            <path
              style="fill: #627b2c"
              d="M247.07-186.05c5.47-0.36,6.98,2.6,6.38,7.48c-0.49,3.96-0.82,7.94-1.23,11.91
            c-0.27,0.31-0.54,0.63-0.8,0.94c-2.24,1.73-4.54,1.67-6.89,0.25c-5.11-7.63,3.59-13.56,2.54-20.7L247.07-186.05z"
            />
            <path
              style="fill: #1e2316"
              d="M346.44,273.03c2.26,0.74,4.53,1.48,6.79,2.23c0.96-0.66,1.64-0.4,2.08,0.64
            c2.83,5.59-1.52,6.32-5.11,7.57c-3.03-0.16-4.79,1.02-4.61,4.3c-1.8,1.93-3.51,1.65-5.15-0.21c-5.63-0.33-11.01-1.93-10.13-8.73
            c0.79-6.09,6.46-6,11.35-5.94C343.26,272.93,344.85,272.96,346.44,273.03z"
            />
            <path
              style="fill: #8d973d"
              d="M355.32,275.91c-0.66-0.32-1.35-0.54-2.08-0.64c-2.41-4.07-2.8-7.7,2.18-10.17
            C357.9,268.72,362.35,272.37,355.32,275.91z"
            />
            <path
              style="fill: #565c2a"
              d="M160.96-166.4c-2.27-0.59-4.53-1.17-6.8-1.76c-1.15-0.14-2.3-0.28-3.46-0.42
            c-2.36-0.94-4.71-1.89-7.06-2.83c0.8-1.48,1.6-2.97,2.4-4.44C150.58-172.01,161.68-178.54,160.96-166.4z"
            />
            <path
              style="fill: #4a702a"
              d="M340.57,130.22c4.3-1.38,8.62-2.71,12.07-5.83c0.78,1.65,2,3.24,0.74,5.17
            c-4.26,0.3-6.39,8.93-12.22,3.14C340.97,131.88,340.77,131.05,340.57,130.22z"
            />
            <path
              style="fill: #b7c867"
              d="M355.29,28.5c-5.28,0.08-9.7,2.5-14.05,5.13c-2.28-3.01,1.32-9.29-5.46-9.79
            c-4.15-0.22-2.8-2.61-1.91-4.81c2.38-2.18,2.64-4.38,0.68-7.19c-2.71-3.88-3.1-8.01,0.08-11.99c1.38-1.27,3.01-1.98,4.87-2.16
            c5.38-2.1,10.77-1.25,16.15-0.01c1.78,0.16,3.56,0.37,5.19,1.18c3.91,3.07,5.95,7.02,5.56,12.08
            c-2.92,3.56,0.03,8.97-3.84,12.24C359.6,24.21,356.67,25.29,355.29,28.5z"
            />
            <path
              style="fill: #9db689"
              d="M365.82,11.77c-1.87-3.17-3.74-6.34-5.61-9.51c-1.42-2.22-1.45-4.54-0.48-6.93
            c2.12-2.61,2.63-7.32,7.78-5.98c0.96,1.5,1.92,3.01,2.88,4.51c0,5.81,0,11.61,0,17.42c-0.73,0.24-1.46,0.5-2.18,0.76
            C367.34,12.6,366.54,12.51,365.82,11.77z"
            />
            <path
              style="fill: #7a9c43"
              d="M365.82,11.77c0.8,0.04,1.6,0.13,2.39,0.26c-0.86,4.23,0.09,9.38-5.64,11.13
            C362.57,19.06,358.81,13.88,365.82,11.77z"
            />
            <path
              style="fill: #29371b"
              d="M247.08-186.16c3.89,7.15,3.89,13.76-2.69,19.45c0.44,3.88-1.91,6.04-4.8,7.88
            c-2.31,1.25-3.93,3.44-6.22,4.73c-2.5,1.98-3.31,6.06-7.43,6.06c-5.73-1.85-6.71-6.32-6.38-11.53c0.1-2.27,0.2-4.54,0.3-6.82
            c4.2-0.68,8.37-2.46,12.63-0.26c2.14-5.49-2.31-13,5.14-16.87c0.5-0.18,1-0.34,1.51-0.47
            C242.83-180.82,244.77-184.15,247.08-186.16z"
            />
            <path
              style="fill: #889566"
              d="M237.61-183.51c-2.59,5.35,3.92,13.49-5.13,16.87c-1.38-5.39-8.61-8.01-6.99-14.81
            C229.53-182.14,233.57-182.82,237.61-183.51z"
            />
            <path
              style="fill: #c7d3a6"
              d="M367.5-10.65c-1.46,2.5-2.92,5.01-4.39,7.51c-5.3-1.5-7.52-4.51-4.84-9.92
            c2.73-1.96,5.46-3.92,8.19-5.87c1.97,0.9,2.63,2.51,2.46,4.55C368.45-13.14,367.98-11.9,367.5-10.65z"
            />
            <path
              style="fill: #2f3d1b"
              d="M-78.4-1.01c1.61-3.34,3.21-6.69,4.82-10.03c1.64,0,3.28-0.09,4.92-0.08
            c12.73,0.05,12.74,0.05,9.29-11.83c-0.47-1.61-0.51-3.23-0.49-4.89c-0.34-4.94,0.9-9.57,2.86-13.97
            c3.47-7.8,8.74-11.31,17.07-6.48c12.82,7.11,10.94,20.43,12.24,31.69c1.79,15.4-1.77,30.87-2.54,46.33
            c-0.19,3.92-4.28,5.99-4.78,9.77c-0.27,1.52,0.19,3.69-0.9,4.51c-7.72,5.84-2.87,9.88,1.76,14.03
            c-3.51,3.42-7.91,2.51-12.13,2.48c-8.83,1.08-11.08,11.77-19.54,13.43c-1.09-0.38-2.02-0.99-2.79-1.85
            c-3.79-5.34-2.9-11.97-4.89-17.81c-0.81-4.54-2.5-8.9-2.81-13.55c4.11-8.32,8.78-16.14-0.36-24.91
            c-3.49-3.35-0.48-10.1-1.88-15.15C-78.5,0.11-78.45-0.45-78.4-1.01z"
            />
            <path
              style="fill: #2e3b1d"
              d="M-23.96,181.35c-4.39,0.49-10.33,10.2-12.58-2.11c-1.7-3.27-2.31-7.07-4.75-10
            c-6.16-2.85-3.3-7.73-2.72-12.1c1.4-3.79,4.09-5.63,8.14-5.41c6.9,6.15,9.78,2.33,11.58-4.59c1.9-7.31,2.52-15.58,12.17-17.53
            c6.87,0.46,8.91,7.52,14.1,10.3c1.75,3.04,0.1,6.48,1.21,9.58c1.7,2.81,3.36,5.61,8.48,4.96c-6.12-9.62,9.42-19.12-0.8-28.92
            c1.11-7.92,1.46-15.85,0.1-23.8c2.69-6.32,6.62-6.35,11.37-2.27c5.3,3.44,7.42,8.68,6.87,14.61c-0.45,4.85,1.04,8.67,3.75,12.35
            c3.01,9,3.76,18.43,7.92,27.4c4.77,10.29-0.6,18.37-14.23,27.11c-5.24,2-10.69-3.71-15.88,0.46c-1.12,0.2-2.23,0.15-3.35-0.04
            c-4.02-0.95-6.11-4.5-9.12-6.81c-8.01-9.14-8.34,3.3-12.66,4.52c-9.15-1.1-3.48-7.65-4.2-11.86
            C-17.88,172.91-23.07,176.33-23.96,181.35z"
            />
            <path
              style="fill: #1b1f14"
              d="M-66.25,72.85c4.49-7.63,6.23-19.7,19.97-12.33c0.58,15.62,1.69,16.73,16.07,16.27
            c1.04-0.03,2.87-0.02,3.02,0.42c2.67,8.09,4.49,16.39,8.67,24.06c2.17,3.98-2.89,7.14-4.44,10.77
            c-5.06,11.9-15.17,19.98-22.68,30.02c-2.1,2.8-4.54,7.26-8.54,5.42c-5.23-2.4-2.02-6.92-0.79-10.67
            c0.5-1.54,0.79-3.16,1.18-4.74c-2.33-1.55-2.83-3.84-2.66-6.42c7.11-12.41,6.95-12.95-7.61-25.3c-5.99-2.85-5.58-9.1-7.53-14.1
            C-71.12,81.26-74.05,74.9-66.25,72.85z"
            />
            <path
              style="fill: #3e5321"
              d="M-53.57,124.89c-0.08,2.39-0.15,4.79-0.23,7.18c-1.6,0.9-3.19,1.81-4.79,2.72
            c-16.7,1.01-16.7,1.01-14.66,17.24c-0.13,0.38-0.15,0.95-0.41,1.1c-8.34,4.66-15.04,10.07-9.83,21.06
            c-12.14,0.93-9.32-8.08-9.87-14.87c-7.36-6.09-3.1-13.21-1.73-20.04c0.46-2.31,2.25-4.79,0.31-6.88
            c-2.31-2.49-4.41,0.08-6.41,1.09c-4.55,2.28-8.05,2.22-11.33-2.62c-2.22-3.28-6-5.41-10.22-6.08c0.87-1.7,1.73-3.41,2.6-5.11
            c5.06-4.15-2.38-12.98,5.93-15.92c2.17-0.29,4.46,0.19,5.26,2.18c5.6,14.04,18.45,11.45,29.74,14.27
            c-5.1-12.49-17.05-21.99-9.24-36.52c1.64-1.65,3.29-3.29,5.44-4.29c6.86-3.18,10.32,2.56,14.79,5.67
            c2.32,4.24,3.02,9.42,7.28,12.52c1.84,5.98,2.16,12.44,6.99,17.4C-51.3,117.7-51.13,121.48-53.57,124.89z"
            />
            <path
              style="fill: #435535"
              d="M-0.03,139.5c-8.86,2.62-8.58-5.95-12.09-9.89c0.78-5.9-0.76-11.51-2.08-17.15
            c3.49-5.57,4.02-11.19,0.24-16.87c-2.02-3.32-1.82-6.06,2.07-7.81c1.46-1.28,2.93-2.56,4.91-2.97c2.66-0.31,5,0.39,6.96,2.24
            c1.82,5.11,7.65,6.82,9.91,11.58c0.37,8.24,3.8,16.41,0.62,24.74c-0.15,1.68-0.25,3.37-1.09,4.89
            c-2.34,3.75-5.62,6.8-7.92,10.58C0.99,139.07,0.48,139.28-0.03,139.5z"
            />
            <path
              style="fill: #446166"
              d="M46.84,218.23c-15.71,1.28-17.49,3.42-16.82,20.16c-5.77,7.84-11.72,0.12-17.58,0.19
            c-3.52-5.47-4.21-11.31-2.35-17.51c5.67-6.59,4.24-20.5,18.62-18.1c6.29,2.6,10.15,9.12,17.1,10.66
            c1.38,1.37,2.29,2.87,1.31,4.88L46.84,218.23z"
            />
            <path
              style="fill: #36573a"
              d="M30.13,206.24c-3.91,0.28-8.87-0.3-9.44,4.98c-0.73,6.84-6.6,7.12-10.61,9.86
            c-1.53-9.03-3.06-18.05-4.59-27.07c2.39-3.08,5.49-2.78,8.73-1.86c8.14,2.33,10.16-1.58,8.97-8.8c0.43-1.14,1.13-2.06,2.14-2.75
            c4.71-2.57,8.23-0.2,11.59,2.79c3.39,3.27,8.51,5.72,5.74,11.95c-3.53,3.58-6.9,7.35-10.94,10.43
            C31.19,205.93,30.66,206.08,30.13,206.24z"
            />
            <path
              style="fill: #5b5e1f"
              d="M-34.15,58.04c-2.4-0.12-5.54,0.7-7.05-0.52c-4.73-3.81-14.73-0.04-15.15-6.77
            c-0.38-6.12,8.82-1.28,12.89-4.64c1.88-1.55,3.87-2.68,4.62-5.13c4.91-5.42,11.41-4.98,17.84-4.85c3.06,1.04,5.55,2.76,6.9,5.84
            c1.63,6.01,0.19,11.28-3.89,15.9c-2.79,2.09-5.65,2.86-8.61,0.3C-29.08,55.89-31.6,55.85-34.15,58.04z"
            />
            <path
              style="fill: #31481e"
              d="M-73.24,152.03c-2.41-2.55-8.95,1.83-8.65-5.76c0.41-10.36,5.24-16.97,14.94-19.8
            c7.2-2.11,4.94,6.14,8.36,8.31C-63.83,140.23-63.55,150.38-73.24,152.03z"
            />
            <path
              style="fill: #4d722a"
              d="M-93.35,159.32c3.2,5.02,4.61,11.22,9.87,14.87c1.82,1.58,3.64,3.17,5.46,4.75
            c-0.17,5.12-1.6,10.03-6.97,11.31c-4.84,1.15-6.39-3.78-8.62-6.94c-1.72-2.45-4.08-3.72-6.96-4.2
            c-2.56-5.49,1.25-9.92,2.47-14.78C-96.52,162.66-94.93,160.99-93.35,159.32z"
            />
            <path
              style="fill: #446531"
              d="M25.42,184.03c3.62,3.63,4.13,7.78,1.27,11.9c-3.02,4.35-7.16,2.03-10.9,1.08
            c-3.46-0.88-6.87-1.99-10.3-3c-3.84-1.07-5.43-3.55-4.67-7.49c2.03-3.14,2.7-7.67,7.81-7.67l1.19,0.2
            C16.33,176.58,20.55,181.32,25.42,184.03z"
            />
            <path
              style="fill: #73813b"
              d="M-7.12,87.59c-1.59,0.06-3.18,0.13-4.76,0.19c0.05-0.83-0.03-1.64-0.25-2.44
            c0.69-8.49-10.41-14.84-4.4-24.3c3.74-3.57,7.48-7.13,13.23-3.94C2.55,67.01,0.84,80.66-7.12,87.59z"
            />
            <path
              style="fill: #475e27"
              d="M-23.96,181.35c-1.58-8.8,4.39-16.67,3.17-25.5c-0.21-1.52,2.07-2.61,3.76-1.79
            c3.34,1.64,4.65,4.36,3.58,8.13c-1.57,5.54-7.04,10.89-0.92,16.86C-17.23,181.25-20.45,181.93-23.96,181.35z"
            />
            <path
              style="fill: #3b3f1d"
              d="M-13.68,62.68c-5.11,7.94,5.47,14.8,1.55,22.66c-4.39-9.29-12.4-16.64-14.47-27.17
            c2.54-0.86,5.09-1.72,7.63-2.57C-13.63,55.28-13.36,58.76-13.68,62.68z"
            />
            <path
              style="fill: #3f534c"
              d="M20.28,245.36c3.15,7.56,9.51,16.07-5.44,18.23c-0.59-7.65-1.18-15.3-1.76-22.95
            C18.01,238.34,20.29,240.1,20.28,245.36z"
            />
            <path
              style="fill: #667f9b"
              d="M20.28,245.36c-1.55-2.88-3.93-4.48-7.2-4.72c-0.3-0.66-0.51-1.34-0.64-2.05
            c5.76-8.8,11.75,2.46,17.58-0.19c0.61,2.53,3.24,4.96,0.96,7.65C27.24,250.44,23.91,244.77,20.28,245.36z"
            />
            <path
              style="fill: #4c7367"
              d="M8.63,178.84c-1.54,3.65-1.18,9.22-7.81,7.67c-3.42-3.45-6.51-6.96-2.53-11.98
            C4.08,170.32,6.84,173.41,8.63,178.84z"
            />
            <path
              style="fill: #446226"
              d="M-41.29,169.25c3.87,2.24,11.73,2.58,4.75,10c-3.8-1.78-8.82,4.7-11.54-0.9
            C-51.05,172.25-41.91,173.6-41.29,169.25z"
            />
            <path
              style="fill: #3a582c"
              d="M-46.42,216.08c-3.27,1.82-6.56,0.42-9.84,0.04c-0.21-5.27,6.66-5.02,7.42-9.53
            c-4.89-3-12.26-5.06-4.81-12.73c1.63,1.64,3.26,3.28,4.88,4.93C-46.76,204.38-42.71,209.71-46.42,216.08z"
            />
            <path
              style="fill: #547529"
              d="M-53.65,193.86c-2.56,5.82,3.07,8.54,4.81,12.73c-9.03,3.44-10.74-3.67-13.71-9.84
            C-59.52,195.76-56.59,194.81-53.65,193.86z"
            />
            <path
              style="fill: #6e897d"
              d="M-13.95,95.59c7.73,4.92,7.64,11.35-0.24,16.87C-14.12,106.84-14.03,101.22-13.95,95.59z"
            />
            <path
              style="fill: #425b24"
              d="M-35.87,151.74c-1.94,2.98-4.64,4.79-8.14,5.41c-2.57-3.44-0.54-5.79,2.03-7.79
            C-38.79,146.87-37.14,148.95-35.87,151.74z"
            />
            <path
              style="fill: #2d361c"
              d="M39.46,194c1.16-4.08-3.87-4.45-4.47-7.47c-0.79-10.94,5.81-12.96,14.71-12.23
            c3.68,1.96,6.36,4.69,6.8,9.08c0.15,2.01-0.62,3.98-0.53,5.99c0.16,3.58-0.52,8.03,2.68,10.11c4.47,2.9,6-2.48,8.51-4.57
            c6.19-5.17,12.93-6.12,20.36-3.42c10.81,8.64,19.39,20.17,35.63,20.67c8.51,0.26,8.84,9.4,8.96,16.59
            c-1.24,7.7-5.74,11.35-13.54,10.92c-5.48-1.75-11.03-6.21-16.26,0.55c-1.12,0.45-2.28,0.67-3.49,0.68
            c-5.9-1.66-9.66-6.46-14.39-9.85c-4.15-11.65-9.42-6.62-14.86-1.07c-2.54,2.59-2.41,8.97-8.28,6.89
            c-4.52-1.6-2.65-5.88-2.94-9.44c-0.52-6.45-3.72-10.7-11.22-8.92c-0.76-0.77-1.52-1.55-2.28-2.32
            c-4.45-3.24-9.02-6.37-10.21-12.32C35.06,200.01,37.35,197.05,39.46,194z"
            />
            <path
              style="fill: #161912"
              d="M46.68,263.14c0.21-2.86-1.81-4.06-3.88-5.23c-2.82-1.66-0.76-5.25-2.84-7.21
            c2.89-6.22,3.05-12.13-0.27-18.52c-3.88-7.48-1.03-11.56,7.53-11.26c2.96,0.92,5.26,2.92,5.53,5.88
            c0.99,11.01,6.27,20.67,9.29,31.03c1.07,2.39-0.06,3.01-2.24,2.77c-4.01,4.79-5.14,9.75-1.63,15.47
            c1.99,3.25,1.74,7.07,0.47,10.57c-1.2,3.28-0.41,7.98-4.35,9.45c-5.12,1.92-6.29-3.85-9.37-5.98c2.47-3.88,6.45-7.04,2.99-12.76
            C45.38,273.17,46.22,267.97,46.68,263.14z"
            />
            <path
              style="fill: #91ad86"
              d="M81.79,332.15c3.34,3.32,8.2,2.07,12.26,3.61c-0.8,5.13-5.07,2.7-7.35,4.1
            c0,0-0.02-0.12-0.02-0.12c-8.93-2.09-18.26,0.06-27.14-2.62c-1.99,0.65-4.62-0.39-5.88,2.21c-9.34-2.56-19.71,1.16-28.5-4.72
            c-0.46-0.56-1.04-0.8-1.76-0.71c0.42-3.96,3.89-3.03,6.24-3.89c7.06,3.69,14.41,1.85,21.66,1.01
            C61.54,329.86,71.71,329.44,81.79,332.15z"
            />
            <path
              style="fill: #435923"
              d="M59.79,260.61c0.75-0.92,1.49-1.84,2.24-2.77c4.21-1.66,8.57-3.87,12.72-0.34
            c2.58,2.19,2.2,5.49,0.43,7.88c-2.29,3.09-2.1,9.93-6.94,8.82C62.27,272.84,60.09,266.77,59.79,260.61z"
            />
            <path
              style="fill: #465826"
              d="M84.41,231.06c7.21-1.42,11.34,3.18,15.42,7.88c4.28,1.86,8.2,4.06,9.07,9.26
            c0.17,1.11,0.3,2.23,0.39,3.36c-0.03,0.55-0.07,1.1-0.1,1.65C99.47,253.58,84.26,239.98,84.41,231.06z"
            />
            <path
              style="fill: #3c4f20"
              d="M40,321.86c1.62,0.09,3.25,0.19,4.87,0.28c3.09-1.84,5.88-7.48,9.78-1.9
            c1.77,2.52-1.76,4.55-3.91,4.85C46.89,325.62,40.65,332.32,40,321.86z"
            />
            <path
              style="fill: #739e65"
              d="M59.54,337.13c9.11,0.24,18.47-2.2,27.13,2.62C77.17,343.71,68.52,338.66,59.54,337.13z"
            />
            <path
              style="fill: #232b19"
              d="M25.16,334.63c9.5,1.57,19,3.14,28.5,4.71C44,338.74,33.62,342.51,25.16,334.63z"
            />
            <path
              style="fill: #506776"
              d="M-85.49,323.1c-1.52-5.68-10.25-8.8-5.51-16.04c1.26-1.92,4.36-2.72,5.32-1.25
            C-82.19,311.16-83.49,317.16-85.49,323.1z"
            />
            <path
              style="fill: #49632f"
              d="M-126.08,310.61c4.94-7.21,2.29-12.23,4.02-17.3c6.99,0.98,13.98,4.03,12.8,11.12
            C-110.47,311.64-118.65,306.32-126.08,310.61z"
            />
            <path
              style="fill: #647d97"
              d="M39.96,250.71c6.72,0.12,2.96,4.38,2.84,7.21c-2.79,2.66-5.52,2.42-8.21-0.17
            C36.38,255.4,38.17,253.05,39.96,250.71z"
            />
            <path
              style="fill: #556c70"
              d="M40.61,274.79c-0.14,3.2-2.23,5.25-4.77,6.35c-2.38,1.04-4.16-0.74-3.44-3.08
            c0.79-2.58,2.13-5.51,4.17-6.99C37.81,270.17,39.71,272.88,40.61,274.79z"
            />
            <path
              style="fill: #29361b"
              d="M264.22,206.4c0.84,1.67,1.67,3.34,2.51,5.01c1.55,1.54,1.49,3.09,0.04,4.66
            c-2.77,5.81-2.36,10.76,3.34,14.82c3.35,2.38,6,5.54,6.44,9.97c-4.53-2.53-9.03-2.63-13.85-0.48
            c-10.51,4.7-20.5,0.25-30.58-2.17c-1.21-2.05-2.02-4.52-4.98-4.74c-5.37-2.41,2.82-15.27-9.48-12.34
            c-0.03-1.46-0.06-2.93-0.09-4.39c-1.47-6.24,9.93-7.29,5.8-14.59c-1.67-5.59,2.14-8.73,5.33-12.17
            c3.49-4.41,7.81-7.49,13.5-8.35c1.21-0.02,2.39,0.22,3.52,0.64C251.85,190.33,257.06,199.11,264.22,206.4z"
            />
            <path
              style="fill: #3c5424"
              d="M318.56,226c-1.6,5.01-9.27,9.4-1.48,15.42c0.7,0.54,0.89,3.2,0.67,3.29
            c-12.19,4.99-1.05,14.5-4.15,21.17c-3.31,2.72-0.92,8.31-5.29,10.5c-2.7,0.51-4.87-0.46-6.68-2.41
            c-2.43-5.18-4.01-10.06,4.17-11.32c5.83-6.09-0.89-8.7-3.89-11.06c-3.63-2.85-8.58-4.01-12.95-5.9
            c-0.34-1.56-1.51-3.66-0.9-4.59c5.47-8.31,15.95-10.66,22.2-18.43C312.06,220.42,316.18,223.66,318.56,226z"
            />
            <path
              style="fill: #0f110c"
              d="M305.81,262.65c-4.12,2.76-2.38,6.63-1.93,10.26c-7.67,8.12-14.34,18.06-27.95,15.4
            c-1.37-1.24-2.04-2.8-2.17-4.62c-0.08-5.86,7.74-7.06,7.63-12.95C286.38,258.51,295.72,259.46,305.81,262.65z"
            />
            <path
              style="fill: #121611"
              d="M276.68,282.88c-0.02,1.58-0.03,3.16-0.05,4.73c1.7,0.85,1.69,1.68-0.02,2.5
            c-0.71,2.5-1.43,5-2.14,7.5c-4.62,2.68-8.38,6.44-12.43,9.85c-2.06,5.34-7.84,1.7-10.95,4.53c-8-3.71-5.38-10.45-0.3-11.29
            c7.51-1.24,8.88-7.62,13.68-10.69C267.37,285.63,268.55,278.29,276.68,282.88z"
            />
            <path
              style="fill: #2d411e"
              d="M262.54,270.35c-0.45,5.78-5.17,7.63-9.85,8.98c-4.05,1.17-6.51-1.72-6.63-5.22
            c-0.23-6.7,5.19-8.79,10.37-10.03C260.72,263.06,261.82,266.61,262.54,270.35z"
            />
            <path
              style="fill: #385222"
              d="M276.68,282.88c-5.31,0.25-6.4,7.73-12.21,7.13c-3.28-5.75-4.74-10.2,4.45-12.02
            c4.46-0.89,8.33-4.74,12.47-7.25C285.68,277.06,279.78,279.43,276.68,282.88z"
            />
            <path
              style="fill: #42542c"
              d="M227.14,233.46c1.78-2.37,2.13-6.52,6.2-6.13c2.38,0.23,4.35,2.25,4.75,4.7
            c0.75,4.47-4.22,3.85-5.98,6.17C229.1,238.05,227.59,236.32,227.14,233.46z"
            />
            <path
              style="fill: #5a6e51"
              d="M232.16,304.87c1.86-0.54,3.73-1.58,5.49,0.22c1.48,4.06,2.96,8.11,4.43,12.17
            C233.66,317.25,233.35,310.7,232.16,304.87z"
            />
            <path
              style="fill: #212a18"
              d="M118.99,238.38c2.42-4.05,9.59-3.14,9.84-9.46c4.45-7.41-1.38-17.53,6.28-24.09
            c1.28-1.74,2.58-3.47,3.09-5.64c0.16-6.24,2.7-11.1,8.21-14.26c4.01-1.34,7.67-3.69,11.97-4.23c4.28-0.07,7.3,1.8,8.95,5.79
            c0.33,1.19,0.35,2.4,0.15,3.61c-3.7,5.47-4.24,11.02-0.05,16.49c2.94,3.85,3.87,7.59,0.22,11.49c-2.93,0.82-5.27,2.37-6.5,5.3
            c1.85,6.15-0.97,10.16-6.01,13.14c-5.34,3.04-11.05,2.64-16.81,1.76c-6.96,3.77-13.39,9.18-22.21,7.11
            C113.99,241.78,115.47,239.66,118.99,238.38z"
            />
            <path
              style="fill: #4e712b"
              d="M138.32,238.27c4.36-3.7,9.94-3.66,15.09-4.92c3.45,2.34,5.42-0.35,7.05-2.49
            c3.41-4.49,7.65-3.91,12.16-2.67c0.04,1.85,0.09,3.71,0.13,5.56c-10.27,1.06-9.53,7.37-6.92,14.56
            c-3.57,1.53-7.15,3.05-10.72,4.58c-3.4-2.94-8.58,1.87-11.6-2.68C145.27,244.72,139.63,242.44,138.32,238.27z"
            />
            <path
              style="fill: #505f39"
              d="M118.99,238.38c-0.96,2.34-1.91,4.67-2.87,7.01c-2.28,2.05-4.55,4.11-6.83,6.17
            c0,0.01-0.32-0.87-0.32-0.87c-2.98-3.81-4.66-8.41-7.39-12.38c0.17-5.76,3.37-7.72,8.78-7.26
            C115.07,231.46,119.51,231.92,118.99,238.38z"
            />
            <path
              style="fill: #9da78e"
              d="M143.52,250.22c5.51-6.26,8,0.68,11.61,2.68c-4.41,2.37-7.87,7.39-13.98,5.04
            C141.83,255.33,136.28,250.81,143.52,250.22z"
            />
            <path
              style="fill: #5a802c"
              d="M209.23,206.41c-5.62,1.43-11.24,2.87-16.86,4.3c-4.38-0.73-8.79,4.03-13.16-0.04
            c-1.31-0.98-2.23-2.26-2.72-3.76c-1.22-2.87-4.15-1.81-6.32-2.6c-7.12-3.48-6.3-9.15-4.21-15.17l-0.23-0.15
            c2.14-6.42,7.65-9.43,12.88-12.71c3.8-1.39,7.23-3.47,10.56-5.76c1.87-0.67,3.69-0.57,5.49,0.27c3.12,1.81,4.04,4.82,4.45,8.1
            c3.22,0.12-5.42,6.14,2.56,4.62c7.84-1.49,15.31,0.41,20.06,7.98c0.89,7.21-2.21,12.02-8.86,14.65
            C211.67,206.36,210.45,206.45,209.23,206.41z"
            />
            <path
              style="fill: #7aa22a"
              d="M227.2,201.37c5.38,3.57,6.14,7.11-0.13,10.5c-3.13,1.69-6.33,3.24-9.5,4.86
            c-2.32,0.57-4.63,1.14-6.95,1.7c-2.26-3.8-1.33-7.97-1.39-12.03c0.45,0.01,0.9,0,1.34-0.04
            C216.28,205.25,220.75,200,227.2,201.37z"
            />
            <path
              style="fill: #a1ba45"
              d="M180.03,208.06c3.47,3.88,9.17-2.63,12.34,2.65c-4.23,3.02-9.4,4.99-11.61,10.29
            c-5.94,0.98-0.09-9.43-7.12-7.4C171.9,207.29,175.66,207.33,180.03,208.06z"
            />
            <path
              style="fill: #445525"
              d="M180.03,208.06c-3.1,0.72-5.6,2.15-6.39,5.54c-1.99,1.49-3.99,2.99-5.98,4.48
            c1.03-4.75-3.47-6.99-4.81-10.64c-2.44-6.63-4.4-13.31,3.13-18.3c2.22,4.09,0.9,10.2,6.89,12.14c1.43,1.78,3.41,2.97,4.89,4.7
            C178.5,206.68,179.26,207.37,180.03,208.06z"
            />
            <path
              style="fill: #38481e"
              d="M172.63,228.19c-4.92,3.35-11.42,4.27-14.81,10.06c-1.16,1.97-3.52,4.39-5.77,1.84
            c-1.76-1.99,0.57-4.51,1.37-6.73c2.58-3.33,5.15-6.65,7.73-9.98C165.51,223.71,170.58,222.33,172.63,228.19z"
            />
            <path
              style="fill: #2e3d1c"
              d="M25.35-131.71c4.2,2.77,10.45,12.49,9.03-3c-0.28-3.04,2.73-6.37,4.73-10.68
            c3.27,4.28,5.89,7.69,8.5,11.11c5.59,4.35,4.04,9.65,1.99,15.03c-2.02,6.4-5.76,10.8-12.97,11.16c-3.9,0.93-7.12-0.42-9.98-2.96
            C25.51-117.89,19.57-124.43,25.35-131.71z"
            />
            <path
              style="fill: #435824"
              d="M49.81-134.34c0.84-1.59,1.68-3.18,2.52-4.77c0.12-0.61,0.24-1.22,0.35-1.84
            c5.47-6.38,9.54-3.78,13.11,2.04c3.05,6.05,10.36,5.07,14.77,8.93c0.95,1.67,1.19,3.44,0.82,5.31
            c-0.54,1.77-1.57,3.21-2.95,4.39c-2.08,1.5-3.27,3.42-2.78,6.13c0.94,5.15,0.71,10.01-5.49,11.4c-5.44,1.22-8.99-2.3-12-6.28
            c-1.51-1.99-1.97-4.34-2.04-6.78c-0.21-5.67,1.08-11.79-4.65-15.85C50.72-132.44,50.16-133.32,49.81-134.34z"
            />
            <path
              style="fill: #c4bd9f"
              d="M25.35-131.71c0.93,6.52,1.86,13.04,2.78,19.56c0.57,0.92,0.76,1.9,0.49,2.95
            c-0.82,1.45-2,2.34-3.73,2.35c-0.68-9.66-8.92-17.38-7.12-27.68C23.15-141.25,23.26-133.81,25.35-131.71z"
            />
            <path
              style="fill: #93ba24"
              d="M55.07-155.6c4.38,0.52,13.19-5.47,9.69,6.61c-1.15,2.74-1.22,6.65-5.79,5.89
            c-3.37-0.56-5.14,1.28-6.61,3.82c0,0-0.04,0.15-0.04,0.15c-2.44-2.03-11.64-1.81-2.65-7.66C53-148.93,54.88-151.67,55.07-155.6z
            "
            />
            <path
              style="fill: #55732b"
              d="M49.81-134.34c0.98,0.12,1.95,0.23,2.92,0.35c3.39,4.26,8.62,8.07,3.46,14.4
            c-4.12,2.35-7.4,2.02-9.26-2.88c0.23-3.94,0.45-7.87,0.68-11.81C48.35-134.31,49.08-134.33,49.81-134.34z"
            />
            <path
              style="fill: #546f29"
              d="M79.64-125.07c-0.23-1.75-0.46-3.51-0.69-5.26c-4.52-1.55-4.65-4.52-2.65-8.18
            c3.44,0.51,6.88,1.02,10.31,1.52c0.64,0.05,1.28,0.1,1.92,0.13c3.71-1.64,7.15-1.87,9.84,1.86c0.65,1.06,1.03,2.23,1.17,3.47
            c-1.35,4.47-0.91,9.6-4.76,13.19c-1.6,0.89-3.32,1.14-5.12,0.88C85.52-118.91,80.35-119.07,79.64-125.07z"
            />
            <path
              style="fill: #7aa038"
              d="M76.3-138.51c0.88,2.73,1.77,5.46,2.65,8.19c-8.94,6.72-10.19-2.53-13.89-6.7
            c-1.34-2.69,0.26-2.8,2.25-2.58C70.14-137.87,73.06-136.93,76.3-138.51z"
            />
            <path
              style="fill: #55732b"
              d="M67.31-139.6c-0.75,0.86-1.5,1.72-2.25,2.58c-3.74-3.53-7.69-5.86-12.69-2.24
            c0.33-8.1,9.65-4.71,12.4-9.72C67.58-146.39,69.64-143.59,67.31-139.6z"
            />
            <path
              style="fill: #30411d"
              d="M-38.68-107.16c5.67-1.67,11.33-3.33,17-5c7.56-3.6,4.57,2.71,5.23,5.59
            c0.41,3.23,2.01,5.85,4.18,8.18c1.73,4.65,4.34,8.64,8.54,11.45c2.88,2.64,4.02,5.83,3.14,9.68c-1.12,3.07-3.34,5.01-6.34,6.12
            c-7.31,0.31-7.28,10.49-14.53,10.9c-9.18-0.27-14.43-7.05-20.54-12.43c-0.79-0.94-1.34-2-1.67-3.17
            c1.71-5.54-0.84-10.73-1.35-16.09C-45.25-97.99-44.51-103.64-38.68-107.16z"
            />
            <path
              style="fill: #262a19"
              d="M-2.21-77.27c-1.67-2.8-3.34-5.59-5.01-8.39c-0.45-3.87-3.6-7.68-0.28-11.64
            c0.57-8.48-2.67-17.42,3-25.3c2.46,1.01,4.92,2.03,7.39,3.05c5.61,1.98,4.25,9.35,9.14,11.88c1.54,1.5,1.89,3.69,3.14,5.35
            c1.5,0.91,3.01,1.82,4.51,2.73c9.81,1,9.9,8.2,9.88,15.48c-2.62,6.34-6.44,11.58-13.16,14.12c-1.11,0.56-2.29,0.87-3.52,0.97
            c-1.84-0.03-3.66-0.14-5.43-0.7c-2.98-1.93-5.72-4.22-8.81-6.01C-1.63-76.24-1.92-76.76-2.21-77.27z"
            />
            <path
              style="fill: #68942c"
              d="M-7.49-97.3c4.76,3.77,2.77,7.7,0.28,11.64c-4.88-2.23-7.79-5.72-7.23-11.4
            c-0.49-4.03,1.76-4.06,4.63-3.18C-9.04-99.26-8.26-98.28-7.49-97.3z"
            />
            <path
              style="fill: #9cc020"
              d="M-9.81-100.24c-1.54,1.06-3.09,2.12-4.63,3.18c-2.19,0.58-4.22,5.18-5.02,3.35
            c-1.89-4.29-1.69-9.45,3.02-12.85C-14.03-104.68-9.64-104.85-9.81-100.24z"
            />
            <path
              style="fill: #b5c96e"
              d="M-6.79-134.64c0.57,0.53,1.24,0.84,2.01,0.94c4.81,2.08,3.05,4.28,0.11,6.49
            c-3.78,1.69-7.61,1.46-8.54-2.92C-14.24-134.92-9.71-134.16-6.79-134.64z"
            />
            <path
              style="fill: #b1c55f"
              d="M96.63-133.9c-2.7-0.99-5.4-1.97-8.1-2.96c4.56-11.39,11.54-5.44,18.09-2.52
            c0.15,1.66,0.29,3.33,0.44,4.99C103.76-130.45,100.12-133.82,96.63-133.9z"
            />
            <path
              style="fill: #baaf97"
              d="M25.55-84.84c1.54-6.3-2.41-10.42-5.85-14.74c0.08-3.61,2.73-5.37,5.2-7.26
            c0.78-0.95,1.57-1.89,2.35-2.84c9.32,9.69,10.86,25.49,3.62,37.23C22.72-73.84,23.87-79.23,25.55-84.84z"
            />
            <path
              style="fill: #cec4ba"
              d="M15.19-102.32c-1.6-0.72-3.19-1.44-4.79-2.16c-2.24-5.6-6.2-11.58,3.19-14.66
            C20.33-114.12,23.34-108.75,15.19-102.32z"
            />
            <path
              style="fill: #59572b"
              d="M13.58-119.13c-5.45,3.93,0.91,10.43-3.19,14.65c-7.59-2.49-5.58-9.76-7.49-15.07
            C6.46-119.41,10.02-119.27,13.58-119.13z"
            />
            <path
              style="fill: #9a976e"
              d="M-38.68-107.16c-0.57,5.7-3.77,11.09-1.98,17.07c-2.28,6.66-6.04,4.2-9.84,1.62
            c-4.17-7.16-1.53-13.52,2.32-19.74c2.24-2.44,3.8-5.67,7.43-6.54C-38.45-112.66-39.03-109.78-38.68-107.16z"
            />
            <path
              style="fill: #d8cfc9"
              d="M-40.75-114.76c-0.95,2.68,0.17,6.29-3.5,7.73c-7.47-2.88-7.4-7.14-2.24-12.32
            c1.51-1.61,3.01-3.22,4.52-4.82C-41.56-121.04-41.16-117.9-40.75-114.76z"
            />
            <path
              style="fill: #3e4422"
              d="M-46.49-119.35c3.13,3.67-3.57,9.14,2.24,12.33c-3.57,5.06-5.34,10.52-3.79,16.74
            c1.31,5.23-3.81,6.75-5.8,10.09c-1.49,1.17-3.16,1.7-5.05,1.43c-1.13-0.38-2.13-0.99-3.01-1.8c-7.3-9.42-2-17.99,1.75-26.63
            c1.8-1.56,1.8-1.56-2.71-3.55c-2.68-2-3.16-5.07-3.98-7.98c-0.3-1.86-0.29-3.71,0.05-5.56c1.17-3.32,3.15-5.38,7.01-4.51
            c4.63-0.53,8.36-4.66,13.44-3.35C-46.18-127.89-54.37-123.73-46.49-119.35z"
            />
            <path
              style="fill: #414424"
              d="M-59.76-128.8c-1.25,1.01-2.51,2.02-3.76,3.04c-0.26,1.7-1.24,2.47-2.96,2.29
            c-11.45-6.1-13.11-11.22-7.25-22.36c3.12-0.77,5.95-0.45,8.2,2.14c0.61,1.5,1.23,3,1.84,4.49
            C-62.1-135.84-57.76-133.52-59.76-128.8z"
            />
            <path
              style="fill: #2a361a"
              d="M276.6,290.11c0.01-0.83,0.02-1.67,0.02-2.5c6.83-0.49,13.55-2.29,14.7-9.93
            c1.54-10.25,6.33-8.57,12.55-4.78c1.58,0.81,3.16,1.62,4.74,2.44c6.6-1,13.19-1.99,19.1,2.44c4.24,6.12,9.77,11.52,10.92,19.38
            c-10.31-1.34-20.56-3-30.26-6.99C297.78,286.45,287.19,289.61,276.6,290.11z"
            />
            <path
              style="fill: #4a5f27"
              d="M338.63,297.16c-4.1-5.62-8.2-11.24-12.3-16.86c-2.38-4.52-2.29-8.66,1.83-12.21
            c1.54-2.45,1.63-4.94,0.15-7.45c0.91-2.67,0.78-5.65,2.42-8.1c1.07-3.65-9.72-6.85-2.64-8.24c7.48-1.48,3.14-18.38,15.05-9.91
            c2.71,3.08,3.09,6.17,0.08,9.3c-8.41,5.52-6.85,19.73,3.22,29.35c-3.58,3.5-7.99,5.35-12.87,5.65c0.12,5.36,5.36,5.56,6.88,8.89
            c-2.7,4.91,3.02,4.72,4.28,7.18c1.74,3.38,7.62,6.73,0.65,10.71C342.74,303.02,343.16,298.07,338.63,297.16z"
            />
            <path
              style="fill: #2f3f30"
              d="M262.04,307.46c3.85-3.65,0.07-16.95,12.42-9.85c0.83,0.01,1.67,0.02,2.5,0.03
            c-2.72,5.09-7.71,7.32-12.42,9.9C263.7,307.51,262.87,307.48,262.04,307.46z"
            />
            <path
              style="fill: #2c3a1c"
              d="M-125.27-94.81c4.39,0.16,8.61-0.04,12.41,3.4c5.64,5.1,10.21,3.36,13.03-3.51
            c1.47-0.6,2.93-1.19,4.4-1.79c5.33,3.06,6.42,6.92,2.22,11.78c-0.76-0.02-1.53-0.05-2.29-0.1c-2.41-0.53-6.17-4.85-6.35,1.47
            c-0.12,4.24-2.01,7.39-3.75,10.81c-3.29-4.07-2.19-11.7-9.89-12.19C-122.04-84.97-128.62-84.96-125.27-94.81z"
            />
            <path
              style="fill: #8eb031"
              d="M-125.27-94.81c0.4,6.13,5.45,7.64,9.77,9.87c-4.95,5.6-9.68,2.94-14.39-0.4
            c-0.11-0.7-0.31-1.38-0.59-2.04C-129.72-90.53-127.92-92.97-125.27-94.81z"
            />
            <path
              style="fill: #9cbe20"
              d="M-100.48,11.11c-1.81-2.34-4.46-2.33-7.04-2.5c-1.3-9.37,10.28-9.63,11.94-16.91
            c6.24,1.21,12.78,1.71,17.18,7.28c0,0,0.09-0.01,0.09-0.01c2.64,11.84-1.95,17.17-13.07,14.61
            C-94.4,12.9-97.84,13.3-100.48,11.11z"
            />
            <path
              style="fill: #12150e"
              d="M-58.93-82.38c0.06,0.78,0.16,1.55,0.28,2.32c8.6,10.18,5.16,21.92,4.02,33.23
            c-0.71,7.02-4.61,13.77-1.54,21.08c5.95,5.94,9.91,14.27,3.14,19.8c-6.14,5.02-15.18,2.82-20.57-5.1
            c-3.94-6.62-6.25-13.57-3.88-21.3c1.12-3.64-0.38-6.13-3.03-8.29c0.02-0.8-0.11-1.57-0.4-2.32c-3.47-13.47-6.9-26.93,7.26-36.92
            c-1.29-3.08-2.41-6.24,1.24-8.46c2.89-1.76,5.37-0.13,7.71,1.57C-62.73-85.36-60.85-83.84-58.93-82.38z"
            />
            <path
              style="fill: #202616"
              d="M-58.93-82.38c-4.83,1.27-11.91-10.76-14.71,2.49c-2.55-2.98-5.1-4.89-9.27-2.23
            c-4.14,2.64-7.69,1.09-10.32-2.82c-0.74-3.93-1.48-7.86-2.22-11.78c0,0-0.17-0.44-0.17-0.44c3.53-6.11,1.74-15.46,10.99-18.09
            c6.96-1.24,12.57-8.76,20.73-4.51c0.9,2.5,1.8,5.01,2.69,7.51c1.61,1.44,2.89,3.03,2.41,5.4
            C-54.91-98.67-64.1-90.56-58.93-82.38z"
            />
            <path
              style="fill: #2b381f"
              d="M266.77,216.07c-0.01-1.55-0.02-3.11-0.04-4.66c1.31-0.93,2.68-1.79,3.93-2.8
            c3.31-2.68,6.35-8.09,10.61-6.32c5.14,2.14,8.59,7.24,7.74,13.77c2.55,7.08-3.26,12.29-6.35,16.06
            c-4.26,5.2-5.49-2.14-6.83-5.21C273.85,222.34,276.14,214.41,266.77,216.07z"
            />
            <path
              style="fill: #567a2b"
              d="M326.22,203.85c0.48,5.08,8.85,11.62-2.67,14.48c0.29-4.83-1.21-9.27-2.74-13.75
            c-0.54-1.58-1.7-4.37,1.32-4.47C323.46,200.06,324.86,202.52,326.22,203.85z"
            />
            <path
              style="fill: #69902c"
              d="M323.51,126.83c-0.89,2.66-1.77,5.33-2.66,7.99c-4.89,2.26-10.97,10.94-11.76-3.72
            c-7.49,5.3-8.02,15.57-17.4,16.17c-0.56-0.16-1.13-0.33-1.69-0.49c-1.58-0.96-2.54-2.34-2.86-4.17
            c0.5-7.4,12.96-7.84,10.01-17.27c0.87-2.88,1.83-5.71,5.01-6.9c4.67-0.74,8.97,2.09,14,0.76
            C321.62,117.78,324.16,121.27,323.51,126.83z"
            />
            <path
              style="fill: #33461e"
              d="M293.8,164.17c-5.93,2.13-6.13,10.05-12.16,12.07c-5.14-0.47-6.85,10.44-12.79,4.76
            c-4.94-4.72-0.66-10.75,1.18-15.94c0.94-2.65,2.67-5.04,3.47-7.83c1.02-2.47,2.75-4.12,5.39-4.72c2.01-0.17,3.72,0.48,5.1,1.97
            C287.52,157.43,289.03,162.46,293.8,164.17z"
            />
            <path
              style="fill: #69902c"
              d="M283.98,154.47c-1.63-0.05-3.26-0.1-4.9-0.15c-2.74-3.85-2.75-7.49,1.22-10.45
            c4.48-3.35,7.25,0.86,10.53,2.68c0,0,0.86,0.72,0.86,0.72C289.12,149.68,286.55,152.07,283.98,154.47z"
            />
            <path
              style="fill: #405c24"
              d="M276.29,159.27c1.65,1.64,4.3,5.34,2.53,5.57c-9.67,1.23-3.95,10.01-7.6,13.46
            c4.97,5.86,4.66-10.79,10.41-2.05c-1.57,1.32-2.33,2.97-2.06,5.04c-1.81,1.8-3.62,3.61-5.43,5.42
            c-2.08,2.82-2.89,6.67-6.62,8.17c-12.91,2.28-16.89-0.57-19-13.58c0.81-2.25,1.73-4.47,2.64-6.68
            c8.5-11.1,5.41-20.04-10.19-29.4c-0.83-1.78-0.82-3.57-0.06-5.37c0.73-1.09,1.71-1.86,2.94-2.31
            c5.92,0.86,11.64-2.42,17.78-0.25C272.22,141.03,273.06,150.85,276.29,159.27z"
            />
            <path
              style="fill: #374c20"
              d="M252,181.49c0.77,8.37,5.84,10.91,13.47,9.98c6.34,2.74,3.51,6.3,0.88,9.85
            c-0.71,1.69-1.43,3.38-2.14,5.08c-16.34,0.57-16.06-12.97-19.67-23.21C245.82,177.28,248.29,176.65,252,181.49z"
            />
            <path
              style="fill: #909d99"
              d="M266.36,201.32c-2.53-3.08-0.25-6.6-0.88-9.85c1.77-3.63,3.97-6.46,8.66-4.78
            C278.65,198.38,277.25,201.01,266.36,201.32z"
            />
            <path
              style="fill: #abc43d"
              d="M323.51,126.83c-1.68-2.66-2.99-6.27-7.1-2.83c-6.66,5.56-9.9,0.85-12.99-4.32
            c-1.1-2.71-1.37-4.98,2.64-4.97c4.19,0.02,8.38,0.04,12.58,0.07c2.62,2.73,5.25,5.46,7.87,8.19
            C326.13,124.74,325.65,126.43,323.51,126.83z"
            />
            <path
              style="fill: #bed039"
              d="M306.69,92.67c-1.12,6.51-2.24,13.02-3.36,19.53c-1,3.32-2.51,6.27-5.72,8
            c-7.89,2.14-13.74,0.22-16.34-8.19c-0.79-1.8-1.27-3.66-1.07-5.64c1.75-5.43,5.12-10.45,4.29-16.58
            c0.95-3.09,2.79-5.27,6.08-5.98c5.06-0.28,7.74,5.05,12.55,5.38c1.08,0.43,2.03,1.07,2.83,1.91
            C306.2,91.62,306.44,92.14,306.69,92.67z"
            />
            <path
              style="fill: #93b729"
              d="M328.38,70.46c-0.32,3.84,3.59,3.38,5.26,5.21c-2.92,4.45,0.55,8.45,0.84,12.67
            c0.29,4.29,0.98,9.18-5.66,9.47c-3.45,0.56-6.9,1.13-10.35,1.68c-0.91-0.78-1.82-1.56-2.72-2.34c-0.28-4.14-3.2-8.04-1.12-12.39
            c0.23-1.19,0.54-2.36,0.91-3.52C317.88,75.35,321.52,70.98,328.38,70.46z"
            />
            <path
              style="fill: #32461f"
              d="M298.66,117.2c1.56-1.67,3.11-3.34,4.67-5.01c0.91,0.84,1.82,1.68,2.74,2.52
            c-0.88,1.66-1.76,3.31-2.64,4.97c-2.4,1.98-1.65,4.91-2.23,7.44c-2.02,6.33-5.78,9.74-12.82,7.69
            c-7.23-6.23-5.23-10.92,2.59-14.34C293.51,119.36,296.48,119.22,298.66,117.2z"
            />
            <path
              style="fill: #5d832f"
              d="M318.82,84.87c-1.02,4.1-2.04,8.2-3.07,12.3c-2.77-2.02-5.21-4.69-9.06-4.5
            c0,0-0.99,0.28-0.99,0.28c-1.38-3.26-2.86-6.52-0.81-10.07c1.6-2.15,3.79-3.05,6.41-3.04
            C314.75,80.08,316.52,82.86,318.82,84.87z"
            />
            <path
              style="fill: #b0ca32"
              d="M328.38,70.46c-3.31,3.78-7.47,6.92-9,12.03c-7.76-5.78-7.48-7.71,1.73-11.93
            c2.37-1.75,4.74-3.5,7.11-5.25C328.27,67.03,328.32,68.74,328.38,70.46z"
            />
            <path
              style="fill: #648a33"
              d="M-112.76-135.42c3.13-1.19,6.26-2.37,9.39-3.56c8.1,2.36,9.44,8.3,8.28,15.59
            c-3.51,5.93-7.05,11.87-15.26,6.17C-117.13-122.5-111.78-129.38-112.76-135.42z"
            />
            <path
              style="fill: #576d27"
              d="M-110.35-117.23c6.07,0.98,8.82-3.48,11.96-7.3c4.97,3.38,8.33,9.4,15.38,9.34
            c0.67,1.11,0.57,2.15-0.29,3.12c-7.71,1.98-5.39,12.27-12.32,14.91c-0.26-10.14-6.88-12.69-15.42-12.93
            C-110.81-112.48-110.58-114.85-110.35-117.23z"
            />
            <path
              style="fill: #4d5937"
              d="M-123.31-114.14c-4.65-0.34-9.44-0.39-11.61-5.76c5.45-2.13,3.2-10.15,9.11-11.92
            c7.62,4.36,7.41,10.3,3.43,17L-123.31-114.14z"
            />
            <path
              style="fill: #8fa59f"
              d="M-122.39-114.82c0.22-5.94,1.12-12.02-3.42-17c0.49-2.06-0.03-6.01,3.64-4
            C-116.84-132.9-117.27-119.53-122.39-114.82z"
            />
            <path
              style="fill: #191c13"
              d="M-83.67-114.84c-11.56,4.57-13.49,3.3-14.72-9.69c0.63-5.61-3.79-9.47-4.98-14.45
            c1.84-8.33,3.9-16.44,15.25-15.26c0,0-0.13,0.24-0.13,0.24c12.51,8.87,14.87,15.63,9.83,29.42c-0.87,2.39-0.2,4.8-1.06,7.08
            C-80.34-115.76-81.73-114.87-83.67-114.84z"
            />
            <path
              style="fill: #4f5b30"
              d="M313.81-151.75c2.19-1.7,3.94-4.9,7.42-2.17c2.83,2.65,4.42,6.22,3.58,9.74
            c-2.35,9.9,2.81,17,8,24.22c-2.24,1.37-3.66,4.11-6.76,4.01c-1.33,3.37-0.8,8.38-6.74,7.68c-1.24-0.39-2.26-1.11-3.05-2.14
            c-1-9.6-3.16-18.86-8.16-27.28c0.51-4.6,2.28-8.65,5.34-12.13C313.38-150.49,313.51-151.14,313.81-151.75z"
            />
            <path
              style="fill: #526f52"
              d="M325.88-75.17c8.77,3.77,14.21,8.48,4.92,17.38c-0.87,0.89-1.92,1.49-3.11,1.84
            c-1.27,0.12-2.48-0.11-3.63-0.66c-3.63-3.59-6.74-7.55-8.62-12.38c-0.26-2.88,0.49-5.37,2.81-7.24
            C321.03-77.61,323.55-77.09,325.88-75.17z"
            />
            <path
              style="fill: #9eb7d3"
              d="M325.88-75.17c-2.38-0.07-4.75-0.13-7.13-0.2c-1.88,0.22-3.39-0.51-4.68-1.81
            c-3.13-3.88-3.17-8.68-3.91-13.28c0.18-4.54,1.47-8.41,6.13-10.23c1.18-0.21,2.33-0.02,3.41,0.49c2.82,1.66,2.41,6.58,6.74,6.75
            C331.15-87.21,326.72-81.24,325.88-75.17z"
            />
            <path
              style="fill: #889588"
              d="M319.73-109.96c3.44-0.57,4.36-3.82,6.31-5.97c0.22,6.09,7.13,12.48-0.8,18.21
            c-2.8,2.64-4.82,0.91-6.76-1.22l-0.01-0.7C315.51-103.5,315.18-107.03,319.73-109.96z"
            />
            <path
              style="fill: #48663a"
              d="M327.69-57.56c1.04-0.08,2.08-0.15,3.12-0.22c3.56,3.11,7.85,2.48,12.05,2.34
            c0.82,0.93,1.2,2,1.08,3.25c-2.6,4.53-3.59,10.83-11,10.16c-1.77-0.79-2.91-2.24-3.95-3.8
            C325.86-49.45,324.09-53.21,327.69-57.56z"
            />
            <path
              style="fill: #ccd8e4"
              d="M318.47-98.95c2.35-0.14,4.01,3.57,6.76,1.22c0.4,1.43,0.8,2.86,1.2,4.28
            c-1,1.93-1.22,5.28-4.29,4.41C316.9-90.53,319.23-95.44,318.47-98.95z"
            />
            <path
              style="fill: #9fac5f"
              d="M350.56-75.08c2.16,4.93,2.47,8.63-4.83,7.28c-0.69-3.24-1.53-6.81,0.74-9.47
            C350.02-81.44,349.51-76.47,350.56-75.08z"
            />
            <path
              style="fill: #99ad48"
              d="M342.87-52.76c-0.01-0.9-0.01-1.8-0.02-2.69c0.4-4.67,4.54-5.79,7.57-7.95
            c0.69-0.05,1.38-0.1,2.07-0.16c5.56,6.23,2.66,9.69-3.91,11.95c-1.34,0.39-2.69,0.4-4.04,0.04
            C343.98-51.97,343.43-52.36,342.87-52.76z"
            />
            <path
              style="fill: #384e21"
              d="M333.33-43.49c3.76-2.49,4.2-8.41,9.54-9.26c0,0,0.5-0.24,0.5-0.24c2.6,3.02,5.58,5.71,7.78,9.07
            c0.77,2.72,2.13,5.41,0.52,8.27c-4.42,2.2-9.48,3.87-8.51,10.47c2.71,3.45,5.43,6.91,8.14,10.36c1.59,3.31,0.1,5.68-2.36,7.76
            c-3.65,1.96-5.35,5.92-8.54,8.36c-1.35,0.12-2.69,0.23-4.04,0.35c-2.96,4.65-4.31,11.32-12.21,9.82
            c-3.83-1.97-7.64-3.99-10.13-7.73c-1.39-2.6-0.91-5.55-1.51-8.31c-0.33-2.17-1.33-4.17-1.61-6.37
            c-0.07-1.15,0.08-2.26,0.38-3.37c0.6-1.62,1.51-3.05,2.58-4.4c2.62-7,12.59-8.03,13.37-16.54
            C328.12-38.84,329.42-42.13,333.33-43.49z"
            />
            <path
              style="fill: #adc943"
              d="M346.31-10.33c1.66-1.5,3.32-2.99,4.99-4.49c2.73-1.03,4.68,1.07,6.99,1.76
            c1.61,3.3,3.22,6.61,4.84,9.91c-0.97,1.8-1.94,3.59-2.91,5.4c-1.45-0.2-2.9-0.4-4.35-0.61c-1.98-3.14-5.94-2.49-8.53-4.45
            C344.47-4.96,342.58-7.27,346.31-10.33z"
            />
            <path
              style="fill: #7f8a46"
              d="M348.05-42.8c-4.13-2.21-4.61-6.11-4.68-10.19c0.65,0.02,1.3,0.08,1.95,0.17
            c3.32,0.88,7.31-1.22,10.06,2.23c0.56,5.07,9.37,8.53,2.89,14.96C352.54-34.71,351.35-40.25,348.05-42.8z"
            />
            <path
              style="fill: #b4c494"
              d="M355.38-50.59c-3.53,0.06-7.85,3.69-10.06-2.23c3.45-2.87,9.31-4.12,7.17-10.74
            C359.35-62.31,359.72-60.66,355.38-50.59z"
            />
            <path
              style="fill: #b1bba6"
              d="M348.05-42.8c5.3-0.32,6.13,5.76,10.22,7.17c-1.93,2.49-4.33,3.16-7.23,1.88
            c-1.93-0.49-2.04-1.25-0.34-2.28C348.68-37.84,347.78-40.09,348.05-42.8z"
            />
            <path
              style="fill: #adbc76"
              d="M350.69-36.02c0.18,0.75,0.29,1.51,0.34,2.28c-4.78,0.87-4.22,6.66-7.88,8.57
            C338.38-33.87,344.98-34.64,350.69-36.02z"
            />
            <path
              style="fill: #58792b"
              d="M-86.01,85.65c-1.04,12.67,7.86,27.66,21.57,33.84c-10.78,3.83-19.18,10.96-29.91,3.51
            c-0.99-0.69-2.38-1.69-3.31-1.45c-15.14,3.87-15.77-5.79-15.45-16.4c-1.15-0.94-1.93-2.13-2.34-3.56
            c0.04-5.04,1.22-8.79,7.56-6.72c0.81,0.08,1.62,0.14,2.43,0.19c2.78,0.26,5.85,1.44,7.34-2.35c0.08-1.58,0.15-3.15,0.23-4.73
            c0.95-3.79,2.79-6.64,7.16-6.86C-88.1,81.53-86.28,82.78-86.01,85.65z"
            />
            <path
              style="fill: #455b24"
              d="M-100.48,11.11c9.68,0.16,20.24,1.91,22.17-12.13c6.66,3.75,4.03,10.91,5.58,16.21
            c5.03,2.85,3.31-5.41,7.29-3.82c3.71,3.48-1.06,21.08-8,29.53c-5.62-1.02-10.01,1.24-13.75,5.21c-2.24,1.03-4.47,2.11-7.06,1.75
            c-4.11-1.18-6.04-4-6.1-8.2c1.56-4.25,2.74-8.77,7.21-11.15c3.23,0.93,4.82-0.04,3.98-3.57
            C-90.67,18.5-95.27,14.54-100.48,11.11z"
            />
            <path
              style="fill: #3e5321"
              d="M-90.72,83c-2.39,1.66-4.78,3.32-7.17,4.98c-1.62-2.82-3.32-5.39-6.1-7.44
            c-6.5-4.8-14.26-9.8-2.77-17.73c0.57-0.39-0.35-2.94-0.58-4.48c-1.01-7.5-0.14-7.96,9.71-5.11
            c7.28,5.61,11.83,12.52,11.48,22.22C-86.28,79.12-87.49,81.51-90.72,83z"
            />
            <path
              style="fill: #2d381c"
              d="M-113.28,102.66c0.06,0.83,0.12,1.66,0.18,2.49c-4.18,3.96,0.43,12.17-7.03,14.54
            c-2.52-7.34-5.05-14.68-7.57-22.02c-1.27-3.47-6.64-6.32-2.71-10.55c3-3.22,6.78-0.51,10.12,0.71c0,1.63-0.01,3.26-0.01,4.89
            C-115.56,94.33-114.27,98.38-113.28,102.66z"
            />
            <path
              style="fill: #5c712c"
              d="M-93.13,28.51c0.86,4.52,1.77,9.07-4.79,10.04c-3.18-0.28-3.45-3.51-5.25-5.19
            c-1.06-0.52-2.13-1.04-3.19-1.57c-4.25-2.12-1.72,8.91-7.13,3.8c-2.28-2.15-1.39-5.41,0.11-7.97c2.53-4.32,6.94-6.19,11.34-5.15
            C-98.78,23.23-96.08,26.41-93.13,28.51z"
            />
            <path
              style="fill: #728138"
              d="M-103.17,33.36c1.75,1.73,3.5,3.46,5.25,5.19c0.24,3.15,1.85,5.44,4.45,7.11
            c2.68,2.69,1.53,5.4,0.04,8.11c-0.85,0.86-1.85,1.48-3,1.85c-0.6-0.01-1.19-0.02-1.79-0.02c-3.09,0.73-8.21-5.3-9.12,2.74
            c-5.15,0.01-8.97-1.94-8.29-7.68c0.73-6.15,5.21-2.89,8.72-3.59C-99.1,45.51-105.18,37.89-103.17,33.36z"
            />
            <path
              style="fill: #505f2d"
              d="M-129.16,34.68c4.21,2.15,3.29,4.46,3.84,6.26c1.31,4.31-8.3,12.33,4.35,13.16
            c0.56,0.04,1.71,5.5,0.93,7.9c-1.03,3.15-4.13,1.59-6.08,0.37c-4.06-2.52-9.81-5.32-7.14-10.91
            C-130.81,46.33-129,41.34-129.16,34.68z"
            />
            <path
              style="fill: #3e5321"
              d="M-113.28,102.66c-2.37-3.3-6.27-5.51-7.03-9.94c5.27-5.78,10.57-11.69,12.43,2.16
            C-111.67,96.08-112.87,99.1-113.28,102.66z"
            />
            <path
              style="fill: #3e5321"
              d="M-98.11,92.72c1.76,2.26,1.67,4.49-0.95,5.74c-3.32,1.58-4.86-1.11-6.4-3.4
            C-103.37,93.14-100.81,92.72-98.11,92.72z"
            />
            <path
              style="fill: #262e18"
              d="M111.77-122.04c3.75,1.83,5.53-0.5,7.77-3.11c5.43-6.32,9.34-4.34,11.76,2.84
            c-2.54,6.16,3.75,8.14,5.76,12.17c1.97,3.83,1.61,7.22-1.85,10.04c-7.07,3.57-13.57-1.85-20.47-1.31
            c-6.96-1.48-6.82-7.62-8.32-12.68C106.41-117.95,107.95-120.77,111.77-122.04z"
            />
            <path
              style="fill: #475d24"
              d="M134.09-109.86c-5.56-0.31-9.76-3.54-10.36-8.52c-0.51-4.23,4.62-3.25,7.57-3.93
            c4.02,1.66,8.04,3.32,12.06,4.99c1.82-0.15,3.4,0.41,4.76,1.63c1.62,1.96,2.23,4.1,1.19,6.54c-0.7,0.96-1.57,1.72-2.61,2.28
            c-1.08,0.37-2.21,0.54-3.36,0.54C139.91-106.57,137.49-109.52,134.09-109.86z"
            />
            <path
              style="fill: #495324"
              d="M146.53-114.35c-1.06-0.99-2.11-1.98-3.17-2.97c-0.79-3.17-1.57-6.35-2.35-9.52
            c5.42-1.97,8.38-7.01,12.77-10.26c6.77,0.17,2.56,8.03,6.91,9.89c0,0.93,0,1.86,0.01,2.79c-0.14,0.56-0.29,1.12-0.43,1.68
            c-2.98,3.54-6.15,6.85-10.37,8.95C148.72-113.65,147.59-113.82,146.53-114.35z"
            />
            <path
              style="fill: #7e9753"
              d="M111.77-122.04c-0.76,2.53-1.52,5.07-2.28,7.6c-1.48,1.61-3.34,2.19-5.48,2.04
            c-3.57-0.8-5.47-3.1-5.94-6.66c-1.01-4.19,1.65-8.79-1.37-12.78c-0.06-0.69-0.09-1.38-0.07-2.07c3.31-3.69,6.9-1.42,10.43-0.48
            C109.98-130.78,111.09-126.49,111.77-122.04z"
            />
            <path
              style="fill: #8ea855"
              d="M176.27-140.86c0.62-1.21,1.25-2.42,1.87-3.64c8.1,3.71,9.31,13.8,16.74,18.13
            c1.97,1.88,1.71,3.81,0.14,5.77c-6.83,3.59-13.84,2.74-20.89,0.87c-6.38-6.09,1.16-7.94,3.47-11.39
            C177.85-134.46,178.37-137.84,176.27-140.86z"
            />
            <path
              style="fill: #5e722a"
              d="M177.6-131.12c-1.48,2.91-2.96,5.82-4.44,8.73c0.84,0.97,1.11,2.09,0.81,3.34
            c-2.83,4.39-8.63,4.61-11.85,8.48c-3.24,1.73-6.34,1.67-9.28-0.67c-4.51-8.76,4-9.7,8.01-13.34c0,0-0.15,0.15-0.15,0.15
            c0.8-0.06,2.23,0.05,2.3-0.21C165.23-132.85,172.37-129.83,177.6-131.12z"
            />
            <path
              style="fill: #c0bc99"
              d="M160.69-127.22c-9.67,1.86-2.04-8.39-6.91-9.89c-0.05-1.5-0.09-3.01-0.13-4.52
            C165.78-141.62,159.16-132.42,160.69-127.22z"
            />
            <path
              style="fill: #688b33"
              d="M323.95,43.75c-4.17,3.83-1.23,9.36-3.25,13.7c-1.13,2.75-3.35,3.04-5.9,2.63
            c-6.47-3.47-4.38-10.42-6.39-15.73c0.32-3.47,1.92-6.01,5.28-7.25c2.04-0.35,3.81,0,5,1.9
            C320.44,40.57,322.19,42.16,323.95,43.75z"
            />
            <path
              style="fill: #bbc4b5"
              d="M318.69,38.99c-1.46-0.34-2.93-0.68-4.39-1.02c-0.59-0.18-1.17-0.35-1.76-0.53
            c-6.92-11.1-6.5-13.47,3.22-18.24c4.49,0.82,6.05,4.99,8.82,7.78c0.67,1.93,0.26,4.47,2.97,5.26
            C326.27,36.68,322.47,37.81,318.69,38.99z"
            />
            <path
              style="fill: #9ebe3a"
              d="M327.55,32.23c-2.84-0.03-3.6-2.23-4.5-4.3c-2.22-5.03-4.13-10.6,2.39-13.37
            c4.91-2.09,9.51-0.43,10.47,6.01c-0.23,0.73-0.54,1.45-0.65,2.2c-0.05,0.33,0.34,0.71,0.52,1.07
            C334.15,27.73,328.61,27.77,327.55,32.23z"
            />
            <path
              style="fill: #8aa535"
              d="M321.1,70.56c-3.67,3.53-6.9,7.11-1.73,11.93c-0.27,0.78-0.46,1.57-0.55,2.38
            c-3.1,0.81-5.29-1.38-7.84-2.4c-3.72-5.35-5.64-10.67,0.23-15.89c2.81-0.99,5.24-0.57,7.09,1.94
            C319.24,69.21,320.17,69.88,321.1,70.56z"
            />
            <path
              style="fill: #d3da81"
              d="M318.3,68.53c-2.32-0.19-4.65-0.38-6.97-0.58c-3.33-1.21-4.16-3.95-4.37-7.09
            c0.36-1.88,1.38-3.21,3.2-3.89c1.2-0.12,2.31,0.12,3.33,0.78c2.4-0.1,4.81-0.2,7.21-0.3C319.9,61.14,319.1,64.84,318.3,68.53z"
            />
            <path
              style="fill: #445923"
              d="M219.55-159.56c2.42,3.39,4.83,6.78,7.25,10.16c4.85,1.63,5.94,6.29,8.12,10.09
            c-0.05,4.53-1.01,8.9-2.46,13.17c-4.06,3.41-6.34,9.77-13.44,8.15c-3.91-1.07-2.71-7.3-7.55-7.44c-2.5-0.78-7.5,2.03-6.17-4.24
            c0.15-0.93,0.3-1.86,0.45-2.79c2.57-5.28,4.92-10.61,4.23-16.71C215.05-150.9,216.62-155.86,219.55-159.56z"
            />
            <path
              style="fill: #719331"
              d="M212.31-129.6c2.35-1.41,5.1-4.52,7.48-2.16c4.44,4.4-1.26,7.89-2.2,11.86
            c-0.16,0.55-0.32,1.09-0.48,1.64c-5.16,4.89-11.43,8.93-10.39,17.74c0.33,2.76-2.66,4.82-4.92,6.61
            c-1.1,0.54-2.26,0.77-3.48,0.77c-2.51-0.32-4.36-2.01-6.54-3.04c-7.99-4.39-8.25-11.44-6.6-19.15c1.55-4.95,7.48-3.22,10.07-6.6
            c-0.12-1.48-0.24-2.95-0.37-4.43c1.87-0.06,3.74-0.12,5.61-0.18C205.31-124.17,209.51-124.17,212.31-129.6z"
            />
            <path
              style="fill: #31411d"
              d="M209.99-149.17c2.79,2.06,8.9,1.65,8.15,5.93c-1.05,6-7.03,8.54-12.39,10.79
            c-0.75-1.52-1.51-3.04-2.26-4.55c-5.14-2.85-1.74-6.44-0.85-9.81c0.06-0.93,0.13-1.86,0.2-2.78
            C205.22-149.46,207.6-149.31,209.99-149.17z"
            />
            <path
              style="fill: #a5c351"
              d="M212.31-129.6c6.18,3.75,0.12,4.58-1.63,5.8c-4.25,2.97-8.07,2.88-10.19-2.75
            c1.6-1.04,3.2-2.08,4.8-3.12C207.61-127.49,209.98-129.65,212.31-129.6z"
            />
            <path
              style="fill: #8d9b8a"
              d="M202.64-146.82c5.16,2.85-1.97,6.76,0.85,9.81c-6.29,2.41-5.76-3.87-6.46-6.23
            C195.65-147.93,200.59-145.72,202.64-146.82z"
            />
            <path
              style="fill: #788d3e"
              d="M264.51-104.9c4.71-6.04,4.48-16.64,15.51-16.84c4.31,2.7,7.19,7.93,13.28,7.5
            c2.77,1.19,4.66,3.19,5.54,6.09c0.91,5.77-3.04,10.28-4.04,15.58c-0.32,1.1-0.83,2.09-1.52,3c-1.64,1.86-3.86,2.32-6.16,2.58
            c-1.64,0.39-3.3,0.6-4.98,0.57c-3.75-0.6-7.51-1.23-11.27,0.07c-5.73-0.06-12.61,4.06-16.01-4.43
            c-1.8-7.09,4.02-9.76,7.89-13.57C263.34-104.54,263.93-104.72,264.51-104.9z"
            />
            <path
              style="fill: #c7c7a8"
              d="M291.46-112.1c-9.57,3.46-12.02,1.39-11.44-9.65c3.38-1.29,3.75-7.02,8.75-5.91
            c3.25-3.44,6.37-3.51,9.34,0.37c0.88,1.72,1.14,3.53,0.85,5.43C297.37-117.91,295.52-114.15,291.46-112.1z"
            />
            <path
              style="fill: #799444"
              d="M247-122c3.2-1.03,6.4-2.05,9.59-3.08c0.93,2.83,1.87,5.67,2.8,8.51
            c0.96,2.4-0.01,4.21-1.73,5.82c-2.34,1.57-5.09,1.72-7.75,2.2c-3.3,0.38-6.57,1.76-9.95,0.39c-4.18-1.78-8.82-3.82-7.15-9.36
            C235.16-125.28,241.81-121.87,247-122z"
            />
            <path
              style="fill: #869472"
              d="M295.93-126.67c-2.26-1.22-4.57-2.12-7.15-0.99c0.04-1.21,0.07-2.42,0.11-3.62
            c3.88-3.74,2.24-8.2,1.62-12.53c2.68-1.81,5.37-3.61,8.06-5.41c0.18,0.75,0.28,1.5,0.3,2.27c1.65,1.82,4.36,2.59,5.24,5.2
            c1.3,4.06-1.21,7.29-2.36,10.82C300.44-128.65,298.74-126.9,295.93-126.67z"
            />
            <path
              style="fill: #788d3e"
              d="M256.68-111.6c0.91-1.66,1.81-3.32,2.72-4.98c5.21,2.36,4.8,7.18,5.12,11.68
            c0,0-0.49,0.48-0.49,0.48C256.61-101.74,254.15-104.12,256.68-111.6z"
            />
            <path
              style="fill: #53664c"
              d="M301.32-141.8c-0.82-1.72-1.64-3.43-2.46-5.15c4.86-0.95,9.72-1.9,14.58-2.85
            c0.55,4.81-0.45,9.28-2.93,13.43c-1.01,0.68-2.12,0.95-3.33,0.85C303.85-136.32,303.52-139.94,301.32-141.8z"
            />
            <path
              style="fill: #29341a"
              d="M-80.97-117.9c-4.1-2.53-14.03-3.72-2.68-10.08c7.42-4.15,3.85-9.93,2.28-14.83
            c-1.29-4.05-4.53-7.49-6.9-11.2c8.79-4.32,9.37,6,14.53,8.16c-1.21,8.42,2.98,14.9,7.6,21.23
            C-69.34-118.52-73.81-115.24-80.97-117.9z"
            />
            <path
              style="fill: #191d12"
              d="M328.16,268.1c0.65,4.26-0.08,8.31-1.83,12.21c-5.73-2.27-12.52-0.76-17.72-4.96
            c0.36-3.84,0.53-7.79,4.99-9.47C316.61,278.73,323.74,264.52,328.16,268.1z"
            />
            <path
              style="fill: #74952d"
              d="M343.22,243.68c-0.03-3.1-0.05-6.21-0.08-9.3c4.42-1.16,7.65,1.28,7.6,4.99
            C350.69,242.8,346.43,243.32,343.22,243.68z"
            />
            <path
              style="fill: #6a7b3b"
              d="M330.73,252.54c4.05,4.15,5.49,7.53-2.42,8.1C324.44,256.54,325.88,254.03,330.73,252.54z"
            />
            <path
              style="fill: #445822"
              d="M229.8-159.04c2.12-2.1,4.83-2.33,7.6-2.38c5.99,0.74,8.84,4.35,9.28,10.18
            c-1.29,1.33-3.91,3.09-3.64,3.91c1.85,5.64,7.64,1.7,11,4.09c0.36,3.85-1.8,4.51-5.02,4.19c-3.49-0.35-6,1.04-6.94,4.64
            c-5.96,1.93-5.77-4.43-8.75-6.51C229.91-146.53,230.71-152.96,229.8-159.04z"
            />
            <path
              style="fill: #475b28"
              d="M247-122c-0.35,0.2-0.67,0.49-1.04,0.59c-7.42,1.97-15.41,3.75-3.61,11.68
            c0.72,1.29,0.73,2.58,0.02,3.89c-4.04,4.94-9.43,5.04-15.08,4.19c-1.34-0.42-2.5-1.14-3.48-2.14c-3.9-9.35,2.42-15.24,7.3-21.63
            c0.99-0.97,2.16-1.65,3.5-2.03c2.92-0.38,5.42,0.34,7.27,2.77C243.59-123.8,245.3-122.9,247-122z"
            />
            <path
              style="fill: #7b9741"
              d="M241.88-124.7c-2.39-0.01-4.79-0.02-7.18-0.04c-1.62-4.2-1.72-8.57-1.36-12.98
            c0.07-1.13,0.47-2.14,1.23-2.99c5.27-1.22,4.39,4.93,7.51,6.28C253.51-130.95,244.34-127.89,241.88-124.7z"
            />
            <path
              style="fill: #a2c036"
              d="M246.67-151.24c-7.7,0.82-6.98-6.05-9.28-10.18c2.33-1.76,4.66-3.52,6.99-5.29
            c2.35,0.33,4.69,0.66,7.04,0.99c1.54,3.81,7.15,6.5,3.23,11.81C251.99-153.02,249.33-152.13,246.67-151.24z"
            />
            <path
              style="fill: #56772a"
              d="M335.91,20.57c-3.16-1.31-6.33-2.61-10.06-4.16c-0.37,4.47,0.29,8.54-2.79,11.52
            c-6.03,1.66-5.5-3.63-7.07-6.69c-1.83-2.97-1.22-6-0.18-9.03c1.84-3.17,4.7-4.05,8.14-3.6c5.36-0.15,8.21-4.76,12.42-6.96
            c-2.23,11.44,6,7,12.92,6.81C344.83,14.06,343.9,20.84,335.91,20.57z"
            />
            <path
              style="fill: #64862d"
              d="M346.31-10.33c-2.13,8.24,10.62,4.57,9.55,11.97c-5.15-0.11-10.3-0.22-15.46-0.33
            C340.57-3.48,339.93-8.69,346.31-10.33z"
            />
            <path
              style="fill: #738348"
              d="M234.56-140.7c0.02,0.77,0,1.54-0.06,2.31c-0.92,0.57-1.5,0.33-1.74-0.73
            c-5.09-1.62-5.78-5.8-5.96-10.28c0.1-3.5,0.58-6.87,2.99-9.64C233.16-153.39,236.02-147.61,234.56-140.7z"
            />
            <path
              style="fill: #3a4a1e"
              d="M-21.88,38.07c-4.66,6.78-10.95,4-16.96,2.91c-3.1-10.43,6.08-17.73,6.69-27.78
            c1.24-20.54-0.12-40.11-9.28-58.75c-0.58-3.57-4.01-6.72-1.64-10.75c1.89-2.86,4.56-4.05,7.94-3.69
            c4.42,0.62,7.25,5.66,12.36,4.45c1.17,0.21,2.24,0.64,3.25,1.26c5.23,4.32,4.8,9.02,0.95,13.95
            c-3.41,6.68,0.94,13.02,0.86,19.55c3.24,8.23-2.38,14.74-5.01,21.44c-1.86,4.74-2.17,8.17,0.29,12.2
            c1.19,5.84,6.02,9.5,8.73,14.45C-12.87,33.62-14.44,38.09-21.88,38.07z"
            />
            <path
              style="fill: #1e2215"
              d="M-41.51-55.1c0.04,3.18,3.1,6.34,0.08,9.54c-17.14-2.51-12.21,11.43-14.74,19.8
            c-4.88-17.92-2.97-36.15-2.48-54.31c0.82-0.45,1.63-0.9,2.45-1.35c8.64-6.39,12.47-1.74,14.76,6.44l-0.08-0.12
            c2.71,2.51,4.46,5.58,5.23,9.2C-35.68-61.16-41.79-59.68-41.51-55.1z"
            />
            <path
              style="fill: #5b6e2e"
              d="M-68.22,85.07c-1.96,5.28-4.66,3.33-6.79,0.38c-1.57-2.17-2.82-3.89-5.73-2.63
            c-8.21-5.97-4.63-16.71-9.7-23.95c0.09-3.58,1.93-5.8,5.29-6.85c3.15-0.48,5.91,0.58,8.54,2.19c6.38,3.25,5.89,10.89,9.97,15.63
            c0.13,1,0.27,2,0.4,3.01C-66.9,76.92-67.56,80.99-68.22,85.07z"
            />
            <path
              style="fill: #798c3d"
              d="M-79.22,55.97c-2.91,1.34-3.82-0.37-4.27-2.8c-1.86-2.04-2.49-4.79-4.1-6.97
            c-0.47-0.99-0.75-2.03-0.88-3.12c2.11-3.22,0.18-9.47,7.5-8.79c4.63,0.43,7.98,0.98,7.53,6.61c1.47,4.22,2.94,8.45,4.41,12.67
            C-71.78,57.1-75.43,56.85-79.22,55.97z"
            />
            <path
              style="fill: #a2ad68"
              d="M-79.22,55.97c2.88-2.99,6.53-2.7,10.19-2.4c0.79,5.42,1.59,10.85,2.38,16.27
            C-70.84,65.22-75.03,60.59-79.22,55.97z"
            />
            <path
              style="fill: #202617"
              d="M49.75,176.57c-5.96,1.78-10.99,4.93-14.76,9.96c-3.17-1.73-6.34-3.45-9.51-5.18
            c-0.65-0.32-0.94-0.72-0.89-1.23c0.06-0.55,0.17-0.82,0.34-0.82c2.69-3.49,4.7-8.13,8.22-10.24c7.07-4.24,4.67-13.27,3-14.59
            c-9.94-7.84-5.83-17.83-6.06-27.2c3.28-4.97,5.39-11.46,13.37-10.39c3.9,14.39,17.79,23.02,21.66,37.48
            c-1.27,4.96-3.84,9.43-4.41,14.75C60.07,175.13,56.31,178.36,49.75,176.57z"
            />
            <path
              style="fill: #191c13"
              d="M42.38,117.37c-3.8,3.66-1.53,14.87-12.28,9.91c-9.02,0.4-7.32-5.93-6.34-10.94
            c1.01-5.16,1.46-9.7-3.36-13.16c0.3-4.19-4.23-8.01-0.98-12.45c12.94-12.12,27.92-11.18,36.74,7.64
            c0.76,1.02,1.46,2.08,2.11,3.17c4.19,5.33,7.41,10.74,1.48,16.94C53.64,123.31,48.11,118.82,42.38,117.37z"
            />
            <path
              style="fill: #4e767c"
              d="M7.67,127.02c0.04-1.51,0.07-3.02,0.11-4.53c2.63-1.67,4.04-0.05,5.3,1.97
            c9.65,2.8,7.12,10.05,4.75,15.83c-2,4.88-3.65,8.24,1.16,12.41c2.13,1.84,4.51,5.44,1.98,8.77c-2.74,3.61-6.3,1.51-9.96,1.19
            c-8.51-0.75-14.6-2.73-13.05-13.05c1.81-3.07,5.79-5.3,3.84-9.85C1.06,134.26,9.07,132.82,7.67,127.02z"
            />
            <path
              style="fill: #657e33"
              d="M19.42,92.8c0.02,3.49,4.75,6.53,0.98,10.38c-2.22-1.53-4.06-5.73-7.18-1.04
            c-1.61,1.2-3.29,1.7-5.09,0.43c-1.63-1.15-1.03-2.48-0.23-3.8c-4.81-0.96-10.41-1.03-9.5-8.44c-0.53-8.74-0.39-17.38,3.65-25.47
            c1.93-3.31,3.86-6.62,6.84-9.13c2.25-1.48,4.6-1.97,7.11-0.74c5.38,7.2,3.89,15.27,2,22.93C16.66,83.32,17.48,87.97,19.42,92.8z
            "
            />
            <path
              style="fill: #7e98ad"
              d="M7.9,98.76c1.08,2.22,2.73,3.54,5.32,3.37c9.55,7.5,4.82,14.92-0.14,22.33
            c-1.77-0.66-3.54-1.32-5.3-1.97C5.61,114.57,3.91,106.65,7.9,98.76z"
            />
            <path
              style="fill: #4c7367"
              d="M24.93,179.29c0.12,0.7,0.31,1.38,0.55,2.05c-0.02,0.89-0.04,1.79-0.06,2.68
            c-7.72,6.26-11.62,0.5-15.6-4.98C15.03,168.22,19.95,175.92,24.93,179.29z"
            />
            <path
              style="fill: #6a93aa"
              d="M2.52,139.69c1.81,2.07,6.85,2.22,5.88,5.26c-1.39,4.34-6.89,3.08-10.44,4.65
            c-9.68-5.43,7.38-5.53,2.01-10.1c0,0,1.13,0.28,1.13,0.28C1.57,139.75,2.04,139.72,2.52,139.69z"
            />
            <path
              style="fill: #2b391b"
              d="M-53.57,124.89c-1.36-2.43-2.2-6.36-4.18-7.06c-14.07-4.96-8.03-12.6-3.19-20.24
            c3.8,2.08,7.55,3.72,10.61,7.41C-40.38,117.01-40.14,117.03-53.57,124.89z"
            />
            <path
              style="fill: #29351a"
              d="M-88.53,58.32c6.25,7.01,7.23,15.69,7.79,24.51c-1.75,0.94-3.51,1.88-5.27,2.82
            c-1.57-0.88-3.14-1.76-4.71-2.64c3.33-10.73-2.62-18.92-7.51-27.4c0,0,0.05-0.02,0.05-0.02C-94,53.08-90.88,54.35-88.53,58.32z"
            />
            <path
              style="fill: #516627"
              d="M5.74,64.99C3.29,73.44,0.84,81.88-1.6,90.32c-1.83-0.91-3.67-1.82-5.51-2.73
            c4.09-9.58,3.7-19.54,2.47-29.57c-6.35-2.62-8.68-8.64-11.8-13.99c-1.81-1.99-3.62-3.97-5.43-5.96
            c4.07-2.91,5.52-6.93,4.99-11.8c-1.57-4.81-2.74-10.15,2.8-12.66c4.94-2.24,8.11,1.95,10.55,5.78c2.5,3.94,6.55,5.75,10.3,8.03
            c0.86,0.92,1.45,1.98,1.83,3.18c0.36,1.78,0.66,3.57,0.26,5.39C1.19,44.93,6.59,55.3,5.74,64.99z"
            />
            <path
              style="fill: #678e9f"
              d="M2.52,139.69c-0.47,0.04-0.95,0.07-1.42,0.09c-2.81-6.83-5.17-13.43,6.57-12.75
            C18.47,136.35,4.76,135.68,2.52,139.69z"
            />
            <path
              style="fill: #334d40"
              d="M37.32,203.63c4.05,3.26,5.61,8.01,7.51,12.57c-7.39,0.37-11.19-4.58-14.7-9.95
            c0,0-0.08,0.06-0.08,0.06C32.11,204.42,33.94,201.9,37.32,203.63z"
            />
            <path
              style="fill: #4a6b5b"
              d="M37.32,203.63c-2.42,0.89-4.84,1.78-7.27,2.67c1.32-5.5,3.83-10.08,9.41-12.3
            C38.75,197.21,38.03,200.42,37.32,203.63z"
            />
            <path
              style="fill: #979a4b"
              d="M-16.44,44.04c8.24,1.03,9.77,7.72,11.8,13.99c-3.01,1.55-6.03,3.1-9.04,4.66
            c-1.76-2.36-3.53-4.73-5.29-7.09C-22.3,50.83-17.73,47.79-16.44,44.04z"
            />
            <path
              style="fill: #34451e"
              d="M49.75,176.57c2.11-2.58,8.59-3.13,5.94-7.75c-4.89-8.52,2.25-10.6,6.33-14.54
            c5.15-13.34,15.05-6.34,23.53-5.52c3.1,3.22,4.12,7.46,5.57,11.49c0.25,0.78,0.54,1.53,0.85,2.28
            c6.57,15.74,6.52,15.9-8.6,23.95c-1.13,0.53-2.33,0.84-3.56,0.98c-8.05-3.21-17.71,5.13-25.05-3.42
            C53.09,181.55,51.42,179.06,49.75,176.57z"
            />
            <path
              style="fill: #4c6234"
              d="M138.57,206.21c-5.86,6.45-1.58,17.26-9.74,22.71c-2.02-0.7-4.36-1.85-3.02-4.2
            c3.75-6.56-0.31-8.4-5.36-8.71c-6.31-0.38-12.71-0.04-19.02,0.51c-3.46,0.31-7.18-1.29-6.31-3.73
            c3.34-9.28-6.54-12.05-8.13-18.67c0.05-0.55,0.09-1.1,0.14-1.65c1.11-3.67,0.55-8.1,4.68-10.42c2.85-0.87,5.69-1.85,8.74-0.98
            c1.13,0.44,2.14,1.09,3.01,1.93c2.26,3.96,3.61,8.7,8.75,10.21c7.76,2.66,16.91,0.58,23.56,7.13
            C137.63,202.08,139.59,203.35,138.57,206.21z"
            />
            <path
              style="fill: #1f2417"
              d="M54.76,184.03c5.08-1.32,10.55-0.28,15.43-3.4c3.89-2.49,6.97-0.01,9.23,3.4
            c5.17,1.35,12.56,1.02,7.57,10.05c0,0,0.01,0.04,0.01,0.04c-8.16-0.29-15.22,1.11-19.44,9.52c-2.2,4.37-6.85,5.94-11.64,4.25
            c-4.47-1.58-5.45-5.38-5.35-9.9C50.68,192.86,40.81,184.97,54.76,184.03z"
            />
            <path
              style="fill: #7d8d59"
              d="M101.58,238.31c6.26,1.85,6.11,7.54,7.39,12.38c-6.41-1.29-7.4-6.81-9.14-11.74
            C100.43,238.77,101.01,238.56,101.58,238.31z"
            />
            <path
              style="fill: #374c20"
              d="M219.9,194.07c-4.02-5.59-10.28-7.36-16.1-6.11c-9.17,1.95-9.66-2.35-8.58-9.09
            c3.09-6.01,0.41-11.6-1.3-17.26c0.08-4.24,3.26-5.81,6.38-7.42c2.32-1.52,9.45-1.81,1.48-5.94c2.11-6.18,7.67-6.51,12.8-7.58
            c7.28,3.98,6.57,10.42,5.24,17.06c-0.72,3.9,1.94,7.97-0.51,11.79c-1.18,2.2-4.36,1.95-5.39,4.37
            c-1.29,1.01,0.68,1.52,0.45,0.44c-0.3-1.42,1.81-2.23,3.38-2.63c2.21-0.71,4.41-1.5,6.78-0.79c3.68,1.61,7.62,1.88,11.53,2.53
            c5.13,0.85,9.12,3.37,6.24,9.78c-2.47,4.65-6.63,7.55-10.79,10.44c-3.23-0.07-5.72,1.99-8.58,2.95
            C220.88,197.28,219.1,197.09,219.9,194.07z"
            />
            <path
              style="fill: #69902b"
              d="M242.31,183.2c-1.58-5.41-5.15-7.1-10.03-4.58c-4.86,2.51-6.41-0.53-8.07-3.93
            c0.28-1.19,0.83-2.24,1.65-3.15c6.8-3.6,12.45-9.52,20.65-10.33c8.25,2.12,10.31,7.41,7.7,15.09c-0.74,1.73-1.47,3.47-2.21,5.2
            c-3.03-1.81-5.47-1.03-7.46,1.7c0,0-1.12,0.09-1.12,0.09L242.31,183.2z"
            />
            <path
              style="fill: #425627"
              d="M256.75,217.9c-6.82,2.21,4.13,12.35-5.2,12.96c-4.35,0.29-10.91-3.2-10.24-9.92
            c0.73-7.29,8.92-6.5,13.38-9.85C255.93,210.17,256.38,214.78,256.75,217.9z"
            />
            <path
              style="fill: #a5c420"
              d="M219.9,194.07c3.99,3.14,7.56-5.47,11.62-0.43c-2.5,1.99-6.59,3.08-4.32,7.73
            c-4.76,4.27-8.71,11.27-16.63,5C211.99,200.98,216.7,198.1,219.9,194.07z"
            />
            <path
              style="fill: #415732"
              d="M138.57,206.21c-8.3-8.12-20.27-5.58-29.76-10.27c-0.28-0.81-0.22-1.59,0.18-2.29
            c-0.07,0.03,0.22-0.91,0.22-0.91s-0.97,0.19-1.05,0.2c-5.14-0.89-3.24-6.86-6.62-8.98l0.09,0.07
            c-9.55-8.4-10.63-19.45-9.69-31.13c0.69-0.96,1.58-1.69,2.63-2.23c5.02-0.78,6.88-3.53,5.64-8.58
            c-1.06-4.33-0.51-8.17,4.42-9.97c3.28-0.84,5.94,0.53,8.49,2.35c4.46,4.74,6.99,10.97,11.87,15.42
            c1.42,2.63,2.55,5.36,2.55,8.42c2,8.13,0.65,16.99,5.37,24.52c3.49,6.19,5.19,13.39,10.52,18.54
            C142.96,204.13,141.33,205.73,138.57,206.21z"
            />
            <path
              style="fill: #334227"
              d="M143.43,201.36c-7.65-3.82-16.46-6.67-14.89-18.09c-0.14-4.26,0.22-9.43,5.06-9.8
            c9.78-0.76,16.87-7.09,25.37-10.41c7.54,2.69,5.31,8.7,4.79,14.13c-3.05,6.43-11.99,4.91-15.15,11.23
            C144.91,191.93,141.29,195.49,143.43,201.36z"
            />
            <path
              style="fill: #32421e"
              d="M180.37,178.95c-4.24,4.28-7.88,9.44-14.64,10.05c-1.65-2.91-4.2-4.5-7.45-5.04
            c-1.51-4.02,4.75-4.26,3.86-7.98c2.8-9.62,8.15-9.74,15.1-4.11C178.97,173.91,181.81,175.5,180.37,178.95z"
            />
            <path
              style="fill: #6f9294"
              d="M163.1,176.64c0.3,3.7-2.85,5.13-4.83,7.32c-3.22,1.48-6.45,2.97-9.67,4.45
            c1.36-6.71,8.53-7.43,12.15-11.8C161.55,175.97,162.33,175.94,163.1,176.64z"
            />
            <path
              style="fill: #202c19"
              d="M163.1,176.64l-1.17-0.09c0,0-1.17,0.06-1.17,0.06c-1.67-3.85,1.98-9.02-2.97-12.06
            c-2.16-0.22-2.82-1.48-2.44-3.49c4.61-3.45,6.9-8.6,9.74-13.35c1.99-2.08,4.36-3.62,5.68-4.29c-6.77,3.86-9.21-4.38-14.01-7.3
            c-0.6-1.02-0.98-2.12-1.21-3.28c1.9-15.39,5.66-18.22,18.96-12.21c7.75,3.5,9.72-3.11,13.9-5.63c-1.09-0.17-1.21,0.97-1.95,1.22
            c-11.34,4.24-13.6,2.67-16.69-11.44c-2.74-5.44-0.56-11.26-1.29-16.86c-1.85-6.56-7.15-11.66-7.87-18.71
            c0.63-3.5,2.7-5.48,6.21-5.97c3.37-0.11,5.72,1.87,8,3.95c1.99,1.95,3.86,4.04,5.88,5.93c1.32,1.34,2.75,2.24,4.64,0.93
            c1.49-1.36,2.92-2.74,4.8-3.55c1.05-0.41,2.13-0.59,3.25-0.61c6.09,0.8,9.66,5,12.97,9.53c3.45,1.86,6.11-1.13,9.18-1.48
            c2.87-1.4,5.71-2.39,8.44,0.29c2.46,3.45,2.41,7.08,0.82,10.81c-3.81,6.37-11.1,11.26-8.73,20.32
            c-0.19,4.32-4.44,7.85-2.42,12.59c0.31,4.89,1,9.68,4.06,13.77c1.1,2.2,1.49,4.42,0.15,6.67c-1.1,0.69-2.13,0.59-3.08-0.3
            c-2.91,4.36-8.16,4.62-12.2,7c-0.01,2.65-1.59,4.22-3.68,5.46c-4.86,1.23-8.47,3.12-4.76,8.95c1.33,3,0.95,5.61-1.74,7.69
            c-0.78,0.83-1.56,1.67-2.33,2.5c-4.77,0.91-9.46,3.97-14.41,0.62C171.2,173.65,166.13,169.57,163.1,176.64z"
            />
            <path
              style="fill: #61882d"
              d="M192.39,171.21c-1.49-2.32-0.91-4.57,0.24-6.8c0.26-1.6,1.21-2.47,2.79-2.7
            c9.32,3.93,9.29,6.62-0.2,17.16C194.28,176.31,193.33,173.76,192.39,171.21z"
            />
            <path
              style="fill: #a3bd4c"
              d="M177.74,205.98c-3.03,0.88-6.06,1.76-10.4,3.03c2.4-3.36,3.96-5.54,5.52-7.73
            c2.29-0.59,4.59-1.16,6.87-1.79c2.18-0.6,4.1-2.76,7.26-0.67C186.24,204.11,180.26,203.06,177.74,205.98z"
            />
            <path
              style="fill: #374c20"
              d="M175.64,174.33c4.85,0.92,9.32-7.3,14.42-0.62c-1.95,4.1-5.91,4.5-9.68,5.24
            C178.7,177.5,175.54,177.59,175.64,174.33z"
            />
            <path
              style="fill: #688831"
              d="M46.93-122.47c2.57,1.45,5.24,1.7,8.01,0.64c1.88,1.5,4.23,2.53,5.06,5.08
            c1.03,6.29,4.66,9.8,10.65,10.94c4.98-7.18-7.47-10.72-2.69-16.71c1.97-2.47,6.1-1.41,9.1,0.42c0.5,0.37,1,0.73,1.51,1.09
            c2.02,2.33,1.79,5.37,2.37,8.1c3.24,15.24-1.05,20.06-16.01,18.15c-2.78-0.35-5.71,0.99-8.36-0.74
            c-3.21-4.25-6.47-8.39-12.76-5.21c-5.63-1.84-9.64-4.61-6.55-11.44C43.03-113.2,44.47-118.32,46.93-122.47z"
            />
            <path
              style="fill: #33441e"
              d="M37.26-112.15c-0.02,5.6,6.81,5.52,8.22,9.92c2.86,1.72,4.24,4.47,5.22,7.5
            c0.38,1.9,0.06,3.72-0.59,5.5c-4.29,5.73-10.4,9.89-14,16.24c-2.3,1.21-4.6,2.33-6.76-0.24c5.3-12.5-3.28-24.2-2.1-36.47
            c0.29-0.82,0.59-1.64,0.88-2.46C31.17-112.15,34.22-112.15,37.26-112.15z"
            />
            <path
              style="fill: #9ab457"
              d="M60-116.75c-4.97,1.58-6.25-0.52-5.06-5.08c3.29-4.78-6.37-7.21-2.21-12.15
            C63.46-131.75,59.53-123.32,60-116.75z"
            />
            <path
              style="fill: #2c391b"
              d="M79.64-125.07c1.35,5.32,10.35-2.73,9.94,5.69c1.32,1.94,1.22,3.83-0.19,5.69
            c-9.17,4.7-10.32-2.47-12.33-8.36c0,0,0.01-0.06,0.01-0.06C77.92-123.1,78.78-124.08,79.64-125.07z"
            />
            <path
              style="fill: #6d8c30"
              d="M88.88-114.82c0.23-1.52,0.46-3.04,0.69-4.56c1.67-0.22,3.34-0.45,5.01-0.67
            c1.61-2.79,3.54-4.57,6.78-2.12c0.31,3.01,0.62,6.02,3.27,8.09c0.27,1.22,0.04,2.33-0.7,3.35c-3.53,2.81-7.61,3.58-12,3.22
            C88.43-108.91,86.94-111.15,88.88-114.82z"
            />
            <path
              style="fill: #3a4b21"
              d="M101.36-122.17c-2.26,0.71-4.52,1.41-6.78,2.12c-0.5-4.15-2.31-8.53,2.12-11.78
            C108.16-133.4,101.7-126.3,101.36-122.17z"
            />
            <path
              style="fill: #496828"
              d="M-39.19-65.56c-0.78-3.18-1.55-6.35-2.33-9.53c6.67,4.16,13.34,8.33,20.01,12.49
            c2.2,3.15,3.59,6.3,0.02,9.46c-3.23,3.73-6.95,5.05-11.55,2.44c-3.54-2.01-1.84-4.67-1.01-7.25
            C-36.87-59.72-37.72-62.85-39.19-65.56z"
            />
            <path
              style="fill: #8dac21"
              d="M7.95-72.94c2.01,0.09,4.03,0.18,6.04,0.28C17.04-60.4,8.25-57.4-0.39-54.26
            c-5.28,0.64-9.14-1.49-11.78-6.01c-2.21-5.73-1.15-10.83,2.97-15.34c2.33-0.55,4.67-1.1,7-1.65c0,0-0.02-0.07-0.02-0.07
            C0.48-74.28,4.52-74.33,7.95-72.94z"
            />
            <path
              style="fill: #7b9c2d"
              d="M-9.21-75.62c-0.03,5.2-0.07,10.39-0.1,15.59c-1.07,7.71-5.99,8.5-12.27,6.84
            c0,0,0.09,0.06,0.09,0.06c-0.28-3.15-1.97-6.3-0.02-9.46C-16.63-66.2-18.16-75.87-9.21-75.62z"
            />
            <path
              style="fill: #676c3a"
              d="M-41.44-74.96c-4.93-2.12-8.67-6.99-14.76-6.45c-0.26-5.7,5.59-5.77,8.16-8.87
            c2.41,2.06,4.84,3.12,7.38,0.19C-36.95-84.84-39.64-79.93-41.44-74.96z"
            />
            <path
              style="fill: #313e1e"
              d="M29.35-73.22c2.16-0.85,4.33-1.7,6.49-2.55c0.53-9.95,5.1-9.86,11.72-4.74
            c1.5,1.2,2.5,2.73,3.07,4.55c2.06,12.36,3.15,24.69-1.5,36.81c-1.82,1.94-4.05,2.97-6.7,3.09c-5.5-0.55-8.21-5.22-12.09-8.18
            C29-46.33,29-48.72,28.75-51.05c-0.67-4.28-0.74-8.87-5.56-11.06c-4.52-2.15-9.35-4.04-9.02-10.4
            c1.01-6.69,10.07-5.92,11.38-12.32C23.3-79.82,29.79-77.66,29.35-73.22z"
            />
            <path
              style="fill: #1f2515"
              d="M14.17-72.52c2.15,4.68,8.69,2.31,10.94,6.83c0.41,2.2,1.48,4.3,1,6.64
            c-1.74,5.67-6.62,10.08-6.58,16.48c-1.06,4.96,1.37,10.67-3.1,14.92c-1.04,0.66-2.17,1.03-3.4,1.09
            C3.23-28.89,2.14-38.39-2.28-45.17c0.02-4.47,1.41-8.68,2.51-12.95c6.19-3.33,11.99-7.03,13.75-14.55
            C13.99-72.67,14.17-72.52,14.17-72.52z"
            />
            <path
              style="fill: #30411d"
              d="M7.95-72.94c-7.59,8.25-7.73-0.74-10.18-4.4C1.87-77.51,6.27-78.38,7.95-72.94z"
            />
            <path
              style="fill: #6e9346"
              d="M-80.97-117.9c5.24-1.59,11.08-1.83,14.83-6.71c0.87-0.38,1.74-0.77,2.61-1.15
            c-0.13,2-0.25,4-0.38,6.01c-6.41,2.7-12.16,7.09-19.41,7.69c-0.12-0.93-0.24-1.85-0.37-2.78
            C-82.77-115.86-81.87-116.88-80.97-117.9z"
            />
            <path
              style="fill: #949a65"
              d="M-58.79-106.85c-0.8-1.8-1.61-3.6-2.41-5.4c9.83-1.01,7.54,5.69,7.26,12.4
            C-55.96-102.76-57.38-104.8-58.79-106.85z"
            />
            <path
              style="fill: #a1be2b"
              d="M276.29,159.27c-1.18-0.19-3.26-0.12-3.4-0.62c-4.28-14.46-15.53-18.11-28.72-18.85
            c-1.91-5.66,1.9-8.99,5.1-12.56c2.74-1.61,6.49-1.93,7.47-5.82c1.28-3.26-0.11-6.77,0.93-10.06c0.36-3.15,0-6.43,1.79-9.3
            c0.83-1.12,1.88-1.96,3.13-2.56c10.32-2.74,13.72,2.86,14.34,11.86c-4.13,11.23,4.99,17.95,9.03,26.4
            c1.04,1.31,1.93,2.72,2.78,4.16c0.7,1.55,1.4,3.1,2.09,4.65c-8.68-4.64-10.93,0.49-11.75,7.76
            C276.84,155.23,276.93,157.45,276.29,159.27z"
            />
            <path
              style="fill: #c5d332"
              d="M288.74,141.92c-1.31,0.06-2.62,0.11-3.93,0.17c-4.58-5.89-1.64-8.41,4.29-9.58
            c5.25,0.94,8.4-2.82,12.09-5.39C303.38,137.39,295.18,138.91,288.74,141.92z"
            />
            <path
              style="fill: #40512a"
              d="M215.77,137.17c-7.34,0.9-8.81-3.06-5.52-14.86c2.48-6.31,7.55-8.99,13.86-9.96
            c5.78,2.24,12.82-3.11,18.01,2.89c2.12,3.96,0.75,8.36,1.61,12.48c1.55,4.44-0.03,8.45-1.7,12.45c0.03,1.46,0.05,2.92,0.08,4.39
            c-0.95,3.34-3.14,4.51-6.42,3.7c-1.66-2.28-4-3.41-6.85-3.66C224.83,141.46,220.95,138.17,215.77,137.17z"
            />
            <path
              style="fill: #92b625"
              d="M234.87,146.68c2.42-0.71,4.83-1.42,7.25-2.12c6.46-0.39,12.45-0.12,12.84,8.61
            c0.03,0.75,0.39,1.91,0.93,2.16c18.36,8.53,7.49,14.68-1.67,20.98c-1.38-4.7-2.62-9.47-7.11-12.38
            c-0.7-0.34-1.27-0.83-1.74-1.46c-1.41-0.59-2.52,0.15-3.69,0.78c-3.02,1.79-6.02,2.31-9.08,0.13
            C230.39,157.4,225.12,151.01,234.87,146.68z"
            />
            <path
              style="fill: #5e832b"
              d="M242.04,140.17c-0.82-3.85-1.64-7.71-2.45-11.56c5.7-2.37,1.59-11.58,8.92-12.83
            c3.1-0.05,5.09,1.52,6.27,4.29c1.16,3.86-0.24,6.96-2.83,9.71c-2.59,3.34-5.18,6.68-7.78,10.02
            C243.46,139.9,242.75,140.03,242.04,140.17z"
            />
            <path
              style="fill: #69902c"
              d="M289.1,132.51c-3.62,2.21-5.88,5.03-4.29,9.58c-9.45-3.79-9.27-15.26-17.01-21.15
            c-4-3.05,1.63-7.29,6.55-8.03c3.21-1.62,5.49-6.1,10.13-3.31c2.46,6.75,7.42,8.85,14.17,7.61
            C298.06,123.93,282.87,121.51,289.1,132.51z"
            />
            <path
              style="fill: #7aa62c"
              d="M284.49,109.59c-3.29,1.37-5.55,5.91-10.14,3.31c-1.27-5.49-2.51-11.02-10.07-10.43
            c-4.38-8.99,3.43-10.06,8.41-12.99c1.95-0.67,3.93-0.57,5.9-0.15c1.27,0.26,2.43,0.81,3.46,1.61c4.3,4.14-0.11,9.57,2.04,14.04
            C284.23,106.52,284.36,108.06,284.49,109.59z"
            />
            <path
              style="fill: #9cbf25"
              d="M306.8,85.12c3.11,3.1,0.61,5.41-1.11,7.83c-0.73-0.07-1.46-0.19-2.18-0.34
            c-8.04-3.09-9.32-11.19-12.99-17.54c1.51-5.33-1.11-12.03,5.14-15.82c6.3-0.05,9.41,5.06,13.6,8.44
            C310.76,73.83,305,78.94,306.8,85.12z"
            />
            <path
              style="fill: #658e2f"
              d="M294.02,75.34c5.68,4.37,5.65,11.88,9.49,17.26c-5.99,0.75-9.97-1.85-12.22-7.34
            c-1.56-1.25-2.09-2.93-1.99-4.87C289.91,77.81,291.33,75.99,294.02,75.34z"
            />
            <path
              style="fill: #b0c260"
              d="M284.1,104.99c-6.81-3.02-2.09-8.12-2.21-12.34c-0.16-4.31,2.02-3.76,4.67-2.26
            C293.71,96.59,286.77,100.43,284.1,104.99z"
            />
            <path
              style="fill: #93b72f"
              d="M286.55,90.38c-1.56,0.75-3.11,1.51-4.67,2.26c-0.52,0-1.05,0.04-1.57,0.12
            c-6.49-8.79-5.59-11.6,5.32-16.69c2.8,0.37,4.81,1.75,5.62,4.58c0.01,1.54,0.03,3.08,0.04,4.62
            C289.71,86.97,288.13,88.67,286.55,90.38z"
            />
            <path
              style="fill: #cad654"
              d="M306.8,85.12c-6.94-6.5,0.48-11.44,1.85-17.04c-1.89-2.49-2.17-4.97,0.09-7.41
            c2.08,1.99,2.8,4.47,2.58,7.28c-0.79,4.82,2.38,9.75-0.36,14.52C309.58,83.35,308.19,84.23,306.8,85.12z"
            />
            <path
              style="fill: #c0c9a2"
              d="M319.73-109.96c-0.42,3.44-0.84,6.88-1.26,10.32c-1.58,2.85-3.16,5.7-4.74,8.55
            c-2.45,4.16-4.73,1.07-7.06-0.16c-1.93-1.45-3.81-2.95-4.74-5.31c-0.52-1.82-0.44-3.61,0.19-5.38
            c1.02-5.49,2.16-10.93,8.34-13.15c1.84-0.37,3.63-0.25,5.34,0.59c1.01,0.67,1.97,1.41,2.89,2.21
            C319.03-111.53,319.38-110.74,319.73-109.96z"
            />
            <path
              style="fill: #869b51"
              d="M318.68-112.31c-0.84,0.04-1.68,0.08-2.52,0.12c-3.8-3.01-5.57-7.59-8.69-11.14
            c-0.77-0.79-1.45-1.63-2.07-2.54c-1.2-1.97-2.01-4.03-1.51-6.4c0.6-2.48,2.48-3.67,4.64-4.57c0.69,0.05,1.35,0.21,1.99,0.46
            C322.18-131.4,319.01-121.37,318.68-112.31z"
            />
            <path
              style="fill: #45593a"
              d="M316.39-77.77c0.79,0.8,1.57,1.6,2.36,2.4c-0.06,2.62-0.11,5.25-0.17,7.87
            c0.89,3.32,2.54,6.52,1.57,10.14c-0.85,1.67-2.16,2.81-3.93,3.42c-3.35,0.77-5.5-1.46-7.86-3.16
            c-14.04-7.76-14.2-9.89-1.5-19.74C309.86-78.96,312.97-79.92,316.39-77.77z"
            />
            <path
              style="fill: #7b956f"
              d="M315.95-56.17c0.91-0.7,1.82-1.41,2.72-2.11c2.83-1.29,5.35,0.87,8.08,0.75
            c0,0,0.94-0.04,0.94-0.04c-0.24,4.57,3.78,6.66,5.77,9.93c-2.95,3.58-4.67,8.79-10.81,8.16c-2.98,0.11-5.74-0.43-7.94-2.66
            c-2.62-3.22-2.33-6.82-1.34-10.5C314.03-53.95,314.89-55.14,315.95-56.17z"
            />
            <path
              style="fill: #273820"
              d="M326.75-57.53c-1.77,2.31-3.54,4.62-5.31,6.93c-0.92-2.56-1.84-5.12-2.76-7.68
            c-2.19-3.05-2.75-6.12-0.1-9.22C322.88-65.48,324.17-60.97,326.75-57.53z"
            />
            <path
              style="fill: #61785d"
              d="M316.39-77.77c-2.66,1.39-5.32,2.77-7.98,4.16c0.1,3.02-2.18,1.86-3.66,2.1
            c-6.62-3.7-5.48-9.54-4.28-15.42c2.11-3.42,4.33-6.75,8.34-8.27c2.9-0.15,2.95,3.14,4.93,4.1
            C320.09-87.75,317.89-82.69,316.39-77.77z"
            />
            <path
              style="fill: #51662a"
              d="M323.32-42.24c3.38-1.8,6.76-3.6,10.15-5.39c-0.05,1.39-0.09,2.77-0.14,4.15
            c-0.93,3.54-1.87,7.09-2.8,10.64c-1.93,1.78-4.12,2.3-6.61,1.37c-1.06-0.61-1.89-1.45-2.51-2.51
            C320.02-37.2,321.48-39.76,323.32-42.24z"
            />
            <path
              style="fill: #2e3e1d"
              d="M303.33-73.27c1.69-0.11,3.38-0.22,5.07-0.34c0.72,2.04-0.31,2.84-2.17,3.14
            c-2.26,0.35-5.45-0.05-5.88,2.79c-0.56,3.7,3.24,3.73,5.56,4.86c1.74,0.86,3.52,1.66,5.28,2.48c-1.74,4.66,3.34,5.53,4.7,8.46
            c0.05,2.97,0.09,5.95,0.14,8.92c0.12,0.51,0.24,1.02,0.37,1.53c0.54,4.52-0.77,9.43,2.88,13.29c0.11,4.49-1.76,8.46-3.26,12.52
            c-0.74,0.7-1.48,1.41-2.22,2.11c-7.23,2.43-14.7,0.92-22.04,1.33c-7.24-2-8.88,6.93-14.74,7.63c-5.33,2.02-7.4-8.52-13.52-3.47
            c-2,0.88-3.9,1.94-5.82,2.95c-6.13,3.6-13.36,2.18-19.79,4.43c-2.38,0.22-4.42-0.94-6.56-1.64c-4.57,0.11-8.86-4.89-13.67-0.68
            c-7.27,2.55-14.94,6.43-17.76-5.43c-1.6-2.94-3.06-5.98-5.54-8.36c-5.48-6.57-0.45-9.08,4.44-11.65
            c5.42-2.25,0.26-8.28,3.94-11.17c2.06-1.71,4.41-2.22,7.01-1.76c2.53,0.51,4.73,1.73,6.78,3.23c2,2.22,3.85,4.58,5.89,6.76
            c2.41,4.57,5.47,6.16,10.9,4.17c6.12-2.24,11.61-5.75,17.73-7.85c2.59-0.62,4.68-1.93,5.91-4.41
            c2.72-5.82,0.43-15.91,9.26-16.39c9.12-0.49,8.58,8.83,10.19,15.29c0.94,3.78,1.41,9.12,7.15,3.65
            c2.2-6.78-3.83-8.37-7.27-11.55c-2.21-7.61-12.25-8.84-13.89-16.95c1.01-4.21,1.92-8.31,1.43-12.83
            c-0.62-5.79,2.22-9.09,8.6-7.83c3.84,1.38,7.65,2.8,10.76,5.57c1.98,2.06,3.26,5.51,7.24,3.68c2.86-1.43,5.62-3.36,9.05-1.71
            C301.27-77.15,301.7-74.77,303.33-73.27z"
            />
            <path
              style="fill: #688c39"
              d="M316-15.63c-2.27-3.98,0.59-8.14-0.45-12.17c0.99-4.03,1.69-8.49,7.67-5.31
            c2.44,0.08,4.87,0.17,7.31,0.25C332.19-21.61,318.82-23.08,316-15.63z"
            />
            <path
              style="fill: #455c27"
              d="M291.44-15.87c7.55-0.16,15.31-2.42,22.34,2.35c-0.05,0.69-0.08,1.38-0.09,2.08
            c-0.09,5.55-2.74,7.93-8.3,6.7c-2.82-0.79-5.69,0.3-8.37,0.07C289.65-5.27,286.85-8.91,291.44-15.87z"
            />
            <path
              style="fill: #93ba2f"
              d="M323.94,8.62c-1.85,1.61-3.7,3.21-5.56,4.82c-7.21,8-9.34,2.2-11.29-4.15
            c1.9-3.73,2.84-8.61,8.83-7.59C317.88,4.83,323.39,3.85,323.94,8.62z"
            />
            <path
              style="fill: #455c27"
              d="M315.92,1.69c-2.45,2.31-2.16,7.33-7.09,7.19c-2.66,1.46-4.4,0.02-5.99-1.91
            c-1.86-4.75,1.8-7.48,3.92-10.78c3.03-1.3,6.29-1.63,9.49-2.22C321.32-3.23,317.78-0.8,315.92,1.69z"
            />
            <path
              style="fill: #87af2b"
              d="M316.25-6.02c-1.55,3.97-4.31,4.16-7.68,2.48c-1.48,1.48-3.11,1.46-4.84,0.5
            c-1.33-3.07,0.59-4.31,2.97-5.28c3.66,1.97,4.67-2.05,6.99-3.11C315.82-10.23,315.84-8.04,316.25-6.02z"
            />
            <path
              style="fill: #9da56e"
              d="M-88.47,43.08c1.03,0.16,2.07,0.33,3.1,0.49c1.41,8.25-1.49,11.86-10.07,9.39
            c0.65-2.43,1.31-4.87,1.96-7.3C-91.8,44.8-90.13,43.94-88.47,43.08z"
            />
            <path
              style="fill: #3e5321"
              d="M-95.43,52.96c5.88-0.42,8.76-4.06,10.06-9.39c4.25,2.49,12.85,4.13,1.88,9.6
            c-1.68,1.72-3.36,3.43-5.04,5.15c-3.22-0.91-6.43-1.82-9.65-2.73C-97.26,54.71-96.34,53.84-95.43,52.96z"
            />
            <path
              style="fill: #3b4f20"
              d="M103.8-112.46c0.22-0.57,0.5-1.11,0.83-1.62c1.62-0.12,3.24-0.24,4.86-0.36
            c2.89,2.92,1.2,9.16,7.13,9.87c0.86,0.8,1.41,1.77,1.64,2.91c0.04,1.13-0.17,2.22-0.62,3.25c-2.22,2.85-5.77,4.85-5.8,9.12
            c0.06,3.67-0.64,6.91-4.61,8.33c-1.82,0.34-3.52-0.22-5.24-0.71c-2.63-1.45-4.42-3.7-5.95-6.2c-0.63-4.93-6.26-5.65-8.1-9.59
            c-0.65-2.41,0.02-4.52,1.43-6.47C92.53-109.56,102.16-104.26,103.8-112.46z"
            />
            <path
              style="fill: #7eac27"
              d="M134.09-109.86c3.31-3.02,6.51-1.53,9.72,0.22c0.88,7.67,2.48,15.94-10.22,12.25
            c-0.74-1.27-0.65-2.48,0.27-3.63C137.84-103.87,135.86-106.87,134.09-109.86z"
            />
            <path
              style="fill: #729537"
              d="M133.85-101.03l-0.13-0.05c-6.27,12.48-11.78,4.24-17.41-0.64c0.1-0.95,0.21-1.9,0.31-2.85
            C124.1-111.86,127.65-99.95,133.85-101.03z"
            />
            <path
              style="fill: #2d3b1c"
              d="M116.31-101.71c5.9-2.44,11.31,8.56,17.41,0.64c5.33,1.07,5.33,1.07,10.08-8.57
            c0.51,0.07,1.01,0.06,1.52-0.02c6.8,5.47,4.22,10.63-0.51,15.71c-2.49,2.9-4.41,6.44-8.41,7.69c-3.61,1.04-6.27,4-9.95,4.93
            c-8.24,3.61-13.87,1.43-16.5-7.42c-0.1-4.92,2.31-8.38,6.35-10.88C116.39-100.33,116.39-101.02,116.31-101.71z"
            />
            <path
              style="fill: #819938"
              d="M140.74-95.01c4.18-4.05,6-8.85,4.58-14.65c0.66-0.08,1.33-0.09,2-0.02
            c5.74-3.01,8.74,0.39,11.3,4.84c1.5,9.08,0.84,16.65-11.31,16.16C144-89.63,141.61-91.53,140.74-95.01z"
            />
            <path
              style="fill: #b9c973"
              d="M155.97-104.59c-2.65-2.1-4.55-5.46-8.65-5.1c-0.27-1.55-0.53-3.11-0.79-4.66
            c0.62-0.01,1.18-0.19,1.69-0.55c2.78-1.25,3.82,1.16,5.35,2.55c2.66,0.1,5.28,0.07,7.36-1.99c4.31-1.35,5.69,1.32,6.52,4.76
            c-0.57,4.77-3.58,6.62-8.04,6.73C158.12-103.16,156.98-103.74,155.97-104.59z"
            />
            <path
              style="fill: #879656"
              d="M153.56-112.35c-1.78-0.85-3.56-1.7-5.35-2.55c1.31-7.02,5.92-9.71,12.63-9.68
            C159.21-120.03,151.84-118.9,153.56-112.35z"
            />
            <path
              style="fill: #425622"
              d="M195.25-121.94c2.46,7.33-5.62,4.23-7.33,7.43c-1.32,1.7-3.1,2.45-5.21,2.5
            c-3.83-1.71-9.96-0.5-9.6-7.54c0.02-0.95,0.04-1.89,0.06-2.84C180.52-122.24,187.88-122.09,195.25-121.94z"
            />
            <path
              style="fill: #81a23f"
              d="M173.1-119.54c2.3,3.69,7.08,2.21,9.92,4.77c0.3,3.84,2.63,7.83-0.82,11.41
            c-2.31,2.41-5.06,3.27-8.3,2.33c-4.15-1.55-7.06-4.29-8.22-8.69c-0.95-2.2-2.42-3.87-4.77-4.62
            C162.58-121.7,168.84-118.28,173.1-119.54z"
            />
            <path
              style="fill: #30441e"
              d="M313.49,57.75l-1.13,0.07l-1.07-0.38c-4.69-1.85-8.38,5.95-13.35,1.39
            c-2.26-1.22-4.3-0.4-6.33,0.67c-2.85,2.4-5.7,4.79-9.86,3.73c-7.21-7.43-6.46-13.2,2.5-16.99c4.78-2.02,10.08-2.63,14.89-4.67
            c5.26-2.26,8.51,1.89,12.43,3.92C318.45,48.6,313.75,53.52,313.49,57.75z"
            />
            <path
              style="fill: #405324"
              d="M311.57,45.49c-3.84,2.53-7.01,1.79-9.58-1.95c-4.66-0.51-7.59-2.91-7.4-7.79
            c0.14-3.6,2.65-5.79,5.94-6.86c3.89-1.27,6.96,0.14,9.72,3.04c1.7,1.78,1.52,4.66,3.85,5.94c0,0,0.19,0.1,0.19,0.1
            C313.39,40.48,312.48,42.98,311.57,45.49z"
            />
            <path
              style="fill: #5b862e"
              d="M314.11,37.87c-1.38-0.62-3.23-0.88-4.05-1.93c-2.9-3.76-6.35-5.43-10.25-2.26
            c-5.29,4.29,0.64,6.72,2.18,9.86c-4.44,4.62-9.95,6.53-16.21,7.78c-7.12,1.43-9.63,5.04-1.58,9.66
            c4.31,2.11,6.66,5.39,6.17,10.37c-1.11,2.67-3.18,3.66-5.95,3.39c-1.17-0.33-2.21-0.91-3.11-1.72
            c-3.61-5.34-9.1-7.81-14.94-9.78c-6.03-2.03-11.2-4.93-11.65-12.31c0.26-2.54,1.46-4.55,3.41-6.16
            c6.93-1.7,14.49-2.26,14.85-12.4c0.12-3.26,4.11-4.63,7.02-6.14c3.69-1.92,6.75-4.36,7-9.11c0.41-7.66,5.12-11.48,12.15-13.07
            c1.88,0.08,3.46,0.78,4.66,2.25c1.67,0.86,3.35,1.72,5.02,2.58c2.34,3.3,3.99,8.04,9.55,4.56c-0.8,2.6-1.6,5.21-2.4,7.81
            C309.24,26.1,315.34,32.4,314.11,37.87z"
            />
            <path
              style="fill: #557b2f"
              d="M298.64,56.11c3.48,2.71,4.78-0.92,6.95-2.29c4.23-2.67,5.86-0.85,5.69,3.62
            c-0.85,1.08-1.69,2.16-2.54,3.23c-0.03,2.47-0.06,4.94-0.09,7.41c-9.76,6.77-9.64-2.71-12.35-7.53
            C294.74,57.83,294.38,55.75,298.64,56.11z"
            />
            <path
              style="fill: #7b9741"
              d="M232.26-124.77c0.21,7.52-6.41,12.71-6.85,20.01c-7.1,4.04-8.88-1.82-11.5-6.16
            c-0.98-3.49-0.37-6.38,3.13-8.16c0,0,0.55-0.81,0.55-0.81c6.16,1.2,7.21-6.95,12.71-6.96
            C232.02-127.17,232.91-126.7,232.26-124.77z"
            />
            <path
              style="fill: #a2c153"
              d="M232.26-124.77c-0.66-0.7-1.31-1.39-1.97-2.09c-0.21-4.29-0.71-8.65,2.46-12.26
            c0.57,0.26,1.15,0.5,1.74,0.73c0.5,4.55,7.9,9,0.19,13.66c0,0-1.22,0.14-1.22,0.14L232.26-124.77z"
            />
            <path
              style="fill: #c0c3a3"
              d="M217.03-119.09c0.05,1.49-1.8,3.1,0.29,4.46c1.5,5.95,3.37,11.86,2.21,18.14
            c-1.38,2.32-3.41,3.81-5.95,4.65c-4.59-1-10.24,2.3-13.72-3.33c0.88-2.17,3.11-4.95,2.45-6.42
            C194.94-118.01,207.13-117.52,217.03-119.09z"
            />
            <path
              style="fill: #8eb92c"
              d="M180.4-104.64c-2.32-2.07,1.86-5.43-2.35-8.27c-2.11-1.42,3.45-0.48,4.98-1.86
            c1.63,0.09,3.26,0.18,4.89,0.27c1.98,5.34-0.72,13.12,7.84,15.02c-4.72,8.66-10.28,6.7-16.11,1.27
            C178.55-100.52,179.16-102.62,180.4-104.64z"
            />
            <path
              style="fill: #c8d0de"
              d="M199.88-95.16c4.33-0.38,8.74-2.12,12.91,0.65c1.78,2.95,0.97,5.73-0.51,8.49
            c-0.79,0.95-1.73,1.71-2.82,2.28c-3.29,1.6-6.37,4.17-10.42,2.26c-4.29-3.47-7.33-7.25-2.94-12.74
            c0.58-0.23,1.16-0.46,1.75-0.69C198.53-94.88,199.21-94.97,199.88-95.16z"
            />
            <path
              style="fill: #546831"
              d="M180.64-100.03c4.93,2.98,9.92,4.39,15.1,0.55c0.7,1.53,1.39,3.06,2.09,4.58
            c0,0,0.17,0.46,0.17,0.46c-4.41,1.04-5.32,7.45-10.71,7.02c-6.53-1.42-13.32-2.48-14.99-10.71
            C174.27-102.34,177.39-101.5,180.64-100.03z"
            />
            <path
              style="fill: #37461e"
              d="M271.07-82.38c-5.71-1.19-3.57,2.65-2.89,4.91c1.54,5.1,1.03,9.31-3.87,12.27
            c-4.12-0.33-6.17-2.91-7.18-6.62c0.18-4.28-0.86-7.41-6.1-6.55c-2.68,0.85-3.17,3.75-4.98,5.44c-1.85,1.66-3.97,2.48-6.47,2.22
            c-2.55-0.54-4.17-2.15-5.14-4.48c-0.86-3.47,0.74-8.29-5.02-9.14c-5.89-4.7-3.28-11.21-3.68-17.08c0.35-1.26,1.05-2.25,2.12-3
            c4.79-0.93,9.58-1.86,14.37-2.8c5.8-1.9,6.34,4.91,10.26,6.14c2.98,2.12,3.27,5.54,4.19,8.66c4.04,6.47,10.39,0.7,15.24,2.88
            c1.72,0.25,2.84,1.18,3.23,2.91C275.06-83.98,273.01-83.24,271.07-82.38z"
            />
            <path
              style="fill: #77874f"
              d="M310.81-112.36c-5.77,2.71-0.53,9.28-4.42,12.65c-3.78-1.54-9.86,0.24-9.82-6.81
            c-1.71-1.86-3.41-3.72-5.12-5.58c-0.62-4.42-1.55-9,4.9-9.93c4.12-0.93,5.82-7.37,11.38-5l1.19,0.26
            C310.88-122.14,314.75-117.76,310.81-112.36z"
            />
            <path
              style="fill: #3f4f20"
              d="M256.66-92.42c-2.45-1.84-4.9-3.68-7.34-5.51c-1.56-4.8-5.73-9.58-0.06-14.41
            c2.62-1.26,5.12-1.33,7.42,0.73c0.06,4.84,2.55,7.19,7.34,7.18C264.97-98.33,258.45-96.83,256.66-92.42z"
            />
            <path
              style="fill: #37471f"
              d="M296.58-106.52c2.68,3.12,7.91,2.57,9.82,6.81c-0.77,0.71-1.53,1.42-2.3,2.13
            c2.64,5.23,1.04,8.86-4.19,11.12c-4.35,1.31-5.87-1.95-7.66-4.77C288.78-97.71,293.12-101.99,296.58-106.52z"
            />
            <path
              style="fill: #9eb09c"
              d="M292.24-91.22c2.04,1.04,4.08,2.08,6.12,3.12c2.85-0.74,5.52-1.17,5.51,3.13
            c-0.6,3.88,1.69,7.89-0.54,11.7c-3.6,0.89-4.42-1.47-4.94-4.2c-3.46-3.24-15.74-1.69-6.43-11.86
            C292.42-89.91,292.51-90.54,292.24-91.22z"
            />
            <path
              style="fill: #7b9240"
              d="M291.96-89.34c-3.52,7.03,7.32,6.26,6.43,11.86c-2.14,1.8-4.27,3.61-6.4,5.41
            c-4.91-0.92-7.65-4.89-11.11-7.86c-1.83-5.63,4.47-7.74,5.29-12.23C288.1-91.21,290.03-90.27,291.96-89.34z"
            />
            <path
              style="fill: #61792b"
              d="M286.17-92.15c0.93,4.86-1.13,8.79-3.87,12.51c0.23,0.99-0.12,1.72-0.98,2.23
            c-3.41-1.66-6.83-3.32-10.25-4.98c0.77-1.82,1.55-3.63,2.33-5.45c2.91-3.76,7.69,1.09,10.68-2.32
            C284.78-90.82,285.48-91.48,286.17-92.15z"
            />
            <path
              style="fill: #39471e"
              d="M284.09-90.15c-2.93,3.64-6.94,2.34-10.67,2.33c-0.61-0.49-1.11-1.05-1.51-1.71
            C275.36-101.64,280.11-88.27,284.09-90.15z"
            />
            <path
              style="fill: #546631"
              d="M307.74-127.03c-3.44,2.47-4.74,9.84-11.38,5c-0.14-1.55-0.29-3.1-0.43-4.65
            c0.94-1.85,1.89-3.69,2.83-5.53c2.52-1.53,4.96-1.75,7.24,0.47C306.58-130.17,307.16-128.6,307.74-127.03z"
            />
            <path
              style="fill: #92a36f"
              d="M249.27-112.34c2.63,4.79,0.06,9.61,0.06,14.41c-4.85-1.2-3.88-6.85-7.1-9.28
            c0.04-0.84,0.09-1.68,0.13-2.52C244.79-110.24,243.93-119.56,249.27-112.34z"
            />
            <path
              style="fill: #738a58"
              d="M305.99-131.74c-2.52,1.53-4.92,1.12-7.24-0.46c0.85-3.2,1.71-6.4,2.56-9.6
            c3.3,0.36,5.14,2.83,7.21,4.97C307.68-135.14,306.84-133.44,305.99-131.74z"
            />
            <path
              style="fill: #475b2d"
              d="M227.85-104.42c-0.11,0.68-0.17,1.36-0.19,2.05c-1.26,6-3.88,9.23-10.17,5.04
            c-0.05-5.77-0.11-11.53-0.16-17.3c3.69,2.47,1.43,9.82,8.08,9.86C226.23-104.67,227.04-104.56,227.85-104.42z"
            />
            <path
              style="fill: #516627"
              d="M-26.62,13.76c-4.27-9.36-0.25-17.76,4-25.52c1.89-3.45,1-6.09,1.08-9.14
            c1.49-2.52,3.49-4.44,6.36-5.32c2.32-0.59,4.65-0.61,6.99-0.09c3.84,0.13,7.24,1.27,9.84,4.27c6.83,4.24,8.08,11.25,9.11,18.37
            c1.13,7.68,2.03,15.32-3.33,22.01c-6.54,3.61-9.7-1.19-12.4-5.54c-5-8.06-9.55-11.98-15.4-0.71
            C-21.38,14.02-23.97,15.31-26.62,13.76z"
            />
            <path
              style="fill: #5d772a"
              d="M-26.62,13.76c3.1-2.91,4.55-6.59,4.97-10.73c0.4-4.04,2.27-7.02,6.44-7.63
            c4.06-0.6,6.83,1.69,8.91,5c3.85,6.14,7.73,12.25,11.6,18.37c1.76-0.34,3.52-0.63,5.28,0.01c7,8.37-1.37,8-5.65,9.96
            c-11.26,3.82-11.79-6.86-17.05-12.94c-1.89,3.6-1.16,8.26-4.75,10.47C-26.19,26.83-27.1,20.83-26.62,13.76z"
            />
            <path
              style="fill: #496828"
              d="M0.23-58.12c6.69,4.31-0.38,8.44-0.16,12.66c-0.31,3.4,0.57,7.13-2.64,9.74
            c-4.67,3.48-9.52,6.52-15.71,6.11c-5.55-3.1-1.85-10.31-6.18-13.96c5.57-1.82,6.57-5.01,2.9-9.62
            c1.95-6.12,8.13-4.65,12.27-6.84C-6.37-58.21-3.18-57.62,0.23-58.12z"
            />
            <path
              style="fill: #5c7f2c"
              d="M-24.47-43.57c6.89,1.65,4,9.06,7.47,12.73c3.62,1.43,5.27,3.6,2.76,7.35
            c-1.97,2.17-4.53,2.68-7.3,2.59C-23.66-28.31-25.28-35.78-24.47-43.57z"
            />
            <path
              style="fill: #6f972d"
              d="M-39.19-65.56c3.47,1.35,4.45,4.39,5.15,7.61c-2.49,0.95-4.98,1.9-7.47,2.84
            C-44.8-59.49-42.87-62.72-39.19-65.56z"
            />
            <path
              style="fill: #2a331b"
              d="M84.15,149.39c-3.39,3.43-5.89,10.04-12.04,3.14c-4.19-4.7-7.02-0.51-10.09,1.76
            c-0.39-3-1.78-5.45-4.41-6.93c-11.78-6.61-16.86-16.58-15.23-29.99c0.63-2.64,1.25-5.29,2.2-9.27
            c3.81,4.67,6.05,10.23,12.43,9.15c7.82,6.09,17.28,8.92,26.05,13.15C86.94,136.54,96.43,142.34,84.15,149.39z"
            />
            <path
              style="fill: #364620"
              d="M79.15,132.38c-9.26-2.29-18.48-4.62-22.14-15.12c4.22-5.66,0.91-10.17-2.24-14.7
            c-1.21-5.38,2.21-6.57,6.37-7.11c5.15,2.12,7.8,0.21,10.28-4.78c5.13-10.33,23.44-13.61,32.06-6.27
            c2.65,2.26,2.69,5.05,0.76,7.64c-1.89,2.54-5.35,4.03-4.92,7.97c3.56,10.38,11.62,19.78,6.85,31.92c-0.59,1-1.29,1.92-2.1,2.75
            c0.79,4.25,2.14,8.49,2.21,12.75c0.07,4.14,2.41,10.22-2.41,12.01c-5.04,1.88-6.27-4.98-9.49-7.64
            c-7.12-6.31-4.96-16.99-11.21-23.87c-1.46-1.61-2.35-4.11-3.86-5.71C81.28,125.5,83.19,128.94,79.15,132.38z"
            />
            <path
              style="fill: #29341c"
              d="M5.57,35.74c-0.03-1.48-0.07-2.97-0.1-4.45c0.97-1.02,2.06-1.88,3.26-2.6
            c7.5,0.42,15.55-1.13,21.19,5.93c1.65,3.76,1.57,7.63,0.54,11.52c-1.99,5.56-6.78,11.32,1.06,16.25
            c3.12,3.78,8.65,2.51,12.05,5.7c1.87,1.39,3.35,4.36,6.13,2.95c5.89-2.98,10.63-1.93,13.03,4.11
            c2.52,6.33,2.27,13.37-2.85,18.15c-3.05,2.85-5.41,5.85-7.55,9.25c-7.37-15.99-7.87-16.16-25.76-10.84
            c-2.29,0.68-4.76,0.74-7.15,1.08c-4-3.38-8.68-3.23-13.92-2.06c10.25-10.2,9.18-22.84,9.87-35c-5.22-2.97-7.83-7.94-10.1-13.18
            C5.28,40.28,5.5,38.01,5.57,35.74z"
            />
            <path
              style="fill: #465a23"
              d="M52.33,102.55c-0.43-4.29,0.6-7.83,3.89-11.05c4.73-4.63,5.03-11.31,1.5-15.76
            c-3.84-4.85-7.39,0.55-10.3,3.43c-1.42,1.4-2.69,1.57-4.17,0.37c-3.23-2.63-2.47-5.7-0.89-8.83c-1.69-5.04,1.85-6.54,5.44-7.31
            c7.4-1.6,8.27-5.29,5.94-12.14c-1.75-5.14-3.14-10.88-1.83-16.64c2.33-6.78,4.42-13.82,13.93-12.67c1.11,0.49,2,1.27,2.63,2.33
            c0.82,3.64,0.05,7.72,2.86,10.86c1.6,3.98-1.52,8.32,0.98,12.24c2.44,4.93,6.59,9.21,5.75,15.36c-0.03,1.18-0.16,2.34-0.36,3.49
            c-1.65,5.12-4.67,9.85-4.43,15.52c-1.86,6.42-1.09,14.62-10.67,15.82c-3.49,0.29-6.14,1.9-7.85,4.99c0,0-1.22,0.06-1.22,0.06
            L52.33,102.55z"
            />
            <path
              style="fill: #a1aa60"
              d="M5.57,35.74c2.09,1.17,2.37,3.03,1.97,5.15c1.87,5.7,6.01,10.99,3.08,17.53
            C9,60.61,7.37,62.8,5.73,64.99C0.47,55.27-7.56,45.57,5.57,35.74z"
            />
            <path
              style="fill: #90a146"
              d="M10.63,58.42c-0.16-6-6.68-10.87-3.08-17.53c3.96,4.23,6.83,9.04,7.83,14.84
            C13.79,56.63,12.21,57.53,10.63,58.42z"
            />
            <path
              style="fill: #758e30"
              d="M8.16,31.17c-0.9,0.04-1.79,0.08-2.69,0.12c-0.18-0.85-0.36-1.7-0.54-2.55
            c-0.73-3.23,5.49-3.65,3.06-7.58c-2.15-3.31-1.97-6.23,1.44-8.61c7.48-0.35,9.87,5.78,13.39,10.49
            c0.86,6.15-0.91,9.57-7.93,7.82C12.68,30.32,10.3,28.85,8.16,31.17z"
            />
            <path
              style="fill: #475e2b"
              d="M79.15,132.38c-0.57-6.05-4.68-9.34-9.46-12.04c-2.6-1.48-5.38-2.88-3.6-6.53
            c1.62-3.32,4.68-3.03,7.49-2.01c12.97,4.69,14.99,17.15,19.15,27.88c1.44,3.7,1.15,8.07,1.65,12.13c0,0-0.43,0.32-0.43,0.32
            c-4.08,2.03,0.35,9.98-6.84,9.84c-0.99-4.19-1.98-8.38-2.97-12.57C89.74,141.58,82.7,137.49,79.15,132.38z"
            />
            <path
              style="fill: #587252"
              d="M87.12,161.96c4.26-1.9-1.54-10.81,6.84-9.84c2.63,10.62,1.42,22.16,7.68,31.9
            c-3.4,0.12-6.8,0.25-10.19,0.37c-3.18,2.07-6.17,1.81-8.99-0.72C93.94,178.57,92.73,170.74,87.12,161.96z"
            />
            <path
              style="fill: #2d361c"
              d="M82.46,183.67c3,0.24,5.99,0.48,8.99,0.72c1.89,4.79,1.61,8.58-4.46,9.7
            c0.37-5.53-4.04-7.46-7.57-10.05C80.43,183.91,81.44,183.79,82.46,183.67z"
            />
            <path
              style="fill: #6a8a6d"
              d="M101.54,183.94c5.46-0.08,4.67,7.38,9.58,7.96c0.84,0.45,1.11,0.87,0.8,1.25
            c-0.29,0.37-0.59,0.56-0.9,0.56c-0.74,0.74-1.47,1.48-2.21,2.22C100.95,195.25,101.26,189.59,101.54,183.94z"
            />
            <path
              style="fill: #4e6626"
              d="M234.87,146.68c-1.76,2.38-3.23,5.4-0.6,7.46c7.02,5.49-1.49,4.95-2.54,7.25
            c0.01,3.88-2.41,5.46-5.78,6.13c-7.49,0.58-7.74-6.39-10.82-10.41c-1.47-4.97,2.8-10.07-0.37-15c0.69-0.09,1.37-0.03,2.04,0.18
            c4.37-2.24,8.79-2.15,13.26-0.34C233.05,142.12,236.26,142.05,234.87,146.68z"
            />
            <path
              style="fill: #60912d"
              d="M195.42,161.71c-0.93,0.9-1.86,1.8-2.79,2.7c-7.77-2.75-7.44-6.72-2.13-11.99
            c2.47-2.46,3.81-9.02,7.27-0.84c2.44,1.27,5.05,2.37,5.26,5.69C200.49,158.75,197.96,160.23,195.42,161.71z"
            />
            <path
              style="fill: #779d29"
              d="M215.13,157.11c4.92,0.3,5.56,6.39,9.98,7.37c2.92,2.53,5.6,5.14,2.71,9.36
            c-1.2,0.28-2.4,0.56-3.6,0.84c-2.26-0.19-4.51-0.37-6.77-0.56c-1.54-1.7-1.44-3.49-0.31-5.35
            C216.47,164.88,215.8,160.99,215.13,157.11z"
            />
            <path
              style="fill: #74934a"
              d="M217.14,168.77c0.1,1.78,0.21,3.57,0.31,5.35c-3.37,1.92-1.11,6.22-4.37,9.31
            C212.63,177.46,205.5,170.88,217.14,168.77z"
            />
            <path
              style="fill: #7fb22e"
              d="M203.03,157.27c-1.76-1.9-3.51-3.79-5.26-5.69c1.6-0.82,3.2-1.65,4.8-2.47
            c2.34,0.54,5.73,0.73,5.53,3.54C207.98,154.3,204.82,155.74,203.03,157.27z"
            />
            <path
              style="fill: #bace33"
              d="M227.82,173.84c-0.9-3.12-1.81-6.24-2.71-9.36c2.21-1.03,4.42-2.06,6.63-3.09
            c3.79,0.3,7.62,0.73,10.63-2.42c2.84-1.22,4.87-0.9,5.15,2.73c-0.2,0.73-0.34,1.47-0.41,2.22
            C239.51,164.95,236.13,174.19,227.82,173.84z"
            />
            <path
              style="fill: #879e9f"
              d="M123.62,159.41c-0.76-2.49-1.53-4.97-2.29-7.46c-3.69-6.46-1.65-12.2,1.79-18.08
            c2.42-4.13,3.5-8.92,4.91-13.53c2.03-3.55,2.99-8.02,7.87-9.09c1.91-0.06,3.44,0.7,4.69,2.11c2.22,3.71,1.13,8.96,5.4,11.74
            c1.35,1.82,2.43,3.81,3.83,5.6c3.3,4.56,4.54,9.56,3.28,15.11c0.66,15.21-10.78,16.06-21.31,18c-1.13,0.28-2.27,0.33-3.42,0.13
            C126.33,162.92,124.26,161.91,123.62,159.41z"
            />
            <path
              style="fill: #63807b"
              d="M128.83,161.72c1.44-0.66,2.82-1.64,4.33-1.93c8.86-1.71,16.88-4.11,15.29-15.87
            c11.77,3.06,13.08,6.15,7.6,17.99c0.58,0.88,1.15,1.76,1.73,2.64c-2.17,9.33-9.96,11.04-17.52,11.89
            c-5.31,0.6-9.45,1.72-11.73,6.83c-3.53-7.57-7.14-15.12-4.91-23.87c1.66,0.84,3.33,1.69,4.99,2.53c0.59,0.46,1.08,1.06,1.78,1.4
            C129.83,162.84,129.31,162.3,128.83,161.72z"
            />
            <path
              style="fill: #35442b"
              d="M131.38,122.28c-2.43,6.89-2.61,14.7-7.15,20.63c-2.26,2.95-2.08,6-2.89,9.04
            c-4.21-4.5-10.54-7.59-10.1-15.19c-3.4-4.79,0.97-7.9,2.49-11.65c1.26-1.91,2.94-3.28,5.15-3.95c2.67-0.68,4.72-2.99,7.66-3.02
            C129.12,118.37,130.39,120.16,131.38,122.28z"
            />
            <path
              style="fill: #5e7457"
              d="M116.56,127.17c3.1,1.36,5.15,3.15,0.44,4.81c-2.71,0.96-5.09,1.65-5.76,4.78
            c-2.39-0.7-4.77-1.39-7.16-2.08c0.09-0.8,0.19-1.6,0.3-2.4c0.52-3.76-1.26-8.52,3.97-10.34
            C113.07,120.29,114.64,124.11,116.56,127.17z"
            />
            <path
              style="fill: #4c6234"
              d="M111.03,193.72c0.09-0.6,0.12-1.21,0.1-1.82c2.8-2.44,5.79-4.36,10.55-3.33
            C119.87,194.61,115.22,193.74,111.03,193.72z"
            />
            <path
              style="fill: #2f411e"
              d="M192.78,117.26c-2.51,2.62-5.3,5.03-7.47,7.91c-4.79,6.36-9.14,9.89-13.92-0.02
            c-1.23-2.55-3.54-4.79-6.86-3.81c-3.08,0.91-4.16,3.14-4.01,6.55c0.07,1.64-1.85,3.37-2.87,5.06c-4.59,0.22-6.15-3.2-7.84-6.49
            c-2.14-6.68,3.13-9.91,6.52-14.01c5.57-3.28,6.64-14.1,16.64-9.94c4.61,2.63-1.39,18.43,12.23,9.88
            C188.96,112.08,191.23,114.11,192.78,117.26z"
            />
            <path
              style="fill: #425d36"
              d="M239.81,115.13c-2.95,1.19-6.2,1.94-8.78,3.67c-5.9,3.97-5.73-0.48-6.32-4.14
            c-3.36-3.55-9.63,0.13-12.35-4.99c-6.59-10.46-0.84-16.65,7.57-21.92c4.52-2.03,7.41,1.1,10.6,3.34
            c2.32,2.67,1.24,6.27,2.52,9.24c1.54,4.6,6.62,5.22,9.43,8.4C243.65,111.73,244.7,114.69,239.81,115.13z"
            />
            <path
              style="fill: #3f5825"
              d="M172.96,102.51c-7.35,2.16-8.15,9.99-12.62,14.64c-5.53-5.52-12.97,3.46-18.46-2.43
            c-2.28-6.34-2.99-12.72-0.4-19.16c1.37-1.67,3.34-3.02,5.3-2.51c8.35,2.2,7.22-4.31,8.35-8.78c2.46-3.27,5.99-3.65,9.69-3.57
            c3.98,1.46,7.04,3.88,8.29,8.14C173.06,93.4,173.01,97.95,172.96,102.51z"
            />
            <path
              style="fill: #496725"
              d="M167.52,66.21c-2.47,0.24-3.7,1.69-3.99,4.07c-1.94,2.23-4.29,1.61-6.64,0.96
            c-4.67-2.81-10.68-1.34-15.16-4.75c-5.97-3.68-17.53-2.79-12.61-15.42c3.09-2.85,6.74-3.16,10.63-2.32
            c6.3,3.04,12.46,0.35,18.71-0.38c4.28-0.51,8.67-1.87,12.96,0.22c1.69,0.87,2.98,2.15,3.89,3.82
            C177.5,59.7,174.52,64.1,167.52,66.21z"
            />
            <path
              style="fill: #6c9e2e"
              d="M153.25,124.99c1.47,2.65,2.93,5.31,4.4,7.96c-0.06,0.56-0.05,1.11,0.05,1.67
            c1.18,4.26,4.51,6.51,8.13,8.23c3.09,1.47,4.33,3.33,2.83,6.58c-0.91,7.49-3.95,12.82-12.6,12.47
            c-1.45-6.45-0.02-14.12-7.6-17.98c2.14-4.5-1.2-7.94-2.26-11.81C144.5,125.73,149.24,125.73,153.25,124.99z"
            />
            <path
              style="fill: #2f411e"
              d="M168.65,149.43c-0.84-3.64-3.13-4.3-6.74-5.03c-4.37-0.89-10.92-3.09-4.22-9.78
            c6.92,1.11,13.09,9.63,21.54,1.79c0.7-0.65,2.54,9.63,2.33,15.44C176.47,152.46,174.31,140.89,168.65,149.43z"
            />
            <path
              style="fill: #395228"
              d="M229.23,92.79c-4.1,0.18-6.64-2.53-9.3-5.02c0.8-3.36,1.6-6.72,2.4-10.07
            c-0.45-1.13-0.44-2.27,0.02-3.4c5.05-4.39,6.57-13.74,16.28-11.65c2.57,0.78,4.49,2.35,5.8,4.69c1.08,2.42,1.13,4.88,0.31,7.37
            c-2,4.93-7.59,7.17-9.28,12.37C233.97,89.62,232.64,92.34,229.23,92.79z"
            />
            <path
              style="fill: #27361c"
              d="M202.52,85.38c-2.68-4.74-3.64-10.87-10.08-12.6c-0.56-1.06-0.64-2.14-0.15-3.25
            c5.18-2.6,8.35-7.38,12.24-11.4c1.68-0.85,3.49-0.98,5.33-0.94c2.58,0.1,4.84,0.9,6.61,2.87c3.06,4.93,4.36,9.93,0.47,15.09
            c-2.45,2.19-5.49,3.79-7.16,6.83C207.78,83.99,204.56,83.42,202.52,85.38z"
            />
            <path
              style="fill: #96b238"
              d="M212.37,109.68c5.12-0.82,12.37-6.94,12.35,4.99c-3.6,4.86-8.85,6.59-14.46,7.64
            C208.69,117.72,204.7,112.72,212.37,109.68z"
            />
            <path
              style="fill: #677d53"
              d="M192.78,117.26c-3.41-0.24-5.81-2.06-7.58-4.87c0.92-1.79,1.59-3.79,2.82-5.34
            c2.2-2.76,5.12-3.54,8.6-2.34c3.16,1.09,4.63,3.95,4.81,6.46C201.87,117.25,194.9,114.03,192.78,117.26z"
            />
            <path
              style="fill: #5b823a"
              d="M167.52,66.21c-1.58-5.78,7.29-7,5.58-12.84c1.28-1.57,2.95-1.97,4.87-1.68
            c2.95,1.13,4.95,3.21,6.06,6.16c0.3,3.61-3.15,5.79-3.44,9.2c-1.28,4.01-3.64,5.88-7.87,3.91
            C170.98,69.39,169.25,67.8,167.52,66.21z"
            />
            <path
              style="fill: #6c957f"
              d="M178.17,63.23c-0.51-3.73,4.45-2.19,4.85-5.05c4.74-3.86,4.62-3.71,9.12,0.08
            c4.58,3.86,7.54,7.55,1,12.19c-0.27,0.77-0.5,1.54-0.69,2.33c-0.85,0.32-1.7,0.64-2.54,0.96
            C181.08,75.73,182.2,66.6,178.17,63.23z"
            />
            <path
              style="fill: #628f75"
              d="M158.47,68.07c1.69,0.74,3.37,1.47,5.06,2.21c2.46,6.56,10.82,10.07,9.57,18.55
            c-3.6-0.56-7.33-0.96-7.8-5.76C158.3,80.23,151.63,77.24,158.47,68.07z"
            />
            <path
              style="fill: #496725"
              d="M178.17,63.23c6.57,0.52,8.05,6.75,11.74,10.5c-0.76,1.22-1.51,2.43-2.27,3.64
            c-3.94,3.51-6.54-2.87-10.2-1.39c-1.58-1.67-3.15-3.34-4.73-5.01C176.57,69.83,177.92,66.92,178.17,63.23z"
            />
            <path
              style="fill: #b4bdc6"
              d="M230.06,141.95c-4.39,1.35-8.72,5.03-13.26,0.33c-0.34-1.7-0.69-3.41-1.03-5.12
            C224.28,127.56,226.58,136.51,230.06,141.95z"
            />
            <path
              style="fill: #98adc8"
              d="M207.34,80.15c-0.15-5.22,1.04-9.09,7.6-7.56c2.24,0.14,4.59-0.3,6.5,1.38
            c0.32,0.47,0.64,0.94,0.96,1.41c0,0.77-0.02,1.54-0.07,2.31c-1.74,1.59-3.48,3.18-5.22,4.77
            C213.23,84.33,209.18,86.96,207.34,80.15z"
            />
            <path
              style="fill: #4a6c5e"
              d="M177.44,75.99c4.06-4.34,6.89,0.29,10.2,1.4c1.84,2.56,4.61,5.56,0.91,8.13
            c-4.57,3.18-4.64-2.08-6.35-4.23C180.73,79.43,179.04,77.75,177.44,75.99z"
            />
            <path
              style="fill: #395228"
              d="M207.34,80.15c2.52,3.91,6.05,3.52,9.77,2.3c-4.08,4.88-9,5.59-14.59,2.93
            C202.83,82.44,204.42,80.68,207.34,80.15z"
            />
            <path
              style="fill: #455c27"
              d="M99.04-90.74c1.61,1.89,3.23,3.79,4.84,5.68c4.47,5.96,5.99,13.65,12.76,18.51
            c4.05,2.9,5.15,8.92,3.58,14.45c-2.75,2.74-4.75,5.46-5.53,9.78c-1.26,6.99-5.54,11.51-13.73,8.29
            c-3.12-4.61-9.49-6.65-10.38-13.04c-1.81-6.22-2.6-12.18-0.9-18.89c2.08-8.2,0.43-16.06-8.75-19.92
            c-2.54-1.26-5.21-1.93-8.07-1.78c-8.01-0.45-15.73,8.5-23.94,0.71c-0.8-0.97-1.35-2.06-1.66-3.28c0-1.34,0-2.69,0.01-4.03
            c1.73-5.37,5.07-6.33,9.78-3.58c2.6-0.96,5-2.07,8.06-1.39c11.28,2.5,13.92,0.3,12.08-10.98c-0.67-4.09-0.3-7.9-0.12-11.84
            c3.29,3.47,5.83,8.18,11.83,7.23c0.64,2.67,2.14,4.44,4.99,4.8c2.94,3.63-3.06,4.78-2.16,7.84c-0.79,1.65-1.58,3.3-2.38,4.96
            C91.9-94.06,97.09-94.84,99.04-90.74z"
            />
            <path
              style="fill: #98b542"
              d="M57.03-97.83c-3.26,1.19-6.52,2.39-9.78,3.58c-1.74-2.4-2.55-5.02-1.77-7.98
            c1.1-3.8,2.2-7.61,4.01-13.86C51.89-108.4,56.63-104.22,57.03-97.83z"
            />
            <path
              style="fill: #7caa29"
              d="M47.25-90.21c0.75,0.33,1.51,0.61,2.29,0.85c4.44,6.24,1.03,9.31-4.62,11.47
            c-4.74-6.63-7.1-3.05-9.08,2.12C31.49-87.04,43.91-85.03,47.25-90.21z"
            />
            <path
              style="fill: #223019"
              d="M91.71-102.17c-3.24-1.17-6.02,6.54-8.66,1.22c-1.7-3.43,3.3-4.48,5.87-6.03
            c1.66-1,3.31-2.02,4.96-3.04c2.14-5.57,6.77-1.03,9.93-2.45c2.93,3.42,4.22,7.96,1.76,11.27c-3.48,4.67-5.85-2.4-9.2-2.15
            C94.79-103.24,93.26-102.58,91.71-102.17z"
            />
            <path
              style="fill: #6f9a31"
              d="M44.92-77.89c1.54-3.83,3.08-7.65,4.62-11.48c9.16,5.97,16.42-5.15,25.18-2.79
            c1.31,1.41,2.42,2.93,2.87,4.85c0.28,8.46-4.29,8.65-10.33,6.12c-0.43-0.01-0.71-0.02-0.71-0.02c-1.02-0.02-1.11-0.02-0.11-0.02
            c4.9,3.08,9.42,0.37,14.05-0.57c1.22,0.36,2.24,0.98,3.11,1.91c3.55,6.33,1.31,11.31-3.58,15.69c-1.89,1.67-3.75,3.41-6.31,4.03
            c-2.38,0.51-4.69-0.52-7.06-0.33c-13.68,4.03-15.28,2.8-19.06-14.64C46.7-76.07,45.81-76.98,44.92-77.89z"
            />
            <path
              style="fill: #54762a"
              d="M47.59-75.16c6.06,9.8,6.06,9.8,20.02,10.44c1.87,5.93,0.08,10.51-5.09,13.82
            c-3.95,2.24-3.97,7.4-7.37,10.09c-2.8,1.49-5.59,2.65-8.17-0.37C47.19-52.51,47.39-63.83,47.59-75.16z"
            />
            <path
              style="fill: #688630"
              d="M33.33-45.67c5.75-1.01,6.54,4,8.76,7.26c3.08,0.9,5.81,2.2,6.57,5.77
            c-0.01,5.49-4.44,7.32-8.08,9.81c-4.42,0.61-8.62,2.74-13.25,1.99c-5.66-2.32-4.85-6.88-3.99-11.46c0.1-1.2,0.27-2.4,0.51-3.57
            c0.31-1.69,0.77-3.33,1.88-4.7C28.08-42.53,30.1-45.01,33.33-45.67z"
            />
            <path
              style="fill: #86b329"
              d="M47.5-32.88c-2.95-0.72-4.59-2.72-5.41-5.53c1.63-0.92,3.26-1.84,4.89-2.76
            c2.47-0.72,4.95-1.43,7.43-2.15c4.63-2.45,8.14-2.14,9.37,3.81c-1.44,7-6.33,8.98-12.72,8.77
            C49.64-31.07,48.45-31.79,47.5-32.88z"
            />
            <path
              style="fill: #909560"
              d="M22.19-60.29c0.63-1.99,0.58-4.35,2.92-5.41c5.25,2.68,9.14,6.14,7.33,12.85
            c-3.05,2.73-6.28,3.46-9.8,0.87C20.85-54.65,21.34-57.46,22.19-60.29z"
            />
            <path
              style="fill: #aeb47e"
              d="M23.06-52.71c3.13-0.04,6.26-0.09,9.39-0.13c0.29,2.39,0.59,4.78,0.88,7.17
            c0.72,2.95,0.33,5.69-2.07,7.68c-0.18,0.15-2.19-1.92-3.35-2.96C22.67-43.38,20.12-46.91,23.06-52.71z"
            />
            <path
              style="fill: #475e28"
              d="M27.24-32.74c0.05,3.24-2.52,6.65,0.62,9.69c3.64,1.68,6.64,3.94,7.41,8.22
            c-1.17,7.03-1.34,14.42-7,19.89C16.06,5.94,14.49,4.61,13.61-7.35c0.79-6.04,1.45-12.09-1.82-17.74
            c-0.04-1.18,0.3-2.23,1.05-3.14c0.67-0.08,1.33-0.09,2-0.04C18.65-30.67,20.55-38.36,27.24-32.74z"
            />
            <path
              style="fill: #375024"
              d="M12.85-28.23c0.06,0.73,0.11,1.47,0.15,2.2c-2.86,8.04-8.22,8.87-15.11,5.24c-2-0.98-4-2.24-6,0
            c-4.19-2.52-3.53-5.99-0.89-8.77c2.28-2.4,3.62-4.94,4.29-8.02c1.6-2.63,3.19-5.26,4.79-7.88C11.57-45.1,6.65-32.53,12.85-28.23
            z"
            />
            <path
              style="fill: #475e28"
              d="M23.06-52.71c2.03,3.75,5.62,6.85,4.85,11.76c-0.23,1.58-0.46,3.16-0.69,4.75
            c-6.52,2.06-10.17-0.89-12.04-6.93c-5.96-9.12,6.19-10.81,7.02-17.15C22.48-57.76,22.77-55.24,23.06-52.71z"
            />
            <path
              style="fill: #587b2b"
              d="M15.17-43.14c4.02,2.31,8.03,4.63,12.04,6.94c0.01,1.15,0.02,2.3,0.03,3.46
            c-4.13,1.49-8.26,2.99-12.39,4.48C16.52-33.19,11.96-38.25,15.17-43.14z"
            />
            <path
              style="fill: #354c22"
              d="M242.41,68.19c-1.65-0.86-3.3-1.73-4.95-2.59c-1.53-1.34-2.42-2.99-2.49-5.05
            c0.71-7.61,7.22-8.31,12.47-10.53c3.05-1.35,6.06-2.14,8.73,0.79c4.19,9.55,16.48,4.35,22.02,12.19
            c1.74,2.46,4.49,4.42,5.74,7.46c-4.68,6.81-13.58,11.8-9.6,22.34c-2.36,4.25-10.24,2.77-10.05,9.67
            c-0.68,0.38-1.42,0.58-2.2,0.6c-6.34,4.8-12.75,7.49-19.46,0.61c-1.08-1.52-1.6-3.22-1.58-5.08c0.38-7.92,1.58-15.69,4.62-23.09
            C245.9,72.49,244.5,70.19,242.41,68.19z"
            />
            <path
              style="fill: #7fa131"
              d="M252.01,119.94c-1.52-0.91-3.04-1.83-4.56-2.74c-2.79-1.93-2.86-4.57-1.92-7.46
            c7.47-7.06,12.31-2.94,16.51,3.89c-0.11,3.81-0.22,7.61-0.33,11.42C256.68,126.77,254.74,122.6,252.01,119.94z"
            />
            <path
              style="fill: #58792e"
              d="M262.04,113.62c-4.97-0.65-8.2-8.74-14.56-3.42c-4.74-1.08-9.01-2.34-2.85-7.55
            c5.73,3.68,11.71-2.8,17.46,0.42C262.07,106.59,262.06,110.11,262.04,113.62z"
            />
            <path
              style="fill: #3b5522"
              d="M252.01,119.94c4.37-0.47,6.49,3.35,9.7,5.1c-0.67,6.93-6.59,2.99-9.76,4.73
            C251.97,126.49,251.99,123.22,252.01,119.94z"
            />
            <path
              style="fill: #374920"
              d="M244.63,102.65c-2.41,3.79,1.13,5.33,2.85,7.55c-0.01,2.33-0.02,4.67-0.02,7
            c-1.67,4.47,1.58,12.33-7.86,11.42c0.07-4.5,0.15-9,0.22-13.49c1.53-1.82,1.62-3.57-0.25-5.23c-4.34-2.34-6.51-5.77-5.45-10.84
            c0.39-1.25,1.06-2.33,1.98-3.26c3.05-2.11,5.88-2.47,8.25,1.01C244.43,98.76,244.53,100.7,244.63,102.65z"
            />
            <path
              style="fill: #405a25"
              d="M247.51,161.7c-1.07-2.14-3.14-2.37-5.15-2.73c1.76-1.85,1.71-6.1,5.52-5.16
            c2.8,0.7,4.72,3.12,4.18,6.33C251.58,163.04,249.44,162.37,247.51,161.7z"
            />
            <path
              style="fill: #446b2a"
              d="M274.33,92.8c-3.85-3.6-12.16-5.91-7.3-12.84c3.58-5.11,6.91-14.34,16.9-9.51
            c0.04,0.87,0.08,1.74,0.12,2.61c1.74,1.21,3.88,2.23,2.43,5c-6.89,2.86-7.02,8.57-6.18,14.69
            C278.32,92.77,276.33,92.79,274.33,92.8z"
            />
            <path
              style="fill: #3e6127"
              d="M286.49,78.07c-0.81-1.67-1.62-3.33-2.43-5c1.54-0.98,3.08-1.96,4.63-2.94
            c-0.3-5.1-1.35-10.34,2.92-14.52c2.43-1.02,4.9-2.66,7.04,0.5c-0.78,1.48-1.56,2.96-2.34,4.44c-0.76,4.93-1.52,9.86-2.28,14.8
            c-0.92,1.77-1.84,3.54-2.77,5.31C289.66,79.79,288.07,78.93,286.49,78.07z"
            />
            <path
              style="fill: #afb87e"
              d="M310.81-112.36c0.36-4.93-3.48-9.32-1.89-14.41c7.64,2.26,5.59,9.34,7.23,14.58
            C314.38-112.24,312.6-112.3,310.81-112.36z"
            />
            <path
              style="fill: #6b7b5c"
              d="M303.87-84.97c-0.8-2.88-3.08-3.13-5.51-3.13c-0.17-4.42,6.95-4.42,5.73-9.48
            c1.57,0.79,3.13,1.59,4.7,2.38C306.98-91.87,308.44-86.96,303.87-84.97z"
            />
            <path
              style="fill: #8fa59f"
              d="M315.89-51.88c-3.85-4.45-6.07,7.12-9.36,1.21c-2.4-4.3-1.59-8.72,4.67-9.67
            c1.59,1.39,3.18,2.79,4.76,4.18C315.93-54.74,315.91-53.31,315.89-51.88z"
            />
            <path
              style="fill: #314021"
              d="M323.32-42.24c1.48,2.29,1.19,4.61,0.09,6.93c-7.54,2.14-10.36-0.1-7.25-7.84
            c0,0-0.13,0.19-0.13,0.19C318.45-42.72,320.88-42.48,323.32-42.24z"
            />
            <path
              style="fill: #85a28a"
              d="M316.15-43.15c-0.1,4.94,1.25,8.55,7.25,7.84c-0.05,0.74-0.11,1.47-0.18,2.21
            c-3.75,0.04-4.87,3.89-7.67,5.31C308.38-33.2,311.04-38.22,316.15-43.15z"
            />
            <path
              style="fill: #25311b"
              d="M259.52-72.7c1.6,2.5,3.19,5,4.79,7.5c5.68,4.38,13.69,6.43,15.03,15.13
            c3.03,5,6.63,9.62,9.61,14.65c-2.36,10.33-12.15,5.62-19.49,10.76c2.86-10.93,4.58-20.09-2.23-28.01
            c-9.07,3.21,1.14,14.13-7.62,17c-1.53-0.85-3.67,1.3-4.91,4.92c-7.04,2.75-13.97,5.81-21.14,8.14
            c-6.56,2.13-12.66,1.49-16.09-5.8c2.07-5.07,8.08-4.53,11.28-7.97c0.7-0.76,1.51-1.37,2.39-1.89c2.02-1.15,4.48-0.67,6.42-1.68
            c-0.12,0.03,0.18-0.61,0.18-0.61s-0.43-0.56-0.56-0.51c-1.66-0.42-3.21-0.88-4.4-2.11c-1.52-1.73-1.61-3.96-2.23-6.01
            c-4.92-12.67-4.29-14.23,9.34-23.35c1.38-0.81,2.76-1.63,4.15-2.44c3.99-0.28,2.5-5.78,6.12-6.43
            C256.25-81.7,259.1-78.51,259.52-72.7z"
            />
            <path
              style="fill: #2a3a1d"
              d="M298.84,6.37c-2.25,4.67-8.99,6.38-3.93,15.29c3.63,6.4-8.95,9.4-14.12,10.57
            c-5.56,1.27-4.24-0.21-2.4,4.59c2.45,6.37-5.54,7.77-9.12,11.16c-5.01,4.75-6.8-1.31-9.77-3.33c-0.58-0.96-0.72-1.99-0.45-3.08
            c1.25-2.43,1.65-5.1,2.15-7.76c2.18-4.65,1.72-10.11,4.44-14.58c0.44-1.03,1.06-1.97,1.78-2.82c2.77-3.09,9.72-2.67,7.99-9.55
            c-0.2-3.52,0.22-7.06-0.35-10.57c0.24-1.04,0.81-1.87,1.65-2.53c1.53-3.35,2.52-7.12,4.71-9.96c3.02-3.92,6.93-6.8,10.02,0.33
            c-0.1,5.08-0.5,10.65,7.4,6.76c2.96-1.46,5.31-0.11,7.87,0.79c-0.93,1.61-1.85,3.21-2.78,4.82
            C304.02,0.71,299.07,2.32,298.84,6.37z"
            />
            <path
              style="fill: #587333"
              d="M217.28-6.15c3.5-3.38,1.09-8.74,4.39-12.9c5.85,3.25,2.59,13.32,10.64,14.42
            c2.37,2.57,4.81,5.07,8.01,6.67c2.4,2.51,2.9,5.51,2.36,8.81c-2.03,3.84-4.71,7.39-5.36,11.87c-1.46,2.27-3.56,3.49-6.21,3.8
            c-14.13-2.26-13.92-13.87-15.73-24.19C214.32-0.88,215.67-3.55,217.28-6.15z"
            />
            <path
              style="fill: #5b802e"
              d="M269.02,18.68c-0.04,0.96-0.09,1.92-0.13,2.89c-3.77,2.08-6.45,6.68-11.8,5.15
            c-3.23-0.96-5.83-2.72-7.43-5.78c-1.44-4.63-1.56-9.48-2.2-14.25c1.2-6.27,7.8-9.33,9.23-15.49c1.79-0.58,3.58-1.16,5.37-1.74
            c6.25-0.9,8.37,3.75,10.75,8.04c1.51,3.81,3.91,7.4,2.9,11.84C274.72,13.33,268.95,13.91,269.02,18.68z"
            />
            <path
              style="fill: #68806b"
              d="M200.41-25.14c-0.99,2.17-8.47,2.62-1.81,6.83c0.1,1.99-0.98,3.18-2.67,3.94
            c-3.85,0.87-7.54-2.26-11.44-0.47c-1.1,0.13-2.2,0.26-3.31,0.39c-3.85,0.38-7.65,2.77-11.56,0.24
            c-2.01-1.41-3.08-3.36-3.33-5.78c0.08-5.12,5.61-8,5.46-13.24c0.49-3.77-0.5-8.03,3.51-10.64
            c10.77,0.92,14.72,12.26,23.89,15.66C199.84-27.3,200.26-26.28,200.41-25.14z"
            />
            <path
              style="fill: #80a830"
              d="M256.69-8.81c9.02,9.78-2.01,11.1-6.41,15.21c-2.51,0.54-4.77-0.1-6.88-1.45
            c-1.63-1.09-3.41-1.93-4.98-3.11c-1.31-1.54-2.72-3.07-0.5-4.97C243.52-7.2,249.03-11.56,256.69-8.81z"
            />
            <path
              style="fill: #324921"
              d="M195.45-16.06c1.05-0.75,2.09-1.5,3.14-2.25c3.51,1.08,4.02,3.94,4.17,7.01
            c-0.23,2.73-1.12,5.24-2.48,7.6c-1.91,3.6-3.73,7.3-8.27,8.28c-1.92,0.26-3.79,0.13-5.62-0.55c-2.59-1.28-4.05-3.38-4.56-6.18
            c-1.16-4.64-2.49-9.31,2.26-12.9C187.69-17.45,191.68-15.55,195.45-16.06z"
            />
            <path
              style="fill: #435329"
              d="M232.13-33.44c-3.74,5-9.17,5.11-14.65,5.03c-0.87-2.29-1.73-4.59-2.6-6.88
            c-0.3-7.6,5.64-11.48,9.77-16.45c1.62-0.78,3.29-0.88,5-0.33C237.33-46.78,228.7-39.31,232.13-33.44z"
            />
            <path
              style="fill: #58753b"
              d="M224.34-50.28c4.28,9.69-6.41,9.92-9.46,14.99c-1.57-0.91-3.15-1.82-4.72-2.72
            c-2.03-2.61-3.58-5.35-2.54-8.84c2.79-5.78,6.59-9.87,13.81-8.08C223.29-53.95,224.34-52.44,224.34-50.28z"
            />
            <path
              style="fill: #455f2a"
              d="M197.75-7.01c1.67-1.43,3.34-2.85,5.02-4.28c1.96,9.84,9.1,5.07,14.52,5.14
            c1.29,2.6,1.18,5.28,0.43,7.99c-1.14,6.45-6.7,5.24-10.94,6.37c-1.26-0.15-2.4-0.61-3.44-1.32
            C200.13,2.8,195.88-0.88,197.75-7.01z"
            />
            <path
              style="fill: #4d6444"
              d="M269.32-2.22c-2.42-2.78-4.84-5.55-7.26-8.32c0.42-8.17,3.72-9.63,9.45-3.74
            c2.18,2.25,3.5,5.34,5.21,8.05c0.05,0.7,0.09,1.41,0.11,2.12C274.94-1.03,273.35,3.24,269.32-2.22z"
            />
            <path
              style="fill: #5e764c"
              d="M210.07-45.1c0.29,2.36,1.67,4.7,0.1,7.08c-2.48-0.02-4.97-0.03-7.45-0.05
            c-1.19,0.84-2.35,0.8-3.5-0.1c-3.48-5.62-3.96-11.18,0.32-16.67c2.95-2.37,6.17-3.14,9.76-1.64
            C213.34-52.95,211.8-49.03,210.07-45.1z"
            />
            <path
              style="fill: #819d8b"
              d="M200.42-38.21c0.76,0.09,1.53,0.14,2.29,0.14c1.31,2.05,3.15,3.96,3.78,6.2
            c0.78,2.76,6.61,3.93,2.84,7.92c-3.22,3.4-5.81-1.61-8.92-1.19c-0.22-0.66-0.45-1.31-0.71-1.95c-3.55-0.11-6.28-1.4-6.29-5.4
            C193.41-37.05,196.14-38.69,200.42-38.21z"
            />
            <path
              style="fill: #4a6545"
              d="M254.71-30.75c0.37-8.19-1.82-16.59,1.76-25.93c3.51,7.62,2.05,14.51,3.14,21.02
            C259.26-32.75,257.53-31.2,254.71-30.75z"
            />
            <path
              style="fill: #5a812e"
              d="M288.95-35.42c-8.32-1.52-8.58-8.34-9.61-14.65C287.64-48.54,289.63-42.86,288.95-35.42z"
            />
            <path
              style="fill: #9dbe2c"
              d="M281.32-77.4c0.45-0.69,0.78-1.43,0.98-2.23c5.01,0.25,8.47,2.48,9.69,7.58
            C285.61-68.18,282.23-70.33,281.32-77.4z"
            />
            <path
              style="fill: #83a76c"
              d="M237.92-3.12c0.54,1.67,1.09,3.34,1.63,5.02c-0.39,1.03-1.12,1.71-2.14,2.08
            c-7.93,0.82-9.68-2.02-5.1-8.61C234.66-5.94,236.42-5.02,237.92-3.12z"
            />
            <path
              style="fill: #7c9c36"
              d="M298.84,6.37c-4.88-6.68-0.42-8.55,5.09-9.87c1.55-0.02,3.1-0.03,4.65-0.05
            c-0.37,3.87-1.97,7.15-4.77,9.85C302.16,6.32,300.5,6.34,298.84,6.37z"
            />
            <path
              style="fill: #779d2d"
              d="M118.89-52.92c-0.43-1.13-0.98-2.23-1.28-3.39c-2.53-9.82-6.35-14.19-14.81-1.97
            c4.2-9.85,2.28-18.32,1.08-26.78c0.97,0.58,1.95,1.16,2.92,1.74c4.55,4.04,12.07,1.51,15.9,7.16c2.28,4.06,1.48,9.49,5.65,12.75
            c0.79,0.84,1.37,1.78,1.75,2.86c0.34,1.81-0.05,3.45-1.03,4.98c-2.01,2.13-3.86,4.53-7.24,4.35
            C120.67-51.45,119.69-52.02,118.89-52.92z"
            />
            <path
              style="fill: #628a2c"
              d="M120.82-75c-5.43-1.49-12.19-0.74-14.01-8.32c2.53-2.2,0.29-4.46,0.11-6.7
            c1.69-1.32,3.31-1.18,4.86,0.24c3.23,6.23,8.09,7.88,14.49,5.14c1.92,2.84,1.29,5.49-0.6,8.05
            C124.28-75.34,122.64-74.88,120.82-75z"
            />
            <path
              style="fill: #223019"
              d="M99.04-90.74c-4.05-0.93-10.49,1.72-9.71-6.48C93.06-95.8,101.41-101.32,99.04-90.74z"
            />
            <path
              style="fill: #70992f"
              d="M111.77-89.77c-1.62-0.08-3.24-0.16-4.86-0.24c-1.95-8.17,5.09-7.57,9.39-9.63
            C114.79-96.35,113.28-93.06,111.77-89.77z"
            />
            <path
              style="fill: #819938"
              d="M123.88-78.03c0.79-2.2,1.59-4.41,2.38-6.61c2.88-1.77,0.43-9.52,7.44-6.66
            c1.08,3.66,5.47,4.93,6.31,8.77c0.11,1.29-0.18,2.49-0.84,3.6c-3.39,4-8.17,2.24-12.35,2.98
            C125.53-76.2,124.55-76.9,123.88-78.03z"
            />
            <path
              style="fill: #b4c658"
              d="M138.07-82.81c-4.46-1.28-4.25-4.97-4.37-8.49c4.18,2.23,4.62-2.62,7.04-3.72
            c1.72,0.89,3.44,1.78,5.15,2.67C147.87-85.4,144.32-83,138.07-82.81z"
            />
            <path
              style="fill: #38461e"
              d="M138.07-82.81c2.04-3.65,7.01-4.89,7.82-9.54c11,2.22,9.85-5.58,10.08-12.25
            c0.75-0.14,1.47-0.04,2.16,0.29c3.96,1.61,4.05,5.89,6.08,8.82c1.46,3.13,5.32,3.36,7.25,5.94c5,7.35,14.13,8.32,20.63,13.37
            c0.64,1.04,1.03,2.17,1.23,3.38c0.09,1.87-0.4,3.57-1.5,5.07c-0.81,0.81-1.79,1.35-2.89,1.63c-3.16-0.02-7.07-2.26-8.57,2.86
            c-0.29,1.05-0.6,2.07-0.88,3.11c-0.45,2.93-1.2,5.69-3.72,7.57c-3.72,1.6-6.58,5.29-11.21,4.75c-3.3-0.28-6.09-2.27-9.33-2.81
            c-2.78-0.13-5.64,0.87-8.34-0.54c-3.25-1.98-5.22-4.7-5.02-8.66c-0.76-1.26,3.16-2.97-0.5-3.95c-1.02-0.05-1.89-0.31-2.75-0.76
            c-5.05-5.11-1.06-10.54-0.64-15.84C138.12-81.17,138.16-81.99,138.07-82.81z"
            />
            <path
              style="fill: #567029"
              d="M160.51-92.38c-3.17-3.5-2.01-7.86-2.38-11.92c2.52-1.81,5.03-3.61,7.55-5.42
            c3.23,1.61,6.47,3.22,9.7,4.83c-2.58,2.94-6.15,5.13-7.06,9.34C166.37-92.87,163.59-92.26,160.51-92.38z"
            />
            <path
              style="fill: #799c34"
              d="M165.43-97.43c1.16-5.36,4.8-7.43,9.95-7.46c1.6,1.51,3.31,0.98,5.02,0.25
            c0.08,1.54,0.16,3.08,0.24,4.61c-2.67,0.32-5.54-0.01-7.61,2.29C170.6-95.09,168.06-95.16,165.43-97.43z"
            />
            <path
              style="fill: #7bad28"
              d="M291.6,55.61c-0.68,4.9,0.84,10.24-2.92,14.52c-2.69-2.46-4.17-5.51-4.48-9.14
            C286.87,59.47,287.5,55.15,291.6,55.61z"
            />
            <path
              style="fill: #394b2a"
              d="M259.44,44.66c0,0,0.08,0,0.08,0c-1.11,2.05-2.22,4.1-3.34,6.15c-2.23,0.8-4.45,1.59-6.68,2.38
            c-3.13,0.03-6.03-0.42-7.66-3.59c0.42-5.96-4.27-11.06-2.97-17.19c0.55-2.51,1.88-4.42,4.19-5.61
            c5.18-1.89,10.36-0.31,15.57,0.01c1.13,0.48,2.04,1.23,2.76,2.22C263.6,34.6,260.84,39.54,259.44,44.66z"
            />
            <path
              style="fill: #44582c"
              d="M224.34-50.28c-1.18-1.2-2.35-2.41-3.53-3.61c-1.63-1.66-3.61-3.07-4.08-5.58
            c-0.21-1.12-0.33-2.26-0.39-3.4c0.11-4.35,2.91-8.21,2.32-12.7c0.74-5.69-2.21-8.52-7.44-9.59c-0.89-0.7-1.51-1.59-1.84-2.68
            c1.13-2.23,2.27-4.45,3.4-6.67c1.57-0.94,3.13-1.88,4.7-2.81c4.41,0.39,7.25-2.41,10.17-5.04c4,5.21,0.41,12.29,4.33,17.52
            c4.07,0.63,4.77,3.8,5.34,7.07c0.85,1.74,1.71,3.48,2.56,5.22c-0.14,2.01,0.32,5.42-0.53,5.8c-8.39,3.67-5.53,10.43-5.02,16.58
            c-1.51,1.19-2.99,1.22-4.44-0.12C228.05-50.3,226.19-50.29,224.34-50.28z"
            />
            <path
              style="fill: #87a093"
              d="M219.64-59.33c0.4,1.81,0.8,3.63,1.19,5.44c-4.69,1.58-7.31,5.69-10.76,8.79
            c-0.71-3.42,1.01-7.43-2.45-10.19c-5.71-2.52-6.15-9.09-10.04-13.02c-0.58-3.5-0.77-7.02-0.49-10.57
            c0.56-1.81,1.58-3.23,3.42-3.93c2.41-1.52,4.82-3.04,7.24-4.56c4.88,4.05,3.16,11.2,7.32,15.58
            C216.47-67.6,216.29-62.82,219.64-59.33z"
            />
            <path
              style="fill: #9aa67c"
              d="M200.51-82.81c-0.05,1.63-0.1,3.25-0.15,4.88c-2.68,3.19-4.02,8.83-10.22,5.6
            c-0.12-0.84-0.25-1.68-0.37-2.53c-1.54-2.61-3.68-4.86-4.75-7.77c-0.38-2.68,0.35-4.91,2.66-6.5c3.45-1.77,6.89-3.55,10.34-5.32
            C195.36-89.81,202.26-87.24,200.51-82.81z"
            />
            <path
              style="fill: #36441d"
              d="M212.27-69.96c-7.91-4.13-4.22-11.29-4.53-17.4c0.51-0.31,1.06-0.46,1.65-0.47
            c1.03,0.13,2.06,0.26,3.09,0.39c2.35,1.58,4.99,2.9,5.26,6.23c0.06,3.13,1.34,6.25,0.32,9.45
            C216.53-69.86,214.81-68.56,212.27-69.96z"
            />
            <path
              style="fill: #b2c276"
              d="M187.67-89.12c0.14,2.33,0.27,4.66,0.41,6.99c-8.32,4.86-13.32-3.32-19.96-5.1
            c-3.79,0.14-6.01-2.05-7.61-5.15c1.64-1.68,3.28-3.37,4.92-5.05c2.53-0.1,5.07-0.21,7.6-0.31
            C179.02-96.75,183.44-93.1,187.67-89.12z"
            />
            <path
              style="fill: #7b9454"
              d="M249.46-80.19c0.05,2.41,0.32,4.85,0.08,7.23c-0.24,2.38-0.85,5.46-4.19,4.27
            c-2.99-1.06-0.04-4.33-1.3-6.32c4.09-2.47-9.72-14.65,5.89-10.86C250.8-83.88,251.03-81.96,249.46-80.19z"
            />
            <path
              style="fill: #788d3e"
              d="M249.46-80.19c0.16-1.89,0.31-3.78,0.47-5.67c5.95,2.37,16.35,1.5,9.59,13.15
            C254.81-73.38,254.86-80.45,249.46-80.19z"
            />
            <path
              style="fill: #25311b"
              d="M237.33-77.78c-1.78-2.36-3.55-4.72-5.34-7.07c1.86-2.44,2.18-6.11,6.47-7.55
            C239.82-87.25,239.19-82.5,237.33-77.78z"
            />
            <path
              style="fill: #3c4b20"
              d="M17.67-8.56c-3.22,9.47,4.59,9.93,10.04,12.33c6.74-2.12,7.16,2.21,7.15,7.01
            c-6.81,12.41-15.74,5.55-24.24,2.65c-2.43,2.05-2.58,4.88-2.63,7.73c-0.9-0.8-1.79-1.6-2.69-2.4C7.79,11.25,5.38,3.6,5.85-3.97
            C8.49-8.85,11.17-13.65,17.67-8.56z"
            />
            <path
              style="fill: #26351b"
              d="M17.67-8.56C13.02-8.86,9.5-6.24,5.85-3.97c0.28-7-3.82-11.92-7.97-16.82
            c5.04-1.75,10.07-3.49,15.11-5.24C29.62-24.25,18.34-14.98,17.67-8.56z"
            />
            <path
              style="fill: #73a24c"
              d="M-4.72-37.58c6.97,7.24-1.11,11.43-3.4,16.79c-2.04-0.9-4.08-1.8-6.12-2.7
            c-0.92-2.45-1.84-4.9-2.76-7.35C-15.25-37.37-11.28-39.83-4.72-37.58z"
            />
            <path
              style="fill: #56742b"
              d="M62.62,97.56c2.36-5.8,4.72-11.6,7.08-17.4c1.38-0.89,0.37,0.2,0.68-0.92
            c0.96-3.53,12.31-0.76,5.31-8.79c0.54-1.03,1.28-1.87,2.21-2.55c5.51-2.57,12.45-3.2,14.26-10.7C92,52.07,92.8,46.9,90.92,41.91
            c0.12-1.97,0.77-3.71,2.05-5.21c7.35-3.73,12.89-14.45,23.39-6.13c3.07,3.16,1.1,7.23,1.94,10.8c3.12,4.62,7.26,8.99,3.98,15.26
            c-1.11,1.36-2.52,2.22-4.21,2.62c-6.92,1.12-8.8,6.52-10.31,12.15c0.15,0.13-0.32,0.52,0.21,0.61c0.12-1.16,0.6-2.14,1.49-2.93
            c2.88-1.91,5.72-0.88,8.58,0.05c2.43,0.91,4.18,2.55,5.23,4.93c1.36,5.38,1.81,10.65-2.25,15.24
            c-3.82,3.59-10.54,3.96-11.68,10.56c-0.69,0.91-1.52,1.65-2.51,2.22c-4.4,0.69-8.89,1.9-12.65-1.86
            c-3.88-1.73-5.69-3.9-0.88-6.6c3.06-1.71,7.29-1.35,9.23-5.33c-5.94-4.85-15.25,4.81-19.95-4.72c-6.37,3.41-6.7,10.46-9.3,16.04
            c-1.38,2.95-2.64,5.14-5.92,4.99C62.88,104.4,62.47,101.08,62.62,97.56z"
            />
            <path
              style="fill: #465f3e"
              d="M94.16,100.22c4.05-0.79,8.08-2.75,12.17-0.15l0.92,0.7l-0.78-0.85c1.13-7.1,2-14.46,12.35-12.26
            c6.59-2.45,8.31,1.79,9.46,6.85c0.09,3.42-1.23,6.49-2.32,9.64c-4.84,6.07-0.65,14.14-4.49,20.43
            c-1.64,0.86-3.27,1.72-4.91,2.58c-6.56-4.27-10-1.07-12.19,5.11C103.14,120.89,99.25,110.37,94.16,100.22z"
            />
            <path
              style="fill: #5d7b29"
              d="M54.88,35.77c3.5,10.16,3.01,21.88,14.71,30.74c-11.23,0.18-20.22-2.69-27.23,4.2
            c-5.31,0.64-10.07-1.35-14.88-3.1c-3.63-6.74-2.59-14.03-2.46-21.21c2.73-2.96,3.15-6.56,2.85-10.36
            c2.49-2.05,4.71-1.29,6.81,0.64c2.53,2.85,0.93,7.15,3.47,9.99c1.02,1.72,2.1,3.4,4.23,3.85c0.18,0.04,0.44-1.16,0.44-2.12
            c2.75-3.48,2.61-7.69,3.18-11.71c1.35-2.28,3.39-3.07,5.93-2.81C53.11,34.22,54.09,34.86,54.88,35.77z"
            />
            <path
              style="fill: #3b471d"
              d="M32.99,38.35c-1.71-0.77-3.42-1.54-5.13-2.31c-6.57-1.62-13.14-3.25-19.71-4.87
            c1.63-5.97,12.62,3.22,11.54-7.14c1.88-5.9,6.3-7.05,11.55-5.7c5.34,1.37,7.9,5.62,8.55,10.71
            C40.43,33.93,38.86,37.89,32.99,38.35z"
            />
            <path
              style="fill: #6e922b"
              d="M25.02,46.4c2.94,6.83,2.67,14.02,2.47,21.21C18.65,61.48,22.29,53.89,25.02,46.4z"
            />
            <path
              style="fill: #31431d"
              d="M116.34,33.36c-9.05-6.44-13.89,6.11-21.79,4.84c-4.41-1.87-3.55,5.08-7.27,4.37
            c-5.7-2.77-11.51-5.23-17.73-6.61c-5.32-2.17-7.93-5.02-2.46-9.88c3.8-3.43,2.07-7.07,0.53-10.81
            c-1.88-4.56-1.31-8.51,3.4-11.14c4.85-1.82,8.05,1.25,11.38,3.83c1.32,0.59,2.46,0.31,3.37-0.85c0.8-1.92,0.29-3.95,0.66-5.87
            c0.15-1.07,0.49-2.08,0.86-3.07c1.31-2.63-0.12-5.2-0.23-7.8c0.19-2.5,1.28-4.4,3.65-5.42c10.75,1.19,19.22,11.59,31.25,7.76
            c1.23,0.2,2.35,0.65,3.41,1.28c3.3,2.3,5.2,5.36,5.2,9.46c-0.19,2.07-1.64,3.61-2.38,5.44c-3.78,9.49-9.17,10.03-15.8,3.19
            c0.24,0.11,0.62,0.49,0.82,0.58c4.11,4.22,10.94,6.95,7.24,14.76C119.77,29.88,119.05,32.31,116.34,33.36z"
            />
            <path
              style="fill: #2f3a1b"
              d="M32.99,38.35c3.82-4.13,4.37-8.19,0.66-12.94c-4.38-5.6-9-3.97-13.95-1.38
            c-4.59-2.19-5.8-7.29-9.08-10.6c7.03-3.62,15.08,3.29,21.93-2.16c3.32-6,8.97-6.97,15.05-7c16.54-0.61,19.58,1.05,22.98,11.97
            c1.24,4.01,0.18,6.34-4.02,7.08c-7.19,1.05-7.04,8.99-11.67,12.44c-0.88-0.13-1.76-0.27-2.64-0.4
            c-6.99-2.59-6.68,2.38-6.96,6.75c-0.6,2.34-2.38,3.98-3.53,6c-2.07,1.75-4.23,2.42-6.6,0.56C31.9,45.77,32.61,42.03,32.99,38.35
            z"
            />
            <path
              style="fill: #608230"
              d="M66.56,23.33c1.79-11.55-1.1-14.63-11.48-12.29c-2.12,0.48-4-0.02-5.55-1.61
            C37.84,5.74,45.39-2.66,44.96-9.14c-0.5-5.81-0.82-11.52,6.72-13.2c7.54,0.58,11.92,10.96,21.02,6.78
            c3.18,0.28,5.72,1.61,7.29,4.5c1.01,2.9,0.26,5.72-0.36,8.57c-1.15,4.14-3.77,7.02-7.63,8.82C69.1,9.5,70.85,12,72.77,14.94
            c4.34,6.67,2.62,10.7-5.68,11.14C66.91,25.16,66.73,24.25,66.56,23.33z"
            />
            <path
              style="fill: #8aaf2c"
              d="M96.4,55.9c5.35,0.93,6.06,6.53,2.98,7.38c-6.82,1.88-11.05,12.01-20.02,6.67
            c-2.24-1.71-0.25-6.74-5.04-6.46c-0.55-5.97-4.95-10.18-7.05-15.44c1.2-9.64,8.09-11.74,19.19-5.85
            c1.7,0.54,3.43,1.04,4.71,2.41C94.86,47.47,90.32,54.15,96.4,55.9z"
            />
            <path
              style="fill: #719332"
              d="M86.64,43.75c-7.27-2.26-13.86-1.41-19.37,4.3c-4.88-5.1,0.06-8.34,2.28-12.09
            c7.23-10.69,7.23-10.69,17.38,5.9l0.53,1.05L86.64,43.75z"
            />
            <path
              style="fill: #8aaf2c"
              d="M77.11,71.2c7.26,11.04-4.11,6.67-7.41,8.97c0.46-5.33,1.76-10.42,4.33-15.14
            C78.68,65.28,78.71,67.83,77.11,71.2z"
            />
            <path
              style="fill: #aac062"
              d="M77.11,71.2c-1.03-2.05-2.05-4.11-3.08-6.17c0.02-0.53,0.12-1.05,0.28-1.54
            c6.02-1.47,1.04-12.23,10.4-11.46c0.73,6.74-4.94,11.65-5.36,17.92C78.61,70.37,77.86,70.78,77.11,71.2z"
            />
            <path
              style="fill: #537754"
              d="M123.74,102.43c-1.97-3.43,0.66-5.2,2.61-7.22c-0.03-6.68-5.82-14.63,4.2-19.11
            c4.71-1.38,8.71-0.12,12.13,3.26c1.45,1.17,2.45,2.66,3,4.43c1.29,4.21-1.86,7.53-2.31,11.4c2.13,6.93,0.18,14.01,0.82,21
            c2.75-0.39,5.3,0.35,5.5,3.31c0.28,4.25-2.67,6.54-6.4,7.88c-6.47-2.28-6.8-6.99-4.93-12.55c1.26-10.16-6.07-8.11-11.82-8.31
            C124.86,105.68,123.86,104.36,123.74,102.43z"
            />
            <path
              style="fill: #80af31"
              d="M143.29,127.4c0.41-3.72,7.32-6.93,0.9-11.2c2.24-1.69,4.44-3.44,6.74-5.05
            c7.9-5.54,9.83-1.58,9.41,6c-2.8,2.22-7.93,2.33-7.09,7.84c-2.35,2.37-4.71,4.75-7.06,7.12
            C145.22,130.54,144.26,128.97,143.29,127.4z"
            />
            <path
              style="fill: #6e8d93"
              d="M126.12,104.69c3.54-5.38,8.82-4.97,12.61-1.76c3.6,3.05,3.26,8.02-0.37,11.91
            c-0.51-0.75-1.01-1.51-1.51-2.26c-2.42-0.09-4.88,0.74-7.25-0.35C126.98,110.4,124.48,108.5,126.12,104.69z"
            />
            <path
              style="fill: #405752"
              d="M131.15,109.55c1.9,1.01,3.8,2.03,5.7,3.04c-1.82,3.23-3.65,6.46-5.47,9.69
            c-1.52-0.7-3.04-1.39-4.56-2.09C121.5,113.89,125.3,111.3,131.15,109.55z"
            />
            <path
              style="fill: #879e9f"
              d="M128.83,161.72c4.9,2.93,13-3.32,18.02,7.97c-7.63-3.91-15.41,1.69-18.23-7.76
            C128.62,161.94,128.83,161.72,128.83,161.72z"
            />
            <path
              style="fill: #5a7770"
              d="M131.15,109.55c-2.64,3.06-5.49,6.03-4.33,10.64c-1.78,1.47-3.57,2.93-5.35,4.4
            c-0.88-7.55-7.81-15.73,2.28-22.15c0.79,0.75,1.59,1.51,2.38,2.26C127.8,106.31,129.47,107.93,131.15,109.55z"
            />
            <path
              style="fill: #aac037"
              d="M229.23,92.79c0.92-2.6,1.83-5.19,2.75-7.79c10.1,1.09,10.65,2.42,5.23,12.7l0.05-0.01
            c-3.02,2.25-4.88,6.92-9.99,4.86C222.95,98.3,228.18,95.96,229.23,92.79z"
            />
            <path
              style="fill: #80ad28"
              d="M227.27,102.56c3.04-2.21,5.47-5.7,9.99-4.86c-0.43,4.3,0.63,8.31,2.3,12.21
            C233.47,110.8,230.63,106.25,227.27,102.56z"
            />
            <path
              style="fill: #26321a"
              d="M143.37,95.21c-5.4-3.28-4.88-6.75-0.33-10.34c4.46-2.99,8.95-7.94,13.18,0.26
            c1.96,2,4.06,3.88,5.84,6.03c3.56,4.3,0.92,9.05-1.17,12.12c-2.18,3.2-4.46-1.28-6.7-2.62C150.74,98.59,146.99,97,143.37,95.21z
            "
            />
            <path
              style="fill: #4e6b28"
              d="M156.23,85.13c-4.34-2.93-8.81,1.01-13.18-0.26c-0.88-0.79-1.76-1.59-2.64-2.38
            c-0.75-3.99,2.33-8.85-3.19-11.81c-2.36-1.27-5.29-4.03-3.52-6.75c2.07-3.17,5.87-3.75,9.32-1.02
            c5.15,1.72,10.3,3.44,15.45,5.16c-1.81,6.87,5.12,9.74,6.83,15C162.28,83.76,159.25,84.44,156.23,85.13z"
            />
            <path
              style="fill: #a7c229"
              d="M143.02,62.91c-1.95,0.09-3.91,0.17-8.3,0.37c6.4,4.76,15.9,3.62,15.69,11.78
            c-0.13,5.04-6.46,5.12-10,7.43c-2.97-1.4-5.94-2.79-8.91-4.18c-3.86,0.71-7.77,1.6-10.21-2.84c-1.6-0.95-3.2-1.91-4.8-2.86
            c-5.28-5.52-6.8-11.04,0-16.56c1.45-0.25,2.91-0.5,4.36-0.75c2.05-2.32,4.31-3.78,7.41-1.86
            C129.9,61.72,140.41,56.15,143.02,62.91z"
            />
            <path
              style="fill: #577d2e"
              d="M170.57,50.71c-9.67,4.29-19.44,7.29-29.8,2.19c-4.02-4.56-4.22-9.9-3.17-15.5
            c7.06-8.88,7.13-8.87,20.34,1.81c0.18-0.37,0.37-0.73,0.55-1.1c-1.56,2.11-3.02,0.52-2.93-0.7c0.51-6.73,5.04-7.53,10.54-6.59
            c6.59,2.48,7.54,8.49,8.88,14.25C174.86,47.98,173.53,49.99,170.57,50.71z"
            />
            <path
              style="fill: #77a231"
              d="M141.04,38.45c-0.74,4.81,3.61,9.7-0.26,14.45c-4.17,0.18-8.35,0.36-12.52,0.54
            c-2.29,1.33-4.62,2.51-7.41,1.86c1.39-9.22-11.7-6.53-11.86-14.46c5.92-2.15,11.69-4.88,18.21-4.71
            C132.17,34.72,136.99,34.3,141.04,38.45z"
            />
            <path
              style="fill: #9eb9ba"
              d="M170.57,50.71c0.77-1.59,1.55-3.19,2.32-4.78c2.66-11.78,4.54-12.81,12.27-6.69
            c0.49,5.38-3.73,8.75-5.6,13.13c-0.55,0.25-1.1,0.5-1.64,0.75c-1.61,0.09-3.21,0.17-4.82,0.26
            C172.26,52.49,171.42,51.6,170.57,50.71z"
            />
            <path
              style="fill: #82b128"
              d="M237.21,97.7c0.53-5.17,1.61-10.57-5.23-12.7c2.42-3.99-0.7-13.38,9.51-9.78
            c2.72-1.81,5.5-6.86,8.04,0.17c0.83,7.77-3.81,14.21-5.19,21.44C241.72,95.18,239.46,96.38,237.21,97.7z"
            />
            <path
              style="fill: #8da1bd"
              d="M237.64,60.69c-0.06,1.64-0.12,3.27-0.18,4.91c-2.22,1.06-6.19-0.09-5.93,4.14
            c0.5,7.86-5.81,4.24-9.13,5.64c0,0,0.08,0.22,0.08,0.22c-2.68-3.1-2.28-6.24-0.08-9.39C226.73,62.29,229.82,54.94,237.64,60.69z
            "
            />
            <path
              style="fill: #72a132"
              d="M249.53,75.39c-2.63-2.59-5.34-0.83-8.04-0.17c0.3-2.34,0.61-4.69,0.92-7.03
            C253.1,68.24,253.1,68.24,249.53,75.39z"
            />
            <path
              style="fill: #49672b"
              d="M193.13,70.45c0.13-6.18-4.25-11.49-10.12-12.27c-3.04-0.33-4.64-2.12-5.1-5.06
            c0,0-0.1-0.04-0.1-0.04c2.79-6.39,9.94-8.87,13.27-14.79c2.99-2.4,5.89-5.04,10.18-3c3.62,2.48,4.35,6.27,4.65,10.25
            c-1.46,3.77-1.83,11.98-0.66,15.17C205.46,69.25,200.31,71.12,193.13,70.45z"
            />
            <path
              style="fill: #3d5328"
              d="M205.26,60.71c-2.96-1.97-5.93-3.95-9.23-6.15c0.63-3.85,6.59-4.05,6.76-8.5
            c2.7-4.72,7-4.19,11.35-3.45c4.09,1.65,6.86,4.4,7.48,8.95c-0.07,7.32-7.04,5.93-10.83,8.56
            C208.95,60.32,207.11,60.52,205.26,60.71z"
            />
            <path
              style="fill: #243e2e"
              d="M225.67,67.83c-1.06,2.59-2.13,5.17-3.19,7.76c-2.58-0.84-6.25,1.08-7.54-3.01
            c2.53-3.14-2-6.3,0.02-9.44c1.44-2.05,3-3.91,5.83-3.84C225.37,60.45,226.26,63.72,225.67,67.83z"
            />
            <path
              style="fill: #a4bc6b"
              d="M220.88,60.94c-1.22,2.75-3.34,3.09-5.92,2.21c-0.21-2.64-2.1-2.97-4.18-3.02
            c-0.07-6.28,6.77-3.39,8.92-6.74C222.23,55.57,222.43,58.12,220.88,60.94z"
            />
            <path
              style="fill: #2b391b"
              d="M143.84-60.76c1.46,2.47,2.93,4.94,4.39,7.42c0.49,2.35,1.07,4.71-0.25,6.99
            c-8.17,7.67-20.28,10.17-26.62,20.26c-5.91,3.43-5.1,8.87-4.21,14.33c-0.46,3.42-2.49,5.46-5.66,6.51
            c-9.04-3.56-16.14-9-18.46-19.08c-1.34-2.42-2.02-4.94-1.18-7.7c2.2-4.09,5-6.61,9.81-3.62c5.28,0.08,10.17-2.72,8.17-7.65
            c-4.65-11.5,3.94-8.49,9.06-9.62c0.7,0.16,1.39,0.35,2.08,0.58c5.33,1.84,4.04-3.16,5.49-5.33c0.87-0.82,1.74-1.63,2.6-2.45
            c1.48-3.74,4.6-3.74,7.84-3.46c1.59,0.04,2.94-0.58,4.17-1.54l-0.12-0.01c1.57-1.64,3.51-1.84,4.75-0.06
            C147.05-63.25,145.64-61.75,143.84-60.76z"
            />
            <path
              style="fill: #618231"
              d="M76.85-65.05c2.08-4.65,5.24-8.91,5.21-14.33c-1.9-3.68-5.47-7.45,0.52-10.75
            c14.44,7.65,17.26,13.31,12.25,29.01c-1.38,4.32,0.11,7.97-0.57,11.86c-0.98,2.56-2.9,3.9-5.55,4.3
            c-10.99-1.62-8.59-11.62-11.44-18.33C77.13-63.88,76.99-64.46,76.85-65.05z"
            />
            <path
              style="fill: #a2afbd"
              d="M86.03-48.33c2.74-0.31,5.48-0.62,8.22-0.93c0.31,1.58,0.62,3.15,0.93,4.73
            c10.64-3.23,6.9,4.03,6.47,8.88c-2.89,0.87-5.91,1.47-7.89,4.1c-3.16,4.07-5.2,1.14-7.35-1.16
            C84.59-37.87,81.5-43.01,86.03-48.33z"
            />
            <path
              style="fill: #aac55d"
              d="M82.58-90.13c-0.17,3.58-0.35,7.17-0.52,10.75c-1.07-0.21-2.14-0.43-3.21-0.65
            c-2.22-2.16-1.96-5.64-4.15-7.82c0-1.44,0.01-2.88,0.01-4.32C77.55-92.3,80.55-93.1,82.58-90.13z"
            />
            <path
              style="fill: #33411d"
              d="M64.93-79.45c-1.22,2.39-0.01,7.83-4.58,5.97c-2.43-0.99-6.49-5.21-4.79-8.87
            c2.44-5.23,5.97-1.16,8.94,0.43C66.47-81.41,66.61-80.59,64.93-79.45z"
            />
            <path
              style="fill: #a4bc8a"
              d="M59.54-54.12c4.51-2.15,4.71-7.57,8.07-10.6c1.49,0.57,2.97,1.14,4.46,1.71
            c0.92,1.08,1.41,2.33,1.47,3.75c-1.5,5.54-3.04,11.05-10.43,11C60.43-49.31,57.47-50.19,59.54-54.12z"
            />
            <path
              style="fill: #404d1f"
              d="M64.93-79.45c-0.14-0.82-0.28-1.65-0.43-2.47c3.6-1.63,10.56,2.53,10.2-5.93
            c4.54,0.93,5.25,3.9,4.15,7.82C74.43-74.65,69.69-76.67,64.93-79.45z"
            />
            <path
              style="fill: #465b23"
              d="M71.42-60.38c0.21-0.88,0.43-1.75,0.64-2.63c1.6-0.68,3.19-1.36,4.79-2.04
            c0,0-0.07-0.15-0.07-0.15c1.09,1.2,2.22,2.56,2.1,4.17c-0.32,4.15,2.14,8.88-2.33,12.25c-1.35,1.01-3,1.72-4.35,0.59
            C67.78-51.89,71.02-56.26,71.42-60.38z"
            />
            <path
              style="fill: #9abb5b"
              d="M59.54-54.12c1.18,2.03,2.84,3.4,5.2,3.82c5.19,1.9,8.49,4.81,5.86,10.92
            c-2.73,2.79-5.57,4.3-8.82,0.72c-0.75-4.25-4.14-4.31-7.36-4.66c-0.23-2.67-0.6-5.33-0.65-8
            C53.69-56.19,56.24-56.02,59.54-54.12z"
            />
            <path
              style="fill: #3c4e2a"
              d="M52.09-21.4c-5.23,3.57-1.63,8.64-2.2,13c-2.21,3.41-4.73,6.85-9.36,5.59
            c-5.94-1.62-4.99-7.29-5.99-11.73c-3.07-5.98,4.44-7.38,5.09-11.75c0.68-4.52,5.64-3.92,7.87-6.59
            c0.74-0.13,1.48-0.09,2.21,0.11C54.57-29.84,56.46-26.27,52.09-21.4z"
            />
            <path
              style="fill: #27301b"
              d="M39.63-26.3c4.39,6.14-2.29,7.26-4.67,10.33c-0.62,0.82-1.42,1-2.36,0.63
            c-2.86-1.78-4.64-4.23-4.74-7.71C31.45-25.32,35.49-26.01,39.63-26.3z"
            />
            <path
              style="fill: #496731"
              d="M52.09-21.4c-0.8-3.79-1.59-7.58-2.39-11.38c4.02-1.96,8.05-3.92,12.07-5.89
            c3,0.83,5.15-1.2,7.59-2.27c5.85-1.06,8.5,2.96,11.15,7.01c0.36,8.76-6.72,14.8-8.39,22.83C63.33-10.41,54.56-9.76,52.09-21.4z"
            />
            <path
              style="fill: #577036"
              d="M32.6-15.33c0.77-0.26,1.56-0.47,2.36-0.63c6,0.5,3.96,16.9,14.93,7.56
            c-0.46,2.42,4.22,5.36,0.25,7.03c-8.39,3.52-2.32,7.12-0.61,10.8c-5.92-1.72-11.35,0.96-16.98,1.85
            c-0.35-3.31-0.87-6.53-4.84-7.51C27.13-3.16,27.35-9.89,32.6-15.33z"
            />
            <path
              style="fill: #899bac"
              d="M220.88,60.94c-0.39-2.52-0.78-5.04-1.18-7.55c-0.25-3.53-3.28-5.22-5.1-7.72
            c-2.23-3.52-2.8-7.38-2.37-11.47c0.79-2.38,2.25-4.04,4.85-4.45c10.04-2.58,13.67,6.39,19.79,10.93
            c3.07,1.83,5.25,4.31,5.69,8.01c2.31,1.5,4.62,3,6.94,4.5c-4.29,1.97-9.83,1.96-11.86,7.5c-5.28,0.21-8.25,4.32-11.97,7.14
            C224.07,65.54,222.48,63.24,220.88,60.94z"
            />
            <path
              style="fill: #72915b"
              d="M239.62-48.02c2.69-1.89,5.82-4.05,7.31,0.77c2,6.47-1.34,10.39-7.4,12.38
            C235.89-39.28,237.84-43.65,239.62-48.02z"
            />
            <path
              style="fill: #6c9064"
              d="M229.9-50.31c1.48,0.04,2.96,0.08,4.44,0.12c0.22,2.29,0.44,4.59,0.66,6.88
            c2.74,3.7,0,5.85-2.29,8.13c-0.26,0.56-0.45,1.13-0.58,1.73C221.73-37.79,234.12-45.15,229.9-50.31z"
            />
            <path
              style="fill: #485c2a"
              d="M232.71-35.17c0.76-2.71,1.53-5.42,2.29-8.13c1.54-1.57,3.08-3.14,4.63-4.71
            c-0.03,4.38-0.06,8.76-0.09,13.15C237.26-34.97,234.98-35.07,232.71-35.17z"
            />
            <path
              style="fill: #364b29"
              d="M269.02,18.68c-2.81-1.74-5.37-3.92-3.63-7.49c1.74-3.58,5.49-1.81,8.41-2.16
            c1.17-1.88,2.28-3.85,5.06-3.08C287.11,19.12,275.58,16.97,269.02,18.68z"
            />
            <path
              style="fill: #8ca4ae"
              d="M278.87,5.95c-1.69,1.03-3.38,2.05-5.06,3.08c-1.43-3.78-5.73-6.41-4.49-11.25
            c3,1.34,5.06-1.03,7.51-1.89C284.44-2.17,284.32,1.35,278.87,5.95z"
            />
            <path
              style="fill: #99ba61"
              d="M259.46,28.5c-0.8-0.69-1.61-1.38-2.41-2.07c-1.37-1.01-1.62-2.35-1.16-3.9
            c4.33-0.32,8.66-0.63,13-0.95c-1.76,4.83,0.83,10.62-3.29,14.93C257.49,38.49,262.82,30.15,259.46,28.5z"
            />
            <path
              style="fill: #667e3a"
              d="M259.46,28.5c4.98,0.4,1.42,7.36,6.13,7.99c1.43,5.36,0.03,8.57-6.15,8.17
            C256.59,39.27,260.83,33.89,259.46,28.5z"
            />
            <path
              style="fill: #bccbd5"
              d="M234.84,45.65c-10.08,1.54-6.41-7.05-8.07-11.77c-3.19,1.74-1.08,6.02-5.49,7.55
            c-1.26-3.28-2.6-6.76-3.94-10.24c-0.9-10.52,7.08-6.91,12.27-7.72c1.8-0.71,3.6-1.43,5.4-2.14c1.65-1.97,3.63-3.19,6.32-2.83
            c5.44,1.94,6.13,5.36,3.08,9.94c-2.41,0.78-2.06,2.99-2.49,4.8C239.3,37.23,237.47,41.67,234.84,45.65z"
            />
            <path
              style="fill: #779459"
              d="M229.62,23.48c-3.35,3.75-9.94,2.33-12.27,7.72c-0.67,1.32-1.34,2.64-2.01,3.96
            c1.2,2.29,0.26,3.66-2.01,4.11c-2.68,0.52-4.27-1.08-5.37-3.3c-0.88-5.07,2.05-10.11,0.14-15.19c0.03-4.88,1.9-9.83-0.49-14.65
            c1.16-6.65,7.36-1.41,10.11-4.3c2.33,0.09,6.63,0.53,5.24,2.81C217.14,14.22,225.54,17.93,229.62,23.48z"
            />
            <path
              style="fill: #4a6735"
              d="M237.41,3.98c0.72-0.7,1.43-1.39,2.15-2.08c1.8-0.45,3.71-1.93,5.22,0.53
            c1.93,4.57,5.92,8.6,3.79,14.26c-1.74,2.35-4,3.21-6.84,2.42c-3-2.24-4.17-4.92-1.99-8.4C238.96,8.46,238.18,6.22,237.41,3.98z"
            />
            <path
              style="fill: #89a691"
              d="M239.73,10.7c0.83,2.6,1.66,5.19,2.49,7.79c1.22,1.38,0.97,2.39-0.75,3.05
            c-2.15-0.07-4.31-0.13-6.46-0.2C234.91,17.05,232.86,11.89,239.73,10.7z"
            />
            <path
              style="fill: #63816a"
              d="M241.48,21.53c0.25-1.02,0.5-2.03,0.75-3.05c1.86-0.76,3.73-1.52,5.59-2.27
            c2.15-0.19,3.38,0.93,4.06,2.84c1.35,1.17,2.7,2.33,4.04,3.48c0.37,1.29,0.75,2.59,1.14,3.89c-2.92,8.74-7.5,7.11-12.63,2.02
            C242.77,26.43,244.5,22.97,241.48,21.53z"
            />
            <path
              style="fill: #91b296"
              d="M251.88,19.05c-1.35-0.95-2.71-1.89-4.06-2.84c-0.86-4.63-4.92-8.56-3.04-13.8
            c1.83,1.34,3.67,2.67,5.5,3.99C255.73,10,253.96,14.51,251.88,19.05z"
            />
            <path
              style="fill: #a8b9bd"
              d="M200.42-38.21c-3.45,3.51-6.69,7.02-0.71,11.13c-8.4,1.25-16.36,1.1-18.72-9.24
            c-1-4.38-3.6-6.04-7.22-7.23c-0.7-0.52-1.04-1.14-1.04-1.84c0-0.75,0.09-1.13,0.26-1.13c6.35-4.89,15.29-6.14,19.3-14.49
            c0.92-1.92,4.38-1.49,6.65,0.01c2.41,2.46,3.14,5.2,1.37,8.35C202.41-47.86,204.01-43.05,200.42-38.21z"
            />
            <path
              style="fill: #324921"
              d="M170.39-16.04c3.76,1.7,6.12-5.4,10.21-1.64c1.24,8.19-1.12,14.83-8.34,19.42
            c-1.19,0.52-2.43,0.75-3.73,0.74c-11.14-2.84-11.47-3.61-6.37-14.65C164-15.38,166.58-17.02,170.39-16.04z"
            />
            <path
              style="fill: #a1acb5"
              d="M175.93-32.85c4.55,8.18-4.53,8.58-7.19,12.64c-4.31,2.18-7.26,0.21-9.76-3.18
            c-2.46-6.61-0.7-12.84,1.73-19.02c0.73-0.95,1.64-1.69,2.73-2.2c6.6-1.35,7.66,3.62,9.19,8.06
            C173.52-35.14,174.99-34.22,175.93-32.85z"
            />
            <path
              style="fill: #9cad9a"
              d="M160.72-25.47c2.67,1.75,5.34,3.51,8.02,5.26c0.55,1.39,1.1,2.78,1.65,4.17
            c-1.62,1.65-3.24,3.3-4.87,4.95c-3.15,2.88-6.37,2.86-9.66,0.24c-5.92-5.38-9.04-11.38-4.25-19.14
            C157.29-33.85,158.5-28.63,160.72-25.47z"
            />
            <path
              style="fill: #577e2d"
              d="M170.67-1.01c3.31-5.56,6.62-11.12,9.92-16.67c0.76,0.2,1.52,0.29,2.3,0.26
            c1.61,0.98,2.33,2.38,2.03,4.27c0.1,3.29,0.19,6.59,0.29,9.88C180.81,0.37,176.52,4.68,170.67-1.01z"
            />
            <path
              style="fill: #d2d9ea"
              d="M175.93-32.85c-2.65,0.7-3.78,5.7-7.73,2.76c1.97-4.61,1.66-9.22,0.07-13.85
            c-1.56-4.55,0.15-6.78,4.86-6.88c1.33,1.72,1.46,3.45,0.07,5.21c-0.24,0.8-0.05,1.49,0.56,2.06
            C175.19-40.13,178.08-37,175.93-32.85z"
            />
            <path
              style="fill: #2e3e1d"
              d="M184.92-13.15c-0.67-1.43-1.35-2.85-2.02-4.28c4.67-4.07,8.79-2.95,12.55,1.37
            C192.46-13.22,188.62-13.44,184.92-13.15z"
            />
            <path
              style="fill: #b3c4c8"
              d="M188.12,1.32c1.3,0.01,2.59,0.02,3.89,0.03c1.12,0.15,1.8,0.8,2.11,1.87
            c-0.03,4.1-3.61,6.23-5.17,9.5c-2.63,2.17-2.77,6.02-5.44,8.18c-2.18,1.62-4.52,2.19-7.13,1.17c-5.34-3.42-5.51-8.95-5.9-14.41
            C173.82-1.52,181.63,1.73,188.12,1.32z"
            />
            <path
              style="fill: #90b547"
              d="M188.12,1.32c-4.24,4.25-11.92,1.01-15.33,7.06c-1.02,1.79-2.65,2.33-4.59,2.21
            c-1.92-0.48-3.2-1.66-3.8-3.56c-0.54-4.73,3.29-6.06,6.26-8.05c0,0,0,0,0,0c4.85-0.75,9.7-1.51,14.54-2.26
            C186.18-1.74,187.15-0.21,188.12,1.32z"
            />
            <path
              style="fill: #85a256"
              d="M192.29,3.35c-0.09-0.66-0.18-1.33-0.27-2c4.82-0.78-1.22-9.04,5.74-8.36
            c3.42,3.98,6.65,8.07,7.59,13.46c-0.5,0.55-1,1.11-1.5,1.66C198.6,9.93,193.39,11.63,192.29,3.35z"
            />
            <path
              style="fill: #bfcccb"
              d="M207.6,6.13c13.47-0.25,5.26,7.5,4.59,12.47c-1.71,4.2-1.6,9.55-7.14,11.34
            c-6.41-0.44-8.38-4.22-6.98-9.96c0.86-3.52,1.6-7.09,2.87-10.51c1.13-1.53,2.82-2.1,4.54-2.61c0,0-0.14-0.4-0.14-0.4
            C206.12,6.47,206.87,6.36,207.6,6.13z"
            />
            <path
              style="fill: #bec9d4"
              d="M200.32-52.66c-0.9-2.54-1.79-5.07-2.69-7.61c-2.65-2.14-7.16-3.1-5.16-8.18
            c2.16-2.44,4.39-4.53,7.59-1.41c7.37,2.33,8.36,7.98,7.56,14.56C205.8-52.7,203.09-52.59,200.32-52.66z"
            />
            <path
              style="fill: #2d3519"
              d="M123.88-78.03c0.79,0.25,1.57,0.51,2.36,0.76c4.28,0.91,8.75,1.57,9.54,7.14
            c0.12,6.75-6.35,6.74-9.93,9.71c-6.48-3.2-9.31-7.66-5.03-14.58C121.84-76.01,122.86-77.02,123.88-78.03z"
            />
            <path
              style="fill: #455c27"
              d="M126.46-57.67c3.54,3.06,3.08,6.04-0.05,9.11c-1.5,1.47-3.11,2.95-5.15,1.6
            c-2.18-1.44-0.91-3.54-0.29-5.38C122.8-54.11,124.63-55.89,126.46-57.67z"
            />
            <path
              style="fill: #80a52b"
              d="M125.84-60.42c0.08-5.39,6.38-5.39,8.09-9.38c4.01,3.01,7.12,6.35,4.44,11.87
            c-3.1-0.73-6.21-1.46-9.31-2.19C127.99-60.22,126.92-60.32,125.84-60.42z"
            />
            <path
              style="fill: #475b22"
              d="M138.37-57.93c-1.48-3.96-2.96-7.91-4.44-11.87c-2.08-2.99-7.07-2.99-7.7-7.47
            c3.36-3.12,7.66-2.68,11.73-3.1c5.61,4.14-0.33,10.66,3.11,15.25C140.99-62.41,141.17-59.61,138.37-57.93z"
            />
            <path
              style="fill: #637e44"
              d="M193.07-67.76c1.52,2.5,3.04,4.99,4.56,7.49c-1.1,0.43-3.15,0.82-3.17,1.3
            c-0.64,14.83-11.21,13.66-21.25,13.36c-0.18-1.52-0.36-3.05-0.54-4.58c-1.21-1.74-1.05-3.45,0.11-5.13
            c2.24-1.98,2.72-4.68,3.06-7.44c4.19,3.1,6.43,1.62,7.29-3.12c1.31-1.3,2.86-1.95,4.72-1.73c0.59-0.23,1.19-0.42,1.8-0.57
            C190.9-68.93,192.04-68.8,193.07-67.76z"
            />
            <path
              style="fill: #4a5d2c"
              d="M160.72-25.47c-2.3-1.54-4.6-3.08-6.9-4.63c-5.67,0.13-7.84-3.73-9.44-8.26
            c-1.48-3.28-0.32-6.24,1.14-9.15c0.9-1.94,1.8-3.89,2.7-5.84c2.73-0.56,5.46-1.12,8.19-1.67c4,3.95,0.49,11.41,6.7,14.32
            C162.37-35.62,163.46-30.24,160.72-25.47z"
            />
            <path
              style="fill: #949f7a"
              d="M163.12-40.7c-10.59,0.7-12.3-2.95-6.7-14.32c5.61-2.96,5.23,2.49,6.97,4.97
            c1.86,2.4,1.87,4.72-0.34,6.92C163.12-42.32,163.15-41.51,163.12-40.7z"
            />
            <path
              style="fill: #58693d"
              d="M168.13-87.23c7.2-0.47,13.05,4.4,19.95,5.1c1.75,2.15,2.27,4.59,1.68,7.28
            C180.79-75.89,173.41-79.73,168.13-87.23z"
            />
            <path
              style="fill: #57742a"
              d="M163.74-82.22c6.3,6.03,6.22,12.64,4.87,19.52C160.29-70.47,160-71.53,163.74-82.22z"
            />
            <path
              style="fill: #817f8d"
              d="M163.05-43.12c0.11-2.31,0.22-4.62,0.33-6.92c4.29,0.3,4.79-6.16,9.4-5.27
            c-0.04,1.71-0.08,3.43-0.11,5.14c-4.62,1.36-3.07,4.36-2.55,7.68c0.65,4.16,2.19,8.84-1.92,12.41
            C167.08-34.67,171.07-41.28,163.05-43.12z"
            />
            <path
              style="fill: #718b2e"
              d="M143.84-60.76c0.72-2.57,0.26-4.35-2.88-4.36c1.9-3.3,2.24-7.53,7.15-11.02
            C146.85-69.99,157.08-62.93,143.84-60.76z"
            />
            <path
              style="fill: #a9bab3"
              d="M184.94-64.94c-0.91,4.05-1.82,8.09-2.74,12.18c-5.71-1.8-2.56-7.89-6.36-10.01
            c-0.26-0.78-0.31-1.57-0.17-2.38C178.82-67.72,181.92-68.41,184.94-64.94z"
            />
            <path
              style="fill: #58693d"
              d="M184.94-64.94c-3.09-0.07-6.18-0.13-9.26-0.2c2.3-9.55,6.58-9.29,12.19-2.47
            C186.88-66.72,185.91-65.83,184.94-64.94z"
            />
            <path
              style="fill: #5c6b49"
              d="M193.07-67.76c-1.14-0.14-2.27-0.28-3.41-0.42c0.16-1.38,0.32-2.77,0.48-4.15
            c4.26-0.31,4.9-7.22,10.22-5.6c0.68,2.72,3.44,5.52-0.3,8.07C197.73-69.16,195.4-68.46,193.07-67.76z"
            />
            <path
              style="fill: #6f8985"
              d="M234.84,45.65c-3.98-7.76-3.59-13.03,7.09-12.41c1.17,5.1,8.71,9.92,0.66,15.44
            C239.99,47.68,237.42,46.67,234.84,45.65z"
            />
            <path
              style="fill: #3e5a38"
              d="M212.27-69.96c1.68-0.88,3.37-1.75,5.05-2.63c6.92,1.63,3.67,6.06,2.71,9.86
            c-0.13,1.13-0.26,2.26-0.39,3.39C206.8-55.66,211.57-64.23,212.27-69.96z"
            />
            <path
              style="fill: #8fa766"
              d="M214.95-79.98c-0.83-2.49-1.65-4.98-2.48-7.46c15.71-5.93,7.8,7.18,9.87,12.26
            C217.81-73.59,217.45-78.44,214.95-79.98z"
            />
            <path
              style="fill: #657c46"
              d="M214.95-79.98c3.48,0.03,4.86,3.3,7.39,4.79c-0.49,4.21,3.84,9.31-2.32,12.46
            c0.06-3.55,0.14-7.11-2.71-9.86C216.42-75.02,212.72-76.55,214.95-79.98z"
            />
            <path
              style="fill: #3e5723"
              d="M121.29,75.47c3.66,0.01,7.33-0.02,10.2,2.83c-5.7,4.42-1.93,11.73-5.14,16.91
            c-2.22-2.81-3.85-6.21-7.53-7.56c0.74-2.23,2.17-4.36-0.29-6.6C113.2,76.21,118.98,76.66,121.29,75.47z"
            />
            <path
              style="fill: #98ba20"
              d="M116.48,56.05c2.27,5.52-2.63,11.04,0,16.57c-2.53-0.63-5.05-1.27-7.58-1.9
            c-0.79,0.86-1.56,0.84-2.31-0.06C106.18,63.26,102.41,53.6,116.48,56.05z"
            />
            <path
              style="fill: #638631"
              d="M128.49,41.02c-6.5-0.06-13-0.13-19.5-0.19c2.45-2.49,4.9-4.98,7.35-7.47
            c0.09-2.26,0.18-4.51,0.27-6.77c4.46-4.42,8.28-1.59,11.33,1.6C131.6,32,132.79,36.56,128.49,41.02z"
            />
            <path
              style="fill: #678252"
              d="M86.64,43.75c0.1-0.63,0.19-1.27,0.29-1.9c5.15-6.33-1.73-9.89-4.18-15.54
            c9.3-0.28,10.03,6.32,11.8,11.88c-0.05,1.34-0.09,2.68-0.14,4.01c-0.16,3.05-1.89,4.19-4.77,3.94
            C88.64,45.35,87.64,44.55,86.64,43.75z"
            />
            <path
              style="fill: #99b47a"
              d="M89.63,46.15c1.59-1.31,3.19-2.62,4.78-3.93c4.17,4.05,1.22,9.14,1.99,13.69
            C88.57,56.53,90.26,50.53,89.63,46.15z"
            />
            <path
              style="fill: #36491e"
              d="M106.6,70.65c0.77-0.15,1.54-0.13,2.31,0.06c2.18,10.5-5.93,8.69-11.49,10.02
            C99.65,76.5,104.64,75.02,106.6,70.65z"
            />
            <path
              style="fill: #364620"
              d="M106.47,99.92c2.49-0.57,5.49-2.94,6.69,1.53c0.7,2.61-0.47,4.68-2.94,5.78
            c-2.19,0.98-4.87,2-6.56-0.23c-2.47-3.26,1.57-4.67,2.67-6.93C106.33,100.07,106.47,99.92,106.47,99.92z"
            />
            <path
              style="fill: #79a72a"
              d="M35.15,48.69c2.11-0.42,4.34-0.62,5.48-2.9c2.33-0.59,3.67,0.43,4.25,2.65
            c1.47,2.74,2.94,5.47,4.54,8.44C34.27,52.19,34.27,52.19,35.15,48.69z"
            />
            <path
              style="fill: #a0b91c"
              d="M44.89,48.44c-1.42-0.88-2.84-1.77-4.25-2.65c-0.52-2.2-0.42-4.2,1.72-5.57
            c1.5-0.98,2.97-2.05,4.88-2.05C55.09,43.57,51.92,46.45,44.89,48.44z"
            />
            <path
              style="fill: #3b471d"
              d="M47.23,38.17c-1.63,0.68-3.26,1.37-4.88,2.05c0.75-4.07,1.5-8.15,2.28-12.38
            c4.18,1.3,5.23,5.09,7.61,7.54C50.57,36.31,48.9,37.24,47.23,38.17z"
            />
            <path
              style="fill: #3d5325"
              d="M128.49,41.02c-0.96-7.29-3.57-13.21-11.88-14.43c3.71-8.29-4.95-8.8-8.04-12.81
            c-3.22-1.61-3.2-1.86,0.49-4.99c0.79-0.13,1.61-0.46,2.37-0.37c10.73,1.31,10.73,1.32,17.19-4.42c1.74,0.53,2.84,1.72,3.54,3.34
            c1.66,5.45,5.6,5.11,9.52,3.28c5.11-2.38,9.23-0.88,13.22,2.3c1.32,1.02,2.48,2.22,3.68,3.36c4.18,2.86,9.42,4.7,10.19,10.79
            c0.08,2.56-0.67,4.73-2.95,6.15c-1.7,0.53-0.94,1.03-4.16,5.9c-2.63,3.97-6.86,1.45-10.35-0.61c-3.29-1.95-6.83-7.54-10.27-0.06
            C136.86,39.3,132.67,40.16,128.49,41.02z"
            />
            <path
              style="fill: #29381c"
              d="M128.75,8.88c-0.04-1.63-0.08-3.26-0.12-4.89c-1.71-2.73-3.43-5.47-5.14-8.2
            c-1.86-4.03-0.74-7.66,1.45-11.17c1.83-2.2,4.17-3.58,6.94-4.18c4.82-1.43,1.49-4.68,1.52-7.19
            c0.64-8.98,4.32-14.82,14.44-13.94c0.77,4.22,3.59,7.28,5.99,10.57c-3.32,6.52,1.67,11.21,3.62,16.57
            c0.53,10.7-9.73,14.48-14.08,22.07C138.58,12.05,133.71,12.66,128.75,8.88z"
            />
            <path
              style="fill: #496828"
              d="M96.7-25.47C99-17.07,108.71-14.21,111.27-6c3.97-1.92,8.05-4.46,10.37,2.04
            c-0.4,0.04-0.9-0.07-1.2,0.13c-13.62,8.74-20.59-2.34-28.83-9.66c-2.81-1.88-4.19-4.48-3.99-7.89
            C89.14-26.07,92.71-26.24,96.7-25.47z"
            />
            <path
              style="fill: #4b6426"
              d="M72.01,6.33c1.56-3.23,3.11-6.46,4.67-9.69c5.22-1.41,10.15-2,12.46,4.56
            c1.13,3.33,2.38,6.62,3.28,10c0.16,0.6-1.12,1.59-1.74,2.4c-0.51-1-1.03-1.99-1.54-2.99c-3.6-1.65-5.55,0.53-7.3,3.16
            C76.85,13.56,76.99,6.55,72.01,6.33z"
            />
            <path
              style="fill: #7cad34"
              d="M89.14,1.2C85.58-1.93,79.5,1.8,76.68-3.36c-0.79-2.77-0.32-4.86,2.93-5.45
            c1.69-5.39,0.48-13.05,9.76-12.48c2.94,1.98,2.11,5.03,2.24,7.82c-1.82,1.21-2.12,3.05-2.08,5.04
            c-0.11,2.55,1.94,5.02,0.28,7.63C89.55-0.16,89.33,0.51,89.14,1.2z"
            />
            <path
              style="fill: #7ca232"
              d="M121.63-3.96c-3.11-2.44-6.88-1.5-10.37-2.04c1.15-2.26,3.29-4.2,2.33-7.15
            c-0.74-3.7,1.71-4.76,4.54-5.51c3.86-0.04,6.6,1.64,8.06,5.28c3.03,4.22-1.12,6.32-2.71,9.18
            C122.88-4.02,122.26-3.94,121.63-3.96z"
            />
            <path
              style="fill: #72a22d"
              d="M109.06,8.79c0.03,1.68-2.13,3.15-0.49,4.99c-1.24,3.12-2.63,6.73-6.63,5.99
            c-3.35-0.62-1.41-4.23-1.57-6.28C99.77,6.03,106.11,10.34,109.06,8.79z"
            />
            <path
              style="fill: #496828"
              d="M89.81-0.82c-1.14-2.5-3.33-4.97-0.28-7.63c4.42,2.06,8.9,4.07,8.16,11.02
            C94.71,1.29,92.26,0.23,89.81-0.82z"
            />
            <path
              style="fill: #769d32"
              d="M81.84,13.77c-0.83-2.52-3.84-5.91-0.78-7.42c2.79-1.37,7.17-0.55,8.08,4.27
            C86.71,11.66,84.27,12.71,81.84,13.77z"
            />
            <path
              style="fill: #31481f"
              d="M89.37-21.3c-3.85,3.69-3.07,11.02-9.76,12.49c-2.5-0.76-5-1.52-7.49-2.28
            c-12.06-12.25,2.07-15.45,7.47-21.67c2.14-4.14,5.21-4.97,9.18-2.59c0.7,2.54,2.28,3.93,5.01,3.8c0.98,2.03,1.95,4.05,2.93,6.08
            C94.26-24.08,91.81-22.69,89.37-21.3z"
            />
            <path
              style="fill: #7b9889"
              d="M165.82,33.22c0.04-2.67,0.09-5.33,0.13-8c0.05-2.66,1.9-2.69,3.83-2.61
            c4.8,3.78,11.98,0.14,16.34,5.28c2.76,5.29,5.47,10.58-3.3,12.92c-3.41-2.61-3.41-2.61-9.93,5.12
            C170.54,41.69,168.18,37.45,165.82,33.22z"
            />
            <path
              style="fill: #5c7e55"
              d="M182.83,40.8c3.86-4.09,2.02-8.01-0.34-11.92c0.16-4.65,2.83-6.89,7.19-7.51
            c1.27,0.03,2.47,0.39,3.56,1.02c0.93,0.77,1.65,1.73,2.2,2.82c2.04,5.71,0.72,10.92-2.48,15.84
            c-0.74,9.44-9.85,8.34-15.14,12.04C177.94,48.36,178.25,43.71,182.83,40.8z"
            />
            <path
              style="fill: #a1b7a7"
              d="M192.96,41.04c-3.05-5.07-0.15-10.25-0.26-15.37c5.41-4.05,7.32,4.65,11.99,3.29
            c0.9-4.62,5.13-6.81,7.5-10.36c-0.01,5.37,1.9,11-2.19,15.8c-3.01,2.72-6.31,2.69-9.78,1.02
            C197.81,37.29,195.38,39.17,192.96,41.04z"
            />
            <path
              style="fill: #6d8751"
              d="M200.23,35.42c3.01-2.75,6.46-1.23,9.78-1.02c1.33,3.38,3.19,3.06,5.33,0.76
            c3.52,3.76,0.92,7.11-0.73,10.51c-3.94,0.13-7.88,0.26-11.82,0.39C199.43,43.12,200.82,39.03,200.23,35.42z"
            />
            <path
              style="fill: #14170f"
              d="M147.84-40.67c-3.33,5.43-10.92,7.53-11.71,14.96c0.88,2-0.26,3.12-1.77,4.08
            c-4.61,1.9-8.28-3.19-12.84-1.7c-1.1-0.28-2.05-0.83-2.88-1.59c-0.58-1.03-0.57-2.06,0.04-3.08
            c5.11-11.81,14.36-17.91,26.86-19.51C146.3-45.23,147.07-42.95,147.84-40.67z"
            />
            <path
              style="fill: #62802f"
              d="M118.67-28c0.11,0.75,0.19,1.5,0.23,2.25c0.08,3.14,2.34,6.22,0.3,9.43
            c-2.26,0.36-4.37,0.99-5.6,3.18C110.38-26.26,110.74-27.31,118.67-28z"
            />
            <path
              style="fill: #6f9a31"
              d="M88.77-35.35c-3.06,0.86-6.12,1.72-9.18,2.59c-5.07-0.63-8.4-3.46-10.22-8.17
            c0.49-4.13-3.71-5.94-4.62-9.37c2.23-3.36,4.46-6.72,6.68-10.08c1.37,3.12-1.17,7.27,3.33,10.63
            c0.74-5.62,1.38-10.53,2.02-15.44c9.62,2.02,4.63,12.09,9.25,16.86C84.81-43.55,93.86-40.95,88.77-35.35z"
            />
            <path
              style="fill: #577e2d"
              d="M140.84,6.12c2.21-9.36,10.43-13.64,16.61-19.64c2,3.11,5.11,2.54,8.07,2.44
            c-2.32,5.42-4.93,11,5.14,10.08c-1.65,2.44-3.3,4.89-4.95,7.33c-4.89,3.02-9.58,6.7-15.96,4.74
            C146.55,9.83,142.19,10.69,140.84,6.12z"
            />
            <path
              style="fill: #b7c2ac"
              d="M167.99,23.76c-0.68,0.49-1.36,0.97-2.04,1.46c-3.36-3.19-10.54-1.11-11.13-8.11
            c-0.14-9.38,8.98-5.21,12.82-8.77c1.72,0.01,3.44,0.03,5.16,0.04c1.81,4.13,3.61,8.26,5.42,12.39
            C175.88,25.46,172.03,24.93,167.99,23.76z"
            />
            <path
              style="fill: #6c8c2f"
              d="M167.99,23.76c3.41-1,6.82-1.99,10.23-2.99c1.51-0.72,3.01-1.45,4.52-2.17
            c1.75-2.96,4.1-4.05,6.88-1.53c1.93,1.75,2.9,3.95,0,5.92c-2.37,1.96-4.75,3.93-7.13,5.89
            C177.37,27.97,171.17,30.16,167.99,23.76z"
            />
            <path
              style="fill: #aebdca"
              d="M189.62,22.99c0.44-5.75-3.94-3.93-6.88-4.39c-1.78-3.82,0.52-6.45,2.66-9.12
            c4.23-4.21,8.54-1.79,12.85-0.17c6.53,8.18,0.48,11.63-5.65,15.05C191.6,23.89,190.61,23.44,189.62,22.99z"
            />
            <path
              style="fill: #85ac6a"
              d="M197.14,11.83c-3.31-3.8-7.51-3.12-11.73-2.36c-0.55-5.25,5.62-2.92,6.88-6.12
            c3.67,3.9,8.69,2.73,13.2,3.5c-0.89,0.69-1.78,1.38-2.67,2.08C201.45,10.93,199.41,11.62,197.14,11.83z"
            />
            <path
              style="fill: #a0bd68"
              d="M167.64,8.34c1.52,11.38-10.56,2.92-12.81,8.79c-0.51-0.76-1.01-1.49-1.51-2.23
            c-1.45-1.55-3.3-2.97-1.84-5.5c4.34-2.93,8.18-8.13,14.25-3.09C166.36,6.99,167,7.66,167.64,8.34z"
            />
            <path
              style="fill: #85a256"
              d="M197.14,11.83c1.89-0.97,3.78-1.94,5.67-2.9c2.45,3.51,1.43,6.77-0.71,9.95
            c-2.92,4.34-1.24,7.49,2.6,10.08c-5.67,5.02-7.69-3.33-12-3.29c-0.08-0.44-0.12-0.88-0.1-1.33
            C194,20.13,199.99,17.59,197.14,11.83z"
            />
            <path
              style="fill: #77a32d"
              d="M151.47,9.41c0.62,1.84,1.23,3.68,1.84,5.5c-3.28-0.21-5.73-2.42-10.01,0.21
            c-5.58,3.42-13.65,4.97-14.55-6.23c4.03-0.92,8.06-1.84,12.1-2.77C144.38,7.21,147.93,8.31,151.47,9.41z"
            />
            <path
              style="fill: #35491f"
              d="M119.2-16.32c-5.11-2.98-6.6-6.08-0.3-9.43c0.75,0.01,1.51-0.01,2.26-0.06
            c3.66,1.36,7.56,2.08,10.9,4.27c1.8,1.67,3.15,3.42,1.1,5.81c-2.32,0.78-4.64,1.57-6.96,2.35
            C123.49-13.47,121.08-14.26,119.2-16.32z"
            />
            <path
              style="fill: #5b812b"
              d="M133.16-15.73c-0.77-1.55-1.53-3.1-2.3-4.65c0.13-1.77,1.67-2.18,2.83-2.96
            c0.81-0.79,1.63-1.58,2.44-2.38c5.93,0.33,9.14,2.17,4.32,8.14C138.56-15.22,137.9-7.1,133.16-15.73z"
            />
            <path
              style="fill: #78a32b"
              d="M133.69-23.34c-0.94,0.99-1.88,1.97-2.83,2.96c-4.09-0.28-10.32,3.28-9.7-5.43
            C126.58-31.27,129.79-25.54,133.69-23.34z"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_14_">
        <defs>
          <path
            id="XMLID_579_"
            d="M62.39,135.27H0V60.09l71.87-51.1l71.7,50.98v75.18H79.3l0.02-37.06l27.89-0.02v-16.6L71.87,56.69
          L37.92,81.51v15.58l24.54,0L62.39,135.27z M13.81,121.45H48.6l0.02-10.56l-24.51,0V74.49L71.7,39.7l49.33,34.59v37.59l-27.9,0.02
          l-0.01,9.44h36.63V67.1L71.87,25.94L13.81,67.22V121.45z"
          />
        </defs>
        <clipPath id="XMLID_00000000213345154638722860000006681817120288470446_">
          <use xlink:href="#XMLID_579_" style="overflow: visible" />
        </clipPath>
        <g id="XMLID_3_" style="clip-path: url(#XMLID_00000000213345154638722860000006681817120288470446_)">
          <g>
            <path
              style="fill: #181c13"
              d="M-402.78,399.54c-81.31,0-162.62-0.23-243.92,0.26c-11.66,0.07-16.47-1.33-15.93-15.01
            c1.29-32.91,0.42-65.9,0.42-98.87c15.06-6.66,19.61,0.66,19.28,14.47c-2.84,13.06,4.23,19.65,14.97,14.31
            c17.56-8.73,32-3.5,47.1,4.17c4.25,1.32,8.52,1.32,12.81,0c11.97-3.28,17.04,4.22,21.04,13.4c5.4,6.89,12.58,8.51,20.83,6.98
            c11.67,4.36,16.27-0.55,15.88-12.07c-0.4-11.51-0.24-22.88,8.22-32.12c1.37-2.4,2.69-4.84,4.74-6.75
            c8.89-4.95,12.1-12.01,8.01-21.78c-0.22-1.81-0.23-3.63-0.12-5.44c0.13-1.81,0.57-3.54,1.22-5.22c3.8-4.07,3.4-9.63,4.95-14.5
            c1.5-4.33,1.46-8.83,1.42-13.34c4.33-10.46-7.73-26.11,7.57-32.19c13.4-5.33,20.39,9.87,30.72,14.96
            c5.78,2.85,10.84,7.57,15,12.85c1.14,2.8,1.19,5.62,0.17,8.46c-9.47,5.98-5.72,16.98-9.47,25.13c-0.41,2.83-3.39,5.18-1.59,8.43
            c0.52-3.8,1.59-7.47,2.49-11.19c3.07-5.76-0.68-13.54,5.4-18.41c11.11-2.77,18.03,4.15,19.26,12.83
            c3.2,22.5,22.14,40.88,16.58,65.46c-0.61,2.69,0.59,6.54,3.3,6.52c13.07-0.13,25.75,8.81,39.13,1.39
            c17.46-9.69,17.3-23.2-0.85-44.61c0.86-14.5-9.75-30.46,5.4-43.17c5.79-2.6,10.85,0.09,16,2.19c3.76,2.29,6.1,5.75,7.91,9.64
            c0.2,8.6,1.7,16.89,6.12,24.46c8.43,10.78,5.67,22.86,4.05,34.8c3.36,9.53,2.94,18.14-5.17,25.48
            c-5.66,5.12-9.18,11.42-7.33,19.55c1.35,7.61,7.61,14.37,3.96,22.85c-4.36,6.97-10.39,9.77-18.46,7.37
            c-2.76-2.13-2.85-5.75-4.66-8.44c-0.73-1.09-1.3-2.27-1.88-3.45c0.35,1.39,1.45,2.51,1.72,3.87
            c3.1,15.11-7.73,11.64-15.82,11.84c-10.44-2.98-18.62-10.22-29.31-17.11C-394.2,379.74-391.28,392.63-402.78,399.54z"
            />
            <path
              style="fill: #1b2115"
              d="M6.65,399.54c-44.59,0-89.18,0-133.77,0c-3.66-8.82-15.06-6.82-19.81-14.13
            c-9.63-15.57-3.44-26.12,10.69-33.98c-0.8-2.16-2.66-2.31-4.57-2.48c-27.3-1.09-51.58-24.56-53.04-51.17
            c-0.05-12.31-13.65-18.28-14.21-30.36c-5.99-12.26-7.21-22.78,9.73-26.65c7.87,0.02,12.75,5.72,18.49,9.77
            c6.72,3.4,7.29,10.03,9.9,16.22c4.14,9.81,7.23,2.2,9.53-2.57c2.38-4.07,6.43-6.64,9.18-10.35c5.47-2.81,7.15-8.32,9.47-13.39
            c1.38-1.45,3-2.53,4.86-3.25c23.48,3.74,30.37,15.21,21.08,36.38c-1.62,3.69-2.84,7.1-3.04,10.78
            c6.06,1.56,10.56-6.53,16.83-2.67c8.89,4.12,8.59,14.6,14.45,20.68c0.04-0.19,1.11,1.35,1.11,1.35s1.47-0.18,1.38-0.45
            c-14.23-19.33-18.13-43.3-30.21-63.41c-2.38-3.97-0.25-9.25,3.39-12.96c5.33-4.05,11.05-3.8,16.94-1.55
            c11,3.18,22.44-0.97,26.56-8.97c4.11-7.99-5.39-16.8-10.74-24.43c-2.54-5.16-1.54-9.69,2.36-13.71c1.55-1.23,3.3-2.11,5.2-2.69
            c10.45-0.2,20.38,1.94,29.6,7.05c21.84,3.44,23.75,5.79,24.36,29.69c3.86,10.54,1.99,20.32-3.86,29.56
            c-2.3,4.72-3.51,9.56-1.62,14.75c1.08,5.1,3.67,9.14,8.37,11.65c19.02,6.97,25.17,20.79,21.27,39.98
            c-5.14,9.24-9.56,18.04-4.51,29.48c3.73,8.44,2.16,17.02-8.36,20.61c0.27,0.11-1.4,1.23-1.07,1.34
            c-0.39,4.77,3.39,7.97,4.28,12.12c2.58,5.85,7.64,9.75,11.37,14.7c1.42,2.59,1.95,5.34,1.5,8.27
            C9.01,396.55,8,398.16,6.65,399.54z"
            />
            <path
              style="fill: #202817"
              d="M-662.22,156.06c0-13.53,0-27.05,0-40.58c18.91-4.09,26.53-15.62,22.74-34.68
            c2.76-24.13,13.86-29.47,33.01-15.95c3.63,5.52,9.28,9.93,9.88,16.97c0.35,0.59,0.9,1.23,1.8,1.27
            c5.48-5.76,11.48-10.31,18.68-13.18c3.07-0.64,6.07-0.39,9,0.72c11.93,5.03,26.14,6.85,31.05,21.84
            c6.2,24.99,18.41,46.09,39.65,61.39c3.87,2.79,6.05,6.97,8.18,11.13c0.75,1.78,1.13,3.64,1.22,5.57
            c-1.36,12.96-9.56,21.36-20.97,24.44c-14.1,3.81-16.07,11.41-12.8,23.87c1.77,6.75,4.65,14.7-3.87,19.9
            c-7.6,1.41-14.12-2.12-16.38-8.31c-7.06-19.37-20.06-32.61-38.08-41.63c-1.55-13.12,4.61-20.34,17.21-22.62
            c14.67-6.29,9.67-14.42,2.08-22.68c-4.46-0.44-8.19,1.58-11.98,3.39c-3.21,1.89-6.44,3.68-10.34,3.5
            c-15.04-2.78-29.66-2.09-43.53,5.11C-637.81,157.75-649.91,165.66-662.22,156.06z"
            />
            <path
              style="fill: #313f1c"
              d="M-337.92-509.43c16.21,0,32.43,0,48.64,0c3.57,8.08,10.35,8.91,17.89,7.85
            c9.53-1.34,14.49,3.7,17.2,11.98c2.25,3.15,5.41,3.43,8.89,2.76c11.1-1.11,16.68-10.36,24.38-16.46
            c1.73-0.81,3.54-1.34,5.42-1.67c13.67,10.67,27.21,3.65,40.78-0.3c6.74-0.11,13.8-0.32,16.19,7.86
            c2.21,7.56-2.38,13.3-7.91,16.68c-11.12,6.8-17.49,17.7-25.8,26.91c-1.58,1.21-3.35,2.08-5.27,2.59
            c-12.77,0.31-25.14-6.17-38.15-2.33c-3.72,0.88-7.21,0-10.61-1.44c-3.29-1.42-6.53-3.01-10.08-3.77
            c-9.94-4.14-19.6-9.07-30.31-11.11c-5.36-0.38-12.11-2.18-15.47,2.07c-13.63,17.27-35.01,27.58-43.8,49.12
            c-1.39,1.54-3.01,2.69-4.89,3.53c-8.16,1.39-16.3,1.87-24.39-0.32c-5.4-2.06-8.11-10.43-15.96-6.77
            c-10.09,4.79-16.37,14.75-26.53,19.44c-10.12,5.6-17.67,0.32-25.03-5.86c-2.48-3.34-4.27-7.06-5.91-10.86
            c-1.01-7.07-5.27-13.57-3.7-21.1c0.86-3.05,2.54-5.55,5-7.52c5.5-1.88,10.21-5.11,14.5-8.99c21.42-9.21,21.42-9.21,17.44-37.86
            c2.01-2.36,4.63-3.54,7.62-4.01c2.93-0.29,5.86-0.43,8.78-0.73c8.24-2.69,17.03-0.86,25.34-3.06
            C-362.71-510.15-347.98-497.14-337.92-509.43z"
            />
            <path
              style="fill: #242c19"
              d="M6.65,399.54c-0.03-1.31-0.04-2.62-0.02-3.94c6.73-16.76,21.27-20.9,37.09-22.95
            c0.42-0.05,1.17-0.06,1.96-0.49c3.61-1.97-0.75-2.47-0.86-3.88c-9.58-18.9,8.79-27.31,14.81-40.36
            c1.38-2.52,3.28-4.55,5.67-6.12c1.66-0.8,3.41-1.42,5.2-1.87c8.61-2.44,16.85-9.92,27.17,0.49
            c-3.23-10.19-11.59-6.67-15.92-10.65c-6.05-5.72-2.96-10.56,1.45-15.26c21.04-19.03,21.46-18.96,42.73,7.49
            c9.79,10.7,15.79,24.86,29.45,32.01c8.58,4.9,14.07,12.72,19.56,20.56c7.49,8.84,2.42,15.46-3.59,21.82
            c-0.38,7.32,4.64,2.59,6.23,2.19c5.21-1.33,10.18-3.63,14.97,0.93c5.45,6.53,5.09,13.23,0.59,20.04c-22.97,0-45.94,0-68.91,0
            c-7.76-2.98-17.49-2.25-21.81-11.61c-9.5-9.51-4.01,5.72-8.14,5.03c-4.98,5.79-13.05,2.71-18.7,6.58
            C52.59,399.54,29.62,399.54,6.65,399.54z"
            />
            <path
              style="fill: #27331b"
              d="M-629.97,147.33c15.94-6.87,32.29-7.81,49-3.31c4.5,9.32-6.85,13.41-7.24,21.31
            c22.54-9.39,25.38-8.75,31.44,6.94c-11.58-0.61-16.43,7.23-20.33,16.25c-5.64,19.37-5.59,41.08-24.95,54.58
            c-5.86,4.09-9.37,11.69-9.74,19.33c-0.6,12.28-4.43,23.55-8.68,34.87c-2.77,7.36-2.58,17.04-12.26,19.08
            c-10.73,2.26-11.08-9.96-17.28-14.27c2.93-10.68-2.5-15.04-12.2-16.18c0-35.17,0-70.34,0-105.51
            c2.39-11.86,10.61-16.07,21.64-16.13C-627.91,164.2-626.57,157.33-629.97,147.33z"
            />
            <path
              style="fill: #2c3a1c"
              d="M-662.22-184.8c0-17.58,0-35.17,0-52.75c7.95-13.22,14.15-26.63,4.41-41.75
            c-0.43-6.93,2.18-11.98,8.84-14.59c7.18-1.21,14.03,1.37,21.04,1.89c1.84,0.3,3.62,0.91,5.28,1.77
            c3.95,2.71,7.59,7.54,12.86,1.94c7.1-8.19,10.83-18.46,17.25-27.06c20.14-26.98,27.94-27.86,51.68-5.47
            c6.98,2.88,12.58,8.33,19.92,10.53c10.45,10.17,1.99,20.03-0.46,29.98c-2.76,9.29,1.06,15.29,9.52,18.97
            c9.4,6.6,10.99,16.26,9.17,26.43c-1.88,10.51-10.26,16.13-18.66,10.24c-11.75-8.23-5.16,3.5-7.35,5.3
            c-5.56,10.59-13.95,10.14-23.57,6.46c-8.93-3.42-11.65-9.41-10.04-18.61c0.65-3.71-0.55-7.83-1.87-11.57
            c-3.29-7.17-1.93-15.05-2.82-21.54c1.64,5.31-1.26,12.25,2.01,18.4c3.92,21.16,2.84,22.19-18.75,17.79
            c-4.81-2.37-7.73-6.2-8.9-11.4c-2.82-4.81,4.74-13.71-6.13-15.16c-4.11,1.63-7.55,4.27-11.34,6.2c-4.29,1.6-6.6,5.76-10.31,8.12
            c-5.92,3.31-11.46,7.29-11.27,14.81c0.28,10.81-6.84,18.05-11.51,26.54C-646.1-189.93-649.51-181.25-662.22-184.8z"
            />
            <path
              style="fill: #2b3b1b"
              d="M245.82,9.98c0,27.05,0,54.11,0,81.16c-10.25,8.12-5.4,16.23,0,24.35c0,9.47,0,18.94,0,28.41
            c-13.23,2.09-11.81-16.45-23.44-16.61c-5.81-5.69-9.83-12.32-10.6-20.6c-8.15,11.05-15.95,6.63-22.04-1.6
            c-3.9-5.26-5.58-12.16-13.99-11.56c-4.44-0.7-8.61,0.78-12.85,1.71c-2.66,0.45-5.3,0.16-7.94-0.08
            c-9.12,0.59-7.91,6.43-7.04,12.81c1.5,10.97-2.02,19.52-13.32,23.45c-11.88-8.01-21.86,3.79-33.06,3.28
            c-14.19-11.47-16.58-25.15-7.59-40.93c3.46-10.17,14.04-15.03,18.3-24.61c6.66-8.41,15.23-11.01,25.47-8.51
            c22,2.91,7.74-13.71,11.09-20.84c1.49-4.97,4.63-8.5,9.28-10.69c12.19-0.52,17.91-16.37,31.59-13.52
            c5.9,4.47,12.26,8.27,18.49,12.28c4.75,6.61,1.55,16.35,8.31,21.34c-5.26-6.64-4.38-14.28-0.65-22.06
            c0.67-1.81,1.6-3.46,2.77-4.98C231.02-0.38,231.02-0.38,245.82,9.98z"
            />
            <path
              style="fill: #31451e"
              d="M245.82-22.48c0,5.41,0,10.82,0,16.23c-2.16,2.13-4.7,3.31-7.77,3.16
            c-13.3-11.18-28.28-3.51-42.56-3.74c-3.84,0.95-7.59,2.34-11.65,2.16c-4.42-0.08-8.19-1.61-11.15-4.96
            c-2.24-3.55-4.59-7.05-5.97-11.07c-2.4-4.81-3.45-9.68-0.69-14.72c5.05-7.19,15.2-5.12,20.91-11.86
            c-7.71-7.29-13.33-15.83-11.58-27.43c0.64-5.34,4.07-8.69,8.16-11.55c1.47-1.18,3.08-2.17,4.78-2.97
            c6.35-2.91,14.2-4.41,13.19-14.2c1.2-15.59,9.41-22.27,24.94-19.65c5.25,3.51,9.95,7.58,12.76,13.42
            c0.89,4.22,3.56,7.29,6.64,10.09c0,2.71,0,5.41,0,8.12c-4.49,4.06-5.06,8.12,0,12.17c0,16.23,0,32.46,0,48.69
            c-2.74,3.02-6.17,4.08-10.16,3.62C239.5-26.49,243.15-25.59,245.82-22.48z"
            />
            <path
              style="fill: #34461d"
              d="M-621.68-509.43c14.86,0,29.73,0,44.59,0c4.02,5.5,6.41,11.3,3.43,18.13
            c-4.6,5.55-6.48,11.93-5.6,19.13c-3.2,9.77,1.59,17.27,7,24.62c0.85,4.08,0.45,7.96-1.83,11.55
            c-11.89,8.62-25.47,10.23-39.61,9.53c-1.53-1.24-2.71-2.79-3.5-4.6c-1.57-5.67,1.35-11.84-1.91-17.51
            c-5.74,0.49-9.19,4.3-12.29,8.62c-3.94,6.95-9.17,12.11-17.75,8.8c-9.35-3.61-7.49-11.08-5.42-18.56
            c2.25-8.14-1.45-14.2-7.65-19.11c0-10.14,1.38-20.53-0.34-30.37c-2.25-12.88,5.8-10.01,12.5-10.2
            C-640.6-503.01-631.14-498.28-621.68-509.43z"
            />
            <path
              style="fill: #212517"
              d="M193.12-509.43c6.76,0,13.51,0,20.27,0c15.34,14.79,15.5,34.79,16.68,53.64
            c1.04,16.6,4.31,31.38,15.75,43.75c0,10.82,0,21.64,0,32.46c-16.49,11.28-30.3,4.44-43.53-6.43
            c-2.97-4.95-2.52-10.46-2.51-15.88c-6.65-19.82-1.27-41.32-8.54-61.07c-4.69-11.52,2.99-24.33-3.71-35.64
            C187.33-503.29,190.17-506.39,193.12-509.43z"
            />
            <path
              style="fill: #636b26"
              d="M-443.19-445.04c-1.71,1.5-3.42,2.99-5.13,4.49c-0.81,0.43-1.61,0.86-2.41,1.3
            c-1.79-0.34-3.35-1.16-4.71-2.37c-2.59-4.53-5.85-8.35-11.17-9.83c-8.36-5.6-16.98-10.99-19.81-21.67
            c-0.1-2.92,0.85-5.48,2.6-7.76c8.02-5.03,13.5-11.43,11.58-21.79c0.73-2.8,2.65-4.79,4.6-6.76c9.46,0,18.92,0,28.38,0
            c2.7,3.2,5.4,2.86,8.11,0c9.46,0,18.92,0,28.38,0c4.44,4.74,15.53,7.77,4.29,16.57c-3.07-0.22-6.13-0.44-9.2-0.66
            c-2.38-2.38-5.63-1.19-8.38-2.05c0.4,1.15,0.89,2.26,1.47,3.33c9.88,2.89,20.6,6.52,15.32,19.65
            c-5.36,13.32-14.51,23.73-31.56,20.21C-436.64-452.76-439.7-448.54-443.19-445.04z"
            />
            <path
              style="fill: #252a19"
              d="M245.82-375.52c0,44.64,0,89.27,0,133.91c-5.06,4.09-10.07,4.13-15.04-0.14
            c-10.45-33.47-17.86-67.66-23.17-102.37c-0.2-2.3-0.38-4.57-0.56-6.87c0.41-11.39-5.89-26.02,13.6-28.11
            C228.81-376.28,237.84-379.57,245.82-375.52z"
            />
            <path
              style="fill: #273019"
              d="M245.82,302.15c0,12.17,0,24.35,0,36.52c-0.72,10.51-22.35,13.51-10.21,28.63
            c1.59,2.61,2.28,5.43,2.16,8.47c-1.62,5.8-3.22,11.63-9.74,13.99c-14.18,1.78-16.58-12.7-25.37-18.41
            c-6.19-5.81-7.89-13.67-9.84-21.41c-4.48-10.5-20.85-6.34-23.55-19.03c0.01-17.91,8.67-27.12,26.85-26.73
            c6.59,0.65,12.16-3.56,18.56-3.93c3.85-0.19,7.68-0.48,11.5-0.96C233.27,296.62,240.12,295.43,245.82,302.15z"
            />
            <path
              style="fill: #64753a"
              d="M-662.22-184.8c7.98-4.05,13.5-9.73,12.88-19.47c3.24-4.09,6.73-2.87,10.28-0.53
            c6.12,20.78,23.94,16.17,38.89,17.88c4.4,3.38,5.85,7.73,4.33,13.07c-4.45,6.77-6.26,13.24-3.36,21.9
            c2.86,8.56,2.71,18.3-5.61,25.29c-13.34,3.59-14.58-12.97-24.63-14.71c-1.66-1.06-3.35-1.76-5.03,0.14
            c-2.11,11.82,10.69,20.05,7.29,31.97c-0.79,5.72-0.16,11.57-1.73,17.19c-14.52,11.36-15.49,11.02-28.05-9.73
            c1.18-11.21-0.78-22.19-2.46-33.2c-4.25-8.11,6-17.83-2.8-25.45C-662.22-168.57-662.22-176.69-662.22-184.8z"
            />
            <path
              style="fill: #262e18"
              d="M-195.75-457.71c4.53-12.95,10.25-24.77,24.2-30.44c3.33-1.35,8.37-3.94,7.28-8.46
            c-1.45-5.96-7.61-3.97-12-4.72c-3.25-1.8-6.11-3.77-3.55-8.11c10.81,0,21.62,0,32.43,0c2.57,2.6,5.27,5.11,5.69,9.05
            c0.3,5.35,0.81,10.69,0.84,16.05c-1.05,6.9,5.73,11.15,6.01,17.6c0.08,7.64,1.5,14.79,7.45,20.3c0.91,1.8,1.39,3.7,1.59,5.69
            c1.14,10.85-6.83,18.32-10.13,27.53c-15.61,6.35-21.94,3.54-26.3-11.65c-1.9-6.61,1.42-15.61-8.47-18.56
            C-178.68-448.85-193.1-443.02-195.75-457.71z"
            />
            <path
              style="fill: #424825"
              d="M245.82-412.04c-17.3-3.25-25.97-13.17-24.32-31.25c2.06-22.62-8.54-43.71-8.11-66.14
            c32.43,0,32.43,0,32.43,32.46C245.82-455.33,245.82-433.69,245.82-412.04z"
            />
            <path
              style="fill: #3e5723"
              d="M-662.22-298.42c0-12.17,0-24.35,0-36.52c1.27-1.29,2.66-2.45,4.15-3.49
            c13.24,1.83,21.08-7.5,29.99-14.6c12.97-4.98,5.76-17.95,10.33-26.27c1.11-1.73,2.51-3.13,4.18-4.31
            c20.94-4.42,26.99-1.39,36.24,18.03c1.31,9.58-9.77,13.55-10.14,22.42c-3.85,9.21-13.06,15.68-13.43,26.67
            c-3.58,11.46-10.73,17.75-23.3,16.1C-636.95-301.72-649.56-299.62-662.22-298.42z"
            />
            <path
              style="fill: #1f2416"
              d="M-142.77,383.31c0.61,0.22,1.32,0.31,1.83,0.67c6.66,4.8,10.33-11.07,16.25-2.97
            c4.09,5.6,0.19,12.53-2.43,18.53c-29.73,0-59.45,0-89.18,0c-1.96-17.93,5.84-31.32,19.43-42.08c7.07-3.94,15.09-1.85,22.51-3.6
            C-154.74,353.87-151.22,371.24-142.77,383.31z"
            />
            <path
              style="fill: #3c5322"
              d="M-662.22-355.23c0-20.29,0-40.58,0-60.87c7.09-8.52,15.79-9.97,25.74-5.9
            c11.14,2.76,12.04,15.14,19.84,21.08c5.04,6.37,7.55,13.17,3.22,20.99l-0.67,1.09c-4.92,9.05-2.58,23.24-17.71,25.05
            c-6.88,0.23-13.35,2.69-20.09,3.81C-656.3-349.84-659.31-352.44-662.22-355.23z"
            />
            <path
              style="fill: #3c4c2b"
              d="M-609.68,71.2c-4.69-4.99-9.35-10.01-17.09-5.39c-7.28,4.35-7.99,10.04-3.51,16.83
            c-7.63,24.24-13.63,28.89-31.94,24.73c0-5.41,0-10.82,0-16.23c4.06-3.39,5.91-8.04,7.23-12.95c1.58-5.05-0.76-10.25,0.49-15.34
            c0.6-2.81,1.95-5.21,3.91-7.27c6.66-4.93,13.81-9.27,19.24-15.73c12.4-8.64,15.2-28.31,33.94-29.7
            c1.98,0.15,3.85,0.72,5.63,1.61c1.68,1.14,3.87,2.47,4.11,4.27c2.44,18.03,1.17,36.23,2.28,54.37
            C-593.27,77.66-601.56,71.7-609.68,71.2z"
            />
            <path
              style="fill: #bccf43"
              d="M225.59-119.12c-5.09,5.39-21.28-0.43-15.77,15.66c-4.25,9.45-14.75,12.49-20.7,20.2l-0.04,0.16
            c-17.67,3.79-21.15-6.4-20.46-20.71c0.25-1.81,0.87-3.54,1.75-5.13c1.6-2.49,4.39-3.91,5.91-6.41
            c-0.29-1.37-0.47-2.64-0.94-3.96c-2-4.43-0.67-8.71,0.14-13.04c2.2-8.51,9.42-10.64,16.42-13.02c4.93-1.04,9.94-0.8,14.92-0.84
            c9.05,2.99,15.05-6.08,23.41-5.99c8.1,0.25,10.54,7.85,15.61,12.04c0,2.71,0,5.41,0,8.12
            C239.68-126.79,237.66-115.07,225.59-119.12z"
            />
            <path
              style="fill: #95a664"
              d="M-74.43-509.43c5.4,0,10.81,0,16.21,0c3.78,6.55,11.05,9.16,16.18,14.24
            c2.6,3.22,4.16,6.85,4.45,11c-0.55,5.22-1.81,10.19-5.2,14.38c-7.88,7.05-16.93,11.16-27.69,10.92
            c-8.47-1.9-14.62,8.39-23.49,4.48c-6.54-3.94-7.98-9.9-6.72-16.92c0.4-4.21,2.03-7.97,4.19-11.53
            C-91.8-493.91-89.38-506.89-74.43-509.43z"
            />
            <path
              style="fill: #4b5a2a"
              d="M-654.18-103.39c3.63,3.21,15.57-3.19,10.74,10.61c-1.68,4.8,5.51,2.45,8.98,1.48
            c5.36-0.57,9.32,1.87,12.61,5.81c1.62,2.52,2.01,5.39,2.32,8.28c1.33,8.36,6.46,16.59,1.01,25.25
            c-3.79,5.15-8.68,8.17-15.28,7.81c-9.05-5.71-22.89-2.35-28.42-14.85c0-18.94,0-37.87,0-56.81
            C-652.71-116.1-653.12-109.96-654.18-103.39z"
            />
            <path
              style="fill: #131510"
              d="M-402.78,399.54c-0.15-7.75,2.9-15.04-1.21-23.38c-4.18-8.49-0.03-17.41,8.52-22
            c8.4-4.5,11.15,3.74,15.57,8.44c5.46,5.81,11.64,10.94,17.51,16.36c0.35,1.56,0.79,3.09,1.34,4.6c0.23,0.77,0.45,1.54,0.7,2.31
            c0.96,4.52,5.97,8.4,2.17,13.66C-373.05,399.54-387.92,399.54-402.78,399.54z"
            />
            <path
              style="fill: #bec587"
              d="M95.83-509.43c2.7,0,5.4,0,8.11,0c5.18,5.21,10.3,10.48,15.97,15.18
            c4.69,4.62,11,8.07,11.42,15.78c-3.33,8.63-5.69,17.71-11.02,25.51c-8.89,4.14-10.11,16.24-20.01,19.38
            c-2.08,0.31-4.07,0.06-6.02-0.7c-3.57-2.35-6.52-5.28-8.49-9.12c-1.6-4.06-3.69-7.96-3.98-12.45
            C75.6-476.57,88.7-492.22,95.83-509.43z"
            />
            <path
              style="fill: #27331a"
              d="M-633.3-415.8c-9.64-0.1-19.28-0.2-28.92-0.3c0-17.58,0-35.17,0-52.75
            c11.95,1.06,26.83-0.53,16.1,19.59c-1.86,3.48-7.77,8.95-2.22,11.91c8.26,4.4,8.32-5.42,11.59-9.51
            c12.91-3.08,20.46,2.18,23.18,14.94l-0.15,1.74c1.7,11.38-9.67,10.39-14.6,15.51C-630.13-414.39-631.79-414.77-633.3-415.8z"
            />
            <path
              style="fill: #528553"
              d="M-341.86,380.01c5.3-3.11,10.61-6.22,15.91-9.33c11.78-9,23.88-7.72,36.12-1.19
            c0.76,6.08-1.74,12.72,9.87,12.94c9.34,0.18,19.29,3.84,28.61,7.22c4.47,1.62,8.92,3.57,6.67,9.88c-22.97,0-45.94,0-68.91,0
            c-9.5-5.95-20.14-5.7-30.81-6.28c-7.83-0.43-17.86,2.69-21.31-8.9l-0.74-1.73C-358.46,379.86-349.91,382.3-341.86,380.01z"
            />
            <path
              style="fill: #32391b"
              d="M-548.72-509.43c9.46,0,18.92,0,28.38,0c4.31,8.02,14.66,12.9,12.71,24.23
            c-2.76,6.39,0.49,12.86-0.11,19.31c-1.13,5.56-4.25,9.27-9.87,10.64c-7.17,0.09-14.35,4.16-21.54,0.07
            c-2.47-1.86-4.53-4.15-6.47-6.54C-551.84-477.27-566.42-492.31-548.72-509.43z"
            />
            <path
              style="fill: #39451c"
              d="M-548.72-509.43c-3.69,16.16,2.46,30.56,8.02,45.07c-4.59,5.18-9.74,5.05-15.19,1.73
            c-2.04-2.91-3.97-2.09-5.91,0.47c-10.8,4.96-17.95,1.98-21.2-9.56c-1.04-8.12,1.2-15.33,6-21.83
            c-3.34-5.27-3.09-10.57-0.09-15.88C-567.63-509.43-558.17-509.43-548.72-509.43z"
            />
            <path
              style="fill: #465723"
              d="M245.82,152.01c0,17.58,0,35.17,0,52.75c-9.54,3.83-15.8-0.74-20.96-8.13
            c-9.63-10.14-0.48-27.46-13.86-36.19c-3.81-2.79-7.44-5.74-8.91-10.51c-0.94-8.74-2.22-17.6,6.31-23.86
            c2.82-1.19,5.69-1.14,8.58-0.3C233.38,127.02,239.37,139.77,245.82,152.01z"
            />
            <path
              style="fill: #a2ab5d"
              d="M95.83-509.43c3.26,19.38-12.41,36.23-7.48,55.83c-0.43,2.85-0.84,5.7-2.18,8.32
            c-11.02,6.28-11.51,18.89-17.07,28.5c-3.84,2.63-7.77,3.62-11.94,0.71c-4.58-4.37-3.86-10.07-3.91-15.56
            c0.05-6.3-1.26-12.81,2.63-18.55c6.23-5.86,9.52-13.05,9.42-21.74c-1-16.02,10.09-26.07,18.37-37.51
            C87.72-509.43,91.78-509.43,95.83-509.43z"
            />
            <path
              style="fill: #3d5222"
              d="M-629.97,147.33c16.03,4.24,13.05,19.17,21.91,31.8c-21.73-6.85-38.36-9.88-54.16,1.28
            c0-8.12,0-16.23,0-24.35C-652.09,150.87-640.92,149.51-629.97,147.33z"
            />
            <path
              style="fill: #7e8860"
              d="M-662.22,26.21c0-17.58,0-35.17,0-52.75c3.09-3.26,2.1-13.26,11.11-6.75
            c3.71,5.14,5.04,11.83,10.31,15.99c16.02,29.69,15.61,33.57-5.78,54.4C-656.05,39.6-657.77,30.94-662.22,26.21z"
            />
            <path
              style="fill: #3f4827"
              d="M193.12-509.43c0.06,3.33,0.13,6.67,0.19,10c-3.19,3.98-1.92,9.9-5.88,13.54
            c-4.01,3.15-9.89,3.43-12.15,9.39c-5.7,15.02-14.3,9.88-22.95,2.8c-2.18-2.34-4.05-4.88-5.07-7.96
            c-0.33-9.3-8.17-18.93,1.28-27.77C163.39-509.43,178.26-509.43,193.12-509.43z"
            />
            <path
              style="fill: #455220"
              d="M83.67-509.43c4.87,16.19-14.63,25.13-12.08,40.63c-8.94,8.73-18.05,2.28-27.12,0.33
            c-12.67-9.02-3.31-24.8-10.83-35.42c-0.12-1.99,0.32-3.84,1.38-5.54c5.4,0,10.81,0,16.21,0c4.05,5.58,8.11,3.45,12.16,0
            C70.16-509.43,76.91-509.43,83.67-509.43z"
            />
            <path
              style="fill: #445526"
              d="M245.82,233.16c0,5.41,0,10.82,0,16.23c-9.25,11.09-12.12,27.99-29.13,32.06
            c-8.15-0.23-13.66-3.73-15.07-12.26c-0.5-7.01,6.51-11.16,6.83-17.9c3.96-4.94,0.69-10.48,1.25-15.72
            c1.06-5.12,4.44-8.38,8.7-10.96C230.45,218.06,238.26,225.21,245.82,233.16z"
            />
            <path
              style="fill: #414e24"
              d="M205.37,366.89c6.52,0.02,16.57-5.3,14.01,8.82c-1.07,5.87,3.72,8.2,6.09,12.02
            c1.92,10.03-9.61,6.28-12.08,11.82c-6.76,0-13.51,0-20.27,0c-3.98-6.12-4.34-13.04-4.46-20.02c-1.42-6.22,0.23-11.14,6.06-14.28
            C198.6,363.62,201.86,366.02,205.37,366.89z"
            />
            <path
              style="fill: #739966"
              d="M-244.68,399.54c-21.49-9.91-45.31-11.81-70.31-19.7c9.23-7.63,18.05-3.47,25.76-4.65
            c19.03-9.44,45.83-2.91,59.23,14.42c0.85,3.73-0.1,7.02-2.52,9.93C-236.58,399.54-240.63,399.54-244.68,399.54z"
            />
            <path
              style="fill: #596538"
              d="M-653.63-30.85c-4.99-2.82-5.61,3.1-8.59,4.3c0-10.82,0-21.64,0-32.46
            c4.35,1.98,8.8,3.65,13.47,1.2c12.52-6.56,15.39,0.08,15.22,11.01c1.58,1.13,2.95,2.48,4.13,4.04
            c2.67,5.77,4.34,11.5-0.49,17.08C-639.83-18.1-647.91-19.09-653.63-30.85z"
            />
            <path
              style="fill: #abb46d"
              d="M-90.81-483.12c-1.75,3.7-3.49,7.4-5.24,11.09c-3.12,3.03-6.67,2.87-10.41,1.42
            c-7.4-9.18-20.75-7.39-28.73-15.56c-2.74-3.22-4.4-6.81-4.01-11.15c1.25-9.53,10.43-8.5,16.12-12.13c5.4,0,10.81,0,16.21,0
            C-93.47-505.57-91.22-494.9-90.81-483.12z"
            />
            <path
              style="fill: #405f26"
              d="M238.61-6.45c2.4,0.06,4.81,0.13,7.21,0.19c0,5.41,0,10.82,0,16.23
            c-5.18,0.29-13.95-7.77-13.13,6.24c0.37,6.37-4.84,9.03-10.53,10.21c-7.63,1.76-13.7,0.28-16.6-7.92
            c-1.28-4.57-1.52-9.26-1.3-14C212.59-18.39,212.98-18.51,238.61-6.45z"
            />
            <path
              style="fill: #5b7b2c"
              d="M-179.82-509.43c1.18,2.7,2.37,5.41,3.55,8.11c-4.53,10.33-19.33,9.62-22.88,21.72
            c-0.92,3.15-7.12,2.53-10.78,0.33c-3.6-2.16-3.57-5.22-1.21-8.37c5.23-7.01,4.12-11-5.22-11.15c-3.24-5.32,0.14-8.09,4.1-10.63
            C-201.44-509.43-190.63-509.43-179.82-509.43z"
            />
            <path
              style="fill: #212715"
              d="M217.04,278.04c8.34-10.8,12.03-26.3,28.78-28.64c0,12.17,0,24.35,0,36.52
            c-10.74,9.52-20.92,9.73-30.39-1.84C215.1,281.84,215.64,279.82,217.04,278.04z"
            />
            <path
              style="fill: #688230"
              d="M-653.89-278.63c24.08,17.1,24.08,17.1-8.33,41.08c0-12.17,0-24.35,0-36.52
            C-659.98-276.58-658.68-280.8-653.89-278.63z"
            />
            <path
              style="fill: #5c6f37"
              d="M-232.52,399.54c-0.17-2.47-0.33-4.94-0.5-7.41c5.27-11.05-4.54-27.56,12.43-34.4
            c8.79-0.3,18.52-4.85,24.86,5.61c-6.86,12.07-13.72,24.13-20.58,36.2C-221.71,399.54-227.12,399.54-232.52,399.54z"
            />
            <path
              style="fill: #bfc69d"
              d="M-43.02-484.35c-0.91-2.81-1.83-5.62-2.74-8.43c-6.21-12.81,5.5-12.83,11.87-16.66
            c5.4,0,10.81,0,16.21,0c2.69,9.82,6.98,19.5,2.87,30c-2.81,3.52-6.51,5.68-10.76,7.02C-35.1-470.95-39.67-476.75-43.02-484.35z"
            />
            <path
              style="fill: #789736"
              d="M-260.65-485.33c-0.91-5.95-0.42-12.97-10.43-9.51c-4.95,1.71-10.6,2.99-15.65,2.32
            c-4.94-0.65-11.64-2.33-12.44-8.45c-0.79-6.06,7.61-4.3,9.91-8.46c6.76,0,13.51,0,20.27,0c8.68,6.2,29.49,3.58,17.84,24.57
            C-254.44-482.67-257.61-482.65-260.65-485.33z"
            />
            <path
              style="fill: #6d935f"
              d="M-365.72,384.35c9.83,5.24,20.54,1.99,30.8,3.2c8.81,1.04,16.44,4.02,21.32,11.98
            c-14.86,0-29.73,0-44.59,0C-373.4,392.74-373.4,392.74-365.72,384.35z"
            />
            <path
              style="fill: #54782b"
              d="M229.58-148.23c-4.99,7.83-9.2,17.82-20.74,7.49c-1.78-4.7-1.76-9.52-0.88-14.39
            c1.94-6.06,6.01-10.14,11.73-12.68c7.46-2.32,7.44-10.83,12.45-15.2c1.78-1,3.69-1.56,5.72-1.71c3.21,0.22,5.69,1.88,7.96,3.99
            c0,4.06,0,8.12,0,12.17C242.02-160.49,242.36-149.12,229.58-148.23z"
            />
            <path
              style="fill: #aeb884"
              d="M148.53-509.43c1.72,9.26,3.43,18.52,5.15,27.78c1.12,2.15,0.85,4.12-0.79,5.9
            c-17.78-0.17-27.96-9.15-31.75-28c0.63-2.1,1.66-4,3.07-5.68C132.31-509.43,140.42-509.43,148.53-509.43z"
            />
            <path
              style="fill: #7ca133"
              d="M-662.22-298.42c9.43-13.04,23.22-12.04,36.88-11.5c0.08,7.92,10.39,15.96-0.29,23.75
            c-7,0.21-14.12,0.98-19.86-4.43c-5.17-3.06-12.46,3.33-16.72-3.76C-662.22-295.72-662.22-297.07-662.22-298.42z"
            />
            <path
              style="fill: #6b794f"
              d="M-662.22,107.37c13.79-4.18,23.07-14.19,31.94-24.73c4.95,24.54-9.38,39.28-31.94,32.84
            C-662.22,112.78-662.22,110.07-662.22,107.37z"
            />
            <path
              style="fill: #5a7729"
              d="M-216.18-498.86c-8.78,6.39-11.82,21.95-27.54,17.23c-1.75-0.51-3.05-1.56-3.91-3.16
            c0.43-8.66-6.32-15.82-5.17-24.64c8.11,0,16.21,0,24.32,0C-225.63-504.45-220.58-502.03-216.18-498.86z"
            />
            <path
              style="fill: #bdc39c"
              d="M-90.81-483.12c-13.6-3.73-9.18-18.47-16.05-26.32c10.81,0,21.62,0,32.43,0
            C-86.44-504.75-76.55-486.4-90.81-483.12z"
            />
            <path
              style="fill: #495b25"
              d="M-25.25-477.05c1.32-3.36,4.4-3.8,7.35-4.45c4.38-8.83-3.81-21.43,8.34-27.94c2.7,0,5.4,0,8.11,0
            c2.69,2.83,5.64,5.49,6.11,9.71c-0.6,9.89,13.86,15.11,8.26,26.58C6.64-467.39-1-466.44-9.05-466.9
            C-16.34-467.25-23.36-468.05-25.25-477.05z"
            />
            <path
              style="fill: #5e5926"
              d="M-512.53-485.05c-1-8.64-17.7-12.25-7.81-24.39c8.11,0,16.21,0,24.32,0
            c5.88,5.11,8.98,10.82,3.95,18.29c-5.22,4.04-10.23,8.41-16.15,11.48C-511.33-480.1-513.57-481.25-512.53-485.05z"
            />
            <path
              style="fill: #667348"
              d="M245.82,233.16c-9.05,4.76-15.8-6.44-24.57-3.55c-11.98-8.49-1.61-14.41,2.39-21.1
            c7.46-5.12,14.85-4.29,22.18,0.3C245.82,216.93,245.82,225.05,245.82,233.16z"
            />
            <path
              style="fill: #455726"
              d="M230.05,370.82c-12.84-18.7-12.48-19.43,15.77-32.15c0,8.12,0,16.23,0,24.35
            C241.46,367.44,236.05,369.72,230.05,370.82z"
            />
            <path
              style="fill: #445526"
              d="M216.72,281.54c8.83,7.26,19.72,0.82,29.1,4.37c0,5.41,0,10.82,0,16.23
            c-7.03,1.13-14.05,2.26-21.08,3.39C218.26,298.82,202.14,295.32,216.72,281.54z"
            />
            <path
              style="fill: #757a3a"
              d="M-337.92-509.43c1.18,10.31-4.48,13.32-13.19,11.16c-6.81-1.68-12.96-1.72-19.02,1.87
            c-4.29,1.15-9.82,3.24-12.01-1.54c-2.94-6.38,4.83-7.78,7.73-11.49C-362.24-509.43-350.08-509.43-337.92-509.43z"
            />
            <path
              style="fill: #706136"
              d="M-495.98-493.59c2.52-5.29,0.66-10.57-0.03-15.85c9.46,0,18.92,0,28.38,0
            c0.05,2.37,0.1,4.75,0.16,7.12c-2.47,6.68-5.25,13.17-11.32,17.54C-487.34-482.23-496.44-478.61-495.98-493.59z"
            />
            <path
              style="fill: #545627"
              d="M-374.4-509.43c-0.32,4.92-8.92,12.56,4.28,13.03c-8.96,5.17-18.87,2.71-28.37,3.54
            c3.66-6.84-3.9-10.77-4.28-16.57C-393.32-509.43-383.86-509.43-374.4-509.43z"
            />
            <path
              style="fill: #89ad34"
              d="M-621.68-509.43c-1.27,4.55,6.91,8.82,1.88,12.37c-5.28,3.72-13.06,3.93-18.55,0.47
            c-4.66-2.94-10.37-6.26-11.71-12.83C-640.6-509.43-631.14-509.43-621.68-509.43z"
            />
            <path
              style="fill: #7b8847"
              d="M124.21-509.43c0.13,1.23,0.26,2.47,0.39,3.7c2.45,8.61,3.69,16.58-9,17.3
            c-13.52-1.64-10.29-12.61-11.66-21.01C110.69-509.43,117.45-509.43,124.21-509.43z"
            />
            <path
              style="fill: #56663f"
              d="M-662.22,26.21c5.48,2.5,10.96,5,16.45,7.5c4.25-1.73,8.2-1.08,8.88,3.78
            c1.33,9.44-5.02,13.96-12.65,17.19c-5.05,1.19-9.08-0.79-12.68-4.11C-662.22,42.44-662.22,34.33-662.22,26.21z"
            />
            <path
              style="fill: #9f9b80"
              d="M245.82-375.52c-8-0.36-16-0.73-24-1.09c-10.39-0.8-20.92,6.31-31.22-0.53
            c-7.21-1.96-11.59-6.14-11.06-14.15c1.5-3.11,4.07-4.89,7.3-5.81c7.43-1.16,14.7-1.77,19.44,5.94
            c10.63,12.59,25.88,9.38,39.55,11.59C245.82-378.23,245.82-376.88,245.82-375.52z"
            />
            <path
              style="fill: #819c2d"
              d="M230.05,370.82c3.01-7.16,5.68-15.01,15.77-7.8c-2.63,12.03,5.19,25.23-4.05,36.52
            c-8.07-6.39-12.05-14.16-8.29-24.56C232.33,373.59,231.19,372.2,230.05,370.82z"
            />
            <path
              style="fill: #434c29"
              d="M75.56,399.54c4.93-2.73,9.86-5.46,14.79-8.19c6.09-1.88,6.63-14.36,16.65-8.71
            c3.88,7.53,10.08,12.68,17.2,16.9C107.99,399.54,91.78,399.54,75.56,399.54z"
            />
            <path
              style="fill: #647e2b"
              d="M233.47,374.98c2.76,8.19,5.53,16.37,8.29,24.56c-9.46,0-18.92,0-28.38,0
            c0.55-7.5,8.31-7.62,12.08-11.82C228.14,383.48,230.81,379.23,233.47,374.98z"
            />
            <path
              style="fill: #7e9446"
              d="M245.82,208.82c-6.83,1.55-13.65,3.09-20.48,4.64c-7.83,0.32-11.63-4.11-10.42-11.19
            c1.28-7.46,6.03-11.94,14.53-9.31c5.45,3.94,10.91,7.87,16.36,11.81C245.82,206.11,245.82,207.46,245.82,208.82z"
            />
            <path
              style="fill: #1d2414"
              d="M245.82,152.01c-7.83-11.37-20.92-14.22-32.22-19.97c2.75-5.01,6-9.22,12.66-8.08
            c14.36-1.06,12.84,13.49,19.56,19.94C245.82,146.6,245.82,149.3,245.82,152.01z"
            />
            <path
              style="fill: #b7d05e"
              d="M234.47-248.34c1.58,5.98,5.54,7.92,11.35,6.72c0,5.41,0,10.82,0,16.23
            c-2.24,3.36-4.49,6.72-9.4,5.74c-5.6-2.86-11.68-5.09-14.6-11.41C219.76-241.4,220.84-249.48,234.47-248.34z"
            />
            <path
              style="fill: #6d9632"
              d="M229.58-148.23c5.41-6.78,10.83-13.56,16.24-20.34c0,9.47,0,18.94,0,28.41
            C238.2-138.41,233.3-142.12,229.58-148.23z"
            />
            <path
              style="fill: #7a9337"
              d="M63.4-509.43c-6.1,6.63,12.22,21.41-5.16,21.88c-10.84,0.3-5.52-14-7-21.88
            C55.29-509.43,59.35-509.43,63.4-509.43z"
            />
            <path
              style="fill: #7b893d"
              d="M-33.89-509.43c-5.83,4.21-9.53,9.94-11.87,16.65c-15.08,2.64-14.94-6.13-12.45-16.65
            C-50.1-509.43-42-509.43-33.89-509.43z"
            />
            <path
              style="fill: #68902b"
              d="M245.82,115.48c-11.11-3.42-13.04-12.36-11.32-21.78c1.15-6.27,6.91-4.19,11.32-2.57
            C245.82,99.25,245.82,107.37,245.82,115.48z"
            />
            <path
              style="fill: #416226"
              d="M245.82-22.48c-5.88-1.23-11.78-2.38-17.64-3.72c-3.08-0.71-7.47-0.58-7.35-4.98
            c0.06-2.22,2.37-4.76,4.27-6.48c4.9-4.46,6.23-0.5,8.14,3.46c2.65,5.51,8.13,3.2,12.58,3.61
            C245.82-27.89,245.82-25.19,245.82-22.48z"
            />
            <path
              style="fill: #313f1c"
              d="M-252.79-509.43c2.57,8.27,10.45,14.63,8.84,24.42c-3.07,2.39-6.09,2.19-9.05-0.21
            c5.65-15.35-12.15-15.15-16.01-24.2C-263.6-509.43-258.2-509.43-252.79-509.43z"
            />
            <path
              style="fill: #3e5723"
              d="M-662.22-294.36c3.71-1.27,7.58-2.22,11.07-3.94c2.32-1.14,4.45-4.11,6.85-1.43
            c2.69,3,0.26,6.2-1.2,9.13c-5.03,2.42-8.06,6.26-8.39,11.97c-2.78,1.52-5.55,3.03-8.33,4.55
            C-662.22-280.84-662.22-287.6-662.22-294.36z"
            />
            <path
              style="fill: #98a278"
              d="M-654.18-103.39c-2.68-4.14-5.36-8.29-8.04-12.43c0-6.76,0-13.53,0-20.29
            c0.84-2.78,2.09-2.98,3.76-0.6C-654.52-125.93-645.66-115.78-654.18-103.39z"
            />
            <path
              style="fill: #62747f"
              d="M-650.07,62.71c-5.66,6.86,28.11,13.56,0.08,20.52c-12.38,0.72-4.46-13.71-12.23-16.43
            c0-1.35,0-2.71,0-4.06C-658.18,59.53-654.13,59.1-650.07,62.71z"
            />
            <path
              style="fill: #a2a789"
              d="M-0.45-497.63c-0.34-3.93-0.67-7.87-1.01-11.8c8.11,0,16.21,0,24.32,0
            c2.33,2.62,2.84,5.6,1.75,8.9c-1.13,1.89-2.67,3.36-4.59,4.42C12.91-492.77,5.83-489.86-0.45-497.63z"
            />
            <path
              style="fill: #557b2b"
              d="M245.82-180.74c-2.67,0-5.35,0-8.02,0c-7.81-9.81-11.5-19.85-1.64-30.61
            c3.62-1.03,6.82-0.23,9.66,2.2C245.82-199.68,245.82-190.21,245.82-180.74z"
            />
            <path
              style="fill: #30441d"
              d="M225.59-119.12c5.95-5.55,11.02-12.49,20.23-12.92c0,1.35,0,2.71,0,4.06
            c-4.92,6.13-3.74,13.18-3.5,20.29c0.23,6.88-3.64,7.26-8.66,4.01C230.97-108.84,228.28-113.98,225.59-119.12z"
            />
            <path
              style="fill: #424f4c"
              d="M-662.22,66.79c9.89,1.14,8.76,10.51,12.23,16.43c-2.81,4.6-6.79,7.39-12.23,7.91
            C-662.22,83.02-662.22,74.91-662.22,66.79z"
            />
            <path
              style="fill: #86ad25"
              d="M-662.22-355.23c4-0.06,8.01-0.13,12.01-0.19c3.87-2.36,7.42-2.24,9.65,2.06
            c2.07,3.98,0.33,7.59-2.38,10.6c-4.14,4.6-10.68,4.32-15.63,7.34c-1.21,0.22-2.43,0.38-3.66,0.47
            C-662.22-341.71-662.22-348.47-662.22-355.23z"
            />
            <path
              style="fill: #a1a781"
              d="M-9.57-509.43c-5.15,8.6,4.36,21.59-8.34,27.94c-2.27-9.33-6.05-18.68,0.23-27.94
            C-14.97-509.43-12.27-509.43-9.57-509.43z"
            />
            <path
              style="fill: #7d9f34"
              d="M233.66-103.69c6.11,0.36,6.14-2.98,5.02-7.65c-1.72-7.16-0.75-13.47,7.14-16.64
            c0,9.47,0,18.94,0,28.41C241.72-100.81,236.11-97.58,233.66-103.69z"
            />
            <path
              style="fill: #2f3f1d"
              d="M-216.18-498.86c-7.44,0.37-19,5.54-12.29-10.57c5.4,0,10.81,0,16.21,0
            c-1.37,3.54-2.73,7.08-4.1,10.63C-216.35-498.8-216.18-498.86-216.18-498.86z"
            />
            <path
              style="fill: #747f39"
              d="M-123.07-509.43c-5.96,3.66-9.52,8.76-9.56,15.97c-6.11,3.33-10.91,1.88-14.51-3.96
            c-0.09-4-0.17-8-0.26-12C-139.29-509.43-131.18-509.43-123.07-509.43z"
            />
            <path
              style="fill: #9aa2be"
              d="M-650.07,62.71c-4.05,0.01-8.1,0.02-12.15,0.02c0-4.06,0-8.12,0-12.17
            c4,0.39,7.99,0.79,11.99,1.18c3.79,0.94,6.47,2.56,4.39,7.13C-647.25,60.15-648.66,61.43-650.07,62.71z"
            />
            <path
              style="fill: #abc76e"
              d="M-431.16-509.43c0.44,13.26-9.17,9.05-16.24,10.45c-0.81-7.31,6.87-6.1,8.13-10.45
            C-436.56-509.43-433.86-509.43-431.16-509.43z"
            />
            <path
              style="fill: #36441e"
              d="M-658.46-136.71c-1.19,0.58-2.44,0.78-3.76,0.6c0-8.12,0-16.23,0-24.35
            C-641.99-155.55-663.8-143.98-658.46-136.71z"
            />
            <path
              style="fill: #598034"
              d="M245.82-79.3c-4.05,1.66-8.16,5.19-9.95-2.88c-2.13-9.57,3.64-9.44,9.95-9.3
            C245.82-87.41,245.82-83.35,245.82-79.3z"
            />
            <path
              style="fill: #8fb44a"
              d="M245.82-209.15c-2.67-0.08-5.35-0.16-8.02-0.24c-3.48-1.32-6.47-3.09-6.74-7.36
            c0.29-2.87,1.69-4.97,4.33-6.19c3.48-0.81,6.95-1.62,10.43-2.43C245.82-219.97,245.82-214.56,245.82-209.15z"
            />
            <path
              style="fill: #5b692d"
              d="M21.96-501.78c0.3-2.55,0.6-5.1,0.9-7.65c4.05,0,8.11,0,12.16,0c0.01,1.41,0.01,2.82,0.02,4.23
            c0.03,0.91,0.06,1.83,0.1,2.74C31.16-494.05,26.81-493.1,21.96-501.78z"
            />
            <path
              style="fill: #1f2417"
              d="M-346.07,237.22c-6.35,13.11,5.17,27.85-3.7,40.74c-8.69,9.09-14.58,9.6-23.29,0.82
            c-9.93-10.01-13.15-22.89-14.13-36.6c-2.33-32.67-13.73-61.06-39.77-82.26c-6.04-4.92-8.94-11.55-11.22-18.62
            c-1.75-5.41-3.85-10.22-10.97-8.9c-2.14,0.35-4.28,0.26-6.07,0.36c1.62,3.36,5.62,4.44,6.49,7.99
            c2.68,10.23-7.27,14.06-10.69,21.24c-9.51,8.97-19.36,16.2-32.49,6.26l-0.09-0.12c-2.85-5.75-5.15-11.39-10.11-16.35
            c-8.19-8.2-10.14-21.6-2.54-28.78c11.6-10.97,2.17-15.08-3.01-21.53c-2.74-3.93-5.51-7.87-7.17-12.43
            c-7.94-22.73-7.94-22.73,18.89-42.82c11.02-7.47,13.51-22.72,26.11-28.77c3.65-1.97,7.35-2.52,11.16-0.45
            c3.53,2.42,4.53,10,11.87,2.88C-434.11,7.58-424-6.35-416.22-21.9c7.06-14.12-2.91-35.92-18.05-41.68
            c-19.48-7.41-23.97-19.23-14.89-39.18c6.28-3.59,11.99-8.3,19.23-10.08c6.71-1.32,12.88-0.4,18.06,4.45
            c7.98,15.69,19.35,29.59,25.08,46.52c0.77,1.8,1.17,3.7,1.3,5.65c-0.01,5.65-2.35,10.92-2.83,16.52
            c-0.35,7.32-0.52,14.64,0.37,21.94c4.47,11.77,7.9,23.54-0.17,35.26c-8.61,7.93-11.89,18.95-16.3,29.17
            c-5.34,12.38-13.45,17.65-26.6,13.49c-1.78,0.11-3.35,0.58-3.83,2.78c-1.18,5.67,0.45,11.07,0.87,16.5
            c0.21,4.44-1.03,9.49,5.07,11.34c4.88,4.39,10.2,5.73,16.23,2.51c5.19-1.17,10.34-2.41,14.56-5.97
            c8.6-8.47,17.12-17.72,28.34-3.25c3.42,6.68,2.66,14.46,5.52,21.3c1.37,5.42,6.42,8.85,7.77,14.3
            c4.14,14.53,11.79,28.33,9.15,44.25c2.44,8.95-2.19,18.64,2.88,27.33c0.3,1.87,0.48,3.74,0.56,5.63
            c1.01,12.03,8.96,23.73,1.71,36.15C-343.29,234.6-344.57,236.01-346.07,237.22z"
            />
            <path
              style="fill: #232b19"
              d="M-196.13,245.41c-16.43,3.77-8.89,12.21-3.92,20.15c-3.74,9.17-10.72,17.01-10.35,27.86
            c0.31,9.08-3.97,15.8-13.15,17.69c-10.54,2.16-16.34-3.45-19.28-16.49c0.63,16.14,2.21,27.55,20.03,24.98
            c7.56-1.09,12.56,3.42,16.56,9.3c4.09,15.77-10.7,16.46-18.25,22.95c-4.51,1.03-9.08,0.93-13.66,0.79
            c-16.28-4.16-32.22-4.9-49.09-1.11c-11.59,2.6-25.12,5.48-35.96-4.61c-2.66-13.34,2.55-21,16.25-22.48
            c2.18,1.06,4.15,2.37,5.24,4.77c0.4,0.9,0.88,1.14,0.75,1.03c-2.09-1.72,2.3-2.33-0.42-1.9c-2.63-11.42-12.84-15.45-20.46-21.91
            c-8.29-10.36,10.26-23.57-3.53-33.35c9.11-11.78,19.55-2.64,29.46-1.93c15.52,5.93,16.39-2.25,12.38-13.55
            c-4.65-13.12-6.87-24.75,10.25-30.17c3.59,1.33,6.09,4.5,9.92,6.17c-1.4-4.31-6.21-4.12-8.12-7.25
            c-19.61-14.63-16.57-39.14-25.53-58.44c-0.44-2.04-0.4-4.06,0.05-6.08c0.61-1.92,1.63-3.61,2.99-5.1
            c16-9.46,19.62,9.17,29.3,12.33c9.06,2.96,19.8-11.68,31.56-2.56c3.9,3.03,6.41,6.9,6.7,11.97
            c7.52,16.63,19.31,28.41,37.45,33.01c3.13,1.17,6.19,2.54,9.49,3.05c9.21,1.45,14.8,6.71,14.14,16.1
            c-0.69,9.9-8.64,12.28-16.95,13.69C-186.89,245.11-191.59,244.2-196.13,245.41z"
            />
            <path
              style="fill: #131510"
              d="M-451.37,127.55c9.93-12.54,15.19-3.58,17.98,5.05c3.98,12.29,13.14,19.1,21.63,27.74
            c19.61,19.97,31.11,44.95,33.05,73.24c0.84,12.26,2.2,24.17,8.18,34.83c4.29,7.65,9.92,14.96,20.77,9.56
            c17.82,3.85,23.4,13.46,19.41,33.41c-2.51,12.56-24.72,27.06-37.29,24.24c-1.94-0.44-3.68-1.66-5.61-2.21
            c-20.82-5.87-23.16-14.39-31.66-34.68c-8.56-20.44-8.05-45.04-25.78-61.55l-1.63-1.14c0,0,0.67-1.87,0.67-1.87
            c0.12-3.12,0.24-6.25,0.36-9.37c-11.99-20.84-18.5-43.42-21.33-67.17C-444.19,147.94-454.81,137.44-451.37,127.55z"
            />
            <path
              style="fill: #1c2215"
              d="M-650.02,302.09c6.95-0.68,10.71,4.27,16.2,10.37c2.16-19.85,7.8-38.08,14.08-54.27
            c9.62-24.82,25.02-48.5,42.64-69.68c20.23-3,33.74,2.98,37.92,25.28c1.67,8.94,6.11,18.01,14.81,23.42
            c3.02-2.98,4.6-7.12,7.92-9.86c9.48-5.88,17.87-6.28,24.03,4.73c1.61,7.5,0.34,14.93-0.83,22.39
            c-12.7,11.29-20.5,26.06-28.52,40.7c-5.85,7-12.88,10.33-22.02,7.28c-8.93-4.77-11.02-16.27-21.6-20.3
            c-10.33-3.93-17.32-6.83-15.06,8.65c0.99,6.73-1.73,13.53,5.62,19.89c8.56,7.41,1.22,12.66-6.47,15.78
            c-13.94-8.72-28.64-10.16-44.24-5.07C-643.13,327.17-651.01,320.14-650.02,302.09z"
            />
            <path
              style="fill: #2a3c22"
              d="M-515.97,233.95c-2.8,1.09-5.61,2.18-8.41,3.27c-0.32-2.63-0.36-5.33-1.02-7.87
            c-2.63-10.15-11.1-19.33-8.45-30.11c3.28-13.35,17.47-9.38,26.64-13.43c8.08-3.56,11.42-10.66,15.31-17.56
            c10.23-2.16,19.8-5.82,28.21-12.2c5.37-5.25,10.77-4.08,16.18-0.06c8.95,22.1,19.31,43.86,16.2,68.8
            c-16.72-2.34-27.82-14.35-40.03-24.36c-13.51,8.86,15.42,21.55-3.61,28.96C-489.06,227-504.27,214.63-515.97,233.95z"
            />
            <path
              style="fill: #1f2416"
              d="M-224.73,362.92c1.22,5.78,2.88,11.51,3.57,17.35c1.01,8.55-1.15,14.68-11.86,11.86
            c-16.85-11.92-37.85-10.05-56.21-16.94c-11.52-7.37-24.38-3.82-36.71-4.51c-2.74-6.55-5.48-13.1-8.22-19.66
            c0.71-0.65,1.41-1.3,2.12-1.95c1.85-0.59,3.73-0.67,5.62-0.25c12.61,6.21,25.96,3.93,39.16,3.41c4.48-1.62,8.78-3.98,13.79-3.38
            c14.27-0.36,27.52,7.32,42.02,5.31c1.89,0.69,3.53,1.75,4.96,3.17C-225.38,359.02-224.78,360.88-224.73,362.92z"
            />
            <path
              style="fill: #232e22"
              d="M-544.42,297.97c8.11,0.76,13.9-4.31,20.2-8.08c4.79-4.33,9.08-9.8,16.84-7.46
            c7.5,2.28,8.5,9.33,11.11,15.21c-6.45,9.19-8.59,19.32-7.8,30.52c1.6,22.76-1.8,25.14-24.69,18.46
            c-6.34-7.78-17.12-2.54-24.16-8.25c-4-5.91-3.32-18.18-16.24-11.96c-5.11-8.05-3.23-14.66,5.57-17.43
            C-556.18,306.65-551.21,300.88-544.42,297.97z"
            />
            <path
              style="fill: #324420"
              d="M-544.42,297.97c4.07,9.98,0.66,15.53-10.01,16.08c-8.25,0.42-14.38,2.79-14.72,12.37
            c-4.03,2.65-8.08,2.5-12.15,0.08c2.35-4.95,8.36-9.87-1.73-13.9c-5.58-2.23-8.13-6.26-7.36-14.2
            c3.35-34.45,8.39-37.36,39.06-20.06C-542.98,283.06-547.81,291.83-544.42,297.97z"
            />
            <path
              style="fill: #314339"
              d="M-294.19,159.53c0.21,1.48,0.42,2.95,0.63,4.43c-2,8.9-3.49,17.52-0.63,26.85
            c2.55,8.33,0.83,16.91-4.94,24.11c-2.66,5.1-6.84,9.05-10.23,13.6c-7.09,6.29-13.87,13.21-24.48,12.72
            c-4.08-1.34-8.15-2.68-12.23-4.03c-0.24-1.04-0.48-2.08-0.73-3.11c9.79-8.12,6.44-19.21,6.61-29.51
            c2.88-5.51,13.39-6.84,8.83-16.44C-328.3,157.33-317.04,148.69-294.19,159.53z"
            />
            <path
              style="fill: #4e604e"
              d="M-297.35,277.73c-9.34-1.55-18.68-3.09-28.02-4.64c-0.86-8.14-12.58-14.75-3.41-24.3
            c7.37-19.45,14.08-22.34,37.63-16.22c5.63,7.89,3.87,17.7,6.65,26.47C-281.42,268.74-287.57,275.24-297.35,277.73z"
            />
            <path
              style="fill: #1f2416"
              d="M-321.84,306.44c17.26-1.35,26.43,5.58,24.42,23.94c-2.81,2.23-5.48,2.26-8.01-0.46
            c-11.99-3.54-9.95,10.73-17.01,13.44c-2.02,4.4-7.16,3.77-10.27,6.39c0,0-1.45,1.28-1.45,1.28
            c-11.17-9.32,0.04-13.94,4.77-18.41C-321.35,325-317.09,317.28-321.84,306.44z"
            />
            <path
              style="fill: #324520"
              d="M-430.58,254.15c0.23,3.29-0.27,6.87,0.85,9.82c2.57,6.71,7.65,13.23-1.4,18.97
            c-6.31,4-11.33,1.62-15.76-3.24c-6-6.58-8.17-13.7-2.07-21.38c1.61-2.02,3.98-3.44,6-5.14
            C-438.6,250.6-434.37,249.55-430.58,254.15z"
            />
            <path
              style="fill: #36524a"
              d="M-515.97,233.95c-1.53-13.66,12.16-17.45,18.78-20.19c8.8-3.65,17.81,5.78,22.24,15.65
            c-0.04,5.12-0.08,10.25-0.12,15.37c-9.15,3-15.37-2.06-21.36-7.75C-501.22,224.95-508.73,230.31-515.97,233.95z"
            />
            <path
              style="fill: #1f2416"
              d="M-341.86,380.01c-7.82,4.47-16.3,2.65-24.6,2.62c1.36-1.22,2.71-2.43,4.06-3.65
            c3.27,0,6.53,0,9.76,0c0-2.14,0.46-3.91-0.07-4.29c-7.09-5.03-15.9-9.13-11.8-20.38c1.79-4.92,6.35-7.85,11.29-6.15
            c10.24,3.51,9.67,13.38,11.02,21.79C-341.66,373.23-341.94,376.65-341.86,380.01z"
            />
            <path
              style="fill: #3f596b"
              d="M-509.48,286.85c-4.86,1.25-8.11,10.36-14.74,3.04c3.97-15.86,8.22-31.51,26.69-36.85
            c7.11-4.3,11.7-1.61,14.77,5.33c-0.29,1.09-0.61,2.17-0.97,3.25c-4.03,3.22-6.77,7.17-7.02,12.55
            c-0.62,1.67-1.46,3.19-2.48,4.64C-496.78,285.28-502.94,286.45-509.48,286.85z"
            />
            <path
              style="fill: #365f74"
              d="M-482.75,258.37c-4.92-1.77-9.84-3.55-14.77-5.32c-4.83-5.69-5.09-11.08,1.08-16.02
            c8.62-1.56,16.19-0.2,21.36,7.76C-469.18,254.09-476.08,256.17-482.75,258.37z"
            />
            <path
              style="fill: #556c70"
              d="M-294.45,233.37c-12.16,3.53-26.45,2.33-34.33,15.42c-1.69-2.51-3.38-5.03-5.06-7.54
            c4.2-8.28,13.4-10.71,19.52-16.75c5.59,1.08,10.28-4.48,16.05-2.2C-294.5,225.13-289.72,227.61-294.45,233.37z"
            />
            <path
              style="fill: #3c4b32"
              d="M-412.1,374.17c0.53,4.98-1.85,8.12-6.68,7.98c-7.74-0.22-5.37-8.22-8.21-12.3
            c-2.69-3.86,0.99-5.84,4.77-6.06C-414.99,363.39-413.62,368.88-412.1,374.17z"
            />
            <path
              style="fill: #30414b"
              d="M-552.91,338.38c12.25-9.54,22.09-12.03,24.17,8.25C-537.54,346.02-548.26,351.12-552.91,338.38z
            "
            />
            <path
              style="fill: #5a7399"
              d="M-509.48,286.85c1.38-7.35,9.44-7.8,13.13-12.77c17.7,3.02,7.74,12.19,4.4,19.88
            c-1.44,1.23-2.88,2.45-4.32,3.68C-503.54,297.58-506,291.59-509.48,286.85z"
            />
            <path
              style="fill: #375876"
              d="M-491.94,293.97c0.5-7.06,3.62-14.71-4.4-19.88c0,0,0.71-1.43,0.71-1.43
            c2.45-4.91,6.83-6.8,11.9-7.6C-476.39,277.61-477.76,282.42-491.94,293.97z"
            />
            <path
              style="fill: #3b5155"
              d="M-430.58,254.15c-4.13-0.32-8.25-0.64-12.38-0.96c5.53-5.29-5.63-20.51,11.29-19.02
            c0.17,1.05,0.49,2.06,0.95,3.02C-430.67,242.83-430.62,248.49-430.58,254.15z"
            />
            <path
              style="fill: #30471f"
              d="M-487.54,373.85c3.97-0.01,6.07,2.62,6.6,5.94c0.35,2.24-0.44,6.53-1.5,6.8
            c-4.91,1.24-6.69-2.88-7.96-6.58C-491.28,377.44-490.68,374.9-487.54,373.85z"
            />
            <path
              style="fill: #264049"
              d="M-483.73,265.06c-2.32,5.12-6.03,8.07-11.9,7.6c-6.75-15.27,6.1-9.36,11.9-11.05
            C-483.59,262.77-483.59,263.92-483.73,265.06z"
            />
            <path
              style="fill: #1f2b18"
              d="M-29.47,242.01c2.05-9.67,8.97-18.72,3.79-29.33c8.08-8.4,2.83-19.11,4.92-28.54
            c1.68-7.6-1.28-16.2,4.85-22.88c19.22-12.17,19.23,4.95,23.6,15.45c2.21,4.06,6.35,6.27,9.42,9.49
            c14.11,2.25,27.75,7.56,42.46,0.99c9.01-4.02,18.57-1.09,26.95,4.29c6.84,2.05,9.04,14.52,19.45,8.55
            c16.79-1.14,27.44,6.99,33.89,21.96c2.65,6.15,3.61,11.41-3.54,15c-14.88-0.79-30.12-2.66-40.22,12.31
            c-9.09,10.11-27.79,11.02-28.14,29.51c-5.89,8.68-8.76,19.64-20.05,24.14c-5.42,2.16-1.5,6.91-0.93,10.55
            c0.6,8.43,2.76,17.18-4.49,24.18c-12.38,4.98-20.05,0.18-24.34-11.7c-0.49-7.86-2.8-15.34-5.25-22.77
            C3.6,284.35,1.6,264.8,9.38,243.79c-14.13,8.11-11.43,30.68-31.58,30.06c-4.26-4.65-6.79-10.11-7.61-16.37
            C-25.51,252.42-24.43,247.28-29.47,242.01z"
            />
            <path
              style="fill: #405629"
              d="M26.85,322.36c-1.45,13.67,9.74,10.39,16.85,12.58c3.75-0.93,4.98,1.35,5.61,4.41
            c-2.7,6.06-5.93,11.82-9.62,17.34c-8.46,6.11-13.38,17.36-25.69,17.97c-8.14-0.21-16.09,5.51-24.41,0.93
            c-9.27-3.58-4.16-15.49-11.62-20.12c2.59-6.02,9.08-1.63,12.75-4.78c6.4-4.62,6.73-6.48,2.2-12.5
            c-10.01-13.31-6.23-26.42,9.2-31.88c-1.19-5.54,2.35-6.47,6.55-6.75C18.8,303.89,17.18,317.63,26.85,322.36z"
            />
            <path
              style="fill: #303f1c"
              d="M-212.4,330.69c-2.19-1.48-4.34-4.18-6.56-4.23c-8.39-0.19-17.77,13.81-22.42,6.22
            c-6.95-11.36-14.93-25.75-10.62-40.01c1.99-6.59-4.98-19.64,7.02-19.47c6.65,0.09,9.44,11.72,11.75,19.33
            c1.51,4.96-3,14.07,7.6,12.45c8.35-1.28,11.27-9.18,8.34-15.53c-8.95-19.4,3.85-21.51,17.24-23.88
            c4.06,10.81,12.76,19.88,12.21,32.43C-194.85,309.78-198.78,323.88-212.4,330.69z"
            />
            <path
              style="fill: #2b361c"
              d="M-196.13,245.41c1.08-5.87,3.51-9.91,10.58-9.21c5.76,0.58,13.45,2.49,14.13-6.22
            c0.62-7.93-7.18-8.24-12.79-10c-2.54-0.8-4.97-1.95-7.44-2.93c-1.32-1.56-1.94-3.35-1.85-5.4c1.67-6.34,5.56-10.49,11.95-12.18
            c13.84,0.18,24.26-12.09,38.48-10.69c11.31,3.57,14.73,11.73,13.2,22.77c-0.48,4.19-2.33,8.11-2.62,12.38
            c-0.03,4.21,1.97,8.46-0.21,12.63c-1.51,2.33-4.36,2.89-6.24,4.75l-0.18,0c-2.26,2.47-3.97,5.46-6.83,7.4
            c-5.98,2.88-7.54,9.3-11.18,14.06c-6.09,6.22-11.23,6.24-15.35-2.14c-2.4-4.88-5.87-7.12-11.02-3.34
            C-189.08,254.77-193.09,250.6-196.13,245.41z"
            />
            <path
              style="fill: #2d411e"
              d="M10.53,301.99c-2.8,1.44-5.59,2.88-8.4,4.32c-4.3-3.59-0.46-9.42-2.92-12.72
            c-3.93-5.26-0.58-22.07-16.59-11.13c-3.34,2.28-3.97-5.09-4.81-8.6c10.72-10.39,14.45-23.68,15.36-37.99
            c0.26-4.04-0.53-8.6,3.33-10.89c4.99-2.96,6.23,3.69,9.45,4.74c22.63,7.38,20.25,19.93,8.24,35.4
            c-4.1,5.27-2.96,11.16,0.69,16.83c3.94,6.13,8.37,12.4,4.69,20.4C16.48,303.87,13.42,304.91,10.53,301.99z"
            />
            <path
              style="fill: #3c5322"
              d="M-212.4,330.69c-0.07-12.46,14-22.49,3.64-37.11c9.15-0.24,13.23,11.31,20.91,4.42
            c1.03,13.87,12.93,18.23,20.79,26.7c8.57,9.24,12.66,21.15,28.22,18.57c0.33,4.79-2.39,8.54-6.53,8.51
            c-24.44-0.18-48.92,7.26-73.33-0.1c-2.71-0.82-4.5-2.69-5.83-5.09C-225.77,337.25-207.83,342.57-212.4,330.69z"
            />
            <path
              style="fill: #2e3d1e"
              d="M-138.96,241.3c0.6-1.42,1.19-2.84,1.79-4.26c5.43-5.01,9.93-11.38,15.91-15.28
            c8-5.23,14.95-5.02,10.42,8.04c16.88,20.73,13.84,51.48,35.4,70.03c6.08,5.23,3.09,10.68-2.67,14.72
            c-5.92-0.36-11.79-0.79-12.65-8.61c-10.89-2.61-11.46-11.67-12.67-20.32c-6.59-5.61-13.34,6.24-19.92-0.18
            c-2.11-4.87-3.61-9.3-0.72-14.91C-115.75,254.35-121.19,244.51-138.96,241.3z"
            />
            <path
              style="fill: #739e65"
              d="M-224.53,346.58c28.56-1.11,57.13-2.22,85.7-3.32c6.48-0.08,12.95-0.16,19.43-0.25
            c6.21,10.58-4.34,10.71-8.99,12.95c-13.73,6.63-28.88,6.18-43.73,6.79c-7.87,0.19-15.73,0.39-23.6,0.58
            c-9.66-0.14-19.33-0.28-29-0.42c-1.31-1.13-2.62-2.26-3.92-3.39c0.08-5.99-11.9-4.31-8.07-12.69
            C-232.66,346.75-228.59,346.67-224.53,346.58z"
            />
            <path
              style="fill: #24311a"
              d="M-74.01,188.39c1.94,1.83,4.5,3.31,5.72,5.54c5.57,10.18,22.03,21.34,13.53,30.33
            c-8,8.46-25.1,13.92-40.34,8.51c-2.96-7.23-7.39-13.8-9.75-21.33c-6.98-19-4.64-23.76,15.04-30.77c1.78,0.38,3.4,1.18,4.87,2.27
            C-81.96,186.03-81.13,193.49-74.01,188.39z"
            />
            <path
              style="fill: #2f411d"
              d="M-101.39,385.95c-6.02-16.32-5.88-30.16,13.04-37.95c2.42-1,6.1-2.33,6.55-4.16
            c1.99-8.07,2.44-18.56,13.95-7.92c8.05,7.44,10.86,15.73,2.46,26.39C-74.85,374.33-86.29,381.89-101.39,385.95z"
            />
            <path
              style="fill: #24311a"
              d="M-45.83,196.4c-4.78-11.01-15.9-12.23-25.15-16.33c-2.77-6.16-9.07-10.96-7.35-18.89
            c3.58-11.09,13.31-15.22,22.52-19.97c9.37-5.76,18.85-10.8,29.92-4.04c7.11,6.61,5.59,15.24,5.49,23.55
            C-22.34,177.27-41.56,181.49-45.83,196.4z"
            />
            <path
              style="fill: #384823"
              d="M10.52,367.13c11.69-0.23,16.47-10.98,24.8-16.33c7.15,3.05,7.63,10.85,11.51,16.23
            c6.74,4.02,21.87-0.66,20.11,10.3c-2.42,15.15-12.23,3.26-20.18,2.49c-14.62-1.42-25.76,12.54-40.14,15.77
            c-1.4-1.58-2.8-3.15-4.2-4.73C-4.22,379.77,4.13,373.79,10.52,367.13z"
            />
            <path
              style="fill: #33461f"
              d="M-45.83,196.4c-1.75-17.2,10.2-26.52,20.94-36.53c3.35-3.82,6.75-7.5,12.39-3.76
            c1.59,2.91,1.05,5.61-0.82,8.18c5.73,18.66,0.5,34.5-12.37,48.4C-24.56,188.63-26.9,186.74-45.83,196.4z"
            />
            <path
              style="fill: #445724"
              d="M-98.52,208.99c8.07,6.93,10.66,14.64,3.43,23.78c-5.25-1-10.5-1.99-15.75-2.98
            c-0.85-1.73-1.69-3.47-2.54-5.21c-3,13.58-13.88,12.1-23.79,12.45c-0.65-4.08-1.3-8.15-1.95-12.23
            c6.75-5.63,13.31-11.52,20.83-16.18C-111.62,204.53-104.98,202.55-98.52,208.99z"
            />
            <path
              style="fill: #414d26"
              d="M-183.52,257.27c4.26-6.79,8.78-8.04,13.44-0.46c2.22,3.62,4.27,7.09,9.37,4.52
            c3.28,0.03,5,1.81,5.52,4.94c-0.52,3.91-0.45,8.02-1.7,11.68c-1.83,5.39-6.38,8.98-11.83,9.4c-8.06,0.63-5.36-7.5-8.04-11.34
            C-180.76,270.28-173.15,260.52-183.52,257.27z"
            />
            <path
              style="fill: #66872e"
              d="M-73.99,164.08c6.96,4.21,8.87,9.36,3.01,15.99c-1.03,0.14-2.06,0.33-3.07,0.57
            c-4.38,1.49-7.28,7.35-13.15,4.48l0.05-0.05c-1.43-2.38-3.34-4.36-5.26-6.34c-3.83-5.89-13.4-2.84-16.18-10.4
            c-1.17-6.96,2.13-11.53,7.87-14.79c4.29-2.31,7.89-5.95,12.91-6.89C-72.96,144.31-73.53,154.23-73.99,164.08z"
            />
            <path
              style="fill: #3c5322"
              d="M-172.13,362.76c9.32-6.71,20.65-3.2,30.75-6.05c8.69-2.45,19.07-2.01,21.98-13.7
            c6.93,8.46,6.46,11.41-8.43,15.31c-14.6,3.83-20.75,10.15-14.94,24.99C-155.77,381.06-156.59,361.37-172.13,362.76z"
            />
            <path
              style="fill: #4e6e2b"
              d="M-123.36,285.43c6.66-1.65,13.33-5.32,19.92,0.19c-11.76,2.06-22.43,23.75-35.98,1.99
            C-134.46,281.6-127.93,290.65-123.36,285.43z"
            />
            <path
              style="fill: #2e3d1e"
              d="M-48.42,289.54c-0.34,4.88,2.92,11.77-5.33,10.45c-5.53-0.89-8.89-6.6-9.67-11.88
            c-0.94-6.41,5.14-7.35,9.59-7.76C-45.22,279.55-49.45,286.48-48.42,289.54z"
            />
            <path
              style="fill: #4e712b"
              d="M-87.2,185.11c4.38-1.49,8.76-2.98,13.15-4.48c-1.01,2.59-4.05,5.19,0.04,7.75
            c-0.87,6.03-3.67,10.88-7.94,15.67C-88.12,197.86-89.58,191.98-87.2,185.11z"
            />
            <path
              style="fill: #425b36"
              d="M-90.78,305.94c4.22,2.87,8.43,5.74,12.65,8.61c-3.71,1.35-7.33,3.34-11.15,3.86
            c-3.81,0.51-9.13,0.77-10.33-3.93C-101.29,307.8-94.54,308.01-90.78,305.94z"
            />
            <path
              style="fill: #64854c"
              d="M10.52,367.13c0.6,9.04-9.44,14.45-8.1,23.74c-4.27-5.1-8.55-10.19-12.83-15.28
            C-3.44,372.77,3.54,369.95,10.52,367.13z"
            />
            <path
              style="fill: #585c40"
              d="M-150.4,240.66c3.76,0.21,7.51,0.43,11.27,0.64c5.32,9.15,3.13,14.87-7.6,16.7
            C-155.18,253.75-156.06,247.9-150.4,240.66z"
            />
            <path
              style="fill: #848568"
              d="M-150.4,240.66c-1.48,6.35,0,12.08,3.66,17.34c-2.82,2.75-5.64,5.51-8.45,8.26
            c-1.84-1.64-3.68-3.29-5.52-4.93C-159.59,253.29-158.53,245.2-150.4,240.66z"
            />
            <path
              style="fill: #667156"
              d="M-9.28,350.69c-3.28,4.18-7.96,4.63-12.75,4.78c-2.8-2.77-7.77-6.56-4.02-9.75
            C-19.36,340.03-13.86,345.37-9.28,350.69z"
            />
            <path
              style="fill: #455b29"
              d="M-29.47,242.01c6.09,0.95,9.87,4.51,9.14,10.65c-0.61,5.19-5.7,4.35-9.49,4.83
            C-33.04,252.25-30.55,247.14-29.47,242.01z"
            />
            <path
              style="fill: #27341b"
              d="M-585.36,66.75c-0.86-6.67-7.14-6.67-11.17-9.64c-7.63-5.62-12.29-16.22-3.05-20.97
            c12.19-6.27,5.39-14.42,6.64-21.81c0.53-11.19,7.6-15,17.5-15.61c15.35,2.63,27.68,14.86,45.39,11.95
            c9.26-1.52,13.03,8.95,11.36,17.45c-2.36,11.98-0.6,23.95-1.84,35.87c-1.26,5.37-4.57,8.8-9.77,10.46
            c-12.87-1.89-25.74-1.3-38.59,0.44c-2.74,0.01-5.48,0.01-8.22,0.02C-583.73,76.07-586.54,73.43-585.36,66.75z"
            />
            <path
              style="fill: #303f1c"
              d="M-499.47,94.92c3.86,7.01,8.16,13.82,11.36,21.12c1.43,3.27,3.71,8.64-0.38,10.83
            c-2.07,1.11-6.6-2.4-10.55-4.04c-3.29,16.16,5.69,30.18,7.05,45.3c-2.76-2.9-4.98-7.07-8.36-8.5
            c-28.53-12.1-37.44-36.25-40.41-64.28c0.95-1.65,2.25-2.94,3.94-3.83c5.57-2.05,11.04-0.77,15.13,2.74
            C-514.14,100.76-506.98,98.63-499.47,94.92z"
            />
            <path
              style="fill: #3e5221"
              d="M-556.77,172.26c-7.75-5.8-14.79-12.35-21.61,2.01c-3.51,7.39-11.84,12.89-18.97,4.03
            c-5.05-6.29-9.75-15.2,0.4-22.5c5.37-3.87,10.65-7.86,15.98-11.79c2.75-1.26,5.51-2.52,8.26-3.79
            c5.58,2.61,9.24,0.55,11.57-4.75c8.91-5.29,15.77-5.17,15.57,7.44c-0.15,9.84,5.11,19.36,1.34,29.35
            C-548.48,183.49-552.59,179.67-556.77,172.26z"
            />
            <path
              style="fill: #3e5321"
              d="M-536.55,95.2c-1.4,0.05-2.81,0.1-4.21,0.16c-4.02-14.22-22.77-8.13-28.15-20.47
            c16.31-17.95,22.4-18.62,36.43-3.99C-524.42,80.57-529.57,88.04-536.55,95.2z"
            />
            <path
              style="fill: #384923"
              d="M-585.36,66.75c2.74,2.72,5.49,5.44,8.23,8.16c-5.21,8.16-12.11,14.68-19.72,20.54
            c-7.41-0.05-7.12-4.1-4.78-9.22c-0.37-6.25-5.15-10.14-8.05-15.03C-602.44,64.97-593.63,67.35-585.36,66.75z"
            />
            <path
              style="fill: #475228"
              d="M-601.63,86.22c1.59,3.07,3.18,6.14,4.77,9.22c-4.53,2.81-10.07,9.86-14.28,2.95
            C-615.97,90.44-608.52,87.51-601.63,86.22z"
            />
            <path
              style="fill: #5d7428"
              d="M-561.14,135.47c6.68,2.32,4.79,5.38,0.42,8.01c-4.87,2.93-10.09,5.5-11.99-3.26
            C-568.86,138.64-565,137.06-561.14,135.47z"
            />
            <path
              style="fill: #496b29"
              d="M-349.98-423.9c-6.81-20.97,15.5-20.81,23.07-31.36c9.99-13.93,23.39-23.32,41.88-21.99
            c4.21,5.54,3.17,13.42,8.32,18.55c5.72,11.75-6.4,20.63-5,31.72c-0.91,4.08-3.11,7.19-6.82,9.16c-1.81,0.72-3.72,1.03-5.66,1.06
            c-4.39-0.11-9.14-2.44-11.74,4.06c-7.15,17.87-17.24,12.21-27.58,3.09c-3.39-2.25-6.56-4.84-10-7.07
            C-346.17-418.63-347.36-421.89-349.98-423.9z"
            />
            <path
              style="fill: #3f5c24"
              d="M-280.64-455.12c-4.35-8.94-12.11,0.39-17.49-2.77c-1.25-10.7,12.35-10.56,13.1-19.35
            c13.5-4.07,20.57,10.37,32.35,11.25c1.86,3.06,3.92,5.8,8.13,5.14c2.61,1.36,5.22,2.72,7.83,4.08
            c5.53,4.52,12.89,5.96,18.24,10.8c4.84,11.08-6.89,15.57-9.4,23.78c-4.74,4.61-10.6,4.01-16.44,3.56
            c-7.21,0.83-13.78-0.27-18.79-6.15c-3.23-5.5-3.7-12.28-8.01-17.3C-274.35-446.38-277.42-450.8-280.64-455.12z"
            />
            <path
              style="fill: #56792c"
              d="M-419.04-408.02c5.21-10.64,13.95-17.1,24.85-20.99c4.11,3.67,15.42-5.45,14.05,7.92
            c-0.7,4.22,0.53,7.73,4.01,10.42c1.15,1.41,1.98,2.96,2.62,4.65c0.75,2.74,0.79,5.5,0.49,8.3c-3.94,12.65,3.29,27.51-7.67,38.77
            c-2.72,1.65-5.46,1.39-8.2,0.07c-4.61-4.32-9.14-8.77-13.99-12.87c-3.47-2.97-7.43-5.22-11.38-7.58
            C-424.6-387.45-425.28-397.17-419.04-408.02z"
            />
            <path
              style="fill: #191f14"
              d="M-390.18-453.57c-3.88-6.63-7.43-14.14-1.05-20.29c6.32-6.09,13.54-1.34,17.31,3.92
            c4.44,6.19,20.41,1.6,15.53,13.48c-4.41,10.75-15.4,16.33-28.39,11.79C-389.92-446.87-390.87-449.9-390.18-453.57z"
            />
            <path
              style="fill: #4e6a2a"
              d="M-228.79-424.53c-3.02-5.1-10.97-4.94-12.75-12c6.36-4.7,18.24,5.38,21.19-7.74
            c-6.32-10.05-5.69-15.62,8.07-12.2c4.28,1.06,8.42,4.63,12.41-0.43c1.37-0.26,2.74-0.53,4.12-0.8
            c8.02,9.17,20.13-4.42,28.09,5.07c-6.5,11.31-16.95,18.78-26.4,27.26c-9.74,2.36-20.57-5.83-29.67,3.09
            C-225.73-422.33-227.4-423.12-228.79-424.53z"
            />
            <path
              style="fill: #524f35"
              d="M-378.57-400.87c0.35-1.98,0.7-3.97,1.05-5.95c6-10.98,19.15-7.31,27.52-13.44
            c0.01-1.21,0.02-2.42,0.02-3.63c2.67,1.14,5.34,2.28,8.01,3.42c8.34,14.23,14.45,29.25,16.34,45.8
            c-1.26,6.63-5.73,10.51-11.37,13.4c-4.3,0.77-7.67-0.87-10.43-4.05c-4.67-6.61,5.58-18.62-7.99-22.02
            c-4.36-1.7-8.94-2.32-13.55-2.91C-374.23-391.93-377.27-395.61-378.57-400.87z"
            />
            <path
              style="fill: #3b5524"
              d="M-419.04-408.02c5.74,7.27,0.05,20.28,12.21,24.33c-3.81,16.97-16,14.33-28.06,12.07
            c-5.41-3.08-6.54-8.98-9.13-13.92c-1.51-4.95-1.23-10.04-1.09-15.12c0.39-4.92,1.53-9.44,6.69-11.62
            C-432.2-409.79-426.47-405.03-419.04-408.02z"
            />
            <path
              style="fill: #678c2f"
              d="M-199.87-456.89c-2.53,5.31-0.8,17.06-12.58,5.78c-7.4-7.08-7.33,1.93-7.9,6.84
            c-8.7,0.1-16.32-1.23-16.37-12.5c2.08-4.71,4.17-9.42,5.06-11.43C-220.77-464.33-210.32-460.61-199.87-456.89z"
            />
            <path
              style="fill: #8a8954"
              d="M-450.32-440.62c0,0,2,0.09,2,0.08c0.87,7.01,7.05,12.59,5.48,20.26
            c-0.48,3.01-1.72,5.6-4.16,7.54c-7.38,2.98-14.69,6.7-22.93,2.79c-5.48-4.83-10.04-10.07-7.58-18.22
            C-469.99-435.67-460.57-439.05-450.32-440.62z"
            />
            <path
              style="fill: #7e942e"
              d="M-443.19-445.04c-0.56-1.86-2.03-4.04-1.52-5.53c1.86-5.42-4.97-14.09,1.73-15.78
            c8.71-2.19,11.47,6.2,12.14,13.97C-432.78-446.26-433.18-437.55-443.19-445.04z"
            />
            <path
              style="fill: #afb475"
              d="M-414.6-492.23c-9.38,0.06-3.93,14.33-14.14,14.15c-4.94-7.07-2.45-13.84,3.24-18.52
            c6.14-5.05,12.21-0.12,17.82,3.08C-409.99-493.09-412.3-492.66-414.6-492.23z"
            />
            <path
              style="fill: #73854c"
              d="M-447.35-416.58c1.5-1.23,3.01-2.46,4.51-3.69c1.47,2.67,2.94,5.33,4.41,7.99
            c0.35,3.13,3.28,6.78-1.77,8.82c-2.27,4.37-3.6,9.77-10.25,9.32C-467.53-404.12-454.61-409.96-447.35-416.58z"
            />
            <path
              style="fill: #56792c"
              d="M-350-420.27c-8.29,6.29-14.76,16.33-27.52,13.44c-0.44-0.54-0.89-1.08-1.35-1.61
            c-2.12-2.71-2.96-5.69-1.9-9.06c0.44-1.61,1.39-2.81,2.86-3.61C-368.61-420.84-359.3-420.55-350-420.27z"
            />
            <path
              style="fill: #171c11"
              d="M-253-485.23c3.01,0.07,6.03,0.14,9.04,0.21c-0.03,1.14,0.05,2.27,0.24,3.39
            c-5.06,4.23-10.14,9.97-17.38,6.3c-4.81-2.44-2.27-6.73,0.45-10.01C-258.1-485.3-255.55-485.26-253-485.23z"
            />
            <path
              style="fill: #3e6027"
              d="M-382.36-420.66c-0.52-7.65-10-2.56-11.84-8.35c6.74-0.79,12.38-10.05,19.35-4.41
            c3.62,2.93-1.76,8.13-3.07,12.3c-0.48,0.61-0.86,1.28-1.14,2C-380.58-418.71-381.66-419.27-382.36-420.66z"
            />
            <path
              style="fill: #49722b"
              d="M-390.18-453.57c1.13,2.97,2.27,5.94,3.4,8.9c-4.63,0.9-9.11,10.15-12.96,3.53
            C-402.87-446.52-396.88-451.94-390.18-453.57z"
            />
            <path
              style="fill: #4d7a2c"
              d="M-244.56-460.85c-0.53,5.76-0.83,11.62-6.16,16.08c-6.61-7.12-0.76-14.46-1.97-21.21
            C-249.98-464.27-247.27-462.56-244.56-460.85z"
            />
            <path
              style="fill: #1b2015"
              d="M197.17,310.27c-14.21-1.21-19.25,9.1-24.3,19.39c-4.05,9.16-12.05,9.38-20.31,9
            c-20.91-0.67-22.97-20.21-32.37-32.41c-9.12-14.56-7.5-18.42,13.47-32.2c20.12-6.09,36.68,6.88,55.04,10.34
            c9.21,2.08,10.51,9.88,12.46,17.21C201.13,305.1,199.87,308.02,197.17,310.27z"
            />
            <path
              style="fill: #466628"
              d="M135.82,277.81c-4.44,9.91-24.41,11.26-15.64,28.45c-3.08,0.89-7.79,2.49-8.03-1.81
            c-1.58-29-14.24-11.29-24-5.98c-3.82-1.22-7.42-2.81-10.12-6c-1.15-1.86-1.72-3.86-1.79-6.04c0.68-5.9,2.9-10.8,8.64-13.43
            c8.85-2.26,17.81-4.05,26.73-5.99C122.48,264.66,130.97,266.98,135.82,277.81z"
            />
            <path
              style="fill: #637632"
              d="M152.56,338.66c3.86-9.59,11.24-11.2,20.31-9c7.9,8.36,24.16,2.75,28.49,17.08
            c-6.43,16.34-21.85,7.69-32.69,11.79C160.45,354.22,157.96,345.26,152.56,338.66z"
            />
            <path
              style="fill: #42502c"
              d="M168.66,358.53c8.22-11.36,18.95-15.77,32.69-11.79c4.73,6.04-1.3,14.23,4.01,20.15
            c-2.15,2.27-4.4,1.72-6.69,0.24c-11.02,6-21.02,23.57-34.39,3.06C165.42,366.18,180.5,367.42,168.66,358.53z"
            />
            <path
              style="fill: #556357"
              d="M164.29,370.19c11.96,4.72,22.95-1.49,34.37-3.05c-3.32,4.12-6.66,8.25-10,12.38
            c-5.19,4.01-10.18,8.33-15.63,11.94c-4.33,2.86-9.12,2.51-12.21-2.07c-3.98-5.91-9.18-12.17-5.42-19.73
            C157.17,366.06,161.23,370.02,164.29,370.19z"
            />
            <path
              style="fill: #405a25"
              d="M82.43,307.24c12.66-4.22,24.36-7.02,22.8,13.38c-0.26,3.41,7.28,5.43,2.96,9.91
            c-4.77,4.95-10.03-0.02-12.05-2.55c-8.1-10.19-16.59-3.64-25.06-2C65.13,313.82,71.14,308.92,82.43,307.24z"
            />
            <path
              style="fill: #51752b"
              d="M46.83,367.04c-10.99-0.33-12.03-7.72-11.51-16.23c3.98-4.02,7.95-8.04,11.93-12.06
            c2.03-9.42,6.22-14.58,16.24-8.25c2.41,1.19,11.36,0.39,5.44,4.48C56.89,343.29,49.62,353.17,46.83,367.04z"
            />
            <path
              style="fill: #405a25"
              d="M63.48,330.49c-5.71,2.17-12.31,2.57-16.24,8.25c-1.19-1.27-2.37-2.54-3.55-3.81
            c-3.13-6.85-7.68-13.56-2.05-21.26c1.71-10.23,9.35-11.31,17.61-11.48c1.92,0.36,3.67,1.13,5.28,2.23
            c5.54,6.33,2.58,14.09,3.52,21.21C66.53,327.26,65,328.88,63.48,330.49z"
            />
            <path
              style="fill: #6f962e"
              d="M68.05,325.64c-6.72-5.25-6.81-11.97-4.29-19.28c0.73-13.07,12.93-11.71,20.43-16.27
            c1.32,2.79,2.65,5.59,3.97,8.38c-1.94,2.9-9.66,2.02-5.72,8.77c-3.78,6.25-7.56,12.5-11.35,18.74
            C70.06,325.99,69.05,325.88,68.05,325.64z"
            />
            <path
              style="fill: #5f7a3d"
              d="M107,382.64c-6.06,1.92-9.45,8.95-16.64,8.71c0.24-1.31,0.19-2.76,0.77-3.9
            c3.83-7.52-17.92-11.04-3.97-18.33C97.65,363.65,102.31,375.08,107,382.64z"
            />
            <path
              style="fill: #1e2817"
              d="M-641.62-201.19c-2.58-1.03-5.15-2.06-7.72-3.09c6.87-9.69,6-21.58,9.41-32.11
            c2.01-6.23,7.57-15.15,18.11-9.19c6.63,1.28,4.92-5.91,8.16-8.07c-2.93-13.45,11.14-0.75,12.26-7.98
            c11.91,1.08,17.55,6.45,12.91,18.87c2.36,3.1,4.72,6.19,7.07,9.29c2.57,2.27,4.1,5.07,4.3,8.54
            c-0.83,7.43-5.11,12.65-11.84,14.81c-13.33,4.27-26.93,7.47-39.44,14.27C-633.51-193.08-638.9-195.19-641.62-201.19z"
            />
            <path
              style="fill: #35481e"
              d="M-625.63-286.17c1.52-7.9-8.36-15.94,0.29-23.75c14.53,7.29,15.57-2.24,15.86-12.72
            c4.72-10.72,16.15-13.65,24.02-20.73c9.04,1.54,17.97,0.63,26.87-1.24c14.02,2.12,16.12,12.54,16.71,24.01
            c-0.31,2.19-1.22,4.11-2.73,5.73c-8.98,5.33-10.23-0.68-10.95-7.48c-0.62-5.85-4.31-7.97-9.7-7.77
            c-4.26,0.16-7.14,2.13-9.71,5.87c-9.76,14.23-20.01,28.13-30.07,42.15c-7.18-0.13-14.6,0.99-20.8-4.11L-625.63-286.17z"
            />
            <path
              style="fill: #405b26"
              d="M-581.18-225.37c-0.08-2.7-0.17-5.4-0.25-8.11c4.76-3.15,23.93,9.05,12.3-11.57
            c2.13-2.73,4.73-3.16,7.74-1.56c5.32,28.15,8,30.51,28.7,25.19c5.54-1.68,7.37,2.19,9.17,6.12
            c4.31,16.84-14.65,19.96-18.74,31.86c-1.29,3.8-1.48,8.03-3.26,11.55c-5.45,10.76-14.3,11.92-19.06,1.19
            C-572.31-188.06-581.62-205.36-581.18-225.37z"
            />
            <path
              style="fill: #48682e"
              d="M-528.36-212.93c-1.45-2.83-2.89-5.66-4.34-8.48c-3.57-10.93-7.14-21.85-11.18-34.24
            c10.93,2.77,10.96,11.54,15.22,16.9c4.05,5.11,9.03,12.16,15.91,8.56c6.75-3.54,4.38-11.83,2.5-18.15
            c-1.11-3.73-3.81-6.98-5.79-10.44c-3.93-10.62,3.44-11.43,10.69-12.36c9.18,1.21,15.32,7.33,14.89,15.63
            c-0.67,12.77,4.33,24.49,4.42,36.93c-2.72,7.3-4.33,15.35-12.2,19.44C-512-195.54-521-202.44-528.36-212.93z"
            />
            <path
              style="fill: #37491e"
              d="M-528.5-278.22c-3.62-10.16,9.14-18,4.03-28.37c-10.14-8.35-9.12-19.42-7.47-30.64
            c1.02-1.97,2.53-3.48,4.47-4.54c13.51,1.97,31.56-11.57,39.1,11.3c-11.33,18.75-8.99,40.76-14.31,60.96
            C-513.13-266.94-523.66-264.11-528.5-278.22z"
            />
            <path
              style="fill: #506e28"
              d="M-528.23-339.1c2.5,10.69,4.72,21.42,3.76,32.51c-8.54,1.68-13.86-4.49-20.16-8.28
            c-0.03-1.35-0.1-2.7-0.22-4.04c-1.38-5.39-0.13-10.89,0.62-16.03C-542.41-347.38-536.88-350.46-528.23-339.1z"
            />
            <path
              style="fill: #6a8d75"
              d="M-561.4-246.6c-2.58,0.52-5.16,1.04-7.74,1.56c-5.88-7.61-0.58-19.35-9.63-26.03
            c-1.04-0.77-0.61-5.4,0.68-7c2.12-2.65,4.25-0.18,6.54,1.35C-560.23-269.23-559.38-258.45-561.4-246.6z"
            />
            <path
              style="fill: #5d8431"
              d="M-588.51-242.76c-1.69-8.08-4.47-15.41-12.91-18.87c-2.52-5.74-2.85-10.55,4.95-12.12
            c7.92-1.59,7.43,4.71,7.11,8.79C-589.94-257.46-572.79-250.6-588.51-242.76z"
            />
            <path
              style="fill: #4d6933"
              d="M-528.5-278.22c9.19-0.43,14.06,11.81,24.3,8.29c0.89,0.72,1.29,1.52,1.18,2.42
            c-0.11,0.93-0.29,1.4-0.53,1.4c-5.42,0.31-10.56,1.1-12.5,7.33C-527.57-260.51-532.23-266.67-528.5-278.22z"
            />
            <path
              style="fill: #63803a"
              d="M-625.86-286.2c7.68-2.43,16.22-9.24,20.8,4.1C-615.22-267.07-621.87-269.88-625.86-286.2z"
            />
            <path
              style="fill: #405b24"
              d="M-613.67-253.65c-0.53,7.33,8.82,11.57,6.38,19.85c-8.39,0.05-9.29-8.56-14.54-11.78
            C-621.08-250.27-618.56-253.16-613.67-253.65z"
            />
            <path
              style="fill: #32451d"
              d="M103.92,204.67c-2.67,3.69-5.34,7.37-9.45,13.05c-3.46-8.29-3.82-16.81-10.96-21
            c-2.44-5.03-7.04-7.36-11.66-9.99c-16.86-9.59-18.36-17-7.5-34.43c7.76-3.45,16.4-4.22,20.95,3.49
            c8.68,14.69,10.62,1.37,14.9-2.74c8.73-6.02,17.17-12.43,25.28-19.28c1.43-2.24,3.29-4.06,5.48-5.54
            c9.38-4.89,12.2-14.47,8.79-22.25c-9.76-22.26,4.74-18.99,17.49-18.49c6.8,11.61,10.8,24.66,18.5,35.83
            c0.93,6.1-1.79,10.96-5.19,15.6c-6.23,6.94-2.77,15.62-4.16,23.44c-1.14,6.55-4.9,10.88-11.03,13.23
            c-8.48-0.24-16.2-1.72-22.23,8.36C126.91,194.36,113.49,197.39,103.92,204.67z"
            />
            <path
              style="fill: #97b72c"
              d="M140.34,66.89c-8.25,2.14-17.88,0.11-24.2,8.05c-2.58,1.78-5.29,1.7-8.09,0.61
            c-9.73-4.29-16.44-10.79-16.38-22.31c1.11-7.84,7.29-11.37,13.02-15.29c4.19-3.22,9.56-5.56,12.06-10.2
            c10.84-20.07,25.42-17.08,41.42-7.48c3.59,4.18,5.3,8.6,1.61,13.64c-2.45,2.9-4.9,5.8-7.35,8.7
            C151.71,52.37,151.49,62.35,140.34,66.89z"
            />
            <path
              style="fill: #415a24"
              d="M226.26,123.95c-3.04,4.54-7.33,7.14-12.66,8.08c-1.44-1.43-2.87-2.87-4.31-4.31
            c-3.32,4.94-7.01,9.42-13.14,11c-2,0.12-3.93-0.22-5.79-0.95c-6.9-3.5-7.73-10.73-10.39-16.84c-2.08-5.04-2.85-10.53-5.33-15.47
            c-2.26-6.11-7.21-12.04-1.16-18.65c8.43,0.69,18.36-1.62,21.33,10.28c1.14,4.56,1.66,11.21,7.32,10.31
            c7.41-1.18,3.93-8.55,4.36-13.47c0.29-3.32,0.33-6.66,0.6-12.61C226.44,91.1,215.66,112.64,226.26,123.95z"
            />
            <path
              style="fill: #435325"
              d="M132.18,135.56c-6.6,10.67-16.98,16.11-28.19,20.4c-10.88-12.87-24.94-6.46-38.01-6.24
            c-3.66-2.25-5.85-5.48-6.61-9.69c-0.41-4.28,1-7.92,3.93-10.99c13.12-3.63,11.81-14.12,11.41-24.22
            c2.01-4.99,5.54-8.53,10.25-10.97c5.1-2.16,10.17-3.94,14.75,1.06c-6.76,13.48-5.02,25.84,4.88,37.12
            C113.94,131.95,124.79,120.16,132.18,135.56z"
            />
            <path
              style="fill: #5a792b"
              d="M181.03,103.38c0.97,5.36,13.77,7.85,3.82,15.84c-2.27,6.14-5.93,8.53-12,4.47
            c-16.29-4.99-22.22-18.75-15.61-36.21c2.42-0.2,4.83-0.41,7.24-0.61C173.58,88.77,173.29,100.11,181.03,103.38z"
            />
            <path
              style="fill: #3a5322"
              d="M159.77,33.92c-1.14-3.84-2.28-7.69-3.42-11.53c-3.67-4.4-4.14-9.13-1.46-14.19
            c2.6-3.09,4.85-6.55,8.62-8.45c1.94-0.59,3.92-0.72,5.92-0.4c4.85,3.32,10.48,4.78,16.04,6.42c2.61,1.64,4.34,3.95,5.19,6.91
            c-0.01,0.98-0.03,1.96-0.04,2.94c-0.53,0.82-1.06,1.65-1.59,2.47C179.83,24.4,178.18,44.7,159.77,33.92z"
            />
            <path
              style="fill: #85ae28"
              d="M140.34,66.89c-0.34-10.26,4.75-17.82,12.08-24.26c3.52,7.68,9.65,15.07,5.28,24.1
            c-2.09,4.31-0.69,11.99-8.57,11.9C141.55,78.54,142.27,71.57,140.34,66.89z"
            />
            <path
              style="fill: #415a24"
              d="M198.33,59.15c-0.97,15.38-8.81,17.54-17.03,18.24c-3.75,0.32-9.92-4.87-8.33-5.67
            c8.78-4.38,4.87-18.43,14.76-19.66C193.92,51.29,198.41,56.67,198.33,59.15z"
            />
            <path
              style="fill: #405f26"
              d="M221.11,30.2c-3.56,12.23,2.51,24.44-0.3,40.82c-4.84-16.18-18.38-24.42-15.87-39.94
            C210.01,25.03,215.45,25.63,221.11,30.2z"
            />
            <path
              style="fill: #718e37"
              d="M132.18,135.56c-8.41-7.38-19.2,3.94-27.6-3.52c7.3-11.7,14.12-26.67,26.39-3.81
            C131.37,130.67,131.77,133.11,132.18,135.56z"
            />
            <path
              style="fill: #7ba92a"
              d="M221.11,30.2c-5.39,0.29-10.78,0.59-16.17,0.88c-8.93,0.13-16.78-1.08-15.9-12.98
            c0,0,0.72,0.82,0.72,0.82c6.1-4.05,11.16-12.13,20.09-5.17c-0.48,8.69,8.9,7.78,12.31,12.69
            C221.76,27.68,221.41,28.93,221.11,30.2z"
            />
            <path
              style="fill: #7ca92e"
              d="M99.7,94.91c-3.74,1.29-7.48,2.57-11.22,3.86c-4.43-2.87-3.72-6.69-2-10.71
            c7.24-7.57,11.52-19.51,25.39-17.43c1.42,1.43,2.84,2.87,4.26,4.3C115.32,85.44,103.9,87.19,99.7,94.91z"
            />
            <path
              style="fill: #85ac27"
              d="M181.03,103.38c-12.07,1.09-11.07-10.96-16.55-16.5c2.98-2.15,5.96-3.95,8.98-0.06
            C175.98,92.34,178.51,97.86,181.03,103.38z"
            />
            <path
              style="fill: #4a6a24"
              d="M172.95-22.18c1.24,2.76,2.48,5.51,3.72,8.27c-6.91,7.91-16.72,8.13-25.99,9.84
            c-6.18,1.36-12.34,2.88-18.31-0.72c-2.99-1.44-5.39-3.62-7.53-6.11c-9.17-14.81-2.51-26.84,6.74-38.45
            c8.05-4.4,14.49-1.57,20.19,4.53c2.35,1.93,4.46,4.1,5.91,6.81C159.82-29.89,166.56-26.21,172.95-22.18z"
            />
            <path
              style="fill: #7ba92a"
              d="M188.78,14.83c-1-1.48-2-2.96-2.99-4.44c3.23-8.01,0.22-17.93,7.57-24.67
            c9.06-1.27,18.09-3.31,27.17-3.56c7.9-0.22,16.12,1.19,18.08,11.4c-1.58,3.27-3.33,9.8-7.54,5.01
            c-13.36-15.25-17.04-2.32-22.45,6.68C204.18,12.93,198.46,17.98,188.78,14.83z"
            />
            <path
              style="fill: #597f2b"
              d="M180.09-75.65c4.97,23.93,6.85,25.15,25.23,16.47c0.53,15.13-4.83,18.91-35.59,25.07
            c-1.71-1.03-2.83-2.49-3.3-4.44c5.21-11.87-3.67-26.87,8.2-37.29C176.48-76.58,178.3-76.51,180.09-75.65z"
            />
            <path
              style="fill: #2b3b1b"
              d="M193.35-14.29c-4.42,7.64,11.06,21.4-7.57,24.67c-7.07,0.28-16.93,6.33-16.87-8.16
            c0.29-10.3,11.85-6.36,15.56-12.33C187.44-11.5,190.4-12.89,193.35-14.29z"
            />
            <path
              style="fill: #9ab12e"
              d="M184.48-10.11c1.39,12.42-11.4,6.93-15.56,12.33c-1.11,0.09-2.2,0.31-3.26,0.68
            c-7.29-1.62-15.06-2.78-12.94-13.47c6.61-10.93,15.88-2.84,23.95-3.35C179.27-12.65,181.88-11.38,184.48-10.11z"
            />
            <path
              style="fill: #9ab12e"
              d="M169.46-38.47c0.09,1.45,0.19,2.91,0.28,4.36c1.07,3.98,2.15,7.95,3.22,11.93
            c-7.57-3.41-28.47,13.46-20.52-13.53C157.14-42.62,162.61-44.78,169.46-38.47z"
            />
            <path
              style="fill: #81a12f"
              d="M180.09-75.65c-0.9,0.28-1.8,0.54-2.71,0.77c-8.98,2.84-13.58-0.81-14.54-9.85
            c-0.36-3.14-1.16-6.24-0.72-9.45c1.48-5.5,3.31-10.63,10.57-10c5.28,7.16,3.94,19.48,16.38,21.07
            C186.08-80.62,183.09-78.14,180.09-75.65z"
            />
            <path
              style="fill: #85ae26"
              d="M189.12-83.27c-0.51-14.33,11.22-16.11,20.7-20.2C212.43-86.96,198.31-87.65,189.12-83.27z"
            />
            <path
              style="fill: #789f2a"
              d="M224.63-82.32c0.11,3.88-3.65,5.56-7.64,6.48c-2.49,0.57-5.9,0.79-3.6-3.79
            c1.45-2.89-2.87-8.22,4-8.9C221.41-88.93,223.96-87.95,224.63-82.32z"
            />
            <path
              style="fill: #5a7332"
              d="M-630.6-416.96c-5.01-18,12.7-6.96,16.87-13.23c12.06-2.78,22.51-13.94,36.43-6.99
            c3.2-5.64,5.22-12.58,14.43-9.96c11.48,3.26,12.57,12.43,13.41,21.94c2.92,10.56,0.8,21.57,2.72,32.25
            c0.47,6.22,0.42,12.32-3.58,17.64c-11.74,8.89-19.64,4.26-25.6-7.25c-0.57-1.88-0.7-3.85-0.55-5.81
            c-0.05-5.52,8.03-11.31-0.56-16.41c-4.57,5.26-7.63,12.66-16.61,10.75c-9.08-7.82-17.08-17.58-30.74-17.3
            C-626.95-412.66-629.3-414.22-630.6-416.96z"
            />
            <path
              style="fill: #3f4c26"
              d="M-556.6-421.95c0.24-14.81-1.64-26.72-20.71-15.22c0.01-4.08,0.02-8.15,0.03-12.22
            c0.27-8.37,6.21-9.22,12.42-8.88c9.34,0.51,18.26-1.76,27.29-3.4c5.68,4.02,11.9,0.52,17.81,1.29c3.58,2.11,5.89,4.78,4.09,9.27
            c-9.14,7.86-14.11,19.24-22.87,27.47C-544.42-421.64-549.73-413.3-556.6-421.95z"
            />
            <path
              style="fill: #7c9133"
              d="M-537.57-461.67c-10.57,12.72-25.58,11.07-39.7,12.28c-17.45-3.19-9.5-14.31-7.36-23.62
            c1.12-2,2.17-1.97,3.16,0.11c5.94,7.47,12.61,10.98,21.23,3.93c1.42-1.15,2.73-1.04,3.94,0.32c4.46,4.13,9.95,4.51,15.61,4.28
            C-539.65-463.47-538.61-462.57-537.57-461.67z"
            />
            <path
              style="fill: #5d792c"
              d="M-613.58-431.93c-7.74-4.96-15.47-9.93-23.19-14.92c0.58-1.22,0.93-3.26,1.75-3.48
            c9.61-2.51,15.49-20.93,27.14-10.03C-598.31-451.4-611.21-441.52-613.58-431.93z"
            />
            <path
              style="fill: #7cb228"
              d="M-581.47-472.9l-1.58-0.11c0,0-1.58,0-1.58,0c-7.58-4.75-5.68-9.87,7.64-20.55
            C-578.49-486.67-579.98-479.79-581.47-472.9z"
            />
            <path
              style="fill: #637849"
              d="M196.98-464.83c9.86,19.32,3.2,41.74,11.51,61.35c-6.62,7.57-14.5,5.23-22.41,2.87
            c-17.56-18.27-6.41-36.11,0.83-54.03C188.2-460.08,190.85-464.19,196.98-464.83z"
            />
            <path
              style="fill: #899b8b"
              d="M196.98-464.83c-1.28,4.28-2.56,8.55-3.84,12.83c-8.1,9.25-14.55,6.69-16.33-4.07
            c-1.9-11.5-4.4-24.23,7.77-32.88c0.2-5.76,3.41-9.01,8.74-10.48C203.02-488.8,191.22-475.88,196.98-464.83z"
            />
            <path
              style="fill: #96a666"
              d="M188.3-403.57c6.75-6.25,13.45,1.23,20.18,0.08c0.73,4.36,2.36,8.89-2.21,12.31
            c-5.71-0.23-11.41-0.46-17.12-0.7C185.92-395.56,185.2-399.42,188.3-403.57z"
            />
            <path
              style="fill: #ccb8a1"
              d="M-450.32-440.62c-4.06,13.36-11.32,20.13-25.6,12.46c-5.23,1.12-6.9-2.48-8.42-6.32
            c3-12.35-6.89-24.05-1.95-36.53c0.7-0.66,1.42-1.3,2.14-1.95c6.82,4.1,10.79,13.31,20.83,11.62
            c10.81,3.05,5.06,15.57,11.91,20.88L-450.32-440.62z"
            />
            <path
              style="fill: #c0b37c"
              d="M-495.98-493.59c3.3,9.35,10.52,3.84,16.17,4.27c3.81,4.13,2.95,8.21-0.12,12.28
            c-1.41,1.36-2.81,2.73-4.22,4.09c0,0,0.48-0.05,0.48-0.05c-8.11,7.08-15.51,7.39-21.84-2.31c-0.83-2.02-1.34-4.11-1.55-6.29
            C-509.89-491.65-504.03-493.63-495.98-493.59z"
            />
            <path
              style="fill: #9e8c5b"
              d="M-479.93-477.04c0.04-4.09,0.08-8.18,0.12-12.28c-0.63-8.84,4.09-12.58,12.33-12.99
            C-454.5-485.43-469.83-482.52-479.93-477.04z"
            />
            <path
              style="fill: #cfd2c7"
              d="M-451.41-440.45c-9.62-3.73-6.99-14.45-11.91-20.87c0.29-8.26,3.55-11.32,7.59-2.38
            C-452.58-456.73-452.73-448.26-451.41-440.45z"
            />
            <path
              style="fill: #2e3a20"
              d="M180.74-391.84c2.43,5.39,7.74,6.53,12.5,8.49c13.1,8.06,9.33,25.38,19.27,35.17
            c0.35,1.51,0.71,3.01,1.06,4.52c0.2,8.15,4.86,16.79-2.58,24.1c-1.99,2.13-4.18,4.16-3.8,7.55c1.25,4.24,2.15,8.45,0.11,12.69
            c-4.54,5.35-8.62,11.18-15.32,14.18c-7.24,0.82-14.59,2.27-20.5-3.89c-3.89-11.07-2.92-23.9-11.8-33.26
            c-2.93-9.32,3.66-18.87-0.7-28.17c-0.3-2.83-0.23-5.66-0.08-8.5c1.9-10.1,2.24-20.3,1.75-30.57c2.78-6.28,7.47-9.4,14.43-8.8
            C178.29-397.32,180.16-395.14,180.74-391.84z"
            />
            <path
              style="fill: #4a5b2b"
              d="M204.97-303.52c-2.57-2.41-5.14-4.83-7.71-7.24c4.71-2.62,4.18-8.72,7.94-11.97
            c10.95-3.7,2.67-15.11,8.37-20.92c6.97,31.77,13.93,63.55,20.9,95.33c-11.11,4.23-10.63,10.48-3.1,17.94
            c0.92,8.97-5.74,11.41-12.11,14.1c-6.14,1.64-11.02-1.58-16.08-4.14c-7.47-4.88-15.55-9.32-14.81-20.22
            c1.68-7.13,5.57-12.81,11.42-17.15c3.5-2.07,7.78-3.16,9.68-7.37c3.03-5.93-1.1-11.1-1.75-16.61
            c0.39-5.72,1.69-11.53-1.65-16.89C205.5-300.24,205.12-301.86,204.97-303.52z"
            />
            <path
              style="fill: #566533"
              d="M212.51-348.18c-25.04-1.51-17.48-20.91-19.27-35.17c9.53,2.25,19.06,4.49,28.58,6.73
            C209.09-370.29,214.29-358.09,212.51-348.18z"
            />
            <path
              style="fill: #68833b"
              d="M216.72,281.54c-2.85,9.86,13.58,13.25,8.03,24c-3.62,0.09-7.24,0.19-10.85,0.29
            c-10.34-9.75-21.18-19.21-20.69-35.35c2.66-4.79,5.29-9.71,11.42-3.68c1.27,6.93,7.69,8.14,12.42,11.23
            C217.09,279.22,216.98,280.39,216.72,281.54z"
            />
            <path
              style="fill: #343e1d"
              d="M197.05,273.89c2.68,12.2,19.44,16.96,16.84,31.95c-5.17,2.99-9.79,8.09-16.72,4.43
            c-0.16-2.44-0.32-4.87-0.48-7.31c0.9-7.57,0.15-13.93-8.32-17.48c-6.92-2.9-8.11-7.94-2.03-13.45
            C190.34,270.18,193.58,272.68,197.05,273.89z"
            />
            <path
              style="fill: #36471e"
              d="M-581.18-225.37c14.27,15.68,22.45,34.18,22.86,61.35c5.43-11.09,7.88-16.11,10.33-21.12
            c4.69-4.61,10.11-4.8,15.94-2.69c8.14,3.76,14.27,10.35,17.88,17.9c4.72,9.86,7.63,21.05-8.96,23.74
            c-8.59,0.85-13.14-9.21-22.92-8.39c3.21,4.06,8.68,4.33,10.25,8.77c5.28,14.4-0.58,26.34-8.24,37.88
            c0.93,19.55,8.75,36.83,17.4,53.88c-0.05,3.19-0.98,6.07-2.77,8.69c-3.63,4.35-8.98,4.99-13.91,6.5c-1.96,0.14-3.92-0.1-5.8-0.7
            c-5.38-8.61-15.19-2.37-21.97-6.43c-2.26-1.84-3.76-4.19-4.67-6.94c0.52-14.93-16.68-12.8-21.63-22.45
            c-4.99-7.96-14.9-9.79-20.71-17.39c-4.41-5.76-7.11-10.54-5.34-17.5c0.38-1.94,1.13-3.73,2.19-5.38
            c2.42-8.45,13.25-6.65,16.76-13.79c1.32-15.88-14.83-33.24,4.02-47.64c2.9,1.32,5.09,0.15,5.21-2.7
            c0.16-4.07-3.32-4.61-6.47-5.05c-0.56,0.35-1.09,0.75-1.68,1.03c-29.33,14.28-31.33,13.37-38.23-17.38
            C-623.08-213.26-599.66-213.13-581.18-225.37z"
            />
            <path
              style="fill: #788152"
              d="M-604.48-129.46c3.75,4.44,9.82,9.85-1.67,11.18c-5.41,0.63-7.31,4.55-10.84,6.91
            c-12.59-7.75-22.46-17.09-19.66-33.82c2.66-3.39,5.94-5.16,10.34-3.88C-619.03-142.53-611.75-135.99-604.48-129.46z"
            />
            <path
              style="fill: #a0a27f"
              d="M-633.66-144.15c-0.54,14.03,17.75,18.47,16.67,32.78c0,0-0.08,0.13-0.08,0.13
            c-2.58,3.45-5.19,6.86-9.11,8.95c-6.45,0.16-4.72-6.13-7.17-9.12c-0.74-11.5-7.38-20.43-13.24-29.58
            c-3.62-5.65,0.55-6.5,3.99-8.17C-638.64-149.26-635.35-148.14-633.66-144.15z"
            />
            <path
              style="fill: #85a13b"
              d="M-601.72-184.84c5.11-4.55,11.29-13.55,17.27-5.6c4.9,6.52-1.65,16.87-7.3,21.3
            c-8.83,6.9-9.16-1.36-8.71-7.94C-600.88-179.68-601.3-182.26-601.72-184.84z"
            />
            <path
              style="fill: #b5ac99"
              d="M-633.35-111.42c2.31,2.68,4.61,5.35,6.92,8.03c3.92,6.79,15.39,13.45,0.38,20.59
            c-2.8-2.83-5.61-5.67-8.42-8.5C-634.09-98-633.72-104.71-633.35-111.42z"
            />
            <path
              style="fill: #acb1ba"
              d="M-633.66-144.15c-2.98-1.67-5.96-3.34-8.94-5.02c1.42-5.44,3.69-11.24,10.02-10.74
            c5.3,0.41,6.8,5.66,6.27,10.85C-628.76-147.43-631.21-145.79-633.66-144.15z"
            />
            <path
              style="fill: #181d12"
              d="M-195.88-428.45c3.25-15.25,11-25.25,28.22-24.18c13.68-0.63,17.42,5.84,14.82,18.79
            c-1.89,9.42-5.77,22.19,12.74,17.88c3.45,0.92,5.58,3.35,7.23,6.34c4.18,6.32,4.28,12.51-0.57,18.55
            c-8.6,4.04-16.56,1.89-24.28-2.5c-4.44-4.47-8.04-10.77-15.67-3.51c-6.15,5.86-13,4.77-19.19-0.86
            c-4.6-6.88-6.14-14.8-7.76-22.7C-199.98-423.88-198.32-426.39-195.88-428.45z"
            />
            <path
              style="fill: #879656"
              d="M-130.98-440.49c-0.31-1.25-0.46-2.51-0.44-3.8c0.87-1.77,2.13-3.18,3.78-4.26
            c20.84-10.43,36.2-0.09,50.79,13.39c4.4,5.15,5.3,11.03,3.72,17.43c-7.74,12.37-20.71,14.24-33.49,16.55
            c-1.65-0.9-3.05-2.1-4.21-3.58c-3.71-7.45-0.42-18.35-11.06-22.41C-127.22-430.04-128.83-435.44-130.98-440.49z"
            />
            <path
              style="fill: #6f7b3f"
              d="M-130.98-440.49c4.13,2.78,8.27,5.56,12.4,8.35c2.75,2.28,3.54,5.32,3.33,8.74
            c-4.04,11.39-10.02,19.78-24.11,15.56c-0.25-2.71-0.5-5.41-0.75-8.12C-140.2-425.3-135.84-432.99-130.98-440.49z"
            />
            <path
              style="fill: #6f7b3f"
              d="M-126.6-444.61c-1.61,0.11-3.21,0.21-4.82,0.32c-10.57-2.87-8.31-13.23-11.78-20.25
            c7.76-3.7,10.9-13.71,20.44-15.04c2.1,0.02,3.99,0.64,5.71,1.83C-107.98-463.17-111.48-452.2-126.6-444.61z"
            />
            <path
              style="fill: #80913e"
              d="M-123.39-476.48c-3.34,9.4-6.4,19.28-19.81,11.94c-1.06-6.82-2.11-13.63-3.17-20.45
            c4.03-4.64,8.35-3.38,12.75-0.64C-129.71-483.15-125.05-481.49-123.39-476.48z"
            />
            <path
              style="fill: #a1ac3c"
              d="M-135-481.14c-3.79-1.29-7.58-2.58-11.37-3.86c-2.81-3.98-6.95-7.88-0.77-12.43
            c6.03-3.25,10.43-0.4,14.48,3.91c0.56,1.72,1.08,3.4,1.61,5.08C-129.49-484.44-131.9-482.6-135-481.14z"
            />
            <path
              style="fill: #35461e"
              d="M-573.95-386.96c6.04,4.31,9.6,16.13,20.17,6.64c18.04-8,23.45-5.29,30.55,15.26
            c-0.12,2.34-0.74,4.56-1.81,6.64c-2.69,3.88-6.38,5.97-11.14,6.05c-10.91-3.48-21.14-9.86-33.66-6.28
            c-4.35,1.25-9.07-0.69-11.26-5.69c-8.17-10.62-19.31-15.05-32.33-15.58c-2.98-5.7-5.97-11.4-8.95-17.09
            c-4-6.67-7.86-13.34,0.93-19.39c18.06-5.57,29.47-1.67,28.41,19.99C-587.2-392.2-576.63-397.55-573.95-386.96z"
            />
            <path
              style="fill: #61852e"
              d="M-581.1-364.35c7.77-1.11,15.63-1.77,23.29-3.42c11.37-2.46,19.62-0.18,22.47,12.4
            c0.71,14.79-7.83,16.16-19.2,13.16c-5.65-1.49-9.84-7.59-16.68-5.32c-4.76,2.24-9.31,5.05-14.62,5.95
            c-4.13,0.56-5.5-3.79-8.68-4.92C-599.88-359.86-583.28-356.66-581.1-364.35z"
            />
            <path
              style="fill: #5a7a2b"
              d="M-658.56-335.42c1.35-5.42,4.24-9.32,9.55-11.77c7.85-3.62,4.5-6.24-1.19-8.23
            c3.62-8.52,10.84-2.61,16.22-4.06c3.16,3.74,10.63,4.47,8.39,12C-635.47-340.4-640.79-320.86-658.56-335.42z"
            />
            <path
              style="fill: #739d2e"
              d="M-625.6-347.48c-3.3-3.64-12.5-3.16-8.39-12c11.11-1.84,9.68-16.6,19.89-19.37
            c0.52,7.28-0.03,14.83,1.79,21.77C-608.09-341.01-615.65-342.98-625.6-347.48z"
            />
            <path
              style="fill: #567f2d"
              d="M-594.53-346.5c2.93,1.19,5.87,2.37,8.8,3.56c1.02,0.51,1.51,1.17,1.46,1.99
            c-0.05,0.9-0.21,1.35-0.48,1.35c-8.25,5.65-16.49,11.3-24.74,16.95C-613.83-336.46-593.78-334.95-594.53-346.5z"
            />
            <path
              style="fill: #6f9a2c"
              d="M-621.44-416.42c-0.31,6.47-0.62,12.94-0.93,19.4c-11.77-1.53-5.59-13.52-10.93-18.79
            c0.88-0.43,1.78-0.81,2.7-1.15C-627.55-416.78-624.5-416.6-621.44-416.42z"
            />
            <path
              style="fill: #465631"
              d="M-572.98,5.89c-7.95-0.26-14.15,3.62-19.97,8.42c-1.58-0.13-3.15-0.26-4.73-0.4
            c-15.09-1.24-18.86-14.53-25.93-24.33c-5.82-5.67-6.73-12.8-6.06-20.4c-2.43-3.64-9.07-5.8-4.2-11.99
            c18.12-4.5,36.18,1.03,54.31,0.33c13.17,10.36,14.68,25.2,15.48,40.33C-565.63,2.1-569.77,3.48-572.98,5.89z"
            />
            <path
              style="fill: #758763"
              d="M-617.71-17.95c4.53,1.06,11.4,3.92,10.03,7.27c-5.07,12.36,6.67,16.53,9.99,24.6
            c-9.83,10.72-19.65,21.45-29.48,32.17c-9.67-1.8-8.66-12.25-7.14-15.56c6.12-13.32-0.18-25.57-0.55-38.22
            C-633.77-18.89-623.88-15.31-617.71-17.95z"
            />
            <path
              style="fill: #82908b"
              d="M-629.88-5.97c5.44,17.12-0.29,34.83,2.72,52.07c-0.81,12.19-12.92,7.83-18.68,12.77
            c-1.47-2.38-2.93-4.75-4.39-7.13c1-6.13,14.94-9.06,4.45-18.03c-5.28-11.18,16.63-15.42,6.81-29.45
            c-3.31-4.73-5-12.94-8.1-19.31C-633.84-26.23-635.24-9.7-629.88-5.97z"
            />
            <path
              style="fill: #58732f"
              d="M191.86-139.91c-3.8,3.9-10.42,4.68-12.11,10.93c-4.86,3.09-9.18,7.34-15.56,7.09
            c-1.99-0.03-3.92-0.37-5.82-0.96c-5-2.19-7.75-6.02-8.38-11.42c0.13-6.29,4.11-10.48,8-14.74c5.09-8.77,14.18-11.31,22.78-14.66
            c11.42,1.17,13.31,9.05,12.99,18.49C193.16-143.42,192.52-141.66,191.86-139.91z"
            />
            <path
              style="fill: #c4d356"
              d="M161.44-127.36c0.95,0.08,1.88,0.25,2.8,0.52c9.31,2.83,12.11,8.95,9.11,18.01
            c-0.22,1.55-0.43,3.1-0.65,4.65c-4.39,3.06-4.19,7.71-4.24,12.27c-2.94,5.3-4.58,12.06-12.85,11.31
            c-3.23-0.78-5.6-2.69-7.25-5.54c-3.07-6.16-0.3-11.92,1.24-17.76C152.58-112.2,147.76-124.47,161.44-127.36z"
            />
            <path
              style="fill: #81a12f"
              d="M173.34-108.83c-3.04-6.01-6.08-12.01-9.11-18.01c5.16-0.85,9.83-5.17,15.52-2.14
            c-0.31,3.53-2.61,7.4,1.69,10.17c5.09,1.6,9.2,3.86,6.95,10.38C183.37-108.56,178.36-108.69,173.34-108.83z"
            />
            <path
              style="fill: #c8d2a3"
              d="M191.86-139.91c-0.66-1.36-1.32-2.72-1.99-4.08c4.37-11.97,10.97-17.78,23.11-8.44
            c2.17,5.16,0.8,9.06-4.14,11.7C203.18-140.46,197.52-140.18,191.86-139.91z"
            />
            <path
              style="fill: #a5c41d"
              d="M188.39-108.43c-2.32-3.47-4.64-6.93-6.95-10.39c4.42-2.2,9.62-7.26,13.48-1.21
            C198.52-114.38,193.45-110.74,188.39-108.43z"
            />
            <path
              style="fill: #536636"
              d="M-71.21-465.99c8.3-2.35,16.6-4.7,24.9-7.05c16.21,1.7,13.71,14.35,14.64,24.95
            c-0.16,1.9-0.39,3.85-0.63,5.74c-1.84,5.4-4.28,10.88,1.09,15.9c2.08,3.49,2.87,7.23,2.51,11.26c-0.26,2.15-0.99,4.1-2.13,5.92
            c-2.83,3.42-6.36,5.72-10.7,6.7c-3.2,0.43-6.4,0.43-9.61,0c-11.09-3.08-18.58-9.69-21.3-21.26c-1.26-5.37-4.51-9.52-9.09-12.59
            c-3.14-4.56-3.38-9.32-0.98-14.26C-79.32-456.19-73.05-459.44-71.21-465.99z"
            />
            <path
              style="fill: #c0c79e"
              d="M-77.92-452.52c-0.13,5.21-0.25,10.43-0.38,15.64c0.02,2.53-1.55,4.25-3.07,5.99
            c-8.04-2.88-24.03,3.1-15.81-19.16c-10.65,8.95-19.8,10.16-29.42,5.43c4.03-10.09,8.97-19.91,8.89-31.17
            c5.69-6.67,8.1-0.61,11.17,2.89c3.5,0.29,7.01,0.58,10.5,0.87c1.8,5.15,3.61,10.29,5.42,15.44
            C-86.73-454.16-81.12-457.1-77.92-452.52z"
            />
            <path
              style="fill: #a1a781"
              d="M-37.68-445.26c-4.51-8.75-0.9-20.02-8.62-27.78c1.1-3.77,2.2-7.54,3.29-11.31
            c5.5,3.45,10.95,7.05,17.76,7.3c3.97,5.87,11.9-2.92,15.53,4.2c3.34,3.47,5.62,7.36,5.01,12.43
            c-1.97,11.97-9.41,18.42-20.92,20.77C-30.77-439.09-34.6-441.37-37.68-445.26z"
            />
            <path
              style="fill: #596d3d"
              d="M-77.92-452.52c-4.45-0.69-10.55,3.8-12.7-4.07c4.75-6.7,12.46-7.26,19.41-9.4
            c2.29,4.38,2.96,8.97,1.39,13.69c-0.73,2.2,0.4,6.36-3.28,5.81C-74.97-446.77-76.33-450.4-77.92-452.52z"
            />
            <path
              style="fill: #3e5321"
              d="M-581.17-38.71c-18.24,7.31-36.16,10.56-52.7-4.09c0.08-1.33,0.19-2.67,0.33-3.99
            c3.68-3.76,7.24-7.7,12.78-8.72c3.69-4.18,2.52-9.6,3.69-14.43c4.8-10.21,11.97-14.62,23.05-9.45
            c11.68,4.75,26.51,6.28,25.02,24.51C-576.77-52.31-576.13-43.37-581.17-38.71z"
            />
            <path
              style="fill: #778a4a"
              d="M-594.01-79.4c-6.36,4.33-14.98,5.18-19.36,12.57c-5.07-1.92-9.71-4.45-12.91-9.05
            c0.08-2.31,0.15-4.61,0.23-6.92c8.42-7.02-2.15-13.7-0.38-20.6c1.94-4.02,5.12-6.57,9.36-7.85
            C-618.06-94.33-598.19-92.56-594.01-79.4z"
            />
            <path
              style="fill: #908e63"
              d="M-626.27-75.88c7.17-1.09,13.44-0.9,12.9,9.04c-0.51,5.05-0.96,10.15-7.39,11.32
            C-619.13-63.25-629.56-67.7-626.27-75.88z"
            />
            <path
              style="fill: #828e46"
              d="M59.57-416.26c2.74-1.18,5.47-2.36,8.21-3.53c6.08-1.63,6.45-6.62,5.48-11.19
            c-1.96-9.26,3.7-13.41,10.25-17.3c1.61-1.77,3.23-3.54,4.84-5.31c1.26,2.94,2.51,5.88,3.76,8.82
            c3.94,7.71,0.47,18.82,10.83,23.58c1.48,1.21,2.62,2.67,3.5,4.36c0.94,2.77,0.82,5.49-0.35,8.17
            c-4.5,5.54-9.13,10.98-15.44,14.67c-6.2,1.51-9.76,5.16-12.08,11.41c-2.48,6.69-6.48,13.18-15.69,11.71
            c-6.75-4.46-6.14-14.63-14.09-18.18c-3.84-4.48-3.99-9.69-3.02-15.13C47.47-411.54,51.93-415.73,59.57-416.26z"
            />
            <path
              style="fill: #bec9aa"
              d="M115.41-456.02c5.06-6.54,1.61-18.44,13.14-20.9c11.1-1.76,8.32,10.15,13.81,13.96
            c2.85,5.05,6.27,9.92,5.45,16.23c-0.44,2.11-1.4,3.93-2.85,5.51c-7.49,4.88-15.29,8.22-24.44,5.05
            c-2.01-1.05-3.63-2.52-4.9-4.39C112.97-445.67,111.3-450.81,115.41-456.02z"
            />
            <path
              style="fill: #919e57"
              d="M130.13-456.82c1.46-6.86,4.06-13.8-1.57-20.09c-4.31-3.84-8.63-7.68-12.95-11.51
            c7-3.68,8.6-10.17,8.99-17.3c4.44,14.38,15.69,22.23,27.9,29.16C150.93-463.3,144.34-455.74,130.13-456.82z"
            />
            <path
              style="fill: #9aa46c"
              d="M100.04-416.25c-7.77,1.86-13.44,0.84-11.78-9.21c1.07-6.47,2.55-12.88,3.85-19.31
            c1.57,2.88,3.14,5.76,4.7,8.64C106.77-430.95,104.97-423.86,100.04-416.25z"
            />
            <path
              style="fill: #9aa46c"
              d="M115.41-456.02c1.43,3.79,2.86,7.58,4.29,11.37c-2.24,5.96-4.72,11.67-12.37,12.07
            c-3.29-0.2-5.89-1.63-7.75-4.37C104.4-443.69,105.63-453.41,115.41-456.02z"
            />
            <path
              style="fill: #bcc2b6"
              d="M99.59-436.95c2.28,0.4,4.57,0.8,6.85,1.2c8.48,8.08,9.4,15.18-2.65,20.61
            c-1.25-0.37-2.5-0.74-3.75-1.12c-1.08-6.62-2.16-13.25-3.23-19.87C97.76-436.33,98.69-436.6,99.59-436.95z"
            />
            <path
              style="fill: #77723c"
              d="M-504.11-481.16c6.45,3.63,10.69,12.82,20.44,8.15c1.77,12.46,15.37,23.43,4.73,37.46
            c-4.56,0.95-9.11,1.97-13.81,0.94c-9.94-1.67-14.22-11.18-21.99-15.88c-0.47-0.79-0.9-1.59-1.34-2.4
            c-1.22-2.5-2.45-4.99-3.67-7.49c4.2-1.01,7.08-3.42,8.18-7.72C-509.54-472.7-508.49-477.88-504.11-481.16z"
            />
            <path
              style="fill: #3b3c1b"
              d="M-504.11-481.16c0.19,5.89-0.94,11.03-7.47,13.06c-2.96-3.54-10.42-0.27-11.11-6.35
            c-0.76-6.72,6-7.75,10.17-10.6c1.82,1.15,3.64,2.29,5.46,3.44C-506.06-481.56-505.08-481.41-504.11-481.16z"
            />
            <path
              style="fill: #7fa728"
              d="M-556.31-468.64c-1.31-0.15-2.63-0.26-3.94-0.32c-0.21-6.22-2.21-12.84,4.92-18.82
            C-551.72-480.59-550.58-474.61-556.31-468.64z"
            />
            <path
              style="fill: #29361b"
              d="M172.85,123.7c4-1.49,8-2.98,12-4.47c2.7,4.99,5.4,9.97,8.1,14.96
            c1.31,12.21,5.8,21.46,20.06,21.62c3.21,2.27,16.43-18.33,10.87,4.96c-1.48,6.18,4.2,1.92,6.73,1.78
            c5.95-0.33,7.86,5.75,5.04,7.16c-11.7,5.86-1.64,16.34-6.19,23.25c-7.15,5.67-16.99,10.79-4.12,20.5
            c1.24,6.05-6.91,9.71-4.1,16.16c-2.68,2.32-5.37,4.64-8.05,6.96c-1.27,6.46,0.67,13.71-4.69,19.18c-4.89,2.56-9.54,1.92-14-1.05
            c-21.95-16.75-25.02-44.35-6.63-58.2c1.02-0.8,1.34-1.37,0.63-1.38c-9.43,4.17-14.44,10.73-13.12,21.2
            c-0.1,4.62-0.61,9.25,0.54,13.84c1.18,6.85-1.13,12.15-6.94,15.92c-10.73,2.8-23.64,17.56-29.73-5.35
            c1.24-5.64,4.39-11.13-0.3-16.92c-11.09-13.68-0.12-27.77,0.01-41.65c0.06-6.4,7.65-8.43,13.62-9.86
            c2.73-4.21,5.47-8.42,8.2-12.62c9.52-6.44-1.15-16.62,4.39-23.8C167.74,131.82,170.3,127.76,172.85,123.7z"
            />
            <path
              style="fill: #65852d"
              d="M213.01,155.81c-10.09,14.07-19.66-6.21-30.32-0.77c3.42-6.95,6.84-13.9,10.26-20.85
            c0,0,0.19,1.77,0.19,1.77c5.46,3,11.98,4.95,12.51,12.78C208.1,151.1,210.56,153.45,213.01,155.81z"
            />
            <path
              style="fill: #97b749"
              d="M205.65,148.74c-5.88-2.58-12.16-4.77-12.51-12.78c5.38-2.74,10.76-5.49,16.14-8.23
            C204.35,134.09,216.91,143.48,205.65,148.74z"
            />
            <path
              style="fill: #5b7129"
              d="M59.57-416.26c-0.44,5.74-5.15,8.13-8.86,11.3c-3.42,5.61-7.75,9.77-14.94,9.32
            c-4.18-0.74-7.47-2.94-10.06-6.26c-2.93-6.12,0.52-13.04-2.42-19.21c0.3-15.62,15.31-12.33,23.27-18.16
            c5.79-0.77,9.17,2.93,12.52,6.73C59.23-427.11,59.4-421.68,59.57-416.26z"
            />
            <path
              style="fill: #4f6126"
              d="M83.51-448.28c-7.71,8.07-4.35,17.54-3.27,26.67c0.76,6.39,4.63,15.04-5.72,15.66
            c-9.17,0.55-5.76-8.48-6.74-13.83C66.43-432.94,64.69-446.3,83.51-448.28z"
            />
            <path
              style="fill: #627b2c"
              d="M43.52-473.31c9.36,1.5,18.71,3.01,28.07,4.51c3.9,9.62,0.93,16.32-8.59,20.24
            c-6.34-1.36-13.49,7.56-19.28-1.47c-4.08-6.78-5.79-13.83-2.35-21.45C42.08-472.1,42.8-472.71,43.52-473.31z"
            />
            <path
              style="fill: #7d9e2b"
              d="M47.51-452.35c3.83,6.74,12.56-6.64,15.49,3.78c-4.24,4.63-3.37,10.51-3.94,16.03
            c-4.2-0.76-8.39-1.52-12.59-2.27c-7.05,2.98-14.68,4.68-20.03-1.88c-4.74-5.82-0.21-11.06,4.25-15.41
            C36.25-455.61,41.84-456.81,47.51-452.35z"
            />
            <path
              style="fill: #6c7a51"
              d="M-629.88-5.97c-8.01,1.32-7.86-12.84-17.18-9.08c-8.29-2.72-6.49-9.65-6.58-15.79
            c7.98,5.12,15.97,4.71,23.97,0.04c9.03-0.42,4.72,11.61,11.97,12.85C-622.03-14.24-628.88-13.09-629.88-5.97z"
            />
            <path
              style="fill: #2a301b"
              d="M180.74-391.84c-1.58-1.56-3.16-3.11-4.74-4.67c-19.36-3.05-19.4-19.81-22.53-33.44
            c-1.59-6.94-1.04-14.61-2.6-21.84c-2.06-9.4-0.93-17.98,6.32-25c4.03,1.1,9.08,8.87,11.63-0.92
            c2.26-8.67,8.09-10.88,15.75-11.24c0.11,10.97,0.94,22,0.13,32.91c-0.78,10.55,5.7,2.68,8.43,4.05
            c-5.28,15.78-11.95,31.42-4.83,48.43c0.28,3.9,0.57,7.8,0.85,11.69C186.35-391.86,183.55-391.85,180.74-391.84z"
            />
            <path
              style="fill: #738a58"
              d="M157.19-476.8c-0.07,8.15-0.14,16.29-0.21,24.44c-3.63,2.69-6.92,6.53-12.2,3.56
            c-4.89-2.67-9.78-5.34-14.66-8.02c6.32-7.9,12.89-15.49,22.38-19.75c0.39-1.69,0.79-3.38,1.18-5.08
            C154.85-480.03,156.02-478.42,157.19-476.8z"
            />
            <path
              style="fill: #627b2c"
              d="M35.04-505.2c9-0.59,11.47,4.28,10.49,12.3c-0.8,6.51-1.35,13.06-2.02,19.59
            c-0.44,0.51-0.88,1.03-1.32,1.55c-3.68,2.84-7.46,2.75-11.33,0.41c-8.4-12.54,5.9-22.3,4.18-34.03L35.04-505.2z"
            />
            <path
              style="fill: #1e2316"
              d="M198.43,249.64c3.72,1.22,7.45,2.44,11.17,3.66c1.58-1.08,2.7-0.66,3.42,1.06
            c4.66,9.2-2.5,10.4-8.41,12.45c-4.98-0.27-7.88,1.68-7.58,7.08c-2.96,3.17-5.77,2.71-8.47-0.34
            c-9.26-0.55-18.1-3.18-16.66-14.35c1.29-10.02,10.62-9.87,18.67-9.77C193.2,249.46,195.82,249.52,198.43,249.64z"
            />
            <path
              style="fill: #8d973d"
              d="M213.03,254.36c-1.09-0.53-2.23-0.88-3.42-1.06c-3.96-6.68-4.6-12.65,3.59-16.73
            C217.27,242.54,224.58,248.54,213.03,254.36z"
            />
            <path
              style="fill: #565c2a"
              d="M-106.53-472.89c-3.72-0.96-7.45-1.93-11.17-2.89c-1.89-0.23-3.79-0.47-5.68-0.7
            c-3.87-1.55-7.74-3.1-11.61-4.66c1.32-2.44,2.63-4.88,3.95-7.31C-123.61-482.11-105.35-492.85-106.53-472.89z"
            />
            <path
              style="fill: #4a702a"
              d="M188.78,14.83c7.06-2.26,14.16-4.45,19.84-9.58c1.28,2.71,3.29,5.32,1.21,8.51
            c-7.01,0.49-10.51,14.69-20.09,5.16C189.43,17.55,189.11,16.19,188.78,14.83z"
            />
            <path
              style="fill: #b7c867"
              d="M212.98-152.43c-8.68,0.14-15.96,4.11-23.11,8.44c-3.75-4.94,2.17-15.28-8.97-16.1
            c-6.82-0.37-4.6-4.29-3.15-7.91c3.91-3.59,4.34-7.2,1.11-11.82c-4.45-6.37-5.1-13.16,0.14-19.72c2.27-2.09,4.95-3.25,8-3.56
            c8.85-3.46,17.7-2.06,26.55-0.02c2.93,0.26,5.85,0.6,8.53,1.94c6.43,5.05,9.78,11.54,9.14,19.86
            c-4.8,5.85,0.05,14.75-6.31,20.13C220.06-159.49,215.24-157.7,212.98-152.43z"
            />
            <path
              style="fill: #9db689"
              d="M230.28-179.93c-3.07-5.21-6.15-10.43-9.22-15.64c-2.33-3.65-2.38-7.46-0.79-11.39
            c3.49-4.29,4.33-12.04,12.79-9.84c1.58,2.47,3.16,4.94,4.74,7.41c0,9.55,0,19.1,0,28.64c-1.2,0.4-2.4,0.82-3.59,1.25
            C232.78-178.57,231.47-178.72,230.28-179.93z"
            />
            <path
              style="fill: #7a9c43"
              d="M230.28-179.93c1.32,0.07,2.62,0.21,3.92,0.43c-1.41,6.96,0.15,15.43-9.28,18.3
            C224.95-167.94,218.77-176.47,230.28-179.93z"
            />
            <path
              style="fill: #29371b"
              d="M35.05-505.37c6.4,11.75,6.39,22.62-4.43,31.98c0.72,6.38-3.15,9.94-7.9,12.95
            c-3.8,2.05-6.46,5.66-10.23,7.78c-4.11,3.26-5.45,9.96-12.21,9.96c-9.42-3.04-11.03-10.4-10.49-18.95
            c0.16-3.74,0.32-7.47,0.49-11.21c6.9-1.11,13.76-4.04,20.76-0.43c3.51-9.03-3.8-21.37,8.44-27.73c0.82-0.3,1.64-0.55,2.48-0.77
            C28.08-496.59,31.27-502.06,35.05-505.37z"
            />
            <path
              style="fill: #889566"
              d="M19.49-501.02c-4.26,8.8,6.45,22.17-8.44,27.74c-2.27-8.85-14.15-13.16-11.5-24.35
            C6.19-498.76,12.84-499.89,19.49-501.02z"
            />
            <path
              style="fill: #c7d3a6"
              d="M233.06-216.8c-2.4,4.12-4.81,8.23-7.21,12.35c-8.71-2.47-12.36-7.42-7.95-16.3
            c4.49-3.22,8.98-6.44,13.47-9.65c3.24,1.49,4.32,4.13,4.05,7.48C234.61-220.9,233.84-218.85,233.06-216.8z"
            />
            <path
              style="fill: #2f3d1b"
              d="M-500.11-200.96c2.64-5.5,5.28-11,7.92-16.5c2.7,0,5.39-0.15,8.09-0.14
            c20.92,0.08,20.95,0.09,15.27-19.45c-0.77-2.65-0.85-5.3-0.81-8.04c-0.56-8.12,1.48-15.74,4.7-22.97
            c5.71-12.82,14.36-18.6,28.07-10.65c21.07,11.69,17.98,33.6,20.13,52.1c2.95,25.33-2.91,50.75-4.17,76.18
            c-0.32,6.44-7.03,9.85-7.85,16.07c-0.45,2.51,0.31,6.07-1.47,7.41c-12.7,9.61-4.72,16.25,2.89,23.08
            c-5.78,5.62-13.01,4.13-19.94,4.08c-14.53,1.78-18.22,19.36-32.12,22.08c-1.79-0.62-3.33-1.63-4.59-3.05
            c-6.23-8.78-4.77-19.68-8.04-29.29c-1.33-7.47-4.12-14.63-4.62-22.28c6.76-13.68,14.43-26.53-0.6-40.95
            c-5.74-5.51-0.79-16.61-3.09-24.91C-500.26-199.1-500.18-200.03-500.11-200.96z"
            />
            <path
              style="fill: #2e3b1d"
              d="M-410.59,98.89c-7.22,0.8-16.99,16.78-20.69-3.47c-2.8-5.38-3.8-11.63-7.8-16.44
            c-10.12-4.68-5.43-12.71-4.48-19.89c2.3-6.23,6.72-9.26,13.39-8.9c11.35,10.11,16.09,3.83,19.04-7.55
            c3.12-12.02,4.14-25.62,20.01-28.83c11.3,0.75,14.65,12.37,23.18,16.94c2.88,4.99,0.17,10.65,1.99,15.76
            c2.8,4.63,5.53,9.22,13.95,8.16c-10.06-15.82,15.48-31.43-1.32-47.56c1.82-13.03,2.4-26.06,0.16-39.13
            c4.42-10.39,10.88-10.43,18.69-3.74c8.72,5.65,12.2,14.27,11.3,24.02c-0.74,7.98,1.71,14.26,6.16,20.3
            c4.95,14.79,6.18,30.31,13.02,45.05c7.85,16.91-0.99,30.2-23.39,44.57c-8.62,3.29-17.57-6.09-26.11,0.76
            c-1.84,0.32-3.67,0.24-5.5-0.06c-6.61-1.56-10.04-7.39-15-11.2c-13.17-15.03-13.71,5.42-20.82,7.43
            c-15.04-1.81-5.72-12.59-6.91-19.49C-400.59,85.02-409.13,90.64-410.59,98.89z"
            />
            <path
              style="fill: #1b1f14"
              d="M-480.11-79.51c7.38-12.54,10.25-32.39,32.83-20.27c0.95,25.68,2.78,27.51,26.42,26.75
            c1.71-0.05,4.72-0.03,4.96,0.69c4.4,13.3,7.38,26.95,14.26,39.56c3.57,6.54-4.76,11.74-7.3,17.71
            c-8.33,19.57-24.94,32.85-37.29,49.36c-3.45,4.61-7.47,11.94-14.05,8.91c-8.6-3.95-3.32-11.39-1.31-17.54
            c0.83-2.54,1.3-5.2,1.94-7.8c-3.83-2.54-4.66-6.32-4.37-10.56c11.69-20.4,11.42-21.3-12.52-41.59
            c-9.84-4.69-9.17-14.97-12.38-23.19C-488.13-65.67-492.94-76.13-480.11-79.51z"
            />
            <path
              style="fill: #3e5321"
              d="M-459.27,6.06c-0.13,3.94-0.25,7.87-0.38,11.81c-2.62,1.49-5.25,2.98-7.87,4.47
            c-27.46,1.66-27.46,1.66-24.1,28.35c-0.21,0.62-0.24,1.56-0.67,1.8c-13.71,7.67-24.73,16.55-16.17,34.63
            c-19.95,1.53-15.33-13.29-16.22-24.44c-12.1-10.01-5.1-21.71-2.85-32.95c0.76-3.8,3.71-7.87,0.52-11.31
            c-3.8-4.09-7.25,0.14-10.54,1.79c-7.49,3.75-13.24,3.65-18.62-4.3c-3.66-5.4-9.87-8.9-16.81-10c1.42-2.8,2.85-5.6,4.27-8.4
            c8.32-6.82-3.92-21.34,9.74-26.17c3.58-0.47,7.34,0.31,8.65,3.59C-541.11-2.01-520-6.26-501.43-1.63
            c-8.38-20.53-28.04-36.16-15.2-60.05c2.69-2.72,5.41-5.41,8.94-7.06c11.28-5.22,16.98,4.21,24.32,9.32
            c3.81,6.97,4.97,15.48,11.98,20.58c3.02,9.84,3.55,20.45,11.49,28.6C-455.54-5.77-455.26,0.46-459.27,6.06z"
            />
            <path
              style="fill: #435535"
              d="M-371.25,30.08c-14.57,4.31-14.1-9.79-19.88-16.26c1.28-9.69-1.24-18.93-3.41-28.2
            c5.74-9.16,6.61-18.4,0.4-27.73c-3.31-5.46-3-9.96,3.41-12.84c2.41-2.1,4.81-4.2,8.08-4.89c4.37-0.5,8.21,0.64,11.44,3.67
            c3,8.39,12.58,11.22,16.29,19.04c0.61,13.54,6.25,26.98,1.02,40.68c-0.25,2.76-0.41,5.54-1.79,8.05
            c-3.85,6.16-9.24,11.18-13.03,17.4C-369.56,29.37-370.41,29.72-371.25,30.08z"
            />
            <path
              style="fill: #446166"
              d="M-294.19,159.53c-25.83,2.11-28.75,5.62-27.66,33.15c-9.49,12.9-19.27,0.2-28.9,0.31
            c-5.79-8.99-6.93-18.6-3.87-28.78c9.31-10.84,6.98-33.7,30.62-29.76c10.34,4.28,16.69,14.99,28.12,17.53
            c2.26,2.25,3.77,4.72,2.15,8.02L-294.19,159.53z"
            />
            <path
              style="fill: #36573a"
              d="M-321.65,139.82c-6.43,0.46-14.59-0.49-15.51,8.19c-1.2,11.25-10.86,11.71-17.45,16.2
            c-2.52-14.84-5.03-29.68-7.55-44.52c3.92-5.06,9.03-4.58,14.35-3.05c13.38,3.84,16.7-2.6,14.75-14.47
            c0.71-1.88,1.86-3.39,3.53-4.52c7.75-4.22,13.53-0.33,19.06,4.59c5.57,5.37,13.99,9.4,9.43,19.65
            c-5.81,5.88-11.35,12.09-17.98,17.14C-319.9,139.3-320.78,139.56-321.65,139.82z"
            />
            <path
              style="fill: #5b5e1f"
              d="M-427.34-103.86c-3.94-0.19-9.11,1.15-11.59-0.85c-7.78-6.27-24.22-0.06-24.9-11.12
            c-0.62-10.06,14.5-2.11,21.2-7.63c3.1-2.55,6.36-4.41,7.6-8.44c8.08-8.91,18.75-8.2,29.33-7.98c5.02,1.72,9.13,4.53,11.34,9.6
            c2.68,9.88,0.32,18.54-6.4,26.15c-4.59,3.43-9.29,4.7-14.16,0.49C-419.01-107.4-423.14-107.46-427.34-103.86z"
            />
            <path
              style="fill: #31481e"
              d="M-491.62,50.68c-3.97-4.19-14.71,3-14.21-9.46c0.68-17.03,8.61-27.89,24.56-32.56
            c11.83-3.46,8.13,10.1,13.75,13.67C-476.15,31.28-475.68,47.97-491.62,50.68z"
            />
            <path
              style="fill: #4d722a"
              d="M-524.68,62.67c5.26,8.25,7.58,18.45,16.23,24.44c2.99,2.6,5.98,5.21,8.97,7.81
            c-0.29,8.42-2.63,16.48-11.47,18.59c-7.96,1.9-10.51-6.21-14.17-11.41c-2.83-4.02-6.71-6.11-11.44-6.9
            c-4.21-9.03,2.06-16.31,4.06-24.3C-529.88,68.15-527.28,65.41-524.68,62.67z"
            />
            <path
              style="fill: #446531"
              d="M-329.4,103.29c5.95,5.96,6.79,12.79,2.09,19.57c-4.96,7.14-11.77,3.33-17.92,1.77
            c-5.69-1.45-11.29-3.27-16.93-4.93c-6.32-1.76-8.93-5.83-7.68-12.32c3.34-5.16,4.44-12.61,12.84-12.61l1.95,0.33
            C-344.34,91.04-337.41,98.85-329.4,103.29z"
            />
            <path
              style="fill: #73813b"
              d="M-382.9-55.27c-2.61,0.1-5.22,0.21-7.83,0.31c0.09-1.36-0.05-2.7-0.41-4.01
            c1.14-13.96-17.12-24.4-7.24-39.96c6.15-5.88,12.3-11.73,21.75-6.48C-367-89.12-369.81-66.67-382.9-55.27z"
            />
            <path
              style="fill: #475e27"
              d="M-410.59,98.89c-2.6-14.47,7.21-27.4,5.21-41.93c-0.34-2.49,3.41-4.3,6.18-2.94
            c5.5,2.7,7.64,7.17,5.89,13.37c-2.58,9.11-11.58,17.91-1.52,27.72C-399.52,98.73-404.81,99.85-410.59,98.89z"
            />
            <path
              style="fill: #3b3f1d"
              d="M-393.69-96.23c-8.4,13.05,8.99,24.34,2.55,37.26c-7.22-15.27-20.39-27.37-23.79-44.68
            c4.18-1.41,8.36-2.82,12.55-4.23C-393.61-108.39-393.15-102.68-393.69-96.23z"
            />
            <path
              style="fill: #3f534c"
              d="M-337.86,204.13c5.19,12.43,15.63,26.43-8.94,29.97c-0.97-12.58-1.93-25.16-2.9-37.74
            C-341.58,192.6-337.83,195.48-337.86,204.13z"
            />
            <path
              style="fill: #667f9b"
              d="M-337.86,204.13c-2.54-4.73-6.46-7.37-11.84-7.77c-0.49-1.08-0.84-2.2-1.06-3.37
            c9.48-14.47,19.31,4.04,28.9-0.32c1,4.15,5.33,8.16,1.57,12.58C-326.41,212.49-331.88,203.17-337.86,204.13z"
            />
            <path
              style="fill: #4c7367"
              d="M-357.01,94.77c-2.53,6-1.95,15.16-12.84,12.61c-5.62-5.67-10.71-11.45-4.16-19.69
            C-364.48,80.75-359.94,85.84-357.01,94.77z"
            />
            <path
              style="fill: #446226"
              d="M-439.09,78.99c6.37,3.69,19.29,4.25,7.81,16.44c-6.25-2.92-14.5,7.73-18.98-1.48
            C-455.13,83.92-440.11,86.15-439.09,78.99z"
            />
            <path
              style="fill: #3a582c"
              d="M-447.51,156c-5.38,2.99-10.78,0.69-16.18,0.06c-0.34-8.66,10.95-8.26,12.2-15.67
            c-8.04-4.93-20.16-8.32-7.91-20.93c2.68,2.7,5.35,5.4,8.03,8.1C-448.08,136.76-441.42,145.51-447.51,156z"
            />
            <path
              style="fill: #547529"
              d="M-459.4,119.45c-4.21,9.57,5.04,14.04,7.91,20.93c-14.85,5.66-17.66-6.03-22.54-16.18
            C-469.06,122.59-464.23,121.02-459.4,119.45z"
            />
            <path
              style="fill: #6e897d"
              d="M-394.14-42.11c12.71,8.1,12.56,18.66-0.4,27.73C-394.4-23.62-394.27-32.87-394.14-42.11z"
            />
            <path
              style="fill: #425b24"
              d="M-430.17,50.2c-3.18,4.89-7.63,7.88-13.39,8.9c-4.23-5.66-0.88-9.52,3.34-12.81
            C-434.98,42.2-432.26,45.61-430.17,50.2z"
            />
            <path
              style="fill: #2d361c"
              d="M-306.31,119.68c1.91-6.71-6.36-7.32-7.36-12.29c-1.3-17.99,9.55-21.31,24.18-20.1
            c6.06,3.21,10.45,7.71,11.18,14.94c0.24,3.31-1.03,6.54-0.88,9.85c0.26,5.89-0.86,13.21,4.4,16.62
            c7.35,4.77,9.87-4.07,13.99-7.52c10.18-8.5,21.25-10.06,33.47-5.62c17.77,14.2,31.88,33.16,58.59,33.99
            c14,0.43,14.53,15.45,14.74,27.28c-2.04,12.66-9.44,18.67-22.27,17.96c-9.01-2.88-18.14-10.21-26.74,0.9
            c-1.85,0.75-3.75,1.11-5.74,1.12c-9.7-2.73-15.88-10.62-23.65-16.19c-6.82-19.16-15.49-10.88-24.44-1.76
            c-4.18,4.26-3.97,14.74-13.61,11.33c-7.44-2.64-4.36-9.67-4.83-15.53c-0.86-10.6-6.11-17.59-18.45-14.66
            c-1.25-1.27-2.51-2.55-3.76-3.82c-7.32-5.32-14.83-10.47-16.79-20.25C-313.55,129.57-309.78,124.71-306.31,119.68z"
            />
            <path
              style="fill: #161912"
              d="M-294.45,233.37c0.35-4.7-2.97-6.68-6.37-8.59c-4.64-2.73-1.26-8.64-4.68-11.85
            c4.75-10.22,5.01-19.95-0.44-30.46c-6.37-12.29-1.7-19,12.38-18.51c4.86,1.51,8.65,4.79,9.09,9.67
            c1.63,18.1,10.32,33.98,15.27,51.02c1.76,3.94-0.09,4.94-3.68,4.55c-6.59,7.88-8.45,16.03-2.68,25.44
            c3.28,5.34,2.87,11.62,0.76,17.37c-1.97,5.39-0.68,13.12-7.15,15.55c-8.42,3.16-10.34-6.33-15.4-9.83
            c4.06-6.38,10.6-11.57,4.92-20.98C-296.59,249.86-295.19,241.32-294.45,233.37z"
            />
            <path
              style="fill: #91ad86"
              d="M-236.72,346.83c5.49,5.46,13.48,3.4,20.15,5.94c-1.31,8.44-8.34,4.44-12.08,6.75
            c0,0-0.03-0.19-0.03-0.19c-14.68-3.44-30.03,0.1-44.62-4.31c-3.28,1.07-7.6-0.65-9.67,3.64c-15.35-4.21-32.41,1.91-46.86-7.75
            c-0.75-0.92-1.71-1.31-2.89-1.16c0.7-6.51,6.39-4.99,10.27-6.39c11.62,6.07,23.69,3.04,35.62,1.66
            C-270.01,343.06-253.28,342.37-236.72,346.83z"
            />
            <path
              style="fill: #435923"
              d="M-272.88,229.21c1.23-1.52,2.45-3.03,3.68-4.55c6.92-2.73,14.08-6.36,20.92-0.55
            c4.24,3.6,3.62,9.02,0.7,12.95c-3.77,5.09-3.45,16.33-11.41,14.51C-268.8,249.32-272.39,239.33-272.88,229.21z"
            />
            <path
              style="fill: #465826"
              d="M-232.4,180.63c11.85-2.34,18.64,5.23,25.35,12.96c7.03,3.05,13.49,6.67,14.91,15.22
            c0.27,1.83,0.49,3.67,0.64,5.52c-0.05,0.9-0.11,1.81-0.16,2.71C-207.64,217.64-232.65,195.29-232.4,180.63z"
            />
            <path
              style="fill: #3c4f20"
              d="M-305.43,329.91c2.67,0.15,5.34,0.31,8.01,0.46c5.08-3.03,9.67-12.29,16.09-3.13
            c2.9,4.15-2.9,7.48-6.42,7.97C-294.1,336.11-304.35,347.12-305.43,329.91z"
            />
            <path
              style="fill: #739e65"
              d="M-273.29,355.02c14.97,0.39,30.37-3.62,44.62,4.31C-244.31,365.85-258.53,357.54-273.29,355.02z"
            />
            <path
              style="fill: #232b19"
              d="M-329.83,350.91c15.62,2.58,31.24,5.17,46.86,7.75C-298.85,357.67-315.92,363.87-329.83,350.91z"
            />
            <path
              style="fill: #506776"
              d="M-511.76,331.95c-2.5-9.34-16.86-14.46-9.05-26.38c2.07-3.16,7.17-4.48,8.75-2.06
            C-506.32,312.32-508.47,322.19-511.76,331.95z"
            />
            <path
              style="fill: #49632f"
              d="M-578.5,311.41c8.12-11.86,3.76-20.11,6.6-28.45c11.49,1.61,22.98,6.62,21.05,18.28
            C-552.83,313.12-566.29,304.36-578.5,311.41z"
            />
            <path
              style="fill: #647d97"
              d="M-305.5,212.93c11.05,0.2,4.87,7.2,4.67,11.85c-4.59,4.38-9.08,3.98-13.5-0.27
            C-311.38,220.64-308.44,216.79-305.5,212.93z"
            />
            <path
              style="fill: #556c70"
              d="M-304.42,252.52c-0.23,5.26-3.67,8.63-7.85,10.45c-3.91,1.7-6.84-1.21-5.66-5.06
            c1.3-4.24,3.5-9.05,6.85-11.49C-309.03,244.93-305.91,249.38-304.42,252.52z"
            />
            <path
              style="fill: #29361b"
              d="M63.24,140.08c1.38,2.75,2.75,5.49,4.13,8.24c2.54,2.53,2.45,5.09,0.06,7.66
            c-4.55,9.56-3.88,17.7,5.49,24.37c5.5,3.92,9.86,9.11,10.59,16.39c-7.46-4.16-14.85-4.33-22.77-0.79
            c-17.29,7.72-33.71,0.41-50.28-3.57c-1.99-3.37-3.33-7.43-8.18-7.79c-8.84-3.96,4.64-25.11-15.59-20.29
            c-0.05-2.4-0.1-4.81-0.15-7.22c-2.42-10.25,16.33-11.99,9.54-23.98c-2.75-9.19,3.51-14.35,8.77-20
            c5.75-7.25,12.85-12.31,22.2-13.72c1.99-0.03,3.92,0.35,5.79,1.05C42.89,113.65,51.46,128.09,63.24,140.08z"
            />
            <path
              style="fill: #3c5424"
              d="M152.59,172.3c-2.64,8.23-15.25,15.45-2.43,25.35c1.16,0.89,1.47,5.26,1.11,5.41
            c-20.04,8.2-1.73,23.84-6.82,34.81c-5.44,4.47-1.51,13.66-8.7,17.26c-4.45,0.84-8-0.75-10.99-3.96
            c-3.99-8.52-6.6-16.54,6.86-18.61c9.59-10.02-1.46-14.31-6.4-18.19c-5.96-4.68-14.1-6.59-21.3-9.69
            c-0.56-2.56-2.49-6.03-1.48-7.55c9-13.66,26.22-17.53,36.51-30.31C141.9,163.14,148.67,168.46,152.59,172.3z"
            />
            <path
              style="fill: #0f110c"
              d="M131.61,232.56c-6.77,4.54-3.91,10.9-3.18,16.86c-12.61,13.35-23.58,29.69-45.95,25.32
            c-2.25-2.04-3.36-4.6-3.57-7.6c-0.13-9.64,12.73-11.6,12.55-21.29C99.67,225.75,115.04,227.32,131.61,232.56z"
            />
            <path
              style="fill: #121611"
              d="M83.72,265.82c-0.03,2.59-0.05,5.19-0.08,7.78c2.79,1.4,2.78,2.77-0.04,4.11
            c-1.17,4.11-2.34,8.22-3.52,12.33c-7.6,4.4-13.78,10.59-20.43,16.19c-3.39,8.79-12.89,2.8-18,7.45
            c-13.15-6.1-8.84-17.18-0.5-18.56c12.34-2.04,14.6-12.52,22.5-17.58C68.42,270.35,70.36,258.28,83.72,265.82z"
            />
            <path
              style="fill: #2d411e"
              d="M60.47,245.23c-0.74,9.5-8.49,12.54-16.19,14.77c-6.66,1.92-10.7-2.82-10.89-8.58
            c-0.37-11.02,8.53-14.45,17.05-16.49C57.48,233.24,59.29,239.07,60.47,245.23z"
            />
            <path
              style="fill: #385222"
              d="M83.72,265.82c-8.73,0.42-10.53,12.72-20.08,11.72c-5.39-9.46-7.79-16.77,7.32-19.77
            c7.34-1.46,13.7-7.8,20.51-11.93C98.52,256.26,88.82,260.14,83.72,265.82z"
            />
            <path
              style="fill: #42542c"
              d="M2.28,184.57c2.93-3.9,3.5-10.72,10.2-10.08c3.91,0.37,7.14,3.7,7.82,7.74
            c1.23,7.35-6.94,6.34-9.83,10.14C5.5,192.12,3.01,189.27,2.28,184.57z"
            />
            <path
              style="fill: #5a6e51"
              d="M10.53,301.99c3.05-0.89,6.13-2.59,9.03,0.37c2.43,6.67,4.86,13.34,7.29,20.01
            C12.99,322.33,12.49,311.57,10.53,301.99z"
            />
            <path
              style="fill: #212a18"
              d="M-175.55,192.65c3.99-6.65,15.78-5.16,16.18-15.55c7.31-12.19-2.28-28.82,10.33-39.61
            c2.1-2.85,4.24-5.7,5.08-9.28c0.26-10.27,4.45-18.25,13.5-23.45c6.59-2.2,12.61-6.08,19.68-6.95
            c7.04-0.12,12.01,2.96,14.72,9.51c0.54,1.95,0.58,3.95,0.24,5.94c-6.09,9-6.97,18.13-0.09,27.11
            c4.84,6.32,6.36,12.47,0.36,18.89c-4.82,1.36-8.67,3.9-10.68,8.71c3.04,10.11-1.59,16.7-9.89,21.6
            c-8.79,4.99-18.17,4.34-27.64,2.9c-11.44,6.19-22.01,15.1-36.51,11.69C-183.77,198.25-181.34,194.76-175.55,192.65z"
            />
            <path
              style="fill: #4e712b"
              d="M-143.76,192.48c7.17-6.09,16.34-6.02,24.81-8.1c5.67,3.85,8.92-0.57,11.6-4.09
            c5.61-7.38,12.57-6.43,20-4.38c0.07,3.05,0.14,6.1,0.21,9.15c-16.89,1.74-15.66,12.11-11.37,23.94
            c-5.87,2.51-11.75,5.02-17.62,7.53c-5.59-4.83-14.1,3.07-19.08-4.4C-132.33,203.08-141.61,199.33-143.76,192.48z"
            />
            <path
              style="fill: #505f39"
              d="M-175.55,192.65c-1.57,3.84-3.15,7.69-4.72,11.53c-3.74,3.38-7.49,6.76-11.23,10.15
            c0,0.01-0.52-1.43-0.52-1.43c-4.91-6.27-7.66-13.83-12.15-20.35c0.28-9.48,5.54-12.69,14.44-11.93
            C-181.99,181.28-174.7,182.04-175.55,192.65z"
            />
            <path
              style="fill: #9da78e"
              d="M-135.22,212.12c9.07-10.29,13.15,1.11,19.08,4.4c-7.26,3.9-12.94,12.14-22.98,8.29
            C-138,220.53-147.12,213.09-135.22,212.12z"
            />
            <path
              style="fill: #5a802c"
              d="M-27.18,140.09c-9.24,2.36-18.48,4.72-27.72,7.07c-7.21-1.2-14.45,6.63-21.63-0.06
            c-2.15-1.62-3.66-3.71-4.47-6.18c-2.01-4.72-6.83-2.98-10.39-4.28c-11.7-5.73-10.35-15.05-6.92-24.94l-0.38-0.24
            c3.52-10.56,12.57-15.5,21.18-20.9c6.25-2.29,11.88-5.71,17.36-9.47c3.07-1.1,6.07-0.94,9.02,0.45
            c5.13,2.97,6.64,7.92,7.32,13.32c5.3,0.19-8.91,10.09,4.2,7.59c12.88-2.45,25.18,0.67,32.99,13.12
            c1.47,11.86-3.63,19.76-14.57,24.09C-23.17,140.01-25.17,140.16-27.18,140.09z"
            />
            <path
              style="fill: #7aa22a"
              d="M2.37,131.81c8.84,5.87,10.09,11.69-0.21,17.26c-5.14,2.78-10.41,5.34-15.62,7.99
            c-3.81,0.93-7.62,1.87-11.42,2.8c-3.72-6.25-2.19-13.11-2.29-19.78c0.74,0.02,1.47,0,2.21-0.06
            C-15.59,138.18-8.24,129.56,2.37,131.81z"
            />
            <path
              style="fill: #a1ba45"
              d="M-75.19,142.81c5.71,6.38,15.08-4.33,20.29,4.35c-6.96,4.97-15.45,8.21-19.09,16.92
            c-9.77,1.61-0.14-15.51-11.71-12.16C-88.55,141.54-82.37,141.6-75.19,142.81z"
            />
            <path
              style="fill: #445525"
              d="M-75.19,142.81c-5.1,1.18-9.2,3.53-10.51,9.1c-3.28,2.46-6.56,4.91-9.84,7.37
            c1.69-7.82-5.71-11.5-7.91-17.49c-4.02-10.9-7.23-21.89,5.14-30.08c3.66,6.72,1.48,16.76,11.33,19.96
            c2.35,2.92,5.61,4.89,8.04,7.73C-77.7,140.53-76.45,141.67-75.19,142.81z"
            />
            <path
              style="fill: #38481e"
              d="M-87.36,175.91c-8.09,5.52-18.77,7.03-24.35,16.53c-1.9,3.24-5.78,7.21-9.49,3.02
            c-2.89-3.27,0.94-7.42,2.25-11.07c4.24-5.47,8.48-10.93,12.72-16.4C-99.06,168.53-90.72,166.28-87.36,175.91z"
            />
            <path
              style="fill: #2e3d1c"
              d="M-329.51-415.84c6.91,4.55,17.18,20.54,14.85-4.94c-0.46-4.99,4.48-10.48,7.78-17.55
            c5.38,7.03,9.68,12.65,13.98,18.26c9.19,7.15,6.64,15.87,3.28,24.72c-3.32,10.53-9.47,17.75-21.32,18.34
            c-6.41,1.53-11.7-0.69-16.42-4.86C-329.24-393.12-339.02-403.87-329.51-415.84z"
            />
            <path
              style="fill: #435824"
              d="M-289.29-420.17c1.38-2.62,2.76-5.23,4.15-7.85c0.2-1.01,0.39-2.01,0.58-3.02
            c8.99-10.49,15.68-6.21,21.55,3.35c5.02,9.95,17.04,8.34,24.28,14.68c1.56,2.74,1.96,5.66,1.34,8.73
            c-0.89,2.91-2.59,5.27-4.84,7.22c-3.41,2.47-5.38,5.62-4.57,10.07c1.55,8.46,1.17,16.46-9.02,18.74
            c-8.95,2.01-14.78-3.79-19.74-10.32c-2.48-3.27-3.24-7.13-3.35-11.15c-0.35-9.33,1.78-19.39-7.65-26.07
            C-287.8-417.05-288.72-418.5-289.29-420.17z"
            />
            <path
              style="fill: #c4bd9f"
              d="M-329.51-415.84c1.53,10.72,3.05,21.44,4.58,32.16c0.94,1.51,1.25,3.12,0.8,4.85
            c-1.35,2.39-3.28,3.85-6.13,3.86c-1.11-15.89-14.66-28.57-11.71-45.51C-333.13-431.54-332.96-419.3-329.51-415.84z"
            />
            <path
              style="fill: #93ba24"
              d="M-280.64-455.12c7.2,0.85,21.69-9,15.93,10.88c-1.9,4.5-2,10.93-9.51,9.69
            c-5.54-0.92-8.45,2.11-10.87,6.29c0,0-0.06,0.25-0.06,0.25c-4.01-3.34-19.14-2.97-4.35-12.6
            C-284.05-444.16-280.96-448.67-280.64-455.12z"
            />
            <path
              style="fill: #55732b"
              d="M-289.29-420.17c1.6,0.19,3.21,0.39,4.81,0.58c5.57,7,14.18,13.27,5.69,23.68
            c-6.77,3.86-12.17,3.33-15.23-4.74c0.37-6.47,0.74-12.94,1.12-19.41C-291.7-420.13-290.5-420.16-289.29-420.17z"
            />
            <path
              style="fill: #546f29"
              d="M-240.26-404.93c-0.38-2.88-0.76-5.76-1.14-8.65c-7.42-2.55-7.64-7.43-4.35-13.46
            c5.65,0.83,11.31,1.67,16.96,2.5c1.05,0.09,2.11,0.16,3.16,0.22c6.1-2.7,11.75-3.07,16.18,3.07c1.08,1.75,1.69,3.67,1.92,5.71
            c-2.22,7.34-1.5,15.79-7.82,21.68c-2.64,1.46-5.46,1.87-8.43,1.45C-230.58-394.8-239.08-395.06-240.26-404.93z"
            />
            <path
              style="fill: #7aa038"
              d="M-245.75-427.03c1.45,4.49,2.91,8.98,4.36,13.46c-14.7,11.04-16.76-4.16-22.84-11.02
            c-2.2-4.42,0.43-4.61,3.7-4.24C-255.87-425.97-251.06-424.43-245.75-427.03z"
            />
            <path
              style="fill: #55732b"
              d="M-260.53-428.82c-1.23,1.41-2.47,2.83-3.7,4.24c-6.15-5.8-12.64-9.63-20.86-3.68
            c0.54-13.32,15.86-7.74,20.38-15.97C-260.07-439.98-256.69-435.38-260.53-428.82z"
            />
            <path
              style="fill: #30411d"
              d="M-434.79-375.48c9.32-2.74,18.64-5.48,27.95-8.22c12.44-5.91,7.51,4.45,8.6,9.19
            c0.68,5.3,3.3,9.62,6.88,13.44c2.85,7.64,7.13,14.2,14.04,18.83c4.74,4.34,6.61,9.58,5.16,15.92
            c-1.85,5.05-5.49,8.23-10.43,10.06c-12.01,0.51-11.97,17.25-23.9,17.93c-15.09-0.44-23.73-11.6-33.78-20.44
            c-1.3-1.54-2.2-3.28-2.74-5.22c2.81-9.11-1.38-17.64-2.23-26.46C-445.59-360.4-444.37-369.69-434.79-375.48z"
            />
            <path
              style="fill: #262a19"
              d="M-374.82-326.34c-2.74-4.6-5.49-9.19-8.23-13.79c-0.73-6.37-5.92-12.63-0.45-19.14
            c0.94-13.95-4.39-28.64,4.94-41.59c4.05,1.67,8.1,3.34,12.15,5.01c9.23,3.26,6.98,15.37,15.03,19.54
            c2.53,2.46,3.11,6.06,5.17,8.8c2.47,1.5,4.94,3,7.41,4.49c16.12,1.64,16.29,13.47,16.25,25.45
            c-4.31,10.42-10.59,19.04-21.63,23.22c-1.83,0.91-3.76,1.42-5.79,1.59c-3.02-0.05-6.02-0.24-8.93-1.15
            c-4.9-3.17-9.41-6.94-14.48-9.88C-373.87-324.64-374.35-325.49-374.82-326.34z"
            />
            <path
              style="fill: #68942c"
              d="M-383.51-359.28c7.83,6.2,4.55,12.66,0.45,19.15c-8.02-3.67-12.81-9.4-11.89-18.75
            c-0.8-6.62,2.9-6.67,7.62-5.23C-386.05-362.5-384.78-360.89-383.51-359.28z"
            />
            <path
              style="fill: #9cc020"
              d="M-387.33-364.11c-2.54,1.74-5.08,3.49-7.62,5.23c-3.6,0.95-6.93,8.51-8.26,5.5
            c-3.11-7.06-2.77-15.54,4.97-21.14C-394.26-371.4-387.05-371.69-387.33-364.11z"
            />
            <path
              style="fill: #b5c96e"
              d="M-382.36-420.66c0.94,0.87,2.04,1.38,3.31,1.54c7.91,3.42,5.02,7.03,0.18,10.67
            c-6.22,2.78-12.51,2.39-14.05-4.8C-394.6-421.13-387.16-419.88-382.36-420.66z"
            />
            <path
              style="fill: #b1c55f"
              d="M-212.31-419.44c-4.44-1.62-8.88-3.24-13.32-4.86c7.5-18.73,18.97-8.94,29.74-4.14
            c0.24,2.74,0.48,5.47,0.72,8.21C-200.59-413.77-206.57-419.31-212.31-419.44z"
            />
            <path
              style="fill: #baaf97"
              d="M-329.19-338.78c2.53-10.37-3.96-17.14-9.63-24.24c0.13-5.93,4.49-8.83,8.55-11.94
            c1.29-1.56,2.58-3.12,3.87-4.67c15.33,15.93,17.85,41.91,5.95,61.21C-333.83-320.7-331.95-329.55-329.19-338.78z"
            />
            <path
              style="fill: #cec4ba"
              d="M-346.22-367.52c-2.62-1.18-5.25-2.37-7.87-3.55c-3.68-9.21-10.19-19.05,5.24-24.1
            C-337.76-386.93-332.81-378.1-346.22-367.52z"
            />
            <path
              style="fill: #59572b"
              d="M-348.86-395.17c-8.96,6.46,1.5,17.15-5.25,24.09c-12.47-4.09-9.17-16.04-12.32-24.78
            C-360.57-395.63-354.71-395.4-348.86-395.17z"
            />
            <path
              style="fill: #9a976e"
              d="M-434.79-375.48c-0.93,9.37-6.2,18.24-3.25,28.06c-3.75,10.95-9.94,6.91-16.18,2.66
            c-6.85-11.77-2.51-22.23,3.81-32.46c3.68-4.02,6.25-9.33,12.22-10.76C-434.41-384.53-435.37-379.79-434.79-375.48z"
            />
            <path
              style="fill: #d8cfc9"
              d="M-438.2-387.97c-1.56,4.4,0.28,10.33-5.75,12.71c-12.27-4.74-12.17-11.74-3.69-20.26
            c2.48-2.64,4.96-5.29,7.43-7.93C-439.53-398.3-438.86-393.13-438.2-387.97z"
            />
            <path
              style="fill: #3e4422"
              d="M-447.63-395.53c5.15,6.04-5.88,15.03,3.69,20.27c-5.87,8.31-8.78,17.3-6.23,27.52
            c2.15,8.59-6.27,11.1-9.53,16.59c-2.45,1.92-5.19,2.8-8.3,2.35c-1.86-0.62-3.51-1.63-4.94-2.95c-12-15.49-3.29-29.58,2.88-43.79
            c2.95-2.57,2.95-2.57-4.45-5.84c-4.4-3.28-5.19-8.34-6.55-13.12c-0.49-3.05-0.47-6.09,0.08-9.14c1.93-5.45,5.19-8.85,11.53-7.41
            c7.61-0.87,13.75-7.65,22.1-5.51C-447.12-409.56-460.59-402.72-447.63-395.53z"
            />
            <path
              style="fill: #414424"
              d="M-469.45-411.07c-2.06,1.66-4.12,3.33-6.18,4.99c-0.42,2.8-2.04,4.06-4.86,3.77
            c-18.83-10.03-21.56-18.45-11.93-36.76c5.13-1.26,9.79-0.74,13.48,3.52c1.01,2.46,2.02,4.93,3.03,7.39
            C-473.29-422.64-466.16-418.83-469.45-411.07z"
            />
            <path
              style="fill: #2a361a"
              d="M83.6,277.71c0.01-1.37,0.03-2.74,0.04-4.11c11.23-0.81,22.28-3.77,24.17-16.33
            c2.53-16.85,10.41-14.1,20.63-7.85c2.6,1.34,5.2,2.67,7.8,4c10.85-1.65,21.69-3.27,31.4,4c6.97,10.06,16.06,18.94,17.95,31.87
            c-16.96-2.21-33.8-4.93-49.76-11.49C118.42,271.7,101.01,276.89,83.6,277.71z"
            />
            <path
              style="fill: #4a5f27"
              d="M185.59,289.3c-6.74-9.24-13.48-18.48-20.22-27.71c-3.92-7.43-3.76-14.25,3.01-20.07
            c2.53-4.03,2.68-8.12,0.25-12.25c1.49-4.39,1.29-9.29,3.98-13.32c1.76-6-15.99-11.26-4.35-13.56
            c12.29-2.43,5.16-30.22,24.75-16.3c4.46,5.06,5.09,10.15,0.13,15.29c-13.82,9.08-11.26,32.44,5.29,48.26
            c-5.88,5.75-13.13,8.8-21.16,9.3c0.2,8.82,8.82,9.14,11.31,14.61c-4.43,8.07,4.96,7.76,7.05,11.81
            c2.86,5.55,12.53,11.07,1.07,17.6C192.33,298.94,193.03,290.8,185.59,289.3z"
            />
            <path
              style="fill: #2f3f30"
              d="M59.65,306.23c6.33-6,0.11-27.87,20.43-16.19c1.37,0.02,2.74,0.03,4.11,0.05
            c-4.47,8.37-12.68,12.04-20.43,16.28C62.39,306.32,61.02,306.28,59.65,306.23z"
            />
            <path
              style="fill: #2c3a1c"
              d="M-577.17-355.17c7.22,0.26,14.16-0.06,20.4,5.58c9.28,8.39,16.79,5.52,21.42-5.78
            c2.41-0.98,4.82-1.96,7.23-2.94c8.76,5.03,10.55,11.38,3.65,19.37c-1.25-0.03-2.51-0.09-3.76-0.16
            c-3.96-0.88-10.14-7.97-10.44,2.42c-0.2,6.97-3.31,12.15-6.16,17.78c-5.41-6.69-3.6-19.24-16.26-20.04
            C-571.86-338.99-582.68-338.98-577.17-355.17z"
            />
            <path
              style="fill: #8eb031"
              d="M-577.17-355.17c0.65,10.08,8.95,12.57,16.07,16.23c-8.15,9.21-15.92,4.83-23.66-0.66
            c-0.18-1.16-0.51-2.27-0.98-3.35C-584.47-348.14-581.52-352.15-577.17-355.17z"
            />
            <path
              style="fill: #9cbe20"
              d="M-536.41-181.03c-2.98-3.85-7.34-3.83-11.58-4.11c-2.14-15.41,16.91-15.83,19.64-27.8
            c10.26,1.99,21.02,2.81,28.25,11.97c0,0,0.15-0.01,0.15-0.01c4.34,19.47-3.2,28.23-21.49,24.03
            C-526.41-178.08-532.07-177.42-536.41-181.03z"
            />
            <path
              style="fill: #12150e"
              d="M-468.08-334.73c0.1,1.28,0.26,2.55,0.46,3.81c14.14,16.75,8.48,36.04,6.61,54.64
            c-1.16,11.55-7.59,22.64-2.53,34.65c9.78,9.76,16.29,23.46,5.17,32.56c-10.1,8.26-24.96,4.64-33.81-8.38
            c-6.48-10.89-10.27-22.31-6.37-35.03c1.84-5.98-0.62-10.08-4.98-13.62c0.03-1.32-0.18-2.59-0.65-3.82
            c-5.71-22.14-11.35-44.27,11.93-60.71c-2.12-5.06-3.97-10.26,2.04-13.91c4.76-2.89,8.83-0.21,12.67,2.58
            C-474.34-339.63-471.25-337.13-468.08-334.73z"
            />
            <path
              style="fill: #202616"
              d="M-468.08-334.73c-7.94,2.08-19.58-17.69-24.18,4.1c-4.18-4.9-8.38-8.03-15.23-3.67
            c-6.8,4.33-12.64,1.79-16.97-4.64c-1.22-6.46-2.44-12.92-3.65-19.37c0.01,0.01-0.29-0.73-0.29-0.73
            c5.81-10.04,2.86-25.42,18.07-29.74c11.45-2.04,20.67-14.41,34.09-7.41c1.48,4.12,2.95,8.23,4.43,12.35
            c2.64,2.37,4.75,4.98,3.96,8.88C-461.48-361.52-476.58-348.19-468.08-334.73z"
            />
            <path
              style="fill: #2b381f"
              d="M67.43,155.97c-0.02-2.55-0.04-5.11-0.06-7.66c2.16-1.53,4.41-2.94,6.46-4.61
            c5.44-4.41,10.45-13.29,17.44-10.39c8.44,3.51,14.12,11.91,12.73,22.65c4.19,11.64-5.36,20.2-10.43,26.41
            c-7,8.55-9.03-3.52-11.22-8.56C79.06,166.29,82.85,153.24,67.43,155.97z"
            />
            <path
              style="fill: #567a2b"
              d="M165.18,135.88c0.8,8.35,14.55,19.1-4.39,23.8c0.48-7.94-1.99-15.24-4.5-22.61
            c-0.89-2.6-2.8-7.18,2.18-7.35C160.63,129.65,162.94,133.7,165.18,135.88z"
            />
            <path
              style="fill: #69902c"
              d="M160.72,9.25c-1.46,4.38-2.92,8.76-4.37,13.13c-8.04,3.71-18.04,17.99-19.33-6.11
            c-12.31,8.71-13.18,25.59-28.61,26.59c-0.93-0.27-1.86-0.53-2.79-0.8c-2.6-1.58-4.17-3.85-4.7-6.85
            c0.82-12.17,21.32-12.89,16.46-28.39c1.44-4.74,3.01-9.38,8.24-11.35c7.69-1.22,14.75,3.43,23.02,1.25
            C157.62-5.64,161.8,0.1,160.72,9.25z"
            />
            <path
              style="fill: #33461e"
              d="M111.88,70.64c-9.75,3.5-10.08,16.52-20,19.85c-8.46-0.78-11.26,17.16-21.03,7.83
            c-8.12-7.75-1.08-17.67,1.94-26.21c1.54-4.36,4.4-8.29,5.7-12.88c1.67-4.06,4.52-6.77,8.85-7.76c3.31-0.27,6.11,0.79,8.38,3.24
            C101.55,59.56,104.03,67.83,111.88,70.64z"
            />
            <path
              style="fill: #69902c"
              d="M95.72,54.7c-2.68-0.08-5.37-0.16-8.05-0.25c-4.5-6.33-4.51-12.31,2-17.18
            c7.37-5.5,11.92,1.41,17.32,4.41c0,0,1.41,1.18,1.41,1.18C104.18,46.81,99.95,50.75,95.72,54.7z"
            />
            <path
              style="fill: #405c24"
              d="M83.09,62.58c2.71,2.7,7.07,8.78,4.16,9.15c-15.9,2.02-6.5,16.45-12.49,22.13
            c8.18,9.63,7.67-17.75,17.12-3.37c-2.58,2.17-3.83,4.88-3.39,8.29c-2.98,2.97-5.96,5.94-8.93,8.91
            c-3.41,4.64-4.76,10.97-10.89,13.43c-21.23,3.74-27.77-0.94-31.23-22.33c1.33-3.7,2.84-7.35,4.34-10.98
            c13.97-18.25,8.89-32.95-16.76-48.34c-1.37-2.93-1.35-5.86-0.1-8.82c1.2-1.8,2.81-3.05,4.83-3.8
            c9.73,1.42,19.15-3.97,29.23-0.41C76.4,32.6,77.77,48.75,83.09,62.58z"
            />
            <path
              style="fill: #374c20"
              d="M43.15,99.13c1.26,13.75,9.6,17.94,22.16,16.41c10.43,4.51,5.77,10.36,1.45,16.19
            c-1.17,2.78-2.35,5.56-3.52,8.35c-26.86,0.94-26.4-21.33-32.34-38.16C32.98,92.19,37.05,91.17,43.15,99.13z"
            />
            <path
              style="fill: #909d99"
              d="M66.76,131.73c-4.16-5.07-0.42-10.84-1.45-16.19c2.91-5.97,6.53-10.62,14.24-7.86
            C86.96,126.89,84.66,131.22,66.76,131.73z"
            />
            <path
              style="fill: #abc43d"
              d="M160.72,9.25c-2.77-4.37-4.92-10.3-11.67-4.66c-10.94,9.15-16.28,1.4-21.36-7.1
            c-1.81-4.46-2.25-8.19,4.34-8.17c6.89,0.04,13.79,0.07,20.68,0.11c4.31,4.49,8.63,8.98,12.94,13.47
            C165.02,5.81,164.24,8.59,160.72,9.25z"
            />
            <path
              style="fill: #bed039"
              d="M133.06-46.93c-1.84,10.7-3.68,21.41-5.52,32.11c-1.64,5.45-4.12,10.31-9.41,13.16
            C105.16,1.86,95.54-1.3,91.26-15.12c-1.31-2.95-2.08-6.02-1.76-9.27c2.87-8.92,8.41-17.18,7.06-27.26
            c1.56-5.08,4.59-8.67,10-9.83c8.33-0.46,12.72,8.3,20.64,8.84c1.78,0.71,3.33,1.76,4.66,3.14
            C132.26-48.64,132.66-47.79,133.06-46.93z"
            />
            <path
              style="fill: #93b729"
              d="M168.73-83.44c-0.53,6.31,5.91,5.57,8.65,8.56c-4.79,7.32,0.9,13.89,1.38,20.83
            c0.48,7.06,1.62,15.09-9.3,15.58c-5.67,0.93-11.35,1.85-17.02,2.77c-1.49-1.29-2.99-2.57-4.48-3.84
            c-0.46-6.8-5.26-13.21-1.84-20.37c0.37-1.96,0.88-3.89,1.5-5.79C151.47-75.4,157.45-82.58,168.73-83.44z"
            />
            <path
              style="fill: #32461f"
              d="M119.86-6.58c2.56-2.74,5.12-5.49,7.68-8.23c1.5,1.38,3,2.76,4.5,4.14
            c-1.45,2.72-2.9,5.45-4.34,8.17c-3.95,3.26-2.71,8.07-3.67,12.23c-3.32,10.4-9.5,16.02-21.07,12.65
            C91.07,12.12,94.36,4.41,107.2-1.2C111.39-3.04,116.28-3.26,119.86-6.58z"
            />
            <path
              style="fill: #5d832f"
              d="M153.01-59.75c-1.68,6.74-3.35,13.49-5.05,20.22c-4.56-3.32-8.57-7.71-14.9-7.39
            c0,0-1.63,0.46-1.63,0.46c-2.26-5.37-4.7-10.72-1.33-16.56c2.63-3.53,6.23-5.01,10.54-5C146.32-67.62,149.23-63.04,153.01-59.75
            z"
            />
            <path
              style="fill: #b0ca32"
              d="M168.73-83.44c-5.45,6.21-12.29,11.37-14.8,19.78c-12.76-9.5-12.31-12.67,2.84-19.62
            c3.9-2.87,7.79-5.75,11.69-8.63C168.54-89.08,168.64-86.26,168.73-83.44z"
            />
            <path
              style="fill: #648a33"
              d="M-556.6-421.95c5.15-1.95,10.3-3.9,15.44-5.85c13.31,3.87,15.51,13.64,13.62,25.63
            c-5.77,9.76-11.59,19.52-25.1,10.14C-563.78-400.7-554.98-412.02-556.6-421.95z"
            />
            <path
              style="fill: #576d27"
              d="M-552.63-392.03c9.98,1.62,14.5-5.72,19.66-12.01c8.17,5.56,13.69,15.46,25.29,15.36
            c1.09,1.82,0.93,3.53-0.48,5.12c-12.68,3.26-8.86,20.18-20.25,24.51c-0.43-16.67-11.3-20.86-25.36-21.26
            C-553.39-384.22-553.01-388.13-552.63-392.03z"
            />
            <path
              style="fill: #4d5937"
              d="M-573.95-386.96c-7.65-0.56-15.53-0.64-19.09-9.46c8.95-3.51,5.26-16.69,14.98-19.6
            c12.53,7.17,12.18,16.94,5.63,27.96L-573.95-386.96z"
            />
            <path
              style="fill: #8fa59f"
              d="M-572.42-388.07c0.37-9.77,1.85-19.77-5.63-27.95c0.81-3.39-0.06-9.88,5.99-6.57
            C-563.31-417.8-564.02-395.83-572.42-388.07z"
            />
            <path
              style="fill: #191c13"
              d="M-508.76-388.11c-19.01,7.52-22.18,5.43-24.21-15.93c1.03-9.22-6.23-15.57-8.18-23.76
            c3.02-13.7,6.41-27.04,25.07-25.08c0,0-0.22,0.39-0.22,0.39c20.57,14.59,24.45,25.7,16.16,48.38
            c-1.44,3.93-0.33,7.89-1.75,11.63C-503.28-389.61-505.58-388.16-508.76-388.11z"
            />
            <path
              style="fill: #4f5b30"
              d="M144.78-448.79c3.6-2.8,6.47-8.06,12.2-3.56c4.65,4.36,7.26,10.22,5.88,16.02
            c-3.87,16.28,4.62,27.95,13.15,39.83c-3.68,2.25-6.02,6.75-11.12,6.6c-2.18,5.54-1.31,13.78-11.09,12.63
            c-2.04-0.63-3.71-1.82-5.01-3.52c-1.64-15.78-5.2-31.01-13.41-44.86c0.84-7.57,3.75-14.22,8.78-19.94
            C144.07-446.72,144.28-447.79,144.78-448.79z"
            />
            <path
              style="fill: #526f52"
              d="M164.61-322.88c14.43,6.2,23.36,13.95,8.1,28.58c-1.43,1.47-3.15,2.45-5.12,3.03
            c-2.09,0.19-4.07-0.17-5.96-1.08c-5.97-5.91-11.09-12.41-14.18-20.36c-0.43-4.74,0.81-8.82,4.62-11.91
            C156.65-326.9,160.79-326.03,164.61-322.88z"
            />
            <path
              style="fill: #9eb7d3"
              d="M164.61-322.88c-3.91-0.11-7.82-0.22-11.73-0.33c-3.09,0.36-5.57-0.84-7.7-2.98
            c-5.15-6.38-5.21-14.27-6.43-21.83c0.3-7.46,2.41-13.82,10.08-16.82c1.95-0.34,3.82-0.03,5.61,0.8
            c4.64,2.73,3.96,10.82,11.09,11.1C173.28-342.68,166-332.87,164.61-322.88z"
            />
            <path
              style="fill: #889588"
              d="M154.51-380.09c5.66-0.94,7.17-6.29,10.38-9.82c0.36,10.02,11.73,20.52-1.32,29.94
            c-4.61,4.33-7.93,1.49-11.12-2.01l-0.01-1.14C147.58-369.46,147.03-375.27,154.51-380.09z"
            />
            <path
              style="fill: #48663a"
              d="M167.59-293.93c1.71-0.12,3.42-0.25,5.12-0.37c5.86,5.11,12.91,4.08,19.8,3.84
            c1.35,1.52,1.98,3.29,1.77,5.34c-4.28,7.45-5.9,17.8-18.08,16.7c-2.92-1.3-4.79-3.68-6.49-6.25
            C164.58-280.59,161.68-286.78,167.59-293.93z"
            />
            <path
              style="fill: #ccd8e4"
              d="M152.44-361.98c3.87-0.22,6.6,5.88,11.12,2.01c0.66,2.35,1.32,4.7,1.97,7.04
            c-1.65,3.17-2.01,8.67-7.05,7.25C149.86-348.14,153.69-356.22,152.44-361.98z"
            />
            <path
              style="fill: #9fac5f"
              d="M205.2-322.73c3.55,8.11,4.06,14.2-7.94,11.97c-1.13-5.33-2.51-11.19,1.21-15.56
            C204.32-333.19,203.47-325.02,205.2-322.73z"
            />
            <path
              style="fill: #99ad48"
              d="M192.55-286.03c-0.01-1.48-0.02-2.95-0.03-4.43c0.66-7.69,7.46-9.51,12.45-13.07
            c1.14-0.08,2.27-0.17,3.4-0.26c9.13,10.25,4.37,15.94-6.43,19.64c-2.21,0.65-4.42,0.67-6.64,0.06
            C194.39-284.73,193.47-285.38,192.55-286.03z"
            />
            <path
              style="fill: #384e21"
              d="M176.86-270.8c6.18-4.09,6.91-13.82,15.69-15.23c0,0,0.82-0.39,0.82-0.39
            c4.28,4.96,9.17,9.39,12.79,14.92c1.26,4.47,3.5,8.89,0.85,13.6c-7.26,3.61-15.59,6.37-14,17.21
            c4.46,5.68,8.92,11.36,13.39,17.04c2.61,5.45,0.16,9.35-3.88,12.76c-6,3.22-8.8,9.74-14.04,13.75
            c-2.21,0.19-4.42,0.38-6.63,0.58c-4.87,7.64-7.08,18.61-20.07,16.15c-6.29-3.24-12.56-6.56-16.66-12.71
            c-2.29-4.28-1.49-9.13-2.49-13.67c-0.54-3.56-2.19-6.86-2.65-10.47c-0.12-1.89,0.12-3.72,0.62-5.54
            c0.99-2.66,2.49-5.02,4.24-7.23c4.3-11.51,20.7-13.2,21.98-27.19C168.3-263.14,170.45-268.56,176.86-270.8z"
            />
            <path
              style="fill: #adc943"
              d="M198.21-216.27c2.73-2.46,5.46-4.92,8.2-7.38c4.5-1.69,7.7,1.77,11.49,2.89
            c2.65,5.43,5.3,10.86,7.95,16.3c-1.59,2.96-3.19,5.91-4.79,8.87c-2.38-0.33-4.76-0.67-7.15-1c-3.26-5.16-9.76-4.09-14.03-7.31
            C195.18-207.45,192.07-211.24,198.21-216.27z"
            />
            <path
              style="fill: #7f8a46"
              d="M201.07-269.66c-6.8-3.64-7.58-10.04-7.69-16.76c1.07,0.04,2.14,0.13,3.2,0.29
            c5.46,1.45,12.01-2,16.54,3.67c0.91,8.33,15.4,14.03,4.75,24.6C208.46-256.35,206.49-265.47,201.07-269.66z"
            />
            <path
              style="fill: #b4c494"
              d="M213.12-282.46c-5.81,0.1-12.91,6.07-16.54-3.67c5.66-4.72,15.31-6.78,11.79-17.65
            C219.66-301.74,220.26-299.02,213.12-282.46z"
            />
            <path
              style="fill: #b1bba6"
              d="M201.07-269.66c8.72-0.52,10.07,9.48,16.8,11.8c-3.17,4.1-7.11,5.2-11.89,3.09
            c-3.17-0.8-3.36-2.05-0.56-3.75C202.11-261.51,200.63-265.21,201.07-269.66z"
            />
            <path
              style="fill: #adbc76"
              d="M205.42-258.52c0.3,1.23,0.48,2.48,0.56,3.74c-7.86,1.43-6.94,10.95-12.95,14.09
            C185.17-254.97,196.02-256.24,205.42-258.52z"
            />
            <path
              style="fill: #58792b"
              d="M-512.6-58.47c-1.72,20.84,12.93,45.48,35.46,55.65c-17.72,6.29-31.53,18.03-49.18,5.77
            c-1.63-1.13-3.91-2.78-5.44-2.39c-24.89,6.37-25.93-9.52-25.41-26.97c-1.89-1.55-3.17-3.5-3.84-5.85
            c0.06-8.29,2-14.46,12.43-11.04c1.33,0.13,2.66,0.24,3.99,0.32c4.57,0.43,9.63,2.37,12.08-3.86c0.13-2.59,0.25-5.19,0.38-7.78
            c1.56-6.23,4.59-10.92,11.77-11.28C-516.05-65.23-513.05-63.18-512.6-58.47z"
            />
            <path
              style="fill: #455b24"
              d="M-536.41-181.03c15.92,0.26,33.28,3.14,36.46-19.94c10.95,6.17,6.62,17.94,9.18,26.65
            c8.27,4.69,5.43-8.9,11.99-6.28c6.1,5.72-1.74,34.67-13.15,48.55c-9.24-1.68-16.45,2.04-22.61,8.57
            c-3.69,1.7-7.35,3.47-11.6,2.88c-6.77-1.94-9.93-6.58-10.02-13.49c2.57-6.99,4.5-14.42,11.85-18.34
            c5.31,1.54,7.93-0.06,6.55-5.88C-520.28-168.88-527.83-175.39-536.41-181.03z"
            />
            <path
              style="fill: #3e5321"
              d="M-520.35-62.81c-3.93,2.73-7.86,5.46-11.78,8.19c-2.66-4.63-5.46-8.86-10.03-12.23
            c-10.69-7.89-23.44-16.11-4.56-29.14c0.93-0.64-0.58-4.83-0.96-7.36c-1.66-12.33-0.24-13.08,15.96-8.41
            c11.97,9.23,19.45,20.59,18.88,36.53C-513.05-69.2-515.05-65.27-520.35-62.81z"
            />
            <path
              style="fill: #2d381c"
              d="M-557.45-30.5c0.1,1.36,0.2,2.72,0.29,4.09c-6.88,6.5,0.71,20.01-11.56,23.9
            c-4.15-12.07-8.3-24.14-12.45-36.21c-2.09-5.7-10.92-10.39-4.45-17.34c4.93-5.29,11.14-0.83,16.63,1.16
            c-0.01,2.68-0.01,5.36-0.02,8.04C-561.2-44.2-559.07-37.53-557.45-30.5z"
            />
            <path
              style="fill: #5c712c"
              d="M-524.31-152.41c1.41,7.43,2.91,14.91-7.88,16.5c-5.23-0.47-5.67-5.77-8.63-8.54
            c-1.75-0.86-3.5-1.71-5.25-2.58c-6.98-3.48-2.83,14.66-11.73,6.25c-3.75-3.54-2.29-8.9,0.18-13.11
            c4.16-7.1,11.41-10.18,18.64-8.47C-533.61-161.09-529.17-155.87-524.31-152.41z"
            />
            <path
              style="fill: #728138"
              d="M-540.82-144.44c2.87,2.85,5.75,5.69,8.62,8.54c0.39,5.18,3.04,8.95,7.32,11.7
            c4.41,4.42,2.52,8.88,0.07,13.34c-1.4,1.41-3.05,2.43-4.94,3.04c-0.98-0.01-1.96-0.03-2.94-0.04c-5.09,1.2-13.5-8.71-14.99,4.5
            c-8.46,0.02-14.75-3.19-13.64-12.62c1.19-10.11,8.57-4.74,14.33-5.9C-534.13-124.46-544.13-137-540.82-144.44z"
            />
            <path
              style="fill: #505f2d"
              d="M-583.55-142.27c6.92,3.54,5.41,7.33,6.31,10.3c2.16,7.09-13.65,20.28,7.16,21.64
            c0.92,0.06,2.81,9.05,1.53,12.99c-1.69,5.19-6.79,2.61-10,0.61c-6.68-4.15-16.12-8.74-11.75-17.93
            C-586.28-123.11-583.3-131.32-583.55-142.27z"
            />
            <path
              style="fill: #3e5321"
              d="M-557.45-30.5c-3.89-5.42-10.3-9.06-11.56-16.35c8.67-9.5,17.38-19.23,20.44,3.55
            C-554.8-41.31-556.77-36.35-557.45-30.5z"
            />
            <path
              style="fill: #3e5321"
              d="M-532.51-46.84c2.89,3.72,2.75,7.39-1.56,9.44c-5.46,2.6-8-1.82-10.52-5.58
            C-541.16-46.14-536.94-46.83-532.51-46.84z"
            />
            <path
              style="fill: #262e18"
              d="M-187.42-399.95c6.17,3,9.09-0.82,12.77-5.11c8.93-10.4,15.35-7.14,19.34,4.66
            c-4.17,10.13,6.17,13.39,9.48,20.01c3.24,6.3,2.65,11.87-3.04,16.5c-11.63,5.88-22.31-3.04-33.65-2.16
            c-11.44-2.43-11.21-12.52-13.69-20.85C-196.24-393.23-193.7-397.86-187.42-399.95z"
            />
            <path
              style="fill: #475d24"
              d="M-150.73-379.92c-9.13-0.51-16.05-5.82-17.04-14c-0.83-6.96,7.59-5.35,12.45-6.47
            c6.61,2.73,13.22,5.47,19.83,8.2c3-0.25,5.59,0.67,7.82,2.68c2.66,3.22,3.66,6.74,1.96,10.75c-1.15,1.57-2.58,2.83-4.29,3.75
            c-1.78,0.61-3.64,0.89-5.52,0.89C-141.15-374.52-145.13-379.35-150.73-379.92z"
            />
            <path
              style="fill: #495324"
              d="M-130.27-387.31c-1.74-1.63-3.47-3.25-5.21-4.88c-1.29-5.22-2.58-10.44-3.87-15.66
            c8.91-3.24,13.77-11.53,21-16.87c11.13,0.27,4.21,13.2,11.37,16.26c0,1.53,0.01,3.06,0.01,4.59c-0.24,0.92-0.47,1.84-0.7,2.76
            c-4.9,5.82-10.11,11.27-17.06,14.72C-126.67-386.15-128.52-386.43-130.27-387.31z"
            />
            <path
              style="fill: #7e9753"
              d="M-187.42-399.95c-1.25,4.17-2.5,8.33-3.75,12.5c-2.44,2.65-5.5,3.61-9,3.36
            c-5.87-1.31-8.99-5.1-9.76-10.94c-1.66-6.88,2.71-14.45-2.25-21.01c-0.1-1.13-0.14-2.27-0.12-3.4
            c5.45-6.07,11.35-2.33,17.15-0.8C-190.37-414.32-188.54-407.27-187.42-399.95z"
            />
            <path
              style="fill: #8ea855"
              d="M-81.37-430.88c1.02-1.99,2.05-3.99,3.07-5.98c13.31,6.1,15.31,22.69,27.53,29.81
            c3.24,3.08,2.81,6.26,0.24,9.49c-11.23,5.9-22.76,4.5-34.35,1.43c-10.48-10.01,1.9-13.06,5.7-18.73
            C-78.77-420.37-77.91-425.92-81.37-430.88z"
            />
            <path
              style="fill: #5e722a"
              d="M-79.18-414.87c-2.43,4.78-4.87,9.57-7.3,14.35c1.38,1.6,1.82,3.43,1.33,5.49
            c-4.65,7.23-14.2,7.58-19.49,13.94c-5.33,2.85-10.43,2.75-15.26-1.1c-7.41-14.41,6.57-15.94,13.17-21.93
            c0,0-0.24,0.25-0.24,0.25c1.31-0.1,3.67,0.08,3.79-0.35C-99.52-417.71-87.78-412.76-79.18-414.87z"
            />
            <path
              style="fill: #c0bc99"
              d="M-106.99-408.46c-15.89,3.06-3.36-13.8-11.37-16.26c-0.07-2.47-0.15-4.95-0.22-7.42
            C-98.62-432.14-109.51-417.01-106.99-408.46z"
            />
            <path
              style="fill: #688b33"
              d="M161.44-127.36c-6.85,6.31-2.02,15.38-5.34,22.53c-1.86,4.53-5.52,5-9.69,4.32
            c-10.64-5.71-7.2-17.14-10.51-25.87c0.52-5.7,3.15-9.88,8.69-11.92c3.35-0.57,6.27,0,8.22,3.12
            C155.68-132.58,158.56-129.97,161.44-127.36z"
            />
            <path
              style="fill: #bbc4b5"
              d="M152.8-135.18c-2.41-0.56-4.81-1.12-7.22-1.67c-0.96-0.29-1.93-0.58-2.89-0.87
            c-11.37-18.26-10.69-22.14,5.3-30c7.39,1.34,9.95,8.2,14.5,12.79c1.1,3.17,0.43,7.35,4.88,8.64
            C165.26-138.98,159.01-137.11,152.8-135.18z"
            />
            <path
              style="fill: #9ebe3a"
              d="M167.37-146.29c-4.66-0.05-5.92-3.67-7.4-7.06c-3.66-8.27-6.78-17.42,3.92-21.98
            c8.07-3.44,15.64-0.7,17.21,9.88c-0.38,1.2-0.89,2.38-1.06,3.61c-0.07,0.53,0.55,1.17,0.86,1.76
            C178.22-153.69,169.1-153.62,167.37-146.29z"
            />
            <path
              style="fill: #8aa535"
              d="M156.77-83.27c-6.03,5.8-11.35,11.7-2.85,19.62c-0.44,1.27-0.75,2.58-0.91,3.92
            c-5.1,1.33-8.7-2.27-12.89-3.94c-6.11-8.8-9.27-17.55,0.38-26.12c4.62-1.62,8.61-0.93,11.67,3.19
            C153.7-85.5,155.23-84.39,156.77-83.27z"
            />
            <path
              style="fill: #d3da81"
              d="M152.16-86.61c-3.82-0.32-7.64-0.63-11.46-0.95c-5.48-1.98-6.84-6.5-7.18-11.66
            c0.59-3.09,2.28-5.28,5.26-6.4c1.97-0.2,3.8,0.2,5.47,1.28c3.95-0.16,7.91-0.33,11.86-0.49
            C154.79-98.76,153.47-92.68,152.16-86.61z"
            />
            <path
              style="fill: #445923"
              d="M-10.21-461.64c3.97,5.57,7.95,11.14,11.92,16.71c7.97,2.69,9.77,10.34,13.35,16.59
            c-0.09,7.45-1.66,14.63-4.04,21.66c-6.67,5.6-10.42,16.06-22.09,13.4c-6.43-1.76-4.46-12-12.42-12.23
            c-4.1-1.29-12.33,3.34-10.15-6.98c0.25-1.53,0.5-3.05,0.74-4.58c4.23-8.67,8.08-17.44,6.96-27.48
            C-17.61-447.4-15.02-455.55-10.21-461.64z"
            />
            <path
              style="fill: #719331"
              d="M-22.11-412.37c3.86-2.32,8.38-7.43,12.29-3.56c7.3,7.23-2.08,12.97-3.63,19.5
            c-0.27,0.9-0.53,1.8-0.8,2.7c-8.48,8.05-18.8,14.68-17.08,29.16c0.54,4.54-4.37,7.92-8.09,10.87c-1.81,0.89-3.71,1.27-5.72,1.27
            c-4.12-0.52-7.18-3.31-10.76-5.01c-13.13-7.21-13.56-18.8-10.85-31.49c2.55-8.15,12.29-5.29,16.55-10.85
            c-0.2-2.43-0.4-4.86-0.6-7.28c3.08-0.1,6.15-0.2,9.23-0.3C-33.62-403.44-26.72-403.46-22.11-412.37z"
            />
            <path
              style="fill: #31411d"
              d="M-25.93-444.55c4.58,3.38,14.64,2.71,13.4,9.75c-1.73,9.87-11.56,14.04-20.37,17.73
            c-1.24-2.5-2.48-4.99-3.72-7.49c-8.45-4.69-2.85-10.59-1.39-16.13c0.11-1.53,0.21-3.05,0.32-4.58
            C-33.77-445.02-29.85-444.79-25.93-444.55z"
            />
            <path
              style="fill: #a5c351"
              d="M-22.11-412.37c10.17,6.17,0.2,7.53-2.68,9.54c-6.98,4.88-13.27,4.73-16.75-4.53
            c2.63-1.71,5.26-3.42,7.9-5.13C-29.83-408.91-25.95-412.45-22.11-412.37z"
            />
            <path
              style="fill: #8d9b8a"
              d="M-38-440.69c8.49,4.68-3.24,11.11,1.39,16.13c-10.34,3.96-9.47-6.36-10.62-10.25
            C-49.51-442.51-41.38-438.89-38-440.69z"
            />
            <path
              style="fill: #788d3e"
              d="M63.72-371.77c7.74-9.94,7.37-27.36,25.51-27.7c7.09,4.44,11.82,13.05,21.84,12.34
            c4.56,1.96,7.66,5.24,9.11,10.02c1.5,9.48-5,16.9-6.64,25.61c-0.52,1.81-1.37,3.43-2.5,4.93c-2.69,3.06-6.34,3.81-10.12,4.25
            c-2.69,0.64-5.42,0.98-8.19,0.93c-6.17-0.99-12.34-2.02-18.53,0.11c-9.42-0.1-20.73,6.67-26.33-7.28
            c-2.95-11.66,6.62-16.05,12.97-22.31C61.79-371.18,62.76-371.47,63.72-371.77z"
            />
            <path
              style="fill: #c7c7a8"
              d="M108.03-383.6c-15.73,5.69-19.77,2.28-18.81-15.87c5.55-2.12,6.17-11.55,14.39-9.72
            c5.35-5.66,10.48-5.78,15.36,0.61c1.45,2.82,1.88,5.81,1.39,8.94C117.74-393.16,114.7-386.98,108.03-383.6z"
            />
            <path
              style="fill: #799444"
              d="M34.93-399.88c5.26-1.69,10.52-3.37,15.77-5.07c1.53,4.66,3.07,9.32,4.6,13.99
            c1.58,3.94-0.02,6.93-2.84,9.56c-3.86,2.58-8.37,2.83-12.75,3.61c-5.43,0.62-10.8,2.89-16.37,0.64
            c-6.87-2.92-14.5-6.28-11.75-15.39C15.45-405.28,26.4-399.66,34.93-399.88z"
            />
            <path
              style="fill: #869472"
              d="M115.37-407.56c-3.72-2.01-7.51-3.49-11.76-1.62c0.06-1.99,0.12-3.97,0.18-5.96
            c6.37-6.16,3.68-13.49,2.66-20.61c4.41-2.97,8.83-5.93,13.26-8.9c0.29,1.23,0.45,2.47,0.49,3.73c2.72,3,7.17,4.26,8.62,8.55
            c2.14,6.68-2,11.98-3.88,17.79C122.79-410.81,119.99-407.94,115.37-407.56z"
            />
            <path
              style="fill: #788d3e"
              d="M50.84-382.79c1.49-2.73,2.98-5.46,4.46-8.18c8.57,3.87,7.89,11.81,8.41,19.2
            c0,0-0.81,0.78-0.81,0.78C50.73-366.57,46.69-370.48,50.84-382.79z"
            />
            <path
              style="fill: #53664c"
              d="M124.24-432.44c-1.35-2.82-2.7-5.65-4.04-8.47c7.99-1.56,15.98-3.12,23.97-4.68
            c0.91,7.9-0.74,15.26-4.82,22.07c-1.66,1.12-3.49,1.55-5.47,1.4C128.39-423.43,127.86-429.38,124.24-432.44z"
            />
            <path
              style="fill: #29341a"
              d="M-504.32-393.13c-6.74-4.15-23.06-6.12-4.4-16.57c12.19-6.83,6.33-16.32,3.76-24.38
            c-2.13-6.67-7.44-12.31-11.34-18.41c14.45-7.1,15.4,9.87,23.88,13.42c-1.99,13.84,4.9,24.5,12.49,34.9
            C-485.2-394.16-492.55-388.76-504.32-393.13z"
            />
            <path
              style="fill: #191d12"
              d="M168.38,241.52c1.07,7-0.13,13.66-3.01,20.07c-9.42-3.73-20.59-1.24-29.13-8.16
            c0.6-6.32,0.86-12.82,8.21-15.57C149.38,258.99,161.11,235.63,168.38,241.52z"
            />
            <path
              style="fill: #74952d"
              d="M193.13,201.37c-0.04-5.1-0.08-10.2-0.13-15.3c7.26-1.91,12.58,2.1,12.5,8.2
            C205.42,199.92,198.4,200.78,193.13,201.37z"
            />
            <path
              style="fill: #6a7b3b"
              d="M172.6,215.94c6.65,6.83,9.02,12.37-3.98,13.32C162.26,222.52,164.62,218.39,172.6,215.94z"
            />
            <path
              style="fill: #445822"
              d="M6.64-460.79c3.49-3.46,7.95-3.82,12.49-3.91c9.85,1.22,14.54,7.16,15.25,16.73
            c-2.11,2.18-6.43,5.08-5.99,6.42c3.03,9.27,12.56,2.8,18.09,6.72c0.59,6.33-2.95,7.42-8.26,6.89c-5.73-0.58-9.87,1.7-11.41,7.63
            c-9.8,3.17-9.49-7.28-14.39-10.71C6.82-440.22,8.15-450.78,6.64-460.79z"
            />
            <path
              style="fill: #475b28"
              d="M34.93-399.88c-0.57,0.33-1.1,0.8-1.71,0.97c-12.2,3.25-25.34,6.17-5.93,19.2
            c1.19,2.12,1.19,4.25,0.04,6.39c-6.65,8.12-15.5,8.29-24.79,6.89c-2.21-0.69-4.11-1.87-5.73-3.52
            c-6.42-15.37,3.98-25.06,12.01-35.57c1.64-1.6,3.55-2.71,5.75-3.34c4.8-0.62,8.91,0.56,11.95,4.55
            C29.32-402.84,32.12-401.36,34.93-399.88z"
            />
            <path
              style="fill: #7b9741"
              d="M26.51-404.32c-3.94-0.02-7.87-0.04-11.81-0.06c-2.66-6.91-2.83-14.08-2.24-21.34
            c0.12-1.87,0.77-3.52,2.02-4.91c8.66-2,7.22,8.1,12.34,10.33C45.63-414.6,30.56-409.57,26.51-404.32z"
            />
            <path
              style="fill: #a2c036"
              d="M34.38-447.96c-12.67,1.35-11.48-9.96-15.25-16.73c3.83-2.9,7.66-5.79,11.49-8.69
            c3.86,0.54,7.72,1.08,11.57,1.63c2.54,6.26,11.75,10.69,5.31,19.41C43.14-450.89,38.76-449.42,34.38-447.96z"
            />
            <path
              style="fill: #56772a"
              d="M181.11-165.46c-5.2-2.15-10.4-4.3-16.54-6.83c-0.61,7.34,0.47,14.04-4.58,18.94
            c-9.91,2.72-9.04-5.97-11.63-10.99c-3.01-4.89-2-9.86-0.3-14.85c3.03-5.21,7.72-6.65,13.38-5.92
            c8.81-0.24,13.5-7.82,20.41-11.45c-3.66,18.8,9.87,11.51,21.24,11.2C195.77-176.17,194.24-165.02,181.11-165.46z"
            />
            <path
              style="fill: #64862d"
              d="M198.21-216.27c-3.51,13.54,17.46,7.52,15.7,19.68c-8.47-0.18-16.94-0.37-25.42-0.55
            C188.78-205.01,187.73-213.58,198.21-216.27z"
            />
            <path
              style="fill: #738348"
              d="M14.48-430.63c0.03,1.27,0,2.53-0.1,3.8c-1.52,0.94-2.47,0.54-2.86-1.2
            c-8.37-2.67-9.5-9.54-9.8-16.9c0.16-5.75,0.95-11.3,4.92-15.86C12.18-451.5,16.88-441.99,14.48-430.63z"
            />
            <path
              style="fill: #3a4a1e"
              d="M-407.16-136.69c-7.66,11.15-18.01,6.58-27.88,4.78c-5.1-17.14,10-29.15,10.99-45.68
            c2.03-33.77-0.2-65.96-15.26-96.6c-0.95-5.88-6.6-11.04-2.69-17.67c3.11-4.7,7.49-6.66,13.06-6.06
            c7.27,1.01,11.92,9.3,20.32,7.31c1.93,0.34,3.69,1.06,5.35,2.07c8.59,7.11,7.88,14.84,1.55,22.94
            c-5.61,10.98,1.54,21.41,1.41,32.15c5.33,13.53-3.91,24.24-8.23,35.26c-3.06,7.8-3.57,13.44,0.47,20.06
            c1.95,9.6,9.89,15.62,14.35,23.76C-392.35-144.01-394.94-136.66-407.16-136.69z"
            />
            <path
              style="fill: #1e2215"
              d="M-439.44-289.88c0.07,5.23,5.1,10.42,0.13,15.69c-28.18-4.13-20.07,18.79-24.23,32.56
            c-8.03-29.46-4.89-59.43-4.08-89.3c1.34-0.74,2.68-1.48,4.03-2.22c14.2-10.5,20.5-2.86,24.27,10.6l-0.13-0.2
            c4.46,4.13,7.33,9.17,8.6,15.12C-429.86-299.84-439.9-297.41-439.44-289.88z"
            />
            <path
              style="fill: #5b6e2e"
              d="M-483.36-59.42c-3.22,8.68-7.66,5.48-11.16,0.63c-2.57-3.56-4.64-6.39-9.42-4.32
            c-13.5-9.81-7.61-27.48-15.96-39.39c0.15-5.88,3.18-9.53,8.7-11.26c5.18-0.79,9.72,0.95,14.04,3.61
            c10.49,5.34,9.68,17.9,16.38,25.69c0.22,1.65,0.44,3.3,0.66,4.94C-481.2-72.81-482.28-66.12-483.36-59.42z"
            />
            <path
              style="fill: #798c3d"
              d="M-501.44-107.27c-4.79,2.21-6.29-0.61-7.02-4.6c-3.05-3.35-4.09-7.88-6.75-11.46
            c-0.77-1.63-1.23-3.34-1.44-5.13c3.46-5.29,0.29-15.57,12.34-14.45c7.62,0.7,13.13,1.61,12.37,10.87
            c2.42,6.95,4.83,13.89,7.25,20.84C-489.22-105.4-495.21-105.82-501.44-107.27z"
            />
            <path
              style="fill: #a2ad68"
              d="M-501.44-107.27c4.74-4.91,10.74-4.44,16.76-3.94c1.3,8.92,2.61,17.84,3.91,26.75
            C-487.67-92.06-494.55-99.66-501.44-107.27z"
            />
            <path
              style="fill: #202617"
              d="M-289.39,91.03c-9.8,2.92-18.07,8.11-24.27,16.37c-5.21-2.84-10.42-5.68-15.63-8.52
            c-1.06-0.52-1.55-1.19-1.46-2.02c0.09-0.9,0.28-1.35,0.56-1.35c4.43-5.74,7.73-13.37,13.52-16.83
            c11.63-6.97,7.67-21.82,4.94-23.98c-16.34-12.9-9.59-29.32-9.96-44.72c5.39-8.18,8.86-18.85,21.99-17.08
            c6.41,23.66,29.26,37.85,35.61,61.62c-2.09,8.15-6.31,15.5-7.26,24.25C-272.42,88.67-278.61,93.97-289.39,91.03z"
            />
            <path
              style="fill: #191c13"
              d="M-301.51-6.31c-6.25,6.02-2.52,24.45-20.19,16.29c-14.82,0.66-12.03-9.75-10.42-17.99
            c1.65-8.49,2.4-15.96-5.53-21.64c0.49-6.89-6.95-13.17-1.62-20.47c21.27-19.93,45.9-18.38,60.41,12.56
            c1.25,1.68,2.39,3.42,3.48,5.21c6.88,8.76,12.18,17.66,2.43,27.86C-283,3.46-292.09-3.92-301.51-6.31z"
            />
            <path
              style="fill: #4e767c"
              d="M-358.58,9.56c0.06-2.48,0.12-4.96,0.17-7.44c4.33-2.75,6.64-0.09,8.72,3.23
            c15.87,4.6,11.71,16.53,7.81,26.02c-3.3,8.02-6,13.55,1.91,20.4c3.5,3.03,7.41,8.94,3.25,14.42
            c-4.51,5.94-10.35,2.48-16.37,1.95c-13.99-1.24-24.01-4.49-21.46-21.45c2.98-5.05,9.53-8.72,6.31-16.19
            C-369.45,21.47-356.28,19.1-358.58,9.56z"
            />
            <path
              style="fill: #657e33"
              d="M-339.27-46.71c0.03,5.73,7.81,10.74,1.61,17.06c-3.65-2.51-6.67-9.42-11.8-1.71
            c-2.64,1.98-5.41,2.79-8.37,0.7c-2.67-1.89-1.7-4.08-0.38-6.25c-7.9-1.58-17.11-1.69-15.62-13.88
            c-0.88-14.37-0.64-28.58,6-41.88c3.17-5.44,6.34-10.88,11.24-15.02c3.69-2.44,7.56-3.23,11.7-1.22
            c8.85,11.84,6.4,25.1,3.29,37.71C-343.8-62.3-342.46-54.64-339.27-46.71z"
            />
            <path
              style="fill: #7e98ad"
              d="M-358.2-36.9c1.78,3.64,4.49,5.81,8.74,5.54c15.7,12.34,7.93,24.53-0.22,36.72
            c-2.91-1.09-5.82-2.16-8.72-3.24C-361.97-10.91-364.77-23.93-358.2-36.9z"
            />
            <path
              style="fill: #4c7367"
              d="M-330.2,95.51c0.2,1.15,0.5,2.27,0.9,3.37c-0.04,1.47-0.07,2.94-0.11,4.41
            c-12.7,10.29-19.1,0.83-25.65-8.2C-346.47,77.31-338.4,89.96-330.2,95.51z"
            />
            <path
              style="fill: #6a93aa"
              d="M-367.05,30.39c2.97,3.41,11.27,3.65,9.67,8.65c-2.28,7.14-11.32,5.07-17.17,7.65
            c-15.91-8.93,12.13-9.09,3.31-16.61c0,0,1.86,0.45,1.86,0.45C-368.61,30.49-367.83,30.44-367.05,30.39z"
            />
            <path
              style="fill: #2b391b"
              d="M-459.27,6.06c-2.24-4-3.62-10.46-6.87-11.6c-23.13-8.16-13.2-20.72-5.25-33.29
            c6.24,3.42,12.42,6.12,17.44,12.18C-437.58-6.91-437.2-6.86-459.27,6.06z"
            />
            <path
              style="fill: #29351a"
              d="M-516.75-103.4c10.27,11.52,11.89,25.8,12.81,40.3c-2.88,1.54-5.77,3.09-8.66,4.63
            c-2.58-1.45-5.16-2.9-7.75-4.34c5.48-17.65-4.3-31.11-12.35-45.05c0,0,0.08-0.03,0.08-0.03
            C-525.74-112.01-520.62-109.92-516.75-103.4z"
            />
            <path
              style="fill: #516627"
              d="M-361.76-92.43c-4.02,13.88-8.05,27.77-12.07,41.65c-3.02-1.5-6.04-2.99-9.06-4.49
            c6.72-15.76,6.09-32.13,4.06-48.62c-10.44-4.3-14.27-14.2-19.4-23c-2.98-3.27-5.95-6.53-8.93-9.8c6.7-4.79,9.08-11.4,8.21-19.4
            c-2.58-7.91-4.5-16.69,4.61-20.82c8.13-3.68,13.34,3.2,17.35,9.51c4.12,6.47,10.77,9.45,16.93,13.2c1.41,1.51,2.39,3.26,3,5.23
            c0.59,2.93,1.09,5.87,0.42,8.86C-369.24-125.41-360.35-108.36-361.76-92.43z"
            />
            <path
              style="fill: #678e9f"
              d="M-367.05,30.39c-0.78,0.06-1.56,0.11-2.34,0.15c-4.62-11.24-8.49-22.09,10.81-20.97
            C-340.83,24.89-363.36,23.8-367.05,30.39z"
            />
            <path
              style="fill: #334d40"
              d="M-309.83,135.53c6.65,5.37,9.23,13.18,12.35,20.66c-12.16,0.61-18.4-7.53-24.17-16.37
            c0,0-0.13,0.1-0.13,0.1C-318.39,136.83-315.39,132.68-309.83,135.53z"
            />
            <path
              style="fill: #4a6b5b"
              d="M-309.83,135.53c-3.98,1.46-7.97,2.93-11.95,4.39c2.16-9.04,6.29-16.57,15.47-20.23
            C-307.49,124.96-308.66,130.25-309.83,135.53z"
            />
            <path
              style="fill: #979a4b"
              d="M-398.23-126.88c13.54,1.69,16.07,12.69,19.4,23c-4.96,2.55-9.91,5.1-14.86,7.66
            c-2.9-3.89-5.8-7.77-8.7-11.66C-407.87-115.72-400.35-120.71-398.23-126.88z"
            />
            <path
              style="fill: #34451e"
              d="M-289.39,91.03c3.46-4.25,14.13-5.15,9.77-12.74c-8.04-14.01,3.7-17.43,10.4-23.9
            c8.47-21.93,24.75-10.42,38.69-9.08c5.1,5.3,6.77,12.26,9.15,18.89c0.41,1.28,0.88,2.52,1.4,3.75
            c10.81,25.88,10.72,26.14-14.14,39.38c-1.86,0.88-3.82,1.38-5.86,1.6c-13.24-5.28-29.12,8.43-41.18-5.62
            C-283.91,99.21-286.65,95.12-289.39,91.03z"
            />
            <path
              style="fill: #4c6234"
              d="M-143.35,139.76c-9.63,10.6-2.6,28.37-16.02,37.35c-3.33-1.14-7.17-3.04-4.96-6.91
            c6.16-10.78-0.52-13.81-8.81-14.32c-10.38-0.63-20.89-0.07-31.27,0.85c-5.7,0.5-11.81-2.12-10.37-6.13
            c5.49-15.26-10.76-19.81-13.36-30.69c0.08-0.9,0.15-1.81,0.23-2.72c1.82-6.04,0.91-13.32,7.69-17.14
            c4.69-1.43,9.36-3.04,14.36-1.61c1.86,0.72,3.51,1.78,4.95,3.17c3.71,6.51,5.94,14.3,14.39,16.79
            c12.77,4.37,27.8,0.95,38.73,11.73C-144.9,132.97-141.67,135.06-143.35,139.76z"
            />
            <path
              style="fill: #1f2417"
              d="M-281.16,103.3c8.36-2.17,17.35-0.46,25.37-5.59c6.39-4.1,11.46-0.01,15.17,5.59
            c8.51,2.22,20.66,1.68,12.45,16.52c0,0,0.01,0.07,0.02,0.07c-13.41-0.47-25.02,1.82-31.97,15.65
            c-3.61,7.18-11.26,9.77-19.14,6.99c-7.35-2.59-8.96-8.85-8.8-16.29C-287.87,117.82-304.09,104.85-281.16,103.3z"
            />
            <path
              style="fill: #7d8d59"
              d="M-204.17,192.54c10.29,3.05,10.04,12.4,12.15,20.35c-10.54-2.12-12.17-11.2-15.03-19.3
            C-206.07,193.3-205.11,192.95-204.17,192.54z"
            />
            <path
              style="fill: #374c20"
              d="M-9.63,119.8c-6.61-9.2-16.89-12.09-26.48-10.05c-15.07,3.21-15.88-3.86-14.1-14.94
            c5.07-9.89,0.68-19.07-2.14-28.39c0.14-6.97,5.36-9.55,10.49-12.2c3.81-2.49,15.54-2.98,2.44-9.76
            c3.47-10.17,12.61-10.71,21.05-12.46c11.97,6.55,10.8,17.14,8.62,28.05c-1.18,6.41,3.19,13.1-0.83,19.38
            c-1.95,3.62-7.17,3.21-8.87,7.18c-2.13,1.67,1.11,2.5,0.74,0.72c-0.49-2.33,2.97-3.66,5.56-4.33c3.64-1.16,7.25-2.47,11.15-1.31
            c6.06,2.64,12.53,3.09,18.96,4.15c8.43,1.39,15,5.53,10.26,16.08c-4.06,7.64-10.9,12.41-17.74,17.17
            c-5.31-0.12-9.4,3.27-14.11,4.85C-8.02,125.08-10.95,124.77-9.63,119.8z"
            />
            <path
              style="fill: #69902b"
              d="M27.22,101.93c-2.59-8.89-8.47-11.67-16.48-7.53C2.74,98.52,0.2,93.52-2.53,87.93
            c0.46-1.96,1.37-3.68,2.72-5.17c11.18-5.92,20.47-15.65,33.95-16.99c13.57,3.48,16.95,12.19,12.65,24.81
            c-1.21,2.85-2.42,5.7-3.64,8.55c-4.98-2.97-8.99-1.7-12.26,2.8c0-0.01-1.84,0.15-1.84,0.15L27.22,101.93z"
            />
            <path
              style="fill: #425627"
              d="M50.97,158.99c-11.21,3.64,6.79,20.3-8.55,21.31c-7.15,0.47-17.94-5.26-16.84-16.3
            c1.2-11.98,14.67-10.7,22-16.19C49.61,146.28,50.34,153.86,50.97,158.99z"
            />
            <path
              style="fill: #a5c420"
              d="M-9.63,119.8c6.57,5.16,12.42-8.99,19.1-0.7c-4.11,3.26-10.84,5.06-7.11,12.71
            c-7.83,7.02-14.31,18.53-27.34,8.22C-22.64,131.17-14.89,126.43-9.63,119.8z"
            />
            <path
              style="fill: #415732"
              d="M-143.35,139.76c-13.65-13.35-33.33-9.18-48.93-16.89c-0.47-1.34-0.36-2.61,0.29-3.77
            c-0.11,0.05,0.36-1.5,0.36-1.5s-1.59,0.31-1.72,0.32c-8.45-1.46-5.33-11.28-10.89-14.77l0.15,0.12
            c-15.7-13.82-17.48-31.97-15.92-51.19c1.14-1.57,2.6-2.77,4.32-3.66c8.26-1.29,11.31-5.8,9.27-14.11
            c-1.75-7.12-0.84-13.44,7.27-16.4c5.4-1.38,9.77,0.87,13.95,3.86c7.34,7.79,11.49,18.04,19.51,25.35
            c2.33,4.33,4.19,8.82,4.2,13.85c3.28,13.37,1.07,27.93,8.83,40.32c5.74,10.18,8.54,22.02,17.3,30.48
            C-136.13,136.34-138.82,138.98-143.35,139.76z"
            />
            <path
              style="fill: #334227"
              d="M-135.36,131.78c-12.57-6.28-27.07-10.97-24.49-29.74c-0.23-7.01,0.37-15.5,8.32-16.12
            c16.08-1.24,27.74-11.65,41.71-17.12c12.4,4.43,8.73,14.3,7.87,23.23c-5.02,10.58-19.72,8.08-24.9,18.47
            C-132.94,116.29-138.89,122.14-135.36,131.78z"
            />
            <path
              style="fill: #32421e"
              d="M-74.62,94.94c-6.98,7.04-12.96,15.53-24.08,16.52c-2.72-4.78-6.91-7.4-12.25-8.29
            c-2.49-6.61,7.81-7,6.35-13.11c4.6-15.82,13.4-16.02,24.83-6.76C-76.94,86.66-72.26,89.27-74.62,94.94z"
            />
            <path
              style="fill: #6f9294"
              d="M-103.02,91.14c0.49,6.09-4.68,8.43-7.93,12.04c-5.3,2.44-10.6,4.88-15.9,7.32
            c2.24-11.03,14.02-12.21,19.98-19.41C-105.58,90.04-104.29,90-103.02,91.14z"
            />
            <path
              style="fill: #202c19"
              d="M-103.02,91.14l-1.93-0.15c0,0-1.93,0.1-1.93,0.1c-2.74-6.34,3.25-14.83-4.88-19.83
            c-3.55-0.36-4.64-2.44-4.01-5.73c7.57-5.67,11.34-14.14,16.01-21.95c3.28-3.41,7.16-5.95,9.34-7.05
            c-11.14,6.35-15.14-7.2-23.04-12.01c-0.99-1.68-1.62-3.49-1.99-5.4c3.12-25.31,9.3-29.95,31.18-20.07
            c12.74,5.75,15.97-5.11,22.86-9.26c-1.79-0.27-1.99,1.59-3.2,2.01c-18.64,6.97-22.35,4.39-27.44-18.8
            c-4.51-8.94-0.92-18.51-2.12-27.72c-3.03-10.78-11.75-19.18-12.94-30.76c1.03-5.75,4.45-9.01,10.21-9.82
            c5.55-0.19,9.41,3.07,13.16,6.5c3.27,3.21,6.34,6.64,9.66,9.75c2.17,2.21,4.52,3.68,7.64,1.53c2.45-2.23,4.8-4.51,7.89-5.84
            c1.73-0.67,3.5-0.98,5.34-1c10.01,1.32,15.89,8.22,21.32,15.67c5.67,3.06,10.05-1.85,15.1-2.44c4.71-2.29,9.39-3.93,13.88,0.47
            c4.05,5.67,3.96,11.63,1.34,17.78c-6.27,10.48-18.25,18.51-14.36,33.41c-0.31,7.1-7.3,12.9-3.98,20.7
            c0.52,8.04,1.64,15.91,6.68,22.64c1.8,3.63,2.44,7.27,0.24,10.97c-1.81,1.13-3.5,0.97-5.07-0.49
            c-4.78,7.16-13.42,7.59-20.05,11.51c-0.02,4.35-2.62,6.94-6.05,8.97c-8,2.02-13.92,5.13-7.83,14.71
            c2.18,4.93,1.57,9.22-2.86,12.64c-1.28,1.37-2.56,2.74-3.84,4.11c-7.85,1.49-15.55,6.53-23.7,1.03
            C-89.71,86.23-98.05,79.52-103.02,91.14z"
            />
            <path
              style="fill: #61882d"
              d="M-54.87,82.21c-2.45-3.82-1.49-7.52,0.39-11.18c0.43-2.62,1.99-4.07,4.59-4.44
            c15.33,6.46,15.28,10.88-0.33,28.22C-51.76,90.61-53.32,86.41-54.87,82.21z"
            />
            <path
              style="fill: #a3bd4c"
              d="M-78.95,139.39c-4.98,1.45-9.96,2.9-17.1,4.98c3.95-5.53,6.51-9.12,9.07-12.7
            c3.76-0.98,7.54-1.91,11.29-2.95c3.58-0.99,6.74-4.53,11.93-1.11C-64.98,136.32-74.81,134.58-78.95,139.39z"
            />
            <path
              style="fill: #374c20"
              d="M-82.4,87.35c7.98,1.52,15.32-12,23.7-1.02c-3.21,6.74-9.72,7.39-15.92,8.62
            C-77.37,92.57-82.56,92.7-82.4,87.35z"
            />
            <path
              style="fill: #688831"
              d="M-294.02-400.65c4.23,2.38,8.61,2.8,13.16,1.05c3.09,2.47,6.96,4.16,8.31,8.36
            c1.69,10.35,7.66,16.12,17.5,17.98c8.19-11.8-12.28-17.62-4.42-27.48c3.24-4.06,10.03-2.32,14.97,0.69
            c0.82,0.6,1.65,1.2,2.48,1.8c3.32,3.84,2.94,8.83,3.9,13.32c5.32,25.07-1.73,32.97-26.33,29.83c-4.57-0.58-9.39,1.63-13.74-1.21
            c-5.28-7-10.64-13.8-20.98-8.57c-9.26-3.02-15.85-7.58-10.76-18.81C-300.44-385.41-298.08-393.83-294.02-400.65z"
            />
            <path
              style="fill: #33441e"
              d="M-309.93-383.69c-0.03,9.21,11.19,9.07,13.52,16.31c4.7,2.83,6.97,7.35,8.59,12.33
            c0.62,3.12,0.11,6.12-0.97,9.04c-7.05,9.42-17.1,16.26-23.02,26.7c-3.78,1.99-7.56,3.83-11.12-0.39
            c8.71-20.55-5.39-39.79-3.45-59.96c0.48-1.35,0.97-2.7,1.45-4.04C-319.94-383.69-314.93-383.69-309.93-383.69z"
            />
            <path
              style="fill: #9ab457"
              d="M-272.54-391.25c-8.17,2.59-10.28-0.85-8.31-8.36c5.42-7.87-10.47-11.86-3.63-19.98
            C-266.86-415.91-273.31-402.05-272.54-391.25z"
            />
            <path
              style="fill: #2c391b"
              d="M-240.26-404.93c2.22,8.76,17.03-4.49,16.34,9.36c2.16,3.19,2,6.3-0.31,9.35
            c-15.08,7.73-16.96-4.07-20.28-13.74c0,0,0.01-0.1,0.01-0.1C-243.08-401.68-241.67-403.3-240.26-404.93z"
            />
            <path
              style="fill: #6d8c30"
              d="M-225.06-388.07c0.38-2.5,0.76-5,1.14-7.5c2.75-0.37,5.49-0.74,8.24-1.11
            c2.65-4.58,5.81-7.52,11.15-3.48c0.5,4.95,1.02,9.9,5.37,13.3c0.45,2.01,0.06,3.83-1.16,5.5c-5.81,4.61-12.51,5.89-19.74,5.29
            C-225.8-378.35-228.24-382.04-225.06-388.07z"
            />
            <path
              style="fill: #3a4b21"
              d="M-204.53-400.16c-3.72,1.16-7.44,2.33-11.15,3.49c-0.82-6.82-3.8-14.02,3.49-19.37
            C-193.35-418.62-203.98-406.96-204.53-400.16z"
            />
            <path
              style="fill: #496828"
              d="M-435.63-307.08c-1.28-5.22-2.55-10.44-3.83-15.67c10.97,6.84,21.93,13.69,32.9,20.53
            c3.61,5.18,5.9,10.36,0.04,15.56c-5.32,6.13-11.42,8.3-18.99,4c-5.83-3.31-3.02-7.68-1.66-11.91
            C-431.82-297.49-433.21-302.63-435.63-307.08z"
            />
            <path
              style="fill: #8dac21"
              d="M-358.12-319.22c3.31,0.15,6.62,0.3,9.93,0.45c5.01,20.17-9.43,25.1-23.65,30.26
            c-8.67,1.05-15.03-2.46-19.38-9.89c-3.64-9.43-1.89-17.81,4.88-25.23c3.84-0.91,7.68-1.81,11.51-2.72c0,0-0.03-0.11-0.03-0.11
            C-370.41-321.42-363.75-321.5-358.12-319.22z"
            />
            <path
              style="fill: #7b9c2d"
              d="M-386.34-323.62c-0.06,8.54-0.11,17.09-0.17,25.63c-1.76,12.67-9.84,13.97-20.17,11.25
            c0,0,0.15,0.1,0.15,0.1c-0.46-5.19-3.23-10.37-0.04-15.56C-398.54-308.14-401.05-324.03-386.34-323.62z"
            />
            <path
              style="fill: #676c3a"
              d="M-439.33-322.54c-8.11-3.49-14.25-11.49-24.27-10.61c-0.43-9.37,9.2-9.49,13.42-14.58
            c3.96,3.39,7.96,5.13,12.13,0.32C-431.95-338.79-436.36-330.7-439.33-322.54z"
            />
            <path
              style="fill: #313e1e"
              d="M-322.94-319.68c3.56-1.4,7.11-2.79,10.67-4.19c0.87-16.36,8.39-16.21,19.28-7.8
            c2.47,1.97,4.12,4.48,5.04,7.48c3.39,20.32,5.18,40.6-2.47,60.52c-2.98,3.19-6.65,4.89-11.02,5.08
            c-9.04-0.9-13.5-8.58-19.88-13.45c-2.18-3.42-2.18-7.36-2.59-11.2c-1.11-7.03-1.22-14.58-9.14-18.19
            c-7.43-3.54-15.38-6.64-14.84-17.1c1.65-10.99,16.55-9.73,18.71-20.26C-332.89-330.52-322.22-326.97-322.94-319.68z"
            />
            <path
              style="fill: #1f2515"
              d="M-347.89-318.53c3.53,7.7,14.29,3.8,17.99,11.23c0.68,3.61,2.44,7.07,1.65,10.91
            c-2.87,9.32-10.88,16.58-10.82,27.1c-1.75,8.16,2.25,17.54-5.1,24.53c-1.71,1.08-3.57,1.69-5.6,1.8
            c-16.11-3.83-17.9-19.45-25.18-30.59c0.03-7.34,2.33-14.27,4.13-21.3c10.17-5.47,19.71-11.55,22.61-23.92
            C-348.19-318.76-347.89-318.53-347.89-318.53z"
            />
            <path
              style="fill: #30411d"
              d="M-358.12-319.22c-12.47,13.56-12.71-1.22-16.74-7.24C-368.12-326.73-360.88-328.15-358.12-319.22
            z"
            />
            <path
              style="fill: #6e9346"
              d="M-504.32-393.13c8.61-2.61,18.22-3.01,24.39-11.04c1.43-0.63,2.86-1.26,4.3-1.9
            c-0.21,3.29-0.41,6.58-0.62,9.87c-10.55,4.44-20,11.65-31.91,12.64c-0.2-1.52-0.4-3.04-0.6-4.56
            C-507.28-389.79-505.8-391.46-504.32-393.13z"
            />
            <path
              style="fill: #949a65"
              d="M-467.86-374.97c-1.32-2.96-2.64-5.92-3.96-8.88c16.16-1.66,12.39,9.36,11.93,20.39
            C-463.2-368.24-465.53-371.61-467.86-374.97z"
            />
            <path
              style="fill: #a1be2b"
              d="M83.09,62.58c-1.94-0.32-5.35-0.19-5.6-1.01c-7.04-23.77-25.53-29.78-47.22-31
            c-3.14-9.31,3.12-14.79,8.39-20.66c4.51-2.64,10.67-3.18,12.29-9.57c2.11-5.36-0.18-11.13,1.54-16.54
            c0.58-5.18,0-10.56,2.94-15.29c1.37-1.84,3.08-3.22,5.14-4.21c16.96-4.5,22.56,4.71,23.58,19.5
            c-6.78,18.46,8.21,29.51,14.84,43.4c1.71,2.16,3.18,4.47,4.57,6.84c1.15,2.55,2.3,5.1,3.44,7.64
            c-14.28-7.62-17.97,0.8-19.33,12.76C83.98,55.94,84.13,59.6,83.09,62.58z"
            />
            <path
              style="fill: #c5d332"
              d="M103.56,34.05c-2.16,0.09-4.31,0.19-6.47,0.28c-7.54-9.69-2.69-13.82,7.05-15.75
            c8.63,1.55,13.82-4.64,19.88-8.86C127.62,26.61,114.15,29.11,103.56,34.05z"
            />
            <path
              style="fill: #40512a"
              d="M-16.42,26.25c-12.07,1.47-14.48-5.03-9.07-24.44C-21.41-8.57-13.08-12.97-2.7-14.57
            c9.5,3.69,21.07-5.12,29.62,4.76c3.49,6.5,1.24,13.74,2.65,20.52c2.55,7.3-0.05,13.89-2.79,20.47c0.04,2.4,0.08,4.81,0.13,7.21
            c-1.57,5.5-5.16,7.42-10.55,6.08c-2.74-3.74-6.58-5.61-11.27-6.02C-1.52,33.31-7.9,27.9-16.42,26.25z"
            />
            <path
              style="fill: #92b625"
              d="M14.99,41.88c3.97-1.16,7.94-2.33,11.91-3.49c10.62-0.65,20.46-0.2,21.11,14.15
            c0.06,1.24,0.64,3.14,1.52,3.56c30.19,14.03,12.32,24.14-2.74,34.49c-2.27-7.72-4.31-15.57-11.69-20.35
            c-1.15-0.56-2.09-1.36-2.86-2.4c-2.31-0.97-4.15,0.24-6.07,1.27c-4.96,2.94-9.9,3.8-14.93,0.22C7.61,59.52-1.06,49,14.99,41.88z
            "
            />
            <path
              style="fill: #5e832b"
              d="M26.77,31.18c-1.34-6.33-2.69-12.67-4.03-19c9.37-3.9,2.62-19.04,14.67-21.1
            c5.1-0.08,8.37,2.5,10.31,7.05c1.91,6.34-0.39,11.44-4.66,15.96c-4.26,5.49-8.52,10.99-12.79,16.48
            C29.1,30.74,27.93,30.94,26.77,31.18z"
            />
            <path
              style="fill: #69902c"
              d="M104.15,18.58c-5.95,3.64-9.67,8.27-7.06,15.75C81.56,28.11,81.86,9.24,69.13-0.45
            c-6.58-5.01,2.68-11.99,10.78-13.2c5.28-2.66,9.02-10.03,16.66-5.44c4.05,11.1,12.2,14.56,23.29,12.51
            C118.88,4.47,93.9,0.5,104.15,18.58z"
            />
            <path
              style="fill: #7aa62c"
              d="M96.57-19.09c-5.41,2.25-9.13,9.72-16.67,5.44c-2.1-9.02-4.13-18.12-16.56-17.15
            c-7.2-14.79,5.65-16.54,13.82-21.36c3.21-1.1,6.46-0.93,9.71-0.24c2.09,0.43,3.99,1.33,5.68,2.64
            c7.06,6.81-0.18,15.73,3.36,23.09C96.14-24.14,96.35-21.62,96.57-19.09z"
            />
            <path
              style="fill: #9cbf25"
              d="M133.25-59.34c5.11,5.1,1,8.89-1.82,12.87c-1.21-0.12-2.4-0.31-3.59-0.56
            c-13.22-5.09-15.33-18.4-21.36-28.83c2.48-8.76-1.83-19.77,8.46-26.01c10.35-0.09,15.47,8.33,22.36,13.88
            C139.75-77.9,130.3-69.5,133.25-59.34z"
            />
            <path
              style="fill: #658e2f"
              d="M112.24-75.41c9.35,7.18,9.29,19.54,15.6,28.38c-9.85,1.23-16.39-3.04-20.09-12.06
            c-2.57-2.06-3.44-4.82-3.27-8.01C105.47-71.35,107.82-74.35,112.24-75.41z"
            />
            <path
              style="fill: #b0c260"
              d="M95.92-26.67c-11.19-4.97-3.43-13.35-3.64-20.3c-0.27-7.08,3.32-6.19,7.67-3.72
            C111.72-40.48,100.32-34.16,95.92-26.67z"
            />
            <path
              style="fill: #93b72f"
              d="M99.95-50.69c-2.56,1.24-5.12,2.48-7.68,3.72c-0.86,0-1.72,0.07-2.57,0.19
            c-10.67-14.45-9.19-19.07,8.75-27.44c4.61,0.61,7.9,2.88,9.24,7.53c0.02,2.53,0.05,5.07,0.07,7.6
            C105.15-56.29,102.55-53.49,99.95-50.69z"
            />
            <path
              style="fill: #cad654"
              d="M133.25-59.34c-11.42-10.69,0.79-18.81,3.05-28.01c-3.1-4.1-3.56-8.16,0.15-12.18
            c3.42,3.28,4.61,7.35,4.25,11.97c-1.29,7.93,3.91,16.02-0.58,23.88C137.82-62.24,135.54-60.79,133.25-59.34z"
            />
            <path
              style="fill: #c0c9a2"
              d="M154.51-380.09c-0.69,5.65-1.38,11.31-2.08,16.96c-2.6,4.69-5.2,9.37-7.79,14.06
            c-4.03,6.84-7.78,1.75-11.61-0.26c-3.18-2.39-6.27-4.85-7.79-8.73c-0.86-2.98-0.72-5.94,0.3-8.85
            c1.68-9.03,3.55-17.97,13.72-21.62c3.02-0.61,5.97-0.41,8.78,0.97c1.66,1.1,3.24,2.32,4.75,3.63
            C153.36-382.66,153.93-381.37,154.51-380.09z"
            />
            <path
              style="fill: #869b51"
              d="M152.78-383.94c-1.38,0.07-2.76,0.13-4.15,0.2c-6.24-4.94-9.16-12.47-14.28-18.31
            c-1.26-1.29-2.39-2.68-3.41-4.17c-1.97-3.25-3.31-6.63-2.48-10.53c0.98-4.08,4.07-6.03,7.62-7.51c1.13,0.09,2.22,0.34,3.26,0.75
            C158.54-415.34,153.33-398.84,152.78-383.94z"
            />
            <path
              style="fill: #45593a"
              d="M149.01-327.16c1.29,1.32,2.58,2.63,3.88,3.95c-0.09,4.31-0.19,8.63-0.28,12.94
            c1.47,5.46,4.18,10.73,2.58,16.68c-1.4,2.75-3.55,4.61-6.46,5.62c-5.51,1.26-9.05-2.39-12.92-5.2
            c-23.09-12.76-23.35-16.26-2.46-32.46C138.27-329.11,143.4-330.69,149.01-327.16z"
            />
            <path
              style="fill: #7b956f"
              d="M148.3-291.64c1.49-1.16,2.98-2.31,4.48-3.47c4.66-2.12,8.79,1.44,13.28,1.23
            c0,0,1.55-0.06,1.54-0.06c-0.39,7.52,6.21,10.96,9.5,16.33c-4.86,5.88-7.69,14.45-17.77,13.42c-4.9,0.19-9.43-0.7-13.05-4.37
            c-4.31-5.3-3.83-11.21-2.21-17.26C145.14-288,146.55-289.94,148.3-291.64z"
            />
            <path
              style="fill: #273820"
              d="M166.05-293.87c-2.91,3.8-5.82,7.6-8.74,11.4c-1.51-4.21-3.03-8.42-4.54-12.63
            c-3.6-5.02-4.52-10.06-0.17-15.16C159.69-306.94,161.81-299.54,166.05-293.87z"
            />
            <path
              style="fill: #61785d"
              d="M149.01-327.16c-4.37,2.28-8.75,4.56-13.12,6.84c0.17,4.97-3.59,3.05-6.02,3.45
            c-10.89-6.08-9.02-15.68-7.04-25.35c3.47-5.62,7.12-11.1,13.71-13.59c4.76-0.24,4.85,5.16,8.11,6.74
            C155.11-343.57,151.49-335.25,149.01-327.16z"
            />
            <path
              style="fill: #51662a"
              d="M160.41-268.74c5.56-2.96,11.12-5.91,16.69-8.87c-0.07,2.28-0.15,4.55-0.23,6.82
            c-1.54,5.82-3.07,11.66-4.61,17.49c-3.17,2.93-6.77,3.79-10.87,2.26c-1.74-1-3.11-2.38-4.12-4.12
            C154.99-260.46,157.39-264.67,160.41-268.74z"
            />
            <path
              style="fill: #2e3e1d"
              d="M127.54-319.76c2.78-0.18,5.56-0.37,8.34-0.56c1.18,3.36-0.51,4.68-3.57,5.16
            c-3.71,0.58-8.96-0.08-9.66,4.58c-0.92,6.08,5.33,6.13,9.14,8c2.87,1.41,5.78,2.72,8.68,4.08c-2.85,7.66,5.49,9.08,7.72,13.91
            c0.07,4.89,0.15,9.78,0.22,14.67c0.2,0.84,0.4,1.67,0.6,2.51c0.89,7.43-1.27,15.51,4.73,21.84c0.18,7.38-2.89,13.91-5.37,20.59
            c-1.21,1.16-2.43,2.31-3.64,3.47c-11.89,4-24.16,1.52-36.24,2.18c-11.91-3.29-14.59,11.4-24.24,12.55
            c-8.76,3.32-12.17-14.01-22.23-5.71c-3.28,1.45-6.4,3.18-9.57,4.85c-10.07,5.92-21.96,3.59-32.54,7.29
            c-3.92,0.36-7.27-1.54-10.79-2.7c-7.51,0.18-14.57-8.03-22.48-1.12c-11.95,4.2-24.56,10.57-29.2-8.92
            c-2.62-4.84-5.03-9.84-9.11-13.74c-9.01-10.8-0.74-14.93,7.3-19.15c8.91-3.69,0.43-13.62,6.49-18.36
            c3.39-2.81,7.25-3.65,11.52-2.89c4.16,0.84,7.77,2.84,11.15,5.31c3.29,3.65,6.33,7.53,9.68,11.12
            c3.97,7.51,8.99,10.13,17.91,6.86c10.05-3.68,19.08-9.46,29.15-12.9c4.26-1.03,7.69-3.17,9.72-7.25
            c4.47-9.56,0.7-26.16,15.23-26.95c14.99-0.81,14.11,14.52,16.75,25.14c1.55,6.22,2.32,15,11.75,6
            c3.62-11.15-6.3-13.75-11.95-18.99c-3.64-12.51-20.15-14.53-22.84-27.86c1.67-6.93,3.15-13.66,2.36-21.09
            c-1.01-9.53,3.66-14.94,14.15-12.87c6.31,2.26,12.59,4.6,17.69,9.16c3.26,3.39,5.36,9.05,11.9,6.05
            c4.7-2.35,9.24-5.53,14.88-2.81C124.16-326.13,124.87-322.22,127.54-319.76z"
            />
            <path
              style="fill: #688c39"
              d="M148.38-224.98c-3.73-6.54,0.97-13.39-0.74-20c1.63-6.63,2.79-13.95,12.61-8.73
            c4.01,0.14,8.01,0.27,12.01,0.41C175-234.82,153-237.24,148.38-224.98z"
            />
            <path
              style="fill: #455c27"
              d="M108-225.37c12.41-0.27,25.17-3.98,36.73,3.87c-0.08,1.14-0.13,2.28-0.14,3.42
            c-0.16,9.13-4.5,13.05-13.64,11.02c-4.63-1.3-9.35,0.49-13.77,0.12C105.05-207.95,100.46-213.94,108-225.37z"
            />
            <path
              style="fill: #93ba2f"
              d="M161.43-185.12c-3.04,2.64-6.09,5.28-9.13,7.92c-11.86,13.15-15.36,3.61-18.56-6.83
            c3.13-6.13,4.66-14.16,14.51-12.48C151.47-191.35,160.53-192.96,161.43-185.12z"
            />
            <path
              style="fill: #455c27"
              d="M148.25-196.5c-4.03,3.79-3.55,12.04-11.66,11.82c-4.38,2.4-7.24,0.04-9.85-3.14
            c-3.07-7.81,2.96-12.3,6.44-17.72c4.98-2.13,10.34-2.68,15.61-3.65C157.12-204.6,151.3-200.61,148.25-196.5z"
            />
            <path
              style="fill: #87af2b"
              d="M148.79-209.19c-2.54,6.53-7.09,6.83-12.62,4.07c-2.43,2.43-5.12,2.41-7.96,0.83
            c-2.19-5.05,0.97-7.08,4.89-8.68c6.02,3.23,7.68-3.38,11.49-5.12C148.08-216.12,148.11-212.5,148.79-209.19z"
            />
            <path
              style="fill: #9da56e"
              d="M-516.65-128.46c1.7,0.27,3.4,0.54,5.1,0.81c2.33,13.57-2.46,19.5-16.55,15.44
            c1.07-4,2.15-8,3.22-12C-522.14-125.63-519.39-127.04-516.65-128.46z"
            />
            <path
              style="fill: #3e5321"
              d="M-528.09-112.21c9.67-0.69,14.41-6.67,16.55-15.44c6.99,4.09,21.13,6.78,3.09,15.78
            c-2.76,2.82-5.53,5.64-8.29,8.46c-5.29-1.49-10.58-2.99-15.86-4.49C-531.11-109.33-529.6-110.77-528.09-112.21z"
            />
            <path
              style="fill: #3b4f20"
              d="M-200.53-384.2c0.36-0.93,0.82-1.82,1.36-2.66c2.66-0.19,5.32-0.39,7.99-0.59
            c4.75,4.81,1.97,15.05,11.73,16.23c1.41,1.31,2.31,2.91,2.7,4.79c0.06,1.85-0.28,3.64-1.02,5.35c-3.65,4.68-9.5,7.97-9.53,14.99
            c0.1,6.03-1.05,11.36-7.58,13.69c-3,0.56-5.79-0.35-8.61-1.16c-4.32-2.38-7.27-6.08-9.78-10.19c-1.03-8.1-10.28-9.3-13.32-15.76
            c-1.07-3.96,0.03-7.43,2.36-10.63C-219.05-379.43-203.23-370.72-200.53-384.2z"
            />
            <path
              style="fill: #7eac27"
              d="M-150.73-379.92c5.44-4.97,10.71-2.51,15.98,0.35c1.45,12.61,4.07,26.2-16.81,20.14
            c-1.21-2.09-1.06-4.08,0.45-5.97C-144.56-370.07-147.81-375-150.73-379.92z"
            />
            <path
              style="fill: #729537"
              d="M-151.11-365.4l-0.22-0.08c-10.3,20.52-19.36,6.97-28.63-1.05c0.17-1.56,0.34-3.12,0.51-4.69
            C-167.15-383.21-161.32-363.62-151.11-365.4z"
            />
            <path
              style="fill: #2d3b1c"
              d="M-179.95-366.53c9.71-4.01,18.59,14.07,28.63,1.06c8.76,1.76,8.76,1.76,16.57-14.09
            c0.83,0.11,1.66,0.1,2.49-0.04c11.18,8.99,6.94,17.47-0.85,25.82c-4.09,4.77-7.25,10.58-13.83,12.64
            c-5.93,1.71-10.32,6.58-16.36,8.11c-13.54,5.94-22.8,2.35-27.13-12.2c-0.16-8.09,3.8-13.78,10.45-17.89
            C-179.83-364.26-179.82-365.39-179.95-366.53z"
            />
            <path
              style="fill: #819938"
              d="M-139.79-355.51c6.87-6.67,9.86-14.55,7.53-24.09c1.09-0.14,2.19-0.15,3.29-0.04
            c9.44-4.94,14.37,0.64,18.58,7.96c2.46,14.93,1.39,27.37-18.59,26.57C-134.42-346.65-138.36-349.78-139.79-355.51z"
            />
            <path
              style="fill: #b9c973"
              d="M-114.74-371.26c-4.36-3.45-7.49-8.98-14.23-8.38c-0.44-2.56-0.87-5.11-1.3-7.67
            c1.02-0.01,1.94-0.31,2.77-0.9c4.57-2.06,6.28,1.9,8.79,4.19c4.37,0.16,8.68,0.11,12.09-3.27c7.09-2.22,9.36,2.17,10.72,7.83
            c-0.93,7.85-5.88,10.89-13.22,11.07C-111.21-368.9-113.09-369.86-114.74-371.26z"
            />
            <path
              style="fill: #879656"
              d="M-118.71-384.02c-2.93-1.4-5.86-2.79-8.79-4.19c2.15-11.55,9.74-15.97,20.76-15.92
            C-109.41-396.64-121.54-394.79-118.71-384.02z"
            />
            <path
              style="fill: #425622"
              d="M-50.17-399.78c4.05,12.05-9.24,6.96-12.06,12.22c-2.18,2.79-5.1,4.02-8.57,4.11
            c-6.29-2.82-16.37-0.82-15.79-12.4c0.03-1.56,0.07-3.12,0.1-4.68C-74.38-400.27-62.27-400.02-50.17-399.78z"
            />
            <path
              style="fill: #81a23f"
              d="M-86.58-395.84c3.78,6.07,11.64,3.64,16.32,7.84c0.49,6.31,4.32,12.87-1.35,18.77
            c-3.8,3.96-8.31,5.38-13.65,3.83c-6.82-2.56-11.61-7.06-13.51-14.29c-1.56-3.62-3.98-6.36-7.84-7.59
            C-103.88-399.39-93.59-393.77-86.58-395.84z"
            />
            <path
              style="fill: #30441e"
              d="M144.25-104.34l-1.87,0.11l-1.76-0.63c-7.71-3.04-13.77,9.78-21.95,2.29
            c-3.71-2-7.08-0.66-10.4,1.11c-4.69,3.95-9.37,7.87-16.21,6.14c-11.85-12.22-10.63-21.7,4.12-27.94
            c7.86-3.33,16.57-4.32,24.48-7.69c8.65-3.72,14,3.1,20.44,6.45C152.4-119.38,144.68-111.29,144.25-104.34z"
            />
            <path
              style="fill: #405324"
              d="M141.1-124.49c-6.31,4.16-11.53,2.94-15.75-3.21c-7.66-0.85-12.49-4.79-12.17-12.8
            c0.23-5.92,4.36-9.52,9.77-11.29c6.4-2.09,11.44,0.24,15.98,5c2.79,2.93,2.51,7.66,6.34,9.77c0,0,0.32,0.16,0.32,0.16
            C144.09-132.74,142.59-128.61,141.1-124.49z"
            />
            <path
              style="fill: #5b862e"
              d="M145.27-137.02c-2.26-1.02-5.31-1.44-6.65-3.18c-4.76-6.17-10.44-8.92-16.86-3.72
            c-8.7,7.05,1.06,11.06,3.59,16.22c-7.3,7.6-16.36,10.74-26.66,12.8c-11.7,2.35-15.83,8.28-2.6,15.89
            c7.09,3.47,10.95,8.86,10.15,17.04c-1.83,4.38-5.22,6.02-9.79,5.57c-1.92-0.54-3.63-1.5-5.12-2.83
            C85.4-88.01,76.37-92.07,66.77-95.3c-9.92-3.34-18.42-8.11-19.16-20.24c0.42-4.18,2.41-7.49,5.61-10.12
            c11.39-2.8,23.82-3.71,24.41-20.39c0.19-5.36,6.76-7.61,11.55-10.1c6.06-3.15,11.1-7.18,11.51-14.97
            c0.67-12.6,8.42-18.87,19.98-21.5c3.09,0.13,5.68,1.28,7.67,3.7c2.75,1.42,5.5,2.83,8.26,4.25c3.84,5.42,6.56,13.22,15.71,7.49
            c-1.31,4.28-2.63,8.56-3.94,12.85C137.27-156.38,147.28-146.02,145.27-137.02z"
            />
            <path
              style="fill: #557b2f"
              d="M119.83-107.03c5.72,4.46,7.86-1.52,11.43-3.77c6.95-4.39,9.64-1.4,9.36,5.95
            c-1.39,1.77-2.79,3.54-4.18,5.32c-0.05,4.06-0.1,8.12-0.15,12.18c-16.05,11.13-15.85-4.46-20.31-12.38
            C113.42-104.2,112.83-107.62,119.83-107.03z"
            />
            <path
              style="fill: #7b9741"
              d="M10.69-404.44c0.34,12.37-10.53,20.89-11.26,32.9c-11.67,6.64-14.6-2.99-18.91-10.13
            c-1.62-5.74-0.61-10.49,5.14-13.42c0,0,0.91-1.33,0.91-1.34c10.13,1.97,11.86-11.43,20.9-11.45
            C10.3-408.39,11.76-407.6,10.69-404.44z"
            />
            <path
              style="fill: #a2c153"
              d="M10.69-404.44c-1.08-1.15-2.16-2.29-3.23-3.44c-0.35-7.06-1.17-14.22,4.05-20.15
            c0.94,0.43,1.9,0.83,2.87,1.19c0.83,7.48,12.99,14.79,0.32,22.46c0,0-2.01,0.23-2.01,0.23L10.69-404.44z"
            />
            <path
              style="fill: #c0c3a3"
              d="M-14.34-395.09c0.08,2.45-2.95,5.1,0.48,7.33c2.47,9.78,5.53,19.49,3.64,29.83
            c-2.27,3.82-5.6,6.27-9.78,7.64c-7.55-1.64-16.84,3.78-22.56-5.47c1.45-3.57,5.11-8.13,4.02-10.56
            C-50.67-393.32-30.63-392.51-14.34-395.09z"
            />
            <path
              style="fill: #8eb92c"
              d="M-74.57-371.34c-3.82-3.4,3.05-8.93-3.87-13.59c-3.46-2.33,5.67-0.8,8.18-3.06
            c2.68,0.15,5.36,0.3,8.04,0.45c3.26,8.77-1.18,21.57,12.88,24.69c-7.77,14.24-16.9,11.01-26.49,2.09
            C-77.63-364.56-76.62-368.01-74.57-371.34z"
            />
            <path
              style="fill: #c8d0de"
              d="M-42.55-355.76c7.13-0.63,14.36-3.49,21.23,1.07c2.93,4.84,1.6,9.43-0.83,13.97
            c-1.29,1.57-2.85,2.81-4.64,3.75c-5.4,2.63-10.47,6.86-17.13,3.72c-7.06-5.71-12.05-11.93-4.84-20.95
            c0.95-0.38,1.91-0.76,2.87-1.13C-44.76-355.29-43.65-355.44-42.55-355.76z"
            />
            <path
              style="fill: #546831"
              d="M-74.18-363.77c8.11,4.9,16.32,7.22,24.83,0.9c1.15,2.51,2.29,5.03,3.44,7.54
            c0,0,0.28,0.76,0.28,0.76c-7.26,1.71-8.75,12.24-17.6,11.55c-10.74-2.34-21.9-4.08-24.65-17.62
            C-84.66-367.56-79.53-366.17-74.18-363.77z"
            />
            <path
              style="fill: #37461e"
              d="M74.5-334.74c-9.38-1.96-5.87,4.36-4.75,8.07c2.54,8.38,1.69,15.32-6.37,20.17
            c-6.78-0.55-10.14-4.78-11.81-10.89c0.3-7.03-1.41-12.18-10.04-10.77c-4.41,1.41-5.22,6.17-8.18,8.94
            c-3.04,2.74-6.53,4.08-10.64,3.65c-4.19-0.89-6.85-3.54-8.45-7.37c-1.42-5.7,1.22-13.63-8.26-15.02
            c-9.69-7.73-5.39-18.43-6.05-28.08c0.57-2.06,1.73-3.71,3.48-4.93c7.87-1.53,15.75-3.06,23.62-4.6
            c9.53-3.13,10.42,8.07,16.87,10.1c4.9,3.48,5.38,9.1,6.89,14.23c6.64,10.64,17.08,1.15,25.06,4.74
            c2.83,0.42,4.67,1.94,5.31,4.79C81.06-337.37,77.68-336.14,74.5-334.74z"
            />
            <path
              style="fill: #77874f"
              d="M139.85-384.02c-9.49,4.46-0.87,15.26-7.27,20.8c-6.21-2.53-16.21,0.4-16.14-11.2
            c-2.8-3.06-5.61-6.12-8.41-9.18c-1.02-7.27-2.55-14.79,8.05-16.32c6.77-1.54,9.57-12.12,18.72-8.23l1.95,0.43
            C139.95-400.11,146.33-392.91,139.85-384.02z"
            />
            <path
              style="fill: #3f4f20"
              d="M50.82-351.24c-4.02-3.02-8.05-6.04-12.07-9.07c-2.56-7.89-9.42-15.76-0.09-23.69
            c4.31-2.08,8.42-2.19,12.19,1.2c0.09,7.96,4.19,11.82,12.07,11.8C64.47-360.97,53.74-358.5,50.82-351.24z"
            />
            <path
              style="fill: #37471f"
              d="M116.44-374.42c4.41,5.13,13,4.23,16.14,11.2c-1.26,1.17-2.52,2.34-3.78,3.5
            c4.34,8.61,1.71,14.58-6.89,18.29c-7.15,2.16-9.65-3.2-12.6-7.84C103.62-359.95,110.76-366.98,116.44-374.42z"
            />
            <path
              style="fill: #9eb09c"
              d="M109.31-349.27c3.35,1.71,6.71,3.42,10.06,5.14c4.68-1.22,9.07-1.93,9.06,5.14
            c-0.99,6.38,2.78,12.98-0.89,19.23c-5.91,1.47-7.27-2.42-8.12-6.91c-5.7-5.32-25.88-2.77-10.57-19.5
            C109.6-347.12,109.75-348.15,109.31-349.27z"
            />
            <path
              style="fill: #7b9240"
              d="M108.86-346.18c-5.78,11.56,12.04,10.3,10.57,19.51c-3.51,2.96-7.02,5.93-10.53,8.9
            c-8.07-1.51-12.58-8.03-18.26-12.93c-3.01-9.26,7.35-12.73,8.69-20.11C102.51-349.26,105.68-347.72,108.86-346.18z"
            />
            <path
              style="fill: #61792b"
              d="M99.33-350.8c1.53,7.99-1.86,14.46-6.36,20.58c0.39,1.63-0.19,2.83-1.61,3.67
            c-5.61-2.73-11.23-5.46-16.85-8.19c1.27-2.99,2.55-5.97,3.83-8.96c4.79-6.18,12.65,1.79,17.56-3.82
            C97.05-348.61,98.19-349.71,99.33-350.8z"
            />
            <path
              style="fill: #39471e"
              d="M95.9-347.52c-4.82,5.98-11.41,3.84-17.55,3.82c-1-0.8-1.82-1.73-2.49-2.81
            C81.55-366.41,89.37-344.42,95.9-347.52z"
            />
            <path
              style="fill: #546631"
              d="M134.8-408.15c-5.66,4.06-7.79,16.17-18.72,8.23c-0.24-2.55-0.47-5.09-0.71-7.64
            c1.55-3.03,3.1-6.07,4.65-9.1c4.14-2.52,8.15-2.87,11.9,0.77C132.88-413.31,133.84-410.73,134.8-408.15z"
            />
            <path
              style="fill: #92a36f"
              d="M38.65-383.99c4.32,7.88,0.09,15.79,0.09,23.69c-7.97-1.97-6.38-11.26-11.68-15.27
            c0.07-1.38,0.14-2.76,0.22-4.15C31.3-380.54,29.87-395.87,38.65-383.99z"
            />
            <path
              style="fill: #738a58"
              d="M131.92-415.89c-4.14,2.52-8.08,1.84-11.9-0.76c1.41-5.26,2.81-10.52,4.22-15.78
            c5.42,0.58,8.45,4.65,11.85,8.17C134.7-421.48,133.31-418.69,131.92-415.89z"
            />
            <path
              style="fill: #475b2d"
              d="M3.44-370.97c-0.18,1.11-0.29,2.24-0.31,3.36c-2.07,9.86-6.38,15.18-16.73,8.29
            c-0.09-9.48-0.18-18.96-0.26-28.44c6.07,4.06,2.35,16.15,13.29,16.22C0.77-371.39,2.11-371.2,3.44-370.97z"
            />
            <path
              style="fill: #516627"
              d="M-414.97-176.67c-7.02-15.38-0.4-29.21,6.57-41.96c3.1-5.67,1.65-10.02,1.78-15.04
            c2.46-4.15,5.73-7.3,10.46-8.74c3.82-0.98,7.64-1,11.48-0.15c6.31,0.21,11.91,2.09,16.18,7.02
            c11.24,6.97,13.29,18.49,14.97,30.2c1.86,12.62,3.34,25.19-5.47,36.19c-10.75,5.94-15.94-1.95-20.38-9.11
            c-8.22-13.26-15.7-19.7-25.32-1.17C-406.34-176.23-410.6-174.12-414.97-176.67z"
            />
            <path
              style="fill: #5d772a"
              d="M-414.97-176.67c5.09-4.78,7.49-10.83,8.17-17.64c0.66-6.65,3.73-11.54,10.59-12.55
            c6.67-0.98,11.24,2.77,14.65,8.22c6.32,10.09,12.71,20.14,19.08,30.21c2.89-0.56,5.79-1.04,8.68,0.02
            c11.51,13.76-2.25,13.16-9.29,16.38c-18.51,6.29-19.38-11.27-28.04-21.27c-3.1,5.92-1.9,13.59-7.81,17.22
            C-414.25-155.17-415.75-165.03-414.97-176.67z"
            />
            <path
              style="fill: #496828"
              d="M-370.82-294.85c11,7.08-0.62,13.87-0.27,20.81c-0.51,5.59,0.94,11.72-4.34,16.02
            c-7.68,5.72-15.65,10.72-25.84,10.05c-9.13-5.1-3.04-16.95-10.16-22.95c9.16-2.99,10.81-8.24,4.76-15.82
            c3.21-10.07,13.37-7.64,20.17-11.25C-381.66-295-376.42-294.03-370.82-294.85z"
            />
            <path
              style="fill: #5c7f2c"
              d="M-411.43-270.92c11.33,2.72,6.58,14.89,12.28,20.92c5.95,2.35,8.67,5.93,4.54,12.08
            c-3.24,3.57-7.45,4.4-12.01,4.26C-410.09-245.84-412.77-258.12-411.43-270.92z"
            />
            <path
              style="fill: #6f972d"
              d="M-435.63-307.08c5.71,2.22,7.31,7.22,8.46,12.52c-4.09,1.56-8.19,3.12-12.28,4.68
            C-444.86-297.1-441.68-302.41-435.63-307.08z"
            />
            <path
              style="fill: #2a331b"
              d="M-232.84,46.33c-5.57,5.64-9.68,16.51-19.79,5.16c-6.89-7.73-11.54-0.84-16.59,2.89
            c-0.64-4.94-2.92-8.96-7.25-11.39c-19.36-10.87-27.71-27.26-25.04-49.3c1.03-4.35,2.06-8.69,3.61-15.24
            c6.26,7.67,9.94,16.82,20.43,15.04c12.86,10.01,28.41,14.66,42.83,21.62C-228.24,25.21-212.64,34.74-232.84,46.33z"
            />
            <path
              style="fill: #364620"
              d="M-241.06,18.37c-15.22-3.76-30.38-7.6-36.4-24.87c6.93-9.3,1.5-16.72-3.68-24.17
            c-1.99-8.84,3.63-10.8,10.47-11.68c8.47,3.48,12.82,0.35,16.9-7.86c8.43-16.99,38.55-22.38,52.72-10.31
            c4.36,3.72,4.42,8.31,1.25,12.57c-3.1,4.17-8.79,6.62-8.09,13.11c5.85,17.06,19.1,32.52,11.26,52.48
            c-0.98,1.64-2.12,3.15-3.46,4.52c1.3,6.98,3.51,13.95,3.63,20.96c0.12,6.81,3.96,16.8-3.96,19.75
            c-8.29,3.09-10.31-8.19-15.6-12.55c-11.71-10.37-8.16-27.93-18.44-39.25c-2.41-2.65-3.87-6.76-6.35-9.39
            C-237.55,7.05-234.41,12.72-241.06,18.37z"
            />
            <path
              style="fill: #29341c"
              d="M-362.03-140.52c-0.06-2.44-0.11-4.88-0.17-7.32c1.59-1.67,3.38-3.09,5.37-4.27
            c12.33,0.69,25.56-1.85,34.84,9.75c2.71,6.19,2.58,12.54,0.89,18.94c-3.27,9.15-11.15,18.6,1.75,26.72
            c5.12,6.22,14.22,4.12,19.81,9.38c3.08,2.29,5.51,7.17,10.08,4.86c9.69-4.9,17.48-3.17,21.42,6.75
            c4.14,10.41,3.73,21.99-4.68,29.85c-5.02,4.69-8.9,9.63-12.41,15.2c-12.12-26.28-12.94-26.57-42.36-17.82
            c-3.76,1.12-7.82,1.21-11.75,1.78c-6.58-5.56-14.27-5.32-22.89-3.39c16.85-16.77,15.09-37.55,16.24-57.55
            c-8.58-4.88-12.87-13.06-16.61-21.67C-362.52-133.06-362.15-136.78-362.03-140.52z"
            />
            <path
              style="fill: #465a23"
              d="M-285.15-30.67c-0.7-7.05,0.98-12.88,6.39-18.17c7.77-7.61,8.26-18.59,2.47-25.91
            c-6.31-7.98-12.15,0.9-16.94,5.64c-2.33,2.31-4.43,2.58-6.85,0.61c-5.32-4.33-4.07-9.37-1.46-14.52
            c-2.78-8.28,3.05-10.75,8.95-12.03c12.17-2.63,13.6-8.7,9.77-19.96c-2.87-8.45-5.16-17.89-3.01-27.35
            c3.83-11.15,7.27-22.73,22.9-20.84c1.83,0.81,3.3,2.09,4.32,3.83c1.35,5.98,0.09,12.69,4.71,17.85
            c2.62,6.54-2.5,13.68,1.61,20.13c4.01,8.1,10.83,15.14,9.46,25.25c-0.05,1.94-0.26,3.84-0.59,5.74
            c-2.72,8.43-7.68,16.19-7.28,25.51c-3.06,10.55-1.8,24.03-17.54,26c-5.73,0.48-10.09,3.12-12.91,8.2c0,0-2,0.1-2,0.1
            L-285.15-30.67z"
            />
            <path
              style="fill: #a1aa60"
              d="M-362.03-140.52c3.43,1.92,3.9,4.98,3.25,8.47c3.07,9.36,9.89,18.07,5.07,28.82
            c-2.68,3.6-5.36,7.2-8.05,10.8C-370.43-108.41-383.63-124.37-362.03-140.52z"
            />
            <path
              style="fill: #90a146"
              d="M-353.72-103.23c-0.27-9.86-10.98-17.87-5.07-28.82c6.51,6.96,11.23,14.86,12.87,24.4
            C-348.52-106.18-351.12-104.7-353.72-103.23z"
            />
            <path
              style="fill: #758e30"
              d="M-357.78-148.03c-1.48,0.06-2.95,0.13-4.43,0.19c-0.3-1.4-0.59-2.79-0.9-4.19
            c-1.2-5.32,9.03-6,5.03-12.46c-3.54-5.45-3.23-10.25,2.37-14.16c12.3-0.58,16.23,9.51,22.02,17.25
            c1.41,10.11-1.49,15.73-13.03,12.86C-350.34-149.44-354.26-151.85-357.78-148.03z"
            />
            <path
              style="fill: #475e2b"
              d="M-241.06,18.37c-0.93-9.95-7.7-15.35-15.56-19.8c-4.28-2.43-8.85-4.73-5.92-10.73
            c2.67-5.46,7.7-4.97,12.32-3.3c21.32,7.71,24.64,28.2,31.49,45.83c2.36,6.08,1.89,13.26,2.72,19.94c0,0-0.7,0.53-0.7,0.53
            c-6.71,3.33,0.57,16.4-11.24,16.18c-1.63-6.89-3.26-13.78-4.89-20.67C-223.64,33.5-235.22,26.78-241.06,18.37z"
            />
            <path
              style="fill: #587252"
              d="M-227.95,67.01c7.01-3.13-2.53-17.78,11.25-16.18c4.33,17.45,2.34,36.43,12.62,52.45
            c-5.59,0.2-11.17,0.4-16.76,0.61c-5.23,3.41-10.15,2.97-14.78-1.18C-216.74,94.32-218.73,81.44-227.95,67.01z"
            />
            <path
              style="fill: #2d361c"
              d="M-235.62,102.7c4.93,0.39,9.85,0.78,14.78,1.18c3.11,7.88,2.65,14.1-7.33,15.94
            c0.61-9.1-6.64-12.27-12.45-16.52C-238.95,103.1-237.29,102.9-235.62,102.7z"
            />
            <path
              style="fill: #6a8a6d"
              d="M-204.23,103.15c8.98-0.14,7.68,12.13,15.75,13.09c1.39,0.74,1.82,1.42,1.32,2.06
            c-0.48,0.61-0.97,0.92-1.47,0.92c-1.21,1.22-2.42,2.44-3.63,3.65C-205.22,121.74-204.7,112.43-204.23,103.15z"
            />
            <path
              style="fill: #4e6626"
              d="M14.99,41.88c-2.9,3.92-5.31,8.89-0.99,12.26c11.54,9.02-2.45,8.14-4.17,11.92
            c0.02,6.38-3.96,8.97-9.5,10.09c-12.31,0.95-12.72-10.51-17.79-17.12c-2.42-8.16,4.6-16.56-0.61-24.66
            c1.13-0.15,2.25-0.05,3.35,0.3c7.18-3.68,14.46-3.54,21.81-0.56C11.98,34.39,17.27,34.27,14.99,41.88z"
            />
            <path
              style="fill: #60912d"
              d="M-49.88,66.59c-1.53,1.48-3.06,2.96-4.59,4.44c-12.78-4.51-12.23-11.04-3.5-19.71
            c4.07-4.04,6.26-14.83,11.96-1.37c4.01,2.08,8.3,3.9,8.66,9.36C-41.54,61.73-45.71,64.16-49.88,66.59z"
            />
            <path
              style="fill: #779d29"
              d="M-17.47,59.03c8.08,0.5,9.14,10.51,16.4,12.12c4.8,4.17,9.2,8.45,4.46,15.39
            C1.42,87-0.55,87.46-2.53,87.93c-3.71-0.3-7.42-0.61-11.13-0.92c-2.53-2.79-2.36-5.74-0.52-8.8
            C-15.27,71.81-16.37,65.42-17.47,59.03z"
            />
            <path
              style="fill: #74934a"
              d="M-14.17,78.21c0.17,2.93,0.34,5.86,0.52,8.8c-5.54,3.15-1.83,10.22-7.19,15.31
            C-21.58,92.49-33.31,81.67-14.17,78.21z"
            />
            <path
              style="fill: #7fb22e"
              d="M-37.37,59.3c-2.89-3.12-5.77-6.24-8.66-9.36c2.63-1.35,5.26-2.7,7.89-4.06
            c3.85,0.88,9.42,1.2,9.1,5.82C-29.23,54.42-34.42,56.78-37.37,59.3z"
            />
            <path
              style="fill: #bace33"
              d="M3.39,86.54c-1.49-5.13-2.97-10.26-4.46-15.39c3.63-1.7,7.27-3.39,10.9-5.09
            c6.24,0.5,12.52,1.21,17.47-3.97c4.67-2,8.01-1.48,8.47,4.49c-0.34,1.2-0.56,2.41-0.67,3.65C22.61,71.93,17.05,87.11,3.39,86.54
            z"
            />
            <path
              style="fill: #879e9f"
              d="M-167.93,62.81c-1.26-4.09-2.51-8.17-3.77-12.26c-6.07-10.62-2.72-20.07,2.95-29.73
            c3.99-6.79,5.75-14.67,8.07-22.24c3.34-5.83,4.91-13.18,12.94-14.94c3.13-0.1,5.66,1.16,7.71,3.46
            c3.64,6.1,1.85,14.73,8.89,19.31c2.22,2.99,3.99,6.27,6.29,9.21c5.43,7.5,7.47,15.72,5.39,24.84
            c1.09,25.01-17.72,26.41-35.04,29.6c-1.86,0.46-3.74,0.54-5.62,0.21C-163.49,68.59-166.88,66.92-167.93,62.81z"
            />
            <path
              style="fill: #63807b"
              d="M-159.37,66.61c2.37-1.09,4.64-2.7,7.12-3.18c14.57-2.81,27.75-6.76,25.14-26.09
            c19.36,5.04,21.5,10.12,12.5,29.58c0.95,1.45,1.9,2.89,2.85,4.34c-3.57,15.35-16.37,18.16-28.8,19.56
            c-8.73,0.98-15.54,2.83-19.29,11.23c-5.8-12.45-11.75-24.85-8.08-39.24c2.74,1.39,5.47,2.78,8.21,4.16
            c0.98,0.76,1.78,1.75,2.92,2.3C-157.73,68.45-158.59,67.56-159.37,66.61z"
            />
            <path
              style="fill: #35442b"
              d="M-155.18,1.77c-3.99,11.32-4.29,24.17-11.76,33.92c-3.72,4.86-3.42,9.86-4.75,14.86
            c-6.92-7.41-17.33-12.48-16.61-24.98c-5.59-7.88,1.59-12.99,4.09-19.15c2.08-3.14,4.84-5.4,8.47-6.5
            c4.4-1.12,7.76-4.91,12.59-4.96C-158.89-4.66-156.8-1.72-155.18,1.77z"
            />
            <path
              style="fill: #5e7457"
              d="M-179.55,9.8c5.09,2.24,8.47,5.17,0.72,7.92c-4.46,1.58-8.37,2.71-9.47,7.86
            c-3.93-1.14-7.85-2.28-11.77-3.42c0.15-1.32,0.31-2.63,0.49-3.95c0.86-6.19-2.07-14.01,6.53-17
            C-185.28-1.51-182.7,4.77-179.55,9.8z"
            />
            <path
              style="fill: #4c6234"
              d="M-188.64,119.22c0.14-0.99,0.2-1.99,0.16-2.99c4.61-4.01,9.52-7.17,17.35-5.47
            C-174.1,120.7-181.74,119.26-188.64,119.22z"
            />
            <path
              style="fill: #2f411e"
              d="M-54.23-6.49c-4.13,4.31-8.72,8.28-12.28,13.01c-7.87,10.46-15.03,16.26-22.89-0.03
            c-2.02-4.19-5.82-7.87-11.28-6.26c-5.07,1.49-6.84,5.16-6.6,10.76c0.12,2.7-3.04,5.54-4.71,8.32
            c-7.54,0.37-10.11-5.26-12.9-10.67c-3.52-10.98,5.14-16.29,10.72-23.04c9.15-5.38,10.92-23.18,27.36-16.35
            c7.59,4.32-2.28,30.3,20.11,16.25C-60.5-15-56.78-11.66-54.23-6.49z"
            />
            <path
              style="fill: #425d36"
              d="M23.11-9.98C18.26-8.02,12.92-6.8,8.67-3.94c-9.71,6.53-9.41-0.79-10.38-6.81
            c-5.52-5.84-15.83,0.22-20.31-8.2c-10.84-17.2-1.38-27.37,12.44-36.03c7.44-3.33,12.19,1.81,17.42,5.48
            c3.81,4.4,2.04,10.32,4.15,15.19c2.53,7.56,10.88,8.58,15.51,13.81C29.42-15.58,31.15-10.72,23.11-9.98z"
            />
            <path
              style="fill: #3f5825"
              d="M-86.81-30.74c-12.09,3.56-13.4,16.43-20.75,24.08c-9.09-9.07-21.32,5.69-30.35-4
            c-3.75-10.43-4.92-20.91-0.66-31.5c2.26-2.75,5.49-4.97,8.71-4.12c13.74,3.62,11.87-7.08,13.74-14.44
            c4.05-5.38,9.85-6,15.93-5.87c6.54,2.41,11.57,6.38,13.63,13.39C-86.65-45.72-86.73-38.23-86.81-30.74z"
            />
            <path
              style="fill: #496725"
              d="M-95.76-90.42c-4.05,0.4-6.09,2.78-6.55,6.69c-3.19,3.67-7.05,2.65-10.91,1.58
            c-7.67-4.62-17.56-2.2-24.93-7.81c-9.82-6.05-28.83-4.59-20.74-25.36c5.09-4.69,11.09-5.2,17.49-3.81
            c10.36,5,20.49,0.58,30.76-0.63c7.04-0.83,14.26-3.07,21.31,0.36c2.78,1.43,4.89,3.54,6.4,6.27
            C-79.35-101.13-84.24-93.9-95.76-90.42z"
            />
            <path
              style="fill: #6c9e2e"
              d="M-119.23,6.23c2.41,4.36,4.82,8.72,7.24,13.09c-0.1,0.92-0.08,1.83,0.08,2.74
            c1.93,7,7.42,10.7,13.38,13.54c5.08,2.41,7.12,5.48,4.65,10.82c-1.5,12.31-6.49,21.08-20.72,20.51
            c-2.38-10.61-0.04-23.22-12.5-29.56c3.52-7.4-1.97-13.05-3.72-19.42C-133.61,7.43-125.8,7.44-119.23,6.23z"
            />
            <path
              style="fill: #2f411e"
              d="M-93.89,46.41c-1.37-5.99-5.14-7.06-11.08-8.28c-7.18-1.46-17.95-5.08-6.93-16.09
            c11.38,1.82,21.52,15.83,35.41,2.95c1.16-1.07,4.18,15.83,3.84,25.39C-81.04,51.39-84.59,32.37-93.89,46.41z"
            />
            <path
              style="fill: #395228"
              d="M5.71-46.73c-6.74,0.3-10.92-4.16-15.29-8.26c1.31-5.52,2.63-11.04,3.94-16.56
            c-0.74-1.87-0.73-3.72,0.03-5.59C2.7-84.36,5.2-99.73,21.16-96.3c4.22,1.29,7.39,3.87,9.54,7.72c1.77,3.97,1.86,8.02,0.51,12.13
            c-3.3,8.1-12.48,11.79-15.25,20.34C13.5-51.93,11.31-47.46,5.71-46.73z"
            />
            <path
              style="fill: #27361c"
              d="M-38.2-58.91c-4.41-7.8-5.98-17.87-16.57-20.72c-0.92-1.74-1.05-3.52-0.24-5.34
            c8.52-4.28,13.72-12.14,20.12-18.75c2.77-1.4,5.74-1.6,8.76-1.54c4.23,0.17,7.96,1.49,10.88,4.71
            c5.03,8.1,7.17,16.33,0.77,24.81c-4.04,3.6-9.02,6.24-11.77,11.23C-29.56-61.19-34.85-62.13-38.2-58.91z"
            />
            <path
              style="fill: #96b238"
              d="M-22.02-18.95c8.41-1.34,20.34-11.42,20.31,8.2C-7.63-2.76-16.26,0.09-25.49,1.81
            C-28.07-5.74-34.62-13.95-22.02-18.95z"
            />
            <path
              style="fill: #677d53"
              d="M-54.23-6.49c-5.61-0.4-9.56-3.38-12.47-8c1.51-2.95,2.61-6.24,4.64-8.78
            c3.62-4.53,8.42-5.81,14.14-3.85c5.2,1.78,7.62,6.5,7.91,10.62C-39.28-6.51-50.74-11.79-54.23-6.49z"
            />
            <path
              style="fill: #5b823a"
              d="M-95.76-90.42c-2.6-9.5,11.99-11.51,9.18-21.1c2.1-2.58,4.86-3.23,8.01-2.75
            c4.86,1.86,8.13,5.28,9.96,10.13c0.5,5.94-5.18,9.52-5.66,15.13c-2.1,6.6-5.99,9.67-12.94,6.43
            C-90.06-85.19-92.91-87.81-95.76-90.42z"
            />
            <path
              style="fill: #6c957f"
              d="M-78.25-95.32c-0.84-6.13,7.32-3.6,7.97-8.3c7.8-6.34,7.6-6.1,14.99,0.13
            c7.53,6.35,12.4,12.41,1.64,20.03c-0.44,1.26-0.82,2.54-1.13,3.83c-1.39,0.52-2.79,1.05-4.18,1.58
            C-73.46-74.77-71.61-89.79-78.25-95.32z"
            />
            <path
              style="fill: #628f75"
              d="M-110.63-87.36c2.77,1.21,5.54,2.42,8.32,3.64c4.05,10.78,17.79,16.55,15.74,30.5
            c-5.93-0.91-12.05-1.57-12.83-9.47C-110.91-67.38-121.89-72.3-110.63-87.36z"
            />
            <path
              style="fill: #496725"
              d="M-78.25-95.32c10.81,0.85,13.24,11.1,19.3,17.27c-1.25,2-2.49,3.99-3.73,5.99
            c-6.48,5.78-10.75-4.72-16.77-2.29c-2.59-2.75-5.18-5.49-7.77-8.23C-80.88-84.47-78.66-89.25-78.25-95.32z"
            />
            <path
              style="fill: #b4bdc6"
              d="M7.08,34.11c-7.22,2.22-14.34,8.27-21.8,0.55c-0.57-2.8-1.13-5.61-1.69-8.41
            C-2.43,10.45,1.36,25.17,7.08,34.11z"
            />
            <path
              style="fill: #98adc8"
              d="M-30.28-67.5c-0.25-8.58,1.71-14.95,12.49-12.44c3.68,0.23,7.55-0.5,10.69,2.27
            c0.53,0.77,1.06,1.55,1.58,2.33c0.01,1.27-0.03,2.53-0.12,3.79c-2.86,2.61-5.72,5.22-8.59,7.84
            C-20.6-60.63-27.26-56.31-30.28-67.5z"
            />
            <path
              style="fill: #4a6c5e"
              d="M-79.44-74.35c6.68-7.14,11.34,0.47,16.78,2.3c3.03,4.21,7.58,9.14,1.5,13.37
            c-7.52,5.23-7.62-3.42-10.44-6.95C-74.03-68.69-76.81-71.46-79.44-74.35z"
            />
            <path
              style="fill: #395228"
              d="M-30.28-67.5c4.14,6.42,9.94,5.79,16.06,3.78c-6.71,8.03-14.79,9.19-23.98,4.81
            C-37.69-63.74-35.08-66.63-30.28-67.5z"
            />
            <path
              style="fill: #455c27"
              d="M-208.35-348.49c2.65,3.11,5.3,6.23,7.96,9.34c7.36,9.8,9.84,22.45,20.98,30.43
            c6.65,4.77,8.47,14.66,5.88,23.77c-4.52,4.51-7.81,8.97-9.1,16.09c-2.07,11.5-9.12,18.92-22.57,13.63
            c-5.12-7.58-15.61-10.93-17.07-21.45c-2.98-10.22-4.28-20.03-1.49-31.05c3.42-13.48,0.71-26.4-14.39-32.75
            c-4.18-2.06-8.57-3.17-13.27-2.92c-13.16-0.73-25.86,13.98-39.36,1.17c-1.31-1.59-2.22-3.39-2.72-5.39
            c0-2.21,0.01-4.42,0.01-6.63c2.85-8.83,8.34-10.42,16.07-5.89c4.27-1.58,8.23-3.4,13.25-2.29c18.55,4.11,22.89,0.49,19.86-18.05
            c-1.1-6.72-0.49-12.98-0.2-19.47c5.42,5.71,9.59,13.46,19.45,11.88c1.05,4.39,3.51,7.3,8.21,7.89c4.83,5.96-5.04,7.86-3.56,12.9
            c-1.3,2.72-2.61,5.43-3.91,8.15C-220.09-353.94-211.55-355.22-208.35-348.49z"
            />
            <path
              style="fill: #98b542"
              d="M-277.42-360.14c-5.36,1.96-10.72,3.93-16.07,5.89c-2.87-3.95-4.19-8.25-2.92-13.13
            c1.81-6.26,3.62-12.51,6.59-22.78C-285.88-377.52-278.08-370.65-277.42-360.14z"
            />
            <path
              style="fill: #7caa29"
              d="M-293.51-347.62c1.23,0.54,2.48,1,3.76,1.39c7.31,10.26,1.7,15.31-7.6,18.87
            c-7.79-10.9-11.67-5.02-14.93,3.49C-319.41-342.39-299-339.1-293.51-347.62z"
            />
            <path
              style="fill: #223019"
              d="M-220.41-367.28c-5.32-1.93-9.89,10.76-14.24,2.01c-2.8-5.63,5.42-7.37,9.65-9.91
            c2.73-1.64,5.44-3.33,8.15-5c3.52-9.15,11.13-1.69,16.32-4.02c4.81,5.62,6.94,13.1,2.89,18.53c-5.72,7.67-9.62-3.94-15.13-3.54
            C-215.34-369.03-217.86-367.95-220.41-367.28z"
            />
            <path
              style="fill: #6f9a31"
              d="M-297.34-327.36c2.54-6.29,5.07-12.58,7.6-18.87c15.05,9.81,27-8.47,41.4-4.59
            c2.16,2.31,3.98,4.82,4.71,7.97c0.45,13.91-7.05,14.23-16.98,10.06c-0.71-0.02-1.17-0.04-1.17-0.04
            c-1.68-0.03-1.82-0.04-0.17-0.03c8.06,5.06,15.49,0.61,23.1-0.94c2,0.59,3.69,1.61,5.12,3.14c5.83,10.4,2.15,18.6-5.88,25.8
            c-3.11,2.74-6.16,5.61-10.38,6.63c-3.91,0.84-7.71-0.85-11.6-0.55c-22.49,6.63-25.12,4.6-31.34-24.08
            C-294.41-324.36-295.88-325.86-297.34-327.36z"
            />
            <path
              style="fill: #54762a"
              d="M-292.94-322.86c9.97,16.11,9.97,16.11,32.91,17.16c3.07,9.76,0.14,17.28-8.37,22.73
            c-6.49,3.69-6.53,12.17-12.12,16.59c-4.6,2.44-9.19,4.37-13.43-0.61C-293.61-285.62-293.28-304.24-292.94-322.86z"
            />
            <path
              style="fill: #688630"
              d="M-316.39-274.38c9.46-1.66,10.75,6.57,14.41,11.93c5.06,1.49,9.56,3.63,10.8,9.49
            c-0.01,9.02-7.31,12.03-13.29,16.13c-7.27,1-14.17,4.5-21.78,3.27c-9.31-3.81-7.98-11.31-6.55-18.84
            c0.16-1.97,0.45-3.94,0.84-5.88c0.51-2.78,1.27-5.48,3.1-7.73C-325.02-269.21-321.71-273.3-316.39-274.38z"
            />
            <path
              style="fill: #86b329"
              d="M-293.1-253.35c-4.84-1.19-7.54-4.48-8.89-9.1c2.68-1.51,5.36-3.03,8.04-4.54
            c4.07-1.18,8.14-2.35,12.21-3.53c7.61-4.03,13.38-3.52,15.4,6.27c-2.36,11.51-10.41,14.76-20.92,14.42
            C-289.58-250.38-291.53-251.55-293.1-253.35z"
            />
            <path
              style="fill: #909560"
              d="M-334.71-298.41c1.04-3.27,0.95-7.14,4.81-8.89c8.64,4.4,15.02,10.09,12.05,21.13
            c-5.02,4.49-10.33,5.69-16.12,1.43C-336.91-289.15-336.11-293.76-334.71-298.41z"
            />
            <path
              style="fill: #aeb47e"
              d="M-333.28-285.96c5.15-0.07,10.29-0.14,15.44-0.21c0.48,3.93,0.97,7.85,1.45,11.78
            c1.18,4.85,0.55,9.36-3.41,12.62c-0.3,0.24-3.6-3.16-5.5-4.87C-333.92-270.61-338.11-276.42-333.28-285.96z"
            />
            <path
              style="fill: #475e28"
              d="M-326.4-253.11c0.08,5.33-4.14,10.93,1.02,15.94c5.98,2.77,10.92,6.47,12.19,13.52
            c-1.92,11.55-2.21,23.71-11.5,32.7c-20.09,1.44-22.68-0.76-24.11-20.41c1.29-9.93,2.39-19.87-2.99-29.17
            c-0.07-1.94,0.49-3.67,1.73-5.16c1.1-0.14,2.19-0.16,3.29-0.06C-340.53-249.71-337.41-262.37-326.4-253.11z"
            />
            <path
              style="fill: #375024"
              d="M-350.07-245.7c0.1,1.2,0.18,2.41,0.24,3.62c-4.7,13.22-13.51,14.58-24.85,8.62
            c-3.29-1.62-6.58-3.69-9.87-0.01c-6.89-4.14-5.8-9.85-1.47-14.41c3.75-3.95,5.95-8.12,7.06-13.19
            c2.63-4.32,5.25-8.64,7.87-12.96C-352.18-273.44-360.26-252.78-350.07-245.7z"
            />
            <path
              style="fill: #475e28"
              d="M-333.28-285.96c3.34,6.16,9.23,11.27,7.97,19.33c-0.38,2.6-0.76,5.2-1.14,7.81
            c-10.73,3.39-16.72-1.47-19.8-11.4c-9.81-15,10.17-17.78,11.54-28.2C-334.23-294.26-333.76-290.11-333.28-285.96z"
            />
            <path
              style="fill: #587b2b"
              d="M-346.24-270.22c6.6,3.8,13.2,7.61,19.8,11.4c0.01,1.89,0.03,3.79,0.04,5.69
            c-6.79,2.46-13.58,4.91-20.38,7.36C-344.03-253.86-351.53-262.18-346.24-270.22z"
            />
            <path
              style="fill: #354c22"
              d="M27.38-87.17c-2.71-1.42-5.42-2.84-8.14-4.26c-2.51-2.2-3.97-4.91-4.09-8.3
            c1.16-12.51,11.87-13.67,20.5-17.31c5.02-2.22,9.97-3.52,14.35,1.3c6.88,15.7,27.09,7.15,36.2,20.04
            c2.86,4.05,7.39,7.26,9.44,12.27c-7.69,11.2-22.33,19.41-15.78,36.74c-3.89,6.99-16.83,4.55-16.52,15.9
            c-1.12,0.63-2.33,0.95-3.62,0.98c-10.43,7.89-20.96,12.32-32,1c-1.77-2.5-2.64-5.29-2.59-8.35c0.62-13.02,2.59-25.8,7.59-37.96
            C33.11-80.1,30.82-83.89,27.38-87.17z"
            />
            <path
              style="fill: #7fa131"
              d="M43.17-2.09c-2.5-1.5-5-3.01-7.49-4.51c-4.59-3.18-4.7-7.51-3.16-12.27
            c12.28-11.6,20.23-4.83,27.14,6.4C59.48-6.21,59.3,0.05,59.11,6.3C50.84,9.15,47.66,2.28,43.17-2.09z"
            />
            <path
              style="fill: #58792e"
              d="M59.66-12.47c-8.17-1.07-13.47-14.37-23.95-5.63c-7.79-1.78-14.82-3.85-4.69-12.42
            c9.43,6.04,19.25-4.6,28.7,0.7C59.7-24.03,59.68-18.25,59.66-12.47z"
            />
            <path
              style="fill: #3b5522"
              d="M43.17-2.09c7.19-0.77,10.67,5.51,15.94,8.39c-1.1,11.39-10.83,4.92-16.05,7.78
            C43.1,8.69,43.13,3.3,43.17-2.09z"
            />
            <path
              style="fill: #374920"
              d="M31.02-30.52c-3.97,6.23,1.85,8.76,4.69,12.42c-0.01,3.84-0.03,7.67-0.04,11.51
            c-2.74,7.34,2.6,20.27-12.93,18.78c0.12-7.4,0.24-14.79,0.37-22.18c2.52-2.99,2.67-5.87-0.4-8.59
            c-7.14-3.84-10.7-9.49-8.97-17.82c0.64-2.06,1.74-3.83,3.26-5.36c5.01-3.47,9.68-4.05,13.56,1.66
            C30.71-36.9,30.86-33.71,31.02-30.52z"
            />
            <path
              style="fill: #405a25"
              d="M35.77,66.58c-1.75-3.53-5.17-3.9-8.47-4.49c2.9-3.04,2.81-10.03,9.07-8.48
            c4.61,1.15,7.76,5.13,6.88,10.42C42.46,68.79,38.94,67.68,35.77,66.58z"
            />
            <path
              style="fill: #446b2a"
              d="M79.87-46.7c-6.33-5.92-20-9.71-12.01-21.1c5.89-8.4,11.36-23.57,27.79-15.63
            c0.06,1.43,0.13,2.86,0.2,4.29c2.87,1.99,6.37,3.67,4,8.22c-11.32,4.71-11.54,14.1-10.16,24.16
            C86.42-46.75,83.15-46.73,79.87-46.7z"
            />
            <path
              style="fill: #3e6127"
              d="M99.85-70.93c-1.33-2.74-2.67-5.48-4-8.22c2.54-1.61,5.07-3.22,7.61-4.84
            c-0.49-8.38-2.22-17.01,4.81-23.87c3.99-1.68,8.06-4.37,11.57,0.82c-1.28,2.44-2.56,4.87-3.84,7.3
            c-1.25,8.11-2.5,16.22-3.75,24.33c-1.52,2.91-3.03,5.81-4.55,8.72C105.07-68.1,102.46-69.52,99.85-70.93z"
            />
            <path
              style="fill: #afb87e"
              d="M139.85-384.02c0.59-8.11-5.73-15.32-3.1-23.7c12.57,3.72,9.19,15.36,11.89,23.98
            C145.71-383.84,142.78-383.93,139.85-384.02z"
            />
            <path
              style="fill: #6b7b5c"
              d="M128.43-338.99c-1.31-4.73-5.07-5.15-9.06-5.14c-0.28-7.27,11.43-7.27,9.43-15.59
            c2.58,1.3,5.15,2.61,7.73,3.91C133.55-350.34,135.95-342.27,128.43-338.99z"
            />
            <path
              style="fill: #8fa59f"
              d="M148.19-284.59c-6.33-7.31-9.98,11.7-15.4,2c-3.95-7.08-2.61-14.34,7.67-15.91
            c2.61,2.29,5.22,4.58,7.83,6.87C148.26-289.29,148.22-286.94,148.19-284.59z"
            />
            <path
              style="fill: #314021"
              d="M160.41-268.74c2.44,3.77,1.95,7.57,0.14,11.4c-12.4,3.52-17.03-0.17-11.92-12.88
            c0,0-0.21,0.3-0.21,0.3C152.41-269.53,156.41-269.14,160.41-268.74z"
            />
            <path
              style="fill: #85a28a"
              d="M148.62-270.23c-0.16,8.13,2.05,14.06,11.92,12.88c-0.08,1.21-0.18,2.42-0.3,3.63
            c-6.17,0.07-8,6.4-12.61,8.73C135.85-253.87,140.21-262.13,148.62-270.23z"
            />
            <path
              style="fill: #25311b"
              d="M55.51-318.83c2.63,4.11,5.25,8.22,7.88,12.33c9.34,7.2,22.51,10.58,24.71,24.88
            c4.98,8.22,10.91,15.82,15.81,24.09c-3.87,16.98-19.98,9.25-32.04,17.69c4.71-17.98,7.53-33.03-3.67-46.05
            c-14.91,5.27,1.87,23.23-12.52,27.95c-2.52-1.39-6.04,2.14-8.07,8.09c-11.57,4.51-22.97,9.56-34.76,13.39
            c-10.79,3.5-20.82,2.45-26.45-9.54c3.4-8.34,13.28-7.45,18.55-13.1c1.15-1.25,2.48-2.26,3.93-3.12
            c3.32-1.88,7.36-1.09,10.56-2.77c-0.2,0.05,0.29-1.01,0.29-1.01s-0.71-0.92-0.92-0.84c-2.73-0.69-5.27-1.44-7.23-3.47
            c-2.51-2.85-2.64-6.52-3.67-9.87c-8.09-20.84-7.06-23.4,15.35-38.4c2.27-1.34,4.55-2.67,6.82-4.01
            c6.56-0.46,4.11-9.5,10.06-10.58C50.14-333.62,54.83-328.37,55.51-318.83z"
            />
            <path
              style="fill: #2a3a1d"
              d="M120.17-188.82c-3.7,7.69-14.79,10.49-6.46,25.15c5.97,10.52-14.72,15.45-23.21,17.39
            c-9.14,2.08-6.97-0.34-3.94,7.55c4.02,10.47-9.11,12.78-14.99,18.35c-8.24,7.81-11.18-2.16-16.06-5.47
            c-0.96-1.59-1.19-3.27-0.74-5.06c2.06-3.99,2.72-8.39,3.53-12.75c3.59-7.64,2.83-16.62,7.3-23.97c0.73-1.7,1.74-3.23,2.93-4.64
            c4.56-5.07,15.98-4.38,13.13-15.71c-0.33-5.79,0.37-11.61-0.58-17.38c0.4-1.71,1.33-3.08,2.71-4.15
            c2.51-5.52,4.15-11.71,7.75-16.38c4.97-6.44,11.4-11.18,16.47,0.55c-0.16,8.35-0.83,17.51,12.16,11.11
            c4.87-2.4,8.74-0.19,12.93,1.29c-1.52,2.64-3.04,5.28-4.57,7.93C128.68-198.12,120.54-195.48,120.17-188.82z"
            />
            <path
              style="fill: #587333"
              d="M-13.93-209.4c5.75-5.56,1.8-14.37,7.21-21.21c9.63,5.35,4.25,21.9,17.49,23.71
            c3.9,4.23,7.91,8.33,13.18,10.96c3.95,4.12,4.77,9.06,3.87,14.48c-3.33,6.32-7.74,12.15-8.81,19.52
            c-2.4,3.74-5.85,5.74-10.21,6.25c-23.23-3.72-22.89-22.81-25.86-39.77C-18.81-200.74-16.59-205.12-13.93-209.4z"
            />
            <path
              style="fill: #5b802e"
              d="M71.14-168.57c-0.07,1.58-0.14,3.16-0.22,4.75c-6.2,3.42-10.61,10.98-19.4,8.46
            c-5.31-1.58-9.59-4.48-12.22-9.5c-2.37-7.61-2.56-15.59-3.62-23.44c1.97-10.31,12.83-15.34,15.18-25.47
            c2.94-0.95,5.88-1.9,8.83-2.86c10.28-1.47,13.77,6.16,17.68,13.21c2.48,6.26,6.43,12.17,4.76,19.46
            C80.5-177.37,71.02-176.42,71.14-168.57z"
            />
            <path
              style="fill: #68806b"
              d="M-41.67-240.62c-1.62,3.57-13.93,4.3-2.98,11.22c0.17,3.27-1.6,5.22-4.4,6.48
            c-6.33,1.43-12.4-3.72-18.81-0.78c-1.81,0.22-3.62,0.42-5.43,0.64c-6.32,0.62-12.58,4.55-19.02,0.4
            c-3.3-2.31-5.06-5.52-5.48-9.5c0.13-8.42,9.22-13.15,8.97-21.77c0.81-6.2-0.83-13.21,5.77-17.49
            c17.7,1.51,24.21,20.16,39.28,25.75C-42.62-244.18-41.92-242.49-41.67-240.62z"
            />
            <path
              style="fill: #80a830"
              d="M50.86-213.77c14.82,16.07-3.3,18.24-10.53,25.01c-4.12,0.9-7.84-0.16-11.31-2.38
            c-2.68-1.79-5.6-3.18-8.19-5.11c-2.15-2.54-4.48-5.05-0.83-8.17C29.2-211.12,38.26-218.3,50.86-213.77z"
            />
            <path
              style="fill: #324921"
              d="M-49.83-225.7c1.72-1.23,3.44-2.47,5.17-3.7c5.77,1.77,6.61,6.48,6.86,11.53
            c-0.38,4.48-1.84,8.61-4.08,12.5c-3.15,5.92-6.13,12.01-13.6,13.62c-3.15,0.43-6.23,0.21-9.24-0.9
            c-4.26-2.1-6.66-5.55-7.5-10.16c-1.91-7.62-4.09-15.31,3.72-21.21C-62.59-227.97-56.03-224.86-49.83-225.7z"
            />
            <path
              style="fill: #435329"
              d="M10.48-254.28c-6.16,8.23-15.07,8.4-24.09,8.27c-1.42-3.77-2.85-7.54-4.27-11.31
            c-0.5-12.5,9.28-18.87,16.07-27.05c2.67-1.28,5.41-1.45,8.22-0.54C19.02-276.2,4.83-263.92,10.48-254.28z"
            />
            <path
              style="fill: #58753b"
              d="M-2.33-281.96c7.03,15.93-10.55,16.31-15.55,24.64c-2.59-1.49-5.18-2.98-7.76-4.48
            c-3.33-4.28-5.88-8.79-4.18-14.54c4.59-9.5,10.83-16.23,22.71-13.28C-4.07-287.99-2.34-285.51-2.33-281.96z"
            />
            <path
              style="fill: #455f2a"
              d="M-46.05-210.82c2.75-2.34,5.5-4.69,8.25-7.04c3.23,16.18,14.96,8.33,23.87,8.45
            c2.12,4.27,1.95,8.67,0.71,13.13c-1.87,10.6-11.01,8.62-17.99,10.47c-2.07-0.25-3.94-0.99-5.66-2.17
            C-42.14-194.69-49.13-200.73-46.05-210.82z"
            />
            <path
              style="fill: #4d6444"
              d="M71.62-202.94c-3.98-4.56-7.96-9.13-11.94-13.68c0.68-13.44,6.12-15.84,15.53-6.14
            c3.59,3.7,5.75,8.78,8.57,13.23c0.08,1.16,0.14,2.32,0.18,3.48C80.86-200.98,78.25-193.96,71.62-202.94z"
            />
            <path
              style="fill: #5e764c"
              d="M-25.8-273.44c0.48,3.88,2.74,7.73,0.16,11.64c-4.08-0.03-8.16-0.05-12.25-0.08
            c-1.96,1.37-3.87,1.32-5.75-0.17c-5.72-9.25-6.52-18.39,0.53-27.41c4.84-3.9,10.14-5.16,16.05-2.69
            C-20.41-286.35-22.95-279.91-25.8-273.44z"
            />
            <path
              style="fill: #819d8b"
              d="M-41.66-262.11c1.25,0.15,2.51,0.23,3.77,0.23c2.15,3.38,5.18,6.5,6.22,10.19
            c1.27,4.53,10.87,6.47,4.67,13.02c-5.29,5.6-9.56-2.65-14.67-1.95c-0.36-1.08-0.75-2.15-1.17-3.21
            c-5.84-0.18-10.32-2.31-10.34-8.88C-53.19-260.21-48.7-262.9-41.66-262.11z"
            />
            <path
              style="fill: #4a6545"
              d="M47.6-249.85c0.61-13.47-2.99-27.29,2.9-42.64c5.76,12.54,3.38,23.85,5.17,34.55
            C55.08-253.14,52.25-250.59,47.6-249.85z"
            />
            <path
              style="fill: #5a812e"
              d="M103.9-257.53c-13.68-2.51-14.11-13.72-15.81-24.09C101.74-279.1,105.02-269.75,103.9-257.53z"
            />
            <path
              style="fill: #9dbe2c"
              d="M91.36-326.55c0.75-1.13,1.28-2.35,1.61-3.67c8.24,0.4,13.92,4.07,15.93,12.46
            C98.4-311.39,92.86-314.93,91.36-326.55z"
            />
            <path
              style="fill: #83a76c"
              d="M20-204.42c0.89,2.75,1.79,5.5,2.68,8.25c-0.64,1.7-1.84,2.81-3.53,3.42
            c-13.03,1.35-15.91-3.32-8.39-14.16C14.64-209.05,17.54-207.54,20-204.42z"
            />
            <path
              style="fill: #7c9c36"
              d="M120.17-188.82c-8.02-10.99-0.69-14.05,8.36-16.23c2.55-0.02,5.1-0.05,7.64-0.07
            c-0.61,6.37-3.23,11.76-7.84,16.19C125.61-188.9,122.89-188.86,120.17-188.82z"
            />
            <path
              style="fill: #779d2d"
              d="M-175.71-286.3c-0.71-1.86-1.61-3.66-2.1-5.57c-4.16-16.15-10.45-23.33-24.36-3.23
            c6.91-16.2,3.75-30.12,1.78-44.04c1.6,0.95,3.21,1.91,4.81,2.86c7.48,6.64,19.84,2.48,26.15,11.78
            c3.75,6.68,2.43,15.6,9.28,20.96c1.3,1.37,2.25,2.93,2.89,4.7c0.57,2.97-0.09,5.68-1.69,8.19c-3.3,3.5-6.34,7.44-11.91,7.16
            C-172.79-283.88-174.41-284.82-175.71-286.3z"
            />
            <path
              style="fill: #628a2c"
              d="M-172.54-322.6c-8.93-2.46-20.04-1.21-23.04-13.68c4.17-3.61,0.48-7.34,0.18-11.01
            c2.78-2.17,5.43-1.94,7.99,0.4c5.32,10.24,13.29,12.95,23.83,8.45c3.16,4.67,2.12,9.03-0.99,13.24
            C-166.85-323.17-169.55-322.41-172.54-322.6z"
            />
            <path
              style="fill: #223019"
              d="M-208.35-348.49c-6.67-1.53-17.25,2.83-15.96-10.65C-218.18-356.81-204.46-365.88-208.35-348.49z
            "
            />
            <path
              style="fill: #70992f"
              d="M-187.42-346.89c-2.66-0.13-5.33-0.27-7.99-0.4c-3.2-13.44,8.36-12.44,15.44-15.83
            C-182.46-357.71-184.94-352.3-187.42-346.89z"
            />
            <path
              style="fill: #819938"
              d="M-167.51-327.58c1.31-3.62,2.61-7.25,3.91-10.87c4.74-2.91,0.7-15.65,12.23-10.96
            c1.77,6.02,9,8.11,10.37,14.43c0.18,2.12-0.3,4.09-1.39,5.91c-5.57,6.58-13.43,3.69-20.3,4.9
            C-164.8-324.58-166.41-325.72-167.51-327.58z"
            />
            <path
              style="fill: #b4c658"
              d="M-144.18-335.45c-7.34-2.1-6.99-8.17-7.19-13.96c6.87,3.67,7.6-4.3,11.58-6.11
            c2.82,1.46,5.65,2.92,8.47,4.38C-128.07-339.71-133.91-335.75-144.18-335.45z"
            />
            <path
              style="fill: #38461e"
              d="M-144.18-335.45c3.36-5.99,11.52-8.04,12.87-15.68c18.08,3.64,16.2-9.17,16.57-20.13
            c1.24-0.22,2.42-0.06,3.55,0.48c6.51,2.65,6.66,9.68,9.99,14.51c2.4,5.15,8.75,5.52,11.92,9.77
            c8.22,12.08,23.23,13.68,33.92,21.98c1.05,1.72,1.7,3.57,2.02,5.55c0.16,3.08-0.66,5.87-2.47,8.34
            c-1.33,1.33-2.94,2.21-4.76,2.68c-5.2-0.03-11.63-3.72-14.09,4.7c-0.48,1.73-0.99,3.4-1.45,5.12
            c-0.74,4.81-1.98,9.35-6.11,12.45c-6.12,2.63-10.82,8.7-18.43,7.81c-5.42-0.47-10.01-3.73-15.34-4.63
            c-4.58-0.21-9.28,1.44-13.71-0.89c-5.34-3.26-8.59-7.73-8.26-14.24c-1.25-2.07,5.19-4.88-0.83-6.5
            c-1.68-0.09-3.1-0.51-4.53-1.25c-8.3-8.41-1.74-17.33-1.05-26.04C-144.09-332.76-144.03-334.1-144.18-335.45z"
            />
            <path
              style="fill: #567029"
              d="M-107.28-351.18c-5.21-5.75-3.31-12.93-3.91-19.6c4.14-2.97,8.28-5.94,12.42-8.91
            c5.32,2.65,10.63,5.29,15.95,7.94c-4.25,4.83-10.11,8.43-11.61,15.36C-97.64-351.99-102.22-350.99-107.28-351.18z"
            />
            <path
              style="fill: #799c34"
              d="M-99.19-359.48c1.91-8.82,7.89-12.22,16.36-12.27c2.64,2.49,5.44,1.61,8.26,0.41
            c0.13,2.53,0.26,5.06,0.39,7.58c-4.39,0.52-9.1-0.02-12.52,3.77C-90.69-355.64-94.86-355.76-99.19-359.48z"
            />
            <path
              style="fill: #7bad28"
              d="M108.26-107.86c-1.13,8.05,1.39,16.84-4.8,23.87c-4.42-4.05-6.85-9.07-7.36-15.03
            C100.48-101.51,101.52-108.6,108.26-107.86z"
            />
            <path
              style="fill: #394b2a"
              d="M55.38-125.86c0,0,0.13,0.01,0.13,0c-1.82,3.37-3.65,6.74-5.49,10.11
            c-3.67,1.31-7.32,2.61-10.98,3.92c-5.14,0.05-9.91-0.69-12.6-5.91c0.69-9.81-7.02-18.18-4.88-28.27c0.9-4.13,3.1-7.27,6.89-9.23
            c8.52-3.1,17.04-0.51,25.6,0.02c1.86,0.79,3.35,2.01,4.53,3.65C62.23-142.4,57.68-134.27,55.38-125.86z"
            />
            <path
              style="fill: #44582c"
              d="M-2.33-281.96c-1.93-1.98-3.87-3.96-5.8-5.94c-2.68-2.74-5.94-5.04-6.7-9.17
            c-0.35-1.84-0.54-3.72-0.64-5.59c0.19-7.15,4.79-13.5,3.81-20.88c1.22-9.36-3.63-14.01-12.23-15.77
            c-1.47-1.15-2.49-2.61-3.02-4.4c1.86-3.66,3.73-7.32,5.59-10.97c2.57-1.54,5.15-3.08,7.73-4.63c7.26,0.64,11.92-3.95,16.72-8.29
            c6.58,8.56,0.67,20.21,7.12,28.8c6.69,1.03,7.85,6.24,8.78,11.63c1.4,2.86,2.81,5.72,4.22,8.59c-0.24,3.3,0.53,8.91-0.88,9.53
            c-13.8,6.03-9.09,17.15-8.26,27.26c-2.49,1.96-4.92,2.01-7.3-0.2C3.76-281.99,0.71-281.97-2.33-281.96z"
            />
            <path
              style="fill: #87a093"
              d="M-10.06-296.84c0.66,2.98,1.31,5.97,1.95,8.95c-7.72,2.6-12.02,9.36-17.69,14.45
            c-1.16-5.62,1.66-12.21-4.02-16.75c-9.38-4.14-10.11-14.94-16.51-21.41c-0.96-5.75-1.27-11.54-0.8-17.38
            c0.93-2.98,2.59-5.31,5.62-6.45c3.97-2.5,7.93-5,11.9-7.49c8.02,6.66,5.19,18.41,12.04,25.62
            C-15.27-310.43-15.57-302.57-10.06-296.84z"
            />
            <path
              style="fill: #9aa67c"
              d="M-41.52-335.44c-0.08,2.67-0.17,5.35-0.25,8.02c-4.41,5.24-6.61,14.52-16.8,9.21
            c-0.2-1.38-0.41-2.77-0.61-4.15c-2.53-4.3-6.05-8-7.81-12.78c-0.62-4.41,0.58-8.08,4.37-10.68c5.67-2.92,11.33-5.83,17-8.75
            C-49.99-346.96-38.64-342.73-41.52-335.44z"
            />
            <path
              style="fill: #36441d"
              d="M-22.18-314.32c-13.01-6.79-6.93-18.56-7.44-28.62c0.83-0.5,1.74-0.76,2.71-0.78
            c1.69,0.22,3.38,0.43,5.07,0.65c3.86,2.59,8.2,4.78,8.64,10.24c0.1,5.15,2.2,10.28,0.52,15.53
            C-15.18-314.14-17.99-312.01-22.18-314.32z"
            />
            <path
              style="fill: #b2c276"
              d="M-62.62-345.82c0.23,3.83,0.45,7.66,0.68,11.48c-13.68,7.99-21.91-5.45-32.81-8.38
            c-6.23,0.23-9.88-3.37-12.52-8.46c2.7-2.77,5.4-5.54,8.09-8.3c4.16-0.17,8.33-0.34,12.5-0.51
            C-76.85-358.36-69.58-352.36-62.62-345.82z"
            />
            <path
              style="fill: #7b9454"
              d="M38.97-331.13c0.08,3.97,0.53,7.98,0.12,11.9c-0.4,3.91-1.39,8.98-6.9,7.02
            c-4.92-1.75-0.06-7.12-2.13-10.39c6.72-4.06-15.98-24.09,9.68-17.86C41.17-337.21,41.55-334.05,38.97-331.13z"
            />
            <path
              style="fill: #788d3e"
              d="M38.97-331.13c0.26-3.1,0.51-6.21,0.77-9.32c9.78,3.9,26.88,2.46,15.77,21.62
            C47.77-319.93,47.85-331.57,38.97-331.13z"
            />
            <path
              style="fill: #25311b"
              d="M19.03-327.18c-2.92-3.88-5.85-7.75-8.77-11.63c3.06-4.01,3.58-10.04,10.65-12.41
            C23.12-342.74,22.08-334.93,19.03-327.18z"
            />
            <path
              style="fill: #3c4b20"
              d="M-342.14-213.36c-5.3,15.57,7.55,16.33,16.51,20.27c11.08-3.48,11.78,3.64,11.75,11.52
            c-11.2,20.4-25.88,9.13-39.86,4.37c-3.99,3.37-4.24,8.02-4.33,12.72c-1.47-1.31-2.95-2.63-4.42-3.94
            c4.11-12.37,0.14-24.94,0.92-37.39C-357.23-213.85-352.83-221.73-342.14-213.36z"
            />
            <path
              style="fill: #26351b"
              d="M-342.14-213.36c-7.65-0.49-13.43,3.82-19.43,7.55c0.46-11.51-6.29-19.6-13.1-27.65
            c8.28-2.87,16.56-5.74,24.84-8.62C-322.49-239.16-341.03-223.92-342.14-213.36z"
            />
            <path
              style="fill: #73a24c"
              d="M-378.95-261.08c11.46,11.91-1.82,18.79-5.6,27.6c-3.35-1.48-6.71-2.96-10.06-4.44
            c-1.51-4.03-3.02-8.05-4.54-12.08C-396.27-260.73-389.74-264.78-378.95-261.08z"
            />
            <path
              style="fill: #56742b"
              d="M-268.23-38.87c3.88-9.53,7.76-19.07,11.64-28.6c2.27-1.47,0.62,0.33,1.12-1.51
            c1.58-5.81,20.23-1.25,8.72-14.46c0.88-1.69,2.1-3.07,3.63-4.19c9.05-4.23,20.48-5.26,23.44-17.59
            c-0.26-8.44,1.06-16.94-2.03-25.14c0.19-3.23,1.27-6.09,3.37-8.56c12.08-6.14,21.2-23.75,38.45-10.09
            c5.05,5.2,1.8,11.88,3.2,17.77c5.13,7.6,11.93,14.77,6.54,25.08c-1.82,2.23-4.15,3.65-6.92,4.3
            c-11.38,1.84-14.46,10.72-16.95,19.97c0.25,0.22-0.52,0.85,0.34,1.01c0.2-1.91,0.99-3.51,2.44-4.82
            c4.73-3.14,9.4-1.45,14.1,0.08c4,1.5,6.87,4.19,8.6,8.11c2.23,8.85,2.98,17.51-3.7,25.06c-6.28,5.9-17.33,6.51-19.21,17.36
            c-1.13,1.5-2.5,2.72-4.13,3.66c-7.24,1.13-14.62,3.12-20.8-3.06c-6.38-2.84-9.36-6.41-1.45-10.85
            c5.02-2.82,11.99-2.21,15.18-8.76c-9.77-7.98-25.08,7.9-32.8-7.76c-10.48,5.6-11.01,17.2-15.29,26.37
            c-2.26,4.85-4.34,8.46-9.74,8.21C-267.8-27.64-268.49-33.09-268.23-38.87z"
            />
            <path
              style="fill: #465f3e"
              d="M-216.37-34.51c6.65-1.3,13.29-4.52,20.01-0.24l1.51,1.15l-1.29-1.4
            c1.86-11.67,3.28-23.78,20.3-20.16c10.83-4.04,13.67,2.95,15.56,11.27c0.15,5.62-2.02,10.68-3.82,15.85
            c-7.95,9.99-1.07,23.26-7.39,33.59c-2.69,1.41-5.38,2.83-8.07,4.24c-10.79-7.02-16.44-1.76-20.04,8.4
            C-201.6-0.51-208.01-17.82-216.37-34.51z"
            />
            <path
              style="fill: #5d7b29"
              d="M-280.95-140.47c5.76,16.7,4.94,35.98,24.19,50.55c-18.46,0.29-33.25-4.43-44.78,6.9
            c-8.73,1.05-16.56-2.22-24.47-5.1c-5.97-11.09-4.27-23.07-4.05-34.88c4.49-4.87,5.18-10.79,4.69-17.03
            c4.09-3.37,7.74-2.13,11.2,1.05c4.16,4.69,1.53,11.75,5.71,16.42c1.67,2.82,3.46,5.59,6.96,6.33c0.29,0.06,0.72-1.9,0.72-3.49
            c4.52-5.72,4.29-12.64,5.23-19.26c2.21-3.74,5.57-5.05,9.76-4.62C-283.87-143.02-282.26-141.97-280.95-140.47z"
            />
            <path
              style="fill: #3b471d"
              d="M-316.95-136.23c-2.81-1.26-5.62-2.53-8.43-3.79c-10.8-2.67-21.6-5.34-32.4-8.01
            c2.68-9.81,20.75,5.3,18.97-11.74c3.08-9.7,10.35-11.59,18.99-9.38c8.79,2.25,13,9.24,14.06,17.6
            C-304.72-143.5-307.29-136.99-316.95-136.23z"
            />
            <path
              style="fill: #6e922b"
              d="M-330.06-122.99c4.84,11.22,4.39,23.06,4.05,34.88C-340.52-98.21-334.54-110.69-330.06-122.99z"
            />
            <path
              style="fill: #31431d"
              d="M-179.9-144.43c-14.88-10.6-22.84,10.04-35.83,7.95c-7.25-3.07-5.83,8.36-11.95,7.19
            c-9.36-4.55-18.93-8.6-29.15-10.87c-8.74-3.57-13.04-8.25-4.04-16.24c6.25-5.64,3.41-11.62,0.87-17.78
            c-3.09-7.5-2.15-14,5.59-18.31c7.97-3,13.23,2.06,18.71,6.3c2.18,0.97,4.05,0.51,5.54-1.4c1.31-3.16,0.48-6.49,1.09-9.66
            c0.25-1.76,0.8-3.42,1.41-5.05c2.15-4.33-0.2-8.55-0.37-12.83c0.32-4.11,2.1-7.23,6-8.92c17.68,1.96,31.6,19.06,51.39,12.77
            c2.02,0.33,3.86,1.07,5.6,2.11c5.42,3.78,8.54,8.81,8.54,15.55c-0.32,3.4-2.7,5.93-3.91,8.95c-6.22,15.6-15.07,16.49-25.98,5.24
            c0.4,0.18,1.03,0.8,1.34,0.95c6.76,6.94,17.98,11.43,11.91,24.27C-174.26-150.17-175.45-146.17-179.9-144.43z"
            />
            <path
              style="fill: #2f3a1b"
              d="M-316.95-136.23c6.28-6.79,7.19-13.46,1.08-21.28c-7.19-9.21-14.81-6.53-22.93-2.26
            c-7.54-3.61-9.54-11.98-14.93-17.43c11.55-5.94,24.8,5.41,36.06-3.54c5.46-9.87,14.75-11.46,24.74-11.51
            c27.2-1,32.2,1.72,37.78,19.68c2.05,6.59,0.29,10.43-6.6,11.65c-11.82,1.73-11.58,14.79-19.19,20.46
            c-1.45-0.22-2.89-0.44-4.34-0.66c-11.5-4.26-10.98,3.92-11.45,11.1c-0.99,3.85-3.91,6.55-5.81,9.87
            c-3.4,2.88-6.95,3.97-10.84,0.92C-318.74-124.03-317.58-130.18-316.95-136.23z"
            />
            <path
              style="fill: #608230"
              d="M-261.76-160.93c2.94-18.99-1.81-24.06-18.87-20.2c-3.48,0.79-6.58-0.04-9.12-2.65
            c-19.22-6.06-6.81-19.88-7.51-30.53c-0.83-9.55-1.34-18.94,11.05-21.71c12.4,0.95,19.6,18.02,34.56,11.14
            c5.22,0.47,9.41,2.64,11.99,7.4c1.65,4.77,0.43,9.41-0.59,14.09c-1.89,6.81-6.21,11.54-12.54,14.51
            c-4.79,5.2-1.9,9.32,1.24,14.16c7.13,10.97,4.31,17.59-9.34,18.31C-261.18-157.92-261.47-159.42-261.76-160.93z"
            />
            <path
              style="fill: #8aaf2c"
              d="M-212.7-107.37c8.79,1.53,9.97,10.74,4.9,12.13c-11.22,3.09-18.16,19.75-32.91,10.97
            c-3.69-2.82-0.41-11.09-8.29-10.62c-0.9-9.82-8.14-16.74-11.59-25.39c1.98-15.85,13.3-19.3,31.55-9.62
            c2.8,0.89,5.64,1.71,7.74,3.97C-215.23-121.24-222.69-110.26-212.7-107.37z"
            />
            <path
              style="fill: #719332"
              d="M-228.74-127.35c-11.96-3.71-22.79-2.32-31.85,7.06c-8.02-8.38,0.1-13.71,3.74-19.88
            c11.89-17.58,11.89-17.58,28.58,9.71l0.87,1.72L-228.74-127.35z"
            />
            <path
              style="fill: #8aaf2c"
              d="M-244.4-82.23c11.94,18.16-6.76,10.96-12.18,14.75c0.75-8.77,2.89-17.13,7.13-24.9
            C-241.83-91.96-241.78-87.76-244.4-82.23z"
            />
            <path
              style="fill: #aac062"
              d="M-244.4-82.23c-1.69-3.38-3.37-6.75-5.06-10.14c0.04-0.87,0.19-1.72,0.47-2.53
            c9.89-2.41,1.71-20.12,17.1-18.84c1.2,11.07-8.12,19.15-8.82,29.47C-241.94-83.59-243.17-82.91-244.4-82.23z"
            />
            <path
              style="fill: #537754"
              d="M-167.73-30.87c-3.23-5.65,1.08-8.55,4.29-11.86c-0.05-10.98-9.57-24.05,6.91-31.42
            c7.74-2.27,14.32-0.2,19.95,5.36c2.38,1.93,4.02,4.37,4.93,7.29c2.13,6.92-3.06,12.38-3.8,18.75
            c3.51,11.39,0.3,23.04,1.35,34.52c4.51-0.64,8.72,0.57,9.04,5.44c0.47,6.99-4.39,10.75-10.52,12.96
            c-10.64-3.75-11.17-11.49-8.11-20.64c2.07-16.7-9.97-13.34-19.43-13.66C-165.89-25.52-167.54-27.7-167.73-30.87z"
            />
            <path
              style="fill: #80af31"
              d="M-135.59,10.18c0.67-6.12,12.04-11.39,1.48-18.41c3.69-2.77,7.3-5.66,11.08-8.31
            c13-9.1,16.16-2.6,15.47,9.87c-4.6,3.65-13.03,3.83-11.66,12.89c-3.87,3.9-7.74,7.8-11.61,11.71
            C-132.42,15.35-134,12.77-135.59,10.18z"
            />
            <path
              style="fill: #6e8d93"
              d="M-163.82-27.15c5.82-8.85,14.51-8.17,20.73-2.89c5.91,5.02,5.37,13.19-0.6,19.58
            c-0.83-1.24-1.66-2.48-2.49-3.71c-3.97-0.15-8.02,1.22-11.92-0.57C-162.42-17.76-166.52-20.89-163.82-27.15z"
            />
            <path
              style="fill: #405752"
              d="M-155.56-19.17c3.12,1.67,6.24,3.33,9.37,5c-3,5.31-6,10.62-9,15.94c-2.5-1.14-5-2.29-7.5-3.43
            C-171.42-12.03-165.17-16.29-155.56-19.17z"
            />
            <path
              style="fill: #879e9f"
              d="M-159.37,66.61c8.06,4.82,21.37-5.46,29.63,13.11c-12.54-6.43-25.33,2.78-29.98-12.76
            C-159.72,66.97-159.37,66.61-159.37,66.61z"
            />
            <path
              style="fill: #5a7770"
              d="M-155.56-19.17c-4.34,5.03-9.03,9.92-7.13,17.5c-2.93,2.41-5.87,4.82-8.8,7.23
            c-1.45-12.42-12.84-25.86,3.75-36.42c1.31,1.24,2.61,2.48,3.91,3.71C-161.07-24.49-158.31-21.83-155.56-19.17z"
            />
            <path
              style="fill: #aac037"
              d="M5.71-46.73c1.5-4.27,3.01-8.54,4.51-12.8c16.61,1.79,17.51,3.98,8.6,20.89l0.09-0.01
            c-4.97,3.7-8.02,11.38-16.43,8C-4.62-37.66,3.98-41.5,5.71-46.73z"
            />
            <path
              style="fill: #80ad28"
              d="M2.49-30.66c5.01-3.64,9-9.37,16.43-8c-0.71,7.07,1.03,13.67,3.78,20.08
            C12.69-17.11,8.01-24.59,2.49-30.66z"
            />
            <path
              style="fill: #26321a"
              d="M-135.46-42.74c-8.88-5.39-8.02-11.09-0.54-17.01c7.32-4.92,14.71-13.06,21.68,0.43
            c3.22,3.29,6.68,6.37,9.61,9.91c5.85,7.07,1.52,14.88-1.92,19.93c-3.59,5.26-7.33-2.1-11.02-4.3
            C-123.35-37.18-129.5-39.8-135.46-42.74z"
            />
            <path
              style="fill: #4e6b28"
              d="M-114.32-59.32c-7.13-4.81-14.48,1.66-21.67-0.43c-1.45-1.31-2.9-2.61-4.34-3.92
            c-1.23-6.56,3.83-14.55-5.24-19.41c-3.89-2.08-8.7-6.63-5.78-11.1c3.41-5.22,9.64-6.16,15.33-1.68
            c8.46,2.83,16.93,5.66,25.4,8.49c-2.98,11.29,8.42,16.02,11.23,24.66C-104.38-61.57-109.35-60.45-114.32-59.32z"
            />
            <path
              style="fill: #a7c229"
              d="M-136.03-95.85c-3.21,0.14-6.42,0.29-13.65,0.61c10.53,7.83,26.14,5.95,25.79,19.37
            c-0.21,8.28-10.62,8.41-16.45,12.22c-4.89-2.29-9.77-4.59-14.66-6.88c-6.35,1.16-12.78,2.63-16.78-4.66
            c-2.63-1.57-5.27-3.14-7.9-4.7c-8.68-9.08-11.19-18.16,0-27.24c2.39-0.41,4.78-0.82,7.17-1.23c3.36-3.81,7.08-6.22,12.19-3.06
            C-157.62-97.8-140.33-106.97-136.03-95.85z"
            />
            <path
              style="fill: #577d2e"
              d="M-90.73-115.92c-15.9,7.05-31.96,11.99-48.99,3.6c-6.61-7.5-6.93-16.28-5.21-25.49
            c11.61-14.6,11.73-14.59,33.44,2.97c0.3-0.6,0.61-1.2,0.91-1.8c-2.57,3.46-4.96,0.85-4.81-1.15
            c0.83-11.07,8.28-12.37,17.33-10.84c10.84,4.08,12.4,13.95,14.61,23.43C-83.69-120.4-85.87-117.1-90.73-115.92z"
            />
            <path
              style="fill: #77a231"
              d="M-139.3-136.07c-1.21,7.9,5.93,15.95-0.43,23.76c-6.86,0.29-13.72,0.58-20.59,0.88
            c-3.77,2.19-7.6,4.12-12.19,3.07c2.29-15.15-19.24-10.73-19.5-23.78c9.73-3.53,19.22-8.03,29.94-7.75
            C-153.87-142.21-145.95-142.89-139.3-136.07z"
            />
            <path
              style="fill: #9eb9ba"
              d="M-90.73-115.92c1.27-2.62,2.54-5.24,3.82-7.87c4.38-19.37,7.47-21.06,20.17-11
            c0.81,8.84-6.13,14.39-9.21,21.6c-0.9,0.41-1.8,0.83-2.7,1.24c-2.64,0.14-5.28,0.28-7.92,0.42
            C-87.96-112.99-89.35-114.45-90.73-115.92z"
            />
            <path
              style="fill: #82b128"
              d="M18.83-38.64c0.88-8.51,2.65-17.39-8.6-20.89c3.98-6.57-1.15-21.99,15.63-16.08
            c4.47-2.98,9.05-11.27,13.22,0.27c1.37,12.77-6.27,23.36-8.53,35.25C26.25-42.8,22.53-40.82,18.83-38.64z"
            />
            <path
              style="fill: #8da1bd"
              d="M19.54-99.5c-0.1,2.69-0.2,5.38-0.29,8.07c-3.64,1.74-10.18-0.15-9.75,6.81
            c0.81,12.92-9.55,6.98-15.02,9.27c0,0,0.13,0.35,0.13,0.35c-4.41-5.1-3.75-10.25-0.14-15.43C1.59-96.87,6.68-108.96,19.54-99.5z
            "
            />
            <path
              style="fill: #72a132"
              d="M39.08-75.34c-4.32-4.26-8.78-1.37-13.21-0.27c0.5-3.85,1.01-7.7,1.51-11.55
            C44.96-87.09,44.96-87.09,39.08-75.34z"
            />
            <path
              style="fill: #49672b"
              d="M-53.64-83.45c0.21-10.16-6.99-18.89-16.63-20.17c-5-0.55-7.64-3.48-8.38-8.33
            c0,0-0.16-0.06-0.16-0.06c4.59-10.51,16.35-14.58,21.83-24.32c4.91-3.94,9.69-8.29,16.74-4.94c5.95,4.08,7.15,10.31,7.64,16.85
            c-2.4,6.19-3,19.71-1.08,24.95C-33.37-85.42-41.83-82.35-53.64-83.45z"
            />
            <path
              style="fill: #3d5328"
              d="M-33.7-99.46c-4.87-3.25-9.74-6.49-15.18-10.11c1.03-6.32,10.83-6.65,11.11-13.98
            c4.44-7.76,11.5-6.9,18.66-5.67c6.73,2.71,11.28,7.24,12.3,14.72c-0.12,12.03-11.57,9.75-17.81,14.07
            C-27.64-100.11-30.67-99.78-33.7-99.46z"
            />
            <path
              style="fill: #243e2e"
              d="M-0.14-87.75c-1.75,4.25-3.5,8.51-5.24,12.76c-4.24-1.38-10.28,1.77-12.4-4.94
            c4.15-5.17-3.29-10.36,0.04-15.53c2.37-3.37,4.94-6.43,9.59-6.32C-0.63-99.89,0.83-94.52-0.14-87.75z"
            />
            <path
              style="fill: #a4bc6b"
              d="M-8.02-99.09c-2.01,4.52-5.5,5.08-9.73,3.63c-0.35-4.34-3.45-4.88-6.86-4.97
            c-0.12-10.33,11.13-5.58,14.66-11.07C-5.81-107.92-5.48-103.73-8.02-99.09z"
            />
            <path
              style="fill: #2b391b"
              d="M-134.69-299.2c2.41,4.06,4.82,8.13,7.22,12.19c0.81,3.87,1.76,7.74-0.41,11.49
            c-13.43,12.61-33.34,16.72-43.76,33.31c-9.72,5.64-8.38,14.58-6.93,23.57c-0.76,5.62-4.1,8.98-9.31,10.71
            c-14.87-5.85-26.53-14.8-30.36-31.38c-2.21-3.98-3.31-8.12-1.94-12.65c3.62-6.73,8.22-10.86,16.13-5.96
            c8.69,0.14,16.72-4.48,13.44-12.58c-7.65-18.91,6.48-13.96,14.89-15.81c1.15,0.26,2.29,0.58,3.41,0.96
            c8.76,3.02,6.64-5.2,9.03-8.76c1.43-1.34,2.85-2.68,4.28-4.03c2.43-6.15,7.56-6.15,12.89-5.69c2.62,0.07,4.84-0.95,6.85-2.54
            l-0.19-0.01c2.58-2.7,5.76-3.02,7.81-0.09C-129.41-303.29-131.72-300.82-134.69-299.2z"
            />
            <path
              style="fill: #618231"
              d="M-244.83-306.24c3.42-7.65,8.61-14.65,8.56-23.56c-3.12-6.06-8.99-12.25,0.85-17.68
            c23.74,12.58,28.39,21.88,20.14,47.7c-2.27,7.1,0.18,13.1-0.94,19.5c-1.61,4.22-4.76,6.42-9.13,7.07
            c-18.07-2.66-14.12-19.1-18.81-30.14C-244.38-304.32-244.6-305.28-244.83-306.24z"
            />
            <path
              style="fill: #a2afbd"
              d="M-229.74-278.76c4.51-0.51,9.01-1.01,13.52-1.53c0.51,2.59,1.02,5.18,1.53,7.78
            c17.49-5.31,11.34,6.63,10.64,14.6c-4.75,1.43-9.72,2.42-12.97,6.74c-5.2,6.69-8.56,1.87-12.09-1.91
            C-232.11-261.56-237.2-270.01-229.74-278.76z"
            />
            <path
              style="fill: #aac55d"
              d="M-235.42-347.48c-0.28,5.89-0.57,11.78-0.85,17.68c-1.76-0.35-3.52-0.71-5.28-1.06
            c-3.65-3.56-3.22-9.28-6.82-12.86c0.01-2.37,0.02-4.73,0.02-7.1C-243.69-351.04-238.75-352.36-235.42-347.48z"
            />
            <path
              style="fill: #33411d"
              d="M-264.44-329.91c-2,3.93-0.02,12.87-7.54,9.82c-4-1.62-10.67-8.57-7.87-14.58
            c4-8.61,9.81-1.9,14.7,0.7C-261.9-333.14-261.66-331.79-264.44-329.91z"
            />
            <path
              style="fill: #a4bc8a"
              d="M-273.3-288.28c7.41-3.53,7.75-12.45,13.26-17.42c2.45,0.94,4.89,1.87,7.33,2.81
            c1.52,1.78,2.32,3.83,2.41,6.17c-2.47,9.11-5,18.17-17.15,18.09C-271.83-280.36-276.69-281.81-273.3-288.28z"
            />
            <path
              style="fill: #404d1f"
              d="M-264.44-329.91c-0.23-1.36-0.47-2.71-0.7-4.06c5.91-2.69,17.36,4.15,16.77-9.75
            c7.46,1.53,8.64,6.41,6.83,12.86C-248.82-322.02-256.6-325.34-264.44-329.91z"
            />
            <path
              style="fill: #465b23"
              d="M-253.76-298.56c0.35-1.44,0.7-2.88,1.05-4.32c2.63-1.12,5.25-2.24,7.87-3.36
            c0,0-0.12-0.24-0.12-0.24c1.78,1.98,3.66,4.2,3.45,6.85c-0.52,6.83,3.52,14.61-3.83,20.13c-2.21,1.67-4.93,2.83-7.16,0.97
            C-259.75-284.61-254.42-291.79-253.76-298.56z"
            />
            <path
              style="fill: #9abb5b"
              d="M-273.3-288.28c1.94,3.33,4.67,5.59,8.55,6.28c8.53,3.12,13.95,7.91,9.63,17.95
            c-4.49,4.59-9.16,7.06-14.51,1.19c-1.23-7-6.82-7.09-12.1-7.66c-0.37-4.39-0.99-8.76-1.06-13.15
            C-282.92-291.67-278.72-291.39-273.3-288.28z"
            />
            <path
              style="fill: #3c4e2a"
              d="M-285.54-234.47c-8.6,5.87-2.68,14.2-3.62,21.37c-3.64,5.6-7.78,11.26-15.39,9.19
            c-9.76-2.66-8.21-11.99-9.85-19.29c-5.05-9.83,7.31-12.13,8.36-19.33c1.11-7.44,9.28-6.44,12.94-10.83
            c1.22-0.21,2.44-0.15,3.63,0.18C-281.47-248.34-278.36-242.49-285.54-234.47z"
            />
            <path
              style="fill: #27301b"
              d="M-306.04-242.52c7.22,10.09-3.76,11.94-7.68,16.99c-1.02,1.35-2.33,1.65-3.88,1.04
            c-4.7-2.93-7.62-6.96-7.79-12.68C-319.48-240.92-312.85-242.05-306.04-242.52z"
            />
            <path
              style="fill: #496731"
              d="M-285.54-234.47c-1.31-6.23-2.62-12.47-3.92-18.7c6.62-3.22,13.23-6.45,19.84-9.68
            c4.94,1.37,8.47-1.97,12.48-3.74c9.62-1.74,13.98,4.87,18.33,11.53c0.59,14.4-11.04,24.33-13.8,37.53
            C-267.06-216.41-281.49-215.33-285.54-234.47z"
            />
            <path
              style="fill: #577036"
              d="M-317.59-224.5c1.27-0.42,2.56-0.77,3.88-1.04c9.87,0.82,6.51,27.78,24.55,12.43
            c-0.76,3.98,6.94,8.82,0.42,11.55c-13.8,5.78-3.82,11.71-1.01,17.76c-9.73-2.83-18.67,1.58-27.93,3.05
            c-0.58-5.45-1.43-10.73-7.95-12.34C-326.59-204.49-326.22-215.55-317.59-224.5z"
            />
            <path
              style="fill: #899bac"
              d="M-8.02-99.09c-0.64-4.14-1.29-8.28-1.93-12.42c-0.41-5.81-5.4-8.58-8.38-12.69
            c-3.67-5.79-4.61-12.14-3.9-18.85c1.3-3.92,3.7-6.65,7.98-7.31c16.5-4.24,22.48,10.51,32.54,17.98
            c5.05,3.01,8.63,7.08,9.36,13.16c3.8,2.47,7.6,4.93,11.4,7.4c-7.05,3.24-16.16,3.22-19.5,12.33
            c-8.69,0.35-13.56,7.1-19.69,11.74C-2.77-91.53-5.4-95.31-8.02-99.09z"
            />
            <path
              style="fill: #72915b"
              d="M22.8-278.24c4.42-3.11,9.57-6.65,12.02,1.27c3.29,10.64-2.2,17.09-12.16,20.35
            C16.66-263.87,19.86-271.05,22.8-278.24z"
            />
            <path
              style="fill: #6c9064"
              d="M6.81-282.01c2.43,0.07,4.87,0.14,7.3,0.2c0.36,3.77,0.72,7.54,1.08,11.31
            c4.51,6.08,0,9.62-3.76,13.37c-0.43,0.91-0.74,1.86-0.95,2.85C-6.63-261.42,13.75-273.52,6.81-282.01z"
            />
            <path
              style="fill: #485c2a"
              d="M11.43-257.12c1.25-4.46,2.51-8.91,3.76-13.37c2.54-2.58,5.07-5.17,7.61-7.75
            c-0.05,7.2-0.09,14.41-0.14,21.61C18.91-256.79,15.17-256.96,11.43-257.12z"
            />
            <path
              style="fill: #364b29"
              d="M71.14-168.57c-4.62-2.85-8.83-6.45-5.97-12.31c2.87-5.89,9.02-2.97,13.83-3.55
            c1.92-3.09,3.75-6.34,8.33-5.07C100.87-167.85,81.91-171.38,71.14-168.57z"
            />
            <path
              style="fill: #8ca4ae"
              d="M87.32-189.5c-2.78,1.69-5.55,3.38-8.33,5.07c-2.35-6.21-9.43-10.53-7.38-18.5
            c4.93,2.2,8.33-1.7,12.34-3.11C96.48-202.86,96.28-197.07,87.32-189.5z"
            />
            <path
              style="fill: #99ba61"
              d="M55.41-152.43c-1.32-1.14-2.64-2.27-3.96-3.41c-2.26-1.66-2.67-3.86-1.9-6.42
            c7.12-0.52,14.25-1.04,21.37-1.56c-2.9,7.94,1.36,17.46-5.41,24.54C52.18-136,60.94-149.72,55.41-152.43z"
            />
            <path
              style="fill: #667e3a"
              d="M55.41-152.43c8.19,0.65,2.34,12.1,10.08,13.13c2.35,8.81,0.04,14.09-10.11,13.44
            C50.7-134.72,57.66-143.57,55.41-152.43z"
            />
            <path
              style="fill: #bccbd5"
              d="M14.93-124.22c-16.58,2.53-10.54-11.6-13.26-19.35c-5.24,2.86-1.77,9.9-9.03,12.42
            c-2.07-5.39-4.28-11.11-6.48-16.83c-1.47-17.3,11.63-11.36,20.18-12.69c2.96-1.17,5.92-2.35,8.87-3.52
            c2.71-3.24,5.97-5.24,10.4-4.66c8.94,3.19,10.07,8.81,5.06,16.34c-3.97,1.28-3.38,4.92-4.1,7.89
            C22.26-138.08,19.25-130.77,14.93-124.22z"
            />
            <path
              style="fill: #779459"
              d="M6.35-160.68C0.84-154.52-10-156.85-13.83-148c-1.1,2.17-2.2,4.34-3.3,6.51
            c1.98,3.76,0.43,6.03-3.3,6.75c-4.41,0.86-7.03-1.77-8.83-5.43c-1.44-8.33,3.36-16.62,0.22-24.97
            c0.04-8.02,3.13-16.16-0.81-24.08c1.91-10.93,12.1-2.32,16.63-7.06c3.83,0.14,10.89,0.87,8.62,4.62
            C-14.17-175.91-0.36-169.81,6.35-160.68z"
            />
            <path
              style="fill: #4a6735"
              d="M19.16-192.75c1.18-1.14,2.36-2.29,3.53-3.42c2.96-0.73,6.1-3.17,8.59,0.87
            c3.17,7.52,9.73,14.14,6.23,23.44c-2.86,3.86-6.57,5.28-11.25,3.98c-4.93-3.69-6.85-8.1-3.28-13.81
            C21.7-185.38,20.43-189.07,19.16-192.75z"
            />
            <path
              style="fill: #89a691"
              d="M22.97-181.7c1.37,4.27,2.73,8.53,4.1,12.8c2,2.26,1.59,3.94-1.23,5.01
            c-3.54-0.11-7.08-0.22-10.62-0.32C15.05-171.26,11.67-179.74,22.97-181.7z"
            />
            <path
              style="fill: #63816a"
              d="M25.84-163.88c0.41-1.67,0.82-3.34,1.23-5.01c3.07-1.25,6.13-2.49,9.2-3.74
            c3.53-0.32,5.55,1.54,6.67,4.67c2.22,1.92,4.45,3.84,6.64,5.73c0.6,2.11,1.24,4.26,1.87,6.39c-4.8,14.36-12.33,11.69-20.77,3.32
            C27.97-155.84,30.81-161.52,25.84-163.88z"
            />
            <path
              style="fill: #91b296"
              d="M42.94-167.96c-2.23-1.56-4.45-3.11-6.68-4.67c-1.42-7.62-8.1-14.08-4.99-22.69
            c3.01,2.2,6.03,4.38,9.05,6.57C49.29-182.85,46.37-175.44,42.94-167.96z"
            />
            <path
              style="fill: #a8b9bd"
              d="M-41.66-262.11c-5.67,5.76-11,11.55-1.17,18.3c-13.8,2.06-26.91,1.81-30.78-15.2
            c-1.64-7.2-5.92-9.93-11.87-11.88c-1.14-0.86-1.71-1.87-1.71-3.03c0.01-1.23,0.15-1.85,0.43-1.85
            c10.44-8.03,25.14-10.1,31.74-23.83c1.52-3.15,7.2-2.44,10.93,0.01c3.96,4.05,5.17,8.55,2.26,13.72
            C-38.39-277.98-35.76-270.07-41.66-262.11z"
            />
            <path
              style="fill: #324921"
              d="M-91.04-225.67c6.19,2.8,10.06-8.87,16.79-2.69c2.04,13.47-1.85,24.39-13.71,31.92
            c-1.96,0.85-4,1.24-6.13,1.22c-18.31-4.67-18.86-5.94-10.48-24.08C-101.55-224.57-97.31-227.28-91.04-225.67z"
            />
            <path
              style="fill: #a1acb5"
              d="M-81.92-253.3c7.48,13.44-7.45,14.1-11.83,20.78c-7.09,3.59-11.94,0.35-16.05-5.23
            c-4.05-10.86-1.15-21.11,2.85-31.28c1.21-1.56,2.69-2.77,4.48-3.61c10.85-2.23,12.59,5.96,15.1,13.25
            C-85.88-257.06-83.48-255.56-81.92-253.3z"
            />
            <path
              style="fill: #9cad9a"
              d="M-106.93-241.17c4.39,2.88,8.79,5.77,13.18,8.65c0.91,2.29,1.81,4.57,2.71,6.85
            c-2.67,2.71-5.33,5.43-8,8.14c-5.18,4.74-10.47,4.7-15.88,0.39c-9.74-8.84-14.86-18.71-6.99-31.47
            C-112.58-254.94-110.59-246.36-106.93-241.17z"
            />
            <path
              style="fill: #577e2d"
              d="M-90.58-200.96c5.44-9.14,10.88-18.28,16.32-27.41c1.24,0.34,2.51,0.47,3.79,0.43
            c2.65,1.61,3.83,3.92,3.33,7.03c0.16,5.42,0.32,10.83,0.47,16.25C-73.9-198.68-80.96-191.6-90.58-200.96z"
            />
            <path
              style="fill: #d2d9ea"
              d="M-81.92-253.3c-4.36,1.16-6.22,9.37-12.71,4.53c3.24-7.57,2.73-15.16,0.11-22.77
            c-2.57-7.48,0.24-11.15,8-11.32c2.18,2.83,2.4,5.68,0.11,8.57c-0.39,1.32-0.08,2.45,0.92,3.39
            C-83.14-265.26-78.39-260.12-81.92-253.3z"
            />
            <path
              style="fill: #2e3e1d"
              d="M-67.14-220.92c-1.11-2.35-2.21-4.69-3.33-7.03c7.68-6.69,14.44-4.84,20.64,2.25
            C-54.75-221.02-61.06-221.38-67.14-220.92z"
            />
            <path
              style="fill: #b3c4c8"
              d="M-61.88-197.12c2.13,0.02,4.26,0.04,6.39,0.06c1.84,0.25,2.96,1.31,3.47,3.08
            c-0.05,6.74-5.94,10.25-8.5,15.61c-4.32,3.57-4.55,9.9-8.94,13.46c-3.58,2.66-7.43,3.6-11.73,1.93
            c-8.79-5.62-9.06-14.72-9.69-23.69C-85.4-201.79-72.56-196.44-61.88-197.12z"
            />
            <path
              style="fill: #90b547"
              d="M-61.88-197.12c-6.97,6.99-19.6,1.67-25.2,11.61c-1.68,2.95-4.35,3.83-7.55,3.64
            c-3.16-0.79-5.25-2.73-6.25-5.85c-0.88-7.77,5.41-9.96,10.3-13.24c0,0,0,0,0,0c7.97-1.24,15.94-2.48,23.91-3.72
            C-65.07-202.15-63.47-199.64-61.88-197.12z"
            />
            <path
              style="fill: #85a256"
              d="M-55.03-193.77c-0.15-1.09-0.3-2.19-0.44-3.29c7.93-1.28-2-14.87,9.43-13.74
            c5.62,6.54,10.93,13.28,12.49,22.13c-0.82,0.91-1.65,1.82-2.47,2.73C-44.66-182.97-53.23-180.16-55.03-193.77z"
            />
            <path
              style="fill: #bfcccb"
              d="M-29.85-189.21c22.14-0.4,8.64,12.34,7.55,20.5c-2.81,6.91-2.63,15.7-11.74,18.64
            c-10.54-0.73-13.79-6.94-11.47-16.37c1.42-5.78,2.63-11.67,4.72-17.28c1.86-2.52,4.63-3.46,7.47-4.3c0,0-0.23-0.65-0.23-0.65
            C-32.29-188.64-31.06-188.83-29.85-189.21z"
            />
            <path
              style="fill: #bec9d4"
              d="M-41.83-285.87c-1.47-4.17-2.95-8.34-4.42-12.51c-4.35-3.52-11.77-5.1-8.48-13.45
            c3.56-4.02,7.22-7.45,12.47-2.31c12.13,3.83,13.74,13.13,12.44,23.94C-32.81-285.93-37.27-285.76-41.83-285.87z"
            />
            <path
              style="fill: #2d3519"
              d="M-167.51-327.58c1.29,0.42,2.58,0.83,3.87,1.25c7.04,1.49,14.38,2.57,15.69,11.74
            c0.19,11.09-10.45,11.08-16.33,15.96c-10.66-5.26-15.31-12.6-8.26-23.97C-170.87-324.26-169.19-325.92-167.51-327.58z"
            />
            <path
              style="fill: #455c27"
              d="M-163.27-294.1c5.82,5.03,5.07,9.93-0.08,14.98c-2.47,2.42-5.11,4.85-8.47,2.63
            c-3.59-2.36-1.5-5.81-0.48-8.85C-169.29-288.26-166.28-291.18-163.27-294.1z"
            />
            <path
              style="fill: #80a52b"
              d="M-164.28-298.63c0.13-8.86,10.5-8.87,13.3-15.41c6.6,4.94,11.7,10.45,7.3,19.51
            c-5.1-1.2-10.2-2.4-15.3-3.6C-160.75-298.29-162.52-298.46-164.28-298.63z"
            />
            <path
              style="fill: #475b22"
              d="M-143.69-294.53c-2.43-6.5-4.87-13.01-7.3-19.51c-3.42-4.92-11.62-4.91-12.66-12.29
            c5.52-5.13,12.59-4.4,19.28-5.1c9.23,6.81-0.54,17.52,5.12,25.07C-139.38-301.91-139.08-297.29-143.69-294.53z"
            />
            <path
              style="fill: #637e44"
              d="M-53.75-310.7c2.5,4.1,5,8.21,7.5,12.31c-1.82,0.71-5.19,1.35-5.22,2.14
            c-1.06,24.39-18.43,22.46-34.94,21.97c-0.29-2.51-0.59-5.01-0.88-7.52c-1.99-2.86-1.72-5.67,0.19-8.44
            c3.68-3.25,4.47-7.69,5.03-12.23c6.9,5.1,10.58,2.66,11.99-5.12c2.15-2.14,4.7-3.2,7.77-2.85c0.97-0.37,1.95-0.69,2.95-0.94
            C-57.31-312.63-55.44-312.4-53.75-310.7z"
            />
            <path
              style="fill: #4a5d2c"
              d="M-106.93-241.17c-3.78-2.54-7.56-5.07-11.34-7.61c-9.32,0.22-12.9-6.13-15.52-13.58
            c-2.44-5.39-0.53-10.25,1.88-15.05c1.48-3.2,2.97-6.39,4.45-9.59c4.49-0.92,8.97-1.84,13.46-2.75
            c6.57,6.49,0.8,18.76,11.02,23.54C-104.22-257.85-102.42-249.01-106.93-241.17z"
            />
            <path
              style="fill: #949f7a"
              d="M-102.99-266.21c-17.41,1.14-20.22-4.85-11.02-23.54c9.22-4.86,8.6,4.1,11.46,8.17
            c3.07,3.95,3.08,7.76-0.55,11.38C-102.99-268.87-102.95-267.54-102.99-266.21z"
            />
            <path
              style="fill: #58693d"
              d="M-94.76-342.71c11.84-0.77,21.45,7.23,32.81,8.38c2.88,3.54,3.73,7.55,2.77,11.97
            C-73.94-324.07-86.06-330.38-94.76-342.71z"
            />
            <path
              style="fill: #57742a"
              d="M-101.97-334.48c10.35,9.91,10.22,20.78,8,32.09C-107.64-315.15-108.12-316.89-101.97-334.48z"
            />
            <path
              style="fill: #817f8d"
              d="M-103.11-270.19c0.18-3.8,0.36-7.59,0.55-11.38c7.05,0.49,7.87-10.13,15.46-8.67
            c-0.07,2.82-0.12,5.63-0.19,8.44c-7.6,2.23-5.04,7.16-4.19,12.63c1.07,6.84,3.61,14.53-3.16,20.4
            C-96.47-256.3-89.91-267.16-103.11-270.19z"
            />
            <path
              style="fill: #718b2e"
              d="M-134.69-299.2c1.18-4.22,0.42-7.15-4.74-7.17c3.12-5.42,3.69-12.37,11.75-18.12
            C-129.74-314.37-112.91-302.75-134.69-299.2z"
            />
            <path
              style="fill: #a9bab3"
              d="M-67.12-306.06c-1.5,6.65-2.99,13.31-4.5,20.03c-9.38-2.96-4.2-12.97-10.45-16.45
            c-0.42-1.28-0.51-2.58-0.27-3.91C-77.18-310.63-72.07-311.76-67.12-306.06z"
            />
            <path
              style="fill: #58693d"
              d="M-67.12-306.06c-5.08-0.11-10.15-0.22-15.23-0.33c3.78-15.71,10.82-15.28,20.04-4.06
            C-63.92-308.99-65.52-307.52-67.12-306.06z"
            />
            <path
              style="fill: #5c6b49"
              d="M-53.75-310.7c-1.87-0.23-3.73-0.45-5.61-0.68c0.26-2.28,0.52-4.55,0.79-6.82
            c7-0.51,8.06-11.86,16.8-9.21c1.12,4.47,5.65,9.07-0.49,13.27C-46.09-313-49.92-311.85-53.75-310.7z"
            />
            <path
              style="fill: #6f8985"
              d="M14.93-124.22c-6.54-12.77-5.91-21.43,11.65-20.4c1.92,8.39,14.33,16.32,1.09,25.39
            C23.4-120.89,19.17-122.56,14.93-124.22z"
            />
            <path
              style="fill: #3e5a38"
              d="M-22.18-314.32c2.77-1.44,5.54-2.88,8.31-4.32c11.38,2.68,6.04,9.96,4.45,16.21
            c-0.21,1.86-0.43,3.71-0.64,5.58C-31.17-290.81-23.33-304.89-22.18-314.32z"
            />
            <path
              style="fill: #8fa766"
              d="M-17.77-330.8c-1.36-4.09-2.72-8.18-4.07-12.27c25.83-9.76,12.83,11.81,16.22,20.15
            C-13.07-320.29-13.66-328.26-17.77-330.8z"
            />
            <path
              style="fill: #657c46"
              d="M-17.77-330.8c5.71,0.05,7.99,5.43,12.15,7.88c-0.8,6.92,6.31,15.31-3.81,20.49
            c0.09-5.84,0.23-11.68-4.45-16.21C-15.35-322.63-21.44-325.15-17.77-330.8z"
            />
            <path
              style="fill: #3e5723"
              d="M-171.77-75.2c6.01,0.02,12.05-0.03,16.77,4.66c-9.37,7.28-3.17,19.29-8.45,27.81
            c-3.65-4.61-6.33-10.21-12.39-12.42c1.21-3.67,3.57-7.18-0.47-10.85C-185.08-73.98-175.56-73.25-171.77-75.2z"
            />
            <path
              style="fill: #98ba20"
              d="M-179.67-107.14c3.74,9.08-4.32,18.16,0,27.24c-4.15-1.04-8.31-2.09-12.46-3.13
            c-1.3,1.42-2.57,1.39-3.79-0.1C-196.62-95.28-202.8-111.16-179.67-107.14z"
            />
            <path
              style="fill: #638631"
              d="M-159.92-131.84c-10.69-0.1-21.38-0.21-32.07-0.31c4.03-4.09,8.06-8.19,12.09-12.28
            c0.15-3.71,0.3-7.42,0.45-11.13c7.33-7.26,13.62-2.61,18.63,2.63C-154.81-146.67-152.85-139.18-159.92-131.84z"
            />
            <path
              style="fill: #678252"
              d="M-228.74-127.35c0.16-1.04,0.32-2.08,0.47-3.12c8.46-10.4-2.85-16.27-6.87-25.55
            c15.3-0.47,16.49,10.39,19.4,19.53c-0.07,2.2-0.15,4.4-0.23,6.6c-0.26,5.02-3.11,6.89-7.85,6.47
            C-225.46-124.72-227.1-126.03-228.74-127.35z"
            />
            <path
              style="fill: #99b47a"
              d="M-223.82-123.41c2.62-2.15,5.24-4.31,7.85-6.47c6.86,6.66,2,15.03,3.27,22.5
            C-225.57-106.35-222.78-116.21-223.82-123.41z"
            />
            <path
              style="fill: #36491e"
              d="M-195.92-83.13c1.27-0.25,2.54-0.22,3.79,0.1c3.59,17.27-9.75,14.29-18.89,16.47
            C-207.34-73.51-199.14-75.95-195.92-83.13z"
            />
            <path
              style="fill: #364620"
              d="M-196.14-35c4.09-0.94,9.02-4.84,10.99,2.51c1.15,4.29-0.78,7.7-4.83,9.51
            c-3.61,1.61-8.01,3.28-10.78-0.37c-4.06-5.36,2.57-7.68,4.4-11.4C-196.36-34.75-196.14-35-196.14-35z"
            />
            <path
              style="fill: #79a72a"
              d="M-313.39-119.23c3.48-0.69,7.14-1.02,9.01-4.77c3.83-0.96,6.03,0.7,7,4.35
            c2.42,4.5,4.84,9,7.46,13.87C-314.84-113.48-314.84-113.48-313.39-119.23z"
            />
            <path
              style="fill: #a0b91c"
              d="M-297.39-119.65c-2.33-1.45-4.66-2.9-7-4.35c-0.86-3.61-0.7-6.9,2.82-9.16
            c2.47-1.61,4.88-3.37,8.03-3.37C-280.61-127.65-285.82-122.92-297.39-119.65z"
            />
            <path
              style="fill: #3b471d"
              d="M-293.53-136.53c-2.68,1.12-5.35,2.25-8.03,3.37c1.24-6.7,2.47-13.4,3.75-20.36
            c6.88,2.14,8.59,8.37,12.52,12.4C-288.04-139.59-290.79-138.06-293.53-136.53z"
            />
            <path
              style="fill: #3d5325"
              d="M-159.92-131.84c-1.58-11.98-5.87-21.72-19.53-23.72c6.1-13.63-8.14-14.47-13.22-21.07
            c-5.3-2.64-5.26-3.05,0.81-8.21c1.3-0.22,2.64-0.76,3.9-0.61c17.64,2.15,17.64,2.17,28.27-7.26c2.85,0.86,4.66,2.83,5.81,5.49
            c2.73,8.96,9.21,8.4,15.65,5.4c8.41-3.92,15.18-1.44,21.73,3.78c2.16,1.68,4.07,3.64,6.06,5.53
            c6.87,4.69,15.48,7.73,16.75,17.74c0.13,4.21-1.1,7.77-4.85,10.1c-2.79,0.87-1.55,1.7-6.84,9.71
            c-4.32,6.53-11.28,2.38-17.02-1.01c-5.42-3.2-11.22-12.4-16.88-0.1C-146.17-134.66-153.05-133.25-159.92-131.84z"
            />
            <path
              style="fill: #29381c"
              d="M-159.51-184.68c-0.06-2.68-0.13-5.36-0.19-8.04c-2.82-4.49-5.64-8.99-8.45-13.48
            c-3.06-6.62-1.22-12.59,2.38-18.36c3.01-3.62,6.85-5.89,11.41-6.87c7.92-2.34,2.45-7.69,2.5-11.82
            c1.06-14.77,7.11-24.37,23.74-22.91c1.26,6.94,5.9,11.96,9.84,17.38c-5.45,10.71,2.74,18.43,5.96,27.25
            c0.88,17.59-16,23.81-23.15,36.29C-143.34-179.48-151.35-178.47-159.51-184.68z"
            />
            <path
              style="fill: #496828"
              d="M-212.2-241.17c3.79,13.82,19.75,18.52,23.95,32.03c6.52-3.16,13.24-7.34,17.04,3.35
            c-0.67,0.06-1.48-0.11-1.98,0.21c-22.39,14.37-33.86-3.85-47.39-15.88c-4.61-3.09-6.88-7.37-6.56-12.97
            C-224.62-242.15-218.76-242.43-212.2-241.17z"
            />
            <path
              style="fill: #4b6426"
              d="M-252.79-188.88c2.56-5.31,5.12-10.63,7.68-15.94c8.59-2.32,16.69-3.3,20.49,7.5
            c1.86,5.47,3.91,10.88,5.39,16.45c0.26,0.99-1.85,2.61-2.86,3.94c-0.84-1.64-1.69-3.28-2.53-4.91c-5.92-2.71-9.12,0.87-12,5.19
            C-244.83-177-244.6-188.52-252.79-188.88z"
            />
            <path
              style="fill: #7cad34"
              d="M-224.63-197.32c-5.86-5.14-15.85,1-20.49-7.5c-1.31-4.55-0.53-7.99,4.82-8.97
            c2.77-8.86,0.78-21.45,16.05-20.53c4.83,3.25,3.47,8.28,3.68,12.85c-3,1.99-3.49,5.02-3.42,8.28
            c-0.17,4.19,3.19,8.25,0.47,12.54C-223.96-199.56-224.32-198.45-224.63-197.32z"
            />
            <path
              style="fill: #7ca232"
              d="M-171.2-205.79c-5.11-4.02-11.32-2.46-17.04-3.35c1.88-3.72,5.4-6.91,3.83-11.76
            c-1.21-6.08,2.82-7.82,7.47-9.05c6.34-0.06,10.85,2.69,13.25,8.67c4.98,6.94-1.84,10.4-4.46,15.09
            C-169.15-205.91-170.17-205.77-171.2-205.79z"
            />
            <path
              style="fill: #72a22d"
              d="M-191.87-184.84c0.05,2.77-3.51,5.18-0.8,8.21c-2.04,5.12-4.32,11.06-10.89,9.84
            c-5.5-1.02-2.31-6.95-2.59-10.33C-207.15-189.38-196.73-182.29-191.87-184.84z"
            />
            <path
              style="fill: #496828"
              d="M-223.53-200.64c-1.88-4.11-5.47-8.17-0.47-12.54c7.26,3.38,14.64,6.69,13.41,18.12
            C-215.48-197.17-219.5-198.91-223.53-200.64z"
            />
            <path
              style="fill: #769d32"
              d="M-236.63-176.65c-1.37-4.14-6.31-9.72-1.29-12.2c4.58-2.26,11.78-0.91,13.29,7.01
            C-228.63-180.11-232.63-178.38-236.63-176.65z"
            />
            <path
              style="fill: #31481f"
              d="M-224.25-234.31c-6.33,6.07-5.04,18.12-16.05,20.53c-4.11-1.25-8.21-2.49-12.32-3.74
            c-19.83-20.14,3.41-25.4,12.28-35.63c3.52-6.81,8.56-8.17,15.09-4.26c1.16,4.18,3.74,6.47,8.23,6.24
            c1.6,3.33,3.21,6.66,4.81,9.99C-216.22-238.89-220.23-236.6-224.25-234.31z"
            />
            <path
              style="fill: #7b9889"
              d="M-98.55-144.67c0.07-4.38,0.14-8.77,0.22-13.15c0.08-4.38,3.12-4.43,6.29-4.3
            c7.88,6.21,19.69,0.22,26.87,8.68c4.54,8.7,8.99,17.4-5.42,21.24c-5.61-4.3-5.61-4.3-16.33,8.42
            C-90.8-130.75-94.67-137.71-98.55-144.67z"
            />
            <path
              style="fill: #5c7e55"
              d="M-70.59-132.2c6.34-6.72,3.32-13.17-0.55-19.6c0.27-7.65,4.66-11.33,11.83-12.35
            c2.09,0.05,4.05,0.63,5.85,1.68c1.54,1.27,2.71,2.85,3.61,4.63c3.36,9.39,1.19,17.96-4.07,26.04
            c-1.21,15.53-16.19,13.72-24.9,19.8C-78.62-119.78-78.12-127.42-70.59-132.2z"
            />
            <path
              style="fill: #a1b7a7"
              d="M-53.92-131.8c-5.01-8.34-0.25-16.85-0.42-25.28c8.89-6.67,12.04,7.64,19.72,5.41
            c1.47-7.59,8.43-11.2,12.33-17.04c-0.02,8.82,3.12,18.09-3.6,25.98c-4.95,4.46-10.37,4.43-16.07,1.67
            C-45.96-137.97-49.94-134.89-53.92-131.8z"
            />
            <path
              style="fill: #6d8751"
              d="M-41.98-141.06c4.94-4.52,10.62-2.02,16.07-1.68c2.19,5.55,5.25,5.03,8.77,1.26
            c5.78,6.19,1.51,11.68-1.2,17.28c-6.48,0.21-12.96,0.43-19.43,0.64C-43.28-128.4-41-135.12-41.98-141.06z"
            />
            <path
              style="fill: #14170f"
              d="M-128.11-266.16c-5.48,8.93-17.95,12.38-19.25,24.6c1.45,3.29-0.43,5.13-2.91,6.71
            c-7.57,3.12-13.62-5.25-21.11-2.8c-1.8-0.47-3.37-1.36-4.74-2.61c-0.96-1.7-0.94-3.39,0.06-5.07
            c8.4-19.42,23.6-29.44,44.16-32.08C-130.65-273.66-129.38-269.91-128.11-266.16z"
            />
            <path
              style="fill: #62802f"
              d="M-176.07-245.33c0.18,1.23,0.31,2.46,0.38,3.7c0.13,5.17,3.85,10.22,0.49,15.5
            c-3.72,0.59-7.19,1.63-9.21,5.22C-189.71-242.46-189.12-244.19-176.07-245.33z"
            />
            <path
              style="fill: #6f9a31"
              d="M-225.24-257.41c-5.03,1.42-10.06,2.83-15.09,4.25c-8.34-1.04-13.8-5.69-16.81-13.43
            c0.81-6.79-6.1-9.76-7.6-15.41c3.66-5.52,7.33-11.05,10.99-16.57c2.26,5.13-1.92,11.96,5.48,17.47
            c1.21-9.24,2.27-17.31,3.33-25.39c15.82,3.33,7.61,19.87,15.21,27.73C-231.75-270.9-216.86-266.61-225.24-257.41z"
            />
            <path
              style="fill: #577e2d"
              d="M-139.62-189.23c3.64-15.39,17.15-22.42,27.3-32.3c3.29,5.11,8.4,4.18,13.28,4.01
            c-3.81,8.92-8.11,18.08,8.46,16.57c-2.71,4.02-5.43,8.04-8.14,12.05c-8.04,4.96-15.76,11.02-26.23,7.79
            C-130.23-183.13-137.4-181.71-139.62-189.23z"
            />
            <path
              style="fill: #b7c2ac"
              d="M-94.99-160.22c-1.12,0.8-2.24,1.6-3.35,2.4c-5.52-5.24-17.33-1.83-18.3-13.34
            c-0.22-15.42,14.77-8.56,21.08-14.42c2.83,0.02,5.66,0.05,8.48,0.07c2.97,6.79,5.94,13.58,8.91,20.37
            C-82-157.42-88.34-158.29-94.99-160.22z"
            />
            <path
              style="fill: #6c8c2f"
              d="M-94.99-160.22c5.61-1.64,11.21-3.28,16.82-4.91c2.48-1.19,4.95-2.38,7.43-3.57
            c2.89-4.87,6.74-6.65,11.32-2.52c3.17,2.87,4.76,6.5-0.01,9.73c-3.9,3.23-7.81,6.46-11.72,9.69
            C-79.55-153.3-89.76-149.7-94.99-160.22z"
            />
            <path
              style="fill: #aebdca"
              d="M-59.42-161.49c0.72-9.46-6.48-6.46-11.31-7.21c-2.93-6.28,0.85-10.61,4.38-15
            c6.95-6.93,14.05-2.95,21.12-0.29c10.73,13.45,0.8,19.12-9.29,24.74C-56.15-160-57.79-160.75-59.42-161.49z"
            />
            <path
              style="fill: #85ac6a"
              d="M-47.06-179.83c-5.44-6.24-12.35-5.13-19.29-3.88c-0.9-8.62,9.24-4.8,11.32-10.06
            c6.04,6.41,14.29,4.49,21.7,5.76c-1.46,1.14-2.93,2.28-4.4,3.41C-39.97-181.31-43.32-180.19-47.06-179.83z"
            />
            <path
              style="fill: #a0bd68"
              d="M-95.56-185.58c2.51,18.71-17.37,4.81-21.07,14.45c-0.83-1.24-1.66-2.45-2.49-3.66
            c-2.38-2.55-5.43-4.88-3.03-9.04c7.13-4.81,13.46-13.37,23.43-5.09C-97.67-187.8-96.61-186.69-95.56-185.58z"
            />
            <path
              style="fill: #85a256"
              d="M-47.06-179.83c3.11-1.59,6.22-3.18,9.33-4.77c4.03,5.77,2.35,11.13-1.16,16.36
            c-4.8,7.14-2.04,12.32,4.27,16.58c-9.33,8.25-12.64-5.47-19.73-5.4c-0.14-0.72-0.19-1.45-0.17-2.18
            C-52.22-166.19-42.37-170.37-47.06-179.83z"
            />
            <path
              style="fill: #77a32d"
              d="M-122.15-183.82c1.01,3.02,2.02,6.04,3.03,9.05c-5.39-0.35-9.42-3.98-16.46,0.34
            c-9.18,5.63-22.44,8.17-23.93-10.25c6.63-1.52,13.26-3.03,19.89-4.55C-133.8-187.43-127.97-185.62-122.15-183.82z"
            />
            <path
              style="fill: #35491f"
              d="M-175.2-226.13c-8.41-4.91-10.85-10-0.49-15.5c1.24,0.01,2.48-0.02,3.72-0.09
            c6.01,2.23,12.43,3.43,17.92,7.01c2.95,2.74,5.18,5.62,1.81,9.56c-3.82,1.29-7.63,2.58-11.45,3.86
            C-168.14-221.43-172.11-222.74-175.2-226.13z"
            />
            <path
              style="fill: #5b812b"
              d="M-152.24-225.15c-1.26-2.55-2.52-5.1-3.78-7.65c0.21-2.9,2.75-3.58,4.65-4.86
            c1.34-1.3,2.67-2.6,4.01-3.91c9.75,0.54,15.03,3.56,7.11,13.39C-143.38-224.31-144.46-210.96-152.24-225.15z"
            />
            <path
              style="fill: #78a32b"
              d="M-151.38-237.66c-1.55,1.62-3.1,3.24-4.65,4.86c-6.72-0.45-16.97,5.39-15.95-8.92
            C-163.07-250.71-157.8-241.28-151.38-237.66z"
            />
          </g>
        </g>
      </g>
      <g id="XMLID_2422_">
        <defs>
          <path
            id="XMLID_945_"
            d="M112.84,29.74c0.26-0.44,0.5-0.9,0.77-1.33c2.33-3.76,5.57-6.55,9.37-8.74c4.1-2.35,8.51-3.73,13.2-4.24
          c1.11-0.12,2.22-0.18,3.35-0.08c-0.57,0.12-1.14,0.23-1.7,0.36c-7.77,1.76-14.22,5.68-19.32,11.82
          c-1.29,1.56-2.36,3.27-3.27,5.08c-0.29,0.57-0.23,0.69,0.34,0.86c4.2,1.24,8.46,1.47,12.74,0.58
          c6.61-1.37,11.92-4.74,15.71-10.41c1.84-2.76,3.47-5.65,5.5-8.28c0.35-0.46,0.7-0.92,1.09-1.35c0.34-0.37,0.27-0.58-0.18-0.72
          c-1.27-0.4-2.54-0.84-3.83-1.21c-2.91-0.83-5.87-1.37-8.9-1.54c-5.03-0.29-9.92,0.32-14.46,2.63
          c-6.14,3.13-9.75,8.09-10.51,15.02C112.7,28.68,112.59,29.19,112.84,29.74z"
          />
        </defs>
        <clipPath id="XMLID_00000094613220840479957260000009380719477722484414_">
          <use xlink:href="#XMLID_945_" style="overflow: visible" />
        </clipPath>
        <g id="XMLID_7_" style="clip-path: url(#XMLID_00000094613220840479957260000009380719477722484414_)">
          <g>
            <path
              style="fill: #181c13"
              d="M-159.57,349.74c-45.56,0-91.11-0.13-136.67,0.14c-6.53,0.04-9.23-0.75-8.93-8.41
            c0.72-18.44,0.23-36.92,0.23-55.39c8.44-3.73,10.99,0.37,10.8,8.11c-1.59,7.32,2.37,11.01,8.39,8.02
            c9.84-4.89,17.93-1.96,26.39,2.33c2.38,0.74,4.77,0.74,7.18,0c6.71-1.84,9.55,2.36,11.79,7.51c3.03,3.86,7.05,4.77,11.67,3.91
            c6.54,2.44,9.12-0.31,8.89-6.76c-0.22-6.45-0.13-12.82,4.61-17.99c0.77-1.35,1.51-2.71,2.66-3.78c4.98-2.77,6.78-6.73,4.49-12.2
            c-0.12-1.02-0.13-2.03-0.07-3.05c0.07-1.01,0.32-1.99,0.68-2.93c2.13-2.28,1.9-5.39,2.77-8.12c0.84-2.43,0.82-4.95,0.79-7.48
            c2.42-5.86-4.33-14.63,4.24-18.04c7.51-2.98,11.42,5.53,17.21,8.38c3.24,1.6,6.07,4.24,8.41,7.2c0.64,1.57,0.67,3.15,0.09,4.74
            c-5.3,3.35-3.2,9.51-5.31,14.08c-0.23,1.59-1.9,2.9-0.89,4.73c0.29-2.13,0.89-4.19,1.4-6.27c1.72-3.23-0.38-7.58,3.03-10.31
            c6.23-1.55,10.1,2.32,10.79,7.19c1.79,12.61,12.4,22.91,9.29,36.68c-0.34,1.51,0.33,3.67,1.85,3.65
            c7.32-0.07,14.43,4.94,21.92,0.78c9.78-5.43,9.69-13-0.47-24.99c0.48-8.12-5.46-17.07,3.02-24.19c3.24-1.45,6.08,0.05,8.97,1.23
            c2.11,1.28,3.42,3.22,4.43,5.4c0.11,4.82,0.95,9.47,3.43,13.7c4.72,6.04,3.18,12.81,2.27,19.5c1.88,5.34,1.65,10.16-2.9,14.28
            c-3.17,2.87-5.14,6.4-4.11,10.95c0.75,4.27,4.27,8.05,2.22,12.8c-2.44,3.9-5.82,5.47-10.35,4.13c-1.54-1.19-1.6-3.22-2.61-4.73
            c-0.41-0.61-0.73-1.27-1.05-1.93c0.2,0.78,0.81,1.41,0.97,2.17c1.74,8.47-4.33,6.52-8.87,6.63c-5.85-1.67-10.43-5.72-16.42-9.59
            C-154.76,338.64-153.12,345.86-159.57,349.74z"
            />
            <path
              style="fill: #1b2115"
              d="M69.83,349.74c-24.98,0-49.97,0-74.95,0c-2.05-4.94-8.44-3.82-11.1-7.91
            c-5.39-8.73-1.93-14.63,5.99-19.04c-0.45-1.21-1.49-1.29-2.56-1.39c-15.29-0.61-28.9-13.76-29.72-28.67
            c-0.03-6.9-7.65-10.24-7.96-17.01c-3.35-6.87-4.04-12.76,5.45-14.93c4.41,0.01,7.15,3.2,10.36,5.47
            c3.77,1.9,4.08,5.62,5.55,9.09c2.32,5.49,4.05,1.23,5.34-1.44c1.33-2.28,3.6-3.72,5.15-5.8c3.06-1.58,4.01-4.66,5.3-7.5
            c0.77-0.81,1.68-1.41,2.72-1.82c13.15,2.1,17.02,8.52,11.81,20.38c-0.91,2.07-1.59,3.98-1.7,6.04c3.4,0.88,5.92-3.66,9.43-1.5
            c4.98,2.31,4.81,8.18,8.09,11.58c0.02-0.11,0.62,0.75,0.62,0.75s0.83-0.1,0.77-0.25c-7.97-10.83-10.16-24.26-16.93-35.53
            c-1.34-2.22-0.14-5.18,1.9-7.26c2.99-2.27,6.19-2.13,9.49-0.87c6.17,1.78,12.57-0.55,14.88-5.03c2.3-4.48-3.02-9.41-6.02-13.69
            c-1.42-2.89-0.86-5.43,1.32-7.68c0.87-0.69,1.85-1.18,2.91-1.51c5.86-0.11,11.42,1.09,16.58,3.95
            c12.23,1.93,13.31,3.24,13.65,16.63c2.16,5.9,1.12,11.38-2.16,16.56c-1.29,2.64-1.97,5.35-0.91,8.26
            c0.61,2.86,2.06,5.12,4.69,6.53c10.66,3.91,14.1,11.65,11.92,22.4c-2.88,5.18-5.36,10.11-2.53,16.52
            c2.09,4.73,1.21,9.53-4.69,11.55c0.15,0.06-0.78,0.69-0.6,0.75c-0.22,2.67,1.9,4.47,2.4,6.79c1.44,3.28,4.28,5.46,6.37,8.24
            c0.8,1.45,1.09,2.99,0.84,4.63C71.15,348.06,70.59,348.96,69.83,349.74z"
            />
            <path
              style="fill: #202817"
              d="M-304.93,213.32c0-7.58,0-15.16,0-22.74c10.6-2.29,14.86-8.75,12.74-19.43
            c1.54-13.52,7.77-16.51,18.49-8.94c2.04,3.09,5.2,5.56,5.53,9.51c0.2,0.33,0.51,0.69,1.01,0.71c3.07-3.23,6.43-5.78,10.47-7.38
            c1.72-0.36,3.4-0.22,5.04,0.41c6.69,2.82,14.65,3.84,17.4,12.24c3.47,14,10.31,25.82,22.21,34.4c2.17,1.56,3.39,3.9,4.58,6.24
            c0.42,1,0.63,2.04,0.69,3.12c-0.76,7.26-5.35,11.97-11.75,13.7c-7.9,2.13-9.01,6.39-7.17,13.37c0.99,3.78,2.61,8.24-2.17,11.15
            c-4.26,0.79-7.91-1.19-9.18-4.66c-3.95-10.85-11.24-18.27-21.33-23.33c-0.87-7.35,2.58-11.39,9.64-12.67
            c8.22-3.52,5.42-8.08,1.17-12.71c-2.5-0.24-4.59,0.88-6.71,1.9c-1.8,1.06-3.61,2.06-5.8,1.96c-8.42-1.56-16.62-1.17-24.39,2.86
            C-291.25,214.27-298.03,218.7-304.93,213.32z"
            />
            <path
              style="fill: #313f1c"
              d="M-123.23-159.54c9.08,0,18.17,0,27.25,0c2,4.53,5.8,4.99,10.02,4.4
            c5.34-0.75,8.12,2.07,9.64,6.71c1.26,1.77,3.03,1.92,4.98,1.54c6.22-0.62,9.34-5.8,13.66-9.22c0.97-0.46,1.98-0.75,3.03-0.93
            c7.66,5.98,15.24,2.04,22.85-0.17c3.78-0.06,7.73-0.18,9.07,4.4c1.24,4.23-1.34,7.45-4.43,9.34c-6.23,3.81-9.8,9.92-14.46,15.08
            c-0.89,0.68-1.88,1.17-2.95,1.45c-7.16,0.17-14.08-3.46-21.38-1.31c-2.08,0.49-4.04,0-5.94-0.81c-1.85-0.8-3.66-1.69-5.65-2.11
            c-5.57-2.32-10.98-5.08-16.98-6.22c-3.01-0.21-6.79-1.22-8.67,1.16c-7.64,9.68-19.62,15.45-24.54,27.52
            c-0.78,0.86-1.69,1.51-2.74,1.98c-4.57,0.78-9.13,1.05-13.67-0.18c-3.03-1.15-4.54-5.84-8.94-3.8
            c-5.65,2.68-9.17,8.27-14.86,10.89c-5.67,3.13-9.9,0.18-14.02-3.28c-1.39-1.87-2.39-3.96-3.31-6.08
            c-0.56-3.96-2.95-7.6-2.07-11.82c0.48-1.71,1.42-3.11,2.8-4.22c3.08-1.06,5.72-2.87,8.12-5.04c12-5.16,12-5.16,9.77-21.21
            c1.12-1.32,2.59-1.98,4.27-2.24c1.64-0.16,3.28-0.24,4.92-0.41c4.62-1.51,9.54-0.48,14.2-1.72
            C-137.12-159.94-128.87-152.65-123.23-159.54z"
            />
            <path
              style="fill: #242c19"
              d="M69.83,349.74c-0.02-0.74-0.02-1.47-0.01-2.21c3.77-9.39,11.91-11.71,20.78-12.86
            c0.24-0.03,0.66-0.03,1.1-0.27c2.02-1.11-0.42-1.38-0.48-2.17c-5.37-10.59,4.93-15.3,8.3-22.61c0.77-1.41,1.84-2.55,3.18-3.43
            c0.93-0.45,1.91-0.79,2.92-1.05c4.83-1.37,9.44-5.56,15.22,0.28c-1.81-5.71-6.49-3.73-8.92-5.97c-3.39-3.21-1.66-5.91,0.81-8.55
            c11.79-10.66,12.02-10.62,23.94,4.2c5.48,5.99,8.85,13.93,16.5,17.94c4.81,2.74,7.88,7.13,10.96,11.52
            c4.2,4.95,1.35,8.66-2.01,12.22c-0.21,4.1,2.6,1.45,3.49,1.22c2.92-0.75,5.7-2.03,8.39,0.52c3.05,3.66,2.85,7.41,0.33,11.23
            c-12.87,0-25.74,0-38.61,0c-4.35-1.67-9.8-1.26-12.22-6.5c-5.32-5.33-2.24,3.2-4.56,2.82c-2.79,3.24-7.31,1.52-10.48,3.69
            C95.57,349.74,82.7,349.74,69.83,349.74z"
            />
            <path
              style="fill: #27331b"
              d="M-286.86,208.43c8.93-3.85,18.09-4.37,27.45-1.86c2.52,5.22-3.84,7.51-4.05,11.94
            c12.63-5.26,14.22-4.9,17.61,3.89c-6.49-0.34-9.21,4.05-11.39,9.11c-3.16,10.85-3.13,23.02-13.98,30.58
            c-3.28,2.29-5.25,6.55-5.46,10.83c-0.33,6.88-2.48,13.2-4.86,19.53c-1.55,4.12-1.45,9.55-6.87,10.69
            c-6.01,1.27-6.21-5.58-9.68-8c1.64-5.98-1.4-8.43-6.83-9.06c0-19.7,0-39.41,0-59.11c1.34-6.64,5.94-9,12.12-9.04
            C-285.71,217.88-284.95,214.03-286.86,208.43z"
            />
            <path
              style="fill: #2c3a1c"
              d="M-304.93,22.34c0-9.85,0-19.7,0-29.56c4.45-7.41,7.93-14.92,2.47-23.39
            c-0.24-3.88,1.22-6.71,4.95-8.17c4.02-0.68,7.86,0.77,11.79,1.06c1.03,0.17,2.03,0.51,2.96,0.99c2.21,1.52,4.25,4.22,7.2,1.09
            c3.98-4.59,6.07-10.34,9.66-15.16c11.28-15.12,15.66-15.61,28.95-3.07c3.91,1.62,7.05,4.67,11.16,5.9
            c5.85,5.7,1.12,11.22-0.26,16.8c-1.55,5.21,0.59,8.56,5.33,10.63c5.27,3.7,6.16,9.11,5.14,14.81
            c-1.05,5.89-5.75,9.04-10.46,5.74c-6.58-4.61-2.89,1.96-4.12,2.97c-3.12,5.93-7.82,5.68-13.21,3.62
            c-5-1.91-6.53-5.27-5.63-10.43c0.36-2.08-0.31-4.39-1.05-6.49c-1.84-4.02-1.08-8.43-1.58-12.07c0.92,2.98-0.7,6.86,1.13,10.31
            c2.19,11.86,1.59,12.43-10.5,9.97c-2.69-1.33-4.33-3.48-4.99-6.39c-1.58-2.7,2.66-7.68-3.44-8.49
            c-2.31,0.91-4.23,2.39-6.35,3.47c-2.4,0.89-3.7,3.23-5.78,4.55c-3.32,1.86-6.42,4.08-6.31,8.3c0.16,6.06-3.83,10.11-6.45,14.87
            C-295.89,19.47-297.81,24.33-304.93,22.34z"
            />
            <path
              style="fill: #2b3b1b"
              d="M203.83,131.47c0,15.16,0,30.31,0,45.47c-5.74,4.55-3.03,9.09,0,13.64c0,5.3,0,10.61,0,15.91
            c-7.41,1.17-6.62-9.22-13.13-9.31c-3.26-3.19-5.51-6.9-5.94-11.54c-4.57,6.19-8.93,3.71-12.35-0.9
            c-2.19-2.95-3.13-6.81-7.84-6.47c-2.49-0.39-4.82,0.44-7.2,0.96c-1.49,0.25-2.97,0.09-4.45-0.04c-5.11,0.33-4.43,3.6-3.94,7.18
            c0.84,6.15-1.13,10.94-7.46,13.14c-6.66-4.49-12.25,2.13-18.52,1.84c-7.95-6.43-9.29-14.09-4.25-22.93
            c1.94-5.7,7.87-8.42,10.25-13.79c3.73-4.71,8.54-6.17,14.27-4.77c12.33,1.63,4.34-7.68,6.22-11.68c0.84-2.79,2.59-4.76,5.2-5.99
            c6.83-0.29,10.03-9.17,17.7-7.58c3.3,2.51,6.87,4.63,10.36,6.88c2.66,3.7,0.87,9.16,4.66,11.95c-2.95-3.72-2.45-8-0.36-12.36
            c0.37-1.01,0.89-1.94,1.55-2.79C195.54,125.67,195.54,125.67,203.83,131.47z"
            />
            <path
              style="fill: #31451e"
              d="M203.83,113.29c0,3.03,0,6.06,0,9.09c-1.21,1.19-2.63,1.85-4.35,1.77
            c-7.45-6.27-15.84-1.97-23.85-2.09c-2.15,0.53-4.25,1.31-6.52,1.21c-2.48-0.05-4.59-0.9-6.25-2.78
            c-1.25-1.99-2.57-3.95-3.35-6.2c-1.34-2.69-1.93-5.42-0.39-8.25c2.83-4.03,8.52-2.87,11.71-6.65
            c-4.32-4.08-7.47-8.87-6.49-15.37c0.36-2.99,2.28-4.87,4.57-6.47c0.82-0.66,1.72-1.21,2.68-1.66c3.56-1.63,7.96-2.47,7.39-7.96
            c0.67-8.74,5.27-12.48,13.97-11.01c2.94,1.96,5.58,4.25,7.15,7.52c0.5,2.36,1.99,4.09,3.72,5.65c0,1.52,0,3.03,0,4.55
            c-2.52,2.27-2.84,4.55,0,6.82c0,9.09,0,18.19,0,27.28c-1.53,1.69-3.46,2.29-5.69,2.03
            C200.29,111.04,202.33,111.54,203.83,113.29z"
            />
            <path
              style="fill: #34461d"
              d="M-282.21-159.54c8.33,0,16.66,0,24.98,0c2.25,3.08,3.59,6.33,1.92,10.16
            c-2.58,3.11-3.63,6.68-3.14,10.72c-1.79,5.48,0.89,9.68,3.92,13.79c0.48,2.29,0.25,4.46-1.02,6.47
            c-6.66,4.83-14.27,5.73-22.19,5.34c-0.86-0.7-1.52-1.56-1.96-2.58c-0.88-3.17,0.75-6.63-1.07-9.81
            c-3.22,0.27-5.15,2.41-6.89,4.83c-2.21,3.89-5.13,6.78-9.94,4.93c-5.24-2.02-4.2-6.21-3.04-10.4c1.26-4.56-0.81-7.96-4.29-10.71
            c0-5.68,0.77-11.5-0.19-17.02c-1.26-7.22,3.25-5.61,7.01-5.72C-292.81-155.94-287.51-153.29-282.21-159.54z"
            />
            <path
              style="fill: #212517"
              d="M174.3-159.54c3.79,0,7.57,0,11.36,0c8.6,8.29,8.69,19.49,9.34,30.05
            c0.58,9.3,2.41,17.58,8.83,24.51c0,6.06,0,12.13,0,18.19c-9.24,6.32-16.98,2.49-24.39-3.6c-1.66-2.78-1.41-5.86-1.41-8.89
            c-3.72-11.1-0.71-23.15-4.79-34.22c-2.63-6.46,1.67-13.63-2.08-19.97C171.06-156.1,172.65-157.84,174.3-159.54z"
            />
            <path
              style="fill: #636b26"
              d="M-182.21-123.46c-0.96,0.84-1.91,1.68-2.87,2.52c-0.45,0.24-0.9,0.48-1.35,0.73
            c-1-0.19-1.88-0.65-2.64-1.33c-1.45-2.54-3.28-4.68-6.26-5.51c-4.68-3.14-9.51-6.16-11.1-12.14c-0.06-1.63,0.47-3.07,1.46-4.35
            c4.49-2.82,7.56-6.4,6.49-12.21c0.41-1.57,1.48-2.68,2.58-3.79c5.3,0,10.6,0,15.9,0c1.51,1.79,3.03,1.6,4.54,0
            c5.3,0,10.6,0,15.9,0c2.49,2.66,8.7,4.35,2.4,9.29c-1.72-0.12-3.44-0.25-5.15-0.37c-1.33-1.33-3.15-0.67-4.69-1.15
            c0.22,0.64,0.5,1.27,0.82,1.87c5.54,1.62,11.54,3.65,8.58,11.01c-3,7.46-8.13,13.3-17.68,11.32
            C-178.54-127.79-180.25-125.42-182.21-123.46z"
            />
            <path
              style="fill: #252a19"
              d="M203.83-84.51c0,25.01,0,50.02,0,75.03c-2.83,2.29-5.64,2.32-8.43-0.08
            c-5.85-18.75-10.01-37.91-12.98-57.36c-0.11-1.29-0.21-2.56-0.31-3.85c0.23-6.38-3.3-14.58,7.62-15.75
            C194.3-84.94,199.36-86.78,203.83-84.51z"
            />
            <path
              style="fill: #273019"
              d="M203.83,295.17c0,6.82,0,13.64,0,20.46c-0.4,5.89-12.52,7.57-5.72,16.04
            c0.89,1.46,1.28,3.04,1.21,4.75c-0.91,3.25-1.8,6.51-5.46,7.84c-7.95,1-9.29-7.11-14.21-10.31c-3.47-3.26-4.42-7.66-5.51-11.99
            c-2.51-5.88-11.68-3.55-13.19-10.66c0-10.03,4.86-15.19,15.04-14.97c3.69,0.36,6.81-2,10.4-2.2c2.16-0.11,4.3-0.27,6.45-0.54
            C196.8,292.07,200.64,291.4,203.83,295.17z"
            />
            <path
              style="fill: #64753a"
              d="M-304.93,22.34c4.47-2.27,7.56-5.45,7.21-10.91c1.81-2.29,3.77-1.61,5.76-0.29
            c3.43,11.64,13.42,9.06,21.79,10.02c2.47,1.89,3.28,4.33,2.42,7.32c-2.5,3.79-3.5,7.42-1.88,12.27
            c1.6,4.8,1.52,10.25-3.14,14.17c-7.47,2.01-8.17-7.27-13.8-8.24c-0.93-0.6-1.88-0.99-2.82,0.08
            c-1.18,6.62,5.99,11.23,4.09,17.91c-0.44,3.2-0.09,6.48-0.97,9.63c-8.13,6.36-8.68,6.18-15.72-5.45
            c0.66-6.28-0.44-12.43-1.38-18.6c-2.38-4.55,3.36-9.99-1.57-14.26C-304.93,31.44-304.93,26.89-304.93,22.34z"
            />
            <path
              style="fill: #262e18"
              d="M-43.58-130.56c2.54-7.25,5.74-13.88,13.56-17.05c1.87-0.76,4.69-2.21,4.08-4.74
            c-0.81-3.34-4.26-2.22-6.72-2.64c-1.82-1.01-3.42-2.11-1.99-4.54c6.06,0,12.11,0,18.17,0c1.44,1.45,2.95,2.86,3.19,5.07
            c0.17,3,0.46,5.99,0.47,8.99c-0.59,3.87,3.21,6.25,3.36,9.86c0.04,4.28,0.84,8.29,4.17,11.38c0.51,1.01,0.78,2.07,0.89,3.19
            c0.64,6.08-3.83,10.26-5.67,15.42c-8.75,3.56-12.29,1.98-14.74-6.53c-1.07-3.71,0.8-8.74-4.74-10.4
            C-34.01-125.6-42.09-122.33-43.58-130.56z"
            />
            <path
              style="fill: #424825"
              d="M203.83-104.98c-9.69-1.82-14.55-7.38-13.63-17.51c1.16-12.67-4.78-24.49-4.54-37.06
            c18.17,0,18.17,0,18.17,18.19C203.83-129.23,203.83-117.1,203.83-104.98z"
            />
            <path
              style="fill: #3e5723"
              d="M-304.93-41.32c0-6.82,0-13.64,0-20.46c0.71-0.72,1.49-1.37,2.32-1.96
            c7.42,1.03,11.81-4.2,16.8-8.18c7.27-2.79,3.23-10.06,5.79-14.72c0.62-0.97,1.41-1.75,2.34-2.41
            c11.73-2.48,15.12-0.78,20.31,10.1c0.73,5.37-5.48,7.59-5.68,12.56c-2.16,5.16-7.32,8.78-7.52,14.94
            c-2.01,6.42-6.01,9.94-13.05,9.02C-290.77-43.16-297.83-41.98-304.93-41.32z"
            />
            <path
              style="fill: #1f2416"
              d="M-13.89,340.64c0.34,0.12,0.74,0.17,1.02,0.38c3.73,2.69,5.79-6.2,9.11-1.67
            c2.29,3.14,0.1,7.02-1.36,10.38c-16.66,0-33.31,0-49.97,0c-1.1-10.04,3.27-17.55,10.88-23.58c3.96-2.21,8.45-1.03,12.61-2.02
            C-20.6,324.15-18.62,333.88-13.89,340.64z"
            />
            <path
              style="fill: #3c5322"
              d="M-304.93-73.15c0-11.37,0-22.74,0-34.1c3.97-4.77,8.85-5.59,14.42-3.3
            c6.24,1.55,6.75,8.48,11.12,11.81c2.83,3.57,4.23,7.38,1.8,11.76l-0.37,0.61c-2.76,5.07-1.45,13.02-9.92,14.03
            c-3.86,0.13-7.48,1.51-11.25,2.14C-301.61-70.12-303.3-71.58-304.93-73.15z"
            />
            <path
              style="fill: #3c4c2b"
              d="M-275.49,165.77c-2.63-2.8-5.24-5.61-9.57-3.02c-4.08,2.44-4.48,5.62-1.97,9.43
            c-4.27,13.58-7.64,16.19-17.9,13.86c0-3.03,0-6.06,0-9.09c2.27-1.9,3.31-4.5,4.05-7.25c0.89-2.83-0.42-5.74,0.27-8.59
            c0.34-1.57,1.09-2.92,2.19-4.08c3.73-2.76,7.74-5.19,10.78-8.81c6.95-4.84,8.52-15.86,19.01-16.64c1.11,0.08,2.16,0.4,3.15,0.9
            c0.94,0.64,2.17,1.39,2.31,2.39c1.37,10.1,0.66,20.3,1.28,30.46C-266.29,169.39-270.94,166.05-275.49,165.77z"
            />
            <path
              style="fill: #bccf43"
              d="M192.49,59.14c-2.85,3.02-11.92-0.24-8.84,8.77c-2.38,5.3-8.26,7-11.6,11.32l-0.03,0.09
            c-9.9,2.12-11.85-3.59-11.47-11.6c0.14-1.01,0.49-1.98,0.98-2.87c0.9-1.39,2.46-2.19,3.31-3.59c-0.16-0.77-0.26-1.48-0.53-2.22
            c-1.12-2.48-0.38-4.88,0.08-7.31c1.23-4.77,5.28-5.96,9.2-7.29c2.76-0.58,5.57-0.45,8.36-0.47c5.07,1.67,8.43-3.41,13.11-3.36
            c4.54,0.14,5.91,4.4,8.75,6.75c0,1.52,0,3.03,0,4.55C200.39,54.84,199.26,61.41,192.49,59.14z"
            />
            <path
              style="fill: #95a664"
              d="M24.4-159.54c3.03,0,6.06,0,9.08,0c2.12,3.67,6.19,5.13,9.07,7.98c1.46,1.8,2.33,3.84,2.5,6.16
            c-0.31,2.92-1.01,5.71-2.91,8.06c-4.41,3.95-9.48,6.25-15.51,6.12c-4.74-1.06-8.19,4.7-13.16,2.51
            c-3.66-2.21-4.47-5.55-3.76-9.48c0.22-2.36,1.14-4.47,2.35-6.46C14.67-150.84,16.02-158.12,24.4-159.54z"
            />
            <path
              style="fill: #4b5a2a"
              d="M-300.42,67.96c2.03,1.8,8.72-1.79,6.02,5.94c-0.94,2.69,3.09,1.37,5.03,0.83
            c3-0.32,5.22,1.05,7.07,3.25c0.91,1.41,1.13,3.02,1.3,4.64c0.74,4.68,3.62,9.29,0.56,14.15c-2.12,2.89-4.86,4.57-8.56,4.37
            c-5.07-3.2-12.82-1.32-15.92-8.32c0-10.61,0-21.22,0-31.83C-299.6,60.84-299.82,64.28-300.42,67.96z"
            />
            <path
              style="fill: #131510"
              d="M-159.57,349.74c-0.09-4.34,1.63-8.43-0.68-13.1c-2.34-4.75-0.02-9.76,4.77-12.33
            c4.7-2.52,6.25,2.1,8.72,4.73c3.06,3.26,6.52,6.13,9.81,9.17c0.2,0.87,0.44,1.73,0.75,2.58c0.13,0.43,0.25,0.87,0.39,1.29
            c0.54,2.53,3.35,4.7,1.21,7.65C-142.91,349.74-151.24,349.74-159.57,349.74z"
            />
            <path
              style="fill: #bec587"
              d="M119.79-159.54c1.51,0,3.03,0,4.54,0c2.9,2.92,5.77,5.87,8.95,8.5c2.63,2.59,6.16,4.52,6.4,8.84
            c-1.87,4.83-3.19,9.92-6.18,14.29c-4.98,2.32-5.66,9.1-11.21,10.86c-1.16,0.17-2.28,0.03-3.37-0.39c-2-1.32-3.65-2.96-4.76-5.11
            c-0.9-2.28-2.07-4.46-2.23-6.97C108.46-141.13,115.8-149.9,119.79-159.54z"
            />
            <path
              style="fill: #27331a"
              d="M-288.72-107.08c-5.4-0.06-10.8-0.11-16.2-0.17c0-9.85,0-19.7,0-29.56
            c6.7,0.59,15.03-0.29,9.02,10.98c-1.04,1.95-4.35,5.02-1.25,6.67c4.63,2.46,4.66-3.03,6.49-5.33
            c7.23-1.72,11.46,1.22,12.99,8.37l-0.09,0.97c0.95,6.37-5.42,5.82-8.18,8.69C-286.95-106.29-287.88-106.5-288.72-107.08z"
            />
            <path
              style="fill: #528553"
              d="M-125.43,338.79c2.97-1.74,5.94-3.48,8.91-5.23c6.6-5.04,13.38-4.33,20.24-0.66
            c0.43,3.41-0.98,7.13,5.53,7.25c5.23,0.1,10.81,2.15,16.03,4.04c2.5,0.91,5,2,3.74,5.54c-12.87,0-25.74,0-38.61,0
            c-5.32-3.33-11.28-3.19-17.26-3.52c-4.39-0.24-10,1.51-11.94-4.99l-0.41-0.97C-134.73,338.71-129.94,340.08-125.43,338.79z"
            />
            <path
              style="fill: #32391b"
              d="M-241.33-159.54c5.3,0,10.6,0,15.9,0c2.42,4.5,8.21,7.23,7.12,13.58
            c-1.55,3.58,0.27,7.2-0.06,10.82c-0.63,3.12-2.38,5.19-5.53,5.96c-4.02,0.05-8.04,2.33-12.07,0.04
            c-1.39-1.04-2.54-2.32-3.62-3.66C-243.08-141.52-251.25-149.94-241.33-159.54z"
            />
            <path
              style="fill: #39451c"
              d="M-241.33-159.54c-2.07,9.05,1.38,17.12,4.49,25.25c-2.57,2.9-5.46,2.83-8.51,0.97
            c-1.14-1.63-2.23-1.17-3.31,0.26c-6.05,2.78-10.06,1.11-11.88-5.36c-0.59-4.55,0.67-8.59,3.36-12.23
            c-1.87-2.95-1.73-5.92-0.05-8.9C-251.93-159.54-246.63-159.54-241.33-159.54z"
            />
            <path
              style="fill: #465723"
              d="M203.83,211.05c0,9.85,0,19.7,0,29.56c-5.34,2.15-8.85-0.41-11.74-4.56
            c-5.39-5.68-0.27-15.39-7.77-20.28c-2.14-1.56-4.17-3.21-4.99-5.89c-0.53-4.9-1.24-9.86,3.53-13.37
            c1.58-0.67,3.19-0.64,4.81-0.17C196.86,197.05,200.21,204.19,203.83,211.05z"
            />
            <path
              style="fill: #a2ab5d"
              d="M119.79-159.54c1.83,10.86-6.96,20.3-4.19,31.28c-0.24,1.6-0.47,3.19-1.22,4.66
            c-6.18,3.52-6.45,10.58-9.56,15.97c-2.15,1.47-4.35,2.03-6.69,0.4c-2.57-2.45-2.16-5.64-2.19-8.72
            c0.03-3.53-0.71-7.18,1.47-10.39c3.49-3.29,5.34-7.31,5.28-12.18c-0.56-8.97,5.65-14.61,10.29-21.02
            C115.25-159.54,117.52-159.54,119.79-159.54z"
            />
            <path
              style="fill: #3d5222"
              d="M-286.86,208.43c8.98,2.38,7.31,10.74,12.28,17.82c-12.18-3.84-21.49-5.53-30.34,0.72
            c0-4.55,0-9.09,0-13.64C-299.25,210.41-292.99,209.65-286.86,208.43z"
            />
            <path
              style="fill: #7e8860"
              d="M-304.93,140.57c0-9.85,0-19.7,0-29.56c1.73-1.83,1.18-7.43,6.23-3.78
            c2.08,2.88,2.82,6.63,5.78,8.96c8.98,16.63,8.74,18.81-3.24,30.48C-301.47,148.07-302.43,143.22-304.93,140.57z"
            />
            <path
              style="fill: #3f4827"
              d="M174.3-159.54c0.04,1.87,0.07,3.73,0.11,5.6c-1.79,2.23-1.08,5.54-3.3,7.59
            c-2.25,1.77-5.54,1.92-6.81,5.26c-3.19,8.41-8.01,5.53-12.86,1.57c-1.22-1.31-2.27-2.73-2.84-4.46
            c-0.18-5.21-4.58-10.61,0.71-15.56C157.65-159.54,165.97-159.54,174.3-159.54z"
            />
            <path
              style="fill: #455220"
              d="M112.98-159.54c2.73,9.07-8.2,14.08-6.77,22.76c-5.01,4.89-10.12,1.28-15.19,0.18
            c-7.1-5.05-1.85-13.89-6.07-19.85c-0.07-1.11,0.18-2.15,0.77-3.1c3.03,0,6.06,0,9.08,0c2.27,3.13,4.54,1.94,6.81,0
            C105.41-159.54,109.19-159.54,112.98-159.54z"
            />
            <path
              style="fill: #445526"
              d="M203.83,256.52c0,3.03,0,6.06,0,9.09c-5.18,6.21-6.79,15.68-16.32,17.96
            c-4.57-0.13-7.65-2.09-8.44-6.87c-0.28-3.93,3.65-6.26,3.83-10.03c2.22-2.77,0.39-5.87,0.7-8.81c0.59-2.87,2.49-4.7,4.87-6.14
            C195.22,248.06,199.59,252.06,203.83,256.52z"
            />
            <path
              style="fill: #414e24"
              d="M181.16,331.44c3.65,0.01,9.29-2.97,7.85,4.94c-0.6,3.29,2.09,4.59,3.41,6.73
            c1.07,5.62-5.39,3.52-6.77,6.62c-3.79,0-7.57,0-11.36,0c-2.23-3.43-2.43-7.31-2.5-11.22c-0.79-3.49,0.13-6.24,3.4-8
            C177.37,329.61,179.2,330.95,181.16,331.44z"
            />
            <path
              style="fill: #739966"
              d="M-70.99,349.74c-12.04-5.55-25.38-6.62-39.39-11.04c5.17-4.28,10.12-1.95,14.44-2.61
            c10.66-5.29,25.68-1.63,33.19,8.08c0.48,2.09-0.06,3.93-1.41,5.56C-66.45,349.74-68.72,349.74-70.99,349.74z"
            />
            <path
              style="fill: #596538"
              d="M-300.12,108.6c-2.8-1.58-3.14,1.74-4.81,2.41c0-6.06,0-12.13,0-18.19
            c2.44,1.11,4.93,2.04,7.55,0.67c7.02-3.68,8.62,0.05,8.53,6.17c0.89,0.63,1.65,1.39,2.31,2.26c1.5,3.23,2.43,6.45-0.28,9.57
            C-292.38,115.74-296.91,115.19-300.12,108.6z"
            />
            <path
              style="fill: #abb46d"
              d="M15.22-144.8c-0.98,2.07-1.96,4.14-2.93,6.22c-1.75,1.7-3.73,1.61-5.83,0.8
            c-4.15-5.14-11.63-4.14-16.1-8.72c-1.53-1.8-2.46-3.82-2.25-6.25c0.7-5.34,5.84-4.76,9.03-6.8c3.03,0,6.06,0,9.08,0
            C13.73-157.38,14.99-151.4,15.22-144.8z"
            />
            <path
              style="fill: #405f26"
              d="M199.79,122.27c1.35,0.04,2.69,0.07,4.04,0.11c0,3.03,0,6.06,0,9.09
            c-2.9,0.16-7.82-4.35-7.36,3.5c0.21,3.57-2.71,5.06-5.9,5.72c-4.28,0.99-7.68,0.16-9.3-4.44c-0.72-2.56-0.85-5.19-0.73-7.85
            C185.21,115.58,185.43,115.51,199.79,122.27z"
            />
            <path
              style="fill: #5b7b2c"
              d="M-34.65-159.54c0.66,1.51,1.33,3.03,1.99,4.54c-2.54,5.79-10.83,5.39-12.82,12.17
            c-0.52,1.76-3.99,1.42-6.04,0.18c-2.02-1.21-2-2.92-0.68-4.69c2.93-3.93,2.31-6.16-2.92-6.25c-1.81-2.98,0.08-4.53,2.3-5.96
            C-46.76-159.54-40.71-159.54-34.65-159.54z"
            />
            <path
              style="fill: #212715"
              d="M187.7,281.66c4.68-6.05,6.74-14.73,16.12-16.05c0,6.82,0,13.64,0,20.46
            c-6.02,5.34-11.72,5.45-17.02-1.03C186.62,283.79,186.92,282.66,187.7,281.66z"
            />
            <path
              style="fill: #688230"
              d="M-300.26-30.23c13.49,9.58,13.49,9.58-4.67,23.01c0-6.82,0-13.64,0-20.46
            C-303.67-29.08-302.94-31.44-300.26-30.23z"
            />
            <path
              style="fill: #5c6f37"
              d="M-64.18,349.74c-0.09-1.38-0.19-2.77-0.28-4.15c2.95-6.19-2.55-15.44,6.96-19.27
            c4.93-0.17,10.38-2.72,13.93,3.14c-3.84,6.76-7.69,13.52-11.53,20.28C-58.12,349.74-61.15,349.74-64.18,349.74z"
            />
            <path
              style="fill: #bfc69d"
              d="M42-145.49c-0.51-1.57-1.02-3.15-1.53-4.72c-3.48-7.18,3.08-7.19,6.65-9.33c3.03,0,6.06,0,9.08,0
            c1.51,5.5,3.91,10.92,1.61,16.81c-1.58,1.97-3.65,3.18-6.03,3.93C46.44-137.98,43.88-141.23,42-145.49z"
            />
            <path
              style="fill: #789736"
              d="M-79.94-146.04c-0.51-3.34-0.24-7.27-5.85-5.33c-2.77,0.96-5.94,1.67-8.77,1.3
            c-2.77-0.37-6.52-1.3-6.97-4.73c-0.44-3.4,4.26-2.41,5.55-4.74c3.79,0,7.57,0,11.36,0c4.86,3.48,16.52,2.01,10,13.77
            C-76.46-144.55-78.23-144.54-79.94-146.04z"
            />
            <path
              style="fill: #6d935f"
              d="M-138.8,341.23c5.51,2.94,11.51,1.12,17.25,1.79c4.94,0.58,9.21,2.25,11.95,6.71
            c-8.33,0-16.66,0-24.98,0C-143.11,345.93-143.11,345.93-138.8,341.23z"
            />
            <path
              style="fill: #54782b"
              d="M194.73,42.83c-2.8,4.39-5.16,9.99-11.62,4.2c-1-2.64-0.99-5.33-0.49-8.06
            c1.09-3.4,3.36-5.68,6.57-7.11c4.18-1.3,4.17-6.07,6.98-8.52c1-0.56,2.07-0.87,3.21-0.96c1.8,0.12,3.19,1.06,4.46,2.23
            c0,2.27,0,4.55,0,6.82C201.7,35.96,201.89,42.34,194.73,42.83z"
            />
            <path
              style="fill: #aeb884"
              d="M149.32-159.54c0.96,5.19,1.92,10.38,2.89,15.57c0.62,1.21,0.48,2.31-0.44,3.31
            c-9.96-0.1-15.66-5.13-17.79-15.69c0.35-1.18,0.93-2.24,1.72-3.18C140.23-159.54,144.78-159.54,149.32-159.54z"
            />
            <path
              style="fill: #7ca133"
              d="M-304.93-41.32c5.28-7.31,13.01-6.75,20.66-6.44c0.04,4.44,5.82,8.94-0.16,13.31
            c-3.92,0.12-7.91,0.55-11.13-2.48c-2.9-1.71-6.98,1.87-9.37-2.11C-304.93-39.8-304.93-40.56-304.93-41.32z"
            />
            <path
              style="fill: #6b794f"
              d="M-304.93,186.04c7.73-2.34,12.93-7.95,17.9-13.85c2.77,13.75-5.26,22-17.9,18.4
            C-304.93,189.07-304.93,187.56-304.93,186.04z"
            />
            <path
              style="fill: #5a7729"
              d="M-55.02-153.62c-4.92,3.58-6.62,12.3-15.43,9.66c-0.98-0.28-1.71-0.88-2.19-1.77
            c0.24-4.85-3.54-8.86-2.89-13.81c4.54,0,9.08,0,13.63,0C-60.31-156.75-57.49-155.4-55.02-153.62z"
            />
            <path
              style="fill: #bdc39c"
              d="M15.22-144.8c-7.62-2.09-5.14-10.35-8.99-14.75c6.06,0,12.11,0,18.17,0
            C17.67-156.92,23.21-146.64,15.22-144.8z"
            />
            <path
              style="fill: #495b25"
              d="M51.95-141.4c0.74-1.88,2.46-2.13,4.12-2.49c2.45-4.95-2.13-12,4.67-15.65c1.51,0,3.03,0,4.54,0
            c1.51,1.58,3.16,3.07,3.42,5.44c-0.34,5.54,7.77,8.47,4.63,14.89c-3.51,3.22-7.79,3.76-12.3,3.49
            C56.94-135.91,53.01-136.36,51.95-141.4z"
            />
            <path
              style="fill: #5e5926"
              d="M-221.06-145.88c-0.56-4.84-9.91-6.86-4.38-13.66c4.54,0,9.08,0,13.63,0
            c3.29,2.86,5.03,6.06,2.21,10.25c-2.92,2.26-5.73,4.71-9.05,6.43C-220.38-143.11-221.64-143.75-221.06-145.88z"
            />
            <path
              style="fill: #667348"
              d="M203.83,256.52c-5.07,2.67-8.85-3.61-13.77-1.99c-6.71-4.76-0.9-8.07,1.34-11.82
            c4.18-2.87,8.32-2.4,12.43,0.17C203.83,247.43,203.83,251.97,203.83,256.52z"
            />
            <path
              style="fill: #455726"
              d="M194.99,333.64c-7.19-10.48-6.99-10.89,8.84-18.01c0,4.55,0,9.09,0,13.64
            C201.39,331.75,198.35,333.03,194.99,333.64z"
            />
            <path
              style="fill: #445526"
              d="M187.52,283.63c4.95,4.07,11.05,0.46,16.31,2.45c0,3.03,0,6.06,0,9.09
            c-3.94,0.63-7.87,1.27-11.81,1.9C188.39,293.3,179.35,291.35,187.52,283.63z"
            />
            <path
              style="fill: #757a3a"
              d="M-123.23-159.54c0.66,5.78-2.51,7.46-7.39,6.26c-3.82-0.94-7.26-0.96-10.66,1.05
            c-2.4,0.65-5.5,1.81-6.73-0.86c-1.64-3.58,2.71-4.36,4.33-6.44C-136.86-159.54-130.04-159.54-123.23-159.54z"
            />
            <path
              style="fill: #706136"
              d="M-211.79-150.66c1.41-2.96,0.37-5.92-0.02-8.88c5.3,0,10.6,0,15.9,0
            c0.03,1.33,0.06,2.66,0.09,3.99c-1.39,3.74-2.94,7.38-6.34,9.83C-206.94-144.3-212.04-142.27-211.79-150.66z"
            />
            <path
              style="fill: #545627"
              d="M-143.67-159.54c-0.18,2.76-5,7.04,2.4,7.3c-5.02,2.89-10.57,1.52-15.9,1.98
            c2.05-3.83-2.19-6.04-2.4-9.28C-154.27-159.54-148.97-159.54-143.67-159.54z"
            />
            <path
              style="fill: #89ad34"
              d="M-282.21-159.54c-0.71,2.55,3.87,4.94,1.05,6.93c-2.96,2.09-7.32,2.2-10.39,0.26
            c-2.61-1.65-5.81-3.51-6.56-7.19C-292.81-159.54-287.51-159.54-282.21-159.54z"
            />
            <path
              style="fill: #7b8847"
              d="M135.69-159.54c0.07,0.69,0.15,1.38,0.22,2.07c1.37,4.82,2.07,9.29-5.04,9.7
            c-7.58-0.92-5.77-7.06-6.54-11.77C128.12-159.54,131.91-159.54,135.69-159.54z"
            />
            <path
              style="fill: #56663f"
              d="M-304.93,140.57c3.07,1.4,6.14,2.8,9.21,4.2c2.38-0.97,4.59-0.61,4.98,2.12
            c0.74,5.29-2.81,7.82-7.09,9.63c-2.83,0.66-5.09-0.44-7.1-2.31C-304.93,149.66-304.93,145.12-304.93,140.57z"
            />
            <path
              style="fill: #9f9b80"
              d="M203.83-84.51c-4.48-0.2-8.97-0.41-13.45-0.61c-5.82-0.45-11.72,3.53-17.49-0.3
            c-4.04-1.1-6.5-3.44-6.2-7.93c0.84-1.74,2.28-2.74,4.09-3.26c4.16-0.65,8.24-0.99,10.89,3.33c5.96,7.05,14.5,5.26,22.16,6.49
            C203.83-86.03,203.83-85.27,203.83-84.51z"
            />
            <path
              style="fill: #819c2d"
              d="M194.99,333.64c1.68-4.01,3.18-8.41,8.84-4.37c-1.48,6.74,2.91,14.13-2.27,20.46
            c-4.52-3.58-6.75-7.94-4.65-13.76C196.27,335.2,195.63,334.42,194.99,333.64z"
            />
            <path
              style="fill: #434c29"
              d="M108.44,349.74c2.76-1.53,5.52-3.06,8.29-4.59c3.41-1.05,3.72-8.05,9.33-4.88
            c2.17,4.22,5.65,7.11,9.64,9.47C126.61,349.74,117.52,349.74,108.44,349.74z"
            />
            <path
              style="fill: #647e2b"
              d="M196.91,335.98c1.55,4.59,3.1,9.17,4.65,13.76c-5.3,0-10.6,0-15.9,0
            c0.31-4.2,4.66-4.27,6.77-6.62C193.92,340.74,195.42,338.36,196.91,335.98z"
            />
            <path
              style="fill: #7e9446"
              d="M203.83,242.88c-3.82,0.87-7.65,1.73-11.47,2.6c-4.39,0.18-6.51-2.3-5.84-6.27
            c0.72-4.18,3.38-6.69,8.14-5.21c3.06,2.2,6.11,4.41,9.17,6.61C203.83,241.36,203.83,242.12,203.83,242.88z"
            />
            <path
              style="fill: #1d2414"
              d="M203.83,211.05c-4.38-6.37-11.72-7.97-18.05-11.19c1.54-2.81,3.36-5.17,7.09-4.53
            c8.05-0.6,7.19,7.56,10.96,11.17C203.83,208.02,203.83,209.53,203.83,211.05z"
            />
            <path
              style="fill: #b7d05e"
              d="M197.47-13.25c0.88,3.35,3.1,4.44,6.36,3.77c0,3.03,0,6.06,0,9.09
            c-1.26,1.88-2.52,3.77-5.27,3.22c-3.13-1.6-6.55-2.85-8.18-6.39C189.23-9.37,189.83-13.89,197.47-13.25z"
            />
            <path
              style="fill: #6d9632"
              d="M194.73,42.83c3.03-3.8,6.07-7.6,9.1-11.4c0,5.3,0,10.61,0,15.91
            C199.56,48.33,196.81,46.25,194.73,42.83z"
            />
            <path
              style="fill: #7a9337"
              d="M101.62-159.54c-3.42,3.72,6.85,11.99-2.89,12.26c-6.08,0.17-3.1-7.84-3.92-12.26
            C97.08-159.54,99.35-159.54,101.62-159.54z"
            />
            <path
              style="fill: #7b893d"
              d="M47.11-159.54c-3.27,2.36-5.34,5.57-6.65,9.33c-8.45,1.48-8.37-3.43-6.98-9.33
            C38.03-159.54,42.57-159.54,47.11-159.54z"
            />
            <path
              style="fill: #68902b"
              d="M203.83,190.59c-6.23-1.91-7.31-6.92-6.34-12.2c0.64-3.51,3.87-2.35,6.34-1.44
            C203.83,181.49,203.83,186.04,203.83,190.59z"
            />
            <path
              style="fill: #416226"
              d="M203.83,113.29c-3.3-0.69-6.6-1.33-9.88-2.09c-1.73-0.4-4.19-0.33-4.12-2.79
            c0.04-1.25,1.33-2.66,2.39-3.63c2.75-2.5,3.49-0.28,4.56,1.94c1.48,3.09,4.56,1.79,7.05,2.02
            C203.83,110.25,203.83,111.77,203.83,113.29z"
            />
            <path
              style="fill: #313f1c"
              d="M-75.53-159.54c1.44,4.64,5.85,8.19,4.95,13.68c-1.72,1.34-3.41,1.23-5.07-0.12
            c3.16-8.6-6.81-8.49-8.97-13.56C-81.59-159.54-78.56-159.54-75.53-159.54z"
            />
            <path
              style="fill: #3e5723"
              d="M-304.93-39.04c2.08-0.71,4.25-1.25,6.2-2.21c1.3-0.64,2.49-2.3,3.84-0.8
            c1.5,1.68,0.15,3.47-0.67,5.11c-2.82,1.36-4.51,3.5-4.7,6.71c-1.56,0.85-3.11,1.7-4.67,2.55
            C-304.93-31.46-304.93-35.25-304.93-39.04z"
            />
            <path
              style="fill: #98a278"
              d="M-300.42,67.96c-1.5-2.32-3-4.64-4.51-6.96c0-3.79,0-7.58,0-11.37c0.47-1.56,1.17-1.67,2.1-0.34
            C-300.61,55.33-295.65,61.02-300.42,67.96z"
            />
            <path
              style="fill: #62747f"
              d="M-298.12,161.02c-3.17,3.84,15.75,7.6,0.05,11.49c-6.94,0.4-2.5-7.68-6.85-9.21
            c0-0.76,0-1.52,0-2.27C-302.66,159.24-300.39,159-298.12,161.02z"
            />
            <path
              style="fill: #a2a789"
              d="M65.85-152.93c-0.19-2.2-0.38-4.41-0.56-6.61c4.54,0,9.08,0,13.63,0
            c1.31,1.47,1.59,3.14,0.98,4.99c-0.63,1.06-1.49,1.88-2.57,2.48C73.33-150.21,69.37-148.58,65.85-152.93z"
            />
            <path
              style="fill: #557b2b"
              d="M203.83,24.62c-1.5,0-3,0-4.49,0c-4.38-5.5-6.45-11.12-0.92-17.15c2.03-0.58,3.82-0.13,5.41,1.23
            C203.83,14.01,203.83,19.31,203.83,24.62z"
            />
            <path
              style="fill: #30441d"
              d="M192.49,59.14c3.34-3.11,6.17-7,11.33-7.24c0,0.76,0,1.52,0,2.27c-2.76,3.43-2.1,7.38-1.96,11.37
            c0.13,3.86-2.04,4.07-4.85,2.24C195.51,64.9,194,62.02,192.49,59.14z"
            />
            <path
              style="fill: #424f4c"
              d="M-304.93,163.3c5.54,0.64,4.91,5.89,6.85,9.21c-1.57,2.58-3.8,4.14-6.85,4.43
            C-304.93,172.4-304.93,167.85-304.93,163.3z"
            />
            <path
              style="fill: #86ad25"
              d="M-304.93-73.15c2.24-0.04,4.49-0.07,6.73-0.11c2.17-1.32,4.16-1.25,5.41,1.16
            c1.16,2.23,0.19,4.25-1.33,5.94c-2.32,2.58-5.99,2.42-8.76,4.11c-0.68,0.12-1.36,0.21-2.05,0.27
            C-304.93-65.57-304.93-69.36-304.93-73.15z"
            />
            <path
              style="fill: #a1a781"
              d="M60.74-159.54c-2.89,4.82,2.44,12.1-4.67,15.65c-1.27-5.23-3.39-10.46,0.13-15.65
            C57.71-159.54,59.23-159.54,60.74-159.54z"
            />
            <path
              style="fill: #7d9f34"
              d="M197.01,67.79c3.43,0.2,3.44-1.67,2.81-4.29c-0.96-4.01-0.42-7.55,4-9.32c0,5.3,0,10.61,0,15.91
            C201.53,69.4,198.39,71.21,197.01,67.79z"
            />
            <path
              style="fill: #2f3f1d"
              d="M-55.02-153.62c-4.17,0.21-10.64,3.1-6.88-5.92c3.03,0,6.06,0,9.08,0
            c-0.77,1.98-1.53,3.97-2.3,5.95C-55.12-153.58-55.02-153.62-55.02-153.62z"
            />
            <path
              style="fill: #747f39"
              d="M-2.85-159.54c-3.34,2.05-5.33,4.91-5.35,8.95c-3.42,1.87-6.11,1.05-8.13-2.22
            c-0.05-2.24-0.1-4.48-0.14-6.73C-11.94-159.54-7.4-159.54-2.85-159.54z"
            />
            <path
              style="fill: #9aa2be"
              d="M-298.12,161.02c-2.27,0-4.54,0.01-6.81,0.01c0-2.27,0-4.55,0-6.82
            c2.24,0.22,4.48,0.44,6.72,0.66c2.12,0.53,3.63,1.44,2.46,3.99C-296.54,159.58-297.33,160.3-298.12,161.02z"
            />
            <path
              style="fill: #abc76e"
              d="M-175.47-159.54c0.25,7.43-5.14,5.07-9.1,5.86c-0.45-4.1,3.85-3.42,4.56-5.86
            C-178.49-159.54-176.98-159.54-175.47-159.54z"
            />
            <path
              style="fill: #36441e"
              d="M-302.82,49.29c-0.67,0.33-1.37,0.44-2.1,0.34c0-4.55,0-9.09,0-13.64
            C-293.59,38.73-305.81,45.22-302.82,49.29z"
            />
            <path
              style="fill: #598034"
              d="M203.83,81.46c-2.27,0.93-4.57,2.91-5.58-1.61c-1.19-5.36,2.04-5.29,5.58-5.21
            C203.83,76.91,203.83,79.18,203.83,81.46z"
            />
            <path
              style="fill: #8fb44a"
              d="M203.83,8.7c-1.5-0.05-3-0.09-4.49-0.14c-1.95-0.74-3.63-1.73-3.78-4.13
            c0.16-1.61,0.94-2.79,2.43-3.47c1.95-0.45,3.9-0.91,5.84-1.36C203.83,2.64,203.83,5.67,203.83,8.7z"
            />
            <path
              style="fill: #5b692d"
              d="M78.41-155.25c0.17-1.43,0.34-2.86,0.5-4.29c2.27,0,4.54,0,6.81,0c0,0.79,0.01,1.58,0.01,2.37
            c0.02,0.51,0.04,1.02,0.05,1.54C83.56-150.92,81.12-150.39,78.41-155.25z"
            />
            <path
              style="fill: #1f2417"
              d="M-127.79,258.79c-3.56,7.35,2.9,15.61-2.07,22.82c-4.87,5.09-8.17,5.38-13.05,0.46
            c-5.56-5.61-7.37-12.83-7.92-20.51c-1.3-18.31-7.69-34.21-22.28-46.09c-3.38-2.75-5.01-6.47-6.29-10.43
            c-0.98-3.03-2.16-5.73-6.15-4.99c-1.2,0.2-2.4,0.15-3.4,0.2c0.91,1.88,3.15,2.49,3.64,4.48c1.5,5.73-4.07,7.88-5.99,11.9
            c-5.33,5.02-10.84,9.08-18.2,3.51l-0.05-0.07c-1.6-3.22-2.89-6.38-5.66-9.16c-4.59-4.59-5.68-12.1-1.42-16.13
            c6.5-6.14,1.21-8.45-1.69-12.06c-1.54-2.2-3.09-4.41-4.02-6.97c-4.45-12.74-4.45-12.74,10.58-23.99
            c6.17-4.18,7.57-12.73,14.63-16.12c2.04-1.1,4.12-1.41,6.25-0.25c1.98,1.35,2.54,5.6,6.65,1.61
            c7.11-6.89,12.77-14.69,17.13-23.41c3.96-7.91-1.63-20.13-10.11-23.35c-10.91-4.15-13.43-10.78-8.34-21.95
            c3.52-2.01,6.72-4.65,10.77-5.65c3.76-0.74,7.22-0.22,10.12,2.49c4.47,8.79,10.84,16.58,14.05,26.06
            c0.43,1.01,0.65,2.07,0.73,3.17c-0.01,3.17-1.32,6.12-1.59,9.25c-0.2,4.1-0.29,8.2,0.21,12.29c2.51,6.6,4.43,13.19-0.09,19.75
            c-4.83,4.44-6.66,10.62-9.13,16.34c-2.99,6.94-7.53,9.89-14.9,7.56c-1,0.06-1.88,0.32-2.14,1.56c-0.66,3.18,0.25,6.2,0.49,9.24
            c0.12,2.49-0.58,5.32,2.84,6.35c2.74,2.46,5.71,3.21,9.09,1.41c2.91-0.65,5.79-1.35,8.16-3.35c4.82-4.74,9.59-9.93,15.88-1.82
            c1.92,3.74,1.49,8.1,3.09,11.93c0.77,3.04,3.6,4.96,4.36,8.01c2.32,8.14,6.61,15.87,5.13,24.79c1.37,5.02-1.23,10.44,1.62,15.31
            c0.17,1.05,0.27,2.09,0.32,3.15c0.56,6.74,5.02,13.29,0.96,20.25C-126.23,257.32-126.95,258.11-127.79,258.79z"
            />
            <path
              style="fill: #232b19"
              d="M-43.79,263.38c-9.2,2.11-4.98,6.84-2.2,11.29c-2.09,5.14-6.01,9.53-5.8,15.61
            c0.17,5.09-2.23,8.85-7.37,9.91c-5.9,1.21-9.15-1.93-10.8-9.24c0.35,9.04,1.24,15.44,11.23,13.99c4.23-0.61,7.04,1.92,9.28,5.21
            c2.29,8.84-6,9.22-10.23,12.86c-2.53,0.58-5.09,0.52-7.65,0.44c-9.12-2.33-18.05-2.74-27.5-0.62
            c-6.5,1.46-14.08,3.07-20.15-2.58c-1.49-7.48,1.43-11.76,9.11-12.6c1.22,0.59,2.33,1.33,2.93,2.67c0.23,0.5,0.49,0.64,0.42,0.58
            c-1.17-0.96,1.29-1.31-0.23-1.06c-1.47-6.4-7.19-8.65-11.46-12.27c-4.65-5.81,5.75-13.2-1.98-18.68
            c5.1-6.6,10.95-1.48,16.5-1.08c8.7,3.32,9.18-1.26,6.94-7.59c-2.61-7.35-3.85-13.87,5.75-16.9c2.01,0.75,3.41,2.52,5.56,3.46
            c-0.78-2.41-3.48-2.31-4.55-4.06c-10.99-8.2-9.28-21.93-14.3-32.74c-0.25-1.14-0.22-2.28,0.03-3.4
            c0.34-1.08,0.92-2.03,1.67-2.86c8.96-5.3,10.99,5.14,16.41,6.91c5.08,1.66,11.09-6.54,17.68-1.44c2.19,1.7,3.59,3.87,3.75,6.71
            c4.22,9.32,10.82,15.92,20.98,18.5c1.75,0.65,3.47,1.42,5.32,1.71c5.16,0.81,8.29,3.76,7.92,9.02c-0.39,5.55-4.84,6.88-9.5,7.67
            C-38.61,263.21-41.24,262.7-43.79,263.38z"
            />
            <path
              style="fill: #131510"
              d="M-186.79,197.35c5.56-7.02,8.51-2,10.08,2.83c2.23,6.89,7.36,10.7,12.12,15.54
            c10.98,11.19,17.43,25.19,18.52,41.03c0.47,6.87,1.23,13.54,4.58,19.51c2.4,4.29,5.56,8.38,11.63,5.35
            c9.98,2.16,13.11,7.54,10.87,18.72c-1.41,7.04-13.85,15.16-20.89,13.58c-1.09-0.24-2.06-0.93-3.14-1.24
            c-11.67-3.29-12.98-8.06-17.74-19.43c-4.8-11.45-4.51-25.24-14.45-34.48l-0.91-0.64c0,0,0.38-1.05,0.38-1.05
            c0.07-1.75,0.13-3.5,0.2-5.25c-6.72-11.68-10.37-24.33-11.95-37.64C-182.77,208.77-188.72,202.89-186.79,197.35z"
            />
            <path
              style="fill: #1c2215"
              d="M-298.09,295.14c3.9-0.38,6,2.39,9.07,5.81c1.21-11.12,4.37-21.33,7.89-30.41
            c5.39-13.91,14.02-27.17,23.89-39.04c11.33-1.68,18.91,1.67,21.24,14.16c0.94,5.01,3.42,10.09,8.3,13.12
            c1.69-1.67,2.58-3.99,4.44-5.52c5.31-3.3,10.01-3.52,13.46,2.65c0.9,4.2,0.19,8.37-0.46,12.54
            c-7.12,6.33-11.49,14.6-15.98,22.81c-3.28,3.92-7.22,5.79-12.34,4.08c-5-2.67-6.17-9.12-12.1-11.37
            c-5.79-2.2-9.71-3.83-8.44,4.85c0.55,3.77-0.97,7.58,3.15,11.14c4.8,4.15,0.68,7.09-3.62,8.84c-7.81-4.89-16.04-5.69-24.78-2.84
            C-294.23,309.19-298.64,305.25-298.09,295.14z"
            />
            <path
              style="fill: #2a3c22"
              d="M-222.99,256.96c-1.57,0.61-3.14,1.22-4.71,1.83c-0.18-1.47-0.2-2.99-0.57-4.41
            c-1.47-5.69-6.22-10.83-4.74-16.87c1.84-7.48,9.79-5.26,14.93-7.52c4.52-2,6.4-5.97,8.58-9.84c5.73-1.21,11.1-3.26,15.8-6.83
            c3.01-2.94,6.04-2.29,9.06-0.03c5.01,12.38,10.82,24.57,9.08,38.55c-9.37-1.31-15.59-8.04-22.43-13.65
            c-7.57,4.97,8.64,12.07-2.02,16.23C-207.91,253.07-216.43,246.14-222.99,256.96z"
            />
            <path
              style="fill: #1f2416"
              d="M-59.81,329.22c0.69,3.24,1.61,6.45,2,9.72c0.57,4.79-0.65,8.23-6.64,6.65
            c-9.44-6.68-21.21-5.63-31.49-9.49c-6.45-4.13-13.66-2.14-20.57-2.53c-1.54-3.67-3.07-7.34-4.61-11.01
            c0.4-0.36,0.79-0.73,1.19-1.09c1.04-0.33,2.09-0.38,3.15-0.14c7.06,3.48,14.54,2.2,21.94,1.91c2.51-0.91,4.92-2.23,7.73-1.89
            c7.99-0.2,15.42,4.1,23.55,2.98c1.06,0.39,1.98,0.98,2.78,1.77C-60.18,327.04-59.84,328.08-59.81,329.22z"
            />
            <path
              style="fill: #232e22"
              d="M-238.92,292.83c4.55,0.43,7.79-2.41,11.32-4.53c2.69-2.42,5.09-5.49,9.44-4.18
            c4.2,1.28,4.76,5.23,6.23,8.52c-3.61,5.15-4.81,10.82-4.37,17.1c0.89,12.75-1.01,14.09-13.83,10.34
            c-3.55-4.36-9.59-1.42-13.54-4.62c-2.24-3.31-1.86-10.19-9.1-6.7c-2.86-4.51-1.81-8.21,3.12-9.77
            C-245.51,297.69-242.73,294.46-238.92,292.83z"
            />
            <path
              style="fill: #324420"
              d="M-238.92,292.83c2.28,5.59,0.37,8.7-5.61,9.01c-4.62,0.24-8.06,1.56-8.25,6.93
            c-2.26,1.48-4.53,1.4-6.81,0.05c1.32-2.78,4.69-5.53-0.97-7.79c-3.13-1.25-4.56-3.5-4.12-7.95c1.88-19.3,4.7-20.93,21.89-11.24
            C-238.12,284.47-240.82,289.39-238.92,292.83z"
            />
            <path
              style="fill: #314339"
              d="M-98.72,215.26c0.12,0.83,0.23,1.65,0.35,2.48c-1.12,4.99-1.96,9.82-0.35,15.05
            c1.43,4.67,0.47,9.48-2.77,13.51c-1.49,2.86-3.83,5.07-5.73,7.62c-3.97,3.53-7.77,7.4-13.71,7.13c-2.28-0.75-4.57-1.5-6.85-2.26
            c-0.13-0.58-0.27-1.16-0.41-1.74c5.48-4.55,3.61-10.76,3.7-16.53c1.61-3.09,7.5-3.83,4.95-9.21
            C-117.84,214.03-111.53,209.19-98.72,215.26z"
            />
            <path
              style="fill: #4e604e"
              d="M-100.5,281.49c-5.23-0.87-10.47-1.73-15.7-2.6c-0.48-4.56-7.05-8.27-1.91-13.62
            c4.13-10.9,7.89-12.52,21.08-9.09c3.16,4.42,2.17,9.92,3.73,14.83C-91.57,276.46-95.02,280.1-100.5,281.49z"
            />
            <path
              style="fill: #1f2416"
              d="M-114.22,297.57c9.67-0.75,14.81,3.13,13.68,13.41c-1.57,1.25-3.07,1.26-4.49-0.26
            c-6.72-1.98-5.57,6.01-9.53,7.53c-1.13,2.46-4.01,2.11-5.75,3.58c0,0-0.81,0.71-0.81,0.72c-6.26-5.22,0.02-7.81,2.67-10.32
            C-113.94,307.98-111.56,303.65-114.22,297.57z"
            />
            <path
              style="fill: #324520"
              d="M-175.14,268.28c0.13,1.85-0.15,3.85,0.48,5.5c1.44,3.76,4.29,7.41-0.78,10.63
            c-3.53,2.24-6.35,0.91-8.83-1.81c-3.36-3.69-4.58-7.67-1.16-11.98c0.9-1.13,2.23-1.93,3.36-2.88
            C-179.64,266.29-177.27,265.7-175.14,268.28z"
            />
            <path
              style="fill: #36524a"
              d="M-222.99,256.96c-0.86-7.65,6.81-9.78,10.52-11.31c4.93-2.04,9.98,3.24,12.46,8.77
            c-0.02,2.87-0.05,5.74-0.07,8.61c-5.12,1.68-8.61-1.15-11.97-4.34C-214.72,251.92-218.93,254.92-222.99,256.96z"
            />
            <path
              style="fill: #1f2416"
              d="M-125.43,338.79c-4.38,2.5-9.13,1.48-13.78,1.47c0.76-0.68,1.52-1.36,2.28-2.05
            c1.83,0,3.66,0,5.47,0c0-1.2,0.26-2.19-0.04-2.4c-3.97-2.82-8.91-5.12-6.61-11.42c1.01-2.76,3.56-4.4,6.32-3.45
            c5.73,1.97,5.42,7.49,6.17,12.21C-125.33,335-125.48,336.91-125.43,338.79z"
            />
            <path
              style="fill: #3f596b"
              d="M-219.35,286.6c-2.73,0.7-4.54,5.8-8.26,1.7c2.23-8.88,4.61-17.66,14.95-20.64
            c3.98-2.41,6.56-0.9,8.28,2.98c-0.16,0.61-0.34,1.22-0.54,1.82c-2.26,1.81-3.8,4.02-3.93,7.03c-0.35,0.93-0.82,1.79-1.39,2.6
            C-212.23,285.72-215.68,286.37-219.35,286.6z"
            />
            <path
              style="fill: #365f74"
              d="M-204.38,270.64c-2.76-0.99-5.51-1.99-8.27-2.98c-2.71-3.19-2.85-6.21,0.6-8.98
            c4.83-0.87,9.07-0.11,11.97,4.35C-196.77,268.24-200.64,269.41-204.38,270.64z"
            />
            <path
              style="fill: #556c70"
              d="M-98.87,256.64c-6.81,1.98-14.82,1.31-19.23,8.64c-0.95-1.41-1.89-2.82-2.84-4.23
            c2.35-4.64,7.51-6,10.94-9.38c3.13,0.61,5.76-2.51,8.99-1.23C-98.9,252.02-96.22,253.41-98.87,256.64z"
            />
            <path
              style="fill: #3c4b32"
              d="M-164.79,335.52c0.3,2.79-1.04,4.55-3.74,4.47c-4.34-0.12-3.01-4.6-4.6-6.89
            c-1.5-2.16,0.56-3.27,2.67-3.39C-166.41,329.48-165.64,332.56-164.79,335.52z"
            />
            <path
              style="fill: #30414b"
              d="M-243.68,315.47c6.86-5.34,12.38-6.74,13.54,4.62C-235.07,319.75-241.08,322.61-243.68,315.47z"
            />
            <path
              style="fill: #5a7399"
              d="M-219.35,286.6c0.77-4.12,5.29-4.37,7.36-7.15c9.91,1.69,4.34,6.83,2.47,11.14
            c-0.81,0.69-1.61,1.37-2.42,2.06C-216.02,292.61-217.4,289.25-219.35,286.6z"
            />
            <path
              style="fill: #375876"
              d="M-209.52,290.59c0.28-3.96,2.03-8.24-2.47-11.14c0,0,0.4-0.8,0.4-0.8
            c1.37-2.75,3.83-3.81,6.67-4.26C-200.81,281.42-201.58,284.12-209.52,290.59z"
            />
            <path
              style="fill: #3b5155"
              d="M-175.14,268.28c-2.31-0.18-4.62-0.36-6.93-0.54c3.1-2.97-3.15-11.49,6.33-10.66
            c0.09,0.59,0.27,1.15,0.53,1.69C-175.19,261.94-175.17,265.11-175.14,268.28z"
            />
            <path
              style="fill: #30471f"
              d="M-207.06,335.35c2.23,0,3.4,1.47,3.7,3.33c0.2,1.25-0.25,3.66-0.84,3.81
            c-2.75,0.69-3.75-1.61-4.46-3.69C-209.15,337.35-208.82,335.93-207.06,335.35z"
            />
            <path
              style="fill: #264049"
              d="M-204.92,274.39c-1.3,2.87-3.38,4.52-6.67,4.26c-3.78-8.56,3.42-5.24,6.67-6.19
            C-204.84,273.1-204.84,273.75-204.92,274.39z"
            />
            <path
              style="fill: #1f2b18"
              d="M49.59,261.47c1.15-5.42,5.02-10.49,2.12-16.43c4.53-4.71,1.59-10.7,2.76-15.99
            c0.94-4.26-0.72-9.08,2.72-12.82c10.77-6.82,10.77,2.77,13.22,8.66c1.24,2.28,3.56,3.52,5.28,5.32
            c7.91,1.26,15.55,4.23,23.79,0.55c5.05-2.25,10.41-0.61,15.1,2.4c3.83,1.15,5.06,8.14,10.9,4.79
            c9.41-0.64,15.38,3.92,18.99,12.3c1.49,3.45,2.02,6.39-1.98,8.41c-8.34-0.45-16.87-1.49-22.53,6.9
            c-5.1,5.66-15.57,6.18-15.76,16.53c-3.3,4.86-4.91,11-11.23,13.53c-3.04,1.21-0.84,3.87-0.52,5.91
            c0.34,4.72,1.54,9.63-2.51,13.55c-6.93,2.79-11.23,0.1-13.64-6.55c-0.28-4.41-1.57-8.59-2.94-12.76
            c-5.23-10.57-6.35-21.53-1.99-33.3c-7.92,4.54-6.4,17.19-17.69,16.84c-2.39-2.61-3.81-5.66-4.26-9.17
            C51.81,267.31,52.41,264.43,49.59,261.47z"
            />
            <path
              style="fill: #405629"
              d="M81.14,306.5c-0.81,7.66,5.46,5.82,9.44,7.05c2.1-0.52,2.79,0.76,3.14,2.47
            c-1.51,3.39-3.32,6.62-5.39,9.71c-4.74,3.42-7.5,9.73-14.4,10.07c-4.56-0.12-9.02,3.09-13.68,0.52
            c-5.19-2.01-2.33-8.68-6.51-11.27c1.45-3.37,5.09-0.91,7.14-2.68c3.58-2.59,3.77-3.63,1.23-7c-5.61-7.46-3.49-14.8,5.16-17.86
            c-0.67-3.1,1.32-3.62,3.67-3.78C76.63,296.15,75.73,303.85,81.14,306.5z"
            />
            <path
              style="fill: #303f1c"
              d="M-52.9,311.16c-1.22-0.83-2.43-2.34-3.68-2.37c-4.7-0.1-9.96,7.74-12.56,3.49
            c-3.9-6.37-8.36-14.43-5.95-22.42c1.12-3.69-2.79-11,3.93-10.91c3.73,0.05,5.29,6.57,6.58,10.83c0.84,2.78-1.68,7.88,4.26,6.97
            c4.68-0.71,6.31-5.15,4.67-8.7c-5.02-10.87,2.16-12.05,9.66-13.38c2.27,6.06,7.15,11.14,6.84,18.17
            C-43.07,299.45-45.27,307.35-52.9,311.16z"
            />
            <path
              style="fill: #2b361c"
              d="M-43.79,263.38c0.61-3.29,1.96-5.55,5.92-5.16c3.23,0.32,7.53,1.4,7.92-3.49
            c0.35-4.44-4.02-4.62-7.16-5.6c-1.42-0.45-2.78-1.09-4.17-1.64c-0.74-0.87-1.09-1.88-1.04-3.03c0.94-3.55,3.12-5.88,6.69-6.82
            c7.75,0.1,13.59-6.77,21.56-5.99c6.34,2,8.25,6.57,7.4,12.76c-0.27,2.35-1.31,4.54-1.47,6.93c-0.02,2.36,1.1,4.74-0.12,7.08
            c-0.85,1.31-2.45,1.62-3.49,2.66l-0.1,0c-1.27,1.38-2.23,3.06-3.83,4.14c-3.35,1.62-4.22,5.21-6.26,7.88
            c-3.41,3.48-6.29,3.5-8.6-1.2c-1.35-2.73-3.29-3.99-6.17-1.87C-39.84,268.63-42.08,266.29-43.79,263.38z"
            />
            <path
              style="fill: #2d411e"
              d="M72,295.08c-1.57,0.81-3.13,1.61-4.7,2.42c-2.41-2.01-0.26-5.28-1.64-7.13
            c-2.2-2.95-0.33-12.36-9.3-6.24c-1.87,1.28-2.23-2.85-2.69-4.82c6.01-5.82,8.09-13.27,8.6-21.28c0.14-2.26-0.29-4.82,1.87-6.1
            c2.79-1.66,3.49,2.07,5.29,2.66c12.68,4.14,11.35,11.17,4.62,19.83c-2.3,2.96-1.66,6.25,0.39,9.43
            c2.21,3.43,4.69,6.95,2.63,11.43C75.33,296.14,73.62,296.72,72,295.08z"
            />
            <path
              style="fill: #3c5322"
              d="M-52.9,311.16c-0.04-6.98,7.84-12.6,2.04-20.79c5.13-0.14,7.41,6.34,11.72,2.48
            c0.58,7.77,7.25,10.21,11.65,14.96c4.8,5.18,7.09,11.85,15.81,10.4c0.18,2.68-1.34,4.79-3.66,4.77
            c-13.7-0.1-27.41,4.07-41.08-0.06c-1.52-0.46-2.52-1.51-3.27-2.85C-60.39,314.83-50.34,317.82-52.9,311.16z"
            />
            <path
              style="fill: #2e3d1e"
              d="M-11.75,261.08c0.33-0.8,0.67-1.59,1-2.39c3.04-2.8,5.56-6.38,8.91-8.56
            c4.48-2.93,8.37-2.81,5.84,4.51c9.46,11.62,7.75,28.84,19.83,39.24c3.4,2.93,1.73,5.98-1.5,8.25c-3.32-0.2-6.61-0.45-7.09-4.82
            c-6.1-1.46-6.42-6.54-7.1-11.39c-3.69-3.14-7.47,3.49-11.16-0.1c-1.18-2.73-2.02-5.21-0.4-8.35
            C1.25,268.39-1.8,262.88-11.75,261.08z"
            />
            <path
              style="fill: #739e65"
              d="M-59.7,320.07c16-0.62,32.01-1.24,48.01-1.86c3.63-0.05,7.26-0.09,10.89-0.14
            c3.48,5.93-2.43,6-5.04,7.26c-7.69,3.72-16.18,3.46-24.5,3.8c-4.41,0.11-8.81,0.22-13.22,0.33c-5.41-0.08-10.83-0.16-16.25-0.24
            c-0.73-0.63-1.47-1.27-2.2-1.9c0.04-3.36-6.67-2.41-4.52-7.11C-64.25,320.16-61.98,320.11-59.7,320.07z"
            />
            <path
              style="fill: #24311a"
              d="M24.63,231.43c1.09,1.02,2.52,1.86,3.2,3.1c3.12,5.71,12.34,11.96,7.58,16.99
            c-4.48,4.74-14.06,7.8-22.6,4.77c-1.66-4.05-4.14-7.73-5.46-11.95c-3.91-10.64-2.6-13.31,8.43-17.24c1,0.21,1.91,0.66,2.73,1.27
            C20.18,230.11,20.64,234.29,24.63,231.43z"
            />
            <path
              style="fill: #2f411d"
              d="M9.3,342.12c-3.37-9.15-3.29-16.9,7.31-21.26c1.36-0.56,3.42-1.3,3.67-2.33
            c1.11-4.52,1.37-10.4,7.82-4.44c4.51,4.17,6.09,8.82,1.38,14.79C24.16,335.61,17.75,339.85,9.3,342.12z"
            />
            <path
              style="fill: #24311a"
              d="M40.42,235.92c-2.68-6.17-8.91-6.85-14.09-9.15c-1.55-3.45-5.08-6.14-4.12-10.59
            c2.01-6.21,7.46-8.53,12.62-11.19c5.25-3.23,10.56-6.05,16.76-2.26c3.98,3.7,3.13,8.54,3.08,13.19
            C53.59,225.2,42.82,227.57,40.42,235.92z"
            />
            <path
              style="fill: #384823"
              d="M71.99,331.58c6.55-0.13,9.23-6.15,13.9-9.15c4.01,1.71,4.27,6.08,6.45,9.09
            c3.78,2.25,12.25-0.37,11.27,5.77c-1.36,8.49-6.85,1.83-11.31,1.4c-8.19-0.79-14.43,7.03-22.49,8.83
            c-0.79-0.88-1.57-1.77-2.36-2.65C63.74,338.66,68.42,335.31,71.99,331.58z"
            />
            <path
              style="fill: #33461f"
              d="M40.42,235.92c-0.98-9.63,5.71-14.86,11.73-20.47c1.88-2.14,3.78-4.2,6.94-2.11
            c0.89,1.63,0.59,3.15-0.46,4.58c3.21,10.45,0.28,19.33-6.93,27.12C52.34,231.57,51.03,230.51,40.42,235.92z"
            />
            <path
              style="fill: #445724"
              d="M10.9,242.98c4.52,3.88,5.97,8.2,1.92,13.32c-2.94-0.56-5.88-1.12-8.82-1.67
            c-0.47-0.97-0.95-1.94-1.42-2.92c-1.68,7.61-7.77,6.78-13.33,6.98c-0.36-2.28-0.73-4.57-1.09-6.85
            c3.78-3.15,7.45-6.45,11.67-9.07C3.56,240.48,7.28,239.37,10.9,242.98z"
            />
            <path
              style="fill: #414d26"
              d="M-36.72,270.03c2.38-3.8,4.92-4.51,7.53-0.26c1.25,2.03,2.39,3.98,5.25,2.53
            c1.84,0.02,2.8,1.02,3.09,2.77c-0.29,2.19-0.25,4.49-0.95,6.54c-1.03,3.02-3.57,5.03-6.63,5.27c-4.52,0.35-3.01-4.2-4.51-6.35
            C-35.18,277.32-30.91,271.85-36.72,270.03z"
            />
            <path
              style="fill: #66872e"
              d="M24.64,217.81c3.9,2.36,4.97,5.25,1.69,8.96c-0.58,0.08-1.15,0.19-1.72,0.32
            c-2.46,0.84-4.08,4.12-7.37,2.51l0.03-0.03c-0.8-1.33-1.87-2.44-2.95-3.55c-2.15-3.3-7.51-1.59-9.06-5.83
            c-0.65-3.9,1.19-6.46,4.41-8.29c2.4-1.3,4.42-3.33,7.23-3.86C25.22,206.74,24.91,212.3,24.64,217.81z"
            />
            <path
              style="fill: #3c5322"
              d="M-30.34,329.13c5.22-3.76,11.57-1.79,17.23-3.39c4.87-1.37,10.68-1.13,12.31-7.67
            c3.88,4.74,3.62,6.39-4.72,8.58c-8.18,2.15-11.63,5.69-8.37,14C-21.17,339.38-21.63,328.35-30.34,329.13z"
            />
            <path
              style="fill: #4e6e2b"
              d="M-3.01,285.8c3.73-0.92,7.47-2.98,11.16,0.1c-6.59,1.15-12.57,13.31-20.16,1.12
            C-9.24,283.66-5.58,288.73-3.01,285.8z"
            />
            <path
              style="fill: #2e3d1e"
              d="M38.97,288.11c-0.19,2.73,1.64,6.6-2.98,5.85c-3.1-0.5-4.98-3.7-5.42-6.66
            c-0.52-3.59,2.88-4.12,5.37-4.35C40.77,282.51,38.4,286.39,38.97,288.11z"
            />
            <path
              style="fill: #4e712b"
              d="M17.24,229.6c2.46-0.84,4.91-1.67,7.37-2.51c-0.57,1.45-2.27,2.91,0.02,4.34
            c-0.49,3.38-2.06,6.09-4.45,8.78C16.73,236.74,15.91,233.44,17.24,229.6z"
            />
            <path
              style="fill: #425b36"
              d="M15.24,297.29c2.36,1.61,4.72,3.22,7.09,4.82c-2.08,0.76-4.11,1.87-6.25,2.16
            c-2.13,0.29-5.12,0.43-5.79-2.2C9.35,298.34,13.13,298.45,15.24,297.29z"
            />
            <path
              style="fill: #64854c"
              d="M71.99,331.58c0.34,5.07-5.29,8.09-4.54,13.3c-2.39-2.86-4.79-5.71-7.19-8.56
            C64.18,334.74,68.08,333.16,71.99,331.58z"
            />
            <path
              style="fill: #585c40"
              d="M-18.17,260.72c2.1,0.12,4.21,0.24,6.31,0.36c2.98,5.13,1.75,8.33-4.26,9.36
            C-20.84,268.05-21.34,264.77-18.17,260.72z"
            />
            <path
              style="fill: #848568"
              d="M-18.17,260.72c-0.83,3.56,0,6.77,2.05,9.72c-1.58,1.54-3.16,3.09-4.74,4.63
            c-1.03-0.92-2.06-1.84-3.09-2.76C-23.31,267.79-22.72,263.27-18.17,260.72z"
            />
            <path
              style="fill: #667156"
              d="M60.9,322.36c-1.84,2.34-4.46,2.59-7.14,2.68c-1.57-1.55-4.36-3.67-2.25-5.46
            C55.26,316.39,58.34,319.39,60.9,322.36z"
            />
            <path
              style="fill: #455b29"
              d="M49.59,261.47c3.41,0.53,5.53,2.53,5.12,5.97c-0.34,2.91-3.2,2.44-5.31,2.7
            C47.59,267.21,48.99,264.35,49.59,261.47z"
            />
            <path
              style="fill: #27341b"
              d="M-261.86,163.28c-0.48-3.73-4-3.74-6.26-5.4c-4.27-3.15-6.89-9.09-1.71-11.75
            c6.83-3.51,3.02-8.08,3.72-12.22c0.3-6.27,4.26-8.4,9.81-8.74c8.6,1.48,15.51,8.33,25.43,6.7c5.19-0.85,7.3,5.01,6.36,9.78
            c-1.32,6.71-0.34,13.42-1.03,20.1c-0.7,3.01-2.56,4.93-5.48,5.86c-7.21-1.06-14.42-0.73-21.62,0.25c-1.54,0-3.07,0.01-4.61,0.01
            C-260.95,168.51-262.53,167.02-261.86,163.28z"
            />
            <path
              style="fill: #303f1c"
              d="M-213.74,179.06c2.16,3.93,4.57,7.75,6.37,11.83c0.8,1.83,2.08,4.84-0.21,6.07
            c-1.16,0.62-3.7-1.35-5.91-2.26c-1.84,9.05,3.19,16.91,3.95,25.38c-1.55-1.62-2.79-3.96-4.68-4.76
            c-15.98-6.78-20.98-20.31-22.64-36.02c0.53-0.93,1.26-1.65,2.21-2.14c3.12-1.15,6.19-0.43,8.48,1.54
            C-221.96,182.34-217.95,181.14-213.74,179.06z"
            />
            <path
              style="fill: #3e5221"
              d="M-245.85,222.4c-4.34-3.25-8.28-6.92-12.11,1.13c-1.97,4.14-6.63,7.22-10.63,2.26
            c-2.83-3.52-5.46-8.51,0.22-12.61c3.01-2.17,5.97-4.4,8.95-6.61c1.54-0.71,3.09-1.41,4.63-2.12c3.12,1.46,5.18,0.31,6.48-2.66
            c4.99-2.96,8.83-2.9,8.73,4.17c-0.08,5.51,2.86,10.85,0.75,16.44C-241.2,228.69-243.51,226.55-245.85,222.4z"
            />
            <path
              style="fill: #3e5321"
              d="M-234.52,179.22c-0.79,0.03-1.57,0.06-2.36,0.09c-2.25-7.97-12.76-4.56-15.77-11.47
            c9.14-10.06,12.55-10.43,20.41-2.24C-227.72,171.03-230.61,175.21-234.52,179.22z"
            />
            <path
              style="fill: #384923"
              d="M-261.86,163.28c1.54,1.52,3.07,3.05,4.61,4.57c-2.92,4.57-6.79,8.23-11.05,11.51
            c-4.15-0.03-3.99-2.3-2.68-5.17c-0.21-3.5-2.88-5.68-4.51-8.42C-271.43,162.28-266.5,163.62-261.86,163.28z"
            />
            <path
              style="fill: #475228"
              d="M-270.98,174.19c0.89,1.72,1.78,3.44,2.68,5.16c-2.54,1.57-5.64,5.53-8,1.65
            C-279.01,176.55-274.84,174.91-270.98,174.19z"
            />
            <path
              style="fill: #5d7428"
              d="M-248.29,201.79c3.74,1.3,2.68,3.02,0.23,4.49c-2.73,1.64-5.65,3.08-6.72-1.82
            C-252.62,203.56-250.46,202.67-248.29,201.79z"
            />
            <path
              style="fill: #496b29"
              d="M-129.99-111.62c-3.81-11.75,8.68-11.66,12.93-17.57c5.6-7.8,13.11-13.07,23.46-12.32
            c2.36,3.11,1.77,7.52,4.66,10.4c3.2,6.58-3.58,11.56-2.8,17.77c-0.51,2.29-1.74,4.03-3.82,5.13c-1.02,0.4-2.08,0.58-3.17,0.59
            c-2.46-0.06-5.12-1.37-6.58,2.27c-4.01,10.01-9.66,6.84-15.45,1.73c-1.9-1.26-3.68-2.71-5.61-3.96
            C-127.85-108.66-128.52-110.49-129.99-111.62z"
            />
            <path
              style="fill: #3f5c24"
              d="M-91.13-129.11c-2.44-5.01-6.79,0.22-9.8-1.55c-0.7-6,6.92-5.92,7.34-10.84
            c7.56-2.28,11.52,5.81,18.12,6.3c1.04,1.72,2.2,3.25,4.55,2.88c1.46,0.76,2.93,1.52,4.39,2.28c3.1,2.53,7.22,3.34,10.22,6.05
            c2.71,6.21-3.86,8.72-5.27,13.33c-2.66,2.58-5.94,2.25-9.21,2c-4.04,0.47-7.72-0.15-10.53-3.44c-1.81-3.08-2.07-6.88-4.49-9.69
            C-87.61-124.21-89.33-126.69-91.13-129.11z"
            />
            <path
              style="fill: #56792c"
              d="M-168.68-102.72c2.92-5.96,7.81-9.58,13.92-11.76c2.3,2.05,8.64-3.05,7.87,4.44
            c-0.39,2.36,0.3,4.33,2.24,5.84c0.64,0.79,1.11,1.66,1.47,2.6c0.42,1.53,0.44,3.08,0.27,4.65c-2.21,7.09,1.85,15.42-4.3,21.72
            c-1.52,0.93-3.06,0.78-4.59,0.04c-2.59-2.42-5.12-4.91-7.84-7.21c-1.95-1.66-4.16-2.92-6.37-4.25
            C-171.79-91.19-172.17-96.64-168.68-102.72z"
            />
            <path
              style="fill: #191f14"
              d="M-152.51-128.24c-2.17-3.71-4.16-7.92-0.59-11.37c3.54-3.41,7.59-0.75,9.7,2.2
            c2.49,3.47,11.43,0.9,8.7,7.55c-2.47,6.02-8.63,9.15-15.91,6.61C-152.36-124.49-152.9-126.19-152.51-128.24z"
            />
            <path
              style="fill: #4e6a2a"
              d="M-62.09-111.97c-1.69-2.86-6.15-2.77-7.14-6.72c3.56-2.63,10.22,3.01,11.87-4.34
            c-3.54-5.63-3.19-8.75,4.52-6.83c2.4,0.6,4.72,2.6,6.95-0.24c0.77-0.15,1.54-0.3,2.31-0.45c4.49,5.14,11.28-2.48,15.74,2.84
            c-3.64,6.34-9.5,10.52-14.79,15.27c-5.46,1.32-11.53-3.26-16.62,1.73C-60.37-110.74-61.3-111.18-62.09-111.97z"
            />
            <path
              style="fill: #524f35"
              d="M-146-98.72c0.2-1.11,0.39-2.22,0.59-3.34c3.36-6.15,10.73-4.1,15.42-7.53
            c0.01-0.68,0.01-1.36,0.01-2.03c1.5,0.64,2.99,1.28,4.49,1.92c4.67,7.97,8.1,16.39,9.16,25.66c-0.7,3.71-3.21,5.89-6.37,7.51
            c-2.41,0.43-4.3-0.49-5.84-2.27c-2.61-3.7,3.12-10.43-4.48-12.34c-2.44-0.95-5.01-1.3-7.59-1.63
            C-143.57-93.71-145.28-95.77-146-98.72z"
            />
            <path
              style="fill: #3b5524"
              d="M-168.68-102.72c3.22,4.07,0.03,11.36,6.84,13.63c-2.13,9.51-8.97,8.03-15.72,6.76
            c-3.03-1.72-3.66-5.03-5.11-7.8c-0.85-2.77-0.69-5.63-0.61-8.47c0.22-2.76,0.85-5.29,3.75-6.51
            C-176.05-103.71-172.84-101.05-168.68-102.72z"
            />
            <path
              style="fill: #678c2f"
              d="M-45.88-130.1c-1.42,2.97-0.45,9.56-7.05,3.24c-4.15-3.97-4.11,1.08-4.43,3.83
            c-4.88,0.05-9.14-0.69-9.17-7c1.17-2.64,2.33-5.28,2.83-6.4C-57.59-134.27-51.74-132.19-45.88-130.1z"
            />
            <path
              style="fill: #8a8954"
              d="M-186.2-120.99c0,0,1.12,0.05,1.12,0.04c0.49,3.93,3.95,7.05,3.07,11.35
            c-0.27,1.69-0.96,3.14-2.33,4.22c-4.14,1.67-8.23,3.76-12.85,1.56c-3.07-2.71-5.63-5.64-4.25-10.21
            C-197.23-118.21-191.94-120.11-186.2-120.99z"
            />
            <path
              style="fill: #7e942e"
              d="M-182.21-123.46c-0.31-1.04-1.14-2.26-0.85-3.1c1.04-3.03-2.78-7.9,0.97-8.84
            c4.88-1.23,6.43,3.48,6.8,7.83C-176.38-124.15-176.6-119.27-182.21-123.46z"
            />
            <path
              style="fill: #afb475"
              d="M-166.19-149.9c-5.26,0.03-2.2,8.03-7.92,7.93c-2.77-3.96-1.37-7.75,1.81-10.38
            c3.44-2.83,6.84-0.07,9.98,1.73C-163.61-150.39-164.9-150.15-166.19-149.9z"
            />
            <path
              style="fill: #73854c"
              d="M-184.54-107.52c0.84-0.69,1.68-1.38,2.53-2.07c0.82,1.49,1.65,2.99,2.47,4.48
            c0.2,1.75,1.84,3.8-0.99,4.94c-1.27,2.45-2.02,5.47-5.74,5.22C-195.85-100.54-188.61-103.81-184.54-107.52z"
            />
            <path
              style="fill: #56792c"
              d="M-129.99-109.58c-4.65,3.52-8.27,9.15-15.42,7.53c-0.24-0.3-0.5-0.61-0.76-0.9
            c-1.19-1.52-1.66-3.19-1.07-5.08c0.24-0.9,0.78-1.58,1.6-2.02C-140.42-109.9-135.21-109.74-129.99-109.58z"
            />
            <path
              style="fill: #171c11"
              d="M-75.65-145.98c1.69,0.04,3.38,0.08,5.07,0.12c-0.02,0.64,0.03,1.27,0.13,1.9
            c-2.84,2.37-5.68,5.59-9.74,3.53c-2.69-1.37-1.27-3.77,0.25-5.61C-78.51-146.02-77.08-146-75.65-145.98z"
            />
            <path
              style="fill: #3e6027"
              d="M-148.12-109.8c-0.29-4.28-5.6-1.44-6.63-4.68c3.78-0.44,6.94-5.63,10.84-2.47
            c2.03,1.64-0.99,4.55-1.72,6.89c-0.27,0.34-0.48,0.71-0.64,1.12C-147.13-108.71-147.74-109.02-148.12-109.8z"
            />
            <path
              style="fill: #49722b"
              d="M-152.51-128.24c0.64,1.66,1.27,3.33,1.91,4.99c-2.6,0.5-5.1,5.69-7.26,1.98
            C-159.62-124.29-156.26-127.33-152.51-128.24z"
            />
            <path
              style="fill: #4d7a2c"
              d="M-70.92-132.32c-0.3,3.23-0.47,6.51-3.45,9.01c-3.7-3.99-0.43-8.1-1.11-11.88
            C-73.96-134.24-72.44-133.28-70.92-132.32z"
            />
            <path
              style="fill: #1b2015"
              d="M176.57,299.72c-7.96-0.68-10.78,5.1-13.61,10.86c-2.27,5.13-6.75,5.25-11.38,5.04
            c-11.71-0.38-12.87-11.33-18.14-18.16c-5.11-8.16-4.2-10.32,7.55-18.04c11.27-3.41,20.55,3.85,30.84,5.8
            c5.16,1.16,5.89,5.53,6.98,9.64C178.79,296.82,178.09,298.46,176.57,299.72z"
            />
            <path
              style="fill: #466628"
              d="M142.2,281.53c-2.49,5.55-13.68,6.31-8.76,15.94c-1.73,0.5-4.37,1.4-4.5-1.01
            c-0.89-16.25-7.98-6.33-13.45-3.35c-2.14-0.68-4.16-1.57-5.67-3.36c-0.64-1.04-0.97-2.16-1-3.38c0.38-3.31,1.62-6.05,4.84-7.52
            c4.96-1.26,9.98-2.27,14.97-3.36C134.72,274.17,139.48,275.47,142.2,281.53z"
            />
            <path
              style="fill: #637632"
              d="M151.58,315.63c2.16-5.37,6.3-6.27,11.38-5.04c4.42,4.69,13.54,1.54,15.96,9.57
            c-3.6,9.15-12.24,4.31-18.32,6.6C156,324.35,154.6,319.32,151.58,315.63z"
            />
            <path
              style="fill: #42502c"
              d="M160.6,326.76c4.61-6.37,10.62-8.83,18.32-6.6c2.65,3.38-0.73,7.97,2.25,11.29
            c-1.2,1.27-2.47,0.96-3.75,0.14c-6.18,3.36-11.77,13.21-19.27,1.71C158.78,331.05,167.23,331.74,160.6,326.76z"
            />
            <path
              style="fill: #556357"
              d="M158.15,333.29c6.7,2.65,12.86-0.84,19.26-1.71c-1.86,2.31-3.73,4.62-5.6,6.93
            c-2.91,2.25-5.7,4.67-8.76,6.69c-2.42,1.6-5.11,1.41-6.84-1.16c-2.23-3.31-5.14-6.82-3.04-11.05
            C154.16,330.98,156.44,333.2,158.15,333.29z"
            />
            <path
              style="fill: #405a25"
              d="M112.29,298.02c7.1-2.36,13.65-3.93,12.77,7.5c-0.15,1.91,4.08,3.04,1.66,5.55
            c-2.67,2.77-5.62-0.01-6.75-1.43c-4.54-5.71-9.3-2.04-14.04-1.12C102.59,301.71,105.96,298.97,112.29,298.02z"
            />
            <path
              style="fill: #51752b"
              d="M92.34,331.53c-6.16-0.18-6.74-4.33-6.45-9.09c2.23-2.25,4.46-4.51,6.68-6.76
            c1.14-5.28,3.48-8.17,9.1-4.62c1.35,0.67,6.36,0.22,3.05,2.51C97.98,318.22,93.9,323.76,92.34,331.53z"
            />
            <path
              style="fill: #405a25"
              d="M101.67,311.05c-3.2,1.22-6.9,1.44-9.1,4.62c-0.66-0.71-1.33-1.42-1.99-2.13
            c-1.76-3.84-4.3-7.6-1.15-11.91c0.96-5.73,5.24-6.34,9.86-6.43c1.07,0.2,2.06,0.63,2.96,1.25c3.1,3.54,1.45,7.9,1.97,11.89
            C103.37,309.24,102.52,310.14,101.67,311.05z"
            />
            <path
              style="fill: #6f962e"
              d="M104.23,308.33c-3.77-2.94-3.82-6.71-2.4-10.8c0.41-7.32,7.25-6.56,11.44-9.12
            c0.74,1.57,1.48,3.13,2.23,4.7c-1.08,1.63-5.41,1.13-3.21,4.91c-2.12,3.5-4.24,7-6.36,10.5
            C105.35,308.53,104.79,308.46,104.23,308.33z"
            />
            <path
              style="fill: #5f7a3d"
              d="M126.05,340.27c-3.4,1.07-5.29,5.01-9.32,4.88c0.13-0.73,0.11-1.55,0.43-2.18
            c2.15-4.21-10.04-6.19-2.22-10.27C120.81,329.63,123.42,336.03,126.05,340.27z"
            />
            <path
              style="fill: #1e2817"
              d="M-293.38,13.16c-1.44-0.58-2.89-1.16-4.33-1.73c3.85-5.43,3.36-12.09,5.27-17.99
            c1.13-3.49,4.24-8.49,10.15-5.15c3.72,0.72,2.75-3.31,4.57-4.52c-1.64-7.54,6.24-0.42,6.87-4.47c6.67,0.6,9.83,3.61,7.23,10.57
            c1.32,1.74,2.64,3.47,3.96,5.21c1.44,1.27,2.3,2.84,2.41,4.78c-0.46,4.16-2.86,7.09-6.63,8.3c-7.47,2.39-15.09,4.19-22.1,7.99
            C-288.84,17.7-291.86,16.52-293.38,13.16z"
            />
            <path
              style="fill: #35481e"
              d="M-284.43-34.45c0.85-4.43-4.68-8.93,0.16-13.31c8.14,4.09,8.73-1.25,8.88-7.13
            c2.65-6,9.05-7.65,13.46-11.61c5.06,0.86,10.07,0.35,15.05-0.7c7.86,1.19,9.03,7.02,9.36,13.45c-0.18,1.23-0.69,2.3-1.53,3.21
            c-5.03,2.99-5.73-0.38-6.13-4.19c-0.35-3.28-2.42-4.47-5.44-4.35c-2.39,0.09-4,1.19-5.44,3.29
            c-5.47,7.97-11.21,15.76-16.85,23.61c-4.02-0.07-8.18,0.55-11.65-2.3L-284.43-34.45z"
            />
            <path
              style="fill: #405b26"
              d="M-259.52-0.38c-0.05-1.51-0.09-3.03-0.14-4.54c2.67-1.77,13.41,5.07,6.89-6.48
            c1.2-1.53,2.65-1.77,4.33-0.87c2.98,15.77,4.48,17.09,16.08,14.11c3.1-0.94,4.13,1.23,5.14,3.43
            c2.41,9.43-8.21,11.19-10.5,17.85c-0.73,2.13-0.83,4.5-1.83,6.47c-3.05,6.03-8.01,6.68-10.68,0.67
            C-254.55,20.52-259.77,10.83-259.52-0.38z"
            />
            <path
              style="fill: #48682e"
              d="M-229.93,6.59c-0.81-1.58-1.62-3.17-2.43-4.75c-2-6.12-4-12.24-6.27-19.19
            c6.12,1.55,6.14,6.46,8.53,9.47c2.27,2.86,5.06,6.81,8.91,4.79c3.78-1.98,2.46-6.63,1.4-10.17c-0.62-2.09-2.13-3.91-3.24-5.85
            c-2.2-5.95,1.93-6.4,5.99-6.93c5.14,0.68,8.58,4.1,8.34,8.76c-0.37,7.16,2.43,13.72,2.48,20.69c-1.53,4.09-2.43,8.6-6.84,10.89
            C-220.76,16.33-225.8,12.46-229.93,6.59z"
            />
            <path
              style="fill: #37491e"
              d="M-230-29.99c-2.03-5.69,5.12-10.08,2.26-15.9c-5.68-4.68-5.11-10.88-4.19-17.17
            c0.57-1.1,1.42-1.95,2.5-2.54c7.57,1.11,17.68-6.48,21.91,6.33c-6.35,10.51-5.04,22.84-8.02,34.16
            C-221.39-23.68-227.3-22.09-230-29.99z"
            />
            <path
              style="fill: #506e28"
              d="M-229.85-64.11c1.4,5.99,2.65,12,2.11,18.22c-4.78,0.94-7.76-2.52-11.29-4.64
            c-0.02-0.76-0.06-1.51-0.12-2.26c-0.77-3.02-0.07-6.1,0.35-8.98C-237.8-68.74-234.7-70.47-229.85-64.11z"
            />
            <path
              style="fill: #6a8d75"
              d="M-248.44-12.28c-1.44,0.29-2.89,0.58-4.33,0.87c-3.29-4.26-0.33-10.84-5.4-14.59
            c-0.58-0.43-0.34-3.03,0.38-3.92c1.19-1.48,2.38-0.1,3.67,0.75C-247.79-24.96-247.31-18.92-248.44-12.28z"
            />
            <path
              style="fill: #5d8431"
              d="M-263.63-10.13c-0.95-4.53-2.5-8.64-7.23-10.57c-1.41-3.22-1.59-5.91,2.77-6.79
            c4.44-0.89,4.16,2.64,3.99,4.92C-264.43-18.37-254.82-14.52-263.63-10.13z"
            />
            <path
              style="fill: #4d6933"
              d="M-230-29.99c5.15-0.24,7.88,6.62,13.62,4.65c0.5,0.4,0.72,0.85,0.66,1.35
            c-0.06,0.52-0.16,0.78-0.3,0.78c-3.04,0.17-5.92,0.62-7,4.11C-229.49-20.08-232.1-23.52-230-29.99z"
            />
            <path
              style="fill: #63803a"
              d="M-284.55-34.47c4.3-1.36,9.09-5.18,11.65,2.3C-278.59-23.75-282.32-25.32-284.55-34.47z"
            />
            <path
              style="fill: #405b24"
              d="M-277.73-16.23c-0.3,4.1,4.94,6.48,3.57,11.12c-4.7,0.03-5.2-4.8-8.14-6.6
            C-281.88-14.34-280.46-15.96-277.73-16.23z"
            />
            <path
              style="fill: #32451d"
              d="M124.32,240.56c-1.5,2.07-2.99,4.13-5.29,7.31c-1.94-4.65-2.14-9.42-6.14-11.76
            c-1.37-2.82-3.95-4.13-6.53-5.6c-9.45-5.37-10.28-9.53-4.2-19.29c4.35-1.94,9.19-2.37,11.74,1.95c4.86,8.23,5.95,0.77,8.35-1.54
            c4.89-3.37,9.62-6.97,14.16-10.8c0.8-1.25,1.84-2.27,3.07-3.1c5.25-2.74,6.84-8.11,4.92-12.47c-5.47-12.47,2.66-10.64,9.8-10.36
            c3.81,6.51,6.05,13.82,10.36,20.08c0.52,3.42-1,6.14-2.91,8.74c-3.49,3.89-1.55,8.75-2.33,13.13c-0.64,3.67-2.74,6.1-6.18,7.41
            c-4.75-0.13-9.08-0.96-12.45,4.69C137.21,234.78,129.68,236.48,124.32,240.56z"
            />
            <path
              style="fill: #97b72c"
              d="M144.73,163.36c-4.62,1.2-10.02,0.06-13.56,4.51c-1.44,1-2.96,0.95-4.53,0.34
            c-5.45-2.4-9.21-6.05-9.18-12.5c0.62-4.39,4.09-6.37,7.29-8.56c2.35-1.8,5.35-3.12,6.76-5.71c6.07-11.25,14.24-9.57,23.2-4.19
            c2.01,2.34,2.97,4.82,0.9,7.64c-1.37,1.63-2.74,3.25-4.12,4.88C151.1,155.22,150.98,160.82,144.73,163.36z"
            />
            <path
              style="fill: #415a24"
              d="M192.87,195.33c-1.7,2.55-4.1,4-7.09,4.53c-0.8-0.8-1.61-1.61-2.42-2.41
            c-1.86,2.77-3.93,5.28-7.36,6.16c-1.12,0.07-2.2-0.12-3.25-0.53c-3.86-1.96-4.33-6.01-5.82-9.44c-1.16-2.82-1.6-5.9-2.99-8.67
            c-1.26-3.42-4.04-6.75-0.65-10.45c4.72,0.39,10.29-0.91,11.95,5.76c0.64,2.56,0.93,6.28,4.1,5.78c4.15-0.66,2.2-4.79,2.44-7.55
            c0.16-1.86,0.18-3.73,0.34-7.07C192.97,176.92,186.93,188.99,192.87,195.33z"
            />
            <path
              style="fill: #435325"
              d="M140.16,201.83c-3.7,5.98-9.51,9.03-15.79,11.43c-6.1-7.21-13.97-3.62-21.3-3.49
            c-2.05-1.26-3.28-3.07-3.71-5.43c-0.23-2.4,0.56-4.43,2.2-6.16c7.35-2.03,6.62-7.91,6.39-13.57c1.12-2.8,3.11-4.78,5.74-6.15
            c2.86-1.21,5.7-2.21,8.26,0.59c-3.79,7.55-2.81,14.48,2.73,20.8C129.94,199.81,136.02,193.2,140.16,201.83z"
            />
            <path
              style="fill: #5a792b"
              d="M167.53,183.8c0.54,3,7.71,4.4,2.14,8.88c-1.27,3.44-3.32,4.78-6.72,2.51
            c-9.13-2.8-12.45-10.51-8.75-20.29c1.35-0.11,2.7-0.23,4.06-0.34C163.36,175.62,163.19,181.97,167.53,183.8z"
            />
            <path
              style="fill: #3a5322"
              d="M155.61,144.89c-0.64-2.15-1.28-4.31-1.92-6.46c-2.05-2.47-2.32-5.12-0.82-7.95
            c1.46-1.73,2.72-3.67,4.83-4.73c1.09-0.33,2.2-0.41,3.32-0.22c2.72,1.86,5.87,2.68,8.99,3.59c1.46,0.92,2.43,2.21,2.91,3.87
            c0,0.55-0.01,1.1-0.02,1.65c-0.3,0.46-0.59,0.92-0.89,1.39C166.86,139.55,165.93,150.93,155.61,144.89z"
            />
            <path
              style="fill: #85ae28"
              d="M144.73,163.36c-0.19-5.75,2.66-9.99,6.77-13.59c1.97,4.3,5.41,8.44,2.96,13.5
            c-1.17,2.41-0.38,6.71-4.8,6.67C145.41,169.89,145.81,165.98,144.73,163.36z"
            />
            <path
              style="fill: #415a24"
              d="M177.22,159.02c-0.54,8.62-4.94,9.83-9.54,10.22c-2.1,0.18-5.56-2.73-4.67-3.18
            c4.92-2.46,2.73-10.32,8.27-11.02C174.75,154.62,177.27,157.63,177.22,159.02z"
            />
            <path
              style="fill: #405f26"
              d="M189.98,142.8c-1.99,6.85,1.41,13.7-0.17,22.87c-2.71-9.07-10.3-13.68-8.89-22.38
            C183.76,139.9,186.81,140.24,189.98,142.8z"
            />
            <path
              style="fill: #718e37"
              d="M140.16,201.83c-4.71-4.14-10.76,2.21-15.46-1.97c4.09-6.56,7.91-14.94,14.79-2.13
            C139.7,199.1,139.93,200.46,140.16,201.83z"
            />
            <path
              style="fill: #7ba92a"
              d="M189.98,142.8c-3.02,0.16-6.04,0.33-9.06,0.49c-5,0.07-9.4-0.6-8.91-7.27c0,0,0.4,0.46,0.4,0.46
            c3.42-2.27,6.25-6.79,11.25-2.9c-0.27,4.87,4.99,4.36,6.9,7.11C190.35,141.39,190.15,142.09,189.98,142.8z"
            />
            <path
              style="fill: #7ca92e"
              d="M121.96,179.06c-2.09,0.72-4.19,1.44-6.28,2.16c-2.48-1.61-2.09-3.75-1.12-6
            c4.06-4.24,6.46-10.93,14.23-9.76c0.8,0.8,1.59,1.61,2.39,2.41C130.71,173.75,124.31,174.74,121.96,179.06z"
            />
            <path
              style="fill: #85ac27"
              d="M167.53,183.8c-6.76,0.61-6.2-6.14-9.27-9.25c1.67-1.21,3.34-2.21,5.03-0.03
            C164.7,177.62,166.11,180.71,167.53,183.8z"
            />
            <path
              style="fill: #4a6a24"
              d="M163,113.45c0.69,1.54,1.39,3.09,2.08,4.63c-3.87,4.43-9.37,4.55-14.56,5.51
            c-3.46,0.76-6.91,1.61-10.26-0.4c-1.68-0.81-3.02-2.03-4.22-3.43c-5.14-8.3-1.4-15.04,3.77-21.54
            c4.51-2.46,8.12-0.88,11.31,2.54c1.32,1.08,2.5,2.3,3.31,3.82C155.64,109.13,159.42,111.2,163,113.45z"
            />
            <path
              style="fill: #7ba92a"
              d="M171.87,134.19c-0.56-0.83-1.12-1.66-1.68-2.49c1.81-4.49,0.12-10.05,4.24-13.82
            c5.07-0.71,10.14-1.86,15.22-2c4.43-0.12,9.03,0.67,10.13,6.39c-0.89,1.83-1.87,5.49-4.22,2.81c-7.49-8.54-9.55-1.3-12.58,3.74
            C180.5,133.13,177.29,135.96,171.87,134.19z"
            />
            <path
              style="fill: #597f2b"
              d="M167,83.5c2.78,13.41,3.84,14.09,14.14,9.23c0.3,8.48-2.71,10.59-19.94,14.05
            c-0.96-0.57-1.59-1.39-1.85-2.49c2.92-6.65-2.06-15.05,4.6-20.89C164.98,82.98,166,83.02,167,83.5z"
            />
            <path
              style="fill: #2b3b1b"
              d="M174.43,117.88c-2.48,4.28,6.2,11.99-4.24,13.82c-3.96,0.16-9.48,3.55-9.45-4.57
            c0.17-5.77,6.64-3.56,8.72-6.91C171.12,119.44,172.78,118.66,174.43,117.88z"
            />
            <path
              style="fill: #9ab12e"
              d="M169.46,120.22c0.78,6.96-6.39,3.88-8.72,6.91c-0.62,0.05-1.23,0.18-1.83,0.38
            c-4.09-0.91-8.44-1.56-7.25-7.55c3.71-6.13,8.9-1.59,13.42-1.88C166.54,118.8,168,119.51,169.46,120.22z"
            />
            <path
              style="fill: #9ab12e"
              d="M161.04,104.33c0.05,0.81,0.1,1.63,0.16,2.44c0.6,2.23,1.2,4.45,1.8,6.68
            c-4.24-1.91-15.95,7.54-11.5-7.58C154.14,102.01,157.21,100.79,161.04,104.33z"
            />
            <path
              style="fill: #81a12f"
              d="M167,83.5c-0.5,0.16-1.01,0.3-1.52,0.43c-5.03,1.59-7.61-0.46-8.15-5.52
            c-0.2-1.76-0.65-3.5-0.4-5.29c0.83-3.08,1.86-5.96,5.92-5.6c2.96,4.01,2.21,10.92,9.18,11.8C170.36,80.71,168.68,82.1,167,83.5z
            "
            />
            <path
              style="fill: #85ae26"
              d="M172.06,79.23c-0.28-8.03,6.29-9.02,11.6-11.32C185.12,77.16,177.21,76.77,172.06,79.23z"
            />
            <path
              style="fill: #789f2a"
              d="M191.96,79.76c0.06,2.18-2.04,3.11-4.28,3.63c-1.39,0.32-3.3,0.44-2.02-2.12
            c0.81-1.62-1.61-4.61,2.24-4.99C190.15,76.06,191.58,76.61,191.96,79.76z"
            />
            <path
              style="fill: #5a7332"
              d="M-287.21-107.73c-2.81-10.08,7.11-3.9,9.45-7.41c6.76-1.56,12.61-7.81,20.41-3.92
            c1.79-3.16,2.92-7.05,8.08-5.58c6.43,1.83,7.04,6.97,7.51,12.29c1.64,5.91,0.45,12.08,1.52,18.07c0.26,3.48,0.23,6.9-2.01,9.88
            c-6.57,4.98-11.01,2.39-14.34-4.06c-0.32-1.05-0.39-2.16-0.31-3.26c-0.03-3.09,4.5-6.34-0.31-9.19
            c-2.56,2.95-4.28,7.09-9.3,6.02c-5.09-4.38-9.57-9.85-17.22-9.69C-285.16-105.32-286.48-106.2-287.21-107.73z"
            />
            <path
              style="fill: #3f4c26"
              d="M-245.75-110.53c0.13-8.3-0.92-14.97-11.6-8.53c0.01-2.28,0.01-4.56,0.02-6.84
            c0.15-4.69,3.48-5.17,6.96-4.98c5.24,0.29,10.23-0.99,15.29-1.9c3.18,2.25,6.67,0.29,9.98,0.72c2.01,1.18,3.3,2.68,2.29,5.19
            c-5.12,4.4-7.9,10.78-12.81,15.39C-238.92-110.35-241.9-105.68-245.75-110.53z"
            />
            <path
              style="fill: #7c9133"
              d="M-235.09-132.78c-5.92,7.13-14.33,6.2-22.25,6.88c-9.78-1.79-5.32-8.02-4.12-13.23
            c0.63-1.12,1.22-1.1,1.77,0.06c3.33,4.18,7.06,6.15,11.89,2.2c0.79-0.64,1.53-0.58,2.21,0.18c2.5,2.31,5.58,2.53,8.75,2.4
            C-236.25-133.79-235.67-133.28-235.09-132.78z"
            />
            <path
              style="fill: #5d792c"
              d="M-277.67-116.12c-4.33-2.78-8.67-5.56-12.99-8.36c0.33-0.68,0.52-1.83,0.98-1.95
            c5.39-1.41,8.68-11.73,15.2-5.62C-269.12-127.02-276.35-121.49-277.67-116.12z"
            />
            <path
              style="fill: #7cb228"
              d="M-259.69-139.07l-0.89-0.06c0,0-0.89,0-0.89,0c-4.25-2.66-3.18-5.53,4.28-11.52
            C-258.01-146.79-258.85-142.93-259.69-139.07z"
            />
            <path
              style="fill: #637849"
              d="M176.46-134.55c5.52,10.82,1.8,23.38,6.45,34.37c-3.71,4.24-8.12,2.93-12.56,1.61
            c-9.84-10.24-3.59-20.23,0.47-30.27C171.55-131.89,173.03-134.19,176.46-134.55z"
            />
            <path
              style="fill: #899b8b"
              d="M176.46-134.55c-0.72,2.4-1.44,4.79-2.15,7.19c-4.54,5.18-8.15,3.75-9.15-2.28
            c-1.07-6.44-2.46-13.58,4.35-18.42c0.11-3.23,1.91-5.05,4.9-5.87C179.85-147.98,173.23-140.74,176.46-134.55z"
            />
            <path
              style="fill: #96a666"
              d="M171.6-100.23c3.78-3.5,7.53,0.69,11.31,0.05c0.41,2.45,1.32,4.98-1.24,6.9
            c-3.2-0.13-6.39-0.26-9.59-0.39C170.27-95.74,169.86-97.9,171.6-100.23z"
            />
            <path
              style="fill: #ccb8a1"
              d="M-186.2-120.99c-2.27,7.49-6.34,11.28-14.34,6.98c-2.93,0.63-3.87-1.39-4.72-3.54
            c1.68-6.92-3.86-13.47-1.09-20.46c0.39-0.37,0.8-0.73,1.2-1.09c3.82,2.3,6.04,7.46,11.67,6.51c6.06,1.71,2.83,8.72,6.67,11.7
            L-186.2-120.99z"
            />
            <path
              style="fill: #c0b37c"
              d="M-211.79-150.66c1.85,5.24,5.89,2.15,9.06,2.39c2.14,2.31,1.65,4.6-0.07,6.88
            c-0.79,0.76-1.58,1.53-2.36,2.29c0,0,0.27-0.03,0.27-0.03c-4.54,3.97-8.69,4.14-12.24-1.3c-0.46-1.13-0.75-2.31-0.87-3.52
            C-219.58-149.58-216.29-150.69-211.79-150.66z"
            />
            <path
              style="fill: #9e8c5b"
              d="M-202.8-141.39c0.02-2.29,0.05-4.59,0.07-6.88c-0.36-4.95,2.29-7.05,6.91-7.28
            C-188.55-146.09-197.14-144.46-202.8-141.39z"
            />
            <path
              style="fill: #cfd2c7"
              d="M-186.82-120.89c-5.39-2.09-3.92-8.1-6.67-11.69c0.16-4.63,1.99-6.34,4.25-1.33
            C-187.47-130.01-187.55-125.27-186.82-120.89z"
            />
            <path
              style="fill: #2e3a20"
              d="M167.37-93.66c1.36,3.02,4.34,3.66,7,4.76c7.34,4.51,5.23,14.22,10.8,19.71
            c0.2,0.84,0.4,1.69,0.59,2.53c0.11,4.56,2.72,9.41-1.45,13.5c-1.11,1.19-2.34,2.33-2.13,4.23c0.7,2.38,1.2,4.74,0.06,7.11
            c-2.54,3-4.83,6.26-8.58,7.95c-4.05,0.46-8.18,1.27-11.49-2.18c-2.18-6.2-1.64-13.39-6.61-18.64
            c-1.64-5.22,2.05-10.57-0.39-15.78c-0.17-1.59-0.13-3.17-0.04-4.76c1.06-5.66,1.25-11.37,0.98-17.13
            c1.56-3.52,4.19-5.27,8.09-4.93C165.99-96.73,167.04-95.51,167.37-93.66z"
            />
            <path
              style="fill: #4a5b2b"
              d="M180.94-44.17c-1.44-1.35-2.88-2.7-4.32-4.06c2.64-1.47,2.34-4.88,4.45-6.71
            c6.13-2.08,1.5-8.47,4.69-11.72c3.9,17.8,7.81,35.61,11.71,53.41c-6.23,2.37-5.96,5.87-1.74,10.05c0.51,5.02-3.21,6.39-6.79,7.9
            c-3.44,0.92-6.18-0.89-9.01-2.32c-4.18-2.73-8.71-5.22-8.3-11.33c0.94-3.99,3.12-7.17,6.4-9.61c1.96-1.16,4.36-1.77,5.43-4.13
            c1.7-3.32-0.62-6.22-0.98-9.31c0.22-3.2,0.95-6.46-0.92-9.46C181.24-42.33,181.03-43.24,180.94-44.17z"
            />
            <path
              style="fill: #566533"
              d="M185.16-69.19c-14.03-0.84-9.79-11.71-10.8-19.71c5.34,1.26,10.68,2.52,16.01,3.77
            C183.25-81.58,186.16-74.75,185.16-69.19z"
            />
            <path
              style="fill: #68833b"
              d="M187.52,283.63c-1.6,5.52,7.61,7.42,4.5,13.45c-2.03,0.05-4.05,0.11-6.08,0.16
            c-5.79-5.46-11.87-10.76-11.59-19.8c1.49-2.68,2.97-5.44,6.4-2.06c0.71,3.88,4.31,4.56,6.96,6.29
            C187.73,282.33,187.67,282.98,187.52,283.63z"
            />
            <path
              style="fill: #343e1d"
              d="M176.5,279.34c1.5,6.84,10.89,9.5,9.44,17.9c-2.9,1.67-5.48,4.53-9.37,2.48
            c-0.09-1.37-0.18-2.73-0.27-4.1c0.51-4.24,0.09-7.8-4.66-9.79c-3.87-1.62-4.54-4.45-1.13-7.54
            C172.74,277.26,174.56,278.66,176.5,279.34z"
            />
            <path
              style="fill: #36471e"
              d="M-259.52-0.38c8,8.78,12.58,19.15,12.81,34.37c3.04-6.22,4.41-9.02,5.79-11.83
            c2.63-2.58,5.66-2.69,8.93-1.51c4.56,2.11,7.99,5.8,10.02,10.03c2.64,5.52,4.27,11.79-5.02,13.3c-4.81,0.48-7.36-5.16-12.84-4.7
            c1.8,2.27,4.87,2.42,5.74,4.91c2.96,8.07-0.33,14.76-4.62,21.22c0.52,10.95,4.9,20.63,9.75,30.19c-0.03,1.78-0.55,3.4-1.55,4.87
            c-2.04,2.44-5.03,2.79-7.79,3.64c-1.1,0.08-2.19-0.06-3.25-0.39c-3.01-4.83-8.51-1.33-12.31-3.6c-1.26-1.03-2.11-2.35-2.61-3.89
            c0.29-8.36-9.34-7.17-12.12-12.58c-2.79-4.46-8.35-5.48-11.6-9.75c-2.47-3.23-3.98-5.9-2.99-9.81c0.21-1.09,0.63-2.09,1.23-3.02
            c1.35-4.73,7.42-3.73,9.39-7.73c0.74-8.9-8.31-18.62,2.25-26.69c1.62,0.74,2.85,0.09,2.92-1.51c0.09-2.28-1.86-2.58-3.62-2.83
            c-0.31,0.19-0.61,0.42-0.94,0.58c-16.43,8-17.56,7.49-21.42-9.74C-282.99,6.4-269.87,6.47-259.52-0.38z"
            />
            <path
              style="fill: #788152"
              d="M-272.57,53.35c2.1,2.49,5.5,5.52-0.94,6.26c-3.03,0.35-4.1,2.55-6.08,3.87
            c-7.05-4.34-12.58-9.57-11.02-18.95c1.49-1.9,3.33-2.89,5.79-2.17C-280.73,46.03-276.65,49.69-272.57,53.35z"
            />
            <path
              style="fill: #a0a27f"
              d="M-288.93,45.12c-0.3,7.86,9.95,10.35,9.34,18.37c0,0-0.04,0.07-0.04,0.07
            c-1.44,1.94-2.91,3.84-5.11,5.01c-3.61,0.09-2.65-3.43-4.01-5.11c-0.42-6.44-4.14-11.45-7.42-16.57
            c-2.03-3.17,0.31-3.64,2.23-4.58C-291.71,42.26-289.87,42.89-288.93,45.12z"
            />
            <path
              style="fill: #85a13b"
              d="M-271.03,22.32c2.86-2.55,6.32-7.59,9.67-3.14c2.75,3.65-0.92,9.45-4.09,11.93
            c-4.94,3.86-5.13-0.76-4.88-4.45C-270.56,25.22-270.79,23.77-271.03,22.32z"
            />
            <path
              style="fill: #b5ac99"
              d="M-288.75,63.46c1.29,1.5,2.58,3,3.88,4.5c2.19,3.81,8.62,7.53,0.22,11.54
            c-1.57-1.59-3.14-3.18-4.71-4.76C-289.16,70.97-288.96,67.22-288.75,63.46z"
            />
            <path
              style="fill: #acb1ba"
              d="M-288.93,45.12c-1.67-0.94-3.34-1.87-5.01-2.81c0.8-3.05,2.07-6.3,5.61-6.02
            c2.97,0.23,3.81,3.17,3.52,6.08C-286.18,43.28-287.55,44.2-288.93,45.12z"
            />
            <path
              style="fill: #181d12"
              d="M-43.65-114.17c1.82-8.55,6.16-14.15,15.81-13.55c7.66-0.35,9.76,3.27,8.3,10.53
            c-1.06,5.28-3.24,12.43,7.14,10.02c1.93,0.52,3.13,1.87,4.05,3.55c2.34,3.54,2.4,7.01-0.32,10.39
            c-4.82,2.26-9.28,1.06-13.61-1.4c-2.49-2.5-4.51-6.03-8.78-1.96c-3.45,3.28-7.28,2.67-10.75-0.48
            c-2.58-3.85-3.44-8.29-4.35-12.72C-45.94-111.61-45.01-113.01-43.65-114.17z"
            />
            <path
              style="fill: #879656"
              d="M-7.28-120.91c-0.18-0.7-0.26-1.41-0.25-2.13c0.49-0.99,1.19-1.78,2.12-2.39
            c11.67-5.84,20.28-0.05,28.46,7.5c2.46,2.88,2.97,6.18,2.09,9.76c-4.34,6.93-11.6,7.98-18.76,9.27c-0.92-0.5-1.71-1.18-2.36-2
            c-2.08-4.17-0.23-10.28-6.19-12.56C-5.18-115.06-6.08-118.09-7.28-120.91z"
            />
            <path
              style="fill: #6f7b3f"
              d="M-7.28-120.91c2.32,1.56,4.63,3.12,6.95,4.68c1.54,1.28,1.98,2.98,1.87,4.9
            c-2.26,6.38-5.61,11.08-13.51,8.72c-0.14-1.52-0.28-3.03-0.42-4.55C-12.45-112.41-10.01-116.71-7.28-120.91z"
            />
            <path
              style="fill: #6f7b3f"
              d="M-4.83-123.22c-0.9,0.06-1.8,0.12-2.7,0.18c-5.92-1.61-4.66-7.41-6.6-11.34
            c4.35-2.07,6.11-7.68,11.45-8.43c1.18,0.01,2.24,0.36,3.2,1.03C5.6-133.62,3.64-127.48-4.83-123.22z"
            />
            <path
              style="fill: #80913e"
              d="M-3.03-141.08c-1.87,5.27-3.58,10.8-11.1,6.69c-0.59-3.82-1.18-7.64-1.78-11.46
            c2.26-2.6,4.68-1.89,7.14-0.36C-6.57-144.81-3.96-143.89-3.03-141.08z"
            />
            <path
              style="fill: #a1ac3c"
              d="M-9.54-143.69c-2.12-0.72-4.25-1.44-6.37-2.16c-1.57-2.23-3.9-4.42-0.43-6.96
            c3.38-1.82,5.84-0.22,8.11,2.19c0.31,0.97,0.61,1.91,0.9,2.84C-6.45-145.54-7.8-144.51-9.54-143.69z"
            />
            <path
              style="fill: #35461e"
              d="M-255.47-90.92c3.38,2.41,5.38,9.04,11.3,3.72c10.11-4.48,13.14-2.97,17.11,8.55
            c-0.07,1.31-0.41,2.55-1.02,3.72c-1.51,2.18-3.58,3.34-6.24,3.39c-6.11-1.95-11.85-5.53-18.86-3.52
            c-2.44,0.7-5.08-0.39-6.31-3.19c-4.58-5.95-10.82-8.43-18.11-8.73c-1.67-3.19-3.34-6.38-5.01-9.58
            c-2.24-3.74-4.41-7.47,0.52-10.87c10.12-3.12,16.51-0.94,15.92,11.2C-262.89-93.86-256.97-96.85-255.47-90.92z"
            />
            <path
              style="fill: #61852e"
              d="M-259.47-78.25c4.35-0.62,8.76-0.99,13.05-1.92c6.37-1.38,10.99-0.1,12.59,6.95
            c0.4,8.28-4.39,9.06-10.76,7.37c-3.17-0.84-5.51-4.25-9.35-2.98c-2.67,1.26-5.21,2.83-8.19,3.33c-2.32,0.31-3.08-2.13-4.86-2.76
            C-270-75.74-260.7-73.95-259.47-78.25z"
            />
            <path
              style="fill: #5a7a2b"
              d="M-302.88-62.04c0.75-3.03,2.37-5.22,5.35-6.59c4.4-2.03,2.52-3.5-0.67-4.61
            c2.03-4.77,6.07-1.46,9.09-2.27c1.77,2.1,5.95,2.51,4.7,6.72C-289.94-64.83-292.92-53.89-302.88-62.04z"
            />
            <path
              style="fill: #739d2e"
              d="M-284.41-68.8c-1.85-2.04-7-1.77-4.7-6.72c6.23-1.03,5.43-9.3,11.15-10.85
            c0.29,4.08-0.01,8.31,1,12.2C-274.6-65.18-278.83-66.28-284.41-68.8z"
            />
            <path
              style="fill: #567f2d"
              d="M-267-68.25c1.64,0.66,3.29,1.33,4.93,1.99c0.57,0.28,0.84,0.66,0.82,1.12
            c-0.03,0.5-0.12,0.76-0.27,0.76c-4.62,3.17-9.24,6.33-13.86,9.5C-277.81-62.63-266.58-61.78-267-68.25z"
            />
            <path
              style="fill: #6f9a2c"
              d="M-282.08-107.42c-0.17,3.62-0.34,7.25-0.52,10.87c-6.59-0.86-3.13-7.57-6.12-10.53
            c0.49-0.24,1-0.46,1.51-0.65C-285.5-107.63-283.79-107.53-282.08-107.42z"
            />
            <path
              style="fill: #465631"
              d="M-254.93,129.18c-4.45-0.15-7.93,2.03-11.19,4.72c-0.88-0.07-1.77-0.15-2.65-0.22
            c-8.45-0.7-10.57-8.14-14.53-13.63c-3.26-3.18-3.77-7.17-3.39-11.43c-1.36-2.04-5.08-3.25-2.35-6.72
            c10.15-2.52,20.27,0.58,30.43,0.18c7.38,5.8,8.23,14.12,8.68,22.59C-250.81,127.06-253.13,127.83-254.93,129.18z"
            />
            <path
              style="fill: #758763"
              d="M-279.99,115.83c2.54,0.59,6.39,2.2,5.62,4.07c-2.84,6.92,3.74,9.26,5.6,13.78
            c-5.51,6.01-11.01,12.02-16.52,18.02c-5.42-1.01-4.85-6.86-4-8.72c3.43-7.46-0.1-14.33-0.31-21.42
            C-288.99,115.3-283.45,117.31-279.99,115.83z"
            />
            <path
              style="fill: #82908b"
              d="M-286.81,122.54c3.05,9.59-0.16,19.51,1.52,29.17c-0.45,6.83-7.24,4.39-10.47,7.16
            c-0.82-1.33-1.64-2.66-2.46-3.99c0.56-3.43,8.37-5.07,2.5-10.1c-2.96-6.26,9.32-8.64,3.82-16.5c-1.86-2.65-2.8-7.25-4.54-10.82
            C-289.03,111.19-289.81,120.45-286.81,122.54z"
            />
            <path
              style="fill: #58732f"
              d="M173.59,47.5c-2.13,2.19-5.84,2.62-6.78,6.12c-2.72,1.73-5.14,4.11-8.72,3.97
            c-1.11-0.02-2.2-0.21-3.26-0.54c-2.8-1.23-4.34-3.37-4.69-6.4c0.07-3.52,2.3-5.87,4.48-8.26c2.85-4.91,7.94-6.34,12.76-8.21
            c6.4,0.66,7.46,5.07,7.28,10.36C174.32,45.53,173.97,46.52,173.59,47.5z"
            />
            <path
              style="fill: #c4d356"
              d="M156.55,54.53c0.53,0.04,1.05,0.14,1.57,0.29c5.22,1.58,6.79,5.01,5.1,10.09
            c-0.12,0.87-0.24,1.74-0.36,2.61c-2.46,1.71-2.35,4.32-2.38,6.87c-1.64,2.97-2.57,6.76-7.2,6.34c-1.81-0.44-3.14-1.5-4.06-3.1
            c-1.72-3.45-0.17-6.68,0.7-9.95C151.59,63.02,148.89,56.15,156.55,54.53z"
            />
            <path
              style="fill: #81a12f"
              d="M163.22,64.91c-1.7-3.36-3.4-6.73-5.1-10.09c2.89-0.48,5.51-2.9,8.69-1.2
            c-0.17,1.98-1.46,4.15,0.95,5.7c2.85,0.9,5.15,2.16,3.89,5.82C168.84,65.06,166.03,64.98,163.22,64.91z"
            />
            <path
              style="fill: #c8d2a3"
              d="M173.59,47.5c-0.37-0.76-0.74-1.53-1.11-2.29c2.45-6.7,6.15-9.96,12.95-4.73
            c1.22,2.89,0.45,5.08-2.32,6.55C179.94,47.19,176.76,47.34,173.59,47.5z"
            />
            <path
              style="fill: #a5c41d"
              d="M171.65,65.13c-1.3-1.94-2.6-3.88-3.9-5.82c2.48-1.23,5.39-4.07,7.55-0.68
            C177.33,61.8,174.49,63.84,171.65,65.13z"
            />
            <path
              style="fill: #536636"
              d="M26.2-135.2c4.65-1.32,9.3-2.63,13.95-3.95c9.08,0.95,7.68,8.04,8.2,13.98
            c-0.09,1.06-0.22,2.16-0.35,3.22c-1.03,3.03-2.4,6.1,0.61,8.91c1.17,1.95,1.61,4.05,1.41,6.31c-0.15,1.2-0.55,2.3-1.19,3.32
            c-1.59,1.91-3.56,3.2-6,3.75c-1.79,0.24-3.59,0.24-5.38,0c-6.21-1.73-10.41-5.43-11.94-11.91c-0.71-3.01-2.53-5.33-5.09-7.05
            c-1.76-2.55-1.89-5.22-0.55-7.99C21.66-129.71,25.17-131.53,26.2-135.2z"
            />
            <path
              style="fill: #c0c79e"
              d="M22.45-127.65c-0.07,2.92-0.14,5.84-0.21,8.76c0.01,1.42-0.87,2.38-1.72,3.36
            c-4.51-1.61-13.46,1.73-8.86-10.73c-5.96,5.02-11.1,5.7-16.49,3.04c2.26-5.65,5.03-11.16,4.98-17.47
            c3.19-3.74,4.54-0.34,6.26,1.62c1.96,0.16,3.93,0.32,5.88,0.48c1.01,2.88,2.02,5.77,3.04,8.65
            C17.51-128.57,20.65-130.22,22.45-127.65z"
            />
            <path
              style="fill: #a1a781"
              d="M44.99-123.59c-2.53-4.9-0.51-11.22-4.83-15.56c0.61-2.11,1.23-4.22,1.85-6.34
            c3.08,1.93,6.13,3.95,9.95,4.09c2.22,3.29,6.66-1.64,8.7,2.35c1.87,1.94,3.15,4.12,2.81,6.96c-1.1,6.71-5.27,10.32-11.72,11.63
            C48.86-120.13,46.71-121.41,44.99-123.59z"
            />
            <path
              style="fill: #596d3d"
              d="M22.45-127.65c-2.49-0.39-5.91,2.13-7.12-2.28c2.66-3.75,6.98-4.07,10.88-5.26
            c1.28,2.45,1.66,5.02,0.78,7.67c-0.41,1.23,0.23,3.56-1.84,3.25C24.1-124.43,23.34-126.47,22.45-127.65z"
            />
            <path
              style="fill: #3e5321"
              d="M-259.52,104.19c-10.22,4.09-20.26,5.92-29.53-2.29c0.04-0.75,0.11-1.49,0.19-2.24
            c2.06-2.11,4.06-4.31,7.16-4.89c2.07-2.34,1.41-5.38,2.07-8.09c2.69-5.72,6.71-8.19,12.91-5.29
            c6.55,2.66,14.85,3.52,14.02,13.73C-257.05,96.58-256.69,101.58-259.52,104.19z"
            />
            <path
              style="fill: #778a4a"
              d="M-266.71,81.4c-3.56,2.43-8.39,2.9-10.84,7.04c-2.84-1.07-5.44-2.5-7.23-5.07
            c0.04-1.29,0.08-2.58,0.13-3.88c4.72-3.93-1.2-7.67-0.21-11.54c1.09-2.25,2.87-3.68,5.24-4.4
            C-280.19,73.03-269.05,74.03-266.71,81.4z"
            />
            <path
              style="fill: #908e63"
              d="M-284.79,83.37c4.02-0.61,7.53-0.5,7.23,5.07c-0.29,2.83-0.54,5.68-4.14,6.34
            C-280.78,90.45-286.63,87.95-284.79,83.37z"
            />
            <path
              style="fill: #828e46"
              d="M99.47-107.34c1.53-0.66,3.07-1.32,4.6-1.98c3.41-0.91,3.61-3.71,3.07-6.27
            c-1.1-5.19,2.07-7.51,5.74-9.69c0.9-0.99,1.81-1.98,2.71-2.98c0.7,1.65,1.41,3.3,2.11,4.94c2.2,4.32,0.26,10.54,6.07,13.21
            c0.83,0.68,1.47,1.5,1.96,2.44c0.53,1.55,0.46,3.08-0.2,4.58c-2.52,3.11-5.12,6.15-8.65,8.22c-3.47,0.85-5.47,2.89-6.77,6.39
            c-1.39,3.75-3.63,7.38-8.79,6.56c-3.78-2.5-3.44-8.19-7.9-10.19c-2.15-2.51-2.23-5.43-1.69-8.48
            C92.7-104.69,95.19-107.04,99.47-107.34z"
            />
            <path
              style="fill: #bec9aa"
              d="M130.76-129.61c2.83-3.67,0.9-10.33,7.36-11.71c6.22-0.99,4.66,5.69,7.74,7.82
            c1.6,2.83,3.51,5.56,3.05,9.09c-0.24,1.18-0.78,2.2-1.6,3.09c-4.19,2.74-8.56,4.6-13.69,2.83c-1.13-0.59-2.04-1.41-2.74-2.46
            C129.4-123.82,128.46-126.69,130.76-129.61z"
            />
            <path
              style="fill: #919e57"
              d="M139.01-130.07c0.82-3.84,2.28-7.73-0.88-11.26c-2.42-2.15-4.83-4.3-7.25-6.45
            c3.92-2.06,4.82-5.7,5.04-9.69c2.49,8.06,8.79,12.46,15.63,16.34C150.66-133.7,146.97-129.46,139.01-130.07z"
            />
            <path
              style="fill: #9aa46c"
              d="M122.15-107.33c-4.36,1.04-7.53,0.47-6.6-5.16c0.6-3.63,1.43-7.21,2.16-10.82
            c0.88,1.61,1.76,3.23,2.63,4.84C125.92-115.57,124.91-111.59,122.15-107.33z"
            />
            <path
              style="fill: #9aa46c"
              d="M130.76-129.61c0.8,2.12,1.6,4.25,2.41,6.37c-1.25,3.34-2.64,6.54-6.93,6.76
            c-1.84-0.11-3.3-0.91-4.34-2.45C124.59-122.71,125.28-128.15,130.76-129.61z"
            />
            <path
              style="fill: #bcc2b6"
              d="M121.9-118.93c1.28,0.22,2.56,0.45,3.84,0.67c4.75,4.52,5.27,8.5-1.48,11.55
            c-0.7-0.21-1.4-0.42-2.1-0.63c-0.6-3.71-1.21-7.42-1.81-11.13C120.87-118.58,121.39-118.74,121.9-118.93z"
            />
            <path
              style="fill: #77723c"
              d="M-216.34-143.7c3.61,2.03,5.99,7.18,11.45,4.57c0.99,6.98,8.61,13.13,2.65,20.99
            c-2.55,0.53-5.11,1.1-7.74,0.53c-5.57-0.93-7.96-6.27-12.32-8.9c-0.26-0.44-0.51-0.89-0.75-1.35c-0.69-1.4-1.37-2.8-2.06-4.2
            c2.35-0.56,3.97-1.91,4.58-4.32C-219.38-138.96-218.79-141.86-216.34-143.7z"
            />
            <path
              style="fill: #3b3c1b"
              d="M-216.34-143.7c0.11,3.3-0.53,6.18-4.19,7.32c-1.66-1.98-5.84-0.15-6.22-3.56
            c-0.42-3.77,3.36-4.34,5.7-5.94c1.02,0.64,2.04,1.28,3.06,1.93C-217.43-143.93-216.88-143.84-216.34-143.7z"
            />
            <path
              style="fill: #7fa728"
              d="M-245.59-136.69c-0.73-0.08-1.47-0.14-2.21-0.18c-0.12-3.48-1.24-7.2,2.76-10.55
            C-243.02-143.38-242.38-140.03-245.59-136.69z"
            />
            <path
              style="fill: #29361b"
              d="M162.95,195.19c2.24-0.83,4.48-1.67,6.72-2.5c1.51,2.79,3.03,5.59,4.54,8.38
            c0.73,6.84,3.25,12.02,11.24,12.12c1.8,1.27,9.21-10.27,6.09,2.78c-0.83,3.46,2.35,1.07,3.77,1c3.34-0.18,4.4,3.22,2.83,4.01
            c-6.56,3.28-0.92,9.15-3.47,13.03c-4,3.18-9.52,6.05-2.31,11.48c0.69,3.39-3.87,5.44-2.3,9.05c-1.5,1.3-3.01,2.6-4.51,3.9
            c-0.71,3.62,0.37,7.68-2.63,10.75c-2.74,1.43-5.34,1.08-7.84-0.59c-12.3-9.38-14.02-24.85-3.71-32.61
            c0.57-0.45,0.75-0.77,0.35-0.77c-5.28,2.34-8.09,6.01-7.35,11.88c-0.05,2.59-0.34,5.18,0.3,7.76c0.66,3.84-0.64,6.81-3.89,8.92
            c-6.01,1.57-13.25,9.84-16.66-3c0.69-3.16,2.46-6.24-0.17-9.48c-6.21-7.66-0.07-15.56,0.01-23.34c0.03-3.59,4.28-4.72,7.63-5.53
            c1.53-2.36,3.06-4.71,4.6-7.07c5.33-3.61-0.65-9.31,2.46-13.34C160.08,199.74,161.51,197.46,162.95,195.19z"
            />
            <path
              style="fill: #65852d"
              d="M185.45,213.18c-5.66,7.88-11.02-3.48-16.99-0.43c1.92-3.89,3.83-7.79,5.75-11.68
            c0,0,0.11,0.99,0.11,0.99c3.06,1.68,6.71,2.77,7.01,7.16C182.7,210.54,184.07,211.86,185.45,213.18z"
            />
            <path
              style="fill: #97b749"
              d="M181.32,209.22c-3.3-1.45-6.81-2.67-7.01-7.16c3.02-1.54,6.03-3.07,9.05-4.61
            C180.59,201.01,187.63,206.27,181.32,209.22z"
            />
            <path
              style="fill: #5b7129"
              d="M99.47-107.34c-0.25,3.22-2.89,4.55-4.97,6.33c-1.92,3.14-4.34,5.47-8.37,5.22
            c-2.34-0.42-4.19-1.65-5.64-3.51c-1.64-3.43,0.29-7.31-1.36-10.76c0.17-8.75,8.58-6.91,13.04-10.18
            c3.24-0.43,5.14,1.64,7.01,3.77C99.29-113.42,99.38-110.38,99.47-107.34z"
            />
            <path
              style="fill: #4f6126"
              d="M112.89-125.28c-4.32,4.52-2.44,9.83-1.83,14.94c0.42,3.58,2.6,8.42-3.21,8.77
            c-5.14,0.31-3.23-4.75-3.78-7.75C103.32-116.68,102.34-124.17,112.89-125.28z"
            />
            <path
              style="fill: #627b2c"
              d="M90.48-139.3c5.24,0.84,10.48,1.68,15.73,2.53c2.19,5.39,0.52,9.14-4.81,11.34
            c-3.55-0.76-7.56,4.23-10.8-0.83c-2.29-3.8-3.25-7.75-1.32-12.02C89.67-138.62,90.08-138.96,90.48-139.3z"
            />
            <path
              style="fill: #7d9e2b"
              d="M92.72-127.56c2.15,3.77,7.04-3.72,8.68,2.12c-2.37,2.59-1.89,5.89-2.21,8.98
            c-2.35-0.43-4.7-0.85-7.05-1.27c-3.95,1.67-8.23,2.62-11.22-1.05c-2.66-3.26-0.12-6.2,2.38-8.64
            C86.41-129.39,89.55-130.06,92.72-127.56z"
            />
            <path
              style="fill: #6c7a51"
              d="M-286.81,122.54c-4.49,0.74-4.41-7.2-9.62-5.09c-4.65-1.53-3.64-5.41-3.69-8.85
            c4.47,2.87,8.95,2.64,13.43,0.02c5.06-0.24,2.64,6.5,6.7,7.2C-282.41,117.91-286.25,118.55-286.81,122.54z"
            />
            <path
              style="fill: #2a301b"
              d="M167.37-93.66c-0.89-0.87-1.77-1.75-2.66-2.62c-10.85-1.71-10.87-11.1-12.63-18.74
            c-0.89-3.89-0.58-8.18-1.46-12.24c-1.15-5.26-0.52-10.07,3.54-14.01c2.26,0.62,5.09,4.97,6.52-0.51c1.27-4.86,4.53-6.1,8.82-6.3
            c0.06,6.15,0.53,12.33,0.07,18.44c-0.44,5.91,3.19,1.5,4.73,2.27c-2.96,8.84-6.7,17.6-2.7,27.14c0.16,2.18,0.32,4.37,0.48,6.55
            C170.51-93.67,168.94-93.66,167.37-93.66z"
            />
            <path
              style="fill: #738a58"
              d="M154.17-141.26c-0.04,4.56-0.08,9.13-0.12,13.69c-2.03,1.51-3.88,3.66-6.84,2
            c-2.74-1.5-5.48-2.99-8.21-4.5c3.54-4.42,7.22-8.68,12.54-11.07c0.22-0.95,0.44-1.9,0.66-2.84
            C152.86-143.07,153.52-142.16,154.17-141.26z"
            />
            <path
              style="fill: #627b2c"
              d="M85.73-157.17c5.04-0.33,6.43,2.4,5.88,6.89c-0.45,3.65-0.76,7.32-1.13,10.97
            c-0.25,0.29-0.49,0.58-0.74,0.87c-2.06,1.59-4.18,1.54-6.35,0.23c-4.71-7.03,3.3-12.49,2.34-19.07L85.73-157.17z"
            />
            <path
              style="fill: #1e2316"
              d="M177.28,265.75c2.09,0.68,4.17,1.37,6.26,2.05c0.89-0.6,1.51-0.37,1.92,0.59
            c2.61,5.15-1.4,5.83-4.71,6.98c-2.79-0.15-4.42,0.94-4.24,3.96c-1.66,1.77-3.23,1.52-4.75-0.19c-5.19-0.31-10.14-1.78-9.33-8.04
            c0.72-5.61,5.95-5.53,10.46-5.47C174.35,265.65,175.81,265.68,177.28,265.75z"
            />
            <path
              style="fill: #8d973d"
              d="M185.46,268.39c-0.61-0.3-1.25-0.49-1.92-0.59c-2.22-3.75-2.58-7.09,2.01-9.37
            C187.83,261.77,191.93,265.13,185.46,268.39z"
            />
            <path
              style="fill: #565c2a"
              d="M6.41-139.07c-2.09-0.54-4.17-1.08-6.26-1.62c-1.06-0.13-2.12-0.26-3.18-0.39
            c-2.17-0.87-4.34-1.74-6.51-2.61c0.74-1.37,1.48-2.73,2.21-4.09C-3.15-144.23,7.07-150.25,6.41-139.07z"
            />
            <path
              style="fill: #4a702a"
              d="M171.87,134.19c3.96-1.27,7.94-2.49,11.12-5.37c0.72,1.52,1.84,2.98,0.68,4.77
            c-3.93,0.27-5.89,8.23-11.25,2.89C172.23,135.72,172.05,134.95,171.87,134.19z"
            />
            <path
              style="fill: #b7c867"
              d="M185.43,40.48c-4.86,0.08-8.94,2.3-12.95,4.73c-2.1-2.77,1.21-8.56-5.03-9.02
            c-3.82-0.21-2.58-2.4-1.76-4.43c2.19-2.01,2.43-4.03,0.62-6.62c-2.49-3.57-2.86-7.38,0.08-11.05c1.27-1.17,2.77-1.82,4.48-1.99
            c4.96-1.94,9.92-1.15,14.88-0.01c1.64,0.15,3.28,0.34,4.78,1.09c3.6,2.83,5.48,6.47,5.12,11.13c-2.69,3.28,0.03,8.27-3.53,11.28
            C189.39,36.53,186.7,37.53,185.43,40.48z"
            />
            <path
              style="fill: #9db689"
              d="M195.12,25.07c-1.72-2.92-3.44-5.84-5.17-8.76c-1.31-2.05-1.33-4.18-0.45-6.38
            c1.95-2.4,2.42-6.74,7.17-5.51c0.88,1.38,1.77,2.77,2.65,4.15c0,5.35,0,10.7,0,16.05c-0.67,0.22-1.34,0.46-2.01,0.7
            C196.52,25.83,195.79,25.75,195.12,25.07z"
            />
            <path
              style="fill: #7a9c43"
              d="M195.12,25.07c0.74,0.04,1.47,0.12,2.2,0.24c-0.79,3.9,0.09,8.64-5.2,10.26
            C192.14,31.79,188.67,27.01,195.12,25.07z"
            />
            <path
              style="fill: #29371b"
              d="M85.74-157.27c3.59,6.59,3.58,12.67-2.48,17.92c0.41,3.57-1.76,5.57-4.43,7.26
            c-2.13,1.15-3.62,3.17-5.73,4.36c-2.31,1.83-3.05,5.58-6.84,5.58c-5.28-1.7-6.18-5.83-5.88-10.62c0.09-2.09,0.18-4.19,0.27-6.28
            c3.87-0.62,7.71-2.27,11.63-0.24c1.97-5.06-2.13-11.97,4.73-15.54c0.46-0.17,0.92-0.31,1.39-0.43
            C81.83-152.34,83.62-155.41,85.74-157.27z"
            />
            <path
              style="fill: #889566"
              d="M77.02-154.82c-2.39,4.93,3.61,12.42-4.73,15.54c-1.27-4.96-7.93-7.38-6.44-13.64
            C69.57-153.56,73.29-154.19,77.02-154.82z"
            />
            <path
              style="fill: #c7d3a6"
              d="M196.68,4.41c-1.35,2.31-2.69,4.61-4.04,6.92c-4.88-1.39-6.93-4.16-4.46-9.13
            c2.52-1.8,5.03-3.61,7.55-5.41c1.81,0.83,2.42,2.32,2.27,4.19C197.55,2.12,197.11,3.27,196.68,4.41z"
            />
            <path
              style="fill: #2f3d1b"
              d="M-214.1,13.29c1.48-3.08,2.96-6.16,4.44-9.24c1.51,0,3.02-0.08,4.53-0.08
            c11.72,0.04,11.74,0.05,8.56-10.9c-0.43-1.49-0.47-2.97-0.45-4.51c-0.32-4.55,0.83-8.82,2.63-12.87
            c3.2-7.18,8.05-10.42,15.73-5.97c11.81,6.55,10.07,18.82,11.28,29.19c1.65,14.19-1.63,28.43-2.34,42.68
            c-0.18,3.61-3.94,5.52-4.4,9c-0.25,1.4,0.17,3.4-0.83,4.15c-7.12,5.38-2.65,9.1,1.62,12.93c-3.24,3.15-7.29,2.31-11.17,2.29
            c-8.14,1-10.21,10.84-18,12.37c-1-0.35-1.86-0.91-2.57-1.71c-3.49-4.92-2.67-11.03-4.5-16.41c-0.74-4.18-2.31-8.2-2.59-12.48
            c3.79-7.66,8.09-14.86-0.34-22.94c-3.22-3.08-0.44-9.3-1.73-13.96C-214.18,14.33-214.14,13.81-214.1,13.29z"
            />
            <path
              style="fill: #2e3b1d"
              d="M-163.94,181.29c-4.05,0.45-9.52,9.4-11.59-1.94c-1.57-3.02-2.13-6.51-4.37-9.21
            c-5.67-2.62-3.04-7.12-2.51-11.14c1.29-3.49,3.77-5.19,7.5-4.99c6.36,5.66,9.01,2.14,10.67-4.23
            c1.75-6.74,2.32-14.36,11.21-16.15c6.33,0.42,8.21,6.93,12.99,9.49c1.61,2.8,0.1,5.97,1.11,8.83c1.57,2.59,3.1,5.16,7.82,4.57
            c-5.64-8.87,8.67-17.61-0.74-26.64c1.02-7.3,1.35-14.6,0.09-21.93c2.47-5.82,6.1-5.85,10.47-2.09c4.89,3.17,6.84,8,6.33,13.46
            c-0.42,4.47,0.96,7.99,3.45,11.37c2.77,8.29,3.46,16.98,7.3,25.24c4.4,9.48-0.55,16.92-13.11,24.97
            c-4.83,1.85-9.84-3.41-14.63,0.43c-1.03,0.18-2.06,0.14-3.08-0.03c-3.7-0.87-5.63-4.14-8.4-6.27
            c-7.38-8.42-7.68,3.04-11.67,4.16c-8.43-1.01-3.2-7.05-3.87-10.92C-158.34,173.52-163.12,176.67-163.94,181.29z"
            />
            <path
              style="fill: #1b1f14"
              d="M-202.9,81.33c4.13-7.02,5.74-18.15,18.39-11.35c0.53,14.39,1.56,15.41,14.8,14.99
            c0.96-0.03,2.65-0.01,2.78,0.38c2.46,7.45,4.14,15.1,7.99,22.16c2,3.67-2.67,6.58-4.09,9.92c-4.67,10.96-13.97,18.4-20.89,27.66
            c-1.93,2.58-4.19,6.69-7.87,4.99c-4.82-2.21-1.86-6.38-0.73-9.83c0.46-1.42,0.73-2.91,1.09-4.37c-2.15-1.43-2.61-3.54-2.45-5.92
            c6.55-11.43,6.4-11.93-7.01-23.3c-5.52-2.63-5.14-8.39-6.94-12.99C-207.39,89.09-210.08,83.23-202.9,81.33z"
            />
            <path
              style="fill: #3e5321"
              d="M-191.22,129.28c-0.07,2.21-0.14,4.41-0.21,6.61c-1.47,0.83-2.94,1.67-4.41,2.5
            c-15.38,0.93-15.38,0.93-13.5,15.89c-0.12,0.35-0.14,0.88-0.37,1.01c-7.68,4.3-13.86,9.27-9.06,19.4
            c-11.18,0.86-8.59-7.45-9.09-13.69c-6.78-5.61-2.86-12.16-1.6-18.46c0.43-2.13,2.08-4.41,0.29-6.34c-2.13-2.29-4.06,0.08-5.91,1
            c-4.19,2.1-7.42,2.04-10.43-2.41c-2.05-3.02-5.53-4.99-9.42-5.6c0.8-1.57,1.6-3.14,2.39-4.71c4.66-3.82-2.2-11.95,5.46-14.66
            c2-0.26,4.11,0.17,4.85,2.01c5.16,12.93,16.99,10.55,27.39,13.14c-4.7-11.5-15.71-20.26-8.51-33.65
            c1.51-1.52,3.03-3.03,5.01-3.95c6.32-2.93,9.51,2.36,13.63,5.22c2.13,3.91,2.79,8.67,6.71,11.53
            c1.69,5.51,1.99,11.46,6.44,16.03C-189.13,122.65-188.97,126.14-191.22,129.28z"
            />
            <path
              style="fill: #435535"
              d="M-141.9,142.74c-8.16,2.41-7.9-5.49-11.14-9.11c0.72-5.43-0.7-10.6-1.91-15.8
            c3.22-5.13,3.71-10.31,0.22-15.54c-1.86-3.06-1.68-5.58,1.91-7.2c1.35-1.18,2.7-2.36,4.53-2.74c2.45-0.28,4.6,0.36,6.41,2.06
            c1.68,4.7,7.05,6.29,9.13,10.67c0.34,7.59,3.5,15.12,0.57,22.79c-0.14,1.55-0.23,3.1-1,4.51c-2.16,3.45-5.18,6.26-7.3,9.75
            C-140.96,142.34-141.43,142.54-141.9,142.74z"
            />
            <path
              style="fill: #446166"
              d="M-98.72,215.26c-14.47,1.18-16.11,3.15-15.5,18.58c-5.32,7.23-10.8,0.11-16.19,0.18
            c-3.25-5.04-3.88-10.42-2.17-16.13c5.22-6.07,3.91-18.88,17.15-16.67c5.79,2.4,9.35,8.4,15.76,9.82
            c1.27,1.26,2.11,2.64,1.2,4.49L-98.72,215.26z"
            />
            <path
              style="fill: #36573a"
              d="M-114.11,204.22c-3.6,0.26-8.18-0.27-8.69,4.59c-0.67,6.31-6.08,6.56-9.78,9.08
            c-1.41-8.31-2.82-16.63-4.23-24.94c2.2-2.84,5.06-2.57,8.04-1.71c7.49,2.15,9.36-1.46,8.26-8.11c0.4-1.05,1.04-1.9,1.98-2.53
            c4.34-2.36,7.58-0.19,10.68,2.57c3.12,3.01,7.84,5.27,5.28,11.01c-3.26,3.29-6.36,6.77-10.08,9.6
            C-113.13,203.93-113.62,204.07-114.11,204.22z"
            />
            <path
              style="fill: #5b5e1f"
              d="M-173.33,67.69c-2.21-0.11-5.1,0.64-6.49-0.48c-4.36-3.51-13.57-0.03-13.95-6.23
            c-0.35-5.63,8.13-1.18,11.88-4.27c1.74-1.43,3.56-2.47,4.26-4.73c4.53-4.99,10.51-4.59,16.43-4.47
            c2.82,0.96,5.12,2.54,6.35,5.38c1.5,5.54,0.18,10.39-3.59,14.65c-2.57,1.92-5.2,2.63-7.93,0.27
            C-168.66,65.71-170.98,65.67-173.33,67.69z"
            />
            <path
              style="fill: #31481e"
              d="M-209.34,154.28c-2.22-2.35-8.24,1.68-7.96-5.3c0.38-9.54,4.83-15.63,13.76-18.24
            c6.63-1.94,4.55,5.66,7.7,7.66C-200.67,143.41-200.41,152.76-209.34,154.28z"
            />
            <path
              style="fill: #4d722a"
              d="M-227.86,161c2.95,4.62,4.25,10.33,9.09,13.69c1.67,1.46,3.35,2.92,5.03,4.37
            c-0.16,4.72-1.48,9.24-6.42,10.42c-4.46,1.06-5.89-3.48-7.94-6.39c-1.58-2.25-3.76-3.43-6.41-3.86
            c-2.36-5.06,1.15-9.14,2.28-13.62C-230.78,164.07-229.32,162.53-227.86,161z"
            />
            <path
              style="fill: #446531"
              d="M-118.46,183.75c3.33,3.34,3.81,7.17,1.17,10.97c-2.78,4-6.6,1.87-10.04,0.99
            c-3.19-0.81-6.33-1.83-9.49-2.76c-3.54-0.98-5-3.27-4.3-6.9c1.87-2.89,2.49-7.06,7.19-7.07l1.09,0.18
            C-126.83,176.89-122.94,181.26-118.46,183.75z"
            />
            <path
              style="fill: #73813b"
              d="M-148.43,94.92c-1.46,0.06-2.93,0.12-4.39,0.17c0.05-0.76-0.03-1.51-0.23-2.25
            c0.64-7.82-9.59-13.67-4.05-22.39c3.44-3.29,6.89-6.57,12.19-3.63C-139.52,75.95-141.1,88.53-148.43,94.92z"
            />
            <path
              style="fill: #475e27"
              d="M-163.94,181.29c-1.46-8.11,4.04-15.35,2.92-23.49c-0.19-1.4,1.91-2.41,3.46-1.65
            c3.08,1.51,4.28,4.02,3.3,7.49c-1.44,5.1-6.49,10.04-0.85,15.53C-157.74,181.2-160.7,181.83-163.94,181.29z"
            />
            <path
              style="fill: #3b3f1d"
              d="M-154.47,71.97c-4.71,7.31,5.04,13.64,1.43,20.87c-4.05-8.56-11.43-15.33-13.33-25.03
            c2.34-0.79,4.69-1.58,7.03-2.37C-154.43,65.15-154.17,68.35-154.47,71.97z"
            />
            <path
              style="fill: #3f534c"
              d="M-123.19,240.25c2.91,6.96,8.76,14.81-5.01,16.79c-0.54-7.05-1.08-14.09-1.62-21.14
            C-125.28,233.79-123.18,235.41-123.19,240.25z"
            />
            <path
              style="fill: #667f9b"
              d="M-123.19,240.25c-1.43-2.65-3.62-4.13-6.63-4.35c-0.27-0.6-0.47-1.23-0.59-1.89
            c5.31-8.11,10.82,2.27,16.19-0.18c0.56,2.33,2.99,4.57,0.88,7.05C-116.78,244.94-119.85,239.71-123.19,240.25z"
            />
            <path
              style="fill: #4c7367"
              d="M-133.92,178.98c-1.42,3.36-1.09,8.49-7.2,7.07c-3.15-3.17-6-6.41-2.33-11.03
            C-138.11,171.12-135.57,173.98-133.92,178.98z"
            />
            <path
              style="fill: #446226"
              d="M-179.91,170.14c3.57,2.06,10.81,2.38,4.37,9.21c-3.5-1.64-8.12,4.33-10.63-0.83
            C-188.9,172.9-180.48,174.15-179.91,170.14z"
            />
            <path
              style="fill: #3a582c"
              d="M-184.63,213.29c-3.02,1.67-6.04,0.39-9.06,0.03c-0.19-4.85,6.13-4.63,6.84-8.78
            c-4.51-2.76-11.3-4.66-4.43-11.73c1.5,1.51,3,3.03,4.5,4.54C-184.95,202.51-181.22,207.41-184.63,213.29z"
            />
            <path
              style="fill: #547529"
              d="M-191.29,192.81c-2.36,5.36,2.82,7.87,4.43,11.73c-8.32,3.17-9.89-3.38-12.63-9.07
            C-196.7,194.57-194,193.69-191.29,192.81z"
            />
            <path
              style="fill: #6e897d"
              d="M-154.72,102.29c7.12,4.54,7.04,10.45-0.22,15.54C-154.87,112.65-154.8,107.47-154.72,102.29z"
            />
            <path
              style="fill: #425b24"
              d="M-174.92,154.01c-1.78,2.74-4.28,4.42-7.5,4.98c-2.37-3.17-0.49-5.33,1.87-7.18
            C-177.61,149.53-176.08,151.44-174.92,154.01z"
            />
            <path
              style="fill: #2d361c"
              d="M-105.52,192.94c1.07-3.76-3.56-4.1-4.12-6.89c-0.73-10.08,5.35-11.94,13.55-11.26
            c3.39,1.8,5.85,4.32,6.26,8.37c0.13,1.86-0.57,3.67-0.49,5.52c0.15,3.3-0.48,7.4,2.47,9.31c4.12,2.67,5.53-2.28,7.84-4.21
            c5.71-4.76,11.91-5.63,18.75-3.15c9.96,7.96,17.86,18.58,32.83,19.04c7.84,0.24,8.14,8.66,8.26,15.29
            c-1.14,7.09-5.29,10.46-12.48,10.06c-5.05-1.62-10.16-5.72-14.98,0.51c-1.03,0.42-2.1,0.62-3.22,0.63
            c-5.43-1.53-8.9-5.95-13.25-9.07c-3.82-10.74-8.68-6.1-13.69-0.99c-2.34,2.39-2.22,8.26-7.62,6.35
            c-4.17-1.48-2.44-5.42-2.71-8.7c-0.48-5.94-3.42-9.85-10.33-8.21c-0.7-0.71-1.4-1.43-2.1-2.14c-4.1-2.98-8.31-5.87-9.41-11.35
            C-109.58,198.48-107.46,195.75-105.52,192.94z"
            />
            <path
              style="fill: #161912"
              d="M-98.87,256.64c0.2-2.64-1.66-3.74-3.57-4.82c-2.6-1.53-0.7-4.84-2.62-6.64
            c2.66-5.73,2.81-11.18-0.25-17.06c-3.57-6.89-0.95-10.65,6.94-10.37c2.72,0.85,4.84,2.69,5.09,5.42
            c0.92,10.14,5.78,19.04,8.55,28.59c0.99,2.2-0.05,2.77-2.06,2.55c-3.69,4.41-4.74,8.98-1.5,14.25c1.84,2.99,1.61,6.51,0.43,9.73
            c-1.11,3.02-0.38,7.35-4.01,8.71c-4.72,1.77-5.79-3.55-8.63-5.51c2.28-3.57,5.94-6.48,2.76-11.76
            C-100.07,265.87-99.29,261.09-98.87,256.64z"
            />
            <path
              style="fill: #91ad86"
              d="M-66.53,320.21c3.07,3.06,7.55,1.91,11.29,3.33c-0.73,4.73-4.67,2.49-6.77,3.78
            c0,0-0.02-0.11-0.02-0.11c-8.22-1.93-16.83,0.06-25-2.41c-1.84,0.6-4.26-0.36-5.42,2.04c-8.6-2.36-18.16,1.07-26.25-4.34
            c-0.42-0.52-0.96-0.73-1.62-0.65c0.39-3.65,3.58-2.79,5.75-3.58c6.51,3.4,13.27,1.7,19.96,0.93
            C-85.18,318.09-75.81,317.71-66.53,320.21z"
            />
            <path
              style="fill: #435923"
              d="M-86.79,254.3c0.69-0.85,1.37-1.7,2.06-2.55c3.88-1.53,7.89-3.56,11.72-0.31
            c2.37,2.02,2.03,5.05,0.39,7.26c-2.11,2.85-1.93,9.15-6.39,8.13C-84.5,265.57-86.52,259.97-86.79,254.3z"
            />
            <path
              style="fill: #465826"
              d="M-64.11,227.09c6.64-1.31,10.44,2.93,14.2,7.26c3.94,1.71,7.56,3.74,8.35,8.53
            c0.15,1.03,0.27,2.06,0.36,3.09c-0.03,0.51-0.06,1.01-0.09,1.52C-50.24,247.82-64.25,235.3-64.11,227.09z"
            />
            <path
              style="fill: #3c4f20"
              d="M-105.02,310.73c1.5,0.09,2.99,0.17,4.49,0.26c2.84-1.7,5.42-6.89,9.01-1.75
            c1.63,2.33-1.63,4.19-3.6,4.47C-98.68,314.2-104.42,320.37-105.02,310.73z"
            />
            <path
              style="fill: #739e65"
              d="M-87.02,324.79c8.39,0.22,17.01-2.03,25,2.41C-70.78,330.86-78.75,326.21-87.02,324.79z"
            />
            <path
              style="fill: #232b19"
              d="M-118.69,322.49c8.75,1.45,17.5,2.89,26.25,4.34C-101.34,326.28-110.9,329.75-118.69,322.49z"
            />
            <path
              style="fill: #506776"
              d="M-220.63,311.87c-1.4-5.23-9.44-8.1-5.07-14.78c1.16-1.77,4.02-2.51,4.9-1.16
            C-217.58,300.87-218.78,306.4-220.63,311.87z"
            />
            <path
              style="fill: #49632f"
              d="M-258.02,300.36c4.55-6.65,2.11-11.26,3.7-15.94c6.44,0.9,12.88,3.71,11.79,10.24
            C-243.64,301.31-251.18,296.41-258.02,300.36z"
            />
            <path
              style="fill: #647d97"
              d="M-105.06,245.18c6.19,0.11,2.73,4.04,2.62,6.64c-2.57,2.45-5.09,2.23-7.56-0.15
            C-108.36,249.51-106.71,247.34-105.06,245.18z"
            />
            <path
              style="fill: #556c70"
              d="M-104.46,267.36c-0.13,2.95-2.06,4.84-4.4,5.85c-2.19,0.95-3.83-0.68-3.17-2.83
            c0.73-2.37,1.96-5.07,3.84-6.44C-107.04,263.11-105.29,265.61-104.46,267.36z"
            />
            <path
              style="fill: #29361b"
              d="M101.53,204.37c0.77,1.54,1.54,3.08,2.31,4.61c1.42,1.42,1.37,2.85,0.03,4.29
            c-2.55,5.36-2.17,9.91,3.08,13.65c3.08,2.19,5.53,5.11,5.93,9.18c-4.18-2.33-8.32-2.43-12.76-0.44
            c-9.69,4.33-18.89,0.23-28.17-2c-1.12-1.89-1.86-4.17-4.58-4.37c-4.95-2.22,2.6-14.07-8.74-11.37c-0.03-1.35-0.06-2.7-0.08-4.04
            c-1.36-5.75,9.15-6.72,5.34-13.44c-1.54-5.15,1.97-8.04,4.91-11.21c3.22-4.06,7.2-6.9,12.44-7.69c1.12-0.02,2.2,0.2,3.24,0.59
            C90.13,189.56,94.93,197.65,101.53,204.37z"
            />
            <path
              style="fill: #3c5424"
              d="M151.59,222.42c-1.48,4.61-8.54,8.66-1.36,14.2c0.65,0.5,0.82,2.95,0.62,3.03
            c-11.23,4.6-0.97,13.36-3.82,19.5c-3.05,2.5-0.85,7.65-4.87,9.67c-2.49,0.47-4.48-0.42-6.16-2.22
            c-2.24-4.77-3.7-9.27,3.84-10.42c5.37-5.61-0.82-8.02-3.58-10.19c-3.34-2.62-7.9-3.69-11.93-5.43
            c-0.32-1.44-1.39-3.38-0.83-4.23c5.04-7.65,14.69-9.82,20.45-16.98C145.61,217.28,149.4,220.27,151.59,222.42z"
            />
            <path
              style="fill: #0f110c"
              d="M139.84,256.18c-3.79,2.55-2.19,6.11-1.78,9.45c-7.07,7.48-13.21,16.63-25.74,14.19
            c-1.26-1.14-1.88-2.58-2-4.26c-0.08-5.4,7.13-6.5,7.03-11.93C121.94,252.37,130.55,253.25,139.84,256.18z"
            />
            <path
              style="fill: #121611"
              d="M113.01,274.82c-0.01,1.45-0.03,2.91-0.04,4.36c1.56,0.78,1.56,1.55-0.02,2.3
            c-0.66,2.3-1.31,4.6-1.97,6.91c-4.26,2.47-7.72,5.93-11.45,9.07c-1.9,4.92-7.22,1.57-10.09,4.17c-7.37-3.42-4.96-9.63-0.28-10.4
            c6.91-1.14,8.18-7.02,12.6-9.85C104.43,277.35,105.52,270.59,113.01,274.82z"
            />
            <path
              style="fill: #2d411e"
              d="M99.98,263.28c-0.42,5.32-4.76,7.03-9.07,8.27c-3.73,1.08-6-1.58-6.1-4.81
            c-0.21-6.17,4.78-8.1,9.55-9.24C98.3,256.56,99.32,259.83,99.98,263.28z"
            />
            <path
              style="fill: #385222"
              d="M113.01,274.82c-4.89,0.23-5.9,7.12-11.25,6.57c-3.02-5.3-4.36-9.4,4.1-11.08
            c4.11-0.82,7.68-4.37,11.49-6.68C121.3,269.46,115.86,271.64,113.01,274.82z"
            />
            <path
              style="fill: #42542c"
              d="M67.38,229.29c1.64-2.19,1.96-6.01,5.71-5.65c2.19,0.21,4,2.07,4.38,4.33
            c0.69,4.12-3.89,3.55-5.51,5.68C69.18,233.52,67.79,231.93,67.38,229.29z"
            />
            <path
              style="fill: #5a6e51"
              d="M72,295.08c1.71-0.5,3.44-1.45,5.06,0.2c1.36,3.74,2.72,7.48,4.09,11.21
            C73.38,306.48,73.1,300.45,72,295.08z"
            />
            <path
              style="fill: #212a18"
              d="M-32.26,233.82c2.23-3.73,8.84-2.89,9.07-8.71c4.1-6.83-1.28-16.14,5.79-22.2
            c1.18-1.6,2.38-3.19,2.84-5.2c0.15-5.75,2.49-10.23,7.56-13.14c3.69-1.23,7.06-3.4,11.03-3.89c3.95-0.07,6.73,1.66,8.25,5.33
            c0.3,1.09,0.32,2.21,0.13,3.33c-3.41,5.04-3.9,10.16-0.05,15.19c2.71,3.54,3.57,6.99,0.2,10.59c-2.7,0.76-4.86,2.19-5.99,4.88
            c1.7,5.67-0.89,9.36-5.54,12.1c-4.92,2.8-10.18,2.43-15.49,1.62c-6.41,3.47-12.33,8.46-20.46,6.55
            C-36.86,236.96-35.5,235-32.26,233.82z"
            />
            <path
              style="fill: #4e712b"
              d="M-14.45,233.73c4.02-3.41,9.15-3.37,13.9-4.54c3.18,2.16,5-0.32,6.5-2.29
            c3.14-4.14,7.05-3.6,11.2-2.46c0.04,1.71,0.08,3.42,0.12,5.13c-9.47,0.98-8.77,6.79-6.37,13.41c-3.29,1.41-6.58,2.81-9.87,4.22
            c-3.13-2.7-7.9,1.72-10.69-2.47C-8.04,239.66-13.24,237.57-14.45,233.73z"
            />
            <path
              style="fill: #505f39"
              d="M-32.26,233.82c-0.88,2.15-1.76,4.31-2.65,6.46c-2.1,1.89-4.19,3.79-6.29,5.69
            c0,0.01-0.29-0.8-0.29-0.8c-2.75-3.51-4.29-7.75-6.81-11.4c0.16-5.31,3.1-7.11,8.09-6.68
            C-35.86,227.45-31.78,227.88-32.26,233.82z"
            />
            <path
              style="fill: #9da78e"
              d="M-9.66,244.73c5.08-5.77,7.37,0.62,10.69,2.46c-4.07,2.18-7.25,6.8-12.87,4.64
            C-11.22,249.44-16.33,245.28-9.66,244.73z"
            />
            <path
              style="fill: #5a802c"
              d="M50.87,204.37c-5.18,1.32-10.35,2.64-15.53,3.96c-4.04-0.67-8.1,3.71-12.12-0.03
            c-1.21-0.91-2.05-2.08-2.5-3.46c-1.13-2.65-3.83-1.67-5.82-2.4c-6.56-3.21-5.8-8.43-3.88-13.97l-0.21-0.13
            c1.97-5.92,7.04-8.69,11.87-11.71c3.5-1.28,6.66-3.2,9.73-5.31c1.72-0.61,3.4-0.53,5.05,0.25c2.88,1.66,3.72,4.44,4.1,7.46
            c2.97,0.11-4.99,5.65,2.35,4.26c7.22-1.37,14.11,0.38,18.48,7.35c0.82,6.64-2.03,11.07-8.16,13.5
            C53.12,204.33,52,204.41,50.87,204.37z"
            />
            <path
              style="fill: #7aa22a"
              d="M67.43,199.73c4.95,3.29,5.65,6.55-0.12,9.67c-2.88,1.56-5.83,2.99-8.75,4.48
            c-2.13,0.52-4.27,1.05-6.4,1.57c-2.08-3.5-1.23-7.34-1.28-11.08c0.41,0.01,0.83,0,1.24-0.03
            C57.37,203.3,61.48,198.47,67.43,199.73z"
            />
            <path
              style="fill: #a1ba45"
              d="M23.97,205.9c3.2,3.58,8.45-2.43,11.37,2.44c-3.9,2.78-8.66,4.6-10.7,9.48
            c-5.48,0.9-0.08-8.69-6.56-6.81C16.49,205.18,19.95,205.22,23.97,205.9z"
            />
            <path
              style="fill: #445525"
              d="M23.97,205.9c-2.86,0.66-5.15,1.98-5.89,5.1c-1.84,1.38-3.67,2.75-5.51,4.13
            c0.95-4.38-3.2-6.44-4.43-9.8c-2.25-6.1-4.05-12.27,2.88-16.86c2.05,3.77,0.83,9.39,6.35,11.18c1.31,1.64,3.14,2.74,4.5,4.33
            C22.57,204.62,23.27,205.26,23.97,205.9z"
            />
            <path
              style="fill: #38481e"
              d="M17.16,224.44c-4.53,3.09-10.52,3.94-13.64,9.26c-1.07,1.82-3.24,4.04-5.32,1.69
            c-1.62-1.83,0.53-4.16,1.26-6.2c2.37-3.06,4.75-6.13,7.12-9.19C10.6,220.31,15.27,219.04,17.16,224.44z"
            />
            <path
              style="fill: #2e3d1c"
              d="M-118.52-107.1c3.87,2.55,9.63,11.51,8.32-2.77c-0.26-2.8,2.51-5.87,4.36-9.83
            c3.02,3.94,5.42,7.09,7.83,10.23c5.15,4.01,3.72,8.89,1.84,13.85c-1.86,5.9-5.3,9.95-11.94,10.28c-3.59,0.86-6.56-0.39-9.2-2.72
            C-118.37-94.37-123.84-100.4-118.52-107.1z"
            />
            <path
              style="fill: #435824"
              d="M-95.98-109.53c0.77-1.47,1.55-2.93,2.32-4.4c0.11-0.56,0.22-1.13,0.33-1.69
            c5.03-5.87,8.79-3.48,12.08,1.87c2.81,5.58,9.55,4.67,13.6,8.22c0.88,1.54,1.1,3.17,0.75,4.89c-0.5,1.63-1.45,2.95-2.71,4.04
            c-1.91,1.38-3.02,3.15-2.56,5.64c0.87,4.74,0.66,9.22-5.05,10.5c-5.01,1.12-8.28-2.12-11.06-5.78c-1.39-1.83-1.82-4-1.88-6.24
            c-0.2-5.23,1-10.86-4.29-14.6C-95.15-107.78-95.66-108.59-95.98-109.53z"
            />
            <path
              style="fill: #c4bd9f"
              d="M-118.52-107.1c0.85,6.01,1.71,12.01,2.56,18.02c0.53,0.84,0.7,1.75,0.45,2.72
            c-0.75,1.34-1.84,2.16-3.43,2.16c-0.62-8.9-8.21-16.01-6.56-25.5C-120.55-115.9-120.45-109.04-118.52-107.1z"
            />
            <path
              style="fill: #93ba24"
              d="M-91.13-129.11c4.03,0.48,12.15-5.04,8.93,6.09c-1.06,2.52-1.12,6.13-5.33,5.43
            c-3.1-0.52-4.73,1.18-6.09,3.52c0,0-0.03,0.14-0.03,0.14c-2.25-1.87-10.72-1.67-2.44-7.06
            C-93.05-122.97-91.31-125.5-91.13-129.11z"
            />
            <path
              style="fill: #55732b"
              d="M-95.98-109.53c0.9,0.11,1.8,0.22,2.69,0.32c3.12,3.92,7.94,7.44,3.19,13.27
            c-3.79,2.16-6.82,1.87-8.53-2.66c0.21-3.63,0.42-7.25,0.63-10.88C-97.33-109.5-96.66-109.52-95.98-109.53z"
            />
            <path
              style="fill: #546f29"
              d="M-68.51-100.99c-0.21-1.61-0.42-3.23-0.64-4.84c-4.16-1.43-4.28-4.17-2.44-7.54
            c3.17,0.47,6.34,0.94,9.5,1.4c0.59,0.05,1.18,0.09,1.77,0.12c3.42-1.51,6.58-1.72,9.07,1.72c0.6,0.98,0.95,2.05,1.08,3.2
            c-1.25,4.11-0.84,8.85-4.38,12.15c-1.48,0.82-3.06,1.05-4.72,0.81C-63.09-95.31-67.85-95.46-68.51-100.99z"
            />
            <path
              style="fill: #7aa038"
              d="M-71.59-113.37c0.82,2.52,1.63,5.03,2.44,7.54c-8.24,6.19-9.39-2.33-12.8-6.18
            c-1.23-2.47,0.24-2.58,2.07-2.37C-77.26-112.78-74.56-111.92-71.59-113.37z"
            />
            <path
              style="fill: #55732b"
              d="M-79.87-114.38c-0.69,0.79-1.38,1.58-2.07,2.37c-3.44-3.25-7.08-5.4-11.69-2.06
            c0.3-7.46,8.89-4.34,11.42-8.95C-79.61-120.63-77.72-118.05-79.87-114.38z"
            />
            <path
              style="fill: #30411d"
              d="M-177.5-84.49c5.22-1.53,10.44-3.07,15.66-4.6c6.97-3.31,4.21,2.5,4.82,5.15
            c0.38,2.97,1.85,5.39,3.85,7.53c1.6,4.28,4,7.96,7.87,10.55c2.66,2.43,3.7,5.37,2.89,8.92c-1.03,2.83-3.08,4.61-5.84,5.64
            c-6.73,0.28-6.71,9.67-13.39,10.05c-8.46-0.25-13.29-6.5-18.92-11.45c-0.73-0.86-1.23-1.84-1.53-2.92
            c1.57-5.1-0.77-9.88-1.25-14.82C-183.55-76.04-182.87-81.25-177.5-84.49z"
            />
            <path
              style="fill: #262a19"
              d="M-143.9-56.96c-1.54-2.58-3.07-5.15-4.61-7.73c-0.41-3.57-3.32-7.08-0.25-10.73
            c0.52-7.81-2.46-16.05,2.77-23.3c2.27,0.93,4.54,1.87,6.8,2.81c5.17,1.83,3.91,8.61,8.42,10.95c1.42,1.38,1.74,3.4,2.89,4.93
            c1.38,0.84,2.77,1.68,4.15,2.52c9.03,0.92,9.12,7.55,9.1,14.26c-2.42,5.84-5.93,10.67-12.12,13.01
            c-1.02,0.51-2.11,0.8-3.25,0.89c-1.69-0.03-3.37-0.13-5-0.64c-2.75-1.78-5.27-3.89-8.11-5.53
            C-143.37-56.01-143.64-56.48-143.9-56.96z"
            />
            <path
              style="fill: #68942c"
              d="M-148.77-75.41c4.39,3.47,2.55,7.1,0.25,10.73c-4.49-2.06-7.18-5.27-6.66-10.51
            c-0.45-3.71,1.62-3.74,4.27-2.93C-150.2-77.22-149.48-76.31-148.77-75.41z"
            />
            <path
              style="fill: #9cc020"
              d="M-150.91-78.12c-1.42,0.98-2.84,1.95-4.27,2.93c-2.02,0.53-3.89,4.77-4.63,3.08
            c-1.74-3.96-1.55-8.71,2.78-11.84C-154.8-82.2-150.76-82.37-150.91-78.12z"
            />
            <path
              style="fill: #b5c96e"
              d="M-148.12-109.8c0.53,0.48,1.15,0.77,1.85,0.87c4.43,1.92,2.81,3.94,0.1,5.98
            c-3.48,1.56-7.01,1.34-7.87-2.69C-154.99-110.06-150.82-109.37-148.12-109.8z"
            />
            <path
              style="fill: #b1c55f"
              d="M-52.85-109.12c-2.49-0.91-4.97-1.82-7.46-2.72c4.2-10.49,10.63-5.01,16.66-2.32
            c0.13,1.53,0.27,3.07,0.4,4.6C-46.28-105.94-49.64-109.05-52.85-109.12z"
            />
            <path
              style="fill: #baaf97"
              d="M-118.33-63.93c1.42-5.81-2.22-9.6-5.39-13.58c0.07-3.32,2.51-4.95,4.79-6.69
            c0.72-0.87,1.45-1.75,2.17-2.62c8.59,8.92,10,23.48,3.33,34.29C-120.94-53.8-119.88-58.76-118.33-63.93z"
            />
            <path
              style="fill: #cec4ba"
              d="M-127.88-80.03c-1.47-0.66-2.94-1.33-4.41-1.99c-2.06-5.16-5.71-10.67,2.94-13.5
            C-123.14-90.9-120.37-85.96-127.88-80.03z"
            />
            <path
              style="fill: #59572b"
              d="M-129.36-95.52c-5.02,3.62,0.84,9.61-2.94,13.5c-6.99-2.29-5.14-8.99-6.9-13.88
            C-135.92-95.78-132.64-95.65-129.36-95.52z"
            />
            <path
              style="fill: #9a976e"
              d="M-177.5-84.49c-0.52,5.25-3.47,10.22-1.82,15.72c-2.1,6.14-5.57,3.87-9.07,1.49
            c-3.84-6.59-1.41-12.46,2.13-18.19c2.06-2.25,3.5-5.23,6.85-6.03C-177.29-89.56-177.83-86.91-177.5-84.49z"
            />
            <path
              style="fill: #d8cfc9"
              d="M-179.41-91.49c-0.87,2.46,0.15,5.79-3.22,7.12c-6.88-2.66-6.82-6.58-2.07-11.35
            c1.39-1.48,2.78-2.96,4.17-4.44C-180.16-97.27-179.78-94.38-179.41-91.49z"
            />
            <path
              style="fill: #3e4422"
              d="M-184.7-95.72c2.89,3.39-3.29,8.42,2.07,11.36c-3.29,4.66-4.92,9.69-3.49,15.42
            c1.2,4.81-3.51,6.22-5.34,9.29c-1.37,1.07-2.91,1.57-4.65,1.31c-1.04-0.35-1.96-0.91-2.77-1.66
            c-6.72-8.68-1.85-16.57,1.61-24.54c1.66-1.44,1.66-1.44-2.49-3.27c-2.47-1.84-2.91-4.67-3.67-7.35
            c-0.27-1.71-0.26-3.41,0.05-5.12c1.08-3.06,2.91-4.96,6.46-4.15c4.26-0.49,7.7-4.29,12.38-3.09
            C-184.41-103.58-191.96-99.75-184.7-95.72z"
            />
            <path
              style="fill: #414424"
              d="M-196.92-104.43c-1.15,0.93-2.31,1.86-3.46,2.8c-0.24,1.57-1.14,2.27-2.72,2.11
            c-10.55-5.62-12.08-10.34-6.68-20.6c2.87-0.71,5.48-0.41,7.55,1.97c0.57,1.38,1.13,2.76,1.7,4.14
            C-199.07-110.91-195.08-108.78-196.92-104.43z"
            />
            <path
              style="fill: #2a361a"
              d="M112.94,281.48c0.01-0.77,0.01-1.53,0.02-2.3c6.29-0.46,12.48-2.11,13.54-9.15
            c1.42-9.44,5.83-7.9,11.56-4.4c1.46,0.75,2.91,1.5,4.37,2.24c6.08-0.93,12.15-1.83,17.59,2.24c3.91,5.64,9,10.61,10.06,17.86
            c-9.5-1.24-18.94-2.76-27.88-6.44C132.45,278.11,122.69,281.02,112.94,281.48z"
            />
            <path
              style="fill: #4a5f27"
              d="M170.08,287.97c-3.78-5.18-7.55-10.35-11.33-15.53c-2.2-4.16-2.11-7.98,1.68-11.25
            c1.42-2.26,1.5-4.55,0.14-6.86c0.84-2.46,0.72-5.21,2.23-7.46c0.99-3.36-8.96-6.31-2.44-7.6c6.89-1.36,2.89-16.93,13.87-9.13
            c2.5,2.83,2.85,5.69,0.07,8.57c-7.74,5.09-6.31,18.18,2.97,27.04c-3.3,3.22-7.36,4.93-11.85,5.21c0.11,4.94,4.94,5.12,6.33,8.19
            c-2.48,4.52,2.78,4.35,3.95,6.62c1.6,3.11,7.02,6.2,0.6,9.86C173.86,293.37,174.25,288.81,170.08,287.97z"
            />
            <path
              style="fill: #2f3f30"
              d="M99.52,297.46c3.55-3.36,0.06-15.61,11.45-9.07c0.77,0.01,1.53,0.02,2.3,0.03
            c-2.51,4.69-7.1,6.74-11.45,9.12C101.06,297.51,100.29,297.48,99.52,297.46z"
            />
            <path
              style="fill: #2c3a1c"
              d="M-257.27-73.11c4.05,0.15,7.93-0.03,11.43,3.13c5.2,4.7,9.41,3.1,12-3.24
            c1.35-0.55,2.7-1.1,4.05-1.65c4.91,2.82,5.91,6.38,2.04,10.85c-0.7-0.02-1.41-0.05-2.11-0.09c-2.22-0.49-5.68-4.46-5.85,1.36
            c-0.11,3.91-1.86,6.81-3.45,9.96c-3.03-3.75-2.02-10.78-9.11-11.23C-254.3-64.05-260.36-64.04-257.27-73.11z"
            />
            <path
              style="fill: #8eb031"
              d="M-257.27-73.11c0.36,5.65,5.02,7.04,9,9.09c-4.56,5.16-8.92,2.7-13.26-0.37
            c-0.1-0.65-0.28-1.27-0.55-1.87C-261.37-69.17-259.71-71.42-257.27-73.11z"
            />
            <path
              style="fill: #9cbe20"
              d="M-234.44,24.46c-1.67-2.16-4.11-2.14-6.49-2.3c-1.2-8.63,9.47-8.87,11-15.57
            c5.75,1.11,11.78,1.57,15.83,6.71c0,0,0.09-0.01,0.09-0.01c2.43,10.91-1.79,15.81-12.04,13.46
            C-228.84,26.11-232,26.48-234.44,24.46z"
            />
            <path
              style="fill: #12150e"
              d="M-196.15-61.66c0.06,0.72,0.14,1.43,0.26,2.13c7.92,9.38,4.75,20.19,3.7,30.62
            c-0.65,6.47-4.25,12.69-1.42,19.42c5.48,5.47,9.13,13.14,2.9,18.24c-5.66,4.63-13.99,2.6-18.95-4.7
            c-3.63-6.1-5.76-12.5-3.57-19.63c1.03-3.35-0.35-5.65-2.79-7.63c0.02-0.74-0.1-1.45-0.37-2.14c-3.2-12.41-6.36-24.8,6.68-34.01
            c-1.19-2.83-2.22-5.75,1.14-7.79c2.66-1.62,4.95-0.12,7.1,1.44C-199.66-64.4-197.93-63.01-196.15-61.66z"
            />
            <path
              style="fill: #202616"
              d="M-196.15-61.66c-4.45,1.17-10.97-9.91-13.55,2.3c-2.34-2.75-4.7-4.5-8.54-2.05
            c-3.81,2.43-7.08,1-9.51-2.6c-0.68-3.62-1.37-7.24-2.05-10.85c0,0-0.16-0.41-0.16-0.41c3.25-5.63,1.6-14.24,10.12-16.66
            c6.42-1.14,11.58-8.07,19.1-4.15c0.83,2.31,1.65,4.61,2.48,6.92c1.48,1.33,2.66,2.79,2.22,4.98
            C-192.45-76.67-200.92-69.2-196.15-61.66z"
            />
            <path
              style="fill: #2b381f"
              d="M103.88,213.27c-0.01-1.43-0.02-2.86-0.03-4.29c1.21-0.86,2.47-1.65,3.62-2.58
            c3.05-2.47,5.85-7.45,9.77-5.82c4.73,1.97,7.91,6.67,7.13,12.69c2.35,6.52-3,11.32-5.85,14.79c-3.92,4.79-5.06-1.97-6.29-4.8
            C110.4,219.05,112.52,211.74,103.88,213.27z"
            />
            <path
              style="fill: #567a2b"
              d="M158.65,202.01c0.45,4.68,8.15,10.7-2.46,13.34c0.27-4.45-1.12-8.54-2.52-12.67
            c-0.5-1.46-1.57-4.02,1.22-4.12C156.1,198.52,157.39,200.79,158.65,202.01z"
            />
            <path
              style="fill: #69902c"
              d="M156.15,131.07c-0.82,2.45-1.63,4.91-2.45,7.36c-4.5,2.08-10.11,10.08-10.83-3.43
            c-6.9,4.88-7.39,14.34-16.03,14.9c-0.52-0.15-1.04-0.3-1.56-0.45c-1.46-0.88-2.34-2.16-2.63-3.84
            c0.46-6.82,11.94-7.22,9.22-15.91c0.8-2.66,1.69-5.26,4.62-6.36c4.31-0.69,8.26,1.92,12.9,0.7
            C154.41,122.72,156.75,125.94,156.15,131.07z"
            />
            <path
              style="fill: #33461e"
              d="M128.79,165.46c-5.46,1.96-5.65,9.25-11.21,11.12c-4.74-0.43-6.31,9.61-11.78,4.39
            c-4.55-4.34-0.61-9.9,1.09-14.69c0.87-2.44,2.46-4.64,3.19-7.22c0.94-2.27,2.53-3.79,4.96-4.35c1.86-0.15,3.42,0.44,4.69,1.81
            C123,159.25,124.38,163.89,128.79,165.46z"
            />
            <path
              style="fill: #69902c"
              d="M119.73,156.53c-1.5-0.05-3.01-0.09-4.51-0.14c-2.52-3.55-2.53-6.9,1.12-9.62
            c4.13-3.08,6.68,0.79,9.7,2.47c0,0,0.79,0.66,0.79,0.66C124.47,152.11,122.1,154.32,119.73,156.53z"
            />
            <path
              style="fill: #405c24"
              d="M112.65,160.94c1.52,1.51,3.96,4.92,2.33,5.13c-8.91,1.13-3.64,9.22-7,12.4
            c4.58,5.4,4.3-9.94,9.59-1.89c-1.45,1.21-2.15,2.74-1.9,4.64c-1.67,1.66-3.34,3.33-5.01,4.99c-1.91,2.6-2.66,6.15-6.1,7.53
            c-11.9,2.1-15.56-0.53-17.5-12.51c0.75-2.08,1.59-4.12,2.43-6.15c7.83-10.23,4.98-18.46-9.39-27.08
            c-0.77-1.64-0.76-3.28-0.06-4.94c0.67-1.01,1.58-1.71,2.71-2.13c5.45,0.8,10.73-2.23,16.38-0.23
            C108.9,144.15,109.67,153.2,112.65,160.94z"
            />
            <path
              style="fill: #374c20"
              d="M90.28,181.42c0.71,7.71,5.38,10.05,12.41,9.19c5.84,2.52,3.23,5.81,0.81,9.07
            c-0.66,1.56-1.31,3.12-1.97,4.68c-15.05,0.53-14.79-11.95-18.12-21.38C84.58,177.54,86.86,176.96,90.28,181.42z"
            />
            <path
              style="fill: #909d99"
              d="M103.5,199.69c-2.33-2.84-0.23-6.08-0.81-9.07c1.63-3.34,3.66-5.95,7.98-4.4
            C114.82,196.98,113.53,199.4,103.5,199.69z"
            />
            <path
              style="fill: #abc43d"
              d="M156.15,131.07c-1.55-2.45-2.75-5.77-6.54-2.61c-6.13,5.13-9.12,0.79-11.97-3.98
            c-1.02-2.5-1.26-4.59,2.43-4.58c3.86,0.02,7.72,0.04,11.59,0.06c2.42,2.52,4.83,5.03,7.25,7.55
            C158.56,129.14,158.12,130.7,156.15,131.07z"
            />
            <path
              style="fill: #bed039"
              d="M140.65,99.59c-1.03,6-2.06,11.99-3.09,17.99c-0.92,3.06-2.31,5.78-5.27,7.37
            c-7.27,1.97-12.66,0.2-15.05-7.55c-0.73-1.65-1.17-3.37-0.99-5.19c1.61-5,4.71-9.62,3.95-15.27c0.87-2.85,2.57-4.86,5.6-5.51
            c4.67-0.26,7.13,4.65,11.57,4.95c1,0.4,1.87,0.99,2.61,1.76C140.2,98.63,140.43,99.11,140.65,99.59z"
            />
            <path
              style="fill: #93b729"
              d="M160.63,79.13c-0.3,3.54,3.31,3.12,4.85,4.8c-2.69,4.1,0.5,7.78,0.77,11.67
            c0.27,3.95,0.91,8.46-5.21,8.73c-3.18,0.52-6.36,1.04-9.54,1.55c-0.84-0.72-1.67-1.44-2.51-2.15c-0.26-3.81-2.95-7.4-1.03-11.41
            c0.21-1.1,0.49-2.18,0.84-3.24C150.97,83.64,154.32,79.61,160.63,79.13z"
            />
            <path
              style="fill: #32461f"
              d="M133.26,122.2c1.43-1.54,2.87-3.07,4.3-4.61c0.84,0.77,1.68,1.54,2.52,2.32
            c-0.81,1.53-1.62,3.05-2.43,4.58c-2.21,1.82-1.52,4.52-2.06,6.85c-1.86,5.83-5.32,8.97-11.81,7.09
            c-6.66-5.74-4.82-10.06,2.38-13.21C128.51,124.18,131.25,124.06,133.26,122.2z"
            />
            <path
              style="fill: #5d832f"
              d="M151.83,92.41c-0.94,3.78-1.88,7.56-2.83,11.33c-2.55-1.86-4.8-4.32-8.35-4.14
            c0,0-0.92,0.26-0.92,0.26c-1.27-3.01-2.63-6.01-0.75-9.28c1.48-1.98,3.49-2.81,5.91-2.8C148.08,88,149.71,90.56,151.83,92.41z"
            />
            <path
              style="fill: #b0ca32"
              d="M160.63,79.13c-3.05,3.48-6.88,6.37-8.29,11.08c-7.15-5.32-6.89-7.1,1.59-10.99
            c2.18-1.61,4.37-3.22,6.55-4.83C160.53,75.97,160.58,77.55,160.63,79.13z"
            />
            <path
              style="fill: #648a33"
              d="M-245.75-110.53c2.88-1.09,5.77-2.18,8.65-3.28c7.46,2.17,8.69,7.64,7.63,14.36
            c-3.23,5.47-6.49,10.94-14.06,5.68C-249.77-98.62-244.84-104.96-245.75-110.53z"
            />
            <path
              style="fill: #576d27"
              d="M-243.53-93.76c5.59,0.91,8.12-3.21,11.02-6.73c4.57,3.11,7.67,8.66,14.17,8.61
            c0.61,1.02,0.52,1.98-0.27,2.87c-7.1,1.83-4.97,11.31-11.35,13.73c-0.24-9.34-6.33-11.69-14.21-11.91
            C-243.95-89.39-243.74-91.57-243.53-93.76z"
            />
            <path
              style="fill: #4d5937"
              d="M-255.47-90.92c-4.28-0.31-8.7-0.36-10.7-5.3c5.02-1.96,2.95-9.35,8.39-10.98
            c7.02,4.02,6.82,9.49,3.16,15.66L-255.47-90.92z"
            />
            <path
              style="fill: #8fa59f"
              d="M-254.62-91.54c0.21-5.47,1.04-11.08-3.15-15.66c0.45-1.9-0.03-5.54,3.36-3.68
            C-249.51-108.2-249.9-95.89-254.62-91.54z"
            />
            <path
              style="fill: #191c13"
              d="M-218.95-91.57c-10.65,4.21-12.43,3.04-13.56-8.92c0.58-5.16-3.49-8.73-4.58-13.31
            c1.69-7.68,3.59-15.15,14.05-14.05c0,0-0.12,0.22-0.12,0.22c11.52,8.17,13.7,14.4,9.05,27.1c-0.81,2.2-0.18,4.42-0.98,6.52
            C-215.88-92.41-217.16-91.59-218.95-91.57z"
            />
            <path
              style="fill: #4f5b30"
              d="M147.22-125.57c2.01-1.57,3.63-4.52,6.84-2c2.6,2.45,4.07,5.73,3.3,8.98
            c-2.17,9.12,2.59,15.66,7.37,22.31c-2.06,1.26-3.37,3.78-6.23,3.7c-1.22,3.11-0.74,7.72-6.21,7.08
            c-1.14-0.36-2.08-1.02-2.81-1.97c-0.92-8.84-2.91-17.37-7.51-25.13c0.47-4.24,2.1-7.97,4.92-11.17
            C146.82-124.4,146.94-125,147.22-125.57z"
            />
            <path
              style="fill: #526f52"
              d="M158.33-55.02c8.08,3.47,13.09,7.82,4.54,16.01c-0.8,0.82-1.76,1.37-2.87,1.7
            c-1.17,0.11-2.28-0.1-3.34-0.61c-3.34-3.31-6.21-6.95-7.94-11.4c-0.24-2.65,0.45-4.94,2.59-6.67
            C153.87-57.27,156.19-56.79,158.33-55.02z"
            />
            <path
              style="fill: #9eb7d3"
              d="M158.33-55.02c-2.19-0.06-4.38-0.12-6.57-0.19c-1.73,0.2-3.12-0.47-4.31-1.67
            c-2.89-3.57-2.92-8-3.61-12.23c0.17-4.18,1.35-7.74,5.65-9.43c1.09-0.19,2.14-0.02,3.14,0.45c2.6,1.53,2.22,6.06,6.21,6.22
            C163.19-66.11,159.11-60.61,158.33-55.02z"
            />
            <path
              style="fill: #889588"
              d="M152.67-87.07c3.17-0.53,4.02-3.52,5.81-5.5c0.2,5.61,6.57,11.5-0.74,16.78
            c-2.58,2.43-4.44,0.84-6.23-1.13l0-0.64C148.79-81.12,148.48-84.37,152.67-87.07z"
            />
            <path
              style="fill: #48663a"
              d="M160-38.8c0.96-0.07,1.91-0.14,2.87-0.21c3.28,2.86,7.23,2.28,11.1,2.15
            c0.76,0.85,1.11,1.84,0.99,2.99c-2.4,4.17-3.3,9.97-10.13,9.36c-1.63-0.73-2.68-2.06-3.64-3.5
            C158.31-31.33,156.69-34.79,160-38.8z"
            />
            <path
              style="fill: #ccd8e4"
              d="M151.51-76.93c2.17-0.13,3.7,3.29,6.23,1.12c0.37,1.32,0.74,2.63,1.11,3.94
            c-0.92,1.77-1.12,4.86-3.95,4.06C150.07-69.17,152.21-73.7,151.51-76.93z"
            />
            <path
              style="fill: #9fac5f"
              d="M181.07-54.94c1.99,4.54,2.27,7.95-4.45,6.71c-0.63-2.99-1.41-6.27,0.68-8.72
            C180.58-60.8,180.1-56.22,181.07-54.94z"
            />
            <path
              style="fill: #99ad48"
              d="M173.98-34.37c-0.01-0.83-0.01-1.65-0.02-2.48c0.37-4.31,4.18-5.33,6.98-7.32
            c0.64-0.04,1.27-0.09,1.91-0.15c5.12,5.74,2.45,8.93-3.6,11.01c-1.24,0.36-2.48,0.37-3.72,0.03
            C175.01-33.64,174.5-34.01,173.98-34.37z"
            />
            <path
              style="fill: #384e21"
              d="M165.19-25.84c3.46-2.29,3.87-7.74,8.79-8.53c0,0,0.46-0.22,0.46-0.22
            c2.4,2.78,5.14,5.26,7.17,8.36c0.71,2.51,1.96,4.98,0.48,7.62c-4.07,2.02-8.73,3.57-7.84,9.64c2.5,3.18,5,6.36,7.5,9.55
            c1.46,3.05,0.09,5.24-2.17,7.15c-3.36,1.8-4.93,5.46-7.87,7.7c-1.24,0.11-2.48,0.21-3.72,0.32c-2.73,4.28-3.97,10.42-11.24,9.05
            c-3.53-1.81-7.04-3.67-9.33-7.12c-1.28-2.4-0.84-5.12-1.39-7.66c-0.3-2-1.23-3.85-1.49-5.87c-0.07-1.06,0.07-2.09,0.35-3.1
            c0.55-1.49,1.39-2.81,2.37-4.05c2.41-6.45,11.6-7.4,12.32-15.24C160.4-21.55,161.6-24.58,165.19-25.84z"
            />
            <path
              style="fill: #adc943"
              d="M177.15,4.71c1.53-1.38,3.06-2.76,4.59-4.13c2.52-0.94,4.32,0.99,6.44,1.62
            c1.48,3.04,2.97,6.09,4.45,9.13c-0.89,1.66-1.79,3.31-2.68,4.97c-1.33-0.18-2.67-0.37-4-0.56c-1.83-2.89-5.47-2.29-7.86-4.1
            C175.46,9.65,173.72,7.53,177.15,4.71z"
            />
            <path
              style="fill: #7f8a46"
              d="M178.76-25.2c-3.81-2.04-4.25-5.63-4.31-9.39c0.6,0.02,1.2,0.07,1.79,0.16
            c3.06,0.81,6.73-1.12,9.27,2.06c0.51,4.67,8.63,7.86,2.66,13.78C182.89-17.74,181.79-22.86,178.76-25.2z"
            />
            <path
              style="fill: #b4c494"
              d="M185.51-32.37c-3.25,0.06-7.23,3.4-9.27-2.05c3.17-2.65,8.58-3.8,6.61-9.89
            C189.17-43.17,189.51-41.65,185.51-32.37z"
            />
            <path
              style="fill: #b1bba6"
              d="M178.76-25.2c4.89-0.29,5.64,5.31,9.41,6.61c-1.77,2.29-3.98,2.92-6.66,1.73
            c-1.78-0.45-1.88-1.15-0.31-2.1C179.34-20.63,178.51-22.71,178.76-25.2z"
            />
            <path
              style="fill: #adbc76"
              d="M181.19-18.96c0.17,0.69,0.27,1.39,0.31,2.1c-4.41,0.8-3.89,6.14-7.26,7.89
            C169.85-16.97,175.93-17.68,181.19-18.96z"
            />
            <path
              style="fill: #58792b"
              d="M-221.1,93.13c-0.96,11.67,7.24,25.48,19.87,31.18c-9.93,3.53-17.67,10.1-27.55,3.23
            c-0.91-0.63-2.19-1.56-3.05-1.34c-13.94,3.57-14.53-5.33-14.24-15.11c-1.06-0.87-1.78-1.96-2.15-3.28
            c0.03-4.64,1.12-8.1,6.97-6.19c0.74,0.07,1.49,0.13,2.24,0.18c2.56,0.24,5.39,1.33,6.77-2.16c0.07-1.45,0.14-2.91,0.21-4.36
            c0.87-3.49,2.57-6.12,6.59-6.32C-223.03,89.34-221.35,90.49-221.1,93.13z"
            />
            <path
              style="fill: #455b24"
              d="M-234.44,24.46c8.92,0.15,18.64,1.76,20.43-11.17c6.13,3.46,3.71,10.05,5.14,14.93
            c4.63,2.63,3.05-4.99,6.72-3.52c3.42,3.2-0.98,19.42-7.37,27.2c-5.18-0.94-9.22,1.14-12.67,4.8c-2.07,0.95-4.12,1.94-6.5,1.62
            c-3.79-1.09-5.57-3.69-5.62-7.56c1.44-3.92,2.52-8.08,6.64-10.27c2.98,0.86,4.44-0.04,3.67-3.29
            C-225.4,31.27-229.63,27.62-234.44,24.46z"
            />
            <path
              style="fill: #3e5321"
              d="M-225.44,90.69c-2.2,1.53-4.4,3.06-6.6,4.59c-1.49-2.6-3.06-4.96-5.62-6.85
            c-5.99-4.42-13.14-9.02-2.55-16.33c0.52-0.36-0.33-2.7-0.54-4.13c-0.93-6.91-0.13-7.33,8.94-4.71
            c6.71,5.17,10.9,11.54,10.58,20.47C-221.35,87.11-222.47,89.31-225.44,90.69z"
            />
            <path
              style="fill: #2d381c"
              d="M-246.23,108.8c0.06,0.76,0.11,1.53,0.17,2.29c-3.85,3.64,0.4,11.21-6.47,13.39
            c-2.32-6.76-4.65-13.52-6.98-20.29c-1.17-3.19-6.12-5.82-2.5-9.71c2.76-2.96,6.24-0.47,9.32,0.65c0,1.5-0.01,3-0.01,4.51
            C-248.33,101.12-247.13,104.86-246.23,108.8z"
            />
            <path
              style="fill: #5c712c"
              d="M-227.66,40.49c0.79,4.17,1.63,8.35-4.42,9.25c-2.93-0.26-3.18-3.23-4.83-4.78
            c-0.98-0.48-1.96-0.96-2.94-1.45c-3.91-1.95-1.58,8.21-6.57,3.5c-2.1-1.98-1.28-4.99,0.1-7.34c2.33-3.98,6.39-5.7,10.45-4.75
            C-232.87,35.63-230.38,38.55-227.66,40.49z"
            />
            <path
              style="fill: #728138"
              d="M-236.91,44.96c1.61,1.59,3.22,3.19,4.83,4.78c0.22,2.9,1.7,5.01,4.1,6.55
            c2.47,2.48,1.41,4.97,0.04,7.47c-0.78,0.79-1.71,1.36-2.77,1.7c-0.55-0.01-1.1-0.01-1.65-0.02c-2.85,0.68-7.57-4.88-8.4,2.52
            c-4.74,0.01-8.26-1.79-7.64-7.07c0.67-5.67,4.8-2.66,8.03-3.3C-233.16,56.15-238.76,49.13-236.91,44.96z"
            />
            <path
              style="fill: #505f2d"
              d="M-260.85,46.17c3.88,1.98,3.03,4.1,3.54,5.77c1.21,3.97-7.65,11.36,4.01,12.12
            c0.51,0.03,1.58,5.07,0.86,7.28c-0.95,2.91-3.8,1.46-5.6,0.34c-3.74-2.33-9.03-4.9-6.58-10.05
            C-262.38,56.91-260.71,52.31-260.85,46.17z"
            />
            <path
              style="fill: #3e5321"
              d="M-246.23,108.8c-2.18-3.04-5.77-5.08-6.47-9.16c4.86-5.32,9.74-10.77,11.45,1.99
            C-244.74,102.74-245.84,105.52-246.23,108.8z"
            />
            <path
              style="fill: #3e5321"
              d="M-232.25,99.64c1.62,2.08,1.54,4.14-0.87,5.29c-3.06,1.46-4.48-1.02-5.89-3.13
            C-237.1,100.03-234.73,99.65-232.25,99.64z"
            />
            <path
              style="fill: #262e18"
              d="M-38.91-98.2c3.46,1.68,5.09-0.46,7.15-2.86c5-5.83,8.6-4,10.84,2.61
            c-2.34,5.67,3.45,7.5,5.31,11.21c1.81,3.53,1.48,6.65-1.7,9.25c-6.51,3.29-12.5-1.7-18.85-1.21c-6.41-1.36-6.28-7.02-7.67-11.68
            C-43.85-94.43-42.43-97.03-38.91-98.2z"
            />
            <path
              style="fill: #475d24"
              d="M-18.35-86.98c-5.12-0.28-9-3.26-9.54-7.85c-0.47-3.9,4.25-2.99,6.98-3.62
            c3.7,1.53,7.41,3.06,11.11,4.6c1.68-0.14,3.13,0.37,4.38,1.5c1.49,1.8,2.05,3.77,1.1,6.02c-0.64,0.88-1.44,1.59-2.4,2.1
            c-1,0.34-2.04,0.5-3.1,0.5C-12.98-83.95-15.21-86.66-18.35-86.98z"
            />
            <path
              style="fill: #495324"
              d="M-6.89-91.12c-0.97-0.91-1.95-1.82-2.92-2.73c-0.72-2.92-1.45-5.85-2.17-8.77
            c4.99-1.82,7.72-6.46,11.76-9.45c6.24,0.15,2.36,7.39,6.37,9.11c0,0.86,0,1.71,0.01,2.57c-0.13,0.51-0.26,1.03-0.39,1.55
            c-2.74,3.26-5.67,6.31-9.56,8.25C-4.87-90.47-5.91-90.63-6.89-91.12z"
            />
            <path
              style="fill: #7e9753"
              d="M-38.91-98.2c-0.7,2.33-1.4,4.67-2.1,7c-1.36,1.48-3.08,2.02-5.04,1.88
            c-3.29-0.73-5.04-2.86-5.47-6.13c-0.93-3.86,1.52-8.09-1.26-11.77c-0.06-0.63-0.08-1.27-0.07-1.9c3.05-3.4,6.36-1.31,9.61-0.45
            C-40.56-106.25-39.53-102.3-38.91-98.2z"
            />
            <path
              style="fill: #8ea855"
              d="M20.51-115.53c0.57-1.12,1.15-2.23,1.72-3.35c7.46,3.42,8.58,12.71,15.42,16.7
            c1.82,1.73,1.58,3.51,0.13,5.32c-6.29,3.3-12.75,2.52-19.25,0.8c-5.87-5.61,1.07-7.32,3.19-10.49
            C21.97-109.64,22.45-112.75,20.51-115.53z"
            />
            <path
              style="fill: #5e722a"
              d="M21.74-106.56c-1.36,2.68-2.73,5.36-4.09,8.04c0.77,0.9,1.02,1.92,0.74,3.08
            c-2.61,4.05-7.95,4.25-10.92,7.81c-2.98,1.6-5.84,1.54-8.55-0.62c-4.15-8.07,3.68-8.93,7.38-12.29c0,0-0.14,0.14-0.14,0.14
            c0.74-0.06,2.06,0.05,2.12-0.19C10.34-108.15,16.92-105.37,21.74-106.56z"
            />
            <path
              style="fill: #c0bc99"
              d="M6.16-102.97c-8.9,1.72-1.88-7.73-6.37-9.11c-0.04-1.39-0.08-2.77-0.12-4.16
            C10.85-116.23,4.75-107.76,6.16-102.97z"
            />
            <path
              style="fill: #688b33"
              d="M156.55,54.53c-3.84,3.53-1.13,8.62-2.99,12.62c-1.04,2.54-3.09,2.8-5.43,2.42
            c-5.96-3.2-4.03-9.6-5.89-14.49c0.29-3.2,1.77-5.53,4.87-6.68c1.87-0.32,3.51,0,4.6,1.75C153.33,51.6,154.94,53.07,156.55,54.53
            z"
            />
            <path
              style="fill: #bbc4b5"
              d="M151.71,50.14c-1.35-0.31-2.7-0.63-4.04-0.94c-0.54-0.16-1.08-0.33-1.62-0.49
            c-6.37-10.23-5.99-12.41,2.97-16.81c4.14,0.75,5.58,4.59,8.13,7.17c0.61,1.78,0.24,4.12,2.73,4.84
            C158.69,48.01,155.19,49.06,151.71,50.14z"
            />
            <path
              style="fill: #9ebe3a"
              d="M159.87,43.92c-2.61-0.03-3.32-2.06-4.14-3.96c-2.05-4.64-3.8-9.76,2.2-12.32
            c4.52-1.93,8.76-0.39,9.64,5.53c-0.21,0.67-0.5,1.33-0.59,2.02c-0.04,0.3,0.31,0.65,0.48,0.98
            C165.96,39.77,160.84,39.81,159.87,43.92z"
            />
            <path
              style="fill: #8aa535"
              d="M153.93,79.23c-3.38,3.25-6.36,6.55-1.6,10.99c-0.25,0.71-0.42,1.45-0.51,2.19
            c-2.86,0.75-4.88-1.27-7.22-2.21c-3.42-4.93-5.19-9.83,0.21-14.63c2.59-0.91,4.82-0.52,6.54,1.79
            C152.21,77.98,153.07,78.6,153.93,79.23z"
            />
            <path
              style="fill: #d3da81"
              d="M151.35,77.36c-2.14-0.18-4.28-0.35-6.42-0.53c-3.07-1.11-3.83-3.64-4.02-6.53
            c0.33-1.73,1.28-2.96,2.95-3.59c1.1-0.11,2.13,0.11,3.07,0.72c2.22-0.09,4.43-0.18,6.64-0.28
            C152.82,70.55,152.09,73.95,151.35,77.36z"
            />
            <path
              style="fill: #445923"
              d="M60.38-132.77c2.23,3.12,4.45,6.24,6.68,9.36c4.47,1.5,5.48,5.79,7.48,9.29
            c-0.05,4.17-0.93,8.2-2.26,12.13c-3.74,3.14-5.84,9-12.38,7.51c-3.6-0.99-2.5-6.72-6.96-6.85c-2.3-0.72-6.91,1.87-5.69-3.91
            c0.14-0.86,0.28-1.71,0.42-2.57c2.37-4.86,4.53-9.77,3.9-15.39C56.24-124.79,57.69-129.35,60.38-132.77z"
            />
            <path
              style="fill: #719331"
              d="M53.72-105.16c2.16-1.3,4.7-4.16,6.89-1.99c4.09,4.05-1.16,7.26-2.03,10.92
            c-0.15,0.5-0.3,1.01-0.45,1.51c-4.75,4.51-10.53,8.23-9.57,16.34c0.3,2.54-2.45,4.44-4.53,6.09c-1.01,0.5-2.08,0.71-3.2,0.71
            c-2.31-0.29-4.02-1.86-6.03-2.8c-7.36-4.04-7.6-10.54-6.08-17.64c1.43-4.56,6.89-2.96,9.27-6.08c-0.11-1.36-0.22-2.72-0.34-4.08
            c1.72-0.06,3.45-0.11,5.17-0.17C47.26-100.16,51.13-100.16,53.72-105.16z"
            />
            <path
              style="fill: #31411d"
              d="M51.57-123.19c2.57,1.9,8.2,1.52,7.51,5.46c-0.97,5.53-6.48,7.86-11.41,9.94
            c-0.69-1.4-1.39-2.8-2.08-4.19c-4.73-2.63-1.6-5.93-0.78-9.04c0.06-0.85,0.12-1.71,0.18-2.56
            C47.18-123.45,49.38-123.32,51.57-123.19z"
            />
            <path
              style="fill: #a5c351"
              d="M53.72-105.16c5.7,3.46,0.11,4.22-1.5,5.34c-3.91,2.73-7.43,2.65-9.39-2.54
            c1.47-0.96,2.95-1.92,4.42-2.88C49.39-103.22,51.56-105.21,53.72-105.16z"
            />
            <path
              style="fill: #8d9b8a"
              d="M44.81-121.02c4.75,2.62-1.82,6.23,0.78,9.04c-5.8,2.22-5.31-3.56-5.95-5.74
            C38.36-122.04,42.92-120.01,44.81-121.02z"
            />
            <path
              style="fill: #788d3e"
              d="M101.8-82.41c4.34-5.57,4.13-15.33,14.29-15.52c3.97,2.49,6.62,7.31,12.24,6.91
            c2.55,1.1,4.29,2.94,5.1,5.61c0.84,5.31-2.8,9.47-3.72,14.35c-0.29,1.01-0.77,1.92-1.4,2.76c-1.51,1.71-3.55,2.14-5.67,2.38
            c-1.51,0.36-3.04,0.55-4.59,0.52c-3.46-0.55-6.92-1.13-10.38,0.06c-5.28-0.05-11.62,3.74-14.75-4.08
            c-1.65-6.54,3.71-8.99,7.27-12.5C100.72-82.08,101.26-82.24,101.8-82.41z"
            />
            <path
              style="fill: #c7c7a8"
              d="M126.63-89.04c-8.81,3.19-11.08,1.28-10.54-8.89c3.11-1.19,3.46-6.47,8.06-5.44
            c3-3.17,5.87-3.24,8.61,0.34c0.81,1.58,1.05,3.25,0.78,5.01C132.07-94.4,130.36-90.93,126.63-89.04z"
            />
            <path
              style="fill: #799444"
              d="M85.67-98.16c2.95-0.94,5.89-1.89,8.84-2.84c0.86,2.61,1.72,5.22,2.58,7.84
            c0.88,2.21-0.01,3.88-1.59,5.36c-2.16,1.45-4.69,1.59-7.14,2.03c-3.04,0.35-6.05,1.62-9.17,0.36c-3.85-1.64-8.13-3.52-6.58-8.62
            C74.76-101.19,80.89-98.04,85.67-98.16z"
            />
            <path
              style="fill: #869472"
              d="M130.74-102.46c-2.08-1.13-4.21-1.96-6.59-0.91c0.03-1.11,0.07-2.23,0.1-3.34
            c3.57-3.45,2.06-7.56,1.49-11.54c2.47-1.66,4.95-3.32,7.43-4.99c0.16,0.69,0.25,1.38,0.28,2.09c1.52,1.68,4.02,2.39,4.83,4.79
            c1.2,3.74-1.12,6.71-2.17,9.97C134.9-104.29,133.33-102.67,130.74-102.46z"
            />
            <path
              style="fill: #788d3e"
              d="M94.59-88.58c0.83-1.53,1.67-3.06,2.5-4.58c4.8,2.17,4.42,6.61,4.71,10.76
            c0,0-0.45,0.44-0.45,0.44C94.52-79.5,92.26-81.69,94.59-88.58z"
            />
            <path
              style="fill: #53664c"
              d="M135.71-116.4c-0.76-1.58-1.51-3.16-2.26-4.75c4.48-0.87,8.95-1.75,13.43-2.62
            c0.51,4.43-0.41,8.55-2.7,12.37c-0.93,0.63-1.95,0.87-3.06,0.79C138.04-111.36,137.74-114.69,135.71-116.4z"
            />
            <path
              style="fill: #29341a"
              d="M-216.46-94.38c-3.78-2.33-12.92-3.43-2.47-9.28c6.83-3.83,3.54-9.15,2.1-13.66
            c-1.19-3.73-4.17-6.9-6.36-10.31c8.1-3.98,8.63,5.53,13.38,7.52c-1.12,7.76,2.75,13.72,7,19.55
            C-205.74-94.96-209.86-91.93-216.46-94.38z"
            />
            <path
              style="fill: #191d12"
              d="M160.44,261.2c0.6,3.92-0.07,7.66-1.69,11.24c-5.28-2.09-11.54-0.7-16.32-4.57
            c0.33-3.54,0.48-7.18,4.6-8.72C149.8,270.99,156.37,257.9,160.44,261.2z"
            />
            <path
              style="fill: #74952d"
              d="M174.31,238.71c-0.02-2.86-0.05-5.72-0.07-8.57c4.07-1.07,7.05,1.18,7,4.59
            C181.19,237.9,177.26,238.38,174.31,238.71z"
            />
            <path
              style="fill: #6a7b3b"
              d="M162.81,246.87c3.73,3.83,5.06,6.93-2.23,7.46C157.01,250.56,158.33,248.24,162.81,246.87z"
            />
            <path
              style="fill: #445822"
              d="M69.82-132.29c1.96-1.94,4.45-2.14,7-2.19c5.52,0.69,8.15,4.01,8.55,9.37
            c-1.18,1.22-3.6,2.85-3.35,3.6c1.7,5.19,7.03,1.57,10.14,3.76c0.33,3.55-1.65,4.16-4.63,3.86c-3.21-0.32-5.53,0.95-6.39,4.28
            c-5.49,1.77-5.32-4.08-8.06-6C69.92-120.76,70.67-126.68,69.82-132.29z"
            />
            <path
              style="fill: #475b28"
              d="M85.67-98.16c-0.32,0.18-0.62,0.45-0.96,0.54c-6.84,1.82-14.2,3.46-3.33,10.76
            c0.66,1.19,0.67,2.38,0.02,3.58c-3.72,4.55-8.68,4.64-13.89,3.86c-1.24-0.38-2.3-1.05-3.21-1.97
            c-3.6-8.61,2.23-14.04,6.73-19.93c0.92-0.9,1.99-1.52,3.22-1.87c2.69-0.35,4.99,0.31,6.7,2.55
            C82.53-99.82,84.1-98.99,85.67-98.16z"
            />
            <path
              style="fill: #7b9741"
              d="M80.95-100.65c-2.21-0.01-4.41-0.02-6.62-0.03c-1.49-3.87-1.59-7.89-1.26-11.95
            c0.07-1.05,0.43-1.97,1.13-2.75c4.85-1.12,4.04,4.54,6.92,5.79C91.67-106.41,83.22-103.59,80.95-100.65z"
            />
            <path
              style="fill: #a2c036"
              d="M85.36-125.1c-7.1,0.76-6.43-5.58-8.55-9.37c2.15-1.62,4.29-3.25,6.44-4.87
            c2.16,0.3,4.32,0.61,6.48,0.91c1.42,3.51,6.58,5.99,2.98,10.88C90.27-126.74,87.82-125.92,85.36-125.1z"
            />
            <path
              style="fill: #56772a"
              d="M167.57,33.18c-2.91-1.2-5.83-2.41-9.27-3.83c-0.34,4.11,0.26,7.86-2.57,10.61
            c-5.55,1.53-5.07-3.34-6.51-6.16c-1.68-2.74-1.12-5.52-0.17-8.32c1.7-2.92,4.33-3.73,7.49-3.31c4.93-0.13,7.57-4.38,11.44-6.41
            c-2.05,10.54,5.53,6.45,11.9,6.28C175.79,27.18,174.93,33.42,167.57,33.18z"
            />
            <path
              style="fill: #64862d"
              d="M177.15,4.71c-1.96,7.59,9.78,4.21,8.8,11.02c-4.74-0.1-9.49-0.21-14.24-0.31
            C171.87,11.02,171.28,6.22,177.15,4.71z"
            />
            <path
              style="fill: #738348"
              d="M74.21-115.39c0.02,0.71,0,1.42-0.06,2.13c-0.85,0.53-1.38,0.3-1.61-0.67
            c-4.69-1.49-5.32-5.35-5.49-9.47c0.09-3.22,0.53-6.33,2.76-8.88C72.92-127.08,75.56-121.75,74.21-115.39z"
            />
            <path
              style="fill: #3a4a1e"
              d="M-162.02,49.3c-4.29,6.25-10.09,3.68-15.62,2.68c-2.86-9.6,5.6-16.33,6.16-25.59
            c1.14-18.92-0.11-36.95-8.55-54.13c-0.53-3.29-3.7-6.19-1.51-9.9c1.74-2.63,4.2-3.73,7.32-3.4c4.07,0.57,6.68,5.21,11.39,4.1
            c1.08,0.19,2.07,0.59,3,1.16c4.81,3.98,4.42,8.31,0.87,12.85c-3.14,6.15,0.86,12,0.79,18.01c2.98,7.58-2.19,13.58-4.61,19.75
            c-1.71,4.37-2,7.53,0.26,11.24c1.09,5.38,5.54,8.75,8.04,13.31C-153.72,45.2-155.17,49.32-162.02,49.3z"
            />
            <path
              style="fill: #1e2215"
              d="M-180.11-36.53c0.04,2.93,2.85,5.84,0.07,8.79c-15.79-2.32-11.24,10.53-13.57,18.24
            c-4.5-16.51-2.74-33.3-2.28-50.03c0.75-0.41,1.5-0.83,2.26-1.24c7.96-5.88,11.49-1.6,13.6,5.94l-0.07-0.11
            c2.5,2.32,4.11,5.14,4.82,8.47C-174.74-42.11-180.37-40.75-180.11-36.53z"
            />
            <path
              style="fill: #5b6e2e"
              d="M-204.72,92.59c-1.8,4.86-4.29,3.07-6.25,0.35c-1.44-2-2.6-3.58-5.28-2.42
            c-7.56-5.5-4.26-15.4-8.94-22.07c0.08-3.3,1.78-5.34,4.88-6.31c2.9-0.44,5.45,0.53,7.86,2.02c5.88,2.99,5.42,10.03,9.18,14.39
            c0.12,0.92,0.25,1.85,0.37,2.77C-203.5,85.09-204.11,88.84-204.72,92.59z"
            />
            <path
              style="fill: #798c3d"
              d="M-214.85,65.78c-2.68,1.24-3.52-0.34-3.93-2.58c-1.71-1.87-2.29-4.41-3.78-6.42
            c-0.43-0.91-0.69-1.87-0.81-2.87c1.94-2.96,0.16-8.72,6.91-8.1c4.27,0.39,7.36,0.9,6.93,6.09c1.35,3.89,2.71,7.78,4.06,11.67
            C-208,66.83-211.35,66.59-214.85,65.78z"
            />
            <path
              style="fill: #a2ad68"
              d="M-214.85,65.78c2.66-2.75,6.02-2.49,9.39-2.21c0.73,5,1.46,9.99,2.19,14.99
            C-207.13,74.3-210.99,70.04-214.85,65.78z"
            />
            <path
              style="fill: #202617"
              d="M-96.04,176.88c-5.49,1.64-10.12,4.54-13.6,9.17c-2.92-1.59-5.84-3.18-8.76-4.77
            c-0.59-0.29-0.87-0.67-0.82-1.13c0.05-0.51,0.16-0.76,0.31-0.76c2.48-3.22,4.33-7.49,7.58-9.43c6.52-3.9,4.3-12.23,2.77-13.44
            c-9.15-7.23-5.37-16.43-5.58-25.05c3.02-4.58,4.96-10.56,12.32-9.57c3.59,13.26,16.39,21.21,19.95,34.52
            c-1.17,4.57-3.54,8.68-4.07,13.59C-86.53,175.56-90,178.53-96.04,176.88z"
            />
            <path
              style="fill: #191c13"
              d="M-102.83,122.35c-3.5,3.37-1.41,13.7-11.31,9.13c-8.31,0.37-6.74-5.46-5.84-10.08
            c0.93-4.76,1.34-8.94-3.1-12.12c0.28-3.86-3.89-7.38-0.91-11.47c11.92-11.16,25.72-10.3,33.84,7.04
            c0.7,0.94,1.34,1.92,1.95,2.92c3.86,4.91,6.82,9.89,1.36,15.61C-92.46,127.82-97.55,123.69-102.83,122.35z"
            />
            <path
              style="fill: #4e767c"
              d="M-134.8,131.24c0.03-1.39,0.07-2.78,0.1-4.17c2.42-1.54,3.72-0.05,4.89,1.81
            c8.89,2.58,6.56,9.26,4.37,14.58c-1.85,4.49-3.36,7.59,1.07,11.43c1.96,1.7,4.15,5.01,1.82,8.08c-2.53,3.33-5.8,1.39-9.17,1.09
            c-7.84-0.7-13.45-2.52-12.03-12.02c1.67-2.83,5.34-4.88,3.53-9.07C-140.89,137.91-133.52,136.58-134.8,131.24z"
            />
            <path
              style="fill: #657e33"
              d="M-123.98,99.71c0.02,3.21,4.37,6.01,0.9,9.56c-2.05-1.41-3.74-5.28-6.61-0.96
            c-1.48,1.11-3.03,1.56-4.69,0.39c-1.5-1.06-0.95-2.28-0.21-3.5c-4.43-0.89-9.59-0.95-8.75-7.77
            c-0.49-8.05-0.36-16.02,3.36-23.47c1.77-3.05,3.55-6.09,6.3-8.42c2.07-1.37,4.23-1.81,6.55-0.69
            c4.96,6.63,3.59,14.06,1.84,21.13C-126.52,90.98-125.77,95.27-123.98,99.71z"
            />
            <path
              style="fill: #7e98ad"
              d="M-134.59,105.21c1,2.04,2.51,3.26,4.9,3.11c8.8,6.91,4.44,13.75-0.13,20.57
            c-1.63-0.61-3.26-1.21-4.89-1.82C-136.7,119.77-138.27,112.48-134.59,105.21z"
            />
            <path
              style="fill: #4c7367"
              d="M-118.9,179.4c0.11,0.64,0.28,1.27,0.5,1.89c-0.02,0.82-0.04,1.65-0.06,2.47
            c-7.11,5.76-10.7,0.46-14.37-4.59C-128.02,169.2-123.5,176.29-118.9,179.4z"
            />
            <path
              style="fill: #6a93aa"
              d="M-139.55,142.91c1.66,1.91,6.31,2.04,5.42,4.84c-1.28,4-6.34,2.84-9.62,4.29
            c-8.91-5,6.8-5.09,1.85-9.3c0,0,1.04,0.25,1.04,0.25C-140.42,142.96-139.99,142.94-139.55,142.91z"
            />
            <path
              style="fill: #2b391b"
              d="M-191.22,129.28c-1.26-2.24-2.03-5.86-3.85-6.5c-12.96-4.57-7.4-11.61-2.94-18.65
            c3.5,1.92,6.96,3.43,9.77,6.83C-179.07,122.01-178.85,122.04-191.22,129.28z"
            />
            <path
              style="fill: #29351a"
              d="M-223.43,67.95c5.75,6.46,6.66,14.45,7.18,22.58c-1.62,0.86-3.23,1.73-4.85,2.6
            c-1.45-0.81-2.89-1.62-4.34-2.43c3.07-9.89-2.41-17.43-6.92-25.24c0,0,0.05-0.02,0.05-0.02
            C-228.46,63.13-225.59,64.3-223.43,67.95z"
            />
            <path
              style="fill: #516627"
              d="M-136.59,74.1c-2.25,7.78-4.51,15.56-6.76,23.34c-1.69-0.84-3.38-1.68-5.08-2.52
            c3.76-8.83,3.41-18,2.28-27.24c-5.85-2.41-8-7.96-10.87-12.88c-1.67-1.83-3.33-3.66-5-5.49c3.75-2.68,5.09-6.39,4.6-10.87
            c-1.45-4.43-2.52-9.35,2.58-11.66c4.55-2.06,7.47,1.8,9.72,5.33c2.31,3.63,6.04,5.29,9.49,7.4c0.79,0.84,1.34,1.83,1.68,2.93
            c0.33,1.64,0.61,3.29,0.24,4.96C-140.78,55.62-135.8,65.17-136.59,74.1z"
            />
            <path
              style="fill: #678e9f"
              d="M-139.55,142.91c-0.44,0.03-0.87,0.06-1.31,0.08c-2.59-6.3-4.76-12.38,6.06-11.75
            C-124.86,139.83-137.48,139.22-139.55,142.91z"
            />
            <path
              style="fill: #334d40"
              d="M-107.49,201.82c3.73,3.01,5.17,7.38,6.92,11.58c-6.81,0.34-10.31-4.22-13.54-9.17
            c0,0-0.07,0.05-0.07,0.05C-112.29,202.54-110.61,200.22-107.49,201.82z"
            />
            <path
              style="fill: #4a6b5b"
              d="M-107.49,201.82c-2.23,0.82-4.46,1.64-6.69,2.46c1.21-5.06,3.53-9.28,8.67-11.34
            C-106.18,195.9-106.83,198.86-107.49,201.82z"
            />
            <path
              style="fill: #979a4b"
              d="M-157.02,54.8c7.59,0.94,9,7.11,10.87,12.89c-2.78,1.43-5.55,2.86-8.33,4.29
            c-1.62-2.18-3.25-4.35-4.87-6.53C-162.42,61.05-158.21,58.25-157.02,54.8z"
            />
            <path
              style="fill: #34451e"
              d="M-96.04,176.88c1.94-2.38,7.91-2.88,5.47-7.14c-4.5-7.85,2.07-9.76,5.83-13.39
            c4.75-12.29,13.87-5.84,21.68-5.09c2.85,2.97,3.79,6.87,5.13,10.58c0.23,0.71,0.5,1.41,0.78,2.1c6.06,14.5,6,14.65-7.92,22.06
            c-1.04,0.49-2.14,0.78-3.28,0.9c-7.42-2.96-16.32,4.72-23.07-3.15C-92.97,181.47-94.5,179.18-96.04,176.88z"
            />
            <path
              style="fill: #4c6234"
              d="M-14.21,204.19c-5.4,5.94-1.45,15.9-8.98,20.92c-1.86-0.64-4.02-1.71-2.78-3.87
            c3.45-6.04-0.29-7.74-4.94-8.02c-5.81-0.35-11.71-0.04-17.52,0.47c-3.19,0.28-6.62-1.19-5.81-3.44
            c3.07-8.55-6.03-11.1-7.49-17.2c0.04-0.51,0.09-1.01,0.13-1.52c1.02-3.38,0.51-7.46,4.31-9.6c2.63-0.8,5.24-1.7,8.05-0.9
            c1.04,0.41,1.97,1,2.77,1.77c2.08,3.65,3.33,8.01,8.06,9.41c7.15,2.45,15.58,0.53,21.7,6.57
            C-15.08,200.38-13.28,201.55-14.21,204.19z"
            />
            <path
              style="fill: #1f2417"
              d="M-91.43,183.76c4.68-1.22,9.72-0.26,14.21-3.13c3.58-2.29,6.42-0.01,8.5,3.13
            c4.77,1.24,11.57,0.94,6.98,9.26c0,0,0.01,0.04,0.01,0.04c-7.52-0.26-14.02,1.02-17.91,8.77c-2.02,4.02-6.31,5.48-10.73,3.92
            c-4.12-1.45-5.02-4.96-4.93-9.12C-95.19,191.89-104.28,184.63-91.43,183.76z"
            />
            <path
              style="fill: #7d8d59"
              d="M-48.29,233.76c5.77,1.71,5.63,6.95,6.81,11.4c-5.9-1.19-6.82-6.27-8.42-10.82
            C-49.36,234.18-48.82,233.99-48.29,233.76z"
            />
            <path
              style="fill: #374c20"
              d="M60.71,193c-3.7-5.15-9.47-6.78-14.84-5.63c-8.44,1.8-8.9-2.17-7.9-8.37
            c2.84-5.54,0.38-10.69-1.2-15.9c0.08-3.9,3-5.35,5.88-6.84c2.13-1.4,8.71-1.67,1.37-5.47c1.95-5.7,7.06-6,11.79-6.98
            c6.71,3.67,6.05,9.6,4.83,15.72c-0.66,3.59,1.78,7.34-0.47,10.86c-1.09,2.03-4.02,1.8-4.97,4.02c-1.19,0.93,0.62,1.4,0.41,0.41
            c-0.28-1.3,1.67-2.05,3.12-2.43c2.04-0.65,4.06-1.39,6.25-0.73c3.39,1.48,7.02,1.73,10.63,2.33c4.72,0.78,8.4,3.1,5.75,9.01
            c-2.28,4.28-6.1,6.95-9.94,9.62c-2.97-0.06-5.27,1.83-7.91,2.72C61.61,195.96,59.97,195.79,60.71,193z"
            />
            <path
              style="fill: #69902b"
              d="M81.35,182.99c-1.45-4.98-4.74-6.54-9.24-4.22c-4.48,2.31-5.9-0.49-7.43-3.62
            c0.26-1.1,0.77-2.06,1.52-2.9c6.26-3.32,11.47-8.77,19.02-9.52c7.6,1.95,9.5,6.83,7.09,13.9c-0.68,1.6-1.36,3.19-2.04,4.79
            c-2.79-1.66-5.04-0.95-6.87,1.57c0,0-1.03,0.08-1.03,0.08L81.35,182.99z"
            />
            <path
              style="fill: #425627"
              d="M94.66,214.96c-6.28,2.04,3.8,11.37-4.79,11.94c-4,0.26-10.05-2.95-9.43-9.14
            c0.67-6.71,8.22-5.99,12.33-9.07C93.9,207.84,94.31,212.09,94.66,214.96z"
            />
            <path
              style="fill: #a5c420"
              d="M60.71,193c3.68,2.89,6.96-5.04,10.7-0.39c-2.3,1.83-6.07,2.83-3.98,7.12
            c-4.39,3.93-8.02,10.38-15.32,4.6C53.42,199.38,57.76,196.72,60.71,193z"
            />
            <path
              style="fill: #415732"
              d="M-14.21,204.19c-7.65-7.48-18.68-5.14-27.41-9.46c-0.26-0.75-0.2-1.46,0.16-2.11
            c-0.06,0.03,0.2-0.84,0.2-0.84s-0.89,0.17-0.97,0.18c-4.74-0.82-2.99-6.32-6.1-8.28l0.09,0.07c-8.8-7.74-9.79-17.91-8.92-28.68
            c0.64-0.88,1.46-1.55,2.42-2.05c4.63-0.72,6.34-3.25,5.19-7.9c-0.98-3.99-0.47-7.53,4.07-9.19c3.02-0.78,5.48,0.49,7.82,2.16
            c4.11,4.37,6.44,10.11,10.93,14.2c1.31,2.43,2.35,4.94,2.35,7.76c1.84,7.49,0.6,15.65,4.94,22.59
            c3.22,5.7,4.78,12.34,9.69,17.08C-10.17,202.27-11.67,203.75-14.21,204.19z"
            />
            <path
              style="fill: #334227"
              d="M-9.74,199.72c-7.04-3.52-15.17-6.14-13.72-16.66c-0.13-3.93,0.21-8.69,4.66-9.03
            c9.01-0.7,15.54-6.53,23.37-9.59c6.95,2.48,4.89,8.01,4.41,13.01c-2.81,5.93-11.05,4.53-13.95,10.35
            C-8.38,191.04-11.71,194.32-9.74,199.72z"
            />
            <path
              style="fill: #32421e"
              d="M24.29,179.08c-3.91,3.94-7.26,8.7-13.49,9.26c-1.52-2.68-3.87-4.14-6.87-4.65
            c-1.39-3.7,4.37-3.92,3.56-7.35c2.58-8.86,7.51-8.97,13.91-3.79C23,174.44,25.62,175.9,24.29,179.08z"
            />
            <path
              style="fill: #6f9294"
              d="M8.38,176.95c0.28,3.41-2.62,4.72-4.45,6.75c-2.97,1.37-5.94,2.73-8.91,4.1
            c1.26-6.18,7.86-6.84,11.19-10.87C6.95,176.33,7.67,176.31,8.38,176.95z"
            />
            <path
              style="fill: #202c19"
              d="M8.38,176.95l-1.08-0.08c0,0-1.08,0.06-1.08,0.06c-1.53-3.55,1.82-8.31-2.74-11.11
            c-1.99-0.2-2.6-1.37-2.25-3.21c4.24-3.18,6.35-7.92,8.97-12.3c1.84-1.91,4.01-3.33,5.23-3.95c-6.24,3.56-8.48-4.03-12.91-6.73
            c-0.56-0.94-0.91-1.96-1.12-3.03c1.75-14.18,5.21-16.78,17.47-11.24c7.14,3.22,8.95-2.86,12.81-5.19
            c-1-0.15-1.11,0.89-1.79,1.13c-10.44,3.9-12.52,2.46-15.38-10.53c-2.53-5.01-0.51-10.37-1.19-15.53
            c-1.7-6.04-6.58-10.74-7.25-17.23c0.58-3.22,2.49-5.05,5.72-5.5c3.11-0.1,5.27,1.72,7.37,3.64c1.83,1.8,3.55,3.72,5.41,5.46
            c1.22,1.24,2.53,2.06,4.28,0.85c1.37-1.25,2.69-2.53,4.42-3.27c0.97-0.37,1.96-0.55,2.99-0.56c5.61,0.74,8.9,4.6,11.94,8.78
            c3.18,1.72,5.63-1.04,8.46-1.37c2.64-1.29,5.26-2.2,7.78,0.27c2.27,3.17,2.22,6.52,0.75,9.96c-3.51,5.87-10.22,10.37-8.05,18.72
            c-0.17,3.98-4.09,7.23-2.23,11.6c0.29,4.51,0.92,8.91,3.74,12.69c1.01,2.03,1.37,4.07,0.14,6.14c-1.02,0.63-1.96,0.54-2.84-0.28
            c-2.68,4.01-7.52,4.25-11.23,6.45c-0.01,2.44-1.47,3.89-3.39,5.03c-4.48,1.13-7.8,2.87-4.39,8.24c1.22,2.76,0.88,5.16-1.6,7.08
            c-0.72,0.77-1.43,1.54-2.15,2.3c-4.4,0.84-8.71,3.66-13.28,0.57C15.84,174.2,11.17,170.44,8.38,176.95z"
            />
            <path
              style="fill: #61882d"
              d="M35.36,171.94c-1.37-2.14-0.84-4.21,0.22-6.26c0.24-1.47,1.12-2.28,2.57-2.49
            c8.59,3.62,8.56,6.1-0.18,15.81C37.1,176.65,36.23,174.3,35.36,171.94z"
            />
            <path
              style="fill: #a3bd4c"
              d="M21.87,203.98c-2.79,0.81-5.58,1.62-9.58,2.79c2.21-3.1,3.65-5.11,5.08-7.12
            c2.11-0.55,4.22-1.07,6.32-1.65c2.01-0.55,3.78-2.54,6.68-0.62C29.7,202.26,24.18,201.29,21.87,203.98z"
            />
            <path
              style="fill: #374c20"
              d="M19.93,174.82c4.47,0.85,8.58-6.72,13.28-0.57c-1.8,3.78-5.45,4.14-8.92,4.83
            C22.75,177.75,19.84,177.82,19.93,174.82z"
            />
            <path
              style="fill: #688831"
              d="M-98.63-98.59c2.37,1.33,4.82,1.57,7.38,0.59c1.73,1.38,3.9,2.33,4.66,4.68
            c0.95,5.8,4.29,9.03,9.81,10.07c4.59-6.61-6.88-9.87-2.48-15.4c1.82-2.28,5.62-1.3,8.39,0.38c0.46,0.34,0.92,0.67,1.39,1.01
            c1.86,2.15,1.65,4.95,2.18,7.47c2.98,14.04-0.97,18.48-14.75,16.72c-2.56-0.33-5.26,0.91-7.7-0.68
            c-2.96-3.92-5.96-7.73-11.76-4.8c-5.19-1.69-8.88-4.25-6.03-10.54C-102.23-90.05-100.9-94.77-98.63-98.59z"
            />
            <path
              style="fill: #33441e"
              d="M-107.55-89.09c-0.02,5.16,6.27,5.08,7.58,9.14c2.63,1.59,3.9,4.12,4.81,6.91
            c0.35,1.75,0.06,3.43-0.55,5.07c-3.95,5.28-9.58,9.11-12.9,14.96c-2.12,1.11-4.24,2.14-6.23-0.22
            c4.88-11.52-3.02-22.3-1.93-33.59c0.27-0.76,0.54-1.51,0.81-2.27C-113.15-89.09-110.35-89.09-107.55-89.09z"
            />
            <path
              style="fill: #9ab457"
              d="M-86.6-93.32c-4.58,1.45-5.76-0.48-4.66-4.68c3.04-4.41-5.87-6.64-2.03-11.2
            C-83.41-107.14-87.03-99.38-86.6-93.32z"
            />
            <path
              style="fill: #2c391b"
              d="M-68.51-100.99c1.25,4.91,9.54-2.52,9.15,5.24c1.21,1.79,1.12,3.53-0.17,5.24
            c-8.45,4.33-9.5-2.28-11.36-7.7c0,0,0.01-0.06,0.01-0.06C-70.09-99.17-69.3-100.08-68.51-100.99z"
            />
            <path
              style="fill: #6d8c30"
              d="M-59.99-91.54c0.21-1.4,0.42-2.8,0.64-4.2c1.54-0.21,3.08-0.41,4.62-0.62
            c1.49-2.57,3.26-4.21,6.25-1.95c0.28,2.78,0.57,5.55,3.01,7.45c0.25,1.12,0.03,2.15-0.65,3.08c-3.26,2.58-7.01,3.3-11.06,2.96
            C-60.41-86.1-61.78-88.17-59.99-91.54z"
            />
            <path
              style="fill: #3a4b21"
              d="M-48.49-98.32c-2.08,0.65-4.17,1.3-6.25,1.95c-0.46-3.82-2.13-7.86,1.96-10.85
            C-42.23-108.66-48.18-102.13-48.49-98.32z"
            />
            <path
              style="fill: #496828"
              d="M-177.97-46.16c-0.71-2.93-1.43-5.85-2.14-8.78c6.14,3.83,12.29,7.67,18.43,11.5
            c2.02,2.9,3.31,5.8,0.02,8.72c-2.98,3.44-6.4,4.65-10.64,2.24c-3.26-1.85-1.69-4.3-0.93-6.67
            C-175.84-40.79-176.62-43.67-177.97-46.16z"
            />
            <path
              style="fill: #8dac21"
              d="M-134.54-52.97c1.85,0.08,3.71,0.17,5.56,0.25c2.81,11.3-5.28,14.06-13.25,16.95
            c-4.86,0.59-8.42-1.38-10.86-5.54c-2.04-5.28-1.06-9.98,2.73-14.13c2.15-0.51,4.3-1.02,6.45-1.52c0,0-0.02-0.06-0.02-0.06
            C-141.43-54.2-137.7-54.24-134.54-52.97z"
            />
            <path
              style="fill: #7b9c2d"
              d="M-150.35-55.44c-0.03,4.79-0.06,9.57-0.09,14.36c-0.98,7.1-5.51,7.83-11.3,6.3
            c0,0,0.08,0.06,0.08,0.06c-0.26-2.91-1.81-5.81-0.02-8.72C-157.19-46.76-158.6-55.66-150.35-55.44z"
            />
            <path
              style="fill: #676c3a"
              d="M-180.04-54.83c-4.54-1.95-7.98-6.44-13.6-5.94c-0.24-5.25,5.15-5.31,7.52-8.17
            c2.22,1.9,4.46,2.88,6.8,0.18C-175.91-63.93-178.38-59.4-180.04-54.83z"
            />
            <path
              style="fill: #313e1e"
              d="M-114.84-53.23c1.99-0.78,3.98-1.56,5.98-2.35c0.48-9.17,4.7-9.08,10.8-4.37
            c1.38,1.1,2.31,2.51,2.82,4.19c1.9,11.39,2.9,22.75-1.38,33.91c-1.67,1.79-3.73,2.74-6.17,2.85c-5.06-0.51-7.57-4.81-11.14-7.54
            c-1.22-1.92-1.22-4.13-1.45-6.27c-0.62-3.94-0.68-8.17-5.12-10.19c-4.16-1.98-8.62-3.72-8.31-9.58
            c0.93-6.16,9.27-5.45,10.48-11.35C-120.41-59.3-114.43-57.31-114.84-53.23z"
            />
            <path
              style="fill: #1f2515"
              d="M-128.82-52.58c1.98,4.31,8.01,2.13,10.08,6.29c0.38,2.03,1.37,3.96,0.92,6.11
            c-1.61,5.22-6.1,9.29-6.06,15.18c-0.98,4.57,1.26,9.83-2.86,13.74c-0.96,0.61-2,0.95-3.14,1.01
            c-9.03-2.15-10.03-10.9-14.11-17.14c0.02-4.11,1.3-8,2.31-11.93c5.7-3.07,11.04-6.47,12.67-13.4
            C-128.98-52.71-128.82-52.58-128.82-52.58z"
            />
            <path
              style="fill: #30411d"
              d="M-134.54-52.97c-6.99,7.6-7.12-0.68-9.38-4.05C-140.15-57.18-136.09-57.97-134.54-52.97z"
            />
            <path
              style="fill: #6e9346"
              d="M-216.46-94.38c4.83-1.46,10.21-1.69,13.66-6.18c0.8-0.35,1.6-0.71,2.41-1.06
            c-0.12,1.84-0.23,3.69-0.35,5.53c-5.91,2.49-11.2,6.53-17.88,7.08c-0.11-0.85-0.23-1.71-0.34-2.56
            C-218.12-92.51-217.29-93.44-216.46-94.38z"
            />
            <path
              style="fill: #949a65"
              d="M-196.03-84.2c-0.74-1.66-1.48-3.32-2.22-4.98c9.05-0.93,6.94,5.25,6.69,11.42
            C-193.42-80.43-194.73-82.32-196.03-84.2z"
            />
            <path
              style="fill: #a1be2b"
              d="M112.65,160.94c-1.09-0.18-3-0.11-3.14-0.57c-3.94-13.32-14.3-16.68-26.46-17.37
            c-1.76-5.22,1.75-8.28,4.7-11.57c2.53-1.48,5.98-1.78,6.88-5.36c1.18-3-0.1-6.24,0.86-9.27c0.33-2.9,0-5.92,1.65-8.56
            c0.77-1.03,1.73-1.81,2.88-2.36c9.5-2.52,12.64,2.64,13.21,10.92c-3.8,10.34,4.6,16.54,8.32,24.32
            c0.96,1.21,1.78,2.51,2.56,3.83c0.64,1.43,1.29,2.86,1.93,4.28c-8-4.27-10.07,0.45-10.83,7.15
            C113.15,157.22,113.24,159.27,112.65,160.94z"
            />
            <path
              style="fill: #c5d332"
              d="M124.12,144.96c-1.21,0.05-2.42,0.11-3.62,0.16c-4.22-5.43-1.51-7.74,3.95-8.83
            c4.83,0.87,7.74-2.6,11.14-4.96C137.6,140.79,130.06,142.2,124.12,144.96z"
            />
            <path
              style="fill: #40512a"
              d="M56.9,140.59c-6.76,0.83-8.11-2.82-5.08-13.69c2.28-5.81,6.95-8.28,12.77-9.17
            c5.32,2.07,11.81-2.87,16.59,2.66c1.96,3.64,0.69,7.7,1.48,11.49c1.43,4.09-0.03,7.78-1.56,11.47c0.02,1.35,0.05,2.69,0.07,4.04
            c-0.88,3.08-2.89,4.16-5.91,3.4c-1.53-2.1-3.69-3.14-6.31-3.37C65.25,144.54,61.67,141.51,56.9,140.59z"
            />
            <path
              style="fill: #92b625"
              d="M74.5,149.35c2.22-0.65,4.45-1.3,6.67-1.96c5.95-0.36,11.46-0.11,11.83,7.93
            c0.03,0.69,0.36,1.76,0.85,1.99c16.91,7.86,6.9,13.53-1.54,19.32c-1.27-4.33-2.42-8.73-6.55-11.4c-0.64-0.31-1.17-0.76-1.6-1.34
            c-1.3-0.54-2.32,0.13-3.4,0.71c-2.78,1.65-5.55,2.13-8.36,0.12C70.37,159.23,65.51,153.34,74.5,149.35z"
            />
            <path
              style="fill: #5e832b"
              d="M81.1,143.35c-0.75-3.55-1.51-7.1-2.26-10.65c5.25-2.19,1.47-10.67,8.22-11.82
            c2.86-0.04,4.69,1.4,5.77,3.95c1.07,3.55-0.22,6.41-2.61,8.94c-2.39,3.08-4.78,6.16-7.16,9.23
            C82.4,143.11,81.75,143.22,81.1,143.35z"
            />
            <path
              style="fill: #69902c"
              d="M124.45,136.29c-3.33,2.04-5.42,4.64-3.95,8.83c-8.7-3.49-8.54-14.06-15.67-19.49
            c-3.69-2.81,1.5-6.72,6.04-7.4c2.96-1.49,5.05-5.62,9.34-3.05c2.27,6.22,6.83,8.16,13.05,7.01
            C132.71,128.39,118.71,126.17,124.45,136.29z"
            />
            <path
              style="fill: #7aa62c"
              d="M120.21,115.19c-3.03,1.26-5.11,5.45-9.34,3.05c-1.17-5.06-2.31-10.15-9.28-9.61
            c-4.03-8.28,3.16-9.27,7.74-11.97c1.8-0.61,3.62-0.52,5.44-0.13c1.17,0.24,2.24,0.75,3.18,1.48c3.96,3.82-0.1,8.82,1.88,12.94
            C119.96,112.36,120.08,113.77,120.21,115.19z"
            />
            <path
              style="fill: #9cbf25"
              d="M140.76,92.64c2.86,2.86,0.56,4.98-1.02,7.21c-0.68-0.07-1.35-0.17-2.01-0.31
            c-7.41-2.85-8.59-10.31-11.97-16.15c1.39-4.91-1.03-11.08,4.74-14.57c5.8-0.05,8.67,4.66,12.53,7.78
            C144.4,82.24,139.1,86.95,140.76,92.64z"
            />
            <path
              style="fill: #658e2f"
              d="M128.99,83.63c5.24,4.02,5.2,10.95,8.74,15.9c-5.52,0.69-9.18-1.7-11.25-6.76
            c-1.44-1.16-1.93-2.7-1.83-4.49C125.19,85.91,126.51,84.23,128.99,83.63z"
            />
            <path
              style="fill: #b0c260"
              d="M119.84,110.94c-6.27-2.79-1.92-7.48-2.04-11.37c-0.15-3.97,1.86-3.47,4.3-2.09
            C128.7,103.21,122.31,106.74,119.84,110.94z"
            />
            <path
              style="fill: #93b72f"
              d="M122.1,97.48c-1.43,0.7-2.87,1.39-4.3,2.09c-0.48,0-0.96,0.04-1.44,0.11
            c-5.98-8.1-5.15-10.68,4.9-15.37c2.58,0.34,4.43,1.61,5.18,4.22c0.01,1.42,0.03,2.84,0.04,4.26
            C125.02,94.35,123.56,95.92,122.1,97.48z"
            />
            <path
              style="fill: #cad654"
              d="M140.76,92.64c-6.4-5.99,0.45-10.54,1.71-15.69c-1.74-2.3-2-4.57,0.08-6.82
            c1.91,1.84,2.58,4.12,2.38,6.71c-0.72,4.44,2.19,8.98-0.33,13.38C143.32,91.01,142.04,91.83,140.76,92.64z"
            />
            <path
              style="fill: #c0c9a2"
              d="M152.67-87.07c-0.39,3.17-0.78,6.33-1.16,9.5c-1.46,2.63-2.91,5.25-4.37,7.88
            c-2.26,3.83-4.36,0.98-6.51-0.15c-1.78-1.34-3.51-2.72-4.36-4.89c-0.48-1.67-0.4-3.33,0.17-4.96
            c0.94-5.06,1.99-10.07,7.69-12.11c1.69-0.34,3.34-0.23,4.92,0.54c0.93,0.62,1.82,1.3,2.66,2.03
            C152.02-88.51,152.35-87.79,152.67-87.07z"
            />
            <path
              style="fill: #869b51"
              d="M151.7-89.23c-0.77,0.04-1.55,0.07-2.32,0.11c-3.5-2.77-5.13-6.99-8-10.26
            c-0.71-0.72-1.34-1.5-1.91-2.34c-1.1-1.82-1.85-3.72-1.39-5.9c0.55-2.29,2.28-3.38,4.27-4.21c0.63,0.05,1.24,0.19,1.83,0.42
            C154.93-106.82,152.01-97.58,151.7-89.23z"
            />
            <path
              style="fill: #45593a"
              d="M149.59-57.42c0.72,0.74,1.45,1.48,2.17,2.21c-0.05,2.42-0.1,4.83-0.16,7.25
            c0.82,3.06,2.34,6.01,1.45,9.34c-0.78,1.54-1.99,2.59-3.62,3.15c-3.09,0.71-5.07-1.34-7.24-2.91
            c-12.94-7.15-13.08-9.11-1.38-18.18C143.57-58.51,146.44-59.4,149.59-57.42z"
            />
            <path
              style="fill: #7b956f"
              d="M149.19-37.52c0.84-0.65,1.67-1.3,2.51-1.94c2.61-1.19,4.93,0.8,7.44,0.69
            c0,0,0.87-0.04,0.87-0.03c-0.22,4.21,3.48,6.14,5.32,9.15c-2.72,3.3-4.31,8.1-9.96,7.52c-2.75,0.1-5.28-0.39-7.31-2.45
            c-2.42-2.97-2.15-6.28-1.24-9.67C147.42-35.48,148.21-36.56,149.19-37.52z"
            />
            <path
              style="fill: #273820"
              d="M159.14-38.77c-1.63,2.13-3.26,4.26-4.9,6.39c-0.85-2.36-1.7-4.72-2.54-7.08
            c-2.02-2.81-2.53-5.64-0.1-8.5C155.57-46.09,156.76-41.94,159.14-38.77z"
            />
            <path
              style="fill: #61785d"
              d="M149.59-57.42c-2.45,1.28-4.9,2.55-7.35,3.83c0.1,2.78-2.01,1.71-3.38,1.93
            c-6.1-3.41-5.05-8.79-3.94-14.2c1.94-3.15,3.99-6.22,7.68-7.61c2.67-0.14,2.72,2.89,4.54,3.77
            C153-66.61,150.98-61.95,149.59-57.42z"
            />
            <path
              style="fill: #51662a"
              d="M155.97-24.69c3.12-1.66,6.23-3.31,9.35-4.97c-0.04,1.28-0.08,2.55-0.13,3.82
            c-0.86,3.26-1.72,6.53-2.58,9.8c-1.78,1.64-3.79,2.12-6.09,1.26c-0.98-0.56-1.74-1.34-2.31-2.31
            C152.94-20.04,154.28-22.41,155.97-24.69z"
            />
            <path
              style="fill: #2e3e1d"
              d="M137.56-53.27c1.56-0.1,3.11-0.21,4.67-0.31c0.66,1.88-0.29,2.62-2,2.89
            c-2.08,0.33-5.02-0.04-5.41,2.57c-0.52,3.41,2.99,3.43,5.12,4.48c1.61,0.79,3.24,1.53,4.86,2.29c-1.6,4.29,3.07,5.09,4.33,7.79
            c0.04,2.74,0.08,5.48,0.12,8.22c0.11,0.47,0.22,0.94,0.34,1.41c0.5,4.16-0.71,8.69,2.65,12.24c0.1,4.13-1.62,7.79-3.01,11.54
            c-0.68,0.65-1.36,1.3-2.04,1.94c-6.66,2.24-13.54,0.85-20.3,1.22c-6.67-1.85-8.18,6.39-13.58,7.03
            c-4.91,1.86-6.82-7.85-12.45-3.2c-1.84,0.81-3.59,1.78-5.36,2.72c-5.64,3.31-12.31,2.01-18.23,4.08
            c-2.2,0.2-4.07-0.86-6.04-1.51c-4.21,0.1-8.16-4.5-12.6-0.63c-6.7,2.35-13.76,5.92-16.36-5c-1.47-2.71-2.82-5.51-5.1-7.7
            c-5.05-6.05-0.42-8.36,4.09-10.73c4.99-2.07,0.24-7.63,3.63-10.29c1.9-1.57,4.06-2.04,6.45-1.62c2.33,0.47,4.35,1.59,6.25,2.98
            c1.84,2.04,3.55,4.22,5.42,6.23c2.22,4.21,5.03,5.67,10.04,3.84c5.63-2.06,10.69-5.3,16.33-7.23c2.38-0.58,4.31-1.77,5.45-4.06
            c2.51-5.36,0.39-14.66,8.53-15.1c8.4-0.45,7.91,8.14,9.38,14.08c0.87,3.49,1.3,8.4,6.58,3.36c2.03-6.25-3.53-7.71-6.69-10.64
            c-2.04-7.01-11.29-8.14-12.8-15.61c0.93-3.88,1.76-7.65,1.32-11.82c-0.57-5.34,2.05-8.37,7.93-7.21
            c3.53,1.27,7.05,2.58,9.91,5.13c1.83,1.9,3.01,5.07,6.67,3.39c2.63-1.32,5.18-3.1,8.34-1.57
            C135.66-56.84,136.06-54.65,137.56-53.27z"
            />
            <path
              style="fill: #688c39"
              d="M149.23-0.17c-2.09-3.66,0.54-7.5-0.41-11.21c0.91-3.72,1.56-7.82,7.06-4.89
            c2.24,0.08,4.49,0.15,6.73,0.23C164.15-5.68,151.83-7.04,149.23-0.17z"
            />
            <path
              style="fill: #455c27"
              d="M126.61-0.39c6.95-0.15,14.1-2.23,20.58,2.17c-0.05,0.64-0.07,1.28-0.08,1.91
            c-0.09,5.11-2.52,7.31-7.64,6.18c-2.6-0.73-5.24,0.27-7.71,0.07C124.96,9.38,122.38,6.02,126.61-0.39z"
            />
            <path
              style="fill: #93ba2f"
              d="M156.55,22.17c-1.71,1.48-3.41,2.96-5.12,4.44c-6.64,7.37-8.61,2.02-10.4-3.83
            c1.75-3.44,2.61-7.93,8.13-6.99C150.96,18.68,156.04,17.77,156.55,22.17z"
            />
            <path
              style="fill: #455c27"
              d="M149.16,15.79c-2.26,2.12-1.99,6.75-6.53,6.62c-2.45,1.35-4.06,0.02-5.52-1.76
            c-1.72-4.37,1.66-6.89,3.61-9.93c2.79-1.2,5.79-1.5,8.74-2.04C154.13,11.25,150.87,13.49,149.16,15.79z"
            />
            <path
              style="fill: #87af2b"
              d="M149.47,8.68c-1.43,3.66-3.97,3.83-7.07,2.28c-1.36,1.36-2.87,1.35-4.46,0.46
            c-1.23-2.83,0.54-3.97,2.74-4.86c3.38,1.81,4.3-1.89,6.44-2.87C149.07,4.8,149.08,6.82,149.47,8.68z"
            />
            <path
              style="fill: #9da56e"
              d="M-223.37,53.91c0.95,0.15,1.91,0.3,2.86,0.45c1.3,7.6-1.38,10.93-9.27,8.65
            c0.6-2.24,1.2-4.48,1.8-6.72C-226.44,55.5-224.9,54.7-223.37,53.91z"
            />
            <path
              style="fill: #3e5321"
              d="M-229.78,63.02c5.42-0.39,8.07-3.74,9.27-8.65c3.92,2.29,11.84,3.8,1.73,8.84
            c-1.55,1.58-3.1,3.16-4.65,4.74c-2.96-0.84-5.93-1.68-8.89-2.51C-231.47,64.63-230.62,63.82-229.78,63.02z"
            />
            <path
              style="fill: #3b4f20"
              d="M-46.25-89.38c0.2-0.52,0.46-1.02,0.76-1.49c1.49-0.11,2.98-0.22,4.47-0.33
            c2.66,2.69,1.11,8.43,6.57,9.1c0.79,0.73,1.29,1.63,1.52,2.68c0.03,1.04-0.16,2.04-0.57,3c-2.04,2.62-5.32,4.46-5.34,8.4
            c0.06,3.38-0.59,6.36-4.24,7.67c-1.68,0.31-3.25-0.2-4.82-0.65c-2.42-1.33-4.07-3.41-5.48-5.71c-0.58-4.54-5.76-5.21-7.46-8.83
            c-0.6-2.22,0.01-4.17,1.32-5.96C-56.63-86.7-47.76-81.82-46.25-89.38z"
            />
            <path
              style="fill: #7eac27"
              d="M-18.35-86.98c3.05-2.78,6-1.41,8.95,0.2c0.81,7.07,2.28,14.68-9.42,11.28
            c-0.68-1.17-0.59-2.29,0.25-3.35C-14.89-81.46-16.71-84.22-18.35-86.98z"
            />
            <path
              style="fill: #729537"
              d="M-18.56-78.84l-0.12-0.05c-5.77,11.5-10.85,3.91-16.04-0.59c0.09-0.88,0.19-1.75,0.28-2.63
            C-27.55-88.82-24.28-77.84-18.56-78.84z"
            />
            <path
              style="fill: #2d3b1c"
              d="M-34.72-79.47c5.44-2.25,10.42,7.88,16.04,0.59c4.91,0.98,4.91,0.98,9.29-7.9
            c0.47,0.06,0.93,0.05,1.4-0.02c6.26,5.04,3.89,9.79-0.47,14.47c-2.29,2.67-4.06,5.93-7.75,7.08c-3.32,0.96-5.78,3.69-9.17,4.54
            c-7.59,3.33-12.78,1.32-15.2-6.84c-0.09-4.53,2.13-7.72,5.85-10.02C-34.65-78.2-34.65-78.84-34.72-79.47z"
            />
            <path
              style="fill: #819938"
              d="M-12.22-73.3C-8.37-77.04-6.7-81.45-8-86.8c0.61-0.08,1.23-0.08,1.84-0.02
            c5.29-2.77,8.05,0.36,10.41,4.46C5.63-74,5.03-67.03-6.17-67.48C-9.21-68.34-11.42-70.09-12.22-73.3z"
            />
            <path
              style="fill: #b9c973"
              d="M1.81-82.13c-2.44-1.93-4.19-5.03-7.97-4.69c-0.24-1.43-0.49-2.86-0.73-4.3
            c0.57-0.01,1.09-0.17,1.55-0.5c2.56-1.15,3.52,1.07,4.93,2.35c2.45,0.09,4.86,0.06,6.78-1.83c3.97-1.24,5.24,1.22,6.01,4.39
            c-0.52,4.4-3.3,6.1-7.41,6.2C3.79-80.8,2.74-81.34,1.81-82.13z"
            />
            <path
              style="fill: #879656"
              d="M-0.41-89.27c-1.64-0.78-3.28-1.57-4.93-2.35c1.2-6.47,5.46-8.95,11.63-8.92
            C4.8-96.34-1.99-95.31-0.41-89.27z"
            />
            <path
              style="fill: #425622"
              d="M37.99-98.1c2.27,6.75-5.18,3.9-6.76,6.85c-1.22,1.56-2.86,2.25-4.8,2.3
            c-3.52-1.58-9.17-0.46-8.85-6.95c0.02-0.87,0.04-1.75,0.06-2.62C24.43-98.38,31.21-98.24,37.99-98.1z"
            />
            <path
              style="fill: #81a23f"
              d="M17.59-95.9c2.12,3.4,6.52,2.04,9.14,4.39c0.27,3.54,2.42,7.21-0.76,10.51
            c-2.13,2.22-4.66,3.01-7.65,2.15c-3.82-1.43-6.51-3.95-7.57-8.01c-0.87-2.03-2.23-3.56-4.39-4.25
            C7.9-97.89,13.66-94.74,17.59-95.9z"
            />
            <path
              style="fill: #30441e"
              d="M146.92,67.42l-1.05,0.06l-0.99-0.35c-4.32-1.7-7.72,5.48-12.3,1.28
            c-2.08-1.12-3.96-0.37-5.83,0.62c-2.63,2.21-5.25,4.41-9.08,3.44c-6.64-6.85-5.95-12.16,2.31-15.65
            c4.4-1.86,9.28-2.42,13.72-4.31c4.85-2.08,7.84,1.74,11.45,3.61C151.49,59,147.16,63.53,146.92,67.42z"
            />
            <path
              style="fill: #405324"
              d="M145.15,56.13c-3.54,2.33-6.46,1.65-8.82-1.8c-4.29-0.47-7-2.68-6.82-7.17
            c0.13-3.32,2.44-5.33,5.47-6.32c3.59-1.17,6.41,0.13,8.95,2.8c1.56,1.64,1.4,4.29,3.55,5.47c0,0,0.18,0.09,0.18,0.09
            C146.83,51.51,145.99,53.82,145.15,56.13z"
            />
            <path
              style="fill: #5b862e"
              d="M147.49,49.11c-1.27-0.57-2.98-0.81-3.73-1.78c-2.67-3.46-5.85-5-9.45-2.08
            c-4.87,3.95,0.59,6.19,2.01,9.09c-4.09,4.26-9.17,6.01-14.93,7.17c-6.56,1.31-8.87,4.64-1.45,8.9c3.97,1.94,6.13,4.96,5.69,9.55
            c-1.03,2.46-2.93,3.37-5.48,3.12c-1.08-0.3-2.03-0.84-2.87-1.58c-3.33-4.92-8.38-7.19-13.76-9.01
            c-5.56-1.87-10.32-4.55-10.73-11.34c0.24-2.34,1.35-4.2,3.14-5.67c6.38-1.57,13.34-2.08,13.68-11.42
            c0.11-3.01,3.79-4.27,6.47-5.66c3.4-1.77,6.22-4.02,6.45-8.39c0.37-7.06,4.72-10.57,11.2-12.04c1.73,0.07,3.19,0.72,4.3,2.07
            c1.54,0.79,3.08,1.59,4.63,2.38c2.15,3.04,3.67,7.4,8.8,4.2c-0.74,2.4-1.47,4.8-2.21,7.2
            C143.01,38.27,148.62,44.07,147.49,49.11z"
            />
            <path
              style="fill: #557b2f"
              d="M133.24,65.92c3.21,2.5,4.4-0.85,6.41-2.11c3.9-2.46,5.4-0.78,5.24,3.33
            c-0.78,0.99-1.56,1.99-2.34,2.98c-0.03,2.27-0.05,4.55-0.08,6.82c-8.99,6.24-8.88-2.5-11.38-6.94
            C129.65,67.5,129.32,65.59,133.24,65.92z"
            />
            <path
              style="fill: #7b9741"
              d="M72.09-100.71c0.19,6.93-5.9,11.71-6.31,18.43c-6.54,3.72-8.18-1.68-10.6-5.68
            c-0.91-3.21-0.34-5.88,2.88-7.52c0,0,0.51-0.75,0.51-0.75c5.68,1.1,6.65-6.4,11.71-6.42C71.87-102.93,72.69-102.49,72.09-100.71
            z"
            />
            <path
              style="fill: #a2c153"
              d="M72.09-100.71c-0.6-0.64-1.21-1.28-1.81-1.93c-0.2-3.96-0.65-7.96,2.27-11.29
            c0.53,0.24,1.06,0.46,1.61,0.67c0.47,4.19,7.28,8.29,0.18,12.58c0,0-1.12,0.13-1.12,0.13L72.09-100.71z"
            />
            <path
              style="fill: #c0c3a3"
              d="M58.06-95.48c0.04,1.37-1.65,2.86,0.27,4.11c1.38,5.48,3.1,10.92,2.04,16.71
            c-1.27,2.14-3.14,3.51-5.48,4.28c-4.23-0.92-9.43,2.12-12.64-3.07c0.81-2,2.86-4.56,2.25-5.92
            C37.71-94.49,48.94-94.03,58.06-95.48z"
            />
            <path
              style="fill: #8eb92c"
              d="M24.32-82.17c-2.14-1.9,1.71-5-2.17-7.62c-1.94-1.31,3.18-0.45,4.58-1.72
            c1.5,0.08,3,0.17,4.5,0.25c1.82,4.92-0.66,12.08,7.22,13.83c-4.35,7.98-9.47,6.17-14.84,1.17
            C22.61-78.37,23.17-80.31,24.32-82.17z"
            />
            <path
              style="fill: #c8d0de"
              d="M42.26-73.44c3.99-0.35,8.05-1.96,11.89,0.6c1.64,2.71,0.9,5.28-0.47,7.82
            c-0.72,0.88-1.6,1.57-2.6,2.1c-3.03,1.47-5.86,3.84-9.6,2.08c-3.95-3.2-6.75-6.68-2.71-11.74c0.53-0.21,1.07-0.42,1.61-0.63
            C41.02-73.18,41.65-73.26,42.26-73.44z"
            />
            <path
              style="fill: #546831"
              d="M24.54-77.93c4.54,2.75,9.14,4.05,13.91,0.5c0.64,1.41,1.28,2.82,1.93,4.22
            c0,0,0.16,0.43,0.16,0.43c-4.07,0.96-4.9,6.86-9.86,6.47c-6.02-1.31-12.27-2.29-13.81-9.87C18.67-80.05,21.54-79.28,24.54-77.93
            z"
            />
            <path
              style="fill: #37461e"
              d="M107.84-61.66c-5.26-1.1-3.29,2.44-2.66,4.52c1.42,4.7,0.95,8.58-3.57,11.3
            c-3.8-0.31-5.68-2.68-6.62-6.1c0.17-3.94-0.79-6.82-5.62-6.03c-2.47,0.79-2.92,3.45-4.58,5.01c-1.7,1.53-3.66,2.29-5.96,2.04
            c-2.35-0.5-3.84-1.98-4.74-4.13c-0.8-3.2,0.68-7.64-4.63-8.42c-5.43-4.33-3.02-10.33-3.39-15.73c0.32-1.16,0.97-2.08,1.95-2.76
            c4.41-0.86,8.82-1.72,13.23-2.58c5.34-1.75,5.84,4.52,9.45,5.66c2.74,1.95,3.01,5.1,3.86,7.97c3.72,5.96,9.57,0.64,14.04,2.66
            c1.59,0.23,2.61,1.09,2.97,2.68C111.51-63.14,109.63-62.45,107.84-61.66z"
            />
            <path
              style="fill: #77874f"
              d="M144.46-89.28c-5.32,2.5-0.49,8.55-4.07,11.65c-3.48-1.42-9.08,0.22-9.04-6.27
            c-1.57-1.71-3.14-3.43-4.71-5.14c-0.57-4.07-1.43-8.29,4.51-9.15c3.79-0.86,5.36-6.79,10.49-4.61l1.09,0.24
            C144.51-98.29,148.08-94.25,144.46-89.28z"
            />
            <path
              style="fill: #3f4f20"
              d="M94.57-70.91c-2.26-1.69-4.51-3.39-6.77-5.08c-1.44-4.42-5.28-8.83-0.05-13.27
            c2.41-1.17,4.72-1.23,6.83,0.67c0.05,4.46,2.35,6.62,6.76,6.61C102.22-76.36,96.21-74.97,94.57-70.91z"
            />
            <path
              style="fill: #37471f"
              d="M131.34-83.9c2.47,2.88,7.28,2.37,9.04,6.27c-0.71,0.65-1.41,1.31-2.12,1.96
            c2.43,4.82,0.96,8.17-3.86,10.25c-4.01,1.21-5.41-1.79-7.06-4.39C124.16-75.79,128.16-79.73,131.34-83.9z"
            />
            <path
              style="fill: #9eb09c"
              d="M127.35-69.8c1.88,0.96,3.76,1.92,5.64,2.88c2.62-0.68,5.08-1.08,5.08,2.88
            c-0.55,3.57,1.56,7.27-0.5,10.77c-3.31,0.82-4.07-1.36-4.55-3.87c-3.19-2.98-14.5-1.55-5.92-10.93
            C127.51-68.6,127.59-69.18,127.35-69.8z"
            />
            <path
              style="fill: #7b9240"
              d="M127.09-68.07c-3.24,6.48,6.75,5.77,5.92,10.93c-1.97,1.66-3.93,3.32-5.9,4.99
            c-4.52-0.84-7.05-4.5-10.23-7.24c-1.69-5.19,4.12-7.13,4.87-11.26C123.53-69.8,125.31-68.94,127.09-68.07z"
            />
            <path
              style="fill: #61792b"
              d="M121.75-70.66c0.86,4.48-1.04,8.1-3.56,11.53c0.22,0.91-0.11,1.59-0.9,2.06
            c-3.14-1.53-6.29-3.06-9.44-4.59c0.71-1.67,1.43-3.35,2.15-5.02c2.68-3.46,7.09,1,9.84-2.14
            C120.47-69.44,121.11-70.05,121.75-70.66z"
            />
            <path
              style="fill: #39471e"
              d="M119.83-68.82c-2.7,3.35-6.39,2.15-9.83,2.14c-0.56-0.45-1.02-0.97-1.39-1.57
            C111.79-79.41,116.17-67.09,119.83-68.82z"
            />
            <path
              style="fill: #546631"
              d="M141.62-102.79c-3.17,2.28-4.36,9.06-10.49,4.61c-0.13-1.43-0.26-2.85-0.4-4.28
            c0.87-1.7,1.74-3.4,2.61-5.1c2.32-1.41,4.57-1.61,6.67,0.43C140.55-105.69,141.09-104.24,141.62-102.79z"
            />
            <path
              style="fill: #92a36f"
              d="M87.76-89.26c2.42,4.42,0.05,8.85,0.05,13.27c-4.47-1.1-3.57-6.31-6.54-8.55
            c0.04-0.77,0.08-1.55,0.12-2.33C83.64-87.32,82.84-95.92,87.76-89.26z"
            />
            <path
              style="fill: #738a58"
              d="M140.01-107.13c-2.32,1.41-4.53,1.03-6.67-0.43c0.79-2.95,1.57-5.9,2.36-8.84
            c3.04,0.33,4.74,2.61,6.64,4.58C141.57-110.26,140.79-108.7,140.01-107.13z"
            />
            <path
              style="fill: #475b2d"
              d="M68.03-81.96c-0.1,0.62-0.16,1.25-0.17,1.88c-1.16,5.52-3.57,8.5-9.37,4.65
            c-0.05-5.31-0.1-10.62-0.15-15.94c3.4,2.27,1.31,9.05,7.44,9.09C66.53-82.2,67.28-82.09,68.03-81.96z"
            />
            <path
              style="fill: #516627"
              d="M-166.4,26.9c-3.93-8.62-0.23-16.36,3.68-23.51c1.74-3.18,0.92-5.61,1-8.42
            c1.38-2.32,3.21-4.09,5.86-4.9c2.14-0.55,4.28-0.56,6.43-0.09c3.54,0.12,6.67,1.17,9.07,3.93c6.29,3.91,7.44,10.36,8.39,16.92
            c1.04,7.07,1.87,14.12-3.06,20.28c-6.02,3.33-8.93-1.09-11.42-5.11c-4.61-7.43-8.79-11.04-14.18-0.65
            C-161.56,27.14-163.95,28.33-166.4,26.9z"
            />
            <path
              style="fill: #5d772a"
              d="M-166.4,26.9c2.85-2.68,4.2-6.07,4.58-9.88c0.37-3.73,2.09-6.46,5.93-7.03
            c3.74-0.55,6.3,1.55,8.21,4.6c3.54,5.65,7.12,11.29,10.69,16.93c1.62-0.31,3.24-0.58,4.86,0.01c6.45,7.71-1.26,7.37-5.21,9.18
            c-10.37,3.52-10.86-6.31-15.71-11.92c-1.74,3.32-1.07,7.61-4.37,9.65C-166,38.94-166.84,33.42-166.4,26.9z"
            />
            <path
              style="fill: #496828"
              d="M-141.66-39.32c6.16,3.97-0.35,7.77-0.15,11.66c-0.29,3.13,0.53,6.57-2.43,8.98
            c-4.3,3.2-8.77,6-14.48,5.63c-5.12-2.86-1.7-9.5-5.69-12.86c5.13-1.67,6.05-4.62,2.67-8.87c1.8-5.64,7.49-4.28,11.3-6.31
            C-147.74-39.4-144.8-38.85-141.66-39.32z"
            />
            <path
              style="fill: #5c7f2c"
              d="M-164.41-25.91c6.35,1.52,3.69,8.34,6.88,11.72c3.34,1.32,4.86,3.32,2.54,6.77
            c-1.82,2-4.18,2.47-6.73,2.39C-163.66-11.86-165.16-18.74-164.41-25.91z"
            />
            <path
              style="fill: #6f972d"
              d="M-177.97-46.16c3.2,1.24,4.1,4.04,4.74,7.01c-2.29,0.87-4.59,1.75-6.88,2.62
            C-183.14-40.57-181.36-43.55-177.97-46.16z"
            />
            <path
              style="fill: #2a331b"
              d="M-64.35,151.84c-3.12,3.16-5.42,9.25-11.09,2.89c-3.86-4.33-6.46-0.47-9.3,1.62
            c-0.36-2.77-1.64-5.02-4.06-6.38c-10.85-6.09-15.53-15.27-14.03-27.62c0.58-2.44,1.15-4.87,2.02-8.54
            c3.51,4.3,5.57,9.42,11.45,8.43c7.2,5.61,15.92,8.22,24,12.12C-61.78,140.01-53.04,145.35-64.35,151.84z"
            />
            <path
              style="fill: #364620"
              d="M-68.96,136.17c-8.53-2.11-17.02-4.26-20.4-13.93c3.88-5.21,0.84-9.37-2.06-13.54
            c-1.12-4.95,2.03-6.05,5.87-6.55c4.75,1.95,7.18,0.19,9.47-4.4c4.73-9.52,21.6-12.54,29.54-5.78c2.44,2.08,2.48,4.66,0.7,7.04
            c-1.74,2.34-4.93,3.71-4.53,7.35c3.28,9.56,10.7,18.22,6.31,29.4c-0.55,0.92-1.19,1.77-1.94,2.53
            c0.73,3.91,1.97,7.82,2.03,11.74c0.06,3.82,2.22,9.42-2.22,11.07c-4.65,1.73-5.78-4.59-8.74-7.03
            c-6.56-5.81-4.57-15.65-10.33-21.99c-1.35-1.49-2.17-3.79-3.56-5.26C-67,129.83-65.24,133.01-68.96,136.17z"
            />
            <path
              style="fill: #29341c"
              d="M-136.74,47.15c-0.03-1.37-0.06-2.73-0.09-4.1c0.89-0.94,1.89-1.73,3.01-2.39
            c6.91,0.39,14.32-1.04,19.52,5.46c1.52,3.47,1.45,7.02,0.5,10.61c-1.83,5.13-6.25,10.42,0.98,14.97
            c2.87,3.49,7.97,2.31,11.1,5.25c1.72,1.28,3.09,4.01,5.65,2.72c5.43-2.75,9.79-1.77,12,3.78c2.32,5.83,2.09,12.32-2.62,16.72
            c-2.81,2.63-4.98,5.39-6.95,8.52c-6.79-14.73-7.25-14.89-23.73-9.98c-2.11,0.63-4.38,0.68-6.59,1
            c-3.69-3.12-7.99-2.98-12.83-1.9c9.44-9.4,8.46-21.04,9.1-32.24c-4.81-2.74-7.21-7.32-9.3-12.14
            C-137.01,51.33-136.8,49.25-136.74,47.15z"
            />
            <path
              style="fill: #465a23"
              d="M-93.66,108.7c-0.39-3.95,0.55-7.21,3.58-10.18c4.35-4.26,4.63-10.41,1.39-14.52
            c-3.54-4.47-6.81,0.5-9.49,3.16c-1.31,1.29-2.48,1.45-3.84,0.34c-2.98-2.42-2.28-5.25-0.82-8.14c-1.56-4.64,1.71-6.02,5.01-6.74
            c6.82-1.47,7.62-4.87,5.48-11.18c-1.61-4.73-2.89-10.02-1.68-15.33c2.14-6.25,4.07-12.74,12.83-11.67
            c1.03,0.45,1.85,1.17,2.42,2.14c0.76,3.35,0.05,7.11,2.64,10c1.47,3.66-1.4,7.66,0.9,11.28c2.25,4.54,6.07,8.48,5.3,14.15
            c-0.03,1.08-0.14,2.15-0.33,3.22c-1.52,4.72-4.3,9.07-4.08,14.29c-1.72,5.91-1.01,13.47-9.83,14.57
            c-3.21,0.27-5.65,1.75-7.23,4.59c0,0-1.12,0.06-1.12,0.06L-93.66,108.7z"
            />
            <path
              style="fill: #a1aa60"
              d="M-136.74,47.15c1.92,1.08,2.18,2.79,1.82,4.75c1.72,5.25,5.54,10.12,2.84,16.15
            c-1.5,2.02-3.01,4.04-4.51,6.05C-141.44,65.14-148.84,56.2-136.74,47.15z"
            />
            <path
              style="fill: #90a146"
              d="M-132.08,68.05c-0.15-5.52-6.15-10.01-2.84-16.15c3.65,3.9,6.29,8.33,7.21,13.67
            C-129.17,66.4-130.62,67.22-132.08,68.05z"
            />
            <path
              style="fill: #758e30"
              d="M-134.35,42.94c-0.83,0.04-1.65,0.07-2.48,0.11c-0.17-0.78-0.33-1.56-0.5-2.35
            c-0.67-2.98,5.06-3.36,2.82-6.98c-1.98-3.05-1.81-5.74,1.33-7.93c6.89-0.33,9.1,5.33,12.34,9.66c0.79,5.67-0.84,8.81-7.3,7.21
            C-130.19,42.16-132.38,40.81-134.35,42.94z"
            />
            <path
              style="fill: #475e2b"
              d="M-68.96,136.17c-0.52-5.57-4.32-8.6-8.72-11.09c-2.4-1.36-4.96-2.65-3.31-6.01
            c1.5-3.06,4.31-2.79,6.9-1.85c11.95,4.32,13.81,15.8,17.64,25.68c1.32,3.41,1.06,7.43,1.52,11.17c0,0-0.39,0.3-0.39,0.3
            c-3.76,1.87,0.32,9.19-6.3,9.06c-0.91-3.86-1.82-7.72-2.74-11.58C-59.2,144.65-65.69,140.89-68.96,136.17z"
            />
            <path
              style="fill: #587252"
              d="M-61.61,163.43c3.93-1.75-1.42-9.96,6.3-9.07c2.43,9.78,1.31,20.41,7.07,29.38
            c-3.13,0.11-6.26,0.23-9.39,0.34c-2.93,1.91-5.69,1.67-8.28-0.66C-55.34,178.73-56.45,171.51-61.61,163.43z"
            />
            <path
              style="fill: #2d361c"
              d="M-65.91,183.42c2.76,0.22,5.52,0.44,8.28,0.66c1.74,4.41,1.48,7.9-4.11,8.93
            c0.34-5.1-3.72-6.87-6.98-9.26C-67.78,183.65-66.85,183.53-65.91,183.42z"
            />
            <path
              style="fill: #6a8a6d"
              d="M-48.33,183.68c5.03-0.08,4.3,6.79,8.82,7.33c0.78,0.41,1.02,0.8,0.74,1.16
            c-0.27,0.34-0.54,0.51-0.83,0.51c-0.68,0.68-1.35,1.37-2.03,2.04C-48.88,194.09-48.59,188.88-48.33,183.68z"
            />
            <path
              style="fill: #4e6626"
              d="M74.5,149.35c-1.62,2.19-2.97,4.98-0.55,6.87c6.47,5.05-1.37,4.56-2.34,6.68
            c0.01,3.57-2.22,5.03-5.32,5.65c-6.9,0.53-7.13-5.89-9.97-9.59c-1.36-4.57,2.58-9.28-0.34-13.81c0.64-0.08,1.26-0.03,1.88,0.17
            c4.02-2.06,8.1-1.98,12.22-0.31C72.82,145.15,75.78,145.08,74.5,149.35z"
            />
            <path
              style="fill: #60912d"
              d="M38.15,163.19c-0.86,0.83-1.71,1.66-2.57,2.49c-7.16-2.53-6.85-6.19-1.96-11.04
            c2.28-2.26,3.51-8.31,6.7-0.77c2.24,1.17,4.65,2.18,4.85,5.24C42.83,160.47,40.49,161.83,38.15,163.19z"
            />
            <path
              style="fill: #779d29"
              d="M56.31,158.95c4.53,0.28,5.12,5.89,9.19,6.79c2.69,2.33,5.16,4.73,2.5,8.62
            c-1.1,0.26-2.21,0.52-3.32,0.78c-2.08-0.17-4.16-0.34-6.24-0.52c-1.42-1.56-1.32-3.22-0.29-4.93
            C57.55,166.12,56.93,162.54,56.31,158.95z"
            />
            <path
              style="fill: #74934a"
              d="M58.16,169.7c0.1,1.64,0.19,3.29,0.29,4.93c-3.1,1.76-1.02,5.73-4.03,8.58
            C54.01,177.7,47.44,171.64,58.16,169.7z"
            />
            <path
              style="fill: #7fb22e"
              d="M45.17,159.11c-1.62-1.75-3.23-3.49-4.85-5.24c1.47-0.76,2.95-1.52,4.42-2.27
            c2.16,0.49,5.28,0.67,5.1,3.26C49.73,156.37,46.82,157.7,45.17,159.11z"
            />
            <path
              style="fill: #bace33"
              d="M68,174.37c-0.83-2.87-1.67-5.75-2.5-8.62c2.04-0.95,4.07-1.9,6.1-2.85
            c3.49,0.28,7.02,0.68,9.79-2.23c2.62-1.12,4.49-0.83,4.75,2.52c-0.19,0.67-0.31,1.35-0.38,2.04
            C78.77,166.18,75.65,174.69,68,174.37z"
            />
            <path
              style="fill: #879e9f"
              d="M-27.99,161.07c-0.7-2.29-1.41-4.58-2.11-6.87c-3.4-5.95-1.52-11.24,1.65-16.65
            c2.23-3.81,3.22-8.22,4.52-12.46c1.87-3.27,2.75-7.39,7.25-8.37c1.76-0.05,3.17,0.65,4.32,1.94c2.04,3.42,1.04,8.25,4.98,10.82
            c1.24,1.67,2.24,3.51,3.52,5.16c3.04,4.2,4.18,8.81,3.02,13.92c0.61,14.01-9.93,14.8-19.63,16.58c-1.04,0.26-2.09,0.3-3.15,0.12
            C-25.5,164.31-27.4,163.38-27.99,161.07z"
            />
            <path
              style="fill: #63807b"
              d="M-23.19,163.2c1.33-0.61,2.6-1.51,3.99-1.78c8.16-1.58,15.55-3.79,14.09-14.62
            c10.84,2.82,12.05,5.67,7.01,16.57c0.53,0.81,1.06,1.62,1.59,2.43c-2,8.6-9.17,10.17-16.14,10.96
            c-4.89,0.55-8.7,1.59-10.81,6.29c-3.25-6.97-6.58-13.92-4.53-21.99c1.53,0.78,3.07,1.55,4.6,2.33c0.55,0.43,1,0.98,1.64,1.29
            C-22.27,164.23-22.75,163.74-23.19,163.2z"
            />
            <path
              style="fill: #35442b"
              d="M-20.84,126.87c-2.24,6.34-2.41,13.54-6.59,19.01c-2.09,2.72-1.91,5.53-2.66,8.33
            c-3.88-4.15-9.71-6.99-9.31-14c-3.13-4.41,0.89-7.28,2.29-10.73c1.17-1.76,2.71-3.02,4.74-3.64c2.46-0.63,4.35-2.75,7.05-2.78
            C-22.92,123.27-21.75,124.92-20.84,126.87z"
            />
            <path
              style="fill: #5e7457"
              d="M-34.5,131.37c2.85,1.25,4.75,2.9,0.4,4.43c-2.5,0.88-4.69,1.52-5.31,4.4
            c-2.2-0.64-4.4-1.28-6.6-1.92c0.08-0.74,0.17-1.48,0.27-2.21c0.48-3.47-1.16-7.85,3.66-9.53
            C-37.71,125.04-36.26,128.56-34.5,131.37z"
            />
            <path
              style="fill: #4c6234"
              d="M-39.59,192.68c0.08-0.56,0.11-1.11,0.09-1.67c2.58-2.25,5.33-4.02,9.72-3.07
            C-31.45,193.51-35.72,192.7-39.59,192.68z"
            />
            <path
              style="fill: #2f411e"
              d="M35.72,122.25c-2.31,2.42-4.88,4.64-6.88,7.29c-4.41,5.86-8.42,9.11-12.82-0.02
            c-1.13-2.34-3.26-4.41-6.32-3.51c-2.84,0.83-3.83,2.89-3.7,6.03c0.07,1.51-1.7,3.11-2.64,4.66c-4.22,0.21-5.66-2.95-7.23-5.98
            c-1.97-6.15,2.88-9.13,6.01-12.91c5.13-3.02,6.12-12.99,15.33-9.16c4.25,2.42-1.28,16.98,11.27,9.1
            C32.2,117.48,34.29,119.35,35.72,122.25z"
            />
            <path
              style="fill: #425d36"
              d="M79.05,120.29c-2.71,1.1-5.71,1.78-8.09,3.38c-5.44,3.66-5.27-0.44-5.82-3.81
            c-3.09-3.27-8.87,0.12-11.38-4.6c-6.07-9.63-0.78-15.34,6.97-20.19c4.17-1.87,6.83,1.02,9.76,3.07
            c2.13,2.46,1.14,5.78,2.33,8.51c1.42,4.24,6.1,4.81,8.69,7.74C82.58,117.16,83.55,119.88,79.05,120.29z"
            />
            <path
              style="fill: #3f5825"
              d="M17.46,108.66c-6.77,1.99-7.51,9.2-11.63,13.49c-5.09-5.08-11.95,3.19-17.01-2.24
            c-2.1-5.84-2.76-11.71-0.37-17.65c1.27-1.54,3.08-2.78,4.88-2.31c7.7,2.03,6.65-3.97,7.7-8.09c2.27-3.01,5.52-3.36,8.92-3.29
            c3.66,1.35,6.48,3.57,7.63,7.5C17.55,100.27,17.51,104.46,17.46,108.66z"
            />
            <path
              style="fill: #496725"
              d="M12.45,75.22c-2.27,0.22-3.41,1.56-3.67,3.75c-1.79,2.06-3.95,1.49-6.11,0.88
            c-4.3-2.59-9.84-1.23-13.97-4.38c-5.5-3.39-16.15-2.57-11.62-14.21c2.85-2.63,6.21-2.91,9.8-2.13
            c5.81,2.8,11.48,0.33,17.23-0.35c3.94-0.47,7.99-1.72,11.94,0.2c1.56,0.8,2.74,1.98,3.59,3.52
            C21.64,69.22,18.9,73.28,12.45,75.22z"
            />
            <path
              style="fill: #6c9e2e"
              d="M-0.7,129.37c1.35,2.44,2.7,4.89,4.05,7.33c-0.06,0.51-0.04,1.03,0.04,1.53
            c1.08,3.92,4.16,6,7.49,7.58c2.84,1.35,3.99,3.07,2.6,6.06c-0.84,6.9-3.64,11.81-11.61,11.49c-1.33-5.94-0.02-13.01-7-16.56
            c1.97-4.14-1.11-7.31-2.09-10.88C-8.76,130.05-4.38,130.05-0.7,129.37z"
            />
            <path
              style="fill: #2f411e"
              d="M13.5,151.89c-0.77-3.36-2.88-3.96-6.21-4.64c-4.02-0.82-10.06-2.84-3.88-9.01
            c6.38,1.02,12.06,8.87,19.84,1.65c0.65-0.6,2.34,8.87,2.15,14.22C20.69,154.68,18.71,144.02,13.5,151.89z"
            />
            <path
              style="fill: #395228"
              d="M69.3,99.7c-3.78,0.17-6.12-2.33-8.57-4.63c0.74-3.09,1.47-6.19,2.21-9.28
            c-0.42-1.04-0.41-2.09,0.02-3.13c4.66-4.04,6.05-12.66,15-10.73c2.36,0.72,4.14,2.17,5.35,4.32c0.99,2.23,1.04,4.49,0.29,6.79
            c-1.85,4.54-6.99,6.61-8.55,11.4C73.67,96.79,72.44,99.29,69.3,99.7z"
            />
            <path
              style="fill: #27361c"
              d="M44.7,92.88c-2.47-4.37-3.35-10.01-9.28-11.61c-0.52-0.98-0.59-1.97-0.14-2.99
            c4.77-2.4,7.69-6.8,11.27-10.5c1.55-0.78,3.21-0.9,4.91-0.86c2.37,0.1,4.46,0.83,6.09,2.64c2.82,4.54,4.02,9.15,0.43,13.9
            c-2.26,2.02-5.05,3.49-6.59,6.29C49.54,91.6,46.57,91.07,44.7,92.88z"
            />
            <path
              style="fill: #96b238"
              d="M53.76,115.26c4.71-0.75,11.4-6.4,11.38,4.6c-3.32,4.48-8.15,6.07-13.32,7.04
            C50.37,122.67,46.7,118.07,53.76,115.26z"
            />
            <path
              style="fill: #677d53"
              d="M35.72,122.25c-3.14-0.22-5.36-1.9-6.99-4.48c0.85-1.65,1.46-3.49,2.6-4.92
            c2.03-2.54,4.72-3.26,7.92-2.16c2.91,1,4.27,3.64,4.43,5.95C44.09,122.24,37.67,119.28,35.72,122.25z"
            />
            <path
              style="fill: #5b823a"
              d="M12.45,75.22c-1.46-5.32,6.72-6.45,5.14-11.82c1.18-1.45,2.72-1.81,4.49-1.54
            c2.72,1.04,4.56,2.96,5.58,5.68c0.28,3.33-2.9,5.33-3.17,8.48c-1.18,3.7-3.35,5.42-7.25,3.61
            C15.64,78.15,14.05,76.69,12.45,75.22z"
            />
            <path
              style="fill: #6c957f"
              d="M22.26,72.48c-0.47-3.44,4.1-2.02,4.46-4.65c4.37-3.55,4.26-3.42,8.4,0.07
            c4.22,3.56,6.95,6.96,0.92,11.23c-0.25,0.7-0.46,1.42-0.63,2.15c-0.78,0.29-1.56,0.59-2.34,0.88
            C24.94,83.99,25.98,75.58,22.26,72.48z"
            />
            <path
              style="fill: #628f75"
              d="M4.12,76.94c1.55,0.68,3.11,1.36,4.66,2.04c2.27,6.04,9.97,9.27,8.82,17.09
            c-3.32-0.51-6.75-0.88-7.19-5.31C3.96,88.13-2.19,85.38,4.12,76.94z"
            />
            <path
              style="fill: #496725"
              d="M22.26,72.48c6.06,0.48,7.42,6.22,10.82,9.67c-0.7,1.12-1.39,2.24-2.09,3.35
            c-3.63,3.24-6.02-2.65-9.4-1.28c-1.45-1.54-2.9-3.08-4.35-4.61C20.79,78.56,22.03,75.88,22.26,72.48z"
            />
            <path
              style="fill: #b4bdc6"
              d="M70.07,145c-4.04,1.24-8.03,4.64-12.22,0.31c-0.32-1.57-0.63-3.14-0.95-4.71
            C64.74,131.74,66.86,139.98,70.07,145z"
            />
            <path
              style="fill: #98adc8"
              d="M49.14,88.06c-0.14-4.81,0.96-8.38,7-6.97c2.06,0.13,4.23-0.28,5.99,1.27
            c0.3,0.43,0.59,0.87,0.89,1.3c0,0.71-0.02,1.42-0.07,2.12c-1.6,1.46-3.21,2.93-4.81,4.39C54.56,91.92,50.83,94.33,49.14,88.06z"
            />
            <path
              style="fill: #4a6c5e"
              d="M21.59,84.23c3.74-4,6.35,0.26,9.4,1.29c1.7,2.36,4.25,5.12,0.84,7.49
            c-4.21,2.93-4.27-1.91-5.85-3.89C24.62,87.4,23.06,85.85,21.59,84.23z"
            />
            <path
              style="fill: #395228"
              d="M49.14,88.06c2.32,3.6,5.57,3.24,9,2.12c-3.76,4.5-8.29,5.15-13.44,2.7
            C44.98,90.17,46.45,88.55,49.14,88.06z"
            />
            <path
              style="fill: #455c27"
              d="M-50.63-69.37c1.49,1.74,2.97,3.49,4.46,5.23c4.12,5.49,5.51,12.58,11.75,17.05
            c3.73,2.67,4.74,8.21,3.3,13.32c-2.53,2.52-4.38,5.03-5.1,9.01c-1.16,6.44-5.11,10.6-12.65,7.63
            c-2.87-4.25-8.75-6.12-9.56-12.02c-1.67-5.73-2.4-11.22-0.83-17.4c1.92-7.55,0.4-14.79-8.06-18.35
            c-2.34-1.16-4.8-1.78-7.44-1.64c-7.38-0.41-14.49,7.83-22.05,0.66c-0.73-0.89-1.24-1.9-1.53-3.02c0-1.24,0-2.48,0.01-3.71
            c1.59-4.94,4.67-5.84,9-3.3c2.39-0.88,4.61-1.91,7.42-1.28c10.39,2.3,12.82,0.28,11.13-10.11c-0.61-3.77-0.27-7.28-0.11-10.91
            c3.03,3.2,5.38,7.54,10.9,6.66c0.59,2.46,1.97,4.09,4.6,4.42c2.7,3.34-2.82,4.4-1.99,7.23c-0.73,1.52-1.46,3.04-2.19,4.57
            C-57.21-72.42-52.43-73.14-50.63-69.37z"
            />
            <path
              style="fill: #98b542"
              d="M-89.33-75.9c-3,1.1-6,2.2-9.01,3.3c-1.61-2.21-2.35-4.62-1.63-7.35
            c1.01-3.5,2.03-7.01,3.69-12.76C-94.07-85.63-89.7-81.78-89.33-75.9z"
            />
            <path
              style="fill: #7caa29"
              d="M-98.34-68.88c0.69,0.3,1.39,0.56,2.11,0.78c4.09,5.75,0.95,8.58-4.26,10.57
            c-4.37-6.1-6.54-2.81-8.37,1.96C-112.86-65.95-101.42-64.11-98.34-68.88z"
            />
            <path
              style="fill: #223019"
              d="M-57.39-79.9c-2.98-1.08-5.54,6.03-7.98,1.13c-1.57-3.16,3.04-4.13,5.41-5.55
            c1.53-0.92,3.05-1.87,4.57-2.8c1.97-5.13,6.23-0.95,9.15-2.25c2.7,3.15,3.89,7.34,1.62,10.38c-3.2,4.3-5.39-2.21-8.48-1.98
            C-54.55-80.87-55.96-80.27-57.39-79.9z"
            />
            <path
              style="fill: #6f9a31"
              d="M-100.49-57.53c1.42-3.53,2.84-7.05,4.26-10.57c8.43,5.5,15.13-4.75,23.2-2.57
            c1.21,1.3,2.23,2.7,2.64,4.46c0.25,7.79-3.95,7.97-9.51,5.64c-0.4-0.01-0.65-0.02-0.65-0.02c-0.94-0.01-1.02-0.02-0.1-0.02
            c4.52,2.83,8.68,0.34,12.94-0.53c1.12,0.33,2.07,0.9,2.87,1.76c3.27,5.83,1.2,10.42-3.3,14.46c-1.74,1.53-3.45,3.14-5.81,3.71
            c-2.19,0.47-4.32-0.48-6.5-0.31c-12.6,3.71-14.07,2.58-17.56-13.49C-98.85-55.85-99.67-56.69-100.49-57.53z"
            />
            <path
              style="fill: #54762a"
              d="M-98.03-55.01c5.58,9.03,5.58,9.03,18.44,9.62c1.72,5.47,0.08,9.68-4.69,12.73
            c-3.64,2.07-3.66,6.82-6.79,9.3c-2.58,1.37-5.15,2.45-7.52-0.34C-98.4-34.14-98.22-44.58-98.03-55.01z"
            />
            <path
              style="fill: #688630"
              d="M-111.17-27.85c5.3-0.93,6.02,3.68,8.07,6.68c2.84,0.83,5.35,2.03,6.05,5.32
            c-0.01,5.06-4.09,6.74-7.44,9.04c-4.07,0.56-7.94,2.52-12.21,1.83c-5.22-2.13-4.47-6.34-3.67-10.56
            c0.09-1.1,0.25-2.21,0.47-3.29c0.29-1.56,0.71-3.07,1.73-4.33C-116-24.95-114.15-27.24-111.17-27.85z"
            />
            <path
              style="fill: #86b329"
              d="M-98.12-16.06c-2.71-0.67-4.23-2.51-4.98-5.1c1.5-0.85,3-1.7,4.51-2.55
            c2.28-0.66,4.56-1.32,6.84-1.98c4.27-2.26,7.5-1.97,8.63,3.51c-1.32,6.45-5.83,8.27-11.72,8.08
            C-96.14-14.4-97.23-15.06-98.12-16.06z"
            />
            <path
              style="fill: #909560"
              d="M-121.43-41.31c0.58-1.83,0.53-4,2.69-4.98c4.84,2.47,8.42,5.65,6.75,11.84
            c-2.81,2.51-5.79,3.19-9.03,0.8C-122.66-36.12-122.21-38.71-121.43-41.31z"
            />
            <path
              style="fill: #aeb47e"
              d="M-120.63-34.33c2.88-0.04,5.77-0.08,8.65-0.12c0.27,2.2,0.54,4.4,0.81,6.6
            c0.66,2.72,0.31,5.24-1.91,7.07c-0.17,0.14-2.02-1.77-3.08-2.73C-120.99-25.73-123.33-28.99-120.63-34.33z"
            />
            <path
              style="fill: #475e28"
              d="M-116.78-15.93c0.04,2.99-2.32,6.13,0.57,8.93c3.35,1.55,6.12,3.63,6.83,7.58
            c-1.07,6.47-1.24,13.28-6.44,18.32c-11.26,0.81-12.7-0.42-13.51-11.44c0.72-5.56,1.34-11.13-1.68-16.35
            c-0.04-1.09,0.27-2.06,0.97-2.89c0.61-0.08,1.23-0.09,1.84-0.03C-124.69-14.03-122.94-21.12-116.78-15.93z"
            />
            <path
              style="fill: #375024"
              d="M-130.04-11.78c0.06,0.67,0.1,1.35,0.14,2.03c-2.63,7.41-7.57,8.17-13.92,4.83
            c-1.84-0.91-3.68-2.07-5.53,0c-3.86-2.32-3.25-5.52-0.82-8.08c2.1-2.21,3.33-4.55,3.95-7.39c1.47-2.42,2.94-4.84,4.41-7.26
            C-131.22-27.32-135.75-15.74-130.04-11.78z"
            />
            <path
              style="fill: #475e28"
              d="M-120.63-34.33c1.87,3.45,5.17,6.31,4.47,10.83c-0.21,1.46-0.42,2.92-0.64,4.37
            c-6.01,1.9-9.37-0.82-11.09-6.38c-5.49-8.4,5.7-9.96,6.46-15.8C-121.16-38.98-120.9-36.66-120.63-34.33z"
            />
            <path
              style="fill: #587b2b"
              d="M-127.89-25.51c3.7,2.13,7.4,4.26,11.09,6.39c0.01,1.06,0.02,2.12,0.02,3.19
            c-3.81,1.38-7.61,2.75-11.42,4.12C-126.65-16.35-130.85-21.01-127.89-25.51z"
            />
            <path
              style="fill: #354c22"
              d="M81.44,77.05c-1.52-0.8-3.04-1.59-4.56-2.39c-1.41-1.23-2.23-2.75-2.29-4.65
            c0.65-7.01,6.65-7.66,11.49-9.7c2.81-1.25,5.58-1.97,8.04,0.73c3.86,8.79,15.18,4,20.28,11.23c1.6,2.27,4.14,4.07,5.29,6.87
            c-4.31,6.27-12.51,10.87-8.84,20.58c-2.18,3.92-9.43,2.55-9.26,8.91c-0.63,0.35-1.31,0.53-2.03,0.55
            c-5.84,4.42-11.74,6.9-17.93,0.56c-0.99-1.4-1.48-2.97-1.45-4.68c0.35-7.3,1.45-14.45,4.26-21.27
            C84.65,81.01,83.37,78.88,81.44,77.05z"
            />
            <path
              style="fill: #7fa131"
              d="M90.29,124.72c-1.4-0.84-2.8-1.68-4.2-2.53c-2.57-1.78-2.63-4.21-1.77-6.88
            c6.88-6.5,11.34-2.71,15.21,3.59c-0.1,3.51-0.2,7.01-0.31,10.52C94.58,131.01,92.8,127.16,90.29,124.72z"
            />
            <path
              style="fill: #58792e"
              d="M99.53,118.9c-4.58-0.6-7.55-8.05-13.42-3.15c-4.37-1-8.3-2.16-2.63-6.96
            c5.28,3.39,10.79-2.58,16.08,0.39C99.55,112.42,99.54,115.66,99.53,118.9z"
            />
            <path
              style="fill: #3b5522"
              d="M90.29,124.72c4.03-0.43,5.98,3.09,8.93,4.7c-0.61,6.38-6.07,2.75-8.99,4.36
            C90.25,130.75,90.27,127.73,90.29,124.72z"
            />
            <path
              style="fill: #374920"
              d="M83.48,108.79c-2.22,3.49,1.04,4.91,2.63,6.96c-0.01,2.15-0.02,4.3-0.02,6.45
            c-1.54,4.11,1.46,11.35-7.24,10.52c0.07-4.15,0.14-8.29,0.2-12.42c1.41-1.67,1.5-3.29-0.23-4.81c-4-2.15-5.99-5.32-5.03-9.98
            c0.36-1.15,0.97-2.15,1.82-3c2.81-1.94,5.42-2.27,7.6,0.93C83.31,105.21,83.39,107,83.48,108.79z"
            />
            <path
              style="fill: #405a25"
              d="M86.14,163.19c-0.98-1.98-2.9-2.19-4.75-2.52c1.63-1.71,1.58-5.62,5.08-4.75
            c2.58,0.64,4.35,2.88,3.85,5.84C89.89,164.42,87.92,163.8,86.14,163.19z"
            />
            <path
              style="fill: #446b2a"
              d="M110.85,99.72c-3.54-3.31-11.2-5.44-6.73-11.82c3.3-4.71,6.37-13.21,15.57-8.76
            c0.04,0.8,0.07,1.6,0.11,2.4c1.61,1.12,3.57,2.06,2.24,4.6c-6.34,2.64-6.46,7.9-5.69,13.53
            C114.52,99.69,112.69,99.7,110.85,99.72z"
            />
            <path
              style="fill: #3e6127"
              d="M122.04,86.14c-0.75-1.54-1.5-3.07-2.24-4.61c1.42-0.9,2.84-1.81,4.26-2.71
            c-0.27-4.69-1.25-9.53,2.69-13.37c2.24-0.94,4.52-2.45,6.48,0.46c-0.72,1.37-1.43,2.73-2.15,4.09c-0.7,4.54-1.4,9.09-2.1,13.63
            c-0.85,1.63-1.7,3.26-2.55,4.89C124.97,87.73,123.51,86.94,122.04,86.14z"
            />
            <path
              style="fill: #afb87e"
              d="M144.46-89.28c0.33-4.55-3.21-8.58-1.74-13.28c7.04,2.08,5.15,8.6,6.66,13.44
            C147.74-89.17,146.1-89.22,144.46-89.28z"
            />
            <path
              style="fill: #6b7b5c"
              d="M138.06-64.05c-0.73-2.65-2.84-2.89-5.08-2.88c-0.16-4.07,6.4-4.08,5.28-8.73
            c1.44,0.73,2.89,1.46,4.33,2.19C140.93-70.4,142.27-65.88,138.06-64.05z"
            />
            <path
              style="fill: #8fa59f"
              d="M149.13-33.57c-3.55-4.1-5.59,6.56-8.63,1.12c-2.21-3.96-1.46-8.03,4.3-8.91
            c1.46,1.28,2.93,2.57,4.39,3.85C149.17-36.2,149.15-34.88,149.13-33.57z"
            />
            <path
              style="fill: #314021"
              d="M155.97-24.69c1.37,2.11,1.09,4.24,0.08,6.38c-6.95,1.97-9.54-0.1-6.68-7.22
            c0,0-0.12,0.17-0.12,0.17C151.49-25.13,153.73-24.91,155.97-24.69z"
            />
            <path
              style="fill: #85a28a"
              d="M149.37-25.52c-0.09,4.55,1.15,7.88,6.68,7.22c-0.04,0.68-0.1,1.36-0.17,2.04
            c-3.46,0.04-4.48,3.59-7.06,4.89C142.22-16.36,144.66-20.98,149.37-25.52z"
            />
            <path
              style="fill: #25311b"
              d="M97.2-52.75c1.47,2.3,2.94,4.6,4.41,6.91c5.23,4.03,12.61,5.93,13.84,13.94
            c2.79,4.61,6.11,8.86,8.86,13.5c-2.17,9.51-11.2,5.18-17.95,9.91c2.64-10.07,4.22-18.51-2.06-25.8
            c-8.35,2.95,1.05,13.01-7.02,15.66c-1.41-0.78-3.38,1.2-4.52,4.53c-6.48,2.53-12.87,5.35-19.48,7.5
            c-6.04,1.96-11.66,1.37-14.82-5.35c1.91-4.67,7.44-4.17,10.39-7.34c0.64-0.7,1.39-1.27,2.2-1.75c1.86-1.06,4.13-0.61,5.92-1.55
            c-0.11,0.03,0.16-0.57,0.16-0.57s-0.4-0.52-0.51-0.47c-1.53-0.39-2.96-0.81-4.05-1.95c-1.4-1.59-1.48-3.65-2.06-5.53
            c-4.53-11.67-3.96-13.11,8.6-21.51c1.27-0.75,2.55-1.5,3.82-2.25c3.68-0.26,2.3-5.32,5.64-5.93
            C94.19-61.04,96.82-58.09,97.2-52.75z"
            />
            <path
              style="fill: #2a3a1d"
              d="M133.43,20.09c-2.07,4.31-8.28,5.88-3.62,14.09c3.35,5.89-8.25,8.66-13.01,9.74
            c-5.12,1.17-3.9-0.19-2.21,4.23c2.25,5.87-5.1,7.16-8.4,10.28c-4.62,4.38-6.26-1.21-9-3.07c-0.54-0.89-0.67-1.83-0.41-2.84
            c1.15-2.24,1.52-4.7,1.98-7.14c2.01-4.28,1.59-9.31,4.09-13.43c0.41-0.95,0.98-1.81,1.64-2.6c2.55-2.84,8.95-2.46,7.36-8.8
            c-0.19-3.24,0.2-6.5-0.33-9.74c0.23-0.96,0.75-1.72,1.52-2.33c1.41-3.09,2.32-6.56,4.34-9.18c2.78-3.61,6.39-6.26,9.23,0.31
            c-0.09,4.68-0.46,9.81,6.82,6.22c2.73-1.34,4.89-0.1,7.25,0.72c-0.85,1.48-1.71,2.96-2.56,4.44
            C138.2,14.88,133.63,16.36,133.43,20.09z"
            />
            <path
              style="fill: #587333"
              d="M58.29,8.56c3.22-3.11,1.01-8.05,4.04-11.88c5.39,2.99,2.38,12.27,9.8,13.29
            c2.19,2.37,4.43,4.67,7.38,6.14c2.21,2.31,2.67,5.08,2.17,8.11c-1.87,3.54-4.34,6.81-4.94,10.94c-1.34,2.09-3.28,3.22-5.72,3.5
            C58.01,36.57,58.2,25.87,56.54,16.37C55.56,13.42,56.81,10.96,58.29,8.56z"
            />
            <path
              style="fill: #5b802e"
              d="M105.96,31.44c-0.04,0.89-0.08,1.77-0.12,2.66c-3.48,1.92-5.94,6.15-10.87,4.74
            c-2.97-0.89-5.37-2.51-6.84-5.32c-1.33-4.27-1.44-8.73-2.03-13.13c1.1-5.78,7.19-8.59,8.5-14.27c1.65-0.53,3.3-1.07,4.95-1.6
            c5.76-0.83,7.71,3.45,9.9,7.4c1.39,3.51,3.6,6.82,2.67,10.91C111.2,26.51,105.89,27.04,105.96,31.44z"
            />
            <path
              style="fill: #68806b"
              d="M42.75-8.93c-0.91,2-7.81,2.41-1.67,6.29c0.09,1.83-0.9,2.93-2.46,3.63
            c-3.55,0.8-6.95-2.08-10.54-0.44c-1.02,0.12-2.03,0.24-3.04,0.36c-3.54,0.35-7.05,2.55-10.65,0.22c-1.85-1.3-2.83-3.1-3.07-5.32
            c0.08-4.72,5.17-7.37,5.03-12.2c0.45-3.47-0.46-7.4,3.23-9.8c9.92,0.84,13.56,11.3,22.01,14.43
            C42.22-10.92,42.61-9.98,42.75-8.93z"
            />
            <path
              style="fill: #80a830"
              d="M94.59,6.11c8.31,9.01-1.85,10.22-5.9,14.01c-2.31,0.5-4.39-0.09-6.34-1.33
            c-1.5-1-3.14-1.78-4.59-2.87c-1.2-1.42-2.51-2.83-0.46-4.58C82.46,7.6,87.54,3.58,94.59,6.11z"
            />
            <path
              style="fill: #324921"
              d="M38.18-0.57c0.96-0.69,1.93-1.38,2.9-2.07c3.23,0.99,3.7,3.63,3.84,6.46
            c-0.22,2.51-1.03,4.83-2.28,7.01c-1.76,3.32-3.44,6.73-7.62,7.63c-1.77,0.24-3.49,0.12-5.18-0.51c-2.39-1.18-3.73-3.11-4.2-5.69
            c-1.07-4.27-2.29-8.58,2.08-11.89C31.03-1.84,34.71-0.1,38.18-0.57z"
            />
            <path
              style="fill: #435329"
              d="M71.97-16.58c-3.45,4.61-8.44,4.71-13.5,4.63c-0.8-2.11-1.59-4.22-2.39-6.34
            c-0.28-7,5.2-10.57,9-15.15c1.49-0.72,3.03-0.81,4.61-0.3C76.76-28.87,68.81-21.99,71.97-16.58z"
            />
            <path
              style="fill: #58753b"
              d="M64.79-32.09c3.94,8.93-5.91,9.14-8.71,13.8c-1.45-0.84-2.9-1.67-4.35-2.51
            c-1.87-2.4-3.29-4.92-2.34-8.15c2.57-5.32,6.07-9.09,12.72-7.44C63.82-35.47,64.79-34.08,64.79-32.09z"
            />
            <path
              style="fill: #455f2a"
              d="M40.3,7.77c1.54-1.31,3.08-2.63,4.62-3.94c1.81,9.06,8.38,4.67,13.37,4.74
            c1.19,2.39,1.09,4.86,0.4,7.36c-1.05,5.94-6.17,4.83-10.08,5.87c-1.16-0.14-2.21-0.56-3.17-1.21
            C42.49,16.8,38.57,13.42,40.3,7.77z"
            />
            <path
              style="fill: #4d6444"
              d="M106.23,12.18c-2.23-2.56-4.46-5.11-6.69-7.67c0.38-7.53,3.43-8.88,8.7-3.44
            c2.01,2.07,3.22,4.92,4.8,7.41c0.05,0.65,0.08,1.3,0.1,1.95C111.41,13.28,109.94,17.21,106.23,12.18z"
            />
            <path
              style="fill: #5e764c"
              d="M51.64-27.32c0.27,2.17,1.53,4.33,0.09,6.52c-2.29-0.01-4.57-0.03-6.86-0.05
            c-1.1,0.77-2.17,0.74-3.22-0.1c-3.21-5.18-3.65-10.3,0.29-15.36c2.71-2.18,5.68-2.89,8.99-1.51
            C54.66-34.55,53.24-30.94,51.64-27.32z"
            />
            <path
              style="fill: #819d8b"
              d="M42.76-20.97c0.7,0.09,1.41,0.13,2.11,0.13c1.2,1.89,2.9,3.64,3.49,5.71
            c0.71,2.54,6.09,3.62,2.62,7.3c-2.97,3.14-5.36-1.49-8.22-1.09c-0.2-0.61-0.42-1.2-0.65-1.8c-3.27-0.1-5.78-1.29-5.79-4.98
            C36.3-19.9,38.81-21.41,42.76-20.97z"
            />
            <path
              style="fill: #4a6545"
              d="M92.77-14.1c0.34-7.55-1.68-15.29,1.63-23.89c3.23,7.02,1.89,13.36,2.9,19.36
            C96.96-15.95,95.37-14.52,92.77-14.1z"
            />
            <path
              style="fill: #5a812e"
              d="M124.32-18.4c-7.66-1.4-7.91-7.68-8.86-13.5C123.11-30.49,124.94-25.25,124.32-18.4z"
            />
            <path
              style="fill: #9dbe2c"
              d="M117.29-57.08c0.42-0.63,0.72-1.32,0.9-2.06c4.62,0.23,7.8,2.28,8.93,6.98
            C121.23-48.58,118.13-50.56,117.29-57.08z"
            />
            <path
              style="fill: #83a76c"
              d="M77.3,11.35c0.5,1.54,1,3.08,1.5,4.62c-0.36,0.95-1.03,1.57-1.98,1.92
            c-7.3,0.76-8.92-1.86-4.7-7.93C74.31,8.76,75.93,9.6,77.3,11.35z"
            />
            <path
              style="fill: #7c9c36"
              d="M133.43,20.09c-4.5-6.16-0.39-7.87,4.69-9.09c1.43-0.01,2.86-0.03,4.28-0.04
            c-0.34,3.57-1.81,6.59-4.39,9.07C136.48,20.05,134.95,20.07,133.43,20.09z"
            />
            <path
              style="fill: #779d2d"
              d="M-32.35-34.52c-0.4-1.04-0.9-2.05-1.18-3.12c-2.33-9.05-5.85-13.07-13.65-1.81
            c3.87-9.08,2.1-16.88,1-24.67c0.9,0.53,1.8,1.07,2.69,1.6c4.19,3.72,11.12,1.39,14.65,6.6c2.1,3.74,1.36,8.74,5.2,11.74
            c0.73,0.77,1.26,1.64,1.62,2.64c0.32,1.66-0.05,3.18-0.94,4.59c-1.85,1.96-3.55,4.17-6.67,4.01
            C-30.71-33.17-31.61-33.7-32.35-34.52z"
            />
            <path
              style="fill: #628a2c"
              d="M-30.57-54.86c-5-1.38-11.23-0.68-12.91-7.67c2.33-2.02,0.27-4.11,0.1-6.17
            c1.56-1.22,3.04-1.09,4.47,0.22c2.98,5.74,7.45,7.26,13.35,4.73c1.77,2.62,1.19,5.06-0.55,7.42
            C-27.38-55.18-28.89-54.76-30.57-54.86z"
            />
            <path
              style="fill: #223019"
              d="M-50.63-69.37c-3.74-0.86-9.67,1.58-8.94-5.97C-56.14-74.03-48.45-79.11-50.63-69.37z"
            />
            <path
              style="fill: #70992f"
              d="M-38.91-68.47c-1.49-0.07-2.98-0.15-4.48-0.22c-1.79-7.53,4.68-6.97,8.65-8.87
            C-36.13-74.54-37.52-71.5-38.91-68.47z"
            />
            <path
              style="fill: #819938"
              d="M-27.75-57.65c0.73-2.03,1.46-4.06,2.19-6.09c2.65-1.63,0.39-8.77,6.85-6.14
            c0.99,3.37,5.04,4.54,5.81,8.08c0.1,1.19-0.17,2.29-0.78,3.31c-3.12,3.69-7.52,2.07-11.37,2.74
            C-26.23-55.97-27.14-56.61-27.75-57.65z"
            />
            <path
              style="fill: #b4c658"
              d="M-14.68-62.06c-4.11-1.18-3.92-4.58-4.03-7.82c3.85,2.06,4.26-2.41,6.49-3.42
            c1.58,0.82,3.17,1.64,4.75,2.46C-5.65-64.45-8.92-62.23-14.68-62.06z"
            />
            <path
              style="fill: #38461e"
              d="M-14.68-62.06c1.88-3.36,6.45-4.5,7.21-8.79c10.13,2.04,9.07-5.14,9.28-11.28
            c0.69-0.13,1.36-0.04,1.99,0.27c3.65,1.48,3.73,5.42,5.6,8.13c1.34,2.89,4.9,3.1,6.68,5.47c4.61,6.77,13.02,7.66,19.01,12.32
            c0.59,0.96,0.95,2,1.13,3.11c0.09,1.73-0.37,3.29-1.39,4.67c-0.74,0.74-1.65,1.24-2.67,1.5c-2.91-0.01-6.51-2.08-7.9,2.63
            c-0.27,0.97-0.56,1.9-0.81,2.87c-0.42,2.7-1.11,5.24-3.43,6.98C16.6-32.7,13.97-29.3,9.7-29.8c-3.04-0.26-5.61-2.09-8.59-2.59
            c-2.57-0.12-5.2,0.8-7.68-0.5c-2.99-1.82-4.81-4.33-4.63-7.98c-0.7-1.16,2.91-2.74-0.46-3.64c-0.94-0.05-1.74-0.29-2.54-0.7
            c-4.65-4.71-0.97-9.71-0.59-14.59C-14.63-60.55-14.6-61.3-14.68-62.06z"
            />
            <path
              style="fill: #567029"
              d="M5.99-70.87C3.08-74.1,4.14-78.12,3.8-81.86c2.32-1.66,4.64-3.33,6.96-4.99
            c2.98,1.48,5.96,2.97,8.93,4.45c-2.38,2.71-5.66,4.72-6.51,8.6C11.4-71.33,8.83-70.77,5.99-70.87z"
            />
            <path
              style="fill: #799c34"
              d="M10.53-75.52c1.07-4.94,4.42-6.84,9.17-6.88c1.48,1.4,3.05,0.9,4.63,0.23
            c0.07,1.42,0.15,2.83,0.22,4.25c-2.46,0.29-5.1-0.01-7.01,2.11C15.29-73.37,12.95-73.44,10.53-75.52z"
            />
            <path
              style="fill: #7bad28"
              d="M126.75,65.45c-0.63,4.51,0.78,9.44-2.69,13.37c-2.47-2.27-3.84-5.08-4.12-8.42
            C122.4,69.01,122.98,65.04,126.75,65.45z"
            />
            <path
              style="fill: #394b2a"
              d="M97.13,55.37c0,0,0.07,0,0.07,0c-1.02,1.89-2.04,3.77-3.07,5.67c-2.05,0.73-4.1,1.46-6.15,2.19
            c-2.88,0.03-5.55-0.39-7.06-3.31c0.39-5.49-3.93-10.19-2.73-15.84c0.5-2.31,1.74-4.07,3.86-5.17c4.77-1.74,9.55-0.29,14.34,0.01
            c1.04,0.44,1.88,1.13,2.54,2.04C100.97,46.1,98.42,50.65,97.13,55.37z"
            />
            <path
              style="fill: #44582c"
              d="M64.79-32.09c-1.08-1.11-2.17-2.22-3.25-3.33c-1.5-1.53-3.33-2.83-3.75-5.14
            c-0.2-1.03-0.31-2.08-0.36-3.13c0.1-4.01,2.68-7.56,2.14-11.7c0.68-5.24-2.03-7.85-6.85-8.84c-0.82-0.65-1.39-1.46-1.69-2.47
            c1.04-2.05,2.09-4.1,3.13-6.15c1.44-0.86,2.89-1.73,4.33-2.59c4.07,0.36,6.68-2.22,9.37-4.64c3.69,4.79,0.37,11.32,3.99,16.14
            c3.75,0.58,4.4,3.5,4.92,6.52c0.79,1.6,1.57,3.21,2.36,4.81c-0.13,1.85,0.3,4.99-0.49,5.34C70.9-43.9,73.54-37.67,74-32.01
            c-1.39,1.1-2.76,1.13-4.09-0.11C68.21-32.11,66.5-32.1,64.79-32.09z"
            />
            <path
              style="fill: #87a093"
              d="M60.46-40.43c0.37,1.67,0.74,3.34,1.09,5.01c-4.32,1.45-6.74,5.24-9.91,8.1
            c-0.65-3.15,0.93-6.84-2.25-9.39c-5.26-2.32-5.66-8.37-9.25-12c-0.54-3.22-0.71-6.47-0.45-9.74c0.52-1.67,1.45-2.98,3.15-3.62
            c2.22-1.4,4.44-2.8,6.67-4.2c4.49,3.73,2.91,10.32,6.75,14.35C57.55-48.04,57.38-43.64,60.46-40.43z"
            />
            <path
              style="fill: #9aa67c"
              d="M42.84-62.06c-0.05,1.5-0.09,3-0.14,4.49c-2.47,2.94-3.71,8.14-9.41,5.16
            c-0.11-0.78-0.23-1.55-0.34-2.33c-1.42-2.41-3.39-4.48-4.38-7.16c-0.35-2.47,0.32-4.53,2.45-5.98c3.18-1.63,6.35-3.27,9.53-4.9
            C38.09-68.51,44.45-66.14,42.84-62.06z"
            />
            <path
              style="fill: #36441d"
              d="M53.67-50.22c-7.29-3.81-3.88-10.4-4.17-16.03c0.47-0.28,0.97-0.43,1.52-0.44
            c0.95,0.12,1.9,0.24,2.84,0.36c2.16,1.45,4.59,2.68,4.84,5.74c0.05,2.89,1.23,5.76,0.29,8.7C57.6-50.12,56.02-48.93,53.67-50.22
            z"
            />
            <path
              style="fill: #b2c276"
              d="M31.01-67.87c0.13,2.14,0.25,4.29,0.38,6.43c-7.67,4.48-12.27-3.06-18.38-4.69
            C9.52-66,7.48-68.02,5.99-70.87c1.51-1.55,3.02-3.1,4.53-4.65c2.33-0.1,4.67-0.19,7-0.29C23.04-74.9,27.12-71.54,31.01-67.87z"
            />
            <path
              style="fill: #7b9454"
              d="M87.93-59.64c0.04,2.22,0.3,4.47,0.07,6.66c-0.23,2.19-0.78,5.03-3.86,3.94
            c-2.76-0.98-0.04-3.99-1.19-5.82c3.76-2.27-8.95-13.5,5.42-10.01C89.17-63.05,89.38-61.28,87.93-59.64z"
            />
            <path
              style="fill: #788d3e"
              d="M87.93-59.64c0.14-1.74,0.29-3.48,0.43-5.22c5.48,2.19,15.06,1.38,8.84,12.12
            C92.87-53.37,92.91-59.89,87.93-59.64z"
            />
            <path
              style="fill: #25311b"
              d="M76.76-57.43c-1.64-2.17-3.27-4.34-4.91-6.52c1.71-2.25,2.01-5.62,5.96-6.96
            C79.05-66.14,78.47-61.77,76.76-57.43z"
            />
            <path
              style="fill: #3c4b20"
              d="M-125.59,6.34c-2.97,8.73,4.23,9.15,9.25,11.36c6.21-1.95,6.6,2.04,6.58,6.45
            c-6.28,11.43-14.5,5.11-22.33,2.45c-2.23,1.89-2.37,4.49-2.43,7.12c-0.83-0.74-1.65-1.47-2.48-2.21
            c2.3-6.93,0.08-13.97,0.52-20.95C-134.05,6.07-131.58,1.66-125.59,6.34z"
            />
            <path
              style="fill: #26351b"
              d="M-125.59,6.34c-4.28-0.27-7.53,2.14-10.89,4.23c0.26-6.45-3.52-10.98-7.34-15.49
            c4.64-1.61,9.28-3.22,13.92-4.83C-114.58-8.11-124.97,0.42-125.59,6.34z"
            />
            <path
              style="fill: #73a24c"
              d="M-146.22-20.39c6.42,6.67-1.02,10.53-3.14,15.47c-1.88-0.83-3.76-1.66-5.64-2.49
            c-0.85-2.26-1.69-4.51-2.54-6.77C-155.92-20.2-152.26-22.47-146.22-20.39z"
            />
            <path
              style="fill: #56742b"
              d="M-84.18,104.1c2.17-5.34,4.35-10.68,6.52-16.03c1.27-0.82,0.34,0.18,0.63-0.85
            c0.89-3.25,11.34-0.7,4.89-8.1c0.49-0.95,1.18-1.72,2.04-2.35c5.07-2.37,11.47-2.95,13.14-9.85c-0.15-4.73,0.59-9.49-1.14-14.09
            c0.11-1.81,0.71-3.41,1.89-4.8c6.77-3.44,11.88-13.31,21.54-5.65c2.83,2.91,1.01,6.66,1.79,9.95c2.87,4.26,6.68,8.28,3.66,14.05
            c-1.02,1.25-2.32,2.04-3.88,2.41c-6.38,1.03-8.1,6-9.5,11.19c0.14,0.12-0.29,0.48,0.19,0.57c0.11-1.07,0.56-1.97,1.37-2.7
            c2.65-1.76,5.27-0.81,7.9,0.04c2.24,0.84,3.85,2.35,4.82,4.54c1.25,4.96,1.67,9.81-2.08,14.04c-3.52,3.3-9.71,3.65-10.76,9.73
            c-0.63,0.84-1.4,1.52-2.31,2.05c-4.06,0.63-8.19,1.75-11.65-1.72c-3.58-1.59-5.24-3.59-0.81-6.08c2.82-1.58,6.72-1.24,8.5-4.91
            c-5.47-4.47-14.05,4.43-18.38-4.35c-5.87,3.14-6.17,9.64-8.56,14.77c-1.27,2.72-2.43,4.74-5.46,4.6
            C-83.94,110.4-84.33,107.34-84.18,104.1z"
            />
            <path
              style="fill: #465f3e"
              d="M-55.13,106.55c3.73-0.73,7.44-2.54,11.21-0.13l0.85,0.64l-0.72-0.78
            c1.04-6.54,1.84-13.33,11.37-11.29c6.07-2.26,7.66,1.65,8.72,6.31c0.09,3.15-1.13,5.98-2.14,8.88
            c-4.46,5.59-0.6,13.03-4.14,18.82c-1.51,0.79-3.02,1.58-4.52,2.38c-6.04-3.94-9.21-0.99-11.23,4.71
            C-46.85,125.6-50.44,115.9-55.13,106.55z"
            />
            <path
              style="fill: #5d7b29"
              d="M-91.31,47.18c3.23,9.36,2.77,20.16,13.56,28.32c-10.35,0.16-18.63-2.48-25.09,3.87
            c-4.89,0.59-9.28-1.24-13.71-2.85c-3.35-6.21-2.39-12.92-2.27-19.54c2.52-2.73,2.9-6.04,2.63-9.54
            c2.29-1.89,4.34-1.19,6.28,0.59c2.33,2.63,0.85,6.58,3.2,9.2c0.94,1.58,1.94,3.13,3.9,3.55c0.16,0.04,0.4-1.06,0.4-1.95
            c2.53-3.21,2.41-7.08,2.93-10.79c1.24-2.1,3.12-2.83,5.47-2.59C-92.95,45.75-92.04,46.34-91.31,47.18z"
            />
            <path
              style="fill: #3b471d"
              d="M-111.48,49.56c-1.57-0.71-3.15-1.42-4.72-2.13c-6.05-1.5-12.1-2.99-18.15-4.49
            c1.5-5.5,11.63,2.97,10.63-6.58c1.73-5.44,5.8-6.49,10.64-5.25c4.92,1.26,7.28,5.18,7.88,9.86
            C-104.63,45.48-106.07,49.13-111.48,49.56z"
            />
            <path
              style="fill: #6e922b"
              d="M-118.82,56.97c2.71,6.29,2.46,12.92,2.27,19.54C-124.69,70.86-121.33,63.87-118.82,56.97z"
            />
            <path
              style="fill: #31431d"
              d="M-34.69,44.96c-8.34-5.94-12.8,5.63-20.08,4.45c-4.06-1.72-3.27,4.68-6.7,4.03
            c-5.25-2.55-10.6-4.82-16.33-6.09c-4.9-2-7.3-4.62-2.26-9.1c3.5-3.16,1.91-6.51,0.49-9.96c-1.73-4.2-1.21-7.84,3.13-10.26
            c4.47-1.68,7.41,1.15,10.48,3.53c1.22,0.55,2.27,0.29,3.11-0.79c0.73-1.77,0.27-3.64,0.61-5.41c0.14-0.99,0.45-1.92,0.79-2.83
            c1.2-2.42-0.11-4.79-0.21-7.19c0.18-2.3,1.18-4.05,3.36-5c9.9,1.1,17.71,10.68,28.79,7.15c1.13,0.19,2.16,0.6,3.14,1.18
            c3.04,2.12,4.79,4.94,4.79,8.71c-0.18,1.9-1.51,3.32-2.19,5.01c-3.48,8.74-8.44,9.24-14.55,2.93c0.22,0.1,0.57,0.45,0.75,0.53
            c3.79,3.89,10.08,6.4,6.67,13.6C-31.53,41.75-32.2,43.99-34.69,44.96z"
            />
            <path
              style="fill: #2f3a1b"
              d="M-111.48,49.56c3.52-3.81,4.03-7.54,0.6-11.92c-4.03-5.16-8.3-3.66-12.85-1.27
            c-4.23-2.02-5.34-6.71-8.37-9.76c6.47-3.33,13.9,3.03,20.2-1.99c3.06-5.53,8.26-6.42,13.86-6.45
            c15.24-0.56,18.04,0.96,21.17,11.03c1.15,3.69,0.16,5.84-3.7,6.52c-6.62,0.97-6.49,8.29-10.75,11.46
            c-0.81-0.12-1.62-0.25-2.43-0.37c-6.44-2.39-6.15,2.2-6.42,6.22c-0.56,2.16-2.19,3.67-3.25,5.53c-1.91,1.61-3.89,2.23-6.08,0.52
            C-112.48,56.39-111.83,52.94-111.48,49.56z"
            />
            <path
              style="fill: #608230"
              d="M-80.56,35.72c1.65-10.64-1.01-13.48-10.57-11.32c-1.95,0.44-3.69-0.02-5.11-1.49
            c-10.77-3.4-3.82-11.14-4.21-17.1c-0.46-5.35-0.75-10.61,6.19-12.16c6.95,0.53,10.98,10.1,19.36,6.24
            c2.93,0.26,5.27,1.48,6.72,4.15c0.93,2.67,0.24,5.27-0.33,7.89c-1.06,3.81-3.48,6.46-7.03,8.13c-2.68,2.92-1.07,5.22,0.7,7.93
            c4,6.15,2.41,9.85-5.23,10.26C-80.23,37.41-80.39,36.56-80.56,35.72z"
            />
            <path
              style="fill: #8aaf2c"
              d="M-53.07,65.73c4.93,0.86,5.58,6.02,2.74,6.8c-6.28,1.73-10.18,11.07-18.44,6.14
            c-2.07-1.58-0.23-6.21-4.65-5.95c-0.51-5.5-4.56-9.38-6.49-14.23c1.11-8.88,7.45-10.81,17.68-5.39c1.57,0.5,3.16,0.96,4.34,2.22
            C-54.49,57.96-58.67,64.11-53.07,65.73z"
            />
            <path
              style="fill: #719332"
              d="M-62.05,54.53c-6.7-2.08-12.77-1.3-17.85,3.96c-4.49-4.69,0.06-7.68,2.1-11.14
            c6.66-9.85,6.66-9.85,16.01,5.44l0.49,0.97L-62.05,54.53z"
            />
            <path
              style="fill: #8aaf2c"
              d="M-70.83,79.81c6.69,10.17-3.79,6.14-6.83,8.26c0.42-4.91,1.62-9.6,3.99-13.95
            C-69.39,74.36-69.36,76.71-70.83,79.81z"
            />
            <path
              style="fill: #aac062"
              d="M-70.83,79.81c-0.94-1.89-1.89-3.78-2.83-5.68c0.02-0.49,0.11-0.96,0.26-1.42
            c5.54-1.35,0.96-11.27,9.58-10.56c0.67,6.2-4.55,10.73-4.94,16.51C-69.45,79.05-70.14,79.43-70.83,79.81z"
            />
            <path
              style="fill: #537754"
              d="M-27.88,108.59c-1.81-3.16,0.6-4.79,2.4-6.65c-0.03-6.15-5.36-13.48,3.87-17.61
            c4.34-1.27,8.02-0.11,11.18,3.01c1.33,1.08,2.25,2.45,2.76,4.09c1.19,3.88-1.72,6.93-2.13,10.51
            c1.96,6.38,0.17,12.91,0.76,19.34c2.53-0.36,4.88,0.32,5.07,3.05c0.26,3.92-2.46,6.02-5.89,7.26c-5.96-2.1-6.26-6.44-4.54-11.56
            c1.16-9.36-5.59-7.47-10.89-7.65C-26.84,111.58-27.77,110.36-27.88,108.59z"
            />
            <path
              style="fill: #80af31"
              d="M-9.87,131.59c0.38-3.43,6.74-6.38,0.83-10.31c2.07-1.55,4.09-3.17,6.21-4.66
            c7.28-5.1,9.05-1.46,8.67,5.53c-2.58,2.05-7.3,2.14-6.53,7.22c-2.17,2.19-4.34,4.37-6.5,6.56
            C-8.09,134.48-8.98,133.04-9.87,131.59z"
            />
            <path
              style="fill: #6e8d93"
              d="M-25.69,110.67c3.26-4.96,8.13-4.58,11.61-1.62c3.31,2.81,3.01,7.39-0.34,10.97
            c-0.47-0.69-0.93-1.39-1.39-2.08c-2.23-0.09-4.49,0.68-6.68-0.32C-24.9,115.93-27.2,114.18-25.69,110.67z"
            />
            <path
              style="fill: #405752"
              d="M-21.05,115.14c1.75,0.93,3.5,1.87,5.25,2.8c-1.68,2.98-3.36,5.95-5.04,8.93
            c-1.4-0.64-2.8-1.28-4.2-1.92C-29.94,119.14-26.44,116.76-21.05,115.14z"
            />
            <path
              style="fill: #879e9f"
              d="M-23.19,163.2c4.52,2.7,11.97-3.06,16.6,7.35c-7.02-3.61-14.19,1.56-16.8-7.15
            C-23.39,163.4-23.19,163.2-23.19,163.2z"
            />
            <path
              style="fill: #5a7770"
              d="M-21.05,115.14c-2.43,2.82-5.06,5.56-3.99,9.8c-1.64,1.35-3.29,2.7-4.93,4.05
            c-0.81-6.96-7.19-14.49,2.1-20.41c0.73,0.69,1.46,1.39,2.19,2.08C-24.14,112.16-22.6,113.65-21.05,115.14z"
            />
            <path
              style="fill: #aac037"
              d="M69.3,99.7c0.84-2.39,1.69-4.78,2.53-7.17c9.31,1.01,9.81,2.23,4.82,11.7l0.05-0.01
            c-2.79,2.08-4.49,6.38-9.21,4.48C63.51,104.78,68.33,102.63,69.3,99.7z"
            />
            <path
              style="fill: #80ad28"
              d="M67.49,108.7c2.8-2.04,5.04-5.25,9.21-4.48c-0.4,3.96,0.58,7.66,2.12,11.25
            C73.21,116.29,70.59,112.11,67.49,108.7z"
            />
            <path
              style="fill: #26321a"
              d="M-9.79,101.93c-4.98-3.02-4.5-6.22-0.3-9.53c4.1-2.76,8.24-7.32,12.14,0.24
            c1.8,1.84,3.74,3.57,5.38,5.55c3.28,3.96,0.85,8.34-1.08,11.17c-2.01,2.95-4.11-1.18-6.17-2.41
            C-3.01,105.05-6.46,103.59-9.79,101.93z"
            />
            <path
              style="fill: #4e6b28"
              d="M2.05,92.65c-4-2.7-8.11,0.93-12.14-0.24c-0.81-0.73-1.62-1.46-2.43-2.19
            c-0.69-3.68,2.14-8.15-2.94-10.88c-2.18-1.17-4.88-3.71-3.24-6.22c1.91-2.92,5.4-3.45,8.59-0.94c4.74,1.59,9.49,3.17,14.23,4.76
            c-1.67,6.33,4.72,8.97,6.29,13.82C7.62,91.39,4.83,92.02,2.05,92.65z"
            />
            <path
              style="fill: #a7c229"
              d="M-10.11,72.18c-1.8,0.08-3.6,0.16-7.65,0.34c5.9,4.39,14.65,3.33,14.45,10.85
            c-0.12,4.64-5.95,4.71-9.22,6.85c-2.74-1.29-5.48-2.57-8.21-3.85c-3.56,0.65-7.16,1.47-9.4-2.61c-1.48-0.88-2.95-1.76-4.43-2.64
            c-4.86-5.09-6.27-10.17,0-15.26c1.34-0.23,2.68-0.46,4.02-0.69c1.88-2.14,3.97-3.49,6.83-1.71
            C-22.21,71.09-12.52,65.95-10.11,72.18z"
            />
            <path
              style="fill: #577d2e"
              d="M15.26,60.94c-8.91,3.95-17.91,6.72-27.45,2.02c-3.7-4.2-3.88-9.12-2.92-14.28
            c6.5-8.18,6.57-8.18,18.74,1.66C3.8,50,3.98,49.66,4.15,49.33c-1.44,1.94-2.78,0.47-2.7-0.65c0.47-6.2,4.64-6.93,9.71-6.07
            c6.07,2.29,6.95,7.82,8.18,13.13C19.21,58.43,17.99,60.27,15.26,60.94z"
            />
            <path
              style="fill: #77a231"
              d="M-11.94,49.64c-0.68,4.43,3.32,8.94-0.24,13.31c-3.85,0.16-7.69,0.33-11.53,0.49
            c-2.11,1.23-4.26,2.31-6.83,1.72c1.28-8.49-10.78-6.01-10.92-13.33c5.45-1.98,10.77-4.5,16.77-4.34
            C-20.11,46.21-15.67,45.82-11.94,49.64z"
            />
            <path
              style="fill: #9eb9ba"
              d="M15.26,60.94c0.71-1.47,1.43-2.94,2.14-4.41c2.45-10.85,4.19-11.8,11.3-6.16
            c0.46,4.95-3.44,8.06-5.16,12.1c-0.5,0.23-1.01,0.46-1.51,0.69c-1.48,0.08-2.96,0.16-4.44,0.24
            C16.82,62.58,16.04,61.76,15.26,60.94z"
            />
            <path
              style="fill: #82b128"
              d="M76.65,104.23c0.49-4.77,1.49-9.74-4.82-11.7c2.23-3.68-0.64-12.32,8.76-9.01
            c2.5-1.67,5.07-6.32,7.41,0.15c0.77,7.16-3.51,13.09-4.78,19.75C80.81,101.9,78.72,103.01,76.65,104.23z"
            />
            <path
              style="fill: #8da1bd"
              d="M77.05,70.14c-0.06,1.51-0.11,3.01-0.16,4.52c-2.04,0.97-5.71-0.08-5.46,3.81
            c0.46,7.24-5.35,3.91-8.41,5.2c0,0,0.07,0.2,0.07,0.2c-2.47-2.86-2.1-5.74-0.08-8.65C66.99,71.61,69.84,64.84,77.05,70.14z"
            />
            <path
              style="fill: #72a132"
              d="M88,83.67c-2.42-2.39-4.92-0.77-7.4-0.15c0.28-2.16,0.56-4.32,0.85-6.47
            C91.29,77.09,91.29,77.09,88,83.67z"
            />
            <path
              style="fill: #49672b"
              d="M36.05,79.13c0.12-5.69-3.92-10.58-9.32-11.3c-2.8-0.31-4.28-1.95-4.7-4.66
            c0,0-0.09-0.03-0.09-0.03c2.57-5.89,9.16-8.17,12.23-13.63c2.75-2.21,5.43-4.64,9.38-2.77c3.33,2.29,4.01,5.78,4.28,9.44
            c-1.34,3.47-1.68,11.04-0.61,13.98C47.4,78.02,42.66,79.75,36.05,79.13z"
            />
            <path
              style="fill: #3d5328"
              d="M47.22,70.16c-2.73-1.82-5.46-3.64-8.51-5.67c0.58-3.54,6.07-3.73,6.23-7.83
            c2.49-4.35,6.44-3.86,10.46-3.18c3.77,1.52,6.32,4.06,6.89,8.25c-0.07,6.74-6.48,5.46-9.98,7.88
            C50.61,69.79,48.92,69.98,47.22,70.16z"
            />
            <path
              style="fill: #243e2e"
              d="M66.02,76.72c-0.98,2.38-1.96,4.77-2.94,7.15c-2.37-0.77-5.76,0.99-6.95-2.77
            c2.33-2.9-1.84-5.81,0.02-8.7c1.33-1.89,2.77-3.6,5.38-3.54C65.75,69.92,66.56,72.93,66.02,76.72z"
            />
            <path
              style="fill: #a4bc6b"
              d="M61.61,70.36c-1.13,2.53-3.08,2.85-5.45,2.03c-0.2-2.43-1.93-2.73-3.85-2.78
            c-0.07-5.79,6.24-3.13,8.21-6.2C62.85,65.42,63.03,67.77,61.61,70.36z"
            />
            <path
              style="fill: #2b391b"
              d="M-9.36-41.75c1.35,2.28,2.7,4.55,4.05,6.83c0.46,2.17,0.99,4.34-0.23,6.44
            c-7.53,7.06-18.68,9.37-24.52,18.66c-5.45,3.16-4.7,8.17-3.88,13.2c-0.43,3.15-2.29,5.03-5.21,6
            c-8.33-3.28-14.86-8.29-17.01-17.58c-1.24-2.23-1.86-4.55-1.09-7.09c2.03-3.77,4.61-6.09,9.04-3.34
            c4.87,0.08,9.37-2.51,7.53-7.05c-4.29-10.6,3.63-7.82,8.34-8.86c0.65,0.15,1.28,0.32,1.91,0.54c4.91,1.69,3.72-2.91,5.06-4.91
            c0.8-0.75,1.6-1.5,2.4-2.26c1.36-3.44,4.24-3.44,7.22-3.19c1.47,0.04,2.71-0.53,3.84-1.42l-0.11-0.01
            c1.45-1.52,3.23-1.69,4.37-0.05C-6.41-44.04-7.7-42.66-9.36-41.75z"
            />
            <path
              style="fill: #618231"
              d="M-71.07-45.69c1.91-4.29,4.82-8.21,4.8-13.2c-1.75-3.39-5.04-6.86,0.48-9.91
            c13.3,7.05,15.9,12.26,11.28,26.73c-1.27,3.98,0.1,7.34-0.53,10.92c-0.9,2.36-2.67,3.59-5.11,3.96
            c-10.12-1.49-7.91-10.7-10.54-16.89C-70.82-44.62-70.95-45.16-71.07-45.69z"
            />
            <path
              style="fill: #a2afbd"
              d="M-62.62-30.3c2.53-0.28,5.05-0.57,7.57-0.85c0.29,1.45,0.57,2.9,0.86,4.36
            c9.8-2.98,6.36,3.71,5.96,8.18c-2.66,0.8-5.45,1.35-7.27,3.78c-2.91,3.75-4.79,1.05-6.78-1.07
            C-63.95-20.66-66.79-25.39-62.62-30.3z"
            />
            <path
              style="fill: #aac55d"
              d="M-65.8-68.8c-0.16,3.3-0.32,6.6-0.48,9.91c-0.98-0.2-1.97-0.4-2.96-0.59
            c-2.04-1.99-1.8-5.2-3.82-7.21c0-1.33,0.01-2.65,0.01-3.98C-70.43-70.8-67.67-71.54-65.8-68.8z"
            />
            <path
              style="fill: #33411d"
              d="M-82.06-58.96c-1.12,2.2-0.01,7.21-4.22,5.5c-2.24-0.91-5.98-4.8-4.41-8.17
            c2.24-4.82,5.5-1.07,8.24,0.39C-80.63-60.77-80.5-60.01-82.06-58.96z"
            />
            <path
              style="fill: #a4bc8a"
              d="M-87.02-35.63c4.15-1.98,4.34-6.97,7.43-9.76c1.37,0.52,2.74,1.05,4.11,1.57
            c0.85,0.99,1.3,2.15,1.35,3.46c-1.39,5.11-2.8,10.18-9.61,10.14C-86.2-31.19-88.93-32.01-87.02-35.63z"
            />
            <path
              style="fill: #404d1f"
              d="M-82.06-58.96c-0.13-0.76-0.26-1.52-0.39-2.28c3.31-1.5,9.73,2.33,9.39-5.46
            c4.18,0.86,4.84,3.59,3.82,7.21C-73.31-54.54-77.67-56.4-82.06-58.96z"
            />
            <path
              style="fill: #465b23"
              d="M-76.07-41.39c0.2-0.81,0.39-1.61,0.59-2.42c1.47-0.63,2.94-1.26,4.41-1.88
            c0,0-0.07-0.14-0.07-0.14c1,1.11,2.05,2.36,1.93,3.84c-0.29,3.83,1.97,8.18-2.14,11.28c-1.24,0.93-2.76,1.59-4.01,0.55
            C-79.43-33.58-76.44-37.6-76.07-41.39z"
            />
            <path
              style="fill: #9abb5b"
              d="M-87.02-35.63c1.09,1.87,2.62,3.13,4.79,3.52c4.78,1.75,7.82,4.43,5.39,10.06
            c-2.52,2.57-5.13,3.96-8.13,0.67c-0.69-3.92-3.82-3.97-6.78-4.29c-0.21-2.46-0.56-4.91-0.59-7.37
            C-92.41-37.53-90.06-37.38-87.02-35.63z"
            />
            <path
              style="fill: #3c4e2a"
              d="M-93.88-5.48c-4.82,3.29-1.5,7.96-2.03,11.97c-2.04,3.14-4.36,6.31-8.62,5.15
            c-5.47-1.49-4.6-6.72-5.52-10.81c-2.83-5.51,4.09-6.79,4.68-10.83c0.62-4.17,5.2-3.61,7.25-6.07c0.69-0.12,1.36-0.08,2.04,0.1
            C-91.6-13.26-89.86-9.98-93.88-5.48z"
            />
            <path
              style="fill: #27301b"
              d="M-105.36-10c4.05,5.66-2.11,6.69-4.3,9.52c-0.57,0.76-1.31,0.92-2.17,0.58
            c-2.64-1.64-4.27-3.9-4.37-7.11C-112.89-9.1-109.18-9.73-105.36-10z"
            />
            <path
              style="fill: #496731"
              d="M-93.88-5.48c-0.73-3.49-1.47-6.99-2.2-10.48c3.71-1.81,7.41-3.61,11.12-5.42
            c2.77,0.77,4.75-1.11,6.99-2.09c5.39-0.97,7.83,2.73,10.27,6.46c0.33,8.07-6.19,13.63-7.73,21.03
            C-83.53,4.64-91.61,5.24-93.88-5.48z"
            />
            <path
              style="fill: #577036"
              d="M-111.84,0.1c0.71-0.24,1.44-0.43,2.17-0.58c5.53,0.46,3.65,15.57,13.76,6.97
            c-0.43,2.23,3.89,4.94,0.23,6.47c-7.73,3.24-2.14,6.56-0.56,9.95c-5.45-1.58-10.46,0.88-15.65,1.71
            c-0.33-3.05-0.8-6.01-4.46-6.92C-116.88,11.31-116.68,5.11-111.84,0.1z"
            />
            <path
              style="fill: #899bac"
              d="M61.61,70.36c-0.36-2.32-0.72-4.64-1.08-6.96c-0.23-3.25-3.03-4.81-4.69-7.11
            c-2.06-3.24-2.58-6.8-2.19-10.56c0.73-2.2,2.07-3.73,4.47-4.1c9.24-2.38,12.6,5.89,18.23,10.07c2.83,1.69,4.84,3.97,5.24,7.37
            c2.13,1.38,4.26,2.76,6.39,4.15c-3.95,1.82-9.05,1.8-10.92,6.91c-4.87,0.19-7.6,3.98-11.03,6.58
            C64.55,74.6,63.08,72.48,61.61,70.36z"
            />
            <path
              style="fill: #72915b"
              d="M78.87-30.01c2.47-1.74,5.36-3.73,6.74,0.71c1.85,5.96-1.23,9.58-6.81,11.4
            C75.44-21.96,77.23-25.98,78.87-30.01z"
            />
            <path
              style="fill: #6c9064"
              d="M69.92-32.12c1.36,0.04,2.73,0.08,4.09,0.11c0.2,2.11,0.4,4.23,0.61,6.34
            c2.53,3.41,0,5.39-2.11,7.49c-0.24,0.51-0.42,1.04-0.53,1.59C62.39-20.58,73.8-27.36,69.92-32.12z"
            />
            <path
              style="fill: #485c2a"
              d="M72.5-18.18c0.7-2.5,1.4-4.99,2.11-7.49c1.42-1.45,2.84-2.9,4.26-4.34
            c-0.03,4.04-0.05,8.07-0.08,12.11C76.7-17.99,74.6-18.08,72.5-18.18z"
            />
            <path
              style="fill: #364b29"
              d="M105.96,31.44c-2.59-1.6-4.95-3.61-3.35-6.9c1.61-3.3,5.06-1.66,7.75-1.99
            c1.08-1.73,2.1-3.55,4.66-2.84C122.62,31.84,112,29.86,105.96,31.44z"
            />
            <path
              style="fill: #8ca4ae"
              d="M115.02,19.71c-1.55,0.95-3.11,1.89-4.66,2.84c-1.32-3.48-5.28-5.9-4.14-10.37
            c2.76,1.23,4.67-0.95,6.92-1.74C120.16,12.23,120.04,15.47,115.02,19.71z"
            />
            <path
              style="fill: #99ba61"
              d="M97.15,40.48c-0.74-0.64-1.48-1.27-2.22-1.91c-1.27-0.93-1.5-2.16-1.06-3.6
            c3.99-0.29,7.98-0.58,11.97-0.88c-1.62,4.45,0.76,9.78-3.03,13.75C95.34,49.68,100.24,42,97.15,40.48z"
            />
            <path
              style="fill: #667e3a"
              d="M97.15,40.48c4.59,0.36,1.31,6.78,5.65,7.36c1.32,4.94,0.02,7.9-5.67,7.53
            C94.51,50.4,98.41,45.44,97.15,40.48z"
            />
            <path
              style="fill: #bccbd5"
              d="M74.47,56.28c-9.29,1.42-5.91-6.5-7.43-10.84c-2.94,1.6-0.99,5.55-5.06,6.96
            c-1.16-3.02-2.4-6.23-3.63-9.43c-0.83-9.69,6.52-6.36,11.31-7.11c1.66-0.66,3.31-1.32,4.97-1.97c1.52-1.82,3.35-2.94,5.83-2.61
            c5.01,1.79,5.64,4.94,2.84,9.16c-2.22,0.72-1.89,2.76-2.3,4.42C78.57,48.52,76.89,52.61,74.47,56.28z"
            />
            <path
              style="fill: #779459"
              d="M69.66,35.86c-3.09,3.45-9.16,2.15-11.31,7.11c-0.62,1.22-1.23,2.43-1.85,3.65
            c1.11,2.11,0.24,3.38-1.85,3.78c-2.47,0.48-3.94-0.99-4.95-3.04c-0.81-4.67,1.88-9.31,0.13-13.99
            c0.02-4.49,1.75-9.06-0.45-13.49c1.07-6.12,6.78-1.3,9.32-3.96c2.15,0.08,6.1,0.49,4.83,2.59
            C58.16,27.32,65.9,30.74,69.66,35.86z"
            />
            <path
              style="fill: #4a6735"
              d="M76.83,17.89c0.66-0.64,1.32-1.28,1.98-1.92c1.66-0.41,3.42-1.78,4.81,0.49
            c1.78,4.21,5.45,7.92,3.49,13.13c-1.6,2.16-3.68,2.96-6.3,2.23c-2.76-2.07-3.84-4.54-1.84-7.74
            C78.26,22.02,77.55,19.95,76.83,17.89z"
            />
            <path
              style="fill: #89a691"
              d="M78.97,24.08c0.77,2.39,1.53,4.78,2.3,7.17c1.12,1.27,0.89,2.2-0.69,2.81
            c-1.98-0.06-3.97-0.12-5.95-0.18C74.53,29.93,72.64,25.18,78.97,24.08z"
            />
            <path
              style="fill: #63816a"
              d="M80.58,34.06c0.23-0.94,0.46-1.87,0.69-2.81c1.72-0.7,3.43-1.4,5.15-2.1
            c1.98-0.18,3.11,0.86,3.74,2.62c1.25,1.08,2.49,2.15,3.72,3.21c0.34,1.18,0.69,2.39,1.05,3.58c-2.69,8.05-6.91,6.55-11.64,1.86
            C81.77,38.57,83.36,35.39,80.58,34.06z"
            />
            <path
              style="fill: #91b296"
              d="M90.16,31.78c-1.25-0.87-2.49-1.74-3.74-2.62c-0.8-4.27-4.54-7.89-2.8-12.71
            c1.69,1.23,3.38,2.46,5.07,3.68C93.71,23.44,92.08,27.59,90.16,31.78z"
            />
            <path
              style="fill: #a8b9bd"
              d="M42.76-20.97c-3.18,3.23-6.16,6.47-0.66,10.25c-7.73,1.15-15.07,1.01-17.24-8.51
            c-0.92-4.03-3.32-5.56-6.65-6.66c-0.64-0.48-0.96-1.05-0.96-1.7c0-0.69,0.09-1.04,0.24-1.04c5.85-4.5,14.09-5.66,17.78-13.35
            c0.85-1.77,4.03-1.37,6.12,0.01c2.22,2.27,2.9,4.79,1.27,7.69C44.59-29.86,46.07-25.43,42.76-20.97z"
            />
            <path
              style="fill: #324921"
              d="M15.09-0.55c3.47,1.57,5.64-4.97,9.41-1.51c1.14,7.55-1.03,13.67-7.68,17.89
            c-1.1,0.48-2.24,0.69-3.43,0.68C3.12,13.89,2.81,13.18,7.51,3.01C9.21,0.06,11.58-1.46,15.09-0.55z"
            />
            <path
              style="fill: #a1acb5"
              d="M20.2-16.03c4.19,7.53-4.17,7.9-6.63,11.64C9.6-2.38,6.88-4.19,4.58-7.32
            c-2.27-6.09-0.64-11.83,1.6-17.52c0.68-0.87,1.51-1.55,2.51-2.02c6.08-1.25,7.06,3.34,8.46,7.43
            C17.98-18.14,19.33-17.3,20.2-16.03z"
            />
            <path
              style="fill: #9cad9a"
              d="M6.19-9.24c2.46,1.62,4.92,3.23,7.39,4.85c0.51,1.28,1.01,2.56,1.52,3.84
            c-1.49,1.52-2.99,3.04-4.48,4.56c-2.9,2.65-5.87,2.63-8.9,0.22C-3.74-0.72-6.61-6.26-2.2-13.4C3.03-16.95,4.14-12.15,6.19-9.24z
            "
            />
            <path
              style="fill: #577e2d"
              d="M15.35,13.29C18.4,8.17,21.45,3.05,24.5-2.07c0.7,0.19,1.4,0.27,2.12,0.24
            c1.49,0.9,2.15,2.2,1.87,3.94c0.09,3.03,0.18,6.07,0.27,9.1C24.7,14.57,20.74,18.54,15.35,13.29z"
            />
            <path
              style="fill: #d2d9ea"
              d="M20.2-16.03c-2.44,0.65-3.48,5.25-7.12,2.54c1.82-4.24,1.53-8.5,0.06-12.76
            c-1.44-4.19,0.13-6.25,4.48-6.34c1.22,1.58,1.34,3.18,0.06,4.8c-0.22,0.74-0.04,1.37,0.52,1.9
            C19.52-22.74,22.18-19.86,20.2-16.03z"
            />
            <path
              style="fill: #2e3e1d"
              d="M28.48,2.11c-0.62-1.31-1.24-2.63-1.86-3.94c4.3-3.75,8.09-2.71,11.56,1.26
            C35.43,2.05,31.89,1.85,28.48,2.11z"
            />
            <path
              style="fill: #b3c4c8"
              d="M31.43,15.44c1.19,0.01,2.39,0.02,3.58,0.03c1.03,0.14,1.66,0.73,1.94,1.73
            c-0.03,3.77-3.33,5.74-4.76,8.75c-2.42,2-2.55,5.54-5.01,7.54c-2.01,1.49-4.16,2.02-6.57,1.08c-4.92-3.15-5.08-8.25-5.43-13.27
            C18.25,12.82,25.45,15.82,31.43,15.44z"
            />
            <path
              style="fill: #90b547"
              d="M31.43,15.44c-3.9,3.92-10.98,0.93-14.12,6.51c-0.94,1.65-2.44,2.15-4.23,2.04
            c-1.77-0.44-2.94-1.53-3.5-3.28c-0.49-4.35,3.03-5.58,5.77-7.42c0,0,0,0,0,0c4.47-0.69,8.93-1.39,13.4-2.08
            C29.64,12.62,30.54,14.03,31.43,15.44z"
            />
            <path
              style="fill: #85a256"
              d="M35.27,17.32c-0.08-0.61-0.17-1.23-0.25-1.84c4.44-0.71-1.12-8.33,5.28-7.7
            c3.15,3.67,6.12,7.44,7,12.4c-0.46,0.51-0.92,1.02-1.38,1.53C41.08,23.37,36.28,24.94,35.27,17.32z"
            />
            <path
              style="fill: #bfcccb"
              d="M49.38,19.87c12.41-0.23,4.84,6.91,4.23,11.49c-1.57,3.87-1.47,8.79-6.58,10.45
            c-5.91-0.41-7.72-3.89-6.43-9.17c0.8-3.24,1.48-6.54,2.64-9.68c1.04-1.41,2.6-1.94,4.18-2.41c0,0-0.13-0.37-0.13-0.37
            C48.01,20.19,48.7,20.09,49.38,19.87z"
            />
            <path
              style="fill: #bec9d4"
              d="M42.66-34.29c-0.83-2.34-1.65-4.67-2.48-7.01c-2.44-1.97-6.6-2.86-4.75-7.54
            c1.99-2.25,4.05-4.17,6.99-1.3c6.79,2.14,7.7,7.35,6.97,13.42C47.72-34.32,45.22-34.22,42.66-34.29z"
            />
            <path
              style="fill: #2d3519"
              d="M-27.75-57.65c0.72,0.23,1.45,0.47,2.17,0.7c3.94,0.83,8.06,1.44,8.79,6.58
            c0.11,6.21-5.85,6.21-9.15,8.94c-5.97-2.95-8.58-7.06-4.63-13.43C-29.63-55.79-28.69-56.72-27.75-57.65z"
            />
            <path
              style="fill: #455c27"
              d="M-25.38-38.9c3.26,2.82,2.84,5.57-0.05,8.39c-1.38,1.35-2.86,2.72-4.74,1.48
            c-2.01-1.32-0.84-3.26-0.27-4.96C-28.75-35.62-27.06-37.26-25.38-38.9z"
            />
            <path
              style="fill: #80a52b"
              d="M-25.94-41.43c0.07-4.96,5.88-4.97,7.45-8.64c3.7,2.77,6.56,5.85,4.09,10.93
            c-2.86-0.67-5.72-1.35-8.57-2.02C-23.97-41.24-24.95-41.34-25.94-41.43z"
            />
            <path
              style="fill: #475b22"
              d="M-14.4-39.13c-1.36-3.64-2.73-7.29-4.09-10.93c-1.92-2.75-6.51-2.75-7.09-6.88
            c3.09-2.87,7.05-2.47,10.8-2.86c5.17,3.82-0.3,9.82,2.87,14.05C-11.99-43.27-11.82-40.68-14.4-39.13z"
            />
            <path
              style="fill: #637e44"
              d="M35.99-48.19c1.4,2.3,2.8,4.6,4.2,6.9c-1.02,0.4-2.91,0.76-2.92,1.2
            c-0.59,13.66-10.32,12.58-19.57,12.31c-0.16-1.4-0.33-2.81-0.49-4.21c-1.11-1.6-0.96-3.18,0.1-4.73
            c2.06-1.82,2.51-4.31,2.82-6.85c3.86,2.86,5.93,1.49,6.72-2.87c1.21-1.2,2.64-1.79,4.35-1.6c0.54-0.21,1.09-0.38,1.65-0.53
            C33.99-49.28,35.04-49.15,35.99-48.19z"
            />
            <path
              style="fill: #4a5d2c"
              d="M6.19-9.24c-2.12-1.42-4.24-2.84-6.35-4.26c-5.22,0.12-7.23-3.43-8.7-7.61
            c-1.37-3.02-0.3-5.74,1.05-8.43c0.83-1.79,1.66-3.58,2.49-5.38c2.51-0.52,5.03-1.03,7.54-1.54c3.68,3.63,0.45,10.51,6.17,13.19
            C7.71-18.59,8.71-13.63,6.19-9.24z"
            />
            <path
              style="fill: #949f7a"
              d="M8.4-23.27c-9.76,0.64-11.33-2.72-6.18-13.19c5.17-2.72,4.82,2.3,6.42,4.58
            c1.72,2.21,1.73,4.35-0.31,6.38C8.4-24.76,8.42-24.01,8.4-23.27z"
            />
            <path
              style="fill: #58693d"
              d="M13.01-66.13c6.64-0.43,12.02,4.05,18.38,4.69c1.62,1.98,2.09,4.23,1.55,6.71
            C24.68-55.69,17.88-59.22,13.01-66.13z"
            />
            <path
              style="fill: #57742a"
              d="M8.97-61.52c5.8,5.55,5.73,11.64,4.48,17.98C5.79-50.69,5.52-51.66,8.97-61.52z"
            />
            <path
              style="fill: #817f8d"
              d="M8.33-25.5c0.1-2.13,0.2-4.25,0.31-6.38c3.95,0.28,4.41-5.68,8.66-4.86
            c-0.04,1.58-0.07,3.16-0.11,4.73c-4.26,1.25-2.83,4.01-2.35,7.08c0.6,3.83,2.02,8.14-1.77,11.43
            C12.05-17.72,15.73-23.8,8.33-25.5z"
            />
            <path
              style="fill: #718b2e"
              d="M-9.36-41.75c0.66-2.36,0.24-4.01-2.66-4.02c1.75-3.04,2.07-6.93,6.58-10.15
            C-6.59-50.25,2.84-43.74-9.36-41.75z"
            />
            <path
              style="fill: #a9bab3"
              d="M28.5-45.59c-0.84,3.73-1.68,7.45-2.52,11.22c-5.26-1.66-2.36-7.27-5.86-9.22
            c-0.24-0.72-0.29-1.45-0.15-2.19C22.86-48.16,25.72-48.79,28.5-45.59z"
            />
            <path
              style="fill: #58693d"
              d="M28.5-45.59c-2.84-0.06-5.69-0.12-8.53-0.19c2.12-8.8,6.06-8.56,11.23-2.27
            C30.29-47.23,29.39-46.41,28.5-45.59z"
            />
            <path
              style="fill: #5c6b49"
              d="M35.99-48.19c-1.05-0.13-2.09-0.25-3.14-0.38c0.14-1.28,0.29-2.55,0.44-3.82
            c3.92-0.29,4.52-6.65,9.41-5.16c0.63,2.5,3.17,5.08-0.27,7.44C40.28-49.48,38.13-48.84,35.99-48.19z"
            />
            <path
              style="fill: #6f8985"
              d="M74.47,56.28c-3.66-7.15-3.31-12,6.53-11.43c1.08,4.7,8.03,9.14,0.61,14.23
            C79.21,58.15,76.84,57.22,74.47,56.28z"
            />
            <path
              style="fill: #3e5a38"
              d="M53.67-50.22c1.55-0.81,3.1-1.61,4.65-2.42c6.37,1.5,3.38,5.58,2.49,9.08
            c-0.12,1.04-0.24,2.08-0.36,3.13C48.64-37.05,53.03-44.94,53.67-50.22z"
            />
            <path
              style="fill: #8fa766"
              d="M56.15-59.45c-0.76-2.29-1.52-4.58-2.28-6.88c14.47-5.47,7.19,6.61,9.09,11.29
            C58.78-53.57,58.45-58.03,56.15-59.45z"
            />
            <path
              style="fill: #657c46"
              d="M56.15-59.45c3.2,0.03,4.47,3.04,6.81,4.41c-0.45,3.87,3.54,8.58-2.13,11.48
            c0.05-3.27,0.13-6.55-2.49-9.08C57.5-54.88,54.09-56.29,56.15-59.45z"
            />
            <path
              style="fill: #3e5723"
              d="M-30.14,83.75c3.37,0.01,6.75-0.02,9.4,2.61c-5.25,4.08-1.77,10.81-4.73,15.58
            c-2.05-2.59-3.55-5.72-6.94-6.96c0.68-2.06,2-4.02-0.26-6.08C-37.59,84.43-32.26,84.85-30.14,83.75z"
            />
            <path
              style="fill: #98ba20"
              d="M-34.56,65.86c2.1,5.09-2.42,10.17,0,15.26c-2.33-0.58-4.66-1.17-6.98-1.75
            c-0.73,0.8-1.44,0.78-2.13-0.06C-44.06,72.5-47.52,63.61-34.56,65.86z"
            />
            <path
              style="fill: #638631"
              d="M-23.5,52.02c-5.99-0.06-11.98-0.12-17.97-0.17c2.26-2.29,4.52-4.59,6.78-6.88
            c0.08-2.08,0.17-4.16,0.25-6.24c4.11-4.07,7.63-1.46,10.44,1.47C-20.64,43.71-19.54,47.91-23.5,52.02z"
            />
            <path
              style="fill: #678252"
              d="M-62.05,54.53c0.09-0.58,0.18-1.17,0.26-1.75c4.74-5.83-1.59-9.11-3.85-14.32
            c8.57-0.26,9.24,5.82,10.87,10.94c-0.04,1.23-0.09,2.47-0.13,3.7c-0.15,2.81-1.74,3.86-4.4,3.63
            C-60.22,56.01-61.14,55.27-62.05,54.53z"
            />
            <path
              style="fill: #99b47a"
              d="M-59.3,56.74c1.47-1.21,2.93-2.41,4.4-3.62c3.84,3.73,1.12,8.42,1.83,12.61
            C-60.28,66.3-58.72,60.78-59.3,56.74z"
            />
            <path
              style="fill: #36491e"
              d="M-43.67,79.31c0.71-0.14,1.42-0.12,2.13,0.06c2.01,9.68-5.46,8-10.58,9.23
            C-50.07,84.7-45.48,83.33-43.67,79.31z"
            />
            <path
              style="fill: #364620"
              d="M-43.79,106.27c2.29-0.53,5.06-2.71,6.16,1.41c0.64,2.4-0.44,4.31-2.71,5.33
            c-2.02,0.9-4.49,1.84-6.04-0.21c-2.27-3,1.44-4.3,2.46-6.39C-43.92,106.42-43.79,106.27-43.79,106.27z"
            />
            <path
              style="fill: #79a72a"
              d="M-109.49,59.08c1.95-0.39,4-0.57,5.05-2.67c2.15-0.54,3.38,0.39,3.92,2.44
            c1.36,2.52,2.71,5.04,4.18,7.77C-110.3,62.3-110.3,62.3-109.49,59.08z"
            />
            <path
              style="fill: #a0b91c"
              d="M-100.52,58.85c-1.31-0.81-2.61-1.63-3.92-2.44c-0.48-2.02-0.39-3.87,1.58-5.13
            c1.39-0.9,2.74-1.89,4.5-1.89C-91.12,54.37-94.04,57.01-100.52,58.85z"
            />
            <path
              style="fill: #3b471d"
              d="M-98.36,49.39c-1.5,0.63-3,1.26-4.5,1.89c0.69-3.75,1.38-7.51,2.1-11.41
            c3.85,1.2,4.81,4.69,7.01,6.95C-95.28,47.67-96.82,48.53-98.36,49.39z"
            />
            <path
              style="fill: #3d5325"
              d="M-23.5,52.02c-0.88-6.71-3.29-12.17-10.94-13.29c3.42-7.64-4.56-8.11-7.41-11.81
            c-2.97-1.48-2.95-1.71,0.45-4.6c0.73-0.12,1.48-0.43,2.18-0.34c9.89,1.2,9.88,1.22,15.84-4.07c1.6,0.48,2.61,1.59,3.26,3.07
            c1.53,5.02,5.16,4.7,8.77,3.02c4.71-2.2,8.5-0.81,12.17,2.12c1.21,0.94,2.28,2.04,3.39,3.1c3.85,2.63,8.68,4.33,9.38,9.94
            c0.07,2.36-0.61,4.35-2.72,5.66c-1.56,0.49-0.87,0.95-3.83,5.44c-2.42,3.66-6.32,1.34-9.54-0.57c-3.04-1.79-6.29-6.95-9.46-0.05
            C-15.8,50.43-19.65,51.23-23.5,52.02z"
            />
            <path
              style="fill: #29381c"
              d="M-23.27,22.41c-0.04-1.5-0.07-3-0.11-4.5c-1.58-2.52-3.16-5.04-4.74-7.55
            c-1.71-3.71-0.68-7.06,1.33-10.29c1.69-2.03,3.84-3.3,6.39-3.85c4.44-1.31,1.37-4.31,1.4-6.62c0.59-8.27,3.98-13.65,13.3-12.84
            c0.71,3.89,3.3,6.7,5.51,9.74c-3.06,6,1.54,10.33,3.34,15.27C3.66,11.62-5.79,15.1-9.8,22.09
            C-14.21,25.32-18.7,25.89-23.27,22.41z"
            />
            <path
              style="fill: #496828"
              d="M-52.79-9.24C-50.67-1.5-41.73,1.14-39.37,8.7c3.65-1.77,7.42-4.11,9.55,1.88
            c-0.37,0.03-0.83-0.06-1.11,0.12c-12.54,8.05-18.97-2.15-26.55-8.9c-2.58-1.73-3.86-4.13-3.68-7.27
            C-59.75-9.79-56.47-9.95-52.79-9.24z"
            />
            <path
              style="fill: #4b6426"
              d="M-75.53,20.06c1.43-2.98,2.87-5.95,4.3-8.93c4.81-1.3,9.35-1.85,11.48,4.2
            c1.04,3.06,2.19,6.1,3.02,9.22c0.15,0.55-1.03,1.46-1.6,2.21c-0.47-0.92-0.94-1.84-1.42-2.75c-3.32-1.52-5.11,0.49-6.72,2.91
            C-71.07,26.71-70.94,20.26-75.53,20.06z"
            />
            <path
              style="fill: #7cad34"
              d="M-59.75,15.33c-3.28-2.88-8.88,0.56-11.48-4.2c-0.73-2.55-0.3-4.48,2.7-5.02
            c1.55-4.96,0.44-12.02,8.99-11.5c2.71,1.82,1.94,4.64,2.06,7.2c-1.68,1.12-1.96,2.81-1.92,4.64c-0.1,2.35,1.79,4.62,0.26,7.02
            C-59.38,14.08-59.58,14.7-59.75,15.33z"
            />
            <path
              style="fill: #7ca232"
              d="M-29.82,10.58c-2.86-2.25-6.34-1.38-9.55-1.88c1.06-2.09,3.03-3.87,2.15-6.59
            c-0.68-3.41,1.58-4.38,4.19-5.07c3.55-0.03,6.08,1.51,7.42,4.86c2.79,3.89-1.03,5.82-2.5,8.45
            C-28.67,10.52-29.24,10.59-29.82,10.58z"
            />
            <path
              style="fill: #72a22d"
              d="M-41.4,22.32c0.03,1.55-1.96,2.9-0.45,4.6c-1.14,2.87-2.42,6.2-6.1,5.52
            c-3.08-0.57-1.3-3.89-1.45-5.79C-49.96,19.78-44.12,23.75-41.4,22.32z"
            />
            <path
              style="fill: #496828"
              d="M-59.14,13.47c-1.05-2.31-3.06-4.57-0.26-7.02c4.07,1.9,8.2,3.75,7.51,10.15
            C-54.63,15.41-56.88,14.44-59.14,13.47z"
            />
            <path
              style="fill: #769d32"
              d="M-66.48,26.91c-0.77-2.32-3.54-5.44-0.72-6.83c2.57-1.27,6.6-0.51,7.45,3.93
            C-61.99,24.97-64.24,25.94-66.48,26.91z"
            />
            <path
              style="fill: #31481f"
              d="M-59.54-5.4c-3.55,3.4-2.82,10.15-8.99,11.5c-2.3-0.7-4.6-1.4-6.9-2.1
            c-11.11-11.28,1.91-14.23,6.88-19.96c1.97-3.81,4.8-4.58,8.46-2.38c0.65,2.34,2.1,3.62,4.61,3.5c0.9,1.87,1.8,3.73,2.7,5.6
            C-55.04-7.96-57.29-6.68-59.54-5.4z"
            />
            <path
              style="fill: #7b9889"
              d="M10.88,44.83c0.04-2.46,0.08-4.91,0.12-7.37c0.05-2.45,1.75-2.48,3.53-2.41
            c4.42,3.48,11.03,0.12,15.06,4.86c2.54,4.88,5.03,9.75-3.04,11.9c-3.14-2.41-3.14-2.41-9.15,4.72
            C15.23,52.63,13.06,48.73,10.88,44.83z"
            />
            <path
              style="fill: #5c7e55"
              d="M26.55,51.81c3.55-3.76,1.86-7.38-0.31-10.98c0.15-4.29,2.61-6.35,6.63-6.92
            c1.17,0.03,2.27,0.36,3.28,0.94c0.86,0.71,1.52,1.6,2.03,2.59c1.88,5.26,0.67,10.06-2.28,14.59c-0.68,8.7-9.07,7.68-13.95,11.09
            C22.05,58.78,22.33,54.49,26.55,51.81z"
            />
            <path
              style="fill: #a1b7a7"
              d="M35.89,52.04c-2.81-4.67-0.14-9.44-0.24-14.16c4.98-3.73,6.75,4.28,11.05,3.03
            c0.83-4.25,4.72-6.28,6.91-9.55c-0.01,4.94,1.75,10.13-2.02,14.56c-2.77,2.5-5.81,2.48-9.01,0.94
            C40.35,48.58,38.12,50.31,35.89,52.04z"
            />
            <path
              style="fill: #6d8751"
              d="M42.58,46.85c2.77-2.53,5.95-1.13,9.01-0.94c1.23,3.11,2.94,2.82,4.91,0.7
            c3.24,3.47,0.85,6.55-0.67,9.68c-3.63,0.12-7.26,0.24-10.89,0.36C41.85,53.95,43.13,50.18,42.58,46.85z"
            />
            <path
              style="fill: #14170f"
              d="M-5.68-23.24c-3.07,5.01-10.06,6.94-10.79,13.78c0.81,1.84-0.24,2.87-1.63,3.76
            c-4.24,1.75-7.63-2.94-11.83-1.57c-1.01-0.26-1.89-0.76-2.65-1.46c-0.54-0.95-0.53-1.9,0.03-2.84
            c4.71-10.88,13.22-16.49,24.74-17.97C-7.1-27.44-6.39-25.34-5.68-23.24z"
            />
            <path
              style="fill: #62802f"
              d="M-32.55-11.57c0.1,0.69,0.17,1.38,0.21,2.08c0.07,2.9,2.16,5.73,0.27,8.68
            c-2.09,0.33-4.03,0.91-5.16,2.92C-40.19-9.96-39.86-10.93-32.55-11.57z"
            />
            <path
              style="fill: #6f9a31"
              d="M-60.1-18.34c-2.82,0.79-5.64,1.59-8.46,2.38c-4.67-0.58-7.73-3.19-9.42-7.52
            c0.45-3.8-3.42-5.47-4.26-8.64c2.05-3.1,4.11-6.19,6.16-9.28c1.26,2.87-1.07,6.7,3.07,9.79c0.68-5.17,1.27-9.7,1.87-14.22
            c8.86,1.87,4.27,11.14,8.52,15.53C-63.74-25.9-55.4-23.49-60.1-18.34z"
            />
            <path
              style="fill: #577e2d"
              d="M-12.12,19.86c2.04-8.62,9.61-12.56,15.3-18.1c1.84,2.86,4.7,2.34,7.44,2.24
            c-2.14,5-4.55,10.13,4.74,9.28c-1.52,2.25-3.04,4.5-4.56,6.75c-4.51,2.78-8.83,6.17-14.7,4.37
            C-6.86,23.28-10.88,24.08-12.12,19.86z"
            />
            <path
              style="fill: #b7c2ac"
              d="M12.88,36.12c-0.63,0.45-1.25,0.9-1.88,1.34c-3.09-2.94-9.71-1.02-10.25-7.47
            c-0.12-8.64,8.27-4.8,11.81-8.08c1.58,0.01,3.17,0.03,4.75,0.04c1.66,3.8,3.33,7.61,4.99,11.42
            C20.16,37.68,16.61,37.2,12.88,36.12z"
            />
            <path
              style="fill: #6c8c2f"
              d="M12.88,36.12c3.14-0.92,6.28-1.84,9.42-2.75c1.39-0.67,2.78-1.33,4.16-2
            c1.62-2.73,3.78-3.73,6.34-1.41c1.78,1.61,2.67,3.64,0,5.45c-2.19,1.81-4.37,3.62-6.56,5.43
            C21.53,39.99,15.81,42.01,12.88,36.12z"
            />
            <path
              style="fill: #aebdca"
              d="M32.81,35.4c0.41-5.3-3.63-3.62-6.34-4.04c-1.64-3.52,0.47-5.94,2.45-8.41
            c3.89-3.88,7.87-1.65,11.84-0.16c6.01,7.54,0.45,10.72-5.21,13.86C34.64,36.24,33.72,35.82,32.81,35.4z"
            />
            <path
              style="fill: #85ac6a"
              d="M39.73,25.13c-3.05-3.5-6.92-2.87-10.81-2.17c-0.51-4.83,5.18-2.69,6.34-5.64
            c3.39,3.59,8.01,2.51,12.16,3.23c-0.82,0.64-1.64,1.28-2.46,1.91C43.71,24.3,41.83,24.93,39.73,25.13z"
            />
            <path
              style="fill: #a0bd68"
              d="M12.56,21.91c1.4,10.48-9.73,2.69-11.81,8.1c-0.47-0.7-0.93-1.37-1.39-2.05
            c-1.34-1.43-3.04-2.73-1.7-5.06c4-2.7,7.54-7.49,13.13-2.85C11.38,20.67,11.97,21.29,12.56,21.91z"
            />
            <path
              style="fill: #85a256"
              d="M39.73,25.13c1.74-0.89,3.48-1.78,5.23-2.67c2.26,3.23,1.32,6.23-0.65,9.16
            c-2.69,4-1.15,6.9,2.39,9.29c-5.23,4.62-7.08-3.06-11.05-3.03c-0.08-0.41-0.11-0.81-0.1-1.22
            C36.84,32.77,42.36,30.43,39.73,25.13z"
            />
            <path
              style="fill: #77a32d"
              d="M-2.34,22.89c0.57,1.69,1.13,3.39,1.7,5.07c-3.02-0.19-5.28-2.23-9.22,0.19
            c-5.14,3.15-12.57,4.58-13.41-5.74c3.71-0.85,7.43-1.7,11.14-2.55C-8.86,20.87-5.6,21.88-2.34,22.89z"
            />
            <path
              style="fill: #35491f"
              d="M-32.06-0.81c-4.71-2.75-6.08-5.6-0.27-8.68c0.69,0.01,1.39-0.01,2.08-0.05
            c3.37,1.25,6.96,1.92,10.04,3.93c1.66,1.54,2.9,3.15,1.02,5.35c-2.14,0.72-4.28,1.44-6.41,2.16
            C-28.11,1.82-30.33,1.09-32.06-0.81z"
            />
            <path
              style="fill: #5b812b"
              d="M-19.2-0.26c-0.71-1.43-1.41-2.86-2.12-4.28c0.12-1.63,1.54-2,2.6-2.73
            c0.75-0.73,1.5-1.46,2.25-2.19c5.46,0.31,8.42,2,3.98,7.5C-14.23,0.21-14.84,7.69-19.2-0.26z"
            />
            <path
              style="fill: #78a32b"
              d="M-18.71-7.27c-0.87,0.91-1.74,1.82-2.6,2.72c-3.77-0.25-9.51,3.02-8.94-5
            C-25.27-14.58-22.31-9.3-18.71-7.27z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
