import { ref } from 'vue'

export default () => {
  let data = ref(null)
  const draggedKlimaatzoneIndex = ref(-1)
  const targetKlimaatzoneIndex = ref(-1)

  const draggedBouwdeelIndex = ref(-1)
  const targetBouwdeelIndex = ref(-1)

  const isDraggingKlimaatzone = ref(false)
  const isDraggingBouwdeel = ref(false)

  const dragDirectionKlimaatzone = ref('')
  const dragDirectionBouwdeel = ref('')

  const load = (list) => {
    data.value = list
  }

  const dragStartKlimaatzone = (klimaatzoneIndex) => {
    isDraggingKlimaatzone.value = true
    draggedKlimaatzoneIndex.value = klimaatzoneIndex
  }

  const dragEnterKlimaatzone = (klimaatzoneIndex) => {
    if (isDraggingKlimaatzone.value) {
      targetKlimaatzoneIndex.value = klimaatzoneIndex

      const draggedKlimaatzone = data.value[draggedKlimaatzoneIndex.value]
      data.value.splice(draggedKlimaatzoneIndex.value, 1)
      data.value.splice(klimaatzoneIndex, 0, draggedKlimaatzone)
      draggedKlimaatzoneIndex.value = klimaatzoneIndex
    } else if (isDraggingBouwdeel.value && data.value[klimaatzoneIndex].bouwdelen.length === 0) {
      // Handle case when dragging a bouwdeel into an empty klimaatzone
      const draggedBouwdeel = data.value[draggedKlimaatzoneIndex.value].bouwdelen[draggedBouwdeelIndex.value]
      data.value[draggedKlimaatzoneIndex.value].bouwdelen.splice(draggedBouwdeelIndex.value, 1)
      data.value[klimaatzoneIndex].bouwdelen.push(draggedBouwdeel)
      draggedKlimaatzoneIndex.value = klimaatzoneIndex
      draggedBouwdeelIndex.value = 0 // The dragged bouwdeel is now the first one in the klimaatzone
    }
  }

  // const swapItems = (sourceIndex, targetIndex) => {
  //   const tempItem = data.value[sourceIndex]
  //   data.value[sourceIndex] = data.value[targetIndex]
  //   data.value[targetIndex] = tempItem
  // }

  const dragEndKlimaatzone = () => {
    isDraggingKlimaatzone.value = false
    draggedKlimaatzoneIndex.value = -1
    targetKlimaatzoneIndex.value = -1
  }

  const dragStartBouwdeel = (event, klimaatzoneIndex, bouwdeelIndex) => {
    event.stopPropagation()
    isDraggingBouwdeel.value = true
    draggedKlimaatzoneIndex.value = klimaatzoneIndex
    draggedBouwdeelIndex.value = bouwdeelIndex
  }

  // const dragEnterBouwdeel = (klimaatzoneIndex, bouwdeelIndex) => {
  //   if (bouwdeelIndex === undefined) {
  //     return
  //   }

  //   if (isDraggingBouwdeel.value) {
  //     targetKlimaatzoneIndex.value = klimaatzoneIndex
  //     targetBouwdeelIndex.value = bouwdeelIndex

  //     const draggedBouwdeel = data.value[draggedKlimaatzoneIndex.value].bouwdelen[draggedBouwdeelIndex.value]

  //     data.value[draggedKlimaatzoneIndex.value].bouwdelen.splice(draggedBouwdeelIndex.value, 1)
  //     console.log('Inserting at index: ', bouwdeelIndex) // log the index
  //     data.value[klimaatzoneIndex].bouwdelen.splice(bouwdeelIndex, 0, draggedBouwdeel)
  //     draggedKlimaatzoneIndex.value = klimaatzoneIndex
  //     draggedBouwdeelIndex.value = bouwdeelIndex
  //   }
  // }

  let debounceTimeout = null

  const dragEnterBouwdeel = (klimaatzoneIndex, bouwdeelIndex) => {
    if (bouwdeelIndex === undefined) {
      return
    }

    if (isDraggingBouwdeel.value) {
      targetKlimaatzoneIndex.value = klimaatzoneIndex
      targetBouwdeelIndex.value = bouwdeelIndex

      const draggedBouwdeel = data.value[draggedKlimaatzoneIndex.value].bouwdelen[draggedBouwdeelIndex.value]

      // Clear any existing timeout
      clearTimeout(debounceTimeout)
      debounceTimeout = setTimeout(() => {
        data.value[draggedKlimaatzoneIndex.value].bouwdelen.splice(draggedBouwdeelIndex.value, 1)
        data.value[klimaatzoneIndex].bouwdelen.splice(bouwdeelIndex, 0, draggedBouwdeel)

        draggedKlimaatzoneIndex.value = klimaatzoneIndex
        draggedBouwdeelIndex.value = bouwdeelIndex
      }, 50)
    }
  }

  const dragEndBouwdeel = () => {
    isDraggingBouwdeel.value = false
    draggedKlimaatzoneIndex.value = -1
    targetKlimaatzoneIndex.value = -1
    draggedBouwdeelIndex.value = -1
    targetBouwdeelIndex.value = -1
  }

  const dragLeaveBouwdeel = () => {
    targetBouwdeelIndex.value = -1
  }

  return {
    dragStartKlimaatzone,
    dragEnterKlimaatzone,
    dragEndKlimaatzone,
    dragStartBouwdeel,
    dragEnterBouwdeel,
    dragEndBouwdeel,
    load,
    dragLeaveBouwdeel,
    dragDirectionKlimaatzone,
    dragDirectionBouwdeel,
    isDraggingKlimaatzone,
    isDraggingBouwdeel,
    targetKlimaatzoneIndex,
    targetBouwdeelIndex,
    draggedKlimaatzoneIndex,
  }
}
